import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { formatters } from 'utils';
import AnimateHeight from 'react-animate-height';
import ReleaseItemsChooser from 'components/common/ReleaseItemsChooser';
import StructuredContentBuilder from 'components/views/StructuredContentBuilder';


class Form extends Component {
  constructor (props) {
    super(props);
    this.defaultState = {
      itemId: null,
      fields: {
        uid: '',
        label: '',
        type: 'page',
        linkUrl: '',
        conditionalDisplay: false,
        conditionalDisplayItems: [],
        structuredContent: [],
        isVisible: true,
      },
      formReady: false,
      conditionalDetailsVisible: false,
    };
    this.state = {
      ...this.defaultState,
    };

    this.fieldPrefix = 'menuBuilderForm-';
    this.numericFields = [];
    this.requiredFields = [];

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleConditionalDisplayChange = this.handleConditionalDisplayChange.bind(this);
    this.handleConditionalItemsChange = this.handleConditionalItemsChange.bind(this);
    this.handleStructuredContentChange = this.handleStructuredContentChange.bind(this);
    this.initFormValues = this.initFormValues.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormChange = debounce(this.handleFormChange, 500);
    this.handleDetailsToggleClick = this.handleDetailsToggleClick.bind(this);
    this.errorClass = this.errorClass.bind(this);
  }

  componentDidMount () {
    this.initFormValues();
  }

  componentDidUpdate (prevProps, prevState) {
    const { item } = this.props;
    const prevItem = prevProps.item;

    if (!isEqual(item, prevItem)) {
      this.initFormValues();
    }
  }

  componentWillUnmount () {
    this.handleFormChange.flush();
  }

  handleInputChange (e) {
    const { fields } = this.state;
    let { name, value, type, checked } = e.target;
    name = name.replace(this.fieldPrefix, '');
    if (value && this.numericFields.includes(name)) {
      value = parseFloat(value);
    } else if (type === 'checkbox') {
      value = checked;
    } else if (name === 'slug') {
      value = formatters.slug(value);
    }
    this.setState({
      fields: {
        ...fields,
        [name]: value,
      },
    }, this.handleFormChange);
  }

  handleConditionalDisplayChange (e) {
    const { fields } = this.state;
    const conditionalDisplay = e.target.value === 'limit';

    this.setState({
      fields: {
        ...fields,
        conditionalDisplay,
      },
      conditionalDetailsVisible: conditionalDisplay,
    }, this.handleFormChange);
  }

  handleConditionalItemsChange (ids) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        conditionalDisplayItems: ids,
      },
    }, this.handleFormChange);
  }

  handleStructuredContentChange (value) {
    const { fields } = this.state;
    if (!isEqual(fields.structuredContent, value)) {
      this.setState({
        fields: {
          ...fields,
          structuredContent: value,
        },
      }, this.handleFormChange);
    }
  }

  handleFormChange () {
    const { onChange } = this.props;
    const { fields } = this.state;
    onChange({ ...fields });
  }

  handleDetailsToggleClick (e) {
    e.preventDefault();
    this.setState({ conditionalDetailsVisible: !this.state.conditionalDetailsVisible });
  }

  initFormValues () {
    const { item } = this.props;
    if (item) {
      const newFields = Object.keys(this.defaultState.fields).reduce((result, key) => {
        if (item.hasOwnProperty(key)) {
          // Keep explicit false for checkbox fields, but convert undefined or null to empty strings
          result[key] = (item[key] || item[key] === false) ? item[key] : '';
        } else {
          result[key] = this.defaultState.fields[key];
        }
        return result;
      }, {});

      this.setState({
        fields: newFields,
        itemId: item.uid,
        formReady: true,
      });
    } else {
      this.setState({ fields: this.defaultState.fields, itemId: null, formReady: true });
    }
  }

  errorClass (fieldName) {
    const { item, errors } = this.props;
    return item && errors && errors[fieldName] ? 'has-error' : '';
  }

  render () {
    const { releaseItems, item, errors } = this.props;
    const {
      formReady,
      conditionalDetailsVisible,
      fields: { isVisible, label, type, linkUrl, conditionalDisplay, conditionalDisplayItems, structuredContent },
    } = this.state;

    if (!item || !formReady) {
      return null;
    }

    let structuredContentErrors = {};
    if (errors && errors.structuredContent && errors.structuredContent.length > 0) {
      // See apps/msp_structured_content/forms.py
      structuredContentErrors = JSON.parse(errors.structuredContent[0].message);
    }

    return (
      <div
        className="title-builder-form-container"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <header className="title-builder-form-header">
          <div>
            <h5><span>Editing:</span> {item.label || (item.isNew ? '(New Item)' : '(Unlabeled Item)')}</h5>
          </div>
        </header>

        {!isVisible && <div className="warning-list mb-4">This Menu Item is disabled and will not appear in the published release. To enable it, click the visibility icon at left.</div>}

        <div className={classNames('form-group', 'mb-4', this.errorClass('label'))}>
          <label className="block-label">Label</label>
          <input
            name={this.fieldPrefix + 'label'}
            type="text"
            className="form-input"
            value={label}
            onChange={this.handleInputChange}
          />
          {errors && errors.label ? (
            errors.label.map(({ message }, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)
          ) : null}
        </div>

        <div className={classNames('form-group', 'mb-4', this.errorClass('conditionalDisplay'))}>
          <label className="block-label">Display this menu item for:</label>
          <label className="form-radio m-0">
            <input
              type="radio"
              name={this.fieldPrefix + 'conditionalDisplay'}
              value=""
              checked={!conditionalDisplay}
              onChange={this.handleConditionalDisplayChange}
            />
            <i className="form-icon" /> All content in this release
          </label>
          <label className="form-radio m-0">
            <input
              type="radio"
              name={this.fieldPrefix + 'conditionalDisplay'}
              value="limit"
              checked={conditionalDisplay}
              onChange={this.handleConditionalDisplayChange}
            />
            <i className="form-icon" />
            <div>
              Specific content only
              {conditionalDisplay && (
                <div className="text-hint">
                  <a href="#details" onClick={this.handleDetailsToggleClick}>{conditionalDetailsVisible ? 'hide' : 'show'} details</a>
                </div>
              )}
            </div>
          </label>
          <AnimateHeight duration={500} height={conditionalDetailsVisible ? 'auto' : 0}>
            <div className="mt-2">
              <ReleaseItemsChooser
                releaseItems={releaseItems}
                selectedIds={conditionalDisplayItems}
                onChange={this.handleConditionalItemsChange}
              />
            </div>
          </AnimateHeight>
        </div>

        <div className={classNames('form-group', 'mb-4', this.errorClass('type'))}>
          <label className="block-label">Item Type:</label>
          <label className="form-radio m-0">
            <input
              type="radio"
              name={this.fieldPrefix + 'type'}
              value="page"
              checked={type === 'page'}
              onChange={this.handleInputChange}
            />
            <i className="form-icon" /> Page &nbsp;<span className="text-hint">Display custom page content.</span>
          </label>

          <label className="form-radio m-0">
            <input
              type="radio"
              name={this.fieldPrefix + 'type'}
              value="link"
              checked={type === 'link'}
              onChange={this.handleInputChange}
            />
            <i className="form-icon" /> Link &nbsp;<span className="text-hint">Link to a specific URL.</span>
          </label>
        </div>

        {type === 'page' ? (
          <>
            <div className="form-group m-0">
              <label className="block-label">Page Content</label>
              <div className="form-input-hint mt-0 mb-1">Assemble this page’s content using the form below.</div>
            </div>

            <StructuredContentBuilder
              releaseItems={releaseItems}
              initialValue={structuredContent}
              errors={structuredContentErrors}
              onChange={this.handleStructuredContentChange}
            />
          </>
        ) : (
          <div className={classNames('form-group', 'mb-4', this.errorClass('linkUrl'))}>
            <label className="block-label">Link URL</label>
            <input
              name={this.fieldPrefix + 'linkUrl'}
              type="text"
              className="form-input"
              value={linkUrl}
              onChange={this.handleInputChange}
            />
            {errors && errors.linkUrl ? (
              errors.linkUrl.map(({ message }, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

Form.propTypes = {
  releaseItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    objectId: PropTypes.string,
    objectType: PropTypes.string,
    objectName: PropTypes.string,
    objectIconName: PropTypes.string,
  })),
  item: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default Form;
