import React from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router';


export const history = createBrowserHistory();

// eslint-disable-next-line
export const routedComponent = Component => props => (
  <Router history={history}>
    <Component {...props} />
  </Router>
);
