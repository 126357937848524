import React, { useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactQuill, { Quill } from 'react-quill';
import Icon from 'components/common/Icon';

const AlignStyle = Quill.import('attributors/style/align');
const Delta = Quill.import('delta');
const Break = Quill.import('blots/break');
const Embed = Quill.import('blots/embed');

const lineBreakMatcher = () => {
  const newDelta = new Delta();
  newDelta.insert({ break: '' });
  return newDelta;
};

class SmartBreak extends Break {
  length () {
    return 1;
  }

  value () {
    return '\n';
  }

  insertInto (parent, ref) {
    Embed.prototype.insertInto.call(this, parent, ref);
  }
}

SmartBreak.blotName = 'break';
SmartBreak.tagName = 'BR';

Quill.register(SmartBreak);
Quill.register(AlignStyle, true);

const icons = Quill.import('ui/icons');
icons.undo = `<svg viewbox="0 0 18 18">
  <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
  <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
</svg>`;
icons.redo = `<svg viewbox="0 0 18 18">
  <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
  <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
</svg>`;
icons.header[3] = `<svg viewBox="0 0 18 18">
  <path class="ql-fill" d="M16.65186,12.30664a2.6742,2.6742,0,0,1-2.915,2.68457,3.96592,3.96592,0,0,1-2.25537-.6709.56007.56007,0,0,1-.13232-.83594L11.64648,13c.209-.34082.48389-.36328.82471-.1543a2.32654,2.32654,0,0,0,1.12256.33008c.71484,0,1.12207-.35156,1.12207-.78125,0-.61523-.61621-.86816-1.46338-.86816H13.2085a.65159.65159,0,0,1-.68213-.41895l-.05518-.10937a.67114.67114,0,0,1,.14307-.78125l.71533-.86914a8.55289,8.55289,0,0,1,.68213-.7373V8.58887a3.93913,3.93913,0,0,1-.748.05469H11.9873a.54085.54085,0,0,1-.605-.60547V7.59863a.54085.54085,0,0,1,.605-.60547h3.75146a.53773.53773,0,0,1,.60547.59375v.17676a1.03723,1.03723,0,0,1-.27539.748L14.74854,10.0293A2.31132,2.31132,0,0,1,16.65186,12.30664ZM9,3A.99974.99974,0,0,0,8,4V8H3V4A1,1,0,0,0,1,4V14a1,1,0,0,0,2,0V10H8v4a1,1,0,0,0,2,0V4A.99974.99974,0,0,0,9,3Z"/>
</svg>`;

const getQuillConf = (full = false) => ({
  modules: {
    toolbar: {
      container: [
        ...(full ? [[{ header: 1 }, { header: 2 }, { header: 3 }]] : []),
        ['bold', 'italic', 'link', 'blockquote'],
        [{ list: 'bullet' }, { list: 'ordered' }],
        [{ align: '' }, { align: 'center' }, { align: 'right' }],
        ['clean'],
        ['undo', 'redo'],
      ],
      handlers: {
        undo: function () {
          this.quill.history.undo();
        },
        redo: function () {
          this.quill.history.redo();
        },
      },
    },
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler: () => true,
        },
        linebreak: {
          key: 13,
          shiftKey: true,
          handler: function (range) {
            const currentLeaf = this.quill.getLeaf(range.index)[0];
            const nextLeaf = this.quill.getLeaf(range.index + 1)[0];
            this.quill.insertEmbed(range.index, 'break', true, 'user');
            // Insert a second break if:
            // At the end of the editor, OR next leaf has a different parent (<p>)
            if (nextLeaf === null || currentLeaf.parent !== nextLeaf.parent) {
              this.quill.insertEmbed(range.index, 'break', true, 'user');
            }
            // Now that we've inserted a line break, move the cursor forward
            this.quill.setSelection(range.index + 1, Quill.sources.SILENT);
          },
        },
      },
    },
    clipboard: {
      matchVisual: false,
      matchers: [['BR', lineBreakMatcher]],
    },
  },
  formats: [
    ...(full ? ['header'] : []),
    'bold', 'italic', 'link', 'blockquote',
    'bullet', 'list', 'indent',
    'align',
  ],
});

const QuillEditor = ({
  inputName,
  readOnly = false,
  value: valueFromProps,
  size,
  useFullMenu,
  onChange,
}) => {
  const containerRef = useRef();

  const [internalValue, setInternalValue] = useState(valueFromProps);
  const value = onChange ? valueFromProps : internalValue;

  const handleChange = (content, delta, source, editor) => {
    // Quill leaves a single newline character when the editor is empty.
    const editorVal = editor.getLength() > 1 ? content : '';
    if (onChange) {
      onChange(editorVal);
    } else {
      setInternalValue(editorVal);
    }
  };

  const [containerEl, setContainerEl] = useState(null);
  useEffect(() => setContainerEl(containerRef.current), []);

  const editorHeightTimeout = useRef();
  const [editorContainerHeight, setEditorContainerHeight] = useState(null);
  const handleEditorSizeChange = () => {
    if (containerRef.current) {
      const h = containerRef.current.querySelector('.ql-container').getBoundingClientRect().height;
      setEditorContainerHeight(h);
    }
  };

  useEffect(() => {
    clearTimeout(editorHeightTimeout.current);
    editorHeightTimeout.current = setTimeout(handleEditorSizeChange, 1000);
  }, [value]);

  const [expanded, setExpanded] = useState(false);
  const maxHeights = { small: 200, large: 600 };
  const expandable = size && !expanded && editorContainerHeight === maxHeights[size];
  const handleExpandClick = () => setExpanded(oldState => !oldState);

  const classes = classNames({
    sm: size === 'small',
    lg: size === 'large',
    disabled: readOnly,
    expanded,
  });

  const { modules, formats } = useMemo(() => getQuillConf(useFullMenu), [useFullMenu]);

  return (
    <div ref={containerRef} className="quill-wrap">
      {!!containerEl && (
        <>
          <ReactQuill
            readOnly={readOnly}
            className={classes}
            modules={modules}
            formats={formats}
            bounds={containerEl}
            value={value}
            onChange={handleChange}
          />
          {expandable && <div className="expand-btn" title="Expand text field" onClick={handleExpandClick}><Icon name="arrow_drop_down" /></div>}
          {expanded && <div className="expand-btn" title="Collapse text field" onClick={handleExpandClick}><Icon name="arrow_drop_up" /></div>}
        </>
      )}
      {inputName && <input name={inputName} type="hidden" value={value} />}
    </div>
  );
};

QuillEditor.propTypes = {
  inputName: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  size: PropTypes.oneOf(['small', 'large']), // If unspecified, the editor will grow indefinitely to fit the text
  useFullMenu: PropTypes.bool,
  onChange: PropTypes.func,
};

export default QuillEditor;
