import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { urls } from 'app-constants';
import { useCSRF } from 'hooks';
import DrawerModal from 'components/common/DrawerModal';
import ErrorBoundary from 'components/common/ErrorBoundary';
import AddLibraryMedia from 'components/common/AddLibraryMedia';
import AddTitleAttachment from 'components/common/AddTitleAttachment';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import LoadingOverlay from 'components/common/LoadingOverlay';
import Message from 'components/common/Message';
import Modal from 'components/common/Modal';
import Icon from 'components/common/Icon';

const SUPPORTED_FIELDS = {
  media: [
    'name', 'short_description', 'long_description', 'notes', 'license_url', 'prints_url', 'nft_url', 'credits', 'related_links',
  ],
  title: [
    'name', 'short_description', 'long_description', 'notes', 'credits', 'related_links',
  ],
  release: [
    'name', 'short_description', 'long_description', 'notes', 'related_links',
  ],
};

const FIELDS = [
  { name: 'name', label: 'Name' },
  { name: 'short_description', label: 'Short description' },
  { name: 'long_description', label: 'Long description' },
  { name: 'notes', label: 'Notes', hint: '(internal/administrative notes)' },
  { name: 'license_url', label: 'License URL' },
  { name: 'prints_url', label: 'Prints URL' },
  { name: 'nft_url', label: 'NFT URL' },
  { name: 'credits', label: 'Credits', hint: '(includes both “simple” and “advanced” credit fields)' },
  { name: 'related_links', label: 'Related links' },
];


const MetadataImport = ({ model, ids }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [srcObj, setSrcObj] = useState({});

  const srcHasField = fieldName => !!srcObj.model && SUPPORTED_FIELDS[srcObj.model].includes(fieldName);
  const destHasField = fieldName => SUPPORTED_FIELDS[model].includes(fieldName);

  useEffect(() => {
    if (srcObj.model) {
      setSelectedFields(selectedFields.filter(f => srcHasField(f) && destHasField(f)));
    }
  }, [srcObj.model]);

  const handleLinkClick = e => {
    e.preventDefault();
    setModalVisible(true);
  };

  const [selectedFields, setSelectedFields] = useState([]);
  const handleInputChange = e => {
    const { name, checked } = e.target;
    const val = name.replace('metaImport-', '');
    setSelectedFields(checked ? [...selectedFields, val] : selectedFields.filter(f => f !== val));
  };

  const handleOptionsFormSubmit = e => {
    e.preventDefault();
    setConfirmModalVisible(true);
  };
  const handleConfirmSubmit = e => {
    e.preventDefault();
    performAction();
    setConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => setConfirmModalVisible(false);

  const handleModalRequestClose = () => setModalVisible(false);
  const handleModalDidClose = () => clearAllFields();

  const clearSrcObj = () => setSrcObj({});
  const clearAllFields = () => {
    setSrcObj({});
    setSelectedFields([]);
  };

  const csrfToken = useCSRF();
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const performAction = () => {
    setIsFetching(true);
    setErrorMessage(null);

    const body = JSON.stringify({
      src_model: srcObj.model,
      src_id: srcObj.id,
      dest_model: model,
      dest_ids: ids,
      fields: selectedFields,
    });

    fetch(urls.metadataImport, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (response.status === 400) {
          // Form validation errors
          response.json().then(data => {
            setIsFetching(false);
            setErrorMessage('Failed to import metadata. Please try again.');
          });
        } else if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          response.json().then(data => {
            // setIsFetching(false);
            window.location.reload();
          });
        }
        return response;
      })
      .catch(err => {
        setIsFetching(false);
        setErrorMessage('Failed to import metadata. Please try again.');
        console.error(err);
      });
  };


  const srcObjPreview = !!srcObj.id && (
    <AttachedItemPreview
      itemType={srcObj.model}
      itemId={srcObj.id}
      clickableThumbnail
    >
      <div
        className="btn-delete tooltip tooltip-left"
        data-tooltip="Clear"
        onClick={clearSrcObj}
      >
        <Icon name="delete" fill />
      </div>
    </AttachedItemPreview>
  );

  const srcObjSelect = !srcObj.id && (
    <div className="attached-item-preview justify-content-center">
      <AddLibraryMedia onChange={id => setSrcObj({ model: 'media', id })}>
        {handleModalTrigger => (
          <button type="button" className="btn btn-primary" onClick={handleModalTrigger}>Select Media</button>
        )}
      </AddLibraryMedia>
      <span style={{ margin: '0 15px' }}>– or –</span>
      <AddTitleAttachment onChange={id => setSrcObj({ model: 'title', id })}>
        {handleModalTrigger => (
          <button type="button" className="btn btn-primary" onClick={handleModalTrigger}>Select Title</button>
        )}
      </AddTitleAttachment>
    </div>
  );

  return (
    <ErrorBoundary>
      <a href="#import" onClick={handleLinkClick}>
        <Icon name="input" className="mr-2" />
        <span>Import Metadata</span>
      </a>
      <DrawerModal
        isOpen={modalVisible}
        title="Import Metadata"
        onRequestClose={handleModalRequestClose}
        onDidClose={handleModalDidClose}
      >
        <div className="limit-800">
          {errorMessage && (
            <Message type="error" clearable={false} text={errorMessage} />
          )}
          <div className="form-group mb-4">
            <label className="block-label">Source Object</label>
            <p className="form-input-hint mb-1">Metadata will be imported from the selected Media or Title object.</p>
            {modalVisible && (srcObj.id ? srcObjPreview : srcObjSelect)}
          </div>

          <div className="form-group mb-4">
            <label className="block-label">Fields</label>
            <p className="form-input-hint mb-1">Select fields to import.</p>

            {FIELDS.filter(f => destHasField(f.name)).map(({ name, label, hint }) => {
              const isDisabled = !!srcObj.model && !srcHasField(name);
              return (
                <label
                  key={name}
                  className={classNames('form-checkbox', isDisabled && 'text-gray')}
                  title={isDisabled ? 'This field cannot be imported from the selected source object.' : ''}
                >
                  <input
                    type="checkbox"
                    name={`metaImport-${name}`}
                    checked={selectedFields.includes(name)}
                    disabled={isDisabled}
                    onChange={handleInputChange}
                  />
                  <i className="form-icon" /> {label} {hint && <span className="text-hint ml-1">{hint}</span>}
                </label>
              );
            })}
          </div>

          <hr />
          <div>
            <button type="button" className="btn mr-2" onClick={handleModalRequestClose}>Cancel</button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={!selectedFields.length || !srcObj.id}
              onClick={handleOptionsFormSubmit}
            >
              Submit
            </button>
          </div>
        </div>
        <LoadingOverlay show={isFetching} />
      </DrawerModal>

      <Modal
        title="Are You Sure?"
        style={{ content: { width: 700 } }}
        isOpen={confirmModalVisible}
        onRequestClose={handleConfirmCancel}
      >
        <form onSubmit={handleConfirmSubmit}>
          <p>The selected fields will be overwritten with values from the source object. There is no undo.</p>
          <div className="modal-action-buttons">
            <button className="btn" onClick={handleConfirmCancel}>Cancel</button>
            <button className="btn btn-primary" type="submit">Confirm</button>
          </div>
        </form>
      </Modal>
    </ErrorBoundary>
  );
};

MetadataImport.propTypes = {
  model: PropTypes.oneOf(['media', 'title', 'release']).isRequired,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MetadataImport;
