import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { usePortal } from 'hooks';
import Icon from 'components/common/Icon';
import { DeleteOverlay } from 'components/common/inlines';
import ItemInlineForm from './ItemInlineForm';


const Item = props => {
  const {
    parentId,
    parentFieldName,
    idFieldName,
    orderingFieldName,
    labelFieldName,
    orderingIndex,
    itemTypeLabel,
    containerEl,
    formComponent,
    itemId,
    prefix,
    errors,
    shouldDelete,
    onDeleteClick,
    onFormClose,
  } = props;

  const idFieldValue = props[idFieldName];

  const [labelText, setLabelText] = useState(props[labelFieldName]);
  const [editing, setEditing] = useState(!props[labelFieldName] && !formComponent);

  const hasError = Object.keys(errors).length > 0;

  useEffect(() => {
    if (shouldDelete && editing) setEditing(false);
  }, [shouldDelete, editing]);

  const handleEditClick = () => setEditing(!editing);
  const handleDeleteClick = () => onDeleteClick(itemId);

  // Modal form
  // ==================================

  const renderPortal = usePortal(containerEl);

  useEffect(() => {
    if (formComponent && !idFieldValue && !hasError) {
      // Newly-added item
      setTimeout(() => setEditing(true), 100);
    }
  }, []);

  const handleFormClose = opts => {
    setEditing(false);
    onFormClose && onFormClose(opts);
  };

  const FormComponent = formComponent;
  const modalForm = FormComponent && renderPortal(
    <>
      <div className={classNames('custom-title-builder-child-form-modal-mask', editing && 'open')} />
      <div className={classNames('custom-title-builder-child-form-modal', editing && 'open')}>
        <header className="custom-title-builder-child-form-modal-header">
          <a href="#back" className="back-icon" onClick={e => { e.preventDefault(); handleFormClose(); }}>
            <Icon name="arrow_back_ios" size={32} />
          </a>
          <h3>Editing <em>{itemTypeLabel}</em> Item</h3>
        </header>
        <div className="custom-title-builder-child-form-modal-content">
          <FormComponent
            onLabelChange={setLabelText}
            onClose={handleFormClose}
            {...props}
          />
        </div>
      </div>
    </>,
  );

  // Render
  // ==================================

  const classes = classNames('item', hasError && 'error');

  return (
    <div className={classes}>
      {(formComponent || !editing) && <h5 className="mb-0">{labelText || '(New Item)'}</h5>}
      {!formComponent && (
        <ItemInlineForm
          {...props}
          editing={editing}
          onLabelChange={setLabelText}
          onConfirmEdit={() => setEditing(false)}
        />
      )}
      <div className="d-flex align-items-center">
        <span
          className="btn-delete tooltip tooltip-left mx-1"
          onClick={handleDeleteClick}
          data-tooltip={`Remove ${itemTypeLabel}`}
        >
          <Icon name="delete" fill />
        </span>
        <span
          className="btn-edit tooltip tooltip-left mx-1"
          onClick={handleEditClick}
          data-tooltip={`Edit ${itemTypeLabel}`}
        >
          <Icon name="edit" />
        </span>
        <span className="btn-move ml-1" title="Drag to sort">
          <Icon name="menu" />
        </span>
      </div>
      {!!idFieldValue && <input type="hidden" name={`${prefix}-${idFieldName}`} value={idFieldValue} />}
      <input type="hidden" name={`${prefix}-${parentFieldName}`} value={parentId} />
      <input type="hidden" name={`${prefix}-${orderingFieldName}`} value={orderingIndex} />
      {shouldDelete && <input type="hidden" name={`${prefix}-DELETE`} value="1" />}
      {shouldDelete && <DeleteOverlay onUndoClick={handleDeleteClick} />}
      {modalForm}
    </div>
  );
};

Item.propTypes = {
  parentId: PropTypes.string.isRequired,
  parentFieldName: PropTypes.string.isRequired,
  idFieldName: PropTypes.string.isRequired,
  orderingFieldName: PropTypes.string.isRequired,
  labelFieldName: PropTypes.string.isRequired,
  editableFieldNames: PropTypes.arrayOf(PropTypes.string),
  orderingIndex: PropTypes.number.isRequired,
  itemTypeLabel: PropTypes.string.isRequired,
  containerEl: PropTypes.instanceOf(Element),
  formComponent: PropTypes.elementType,
  itemId: PropTypes.string,
  prefix: PropTypes.string,
  errors: PropTypes.object,
  shouldDelete: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onFormClose: PropTypes.func,
};

export default Item;
