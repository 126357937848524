import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DeleteOverlay } from 'components/common/inlines';
import Icon from 'components/common/Icon';

const Item = ({
  label,
  hasError,
  isNew,
  isVisible,
  isEditing,
  shouldDelete,
  onClick,
  onDeleteClick,
  onVisibilityClick,
}) => (
  <div style={{ position: 'relative' }}>
    <div
      className={classNames({
        item: true,
        error: hasError,
        active: isEditing,
        dimmed: !isVisible,
      })}
      onClick={onClick}
    >
      <h5 className="mb-0">{label || (isNew ? '(New Item)' : '(Unlabeled Item)')}</h5>
      <div className="d-flex align-items-center" onClick={e => e.stopPropagation()}>
        <span
          className="btn-delete tooltip tooltip-left mx-1"
          onClick={onDeleteClick}
          data-tooltip="Remove Item"
        >
          <Icon name="delete" fill />
        </span>
        <span
          key={isVisible}
          className="btn-action btn-clear tooltip tooltip-left mx-1"
          onClick={onVisibilityClick}
          data-tooltip="Toggle Item Visibility"
        >
          <Icon name={isVisible ? 'visibility' : 'visibility_off'} fill />
        </span>
        <span className="btn-move ml-1" title="Drag to sort">
          <Icon name="menu" />
        </span>
      </div>
    </div>
    {shouldDelete ? <DeleteOverlay onUndoClick={onDeleteClick} /> : null}
  </div>
);

Item.propTypes = {
  label: PropTypes.string,
  hasError: PropTypes.bool,
  isNew: PropTypes.bool,
  isVisible: PropTypes.bool,
  isEditing: PropTypes.bool,
  shouldDelete: PropTypes.bool,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onVisibilityClick: PropTypes.func,
};

export default Item;
