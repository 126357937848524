import React from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'components/common/Collapsible';
import DjangoFormField from 'components/common/DjangoFormField';

const SEOFields = ({
  titleField,
  descriptionField,
  renderOverrideToggle = () => {},
  isFieldDisabled = () => false,
}) => {
  const defaultOpen = !!(titleField.errors.length || descriptionField.errors.length || titleField.value || descriptionField.value);

  return (
    <Collapsible
      defaultOpen={defaultOpen}
      labelOpen="Hide SEO fields"
      labelClosed="Show SEO fields"
      className="mb-6"
    >
      <div className="seo-fields">
        <div className="text-hint mb-3">
          These fields can influence the appearance of your pages in search results. They generally do not influence search engine rankings. If left blank, the object name and short description above will be used by default.
        </div>

        <div className="form-group mb-4">
          <div className="flex-spread mb-1">
            <label className="strong m-0">{titleField.label}</label>
            {renderOverrideToggle(titleField.name)}
          </div>
          <DjangoFormField
            {...titleField}
            label=""
            disabled={isFieldDisabled(titleField)}
          />
        </div>

        <div className="form-group mb-4">
          <div className="flex-spread mb-1">
            <label className="strong m-0">{descriptionField.label}</label>
            {renderOverrideToggle(descriptionField.name)}
          </div>
          <DjangoFormField
            {...descriptionField}
            label=""
            disabled={isFieldDisabled(descriptionField)}
          />
        </div>
      </div>
    </Collapsible>
  );
};

SEOFields.propTypes = {
  titleField: PropTypes.object,
  descriptionField: PropTypes.object,
  renderOverrideToggle: PropTypes.func,
  isFieldDisabled: PropTypes.func,
};

export default SEOFields;
