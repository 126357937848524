import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';


class DragDropFileInput extends Component {
  render () {
    const { multiple, helpText, onFileDrop, ...rest } = this.props;
    return (
      <Dropzone
        accept="image/gif,image/jpeg,image/png,.jpg,.jpeg,.gif,.png,audio/*,.mp3,.wav,"
        className="drag-drop-file-input"
        activeClassName="active"
        rejectClassName="reject"
        ref={node => this.dropzoneRef = node}
        onDrop={onFileDrop}
        multiple={multiple}
        disableClick
        {...rest}
      >
        {({ isDragActive, isDragReject }) => (
          <>
            {isDragReject ? (
              <h5 className="text-error">Unsupported file type</h5>
            ) : (
              <h5>{helpText || (multiple ? 'Drag files here' : 'Drag a file here')}</h5>
            )}
            <div className="separator">– or –</div>
            <div><button className="btn" onClick={() => this.dropzoneRef.open()}>Choose File{multiple ? 's' : ''}</button></div>
          </>
        )}
      </Dropzone>
    );
  }
}

DragDropFileInput.propTypes = {
  multiple: PropTypes.bool,
  helpText: PropTypes.string,
  onFileDrop: PropTypes.func,
};

DragDropFileInput.defaultProps = {
  multiple: true,
};

export default DragDropFileInput;
