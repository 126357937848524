import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { DragSource, DropTarget } from 'react-dnd';

const LEVEL_INDENT = 40;

const getType = props => props.type || 'NestedSortableItem';

const itemSource = {
  beginDrag (props, monitor, component) {
    component.onDragStart(props.itemId);
    return {
      startPos: monitor.getClientOffset(),
      itemId: props.itemId,
    };
  },

  endDrag (props, monitor, component) {
    if (!component) return null;
    component.setDeltaOffset(0);
    component.onDragEnd(props.itemId);
  },

  canDrag (props, monitor) {
    return props.itemId.indexOf('placeholder') === -1;
  },

  isDragging (props, monitor) {
    return props.itemId === monitor.getItem().itemId;
  },
};

const itemTarget = {
  hover: throttle((props, monitor, component) => {
    if (!component) {
      return null;
    }
    const dragId = (monitor.getItem() || {}).itemId;
    const hoverId = props.itemId;

    // Ignore the parent list if the target is within a nested list
    if (monitor.isOver({ shallow: true })) {
      // Example: https://react-dnd.github.io/react-dnd/examples-sortable-simple.html
      const hoverBoundingRect = component.container.getBoundingClientRect();  // eslint-disable-line
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      const deltaX = monitor.getDifferenceFromInitialOffset().x;
      component.setDragDelta(deltaX - component.deltaOffset);

      if (dragId !== hoverId) {
        const insertPos = hoverClientY < hoverMiddleY ? 'before' : 'after';
        component.moveItem(dragId, hoverId, insertPos);
      }
    }
  }, 50),
};

const NestedSortableItem = forwardRef(({
  itemId,
  onItemMove,
  onLevelChange,
  onDragStart = () => {},
  onDragEnd = () => {},
  isDragging,
  isOver,
  connectDragPreview,
  connectDragSource,
  connectDropTarget,
  canSort = true,
  children,
  level = 0,
  canIndent,
  canOutdent,
  className,
  style = {},
  type = 'NestedSortableItem',
}, ref) => {
  const containerRef = useRef();
  const defaultDragState = { dragId: null, hoverId: null, insertPos: null, deltaX: 0 };
  const [dragState, setDragState] = useState(defaultDragState);
  const updateDragState = attrs => setDragState(prevState => ({ ...prevState, ...attrs }));

  const [deltaOffset, setDeltaOffset] = useState(0);

  const moveItem = (dragId, hoverId, insertPos) => updateDragState({ dragId, hoverId, insertPos });
  const setDragDelta = deltaX => updateDragState({ deltaX });
  useImperativeHandle(ref, () => ({
    container: containerRef.current,
    moveItem,
    setDragDelta,
    deltaOffset,
    setDeltaOffset,
    onDragStart,
    onDragEnd,
  }));

  useEffect(() => {
    const { dragId, hoverId, insertPos } = dragState;
    if (dragId && hoverId && insertPos) {
      onItemMove(dragId, hoverId, insertPos);
    }
  }, [dragState.dragId, dragState.hoverId, dragState.insertPos]);

  useEffect(() => {
    if (isDragging && canIndent && dragState.deltaX > LEVEL_INDENT) {
      onLevelChange(itemId, level + 1);
      setDeltaOffset(prevState => prevState + LEVEL_INDENT);
    } else if (isDragging && canOutdent && dragState.deltaX < 0) {
      onLevelChange(itemId, level - 1);
      setDeltaOffset(prevState => prevState - LEVEL_INDENT);
    }
  }, [dragState.deltaX]);

  useEffect(() => {
    if (!isOver) setDragState({ ...defaultDragState });
  }, [isOver]);

  const classes = classNames(className, {
    'sortable-item': true,
    nestable: true,
    draggable: canSort,
    dragging: isDragging,
  });

  let renderedChildren = children;
  if (typeof children === 'function') {
    // the child function pattern can be used to limit the drag handle to a specific
    // element within the child, as opposed to making the entire child component draggable.
    renderedChildren = canSort ? children(connectDragSource) : children();
  }

  const component = (
    <div ref={containerRef} className={classes} style={{ ...style, marginLeft: level * LEVEL_INDENT }}>
      <div className="sortable-item-contents">
        {renderedChildren}
      </div>
    </div>

  );

  if (canSort && typeof children === 'function') {
    // Sorting is enabled and `children` is a function: `connectDragSource` should be called
    // somewhere in a descendant component, with the drag handle element as its argument.
    return connectDragPreview(connectDropTarget(component));
  } else if (canSort) {
    return connectDragSource(connectDropTarget(component));
  } else {
    return component;
  }
});

NestedSortableItem.displayName = 'NestedSortableItem';

NestedSortableItem.propTypes = {
  itemId: PropTypes.any,
  index: PropTypes.number,
  canSort: PropTypes.bool,
  onItemMove: PropTypes.func,
  onLevelChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
  isDragging: PropTypes.bool,
  isOver: PropTypes.bool,
  isOverCurrent: PropTypes.bool,
  connectDragSource: PropTypes.func,
  connectDragPreview: PropTypes.func,
  connectDropTarget: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  level: PropTypes.number,
  maxLevel: PropTypes.number,
  canIndent: PropTypes.bool,
  canOutdent: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
};

const NestedSortableItemDragSource = DragSource(getType, itemSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))(NestedSortableItem);

export default DropTarget(getType, itemTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(NestedSortableItemDragSource);

