import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import RedirectsWidget from 'components/common/RedirectsWidget';

const RedirectsField = ({ redirectConfigField, isEnabled }) => {
  const [value, setValue] = useState(JSON.parse(redirectConfigField.value));

  const inputVal = JSON.stringify(value);
  const handleChange = useCallback(value => setValue(value), []);

  let errors = {};
  if (redirectConfigField.errors && redirectConfigField.errors.length > 0) {
    errors = JSON.parse(redirectConfigField.errors[0]);
  }

  return (
    <>
      <RedirectsWidget
        isEnabled={isEnabled}
        initialValue={value}
        errors={errors}
        onChange={handleChange}
      />
      <input type="hidden" name={redirectConfigField.name} value={inputVal} />
    </>
  );
};


RedirectsField.propTypes = {
  redirectConfigField: PropTypes.object,
  isEnabled: PropTypes.bool,
};

export default RedirectsField;
