import React from 'react';
import PropTypes from 'prop-types';
import renderBlockErrors from './renderBlockErrors';
import Select from 'components/common/Select';

const Embed = ({ uid, errors, embedCode, size, onChange }) => {
  const handleEmbedCodeChange = evt => onChange({ embedCode: evt.target.value });

  const sizeOptions = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
    { value: 'full', label: 'Full width' },
  ];
  const sizeSelectVal = sizeOptions.find(opt => opt.value === size);
  const handleSizeChange = val => onChange({ size: val });

  const renderedErrors = renderBlockErrors(errors);

  return (
    <div className="content">
      <div className="mb-3">
        <div>Embed Code:</div>
        <div>
          <textarea
            className="form-input code-textarea"
            cols="30"
            rows="8"
            value={embedCode}
            onChange={handleEmbedCodeChange}
          />
          {renderedErrors.embedCode}
        </div>
      </div>

      <div className="mb-3">
        <div>Size:</div>
        <div className="mb-1">
          <Select
            value={sizeSelectVal}
            options={sizeOptions}
            onChange={handleSizeChange}
          />
          {renderedErrors.size}
        </div>
      </div>
    </div>
  );
};

Embed.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  embedCode: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large', 'full']),
  onChange: PropTypes.func,
};

export default Embed;
