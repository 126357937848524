import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import { formatters } from 'utils';
import ReactPlayer from 'react-player';
import ResizeDetector from 'react-resize-detector';
import Icon from 'components/common/Icon';
import Marker from './Marker';

const Timeline = ({ mediaType, mediaUrl, markers, playerPortalEl, onAddClick, onTimeChange, onMarkerMove }) => {
  const playerRef = useRef();
  const playerContainerRef = useRef();

  const [duration, setDuration] = useState(null);
  const [elapsed, setElapsed] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const [barWidth, setBarWidth] = useState(null);
  const elapsedWidth = barWidth && elapsed && duration ? elapsed / duration * barWidth : 0;

  const handleDuration = seconds => setDuration(Math.floor(seconds));
  const handleProgress = ({ playedSeconds }) => {
    if (isPlaying) setElapsed(playedSeconds);
  };
  const handleSeek = seconds => setElapsed(seconds);
  const handleEnded = () => {
    setIsPlaying(false);
    setElapsed(duration);
  };

  useEffect(() => onTimeChange(Math.round(elapsed * 10) / 10), [elapsed]);

  const seekToPos = xOffset => {
    if (!barWidth || !duration || !playerRef.current) return null;
    const seconds = xOffset / barWidth * duration;
    playerRef.current.seekTo(seconds, 'seconds');
  };

  const handleBarClick = evt => {
    const xOffset = evt.pageX - evt.target.getBoundingClientRect().x;
    seekToPos(xOffset);
  };

  const handlePlayheadDrag = seekToPos;

  const renderedMarkers = !!barWidth && [...markers].sort((a, b) => a.isActive ? 1 : 0).map(({ itemId, startTime, isActive }) => {
    const left = startTime / duration * barWidth;

    const handleDrag = x => {
      let seconds = x / barWidth * duration;
      seconds = Math.round(seconds * 10) / 10;
      onMarkerMove(itemId, seconds);
    };

    return (
      <Marker
        key={itemId}
        className={classNames('annotation-builder-marker', isActive && 'active')}
        left={left}
        maxLeft={barWidth}
        draggable={isActive}
        onDrag={handleDrag}
      />
    );
  });

  const portalEl = playerPortalEl || playerContainerRef.current;
  const player = portalEl && createPortal(
    <ReactPlayer
      url={mediaUrl}
      width={350}
      height={200}
      controls={false}
      playing={isPlaying}
      progressInterval={200}
      onDuration={handleDuration}
      onPlay={() => setIsPlaying(true)}
      onPause={() => setIsPlaying(false)}
      onProgress={handleProgress}
      onSeek={handleSeek}
      onEnded={handleEnded}
      ref={playerRef}
    />,
    portalEl,
  );

  const elapsedText = formatters.seconds(Math.floor(elapsed));
  const durationText = duration === null ? '--:--' : formatters.seconds(Math.floor(duration));

  return (
    <div className="title-builder-item disabled annotation-builder-timeline-container">
      <div className="annotation-builder-timeline">
        <div className="play-pause" onClick={() => setIsPlaying(!isPlaying)}>
          <Icon name={isPlaying ? 'pause' : 'play_arrow'} fill />
        </div>
        <div className="media-duration" onClick={handleBarClick}>
          <ResizeDetector handleWidth onResize={width => setBarWidth(width)} />
          <div className="media-elapsed" style={{ width: elapsedWidth }} />
          {renderedMarkers}
          <Marker
            className="annotation-builder-playhead"
            left={elapsedWidth}
            maxLeft={barWidth}
            onDrag={handlePlayheadDrag}
          />
        </div>
      </div>
      <div className="annotation-builder-timeline-timecode">
        <span className="elapsed">{elapsedText}</span>
        <span className="duration"> / {durationText}</span>
      </div>
      <button type="button" className="btn btn-primary" onClick={onAddClick}>Add Annotation</button>

      <div style={{ display: 'none' }} ref={playerContainerRef} />
      {player}
    </div>
  );
};

Timeline.propTypes = {
  mediaType: PropTypes.oneOf(['audio', 'video']),
  mediaUrl: PropTypes.string,
  markers: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    startTime: PropTypes.number,
    isActive: PropTypes.bool,
  })),
  playerPortalEl: PropTypes.instanceOf(Element),
  onAddClick: PropTypes.func,
  onTimeChange: PropTypes.func,
  onMarkerMove: PropTypes.func,
};

export default Timeline;
