import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import { v4 as uuid4 } from 'uuid';
import withDragDropContext from 'components/common/withDragDropContext';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { ManagementForm } from 'components/common/inlines';
import SortableItem from 'components/common/SortableItem';
import Item from './Item';


class InlineFormset extends Component {
  constructor (props) {
    super(props);
    this.state = {
      itemsById: {},
      itemsOrdered: [],
      hasError: false,
    };

    this.nextItemIndex = 0;
    this.handleItemDeleteClick = this.handleItemDeleteClick.bind(this);
    this.handleItemFormClose = this.handleItemFormClose.bind(this);
    this.handleAddItemClick = this.handleAddItemClick.bind(this);
    this.handleItemMove = this.handleItemMove.bind(this);
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { isBound, isValid } = camelize(nextProps.formsetData);
    return { hasError: isBound && !isValid };
  }

  componentDidMount () {
    this.initItems();
  }

  handleItemDeleteClick (itemId) {
    const { itemsById } = this.state;
    const item = this.getItem(itemId);
    this.setState({
      itemsById: {
        ...itemsById,
        [itemId]: {
          ...item,
          shouldDelete: !item.shouldDelete,
        },
      },
    });
  }

  handleItemFormClose (opts) {
    if (opts && opts.addAnother) {
      this.initNewItem();
    }
  }

  handleAddItemClick (e) {
    e.preventDefault();
    this.initNewItem();
  }

  handleItemMove (sourceId, targetId, insertPos) {
    const { itemsOrdered } = this.state;
    const targetIndex = itemsOrdered.indexOf(targetId);
    const toIndex = insertPos === 'before' ? targetIndex : targetIndex + 1;
    const newValue = itemsOrdered.filter(id => id !== sourceId);
    newValue.splice(toIndex, 0, sourceId);
    this.setState({ itemsOrdered: newValue });
  }

  initNewItem () {
    const { prefix } = this.props.formsetData;
    const { itemsById, itemsOrdered } = this.state;
    const itemId = uuid4();
    const newItem = {
      itemId,
      itemIndex: this.nextItemIndex,
      prefix: `${prefix}-${this.nextItemIndex}`,
      errors: {},
      isBound: false,
      filePreviews: {},
    };

    this.nextItemIndex += 1;
    this.setState({
      itemsById: {
        ...itemsById,
        [itemId]: newItem,
      },
      itemsOrdered: [
        ...itemsOrdered,
        itemId,
      ],
    });
    return newItem;
  }

  initItems () {
    // Hydrate item list from initial formset data
    const { forms, prefix } = camelize(this.props.formsetData);
    const itemsById = {};
    const itemsOrdered = [];
    forms.forEach(form => {
      const itemId = uuid4();
      const fields = Object.keys(form.fields).reduce((result, key) => {
        if (key === 'DELETE') {
          result.shouldDelete = !!form.fields[key].value;
        } else {
          result[key] = form.fields[key].value;
        }
        return result;
      }, {});

      const filePreviews = Object.entries(form.fields).reduce((result, [key, value]) => {
        const { fileUrl } = value;
        if (fileUrl) result[key] = fileUrl;
        return result;
      }, {});

      const item = {
        itemId,
        itemIndex: this.nextItemIndex,
        prefix: `${prefix}-${this.nextItemIndex}`,
        errors: form.errors,
        isBound: form.isBound,
        filePreviews,
        ...fields,
      };

      itemsById[itemId] = item;
      itemsOrdered.push(itemId);
      this.nextItemIndex += 1;
    });
    this.setState({ itemsById, itemsOrdered });
  }

  getItem (itemId) {
    const { itemsById } = this.state;
    return itemsById[itemId];
  }

  renderItems () {
    const { prefix } = camelize(this.props.formsetData);
    const { itemsOrdered } = this.state;
    return itemsOrdered.map((itemId, idx) => {
      const item = this.getItem(itemId);
      return (
        <SortableItem key={itemId} itemId={itemId} type={prefix} className="mb-4" onItemMove={this.handleItemMove}>
          <Item
            parentFieldName={this.props.parentFieldName}
            parentId={this.props.parentId}
            orderingFieldName={this.props.orderingFieldName}
            orderingIndex={idx}
            idFieldName={this.props.idFieldName}
            labelFieldName={this.props.labelFieldName}
            editableFieldNames={this.props.editableFieldNames}
            itemTypeLabel={this.props.itemTypeLabel}
            onDeleteClick={this.handleItemDeleteClick}
            onFormClose={this.handleItemFormClose}
            formComponent={this.props.disabled ? null : this.props.formComponent}
            containerEl={this.containerRef}
            {...item}
          />
        </SortableItem>
      );
    });
  }

  render () {
    const { itemTypeLabel, disabled } = this.props;
    const { prefix, managementForm } = camelize(this.props.formsetData);
    const { itemsOrdered, hasError } = this.state;
    const formCount = itemsOrdered.length;
    // const itemCountLabel = itemsOrdered.length === 1 ? itemTypeLabel : itemTypeLabelPlural || `${itemTypeLabel}s`;
    const itemCountLabel = itemsOrdered.length === 1 ? 'Item' : 'Items';

    return (
      <ErrorBoundary>
        <div className={classNames('relation-field mt-1', disabled && 'disabled')} ref={node => this.containerRef = node}>
          <ManagementForm prefix={prefix} formData={managementForm} totalForms={formCount} />
          <div className="controls top">
            <div className="item-count">{itemsOrdered.length} {itemCountLabel}</div>
            <button type="button" className="btn btn-primary" onClick={this.handleAddItemClick}>Add {itemTypeLabel}</button>
          </div>
          {hasError ? <div className="error-list">Some of the items below contain errors.</div> : null}
          <div className="item-list">
            {this.renderItems()}
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}

InlineFormset.propTypes = {
  parentId: PropTypes.string,
  formsetData: PropTypes.object,
  disabled: PropTypes.bool,
  parentFieldName: PropTypes.string,
  orderingFieldName: PropTypes.string,
  idFieldName: PropTypes.string,
  labelFieldName: PropTypes.string.isRequired,
  editableFieldNames: PropTypes.arrayOf(PropTypes.string),
  itemTypeLabel: PropTypes.string,
  itemTypeLabelPlural: PropTypes.string,
  formComponent: PropTypes.func,
};

InlineFormset.defaultProps = {
  disabled: false,
  parentFieldName: 'parent',
  orderingFieldName: 'index',
  idFieldName: 'id',
  editableFieldNames: [],
  itemTypeLabel: 'item',
};

export default withDragDropContext(InlineFormset);
