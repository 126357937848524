import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AnimateHeight from 'react-animate-height';

const Collapsible = ({
  className,
  style,
  contentClassName,
  contentStyle,
  defaultOpen = false,
  labelOpen = 'Hide',
  labelClosed = 'Show',
  onToggle = () => {},
  children,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const toggle = () => setIsOpen(oldState => !oldState);
  useEffect(() => onToggle(isOpen), [isOpen]);
  const label = isOpen ? labelOpen : labelClosed;

  return (
    <div className={classNames('collapsible-container', className)} style={style}>
      <header className="collapsible-header" onClick={toggle}>
        <span>{label}</span>
        <span className="ml-2" style={{ transform: isOpen ? 'rotate(180deg) translateY(-3px)' : '' }}>▾</span>
      </header>
      <AnimateHeight duration={500} height={isOpen ? 'auto' : 0}>
        <section className={classNames('collapsible-content', contentClassName)} style={contentStyle}>
          {children}
        </section>
      </AnimateHeight>
    </div>
  );
};

Collapsible.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  contentClassName: PropTypes.string,
  contentStyle: PropTypes.object,
  defaultOpen: PropTypes.bool,
  labelOpen: PropTypes.string,
  labelClosed: PropTypes.string,
  onToggle: PropTypes.func,
  children: PropTypes.node,
};

export default Collapsible;
