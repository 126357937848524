import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/views/CustomTitleBuilder/CustomTitleField/FieldWrapper';
import Image from 'components/common/DjangoFormField/Image';


class MarkerTypeForm extends Component {
  constructor (props) {
    super(props);
    this.state = {};
    this.handleFormClose = this.handleFormClose.bind(this);
  }

  handleFormClose (e) {
    const { onClose } = this.props;
    e.preventDefault();
    e.stopPropagation();
    onClose();
  }

  getFieldErrors (fieldName) {
    const { errors } = this.props;
    if (!errors[fieldName]) {
      return [];
    }
    return errors[fieldName].map(err => err.message);
  }

  render () {
    const { prefix, filePreviews, name, width, height, icon, inLegend, onLabelChange } = this.props;

    const defaultValues = {
      name: name || '',
      width: typeof width === 'undefined' ? 20 : width,
      height: typeof height === 'undefined' ? 20 : height,
      inLegend: typeof inLegend === 'undefined' ? true : inLegend,
    };

    const iconErrors = this.getFieldErrors('icon');
    const iconUrl = filePreviews.icon;

    return (
      <div className="custom-title-builder-form">
        <FieldWrapper
          name={`${prefix}-name`}
          label="Name"
          errors={this.getFieldErrors('name')}
        >
          <input
            type="text"
            className="form-input"
            name={`${prefix}-name`}
            defaultValue={defaultValues.name}
            onChange={e => onLabelChange(e.target.value)}
          />
        </FieldWrapper>

        <FieldWrapper
          name={`${prefix}-width`}
          label="Width"
          errors={this.getFieldErrors('width')}
        >
          <input
            type="number"
            step="any"
            className="form-input"
            name={`${prefix}-width`}
            style={{ width: 'unset' }}
            defaultValue={defaultValues.width}
          />
        </FieldWrapper>

        <FieldWrapper
          name={`${prefix}-height`}
          label="Height"
          errors={this.getFieldErrors('height')}
        >
          <input
            type="number"
            step="any"
            className="form-input"
            name={`${prefix}-height`}
            style={{ width: 'unset' }}
            defaultValue={defaultValues.height}
          />
        </FieldWrapper>

        <label className="block-label" htmlFor={`${prefix}-icon`}>Icon</label>
        <Image
          name={`${prefix}-icon`}
          helpText="Select an icon image in .png, .jpg, or .gif format."
          initial={icon}
          errors={iconErrors}
          fileUrl={iconUrl}
        />

        <div className="form-group mb-4">
          <label className="form-checkbox my-0">
            <input type="checkbox" name={`${prefix}-in_legend`} defaultChecked={defaultValues.inLegend} />
            <i className="form-icon" /> Include in Legend
          </label>
        </div>

        <hr />
        <div>
          <button type="submit" className="btn btn-primary" onClick={this.handleFormClose}>Close</button>
        </div>
      </div>
    );
  }
}

MarkerTypeForm.propTypes = {
  prefix: PropTypes.string,
  errors: PropTypes.object,
  filePreviews: PropTypes.object,
  isBound: PropTypes.bool,
  name: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.string,
  inLegend: PropTypes.bool,
  onLabelChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default MarkerTypeForm;
