import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTitleBuilder } from 'components/views/titleBuilder';
import ErrorBoundary from 'components/common/ErrorBoundary';
import StyledScrollbar from 'components/common/StyledScrollbar';
import Item from './Item';
import Form from './Form';
import Timeline from './Timeline';
import itemFormDefaults from './itemFormDefaults';

const AnnotationBuilder = ({ formsetData, hasError, mediaType, mediaUrl }) => {
  const playerPortalRef = useRef();

  const opts = {
    formsetData,
    itemLabel: 'annotation',
    itemLabelPlural: 'annotations',
    hasError,
    isSortable: false,
    ItemComponent: Item,
    itemFormDefaults,
  };

  const {
    itemsById,
    setItemsById,
    setItemsOrdered,
    activeItem,
    handleItemAdd,
    handleFormChange,
    managementForm,
    messages,
    renderEmptyMessage,
    renderedItems,
  } = useTitleBuilder(opts);

  useEffect(() => updateSortOrder(), [itemsById]);

  const updateSortOrder = () => {
    const newItemsOrdered = Object.keys(itemsById).sort((a, b) => {
      return parseFloat(itemsById[a].startTime || 0) > parseFloat(itemsById[b].startTime || 0) ? 1 : -1;
    });
    setItemsOrdered(newItemsOrdered);
  };

  const [elapsedSeconds, setElapsedSeconds] = useState(0);
  const handleSyncStartTime = () => handleFormChange({ startTime: elapsedSeconds });
  const handleAddClick = () => handleItemAdd({ startTime: elapsedSeconds });

  const handleMarkerMove = (itemId, seconds) => {
    setItemsById(oldState => ({
      ...oldState,
      [itemId]: {
        ...oldState[itemId],
        startTime: seconds,
      },
    }));
  };

  const markerData = Object.values(itemsById).map(({ itemId, startTime }) => ({
    itemId,
    startTime: parseFloat(startTime),
    isActive: !!activeItem && activeItem.itemId === itemId,
  }));

  return (
    <div className={classNames('annotation-builder', mediaType)}>
      <Timeline
        mediaType={mediaType}
        mediaUrl={mediaUrl}
        markers={markerData}
        playerPortalEl={playerPortalRef.current}
        onAddClick={handleAddClick}
        onTimeChange={setElapsedSeconds}
        onMarkerMove={handleMarkerMove}
      />
      <div className="title-builder object-detail-panel-full-height">
        <ErrorBoundary>
          {managementForm}
          <div className="title-builder-items-container">
            {mediaType === 'video' && <div className="title-builder-item disabled annotation-builder-preview-player" ref={playerPortalRef} />}
            <div className="annotation-builder-items">
              <StyledScrollbar>
                {messages}
                {renderedItems}
              </StyledScrollbar>
            </div>
          </div>

          {activeItem
            ? <Form item={activeItem} onChange={handleFormChange} onSyncStartTime={handleSyncStartTime} />
            : renderEmptyMessage(handleAddClick)}
        </ErrorBoundary>
      </div>
    </div>
  );
};

AnnotationBuilder.propTypes = {
  formsetData: PropTypes.object,
  hasError: PropTypes.bool,
  mediaType: PropTypes.oneOf(['audio', 'video']),
  mediaUrl: PropTypes.string,
};

export default AnnotationBuilder;
