import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import withDragDropContext from 'components/common/withDragDropContext';
import ErrorBoundary from 'components/common/ErrorBoundary';
import CustomTitleField from './CustomTitleField';


class CustomTitleBuilder extends Component {
  constructor (props) {
    super(props);
    this.state = {
      formData: null,
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
  }

  componentDidMount () {
    this.initFormData();
  }

  initFormData () {
    // Load initial field values from props.initialData
    const { formSpec, initialData } = this.props;
    const formData = {};
    formSpec.forEach(({ name, default: defaultValue }) => {
      if (initialData.hasOwnProperty(name)) {
        formData[name] = initialData[name];
      } else {
        formData[name] = defaultValue;
      }
    });

    this.setState({ formData });
  }

  handleFieldChange (fieldName, value) {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        [fieldName]: value,
      },
    });
  }

  render () {
    const { formSpec, formErrors } = this.props;
    const { formData } = this.state;
    const nonFieldErrors = formErrors.non_field_errors;
    return (
      <div className="custom-title-builder">
        <ErrorBoundary>
          <div className="custom-title-builder-form">
            {nonFieldErrors && nonFieldErrors.length ? (
              <div className="error-list mb-4">
                {nonFieldErrors.map((msg, idx) => <p key={idx}>{msg}</p>)}
              </div>
            ) : null}
            {formData !== null ? camelize(formSpec).map(fieldSpec => {
              return (
                <CustomTitleField
                  key={fieldSpec.name}
                  {...fieldSpec}
                  value={formData[fieldSpec.name]}
                  errors={formErrors[fieldSpec.name]}
                  onChange={value => this.handleFieldChange(fieldSpec.name, value)}
                  nestingLevel={1}
                />
              );
            }) : null}
          </div>
          <input type="hidden" name="custom_title_data" value={JSON.stringify(formData)} />
        </ErrorBoundary>
      </div>
    );
  }
}

CustomTitleBuilder.propTypes = {
  formSpec: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialData: PropTypes.object,
  formErrors: PropTypes.object,
};

export default withDragDropContext(CustomTitleBuilder);
