import { RELEASE_PUBLISH } from '../actions/releaseDetailActions';


export const releaseDetail = (state = {}, action) => {
  switch (action.type) {
    case RELEASE_PUBLISH: {
      const { timestamp } = action.payload;
      return {
        ...state,
        timestamp,
      };
    }
    default:
      return state;
  }
};
