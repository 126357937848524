import React, { Component } from 'react';
import PropTypes from 'prop-types';
import parseISO from 'date-fns/parseISO';
import { formatters } from 'utils';
import Icon from 'components/common/Icon';

class HistoryItem extends Component {
  constructor (props) {
    super(props);
    this.state = { showLog: false };
    this.handleHeaderClick = this.handleHeaderClick.bind(this);
  }

  handleHeaderClick (e) {
    this.setState({ showLog: !this.state.showLog });
  }

  render () {
    const { result, log, date: dateString } = this.props.data;
    const { showLog } = this.state;
    const date = parseISO(dateString);
    const dateFormatted = formatters.shortDateTime(date);

    let statusText;
    let icon;
    switch (result) {
      case 'completed':
        statusText = 'Succeeded';
        icon = <Icon name="check_circle" fill size={20} className="text-success" />;
        break;
      case 'failed':
        statusText = 'Failed';
        icon = <Icon name="warning" fill size={20} className="text-error" />;
        break;
      case 'cancelled':
        statusText = 'Cancelled';
        icon = <Icon name="warning" fill size={20} className="text-error" />;
        break;
      default:
        break;
    }

    return (
      <div className="release-publish-history-item">
        <header onClick={this.handleHeaderClick}>
          <div className="release-publish-history-icon">{icon}</div>
          <h5 className="m-0">
            {dateFormatted} - {statusText}
          </h5>
          <span className="toggle-log text-meta">toggle log &nbsp;▾</span>
        </header>
        {showLog ? (
          <div className="release-publish-history-log mb-2">
            {log.map((line, idx) => <div key={`log-line-${idx}`}>{line}</div>)}
          </div>
        ) : null}
      </div>
    );
  }
}

HistoryItem.propTypes = {
  data: PropTypes.shape({
    result: PropTypes.oneOf(['completed', 'failed', 'cancelled']),
    date: PropTypes.string,
    log: PropTypes.arrayOf(PropTypes.string),
  }),
};

export default HistoryItem;
