import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'components/common/Select';
import MediaRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/MediaRelationField';
import TitleRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/TitleRelationField';
import renderBlockErrors from './renderBlockErrors';
import FormGroup from './FormGroup';


const SOURCE_OPTIONS = [
  { value: 'item', label: 'Current Item', helpText: 'Display metadata associated with the current active item' },
  { value: 'release', label: 'Release', helpText: 'Display metadata associated with the release' },
  { value: 'media', label: 'Media', helpText: 'Display metadata associated with a specific media object' },
  { value: 'title', label: 'Title', helpText: 'Display metadata associated with a specific title' },
];

const PublicationDates = ({
  uid,
  errors,
  allowSubItemSource = true,
  source,
  media,
  title,
  useSubItems,
  displayPublished,
  displayUpdated,
  publishedLabelText,
  updatedLabelText,
  dateFormat,
  displayTime,
  alignment,
  onPreviewTextUpdate,
  onChange,
}) => {
  const alignOptions = [
    { value: 'left', label: 'Left' },
    { value: 'center', label: 'Center' },
    { value: 'right', label: 'Right' },
  ];
  const alignSelectVal = alignOptions.find(opt => opt.value === alignment);
  const handleAlignChange = val => onChange({ alignment: val });

  const createHandler = name => val => onChange({ [name]: val });
  const handleSourceChange = val => {
    const updates = { source: val };
    if (val !== 'title') {
      updates.title = null;
    }
    if (val !== 'media') {
      updates.media = null;
    }
    onChange(updates);
  };

  const handleInputChange = e => {
    let { name, value, type, checked } = e.target;
    name = name.replace(`${uid}__`, '');
    if (type === 'checkbox') {
      value = checked;
    }
    onChange({ [name]: value });
  };

  const sourceSelectVal = SOURCE_OPTIONS.find(opt => opt.value === source);

  useEffect(() => {
    let text = '';
    if (sourceSelectVal) text += sourceSelectVal.label;
    onPreviewTextUpdate(text);
  }, [sourceSelectVal]);

  const renderedErrors = renderBlockErrors(errors);
  const showSubItemsField = allowSubItemSource && source === 'item';

  return (
    <div className="content">
      <FormGroup className="mb-3" label="Source:">
        <Select
          value={sourceSelectVal}
          options={SOURCE_OPTIONS}
          onChange={handleSourceChange}
        />
        {renderedErrors.source}
      </FormGroup>

      {source === 'media' && (
        <FormGroup className="mb-3">
          <MediaRelationField className="m-0" value={media} onChange={createHandler('media')} />
          {renderedErrors.media}
        </FormGroup>
      )}

      {source === 'title' && (
        <FormGroup className="mb-3">
          <TitleRelationField className="m-0" value={title} onChange={createHandler('title')} />
          {renderedErrors.title}
        </FormGroup>
      )}

      {showSubItemsField && (
        <FormGroup className="mb-1">
          <label className="form-checkbox">
            <input
              type="checkbox"
              name={`${uid}__useSubItems`}
              checked={useSubItems}
              onChange={handleInputChange}
            />
            <i className="form-icon" /> Include Sub-items
            <div className="text-hint">
              If this option is selected, metadata will be sourced from the current active item <em>within</em> the current title (for supported title types) — for example, an image within a slideshow title or a video within a playlist title. Deselect this option to source metadata only from the title itself.
            </div>
          </label>
          {renderedErrors.useSubItems}
        </FormGroup>
      )}

      <FormGroup alignItems="baseline">
        <label className="form-checkbox">
          <input name={`${uid}__displayPublished`} type="checkbox" checked={displayPublished} onChange={handleInputChange} />
          <i className="form-icon" /> Display published date
        </label>
        {renderedErrors.displayPublished}
      </FormGroup>

      <FormGroup className="mb-2" label="Label text:">
        <input
          type="text"
          name={`${uid}__publishedLabelText`}
          className="form-input"
          onChange={handleInputChange}
          value={publishedLabelText}
          disabled={!displayPublished}
        />
        {renderedErrors.publishedLabelText}
      </FormGroup>

      <FormGroup alignItems="baseline">
        <label className="form-checkbox">
          <input name={`${uid}__displayUpdated`} type="checkbox" checked={displayUpdated} onChange={handleInputChange} />
          <i className="form-icon" /> Display updated date
        </label>
        {renderedErrors.displayUpdated}
      </FormGroup>

      <FormGroup className="mb-4" label="Label text:">
        <input
          type="text"
          name={`${uid}__updatedLabelText`}
          className="form-input"
          onChange={handleInputChange}
          value={updatedLabelText}
          disabled={!displayUpdated}
        />
        {renderedErrors.updatedLabelText}
      </FormGroup>

      <FormGroup alignItems="baseline" label="Format:">
        <label className="form-radio m-0">
          <input
            type="radio"
            name={`${uid}__dateFormat`}
            value="short"
            checked={dateFormat === 'short'}
            onChange={handleInputChange}
          />
          <i className="form-icon" /> 1/1/2020{displayTime && ', 12:00 PM'}
        </label>
        <label className="form-radio m-0">
          <input
            type="radio"
            name={`${uid}__dateFormat`}
            value="medium"
            checked={dateFormat === 'medium'}
            onChange={handleInputChange}
          />
          <i className="form-icon" /> Jan 1, 2020{displayTime && ', 12:00 PM'}
        </label>
        <label className="form-radio m-0">
          <input
            type="radio"
            name={`${uid}__dateFormat`}
            value="long"
            checked={dateFormat === 'long'}
            onChange={handleInputChange}
          />
          <i className="form-icon" /> January 1st, 2020{displayTime && ' at 12:00 PM'}
        </label>
        {renderedErrors.dateFormat}
      </FormGroup>

      <FormGroup alignItems="baseline">
        <label className="form-checkbox mb-0">
          <input type="checkbox" name={`${uid}__displayTime`} checked={displayTime} onChange={handleInputChange} />
          <i className="form-icon" /> Include time
        </label>
        {renderedErrors.displayTime}
      </FormGroup>

      <FormGroup className="mb-3" label="Alignment:">
        <Select
          value={alignSelectVal}
          options={alignOptions}
          onChange={handleAlignChange}
        />
        {renderedErrors.alignment}
      </FormGroup>
    </div>
  );
};

PublicationDates.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  allowSubItemSource: PropTypes.bool,
  source: PropTypes.oneOf(['', ...SOURCE_OPTIONS.map(opt => opt.value)]),
  media: PropTypes.string,
  title: PropTypes.string,
  useSubItems: PropTypes.bool,
  displayPublished: PropTypes.bool,
  displayUpdated: PropTypes.bool,
  publishedLabelText: PropTypes.string,
  updatedLabelText: PropTypes.string,
  dateFormat: PropTypes.oneOf(['short', 'medium', 'long']),
  displayTime: PropTypes.bool,
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default PublicationDates;
