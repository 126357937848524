import React from 'react';
import PropTypes from 'prop-types';
import FontField from 'components/common/FontField';

const Font = ({ name, label, helpText, value, errors, className, style, onChange }) => {
  const handleChange = value => {
    const fakeEvt = {
      target: {
        name,
        type: 'text',
        value,
      },
    };
    onChange(fakeEvt);
  };

  return (
    <div className={`form-group ${errors.length ? 'has-error' : ''} ${className || ''}`} style={style}>
      {label && <label htmlFor={name}>{label}</label>}
      <FontField field={{ name, value }} googleKey={window.GOOGLE_KEY} onChange={handleChange} />
      {helpText && !errors.length ? <p className="form-input-hint mb-0">{helpText}</p> : null}
      {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
    </div>
  );
};

Font.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};

Font.defaultProps = {
  errors: [],
  onChange: () => null,
};

export default Font;
