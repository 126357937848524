import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import { usePortal } from 'hooks';
import DjangoFormField from 'components/common/DjangoFormField';
import RelatedLinksFormset from 'components/common/RelatedLinksFormset';
import SEOFields from 'components/common/SEOFields';
import { FieldHeader, PublishDates, Tags, SocialImage } from './fields';
import useMetadataPreset from './useMetadataPreset';

const ReleaseMetadataForm = ({ form, relatedLinksFormset, objectUid }) => {
  const sidebarPortal = usePortal(document.querySelector('.general-sidebar-portal'));
  const publishDatesPortal = usePortal(document.querySelector('.publish-sidebar-portal'));

  const { fields: origFields } = camelize(form);
  const {
    presetId,
    fields,
    formsets: presetFormsets,
    transformFormsetData,
    metadataPresetField,
    renderOverrideToggle,
    isOverrideActive,
    isFieldDisabled,
    processing,
  } = useMetadataPreset(origFields);

  const showPresetSocialImage = !!presetId && !isOverrideActive('social_image');
  const showPresetRelatedLinks = !!presetId && !isOverrideActive('related_links');
  const presetRelatedLinksFormset = transformFormsetData(relatedLinksFormset, presetFormsets.relatedLinks);

  const sidebarContent = (
    <>
      <div className="form-group mb-4">
        <FieldHeader field={fields.notes} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.notes}
          label=""
          helpText="Internal/administrative notes"
          disabled={isFieldDisabled(fields.notes)}
        />
      </div>
      <hr className="mb-6" />
      <Tags
        field={fields.tags}
        className="mb-6"
        renderOverrideToggle={renderOverrideToggle}
        isFieldDisabled={isFieldDisabled}
      />

      {!!fields.siteUrl && (
        <DjangoFormField {...fields.siteUrl} className="mb-4" labelClass="h5" placeholder="http://" />
      )}

      <div className="form-group mb-4">
        <div className="flex-spread mb-1">
          <h5 className="m-0">{fields.analyticsProvider.label}</h5>
          {renderOverrideToggle([fields.analyticsProvider.name, fields.analyticsId.name])}
        </div>
        <DjangoFormField
          {...fields.analyticsProvider}
          label=""
          disabled={isFieldDisabled(fields.analyticsProvider)}
        />
      </div>

      <DjangoFormField
        {...fields.analyticsId}
        className="mb-4"
        labelClass="h5"
        disabled={isFieldDisabled(fields.analyticsId)}
      />
      <div className="flex-spread mb-1">
        <h4 className="alt-head m-0">Publisher Details</h4>
        {renderOverrideToggle(['publisher_name', 'publisher_url', 'publisher_twitter', 'publisher_facebook'])}
      </div>
      <hr className="mt-0 mb-2" />
      <div className="text-hint mb-4">
        Enter information about the publishing entity in the fields below. This appears in search results and when users share your content on social media.
      </div>

      <DjangoFormField
        {...fields.publisherName}
        className="mb-4"
        labelClass="h5"
        disabled={isFieldDisabled(fields.publisherName)}
      />

      <DjangoFormField
        {...fields.publisherUrl}
        className="mb-4"
        labelClass="h5"
        placeholder="https://"
        disabled={isFieldDisabled(fields.publisherUrl)}
      />

      <DjangoFormField
        {...fields.publisherTwitter}
        className="mb-4"
        labelClass="h5"
        addon="@"
        disabled={isFieldDisabled(fields.publisherTwitter)}
      />

      <DjangoFormField
        {...fields.publisherFacebook}
        className="mb-4"
        labelClass="h5"
        disabled={isFieldDisabled(fields.publisherFacebook)}
      />
    </>
  );

  const publishSidebarContent = (
    <>
      <PublishDates
        fields={fields}
        renderOverrideToggle={renderOverrideToggle}
        isFieldDisabled={isFieldDisabled}
      />
      <hr className="mb-4" />
      <div className="form-group mb-4">
        <FieldHeader field={fields.internalDomains} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.internalDomains}
          label=""
          placeholder="domain.com"
          disabled={isFieldDisabled(fields.internalDomains)}
        />
      </div>
    </>
  );

  return (
    <>
      {metadataPresetField}
      <hr className="mb-4" />

      <div className="form-group mb-6">
        <FieldHeader field={fields.shortDescription} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.shortDescription}
          allowFormatting
          label=""
          disabled={isFieldDisabled(fields.shortDescription)}
        />
      </div>

      <div className="form-group mb-6">
        <FieldHeader field={fields.longDescription} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.longDescription}
          allowFormatting
          label=""
          editorSize="large"
          disabled={isFieldDisabled(fields.longDescription)}
        />
      </div>

      <SEOFields
        titleField={fields.seoTitle}
        descriptionField={fields.seoDescription}
        renderOverrideToggle={renderOverrideToggle}
        isFieldDisabled={isFieldDisabled}
      />

      {!!fields.shareUrl && (
        <DjangoFormField {...fields.shareUrl} className="mb-4" labelClass="h5" placeholder="http://" />
      )}

      <div className="form-group mb-6">
        <div className="flex-spread mb-2">
          <h5 className="m-0">Social media image</h5>
          {renderOverrideToggle('social_image')}
        </div>
        <div className={classNames({ 'd-none': showPresetSocialImage })}>
          <SocialImage key="local" field={fields.socialImage} />
        </div>
        {showPresetSocialImage && (
          <SocialImage
            key={`preset-${processing}`}
            field={fields.socialImage}
            className="mb-6"
            renderOverrideToggle={renderOverrideToggle}
            disabled
          />
        )}
      </div>

      <div className="form-group mb-6">
        <div className="flex-spread mb-2">
          <h5 className="m-0">Related Links</h5>
          {renderOverrideToggle('related_links')}
        </div>
        <div className={classNames({ 'd-none': showPresetRelatedLinks })}>
          <RelatedLinksFormset key="local" parentId={objectUid} formsetData={relatedLinksFormset} />
        </div>
        {showPresetRelatedLinks && (
          <RelatedLinksFormset key={`preset-${processing}`} parentId={objectUid} disabled formsetData={presetRelatedLinksFormset} />
        )}
      </div>

      {sidebarPortal(sidebarContent)}
      {publishDatesPortal(publishSidebarContent)}
    </>
  );
};

ReleaseMetadataForm.propTypes = {
  form: PropTypes.object,
  relatedLinksFormset: PropTypes.object,
  objectUid: PropTypes.string,
};

export default ReleaseMetadataForm;
