import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isUrl from 'is-url';
import withCSRF from 'components/common/withCSRF';


class Url extends Component {
  constructor (props) {
    super(props);
    this.state = {
      url: '',
      processing: false,
      invalidUrl: false,
      error: null,
      xhr: null,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleImportComplete = this.handleImportComplete.bind(this);
    this.handleImportError = this.handleImportError.bind(this);
    this.handleImportCancel = this.handleImportCancel.bind(this);
    this.doImport = this.doImport.bind(this);
  }

  componentWillUnmount () {
    this.handleImportCancel();
  }

  handleInputChange (e) {
    this.setState({ url: e.target.value });
  }

  handleFormSubmit (e) {
    const { url } = this.state;
    e.preventDefault();
    if (!isUrl(url)) {
      this.setState({ invalidUrl: true });
    } else {
      this.setState({ invalidUrl: false, processing: true }, this.doImport);
    }
  }

  handleImportComplete (e) {
    const { onComplete } = this.props;
    const { status, statusText, responseText } = e.target;

    if (status === 400 && responseText) {
      const { detail } = JSON.parse(responseText);
      this.setState({
        processing: false,
        xhr: null,
        error: detail,
      });
    } else if (status !== 201) {
      this.setState({
        processing: false,
        xhr: null,
        error: statusText,
      });
    } else if (onComplete) {
      onComplete();
    }
    // TODO send message
  }

  handleImportError (e) {
    // TODO send message
    this.setState({
      processing: false,
      xhr: null,
      error: 'Network error',
    });
  }

  handleImportCancel () {
    const { xhr } = this.state;
    xhr && xhr.abort();
    this.setState({ xhr: null });
  }

  doImport () {
    const { csrfToken, endpoint } = this.props;
    const { url } = this.state;
    const body = JSON.stringify({ url });
    const xhr = new XMLHttpRequest();
    xhr.open('POST', endpoint);
    xhr.withCredentials = true;
    xhr.setRequestHeader('X-CSRFToken', csrfToken);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = this.handleImportComplete;
    xhr.onerror = this.handleImportError;
    xhr.send(body);
    this.setState({ xhr });
  }

  render () {
    const { url, processing, error, invalidUrl } = this.state;
    const invalidMsg = 'Enter a valid URL, beginning with “http://” or “https://”';

    return error ? (
      <div className="media-import-url">
        <div className="toast toast-error">Import failed: {error}</div>
      </div>
    ) : (
      <div className="media-import-url">
        <form onSubmit={this.handleFormSubmit}>
          <div className={`form-group ${invalidUrl ? 'has-error' : ''}`}>
            <div className="input-group">
              <div className="has-icon-right" style={{ flex: 1, display: 'flex' }}>
                <input
                  type="text"
                  className="form-input"
                  placeholder="http://"
                  readOnly={processing}
                  onChange={this.handleInputChange}
                  value={url}
                  autoFocus
                />
                {processing ? <i className="form-icon loading" /> : null}
              </div>
              <button type="submit" className="btn btn-primary input-group-btn">Submit</button>
            </div>
            {invalidUrl && <div className="form-input-hint">{invalidMsg}</div>}
          </div>
          <div>Enter the full URL to a media file you wish to import.</div>
        </form>
      </div>
    );
  }
}

Url.propTypes = {
  csrfToken: PropTypes.string,
  endpoint: PropTypes.string,
  onComplete: PropTypes.func,
};

export default withCSRF(Url);
