import React from 'react';
import PropTypes from 'prop-types';
import { InlineFormset } from 'components/common/inlines';
import MarkerTypeForm from './MarkerTypeForm';


const MarkerTypesFormset = ({ parentId, formsetData }) => (
  <InlineFormset
    parentId={parentId}
    formsetData={formsetData}
    labelFieldName="name"
    itemTypeLabel="Marker Type"
    formComponent={MarkerTypeForm}
  />
);

MarkerTypesFormset.propTypes = {
  parentId: PropTypes.string,
  formsetData: PropTypes.object,
};

export default MarkerTypesFormset;
