import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';


class IntegrationForm extends Component {
  constructor (props) {
    super(props);
    this.state = {};

    this.defaultsForType = {
      text: '',
      password: '',
      checkbox: false,
      switch: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  componentDidMount () {
    const { fieldConfig, data } = this.props;
    const fieldValues = fieldConfig.reduce((result, field) => {
      if (data.hasOwnProperty(field.name)) {
        result[field.name] = data[field.name];
      } else if (field.hasOwnProperty('defaultValue')) {
        result[field.name] = field.defaultValue;
      } else {
        result[field.name] = this.defaultsForType[field.type];
      }
      return result;
    }, {});
    this.setState({ ...fieldValues });
  }

  componentDidUpdate (prevProps, prevState) {
    if (!isEqual(this.state, prevState)) {
      this.handleFormChange();
    }
  }

  handleInputChange (e) {
    const { name, type, checked, value: rawValue } = e.target;
    const value = type === 'checkbox' ? checked : rawValue;
    this.setState({ [name]: value });
  }

  handleFormChange () {
    const { onChange } = this.props;
    onChange(this.state);
  }

  renderField ({ name, label, helpText, type, defaultValue }) {
    const fieldValue = this.state[name];
    // Don't render before the value is initialized
    if (typeof fieldValue === 'undefined') {
      return null;
    }
    const fieldErrors = this.props.errors[name];
    const renderedErrors = !!fieldErrors && fieldErrors.length > 0 && fieldErrors.map((errorText, idx) => (
      <p key={idx} className="form-input-hint mb-0">{errorText}</p>
    ));
    const renderedHelpText = !!helpText && <p className="form-input-hint mb-0" dangerouslySetInnerHTML={{ __html: helpText }} />;

    let renderedField;
    switch (type) {
      case 'text':
        // fall through
      case 'password':
        renderedField = (
          <>
            <label className="strong" dangerouslySetInnerHTML={{ __html: label }} />
            <input name={name} type={type} className="form-input" value={fieldValue} onChange={this.handleInputChange} />
          </>
        );
        break;
      case 'checkbox':
        // fall through
      case 'switch':
        renderedField = (
          <label className={`form-${type}`}>
            <input type="checkbox" name={name} checked={fieldValue} onChange={this.handleInputChange} />
            <i className="form-icon" /> <strong>{label}</strong>
          </label>
        );
        break;
      default:
        break;
    }

    return (
      <div key={name} className={classNames('form-group', 'mb-3', !!renderedErrors && 'has-error')}>
        {renderedField}
        {renderedErrors || renderedHelpText}
      </div>
    );
  }

  render () {
    const { fieldConfig, errors: { nonFieldErrors } } = this.props;
    return (
      <div>
        {nonFieldErrors ? (
          <div className="error-list mb-3">{nonFieldErrors.map((message, i) => <p key={i}>{message}</p>)}</div>
        ) : null}
        {fieldConfig.map(f => this.renderField(f))}
      </div>
    );
  }
}

IntegrationForm.propTypes = {
  fieldConfig: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'checkbox', 'switch']).isRequired,
    defaultValue: PropTypes.bool,
  })),
  data: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

IntegrationForm.defaultProps = {
  errors: {},
  data: {},
};

export default IntegrationForm;
