export default {
  linkType: 'media',
  media: null,
  title: null,
  external: false,
  linkUrl: '',
  label: '',
  name: '',
  nameSource: 'item_name',
  description: '',
  descriptionSource: 'item_long_desc',
  backgroundImage: null,
  backgroundVideo: null,
};
