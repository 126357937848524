import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { formatters } from 'utils';
import Slider from 'rc-slider';


const Range = Slider.Range;

class RangeFilter extends Component {
  constructor (props) {
    super(props);
    this.state = {
      value: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleCommit = this.handleCommit.bind(this);
    this.getCurrentValues = this.getCurrentValues.bind(this);
  }

  componentDidMount () {
    this.setState({ value: this.getCurrentValues() });
  }

  componentDidUpdate (prevProps, prevState) {
    const { activeValue } = this.props;
    if (!isEqual(activeValue, prevProps.activeValue)) {
      this.setState({ value: this.getCurrentValues() });
    }
  }

  handleChange (value) {
    this.setState({ value });
  }

  handleCommit (value) {
    const { params, onChange, minValue, maxValue } = this.props;
    const [curLower, curUpper] = this.getCurrentValues();
    const [newLower, newUpper] = value;
    if (newLower !== curLower) {
      onChange(params.lowerBound, newLower === minValue ? null : newLower);
    } else if (newUpper !== curUpper) {
      onChange(params.upperBound, newUpper === maxValue ? null : newUpper);
    }
  }

  getCurrentValues () {
    const { minValue, maxValue, activeValue } = this.props;
    return [
      parseInt(activeValue[0], 10) || minValue,
      parseInt(activeValue[1], 10) || maxValue,
    ];
  }

  render () {
    const { numberFormat, minValue, maxValue, activeValue } = this.props;
    const { value } = this.state;
    const format = numberFormat in formatters ? formatters[numberFormat] : val => val;

    if (!value.length) {
      return null;
    }

    return (
      <div className="range-filter">
        <div className="range-filter-labels">
          <span>{format(value[0])}</span>
          <span>{value[1] === maxValue ? 'ANY' : format(value[1])}</span>
        </div>
        <div className="range-filter-slider">
          <Range
            className={activeValue.length ? 'active' : null}
            allowCross={false}
            min={minValue}
            max={maxValue}
            value={value}
            onChange={this.handleChange}
            onAfterChange={this.handleCommit}
          />
        </div>
      </div>
    );
  }
}

RangeFilter.propTypes = {
  params: PropTypes.shape({
    lowerBound: PropTypes.string,
    upperBound: PropTypes.string,
  }),
  numberFormat: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  activeValue: PropTypes.array,
  onChange: PropTypes.func,
};

RangeFilter.defaultProps = {
  activeValue: [],
};

export default RangeFilter;
