import { useState, useCallback } from 'react';
import debounce from 'lodash/debounce';

export default function useSortableItems () {
  const [itemsOrdered, setItemsOrdered] = useState([]);

  let handleItemMove = (sourceId, targetId, insertPos) => {
    const toIndex = insertPos === 'before' ? itemsOrdered.indexOf(targetId) : itemsOrdered.indexOf(targetId) + 1;
    const newValue = itemsOrdered.filter(uid => uid !== sourceId);
    newValue.splice(toIndex, 0, sourceId);
    setItemsOrdered(newValue);
  };
  handleItemMove = useCallback(debounce(handleItemMove, 100), [itemsOrdered]);

  return { itemsOrdered, setItemsOrdered, handleItemMove };
}
