import React, { Component } from 'react';
import Modal from 'components/common/Modal';


class FacebookReleaseRemove extends Component {
  constructor (props) {
    super(props);
    this.state = {
      modalVisible: false,
    };

    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.handleModalRequestClose = this.handleModalRequestClose.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  handleLinkClick (e) {
    e.preventDefault();
    this.setState({ modalVisible: true });
  }

  handleModalRequestClose () {
    this.setState({ modalVisible: false });
  }

  handleCancelClick (e) {
    e.preventDefault();
    this.handleModalRequestClose();
  }

  getParentNode () {
    return document.querySelector('.object-detail-form');
  }

  render () {
    const { modalVisible } = this.state;

    return (
      <>
        <div className="text-center mb-4">
          <a className="btn btn-lg btn-error-outline" href="#delete" onClick={this.handleLinkClick}>Remove from Facebook</a>
        </div>
        <Modal
          title="Are You Sure?"
          style={{ content: { width: 660 } }}
          isOpen={modalVisible}
          onRequestClose={this.handleModalRequestClose}
          parentSelector={this.getParentNode}
        >
          <p>This item will be removed from Facebook.</p>
          <div className="modal-action-buttons">
            <button className="btn" onClick={this.handleCancelClick}>Cancel</button>
            <button className="btn btn-error" type="submit" action="." name="_delete_post">Remove</button>
          </div>
        </Modal>
      </>
    );
  }
}

export default FacebookReleaseRemove;
