import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/common/Icon';

const Item = ({ itemKey, itemVal, keyLabel, valLabel, keyChoices, errors, onChange, onDeleteClick }) => {
  const handleKeyChange = evt => onChange({ itemKey: evt.target.value, itemVal });
  const handleValChange = evt => onChange({ itemKey, itemVal: evt.target.value });

  const hasError = errors && Object.keys(errors).length > 0;

  let keyErrors, valErrors;
  if (errors && errors.length > 0) {
    [keyErrors, valErrors] = errors.map(val => (
      val
        ? val.map((msg, idx) => <div key={idx} className="text-sm text-error">{msg}</div>)
        : <div className="text-sm">&nbsp;</div>
    ));
  }

  return (
    <div className={classNames('item redirects-widget-item', hasError && 'error')}>
      <div>
        <div className="input-group">
          <span className="input-group-addon addon-sm">{keyLabel}</span>
          {keyChoices ? (
            <select className="form-select select-sm" value={itemKey} onChange={handleKeyChange}>
              {keyChoices.map(([val, label]) => <option key={val} value={val}>{label}</option>)}
            </select>
          ) : (
            <input
              type="text"
              className="form-input input-sm"
              value={itemKey}
              onChange={handleKeyChange}
            />
          )}
        </div>
        {keyErrors}
      </div>

      <div>
        <div className="input-group">
          <span className="input-group-addon addon-sm">{valLabel}</span>
          <input
            type="text"
            className="form-input input-sm"
            value={itemVal}
            onChange={handleValChange}
          />
        </div>
        {valErrors}
      </div>

      <span
        className="btn-delete tooltip tooltip-left mx-1"
        onClick={onDeleteClick}
        data-tooltip="Delete"
      >
        <Icon name="delete" fill />
      </span>

      <span className="btn-move ml-1" title="Drag to sort">
        <Icon name="menu" />
      </span>
    </div>
  );
};

Item.propTypes = {
  itemKey: PropTypes.string.isRequired,
  itemVal: PropTypes.string.isRequired,
  keyLabel: PropTypes.string.isRequired,
  valLabel: PropTypes.string.isRequired,
  keyChoices: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  onChange: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default Item;
