import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import Icon from 'components/common/Icon';
import Modal from 'components/common/Modal';
import LoadingOverlay from 'components/common/LoadingOverlay';
import EditIntegration from './EditIntegration';


class IntegrationItem extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: {},
      editing: false,
      deleteRequested: false,
      showDebugModal: false,
    };

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleEditsSaved = this.handleEditsSaved.bind(this);
    this.handleDebugClick = this.handleDebugClick.bind(this);
    this.handleModalRequestClose = this.handleModalRequestClose.bind(this);
    this.handleEditsCancelled = this.handleEditsCancelled.bind(this);
    this.handleDeleteRequested = this.handleDeleteRequested.bind(this);
    this.handleDeleteCompleted = this.handleDeleteCompleted.bind(this);
    this.handleDeleteCancelled = this.handleDeleteCancelled.bind(this);
  }

  componentDidMount () {
    this.fetchData();
  }

  handleEditClick (e) {
    e.preventDefault();
    this.setState({ editing: true });
  }

  handleEditsSaved () {
    const { onChange } = this.props;
    this.setState({ editing: false });
    this.fetchData();
    onChange();
  }

  handleDebugClick (e) {
    e.preventDefault();
    this.setState({ showDebugModal: true });
  }

  handleModalRequestClose () {
    this.setState({ editing: false, deleteRequested: false, showDebugModal: false });
  }

  handleEditsCancelled () {
    this.setState({ editing: false });
  }

  handleDeleteRequested () {
    this.setState({ deleteRequested: true });
  }

  handleDeleteCompleted () {
    this.props.onChange();
  }

  handleDeleteCancelled () {
    this.setState({ deleteRequested: false });
  }

  fetchData () {
    const { id } = this.props;
    const url = urlJoin(urls.integrationBase, id, '?format=json');
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({ data, processing: false });
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  renderCapabilityIcons () {
    const { capabilities, healthCheck } = this.state.data;
    if (!capabilities) {
      return null;
    }

    return (
      <div className="integrations-item-status">
        {healthCheck.passed === false && <Icon name="warning" size={20} className="error" />}
        {capabilities.import.supported && <Icon name="download" size={20} className={capabilities.import.enabled ? 'enabled' : null} />}
        {capabilities.release.supported && <Icon name="upload" size={20} className={capabilities.release.enabled ? 'enabled' : null} />}
      </div>
    );
  }

  renderEditForm () {
    const { id, serviceConfig } = this.props;
    const { healthCheck } = this.state.data;
    return serviceConfig && (
      <EditIntegration
        id={id}
        formFields={serviceConfig.formFields}
        healthCheckErrors={healthCheck && healthCheck.errors}
        onSave={this.handleEditsSaved}
        onCancel={this.handleEditsCancelled}
        onRequestDelete={this.handleDeleteRequested}
        onCancelDelete={this.handleDeleteCancelled}
        onDelete={this.handleDeleteCompleted}
      />
    );
  }

  render () {
    const { editable } = this.props;
    const { processing, editing, deleteRequested, showDebugModal } = this.state;
    const { serviceDisplayName, icon, details, capabilities, debug } = this.state.data;
    const modalTitle = deleteRequested ? 'Are you sure?' : 'Edit Integration';
    const supportedCapabilities = capabilities && Object.values(capabilities).filter(v => v.supported);
    const isDisabled = capabilities && supportedCapabilities.length > 0 && supportedCapabilities.every(v => !v.enabled);
    let cardTitle = <span>&nbsp;</span>;
    if (serviceDisplayName) {
      cardTitle = isDisabled ? <span className="text-gray">{serviceDisplayName} (disabled)</span> : serviceDisplayName;
    }
    return (
      <div className="integrations-item card-item">
        <div className="integrations-item-icon">
          {icon ? <img src={icon} alt="Icon" /> : null}
        </div>
        <div className="integrations-item-body">
          <header>
            <strong>{cardTitle}</strong>
            {this.renderCapabilityIcons()}
          </header>
          <section className="integrations-item-details">
            {!!details && details.map(([label, value]) => <div key={label}><strong>{label}:</strong> {value}</div>)}
            {!!debug && <div><a href="#debug" onClick={this.handleDebugClick}><strong>Debug</strong></a></div>}
          </section>
          {editable && (
            <button
              className="btn btn-primary action-edit tooltip tooltip-left"
              data-tooltip="Edit Configuration"
              onClick={this.handleEditClick}
            >
              <Icon name="edit" />
            </button>
          )}
        </div>
        <LoadingOverlay show={processing} />

        {editable && (
          <Modal
            title={modalTitle}
            style={{ content: { width: 800 } }}
            isOpen={editing}
            onRequestClose={this.handleModalRequestClose}
          >
            {editing ? this.renderEditForm() : null}
          </Modal>
        )}

        {!!debug && (
          <Modal
            title="Debug"
            style={{ content: { width: 1000 } }}
            isOpen={showDebugModal}
            onRequestClose={this.handleModalRequestClose}
          >
            <pre>{JSON.stringify(debug, null, 2)}</pre>
          </Modal>
        )}
      </div>
    );
  }
}

IntegrationItem.propTypes = {
  id: PropTypes.string,
  serviceConfig: PropTypes.object,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
};

IntegrationItem.defaultProps = {
  editable: true,
};

export default IntegrationItem;
