import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { urls } from 'app-constants';
import { formatters } from 'utils';
import { useItemData } from 'hooks';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';
import MediaTypeIcon from 'components/common/MediaTypeIcon';
import TruncateText from 'components/common/TruncateText';
import { DeleteOverlay } from 'components/common/inlines';


const Item = props => {
  let isItemLink = false;
  let linkedItemType;
  if (['media', 'title'].includes(props.type)) {
    isItemLink = true;
    linkedItemType = props.type;
  }
  const linkedItemId = isItemLink ? props[props.type] : null;

  const { thumbnailMedia } = props;
  const { itemData: linkedItemData, processing: linkedItemProcessing } = useItemData(linkedItemType, linkedItemId);
  const { itemData: thumbnailData, processing: thumbnailProcessing } = useItemData('media', thumbnailMedia);

  const handleDeleteClick = evt => {
    evt.stopPropagation();
    props.onDeleteClick && props.onDeleteClick();
  };

  // Dynamic label/description text
  const { label, labelSource, description, descriptionSource } = props;
  const itemAttrs = !linkedItemData ? {} : {
    item_name: linkedItemData.name,
    item_short_desc: linkedItemData.shortDescription,
    item_long_desc: linkedItemData.longDescription,
  };
  const labelText = { ...itemAttrs, custom: label }[labelSource] || '';

  // Thumbnail image
  const thumbnailMediaProcessing = thumbnailMedia ? thumbnailProcessing : linkedItemProcessing;
  const thumbnailSource = thumbnailMedia ? thumbnailData : linkedItemData;
  let renderedThumbnail = <Icon name="content_copy" />;
  if (thumbnailMedia || linkedItemId) {
    if (thumbnailMediaProcessing || !thumbnailSource) {
      renderedThumbnail = <Icon name="steppers" />;
    } else {
      renderedThumbnail = <img src={thumbnailSource.thumbnail} alt="thumbnail" width="100" height="60" />;
    }
  }

  // Type icon
  let icon;
  if (props.type === 'link') {
    icon = <Icon name="open_in_new" />;
  } else if (props.type === 'chapter') {
    icon = <Icon name="bookmark" fill />;
  } else {
    icon = linkedItemData && (
      props.type === 'media'
        ? <MediaTypeIcon type={linkedItemData.type.toLowerCase()} />
        : <Icon name={linkedItemData.iconName} />
    );
  }
  icon = <div className="title-builder-item-icon" title={linkedItemData && linkedItemData.type}>{icon}</div>;

  // Form fields
  const { prefix, id, shouldDelete, parent, type, startTime, duration, linkUrl, media, title, popupStyle } = props;
  const formFields = (
    <>
      {!!id && <input type="hidden" name={`${prefix}-id`} value={id} />}
      {shouldDelete && <input type="hidden" name={`${prefix}-DELETE`} value="1" />}
      <input type="hidden" name={`${prefix}-parent`} value={parent} />
      <input type="hidden" name={`${prefix}-type`} value={type || ''} />
      <input type="hidden" name={`${prefix}-start_time`} value={startTime || 0} />
      <input type="hidden" name={`${prefix}-duration`} value={duration || ''} />
      <input type="hidden" name={`${prefix}-link_url`} value={linkUrl || ''} />
      <input type="hidden" name={`${prefix}-label`} value={label || ''} />
      <input type="hidden" name={`${prefix}-label_source`} value={labelSource || ''} />
      <input type="hidden" name={`${prefix}-description`} value={description || ''} />
      <input type="hidden" name={`${prefix}-description_source`} value={descriptionSource || ''} />
      {!!media && <input type="hidden" name={`${prefix}-media`} value={media} />}
      {!!title && <input type="hidden" name={`${prefix}-title`} value={title} />}
      <input type="hidden" name={`${prefix}-popup_style`} value={popupStyle || ''} />
      {!!thumbnailMedia && <input type="hidden" name={`${prefix}-thumbnail_media`} value={thumbnailMedia} />}
    </>
  );

  const { active, error } = props;
  const containerClasses = classNames({
    active,
    error,
    'title-builder-item': true,
    'to-delete': shouldDelete,
  });
  const baseUrl = urls[`${type}Base`];
  const { onClick } = props;

  return (
    <ErrorBoundary suppressMessage>
      <div className={containerClasses} onClick={onClick}>
        <div className="index-counter">{formatters.seconds(startTime)}</div>
        {icon}
        <div className="title-builder-item-thumbnail mr-4">
          {renderedThumbnail}
        </div>

        <div className="title-builder-item-details">
          <h6 className="mb-0"><TruncateText text={labelText} numChars={45} forceBreak /></h6>
          {linkedItemData && (
            <div className="meta mt-1 lh-1">
              {type === 'media' ? 'Media' : 'Title'}:&nbsp;<a href={`${baseUrl}${linkedItemData.id}/`} target="_blank" rel="noopener noreferrer">{linkedItemData.id}</a>
            </div>
          )}
          {type === 'link' && !!linkUrl && (
            <div className="meta mt-1 lh-1">
              Link:&nbsp;
              <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                <TruncateText text={(linkUrl || '').replace(/^https?:\/\//, '')} numChars={15} />
              </a>
            </div>
          )}
        </div>

        {formFields}
        {shouldDelete && <DeleteOverlay onUndoClick={handleDeleteClick} />}

        <span
          className="btn-delete tooltip tooltip-left"
          key={shouldDelete}
          onClick={handleDeleteClick}
          data-tooltip={shouldDelete ? 'Undo Remove' : 'Remove Item'}
        >
          {shouldDelete ? <Icon name="undo" /> : <Icon name="delete" fill />}
        </span>
      </div>
    </ErrorBoundary>
  );
};

Item.propTypes = {
  active: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  prefix: PropTypes.string,
  id: PropTypes.string,
  parent: PropTypes.string,
  shouldDelete: PropTypes.bool,
  type: PropTypes.oneOf(['chapter', 'link', 'media', 'title']),
  startTime: PropTypes.number,
  duration: PropTypes.number,
  linkUrl: PropTypes.string,
  media: PropTypes.string,
  title: PropTypes.string,
  label: PropTypes.string,
  labelSource: PropTypes.oneOf(['custom', 'item_name']),
  description: PropTypes.string,
  descriptionSource: PropTypes.oneOf(['custom', 'item_short_desc', 'item_long_desc']),
  popupStyle: PropTypes.oneOf(['none', 'compact', 'expanded']),
  thumbnailMedia: PropTypes.string,
};

export default Item;
