import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import queryString from 'query-string';
import { webSafeFontFamilies, urls } from 'app-constants';
import { createFilter } from 'react-select';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Select, { getOptionStyles } from 'components/common/Select';

const MENU_DEFAULT_RESULTS = 50;

const FamilySelect = ({ source, kitId, selectedFamily, googleKey, onChange, onErrorStateChange }) => {
  const [typekitIsFetching, setTypekitIsFetching] = useState(false);
  const [googleIsFetching, setGoogleIsFetching] = useState(false);
  const [typekitFetchError, setTypekitFetchError] = useState(null);
  const [googleFetchError, setGoogleFetchError] = useState(null);
  const [familyOptions, setFamilyOptions] = useState([]);
  const [cachedGoogleOptions, setCachedGoogleOptions] = useState([]);
  const [googlePreviewFamilies, setGooglePreviewFamilies] = useState([]);

  const kitIdTimeout = useRef();
  const googlePreviewsTimeout = useRef();

  useEffect(() => {
    if (source === 'google') {
      setFamilyOptions([...cachedGoogleOptions]);
    } else if (source === 'typekit' && kitId) {
      if (kitId) {
        setFamilyOptions([]);
        clearTimeout(kitIdTimeout.current);
        kitIdTimeout.current = setTimeout(() => fetchTypekit(), 1000);
      } else {
        setFamilyOptions([]);
        setTypekitFetchError(null);
      }
    } else if (source === 'local') {
      setFamilyOptions(webSafeFontFamilies.map(item => ({ value: item.family, label: item.family, ...item })));
    }
  }, [source, kitId]);

  useEffect(() => fetchGoogle(), []);

  useEffect(() => {
    if (cachedGoogleOptions.length) {
      const familiesToLoad = cachedGoogleOptions.slice(0, MENU_DEFAULT_RESULTS);
      // Also load the initially-selected font, if not already included in the above set.
      if (source === 'google' && selectedFamily && !familiesToLoad.find(o => o.family === selectedFamily)) {
        familiesToLoad.push(cachedGoogleOptions.find(o => o.family === selectedFamily));
      }

      setGooglePreviewFamilies(familiesToLoad.map(o => ({
        family: o.family,
        weight: o.weights.includes(400) ? 400 : o.weights[0],
      })));
    }
  }, [cachedGoogleOptions]);

  useEffect(() => {
    if (source === 'google' && !googleIsFetching) {
      setFamilyOptions([...cachedGoogleOptions]);
    }
  }, [googleIsFetching]);

  useEffect(() => {
    const errors = [];
    googleFetchError && errors.push(googleFetchError);
    typekitFetchError && errors.push(typekitFetchError);
    onErrorStateChange(errors);
  }, [googleFetchError, typekitFetchError]);

  const fetchGoogle = () => {
    setGoogleFetchError(null);
    setGoogleIsFetching(true);

    const apiUrl = 'https://www.googleapis.com/webfonts/v1/webfonts';
    const params = {
      key: googleKey,
      prettyPrint: 'false',
      fields: 'items.family,items.variants,items.category',
      sort: 'POPULARITY',
    };
    const url = `${apiUrl}?${queryString.stringify(params)}`;
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Error contacting Google Fonts. Please try again later.');
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setCachedGoogleOptions(data.items.filter(({ family }) => !family.includes('Material ')).map(item => {
          const fallbacks = ['serif', 'sans-serif'].includes(item.category) ? item.category : '';
          const weights = item.variants.reduce((result, v) => {
            const w = v === 'regular' ? 400 : parseInt(v, 10);
            if ([300, 400, 500, 600, 700].includes(w) && !result.includes(w)) {
              result.push(w);
            }
            return result;
          }, []);

          return {
            label: item.family,
            value: item.family,
            family: item.family,
            fallbacks,
            weights,
          };
        }));
        setGoogleIsFetching(false);
      })
      .catch(err => {
        setGoogleIsFetching(false);
        setGoogleFetchError(err.message);
        console.error(err);
      });
  };

  const fetchTypekit = () => {
    setTypekitFetchError(null);
    setTypekitIsFetching(true);

    const url = `${urls.typekitKitBase}${kitId}/`;
    fetch(url)
      .then(response => {
        if (!response.ok) {
          if (response.status === 404) {
            throw new Error('A Typekit project with this Kit ID was not found.');
          } else {
            throw new Error('Error retrieving kit details.');
          }
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        if (data.errors) throw new Error(data.errors[0]);
        setFamilyOptions(data.kit.families.map(family => ({
          label: family.name,
          value: family.cssNames[0],
          family: family.cssNames[0],
          fallbacks: family.cssStack.split(',').slice(1).join(','),
        })));
        setTypekitIsFetching(false);
      })
      .catch(err => {
        setTypekitIsFetching(false);
        setTypekitFetchError(err.message);
        console.error(err);
      });
  };

  const selectedOption = familyOptions.find(({ value }) => value === selectedFamily) || '';

  const handleChange = val => {
    const opt = familyOptions.find(o => o.value === val);
    const { value, label, ...rest } = opt;
    onChange(rest);
  };

  const defaultFilter = createFilter();

  const handleInputChange = (query, { action }) => {
    if (source === 'google' && query && action === 'input-change') {
      clearTimeout(googlePreviewsTimeout.current);
      googlePreviewsTimeout.current = setTimeout(() => updateGooglePreviews(query), 1500);
    }
  };

  const updateGooglePreviews = query => {
    const matches = cachedGoogleOptions.filter(option => defaultFilter(option, query));
    const newFamilies = matches.reduce((result, match) => {
      if (!result.find(r => r.family === match.family)) {
        result.push({
          family: match.family,
          weight: match.weights.includes(400) ? 400 : match.weights[0],
        });
      }
      return result;
    }, [...googlePreviewFamilies]);
    setGooglePreviewFamilies(newFamilies);
  };

  // If the user hasn't entered any search text, limit the maximum number
  // of items displayed in the select menu to preserve performance.
  const filterOption = (option, query) => {
    const optIndex = familyOptions.findIndex(o => o.value === option.value);
    return query ? defaultFilter(option, query) : (optIndex < MENU_DEFAULT_RESULTS || option.value === selectedFamily);
  };

  const linkUrls = [];
  if (source === 'typekit' && kitId && !typekitFetchError) {
    linkUrls.push(`https://use.typekit.net/${kitId}.css`);
  }
  googlePreviewFamilies.forEach(({ family, weight }) => {
    const baseUrl = 'https://fonts.googleapis.com/css2';
    let familyParam = family.replace(/ /g, '+');
    if (weight !== 400) familyParam += `:ital,wght@0,${weight}`;
    linkUrls.push(`${baseUrl}?family=${familyParam}&text=${encodeURIComponent(family)}&display=swap`);
  });

  const isDisabled = !source || (source === 'typekit' && !kitId);
  const isLoading = (source === 'google' && googleIsFetching) || (source === 'typekit' && typekitIsFetching);

  const selectStyles = {
    option: (provided, state) => ({
      ...provided,
      ...getOptionStyles(state),
      fontFamily: state.data.fallbacks ? `${state.data.family},${state.data.fallbacks}` : state.data.family,
    }),

    singleValue: (provided, state) => ({
      ...provided,
      fontFamily: state.data.fallbacks ? `${state.data.family},${state.data.fallbacks}` : state.data.family,
    }),
  };

  return (
    <HelmetProvider>
      <Helmet>
        {linkUrls.map((url, idx) => <link key={idx} rel="stylesheet" href={url} />)}
      </Helmet>
      <Select
        options={familyOptions}
        onChange={handleChange}
        value={selectedOption}
        isDisabled={isDisabled}
        isLoading={isLoading}
        styles={selectStyles}
        filterOption={filterOption}
        onInputChange={handleInputChange}
      />
    </HelmetProvider>
  );
};

FamilySelect.propTypes = {
  source: PropTypes.oneOf(['google', 'typekit', 'local', '']),
  kitId: PropTypes.string,
  selectedFamily: PropTypes.string,
  googleKey: PropTypes.string,
  onChange: PropTypes.func,
  onErrorStateChange: PropTypes.func,
};

FamilySelect.defaultProps = {
  onChange: () => null,
};

export default FamilySelect;
