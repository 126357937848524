import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Checkbox from 'components/common/Checkbox';
import Icon from 'components/common/Icon';

const ReleaseItemsChooser = ({ style = {}, releaseItems, selectedIds, onChange }) => {
  const items = (releaseItems || []).map(item => ({
    ...item,
    isSelected: selectedIds.includes(item.id),
    handleChange: evt => onChange(evt.target.checked ? [...selectedIds, item.id] : selectedIds.filter(val => val !== item.id)),
  }));

  return (
    <div className="release-items-chooser" style={style}>
      {items.map(({ id, objectId, objectName, objectIconName, isSelected, handleChange }) => (
        <div key={id} className={classNames('item', isSelected && 'selected')}>
          <label className="form-checkbox m-0">
            <Checkbox checked={isSelected} onChange={handleChange} />
            <i className="form-icon" />
            <div className="d-flex align-items-center">
              <span className="type-icon">
                <Icon name={objectIconName} size={20} />
              </span>
              {objectName}
            </div>
          </label>
        </div>
      ))}
      {items.length === 0 && <div className="item"><em>There are currently no items in this release.</em></div>}
    </div>
  );
};

ReleaseItemsChooser.propTypes = {
  style: PropTypes.object,
  releaseItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    objectId: PropTypes.string,
    objectType: PropTypes.string,
    objectName: PropTypes.string,
    objectIconName: PropTypes.string,
  })),
  selectedIds: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default ReleaseItemsChooser;
