import React from 'react';
import PropTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';


const LoadingOverlay = ({ show, text, style = {} }) => {
  const transitionStyles = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 },
  };

  return (
    <Transition in={show} timeout={0} mountOnEnter unmountOnExit>
      {state => (
        <div className="loading-overlay" style={{ ...style, ...transitionStyles[state] }}>
          {text && <div className="text" dangerouslySetInnerHTML={{ __html: text }} />}
        </div>
      )}
    </Transition>
  );
};

LoadingOverlay.propTypes = {
  show: PropTypes.bool,
  text: PropTypes.string,
  style: PropTypes.object,
};

export default LoadingOverlay;
