import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import SortableJsonKeyValueWidget from 'components/common/SortableJsonKeyValueWidget';

const SortableJsonKeyValueField = ({
  typeLabel,
  typeLabelPlural,
  helpText,
  keyLabel,
  valLabel,
  keyChoices,
  field,
}) => {
  const [value, setValue] = useState(JSON.parse(field.value));

  const inputVal = JSON.stringify(value);
  const handleChange = useCallback(value => setValue(value), []);

  let errors = {};
  if (field.errors && field.errors.length > 0) {
    errors = JSON.parse(field.errors[0]);
  }

  const { name, label, helpText: fieldHelpText } = camelize(field);

  return (
    <>
      <SortableJsonKeyValueWidget
        typeLabel={typeLabel}
        typeLabelPlural={typeLabelPlural || label}
        helpText={helpText || fieldHelpText}
        keyLabel={keyLabel}
        valLabel={valLabel}
        keyChoices={keyChoices}
        initialValue={value}
        errors={errors}
        onChange={handleChange}
      />
      <input type="hidden" name={name} value={inputVal} />
    </>
  );
};


SortableJsonKeyValueField.propTypes = {
  typeLabel: PropTypes.string,
  typeLabelPlural: PropTypes.string,
  helpText: PropTypes.string,
  keyLabel: PropTypes.string,
  valLabel: PropTypes.string,
  keyChoices: PropTypes.arrayOf(PropTypes.string),
  field: PropTypes.object,
};

export default SortableJsonKeyValueField;
