import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import striptags from 'striptags';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { formatters } from 'utils';
import { ErrorList } from 'components/common/inlines';
import QuillEditor from 'components/common/QuillEditor';
import MediaRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/MediaRelationField';
import Select from 'components/common/Select';
import { relatedItemDefaults } from './slideFormDefaults';


class RelatedItemInlineForm extends Component {
  constructor (props) {
    super(props);
    this.defaultState = {
      itemId: null,
      fields: { ...relatedItemDefaults },
      mediaData: null,
      linkImageData: null,
      mediaProcessing: false,
      linkImageProcessing: false,
      mediaError: null,
      linkImageError: null,
    };
    this.state = {
      ...this.defaultState,
    };

    this.fieldPrefix = 'slideshowBuilderRelatedItemForm-';
    this.numericFields = [];
    this.requiredFields = ['media'];

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.initFormValues = this.initFormValues.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormChange = debounce(this.handleFormChange, 500);
  }

  componentDidMount () {
    this.initFormValues();
    const mediaId = this.props.item.media;
    const linkImageId = this.props.item.linkImage;
    if (mediaId) {
      this.fetchData(mediaId, 'media');
    }
    if (linkImageId) {
      this.fetchData(mediaId, 'linkImage');
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { item, onMediaChange } = this.props;
    const prevItem = prevProps.item;
    const { fields, mediaData } = this.state;

    if (!isEqual(item, prevItem)) {
      this.initFormValues();
    }

    if (item.media !== prevItem.media) {
      if (!item.media) {
        this.setState({ mediaData: null, mediaProcessing: false, mediaError: null });
      } else {
        this.fetchData(item.media, 'media');
      }
    }

    if (item.linkImage !== prevItem.linkImage) {
      if (!item.linkImage) {
        this.setState({ linkImageData: null, linkImageProcessing: false, linkImageError: null });
      } else {
        this.fetchData(item.linkImage, 'linkImage');
      }
    }

    if (mediaData && !prevState.mediaData) {
      onMediaChange(mediaData);
    }

    if (fields.itemType !== prevState.fields.itemType) {
      const newFields = {};
      if (fields.itemType === 'link') {
        newFields.media = null;
        newFields.credit = '';
        newFields.creditSource = 'none';

        if (fields.nameSource === 'media_name') {
          newFields.nameSource = 'custom';
        }

        if (['media_short_desc', 'media_long_desc'].includes(fields.descriptionSource)) {
          newFields.descriptionSource = 'custom';
        }
      } else {
        if (fields.nameSource === 'custom' && !fields.name) {
          newFields.nameSource = 'media_name';
        }

        if (fields.descriptionSource === 'custom' && !fields.description) {
          newFields.descriptionSource = 'media_short_desc';
        }
      }

      this.setState({
        fields: {
          ...fields,
          ...newFields,
        },
      }, this.handleFormChange);
    }
  }

  componentWillUnmount () {
    this.handleFormChange.flush();
  }

  fetchData (mediaId, field) {
    const stateKey = `${field}Data`;
    const processingKey = `${field}Processing`;
    const errorKey = `${field}Error`;
    const url = urlJoin(urls.mediaDataBase, mediaId, '?format=json');
    this.setState({ [processingKey]: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ [errorKey]: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({ [stateKey]: data, [processingKey]: false });
      })
      .catch(err => {
        this.setState({ [processingKey]: false });
        console.error(err);
      });
  }

  handleInputChange (e) {
    const { fields } = this.state;
    let { name, value, type, checked } = e.target;
    name = name.replace(this.fieldPrefix, '');
    if (value && this.numericFields.includes(name)) {
      value = parseFloat(value);
    } else if (type === 'checkbox') {
      value = checked;
    } else if (name === 'slug') {
      value = formatters.slug(value);
    }
    this.setState({
      fields: {
        ...fields,
        [name]: value,
      },
    }, this.handleFormChange);
  }

  handleFieldChange (field, value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        [field]: value,
      },
    }, this.handleFormChange);
  }

  handleFormChange () {
    const { onChange } = this.props;
    const { fields } = this.state;
    onChange(fields);
  }

  initFormValues () {
    const { item } = this.props;

    if (item) {
      const newFields = Object.keys(item).reduce((result, key) => {
        if (this.defaultState.fields.hasOwnProperty(key)) {
          // Keep explicit false for checkbox fields, but convert undefined or null to empty strings
          result[key] = (item[key] || item[key] === false) ? item[key] : '';
        }
        return result;
      }, {});
      this.setState({ fields: newFields, itemId: item.itemId });
    } else {
      this.setState({ fields: this.defaultState.fields, itemId: null });
    }
  }

  errorClass (fieldName) {
    const { item } = this.props;
    return item && item.errors[fieldName] ? 'has-error' : '';
  }

  renderNameInput () {
    const { mediaData } = this.state;
    const { name, nameSource } = this.state.fields;

    switch (nameSource) {
      case 'media_name':
        return <input key="nameSource1" type="text" className="form-input" value={mediaData ? mediaData.name : ''} disabled />;
      case 'custom':
        return (
          <input
            key="nameSource2"
            name={this.fieldPrefix + 'name'}
            type="text"
            className="form-input"
            value={name}
            onChange={this.handleInputChange}
          />
        );
      case 'none':
        // Intentionally fall through
      default:
        return <input key="nameSource3" type="text" className="form-input" disabled />;
    }
  }

  renderDescriptionInput () {
    const { mediaData } = this.state;
    const { description, descriptionSource } = this.state.fields;

    switch (descriptionSource) {
      case 'media_long_desc':
        return (
          <textarea
            key="descriptionSource1"
            cols="30"
            rows="3"
            className="form-input"
            value={mediaData ? striptags(mediaData.longDescription) : ''}
            disabled
          />
        );
      case 'media_short_desc':
        return (
          <textarea
            key="descriptionSource2"
            cols="30"
            rows="3"
            className="form-input"
            value={mediaData ? striptags(mediaData.shortDescription) : ''}
            disabled
          />
        );
      case 'custom':
        return (
          <QuillEditor
            inputName={this.fieldPrefix + 'description'}
            value={description}
            onChange={val => this.handleFieldChange('description', val)}
            size="small"
          />
        );
      case 'none':
        // Intentionally fall through
      default:
        return <textarea key="descriptionSource3" cols="30" rows="3" className="form-input" disabled />;
    }
  }

  renderCreditInput () {
    const { mediaData } = this.state;
    const { credit, creditSource } = this.state.fields;

    switch (creditSource) {
      case 'media_credit':
        return <input key="creditSource1" type="text" className="form-input" value={mediaData ? mediaData.credit : ''} disabled />;
      case 'custom':
        return (
          <input
            key="creditSource2"
            name={this.fieldPrefix + 'credit'}
            type="text"
            className="form-input"
            value={credit}
            onChange={this.handleInputChange}
          />
        );
      case 'none':
        // Intentionally fall through
      default:
        return <input key="creditSource3" type="text" className="form-input" disabled />;
    }
  }

  render () {
    const { item, onSubmit } = this.props;
    const {
      itemType,
      nameSource,
      descriptionSource,
      creditSource,
      linkUrl,
      linkImage,
      media,
    } = this.state.fields;

    if (!item) {
      return null;
    }
    const hasErrors = Object.keys(item.errors).length > 0;
    const isMedia = itemType === 'media';

    let nameSourceOptions = isMedia ? [
      { value: 'media_name', label: 'Use media name' },
    ] : [];
    nameSourceOptions = [
      ...nameSourceOptions,
      { value: 'custom', label: 'Use custom name' },
      { value: 'none', label: 'None' },
    ];
    const nameSourceValue = nameSourceOptions.find(opt => opt.value === nameSource);

    let descriptionSourceOptions = isMedia ? [
      { value: 'media_long_desc', label: 'Use media long description' },
      { value: 'media_short_desc', label: 'Use media short description' },
    ] : [];
    descriptionSourceOptions = [
      ...descriptionSourceOptions,
      { value: 'custom', label: 'Use custom description' },
      { value: 'none', label: 'None' },
    ];
    const descriptionSourceValue = descriptionSourceOptions.find(opt => opt.value === descriptionSource);

    let creditSourceOptions = isMedia ? [
      { value: 'media_credit', label: 'Use media credit' },
    ] : [];
    creditSourceOptions = [
      ...creditSourceOptions,
      { value: 'custom', label: 'Use custom credit' },
      { value: 'none', label: 'None' },
    ];
    const creditSourceValue = creditSourceOptions.find(opt => opt.value === creditSource);

    return (
      <form className="custom-title-builder-form" onSubmit={onSubmit} key={item.itemId}>
        {hasErrors ? <ErrorList className="mb-5" errors={item.errors} /> : null}

        <div className={`form-group mb-5 ${this.errorClass('itemType')}`}>
          <label className="block-label">Type</label>
          <label className="form-radio m-0">
            <input
              type="radio"
              name={this.fieldPrefix + 'itemType'}
              value="link"
              checked={itemType === 'link'}
              onChange={this.handleInputChange}
            />
            <i className="form-icon" /> Link
          </label>
          <label className="form-radio m-0">
            <input
              type="radio"
              name={this.fieldPrefix + 'itemType'}
              value="media"
              checked={itemType === 'media'}
              onChange={this.handleInputChange}
            />
            <i className="form-icon" /> Media
          </label>
          <p className="form-input-hint mb-0">The related content may be either a link or a media item.</p>
        </div>

        {isMedia ? (
          <div className={`form-group mb-5 ${this.errorClass('media')}`}>
            <label className="block-label">Media</label>
            <p className="form-input-hint m-0">Use the form below to select a media item.</p>
            <MediaRelationField limitTypes={['video', 'audio']} value={media} onChange={val => this.handleFieldChange('media', val)} />
          </div>
        ) : (
          <>
            <div className={`form-group mb-5 ${this.errorClass('linkUrl')}`}>
              <label className="block-label">URL</label>
              <input name={`${this.fieldPrefix}linkUrl`} type="text" className="form-input" value={linkUrl} onChange={this.handleInputChange} />
              <p className="form-input-hint mb-0">Enter the link URL, beginning with “http://” or “https://”.</p>
            </div>
            <div className={`form-group mb-5 ${this.errorClass('linkImage')}`}>
              <label className="block-label">Image</label>
              <p className="form-input-hint m-0">Optionally select an image to display with this link.</p>
              <MediaRelationField limitTypes={['image']} value={linkImage} onChange={val => this.handleFieldChange('linkImage', val)} />
            </div>
          </>
        )}

        <div className={classNames('form-group', 'mb-3', this.errorClass('name'), this.errorClass('nameSource'))}>
          <label className="block-label">Name</label>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
              <Select
                value={nameSourceValue}
                options={nameSourceOptions}
                onChange={val => this.handleFieldChange('nameSource', val)}
              />
            </div>
            <div style={{ flex: 1, minWidth: 300 }}>
              {this.renderNameInput()}
            </div>
          </div>
        </div>

        <div className={classNames('form-group', 'mb-3', this.errorClass('description'), this.errorClass('descriptionSource'))}>
          <label className="block-label">Description</label>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
              <Select
                value={descriptionSourceValue}
                options={descriptionSourceOptions}
                onChange={val => this.handleFieldChange('descriptionSource', val)}
              />
            </div>
            <div style={{ flex: 1, minWidth: 300 }}>
              {this.renderDescriptionInput()}
            </div>
          </div>
        </div>

        {isMedia && (
          <div className={classNames('form-group', 'mb-3', this.errorClass('credit'), this.errorClass('creditSource'))}>
            <label className="block-label">Credit</label>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
                <Select
                  value={creditSourceValue}
                  options={creditSourceOptions}
                  onChange={val => this.handleFieldChange('creditSource', val)}
                />
              </div>
              <div style={{ flex: 1, minWidth: 300 }}>
                {this.renderCreditInput()}
              </div>
            </div>
          </div>
        )}

        <hr />
        <div>
          <button type="submit" className="btn btn-primary mr-2">Close</button>
          <button type="submit" className="btn btn-primary" value="add-another">Add Another</button>
        </div>
      </form>
    );
  }
}

RelatedItemInlineForm.propTypes = {
  item: PropTypes.object,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  onMediaChange: PropTypes.func,
};

export default RelatedItemInlineForm;
