import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Modal from 'components/common/Modal';
import LoadingOverlay from 'components/common/LoadingOverlay';
import PageHeader from 'components/common/PageHeader';
import IntegrationItem from './IntegrationItem';
import ServicesList from './ServicesList';
import EditIntegration from './EditIntegration';
import servicesConfig from './config';


class Integrations extends Component {
  constructor (props) {
    super(props);
    this.state = {
      modalVisible: false,
      selectedService: null,
      integrations: [],
      processing: false,
      error: null,
    };

    this.handleNewIntegrationClick = this.handleNewIntegrationClick.bind(this);
    this.handleNewIntegrationCancel = this.handleNewIntegrationCancel.bind(this);
    this.handleNewIntegrationSuccess = this.handleNewIntegrationSuccess.bind(this);
    this.handleServiceSelect = this.handleServiceSelect.bind(this);
    this.handleItemChange = this.handleItemChange.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount () {
    this.fetchData();
  }

  handleNewIntegrationClick (e) {
    e.preventDefault();
    this.setState({ modalVisible: true });
  }

  handleNewIntegrationCancel () {
    this.setState({
      modalVisible: false,
      selectedService: null,
    });
  }

  handleNewIntegrationSuccess () {
    this.setState({
      modalVisible: false,
      selectedService: null,
    });
    this.fetchData();
  }

  handleServiceSelect (serviceName) {
    this.setState({ selectedService: serviceName });
  }

  handleItemChange () {
    this.fetchData();
  }

  fetchData () {
    const url = urlJoin(urls.integrationList, '?format=json');
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({ integrations: data, processing: false });
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  renderNewIntegrationForm () {
    const { selectedService } = this.state;
    const config = servicesConfig.find(item => item.serviceName === selectedService);

    return config ? (
      <EditIntegration
        key={selectedService}
        {...config}
        onCancel={this.handleNewIntegrationCancel}
        onSave={this.handleNewIntegrationSuccess}
      />
    ) : <span>Select a service from the list at left to configure a new integration.</span>;
  }

  render () {
    const { headerSubtitle } = this.props;
    const { modalVisible, selectedService, integrations, processing, error } = this.state;

    return (
      <div className="wrap-page">
        <ErrorBoundary>
          <PageHeader
            title="Integrations"
            subtitle={headerSubtitle}
            iconName="power"
            buttons={[
              { label: 'New Integration', onClick: this.handleNewIntegrationClick },
            ]}
          />

          <div className="page-body">
            <div className="integrations-item-container">
              {integrations.map(({ id, service }) => (
                <IntegrationItem
                  key={id}
                  id={id}
                  serviceConfig={servicesConfig.find(item => item.serviceName === service)}
                  onChange={this.handleItemChange}
                />
              ))}
              <LoadingOverlay show={processing && !integrations.length} />
            </div>
          </div>

          <Modal
            title="New Integration"
            style={{ content: { width: 800 } }}
            isOpen={modalVisible}
            onRequestClose={this.handleNewIntegrationCancel}
          >
            <hr className="mt-0 mb-5" />
            {error ? (
              <div className="toast toast-error">Error: {error}</div>
            ) : (
              <div className="d-flex">
                <ServicesList selected={selectedService} onSelect={this.handleServiceSelect} />
                <div>
                  {this.renderNewIntegrationForm()}
                </div>
              </div>
            )}
          </Modal>
        </ErrorBoundary>
      </div>
    );
  }
}

Integrations.propTypes = {
  headerSubtitle: PropTypes.string,
};

export default Integrations;
