import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'app-constants';
import { components, createFilter } from 'react-select';
import ContributorPreview from 'components/common/ContributorPreview';
import Select from 'components/common/Select';


const { Option } = components;
const OPTION_CREATE_NEW = 'createNew';
const OPTION_TEXT_ONLY = 'textOnly';
const OPTION_LABELS = {
  [OPTION_CREATE_NEW]: 'New Contributor',
  [OPTION_TEXT_ONLY]: 'Free-Form Text Credit',
};

const ContributorOption = props => {
  let inner = <ContributorPreview {...props.data} />;
  if ([OPTION_CREATE_NEW, OPTION_TEXT_ONLY].includes(props.value)) {
    inner = (
      <div style={{ fontWeight: 600, paddingBottom: 5, borderBottom: `1px solid ${colors.grayLight}` }}>
        {OPTION_LABELS[props.value]}
      </div>
    );
  }

  return <Option {...props}>{inner}</Option>;
};

ContributorOption.propTypes = {
  value: PropTypes.string,
  data: PropTypes.object,
};

const ContributorSelect = ({ contributors, value, ...rest }) => {
  const labelForOption = opt => [opt.name, opt.organization].filter(s => !!s).join(' | ');
  const options = useMemo(() => ([
    { value: OPTION_CREATE_NEW, label: OPTION_LABELS[OPTION_CREATE_NEW] },
    { value: OPTION_TEXT_ONLY, label: OPTION_LABELS[OPTION_TEXT_ONLY] },
    ...contributors.map(c => ({ value: c.uid, label: labelForOption(c), ...c })),
  ]), [contributors]);
  const selectedOption = options.filter(({ value: id }) => value === id);

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: 400,
    }),
  };

  const defaultFilter = createFilter();
  const customFilter = (option, input) => {
    return [OPTION_CREATE_NEW, OPTION_TEXT_ONLY].includes(option.value) || defaultFilter(option, input);
  };

  return (
    <Select
      styles={customStyles}
      placeholder="Select a contributor..."
      options={options}
      value={selectedOption}
      components={{ Option: ContributorOption }}
      filterOption={customFilter}
      {...rest}
    />
  );
};

ContributorSelect.propTypes = {
  contributors: PropTypes.array,
  value: PropTypes.string,
  isLoading: PropTypes.bool,
  onChange: PropTypes.func,
};

ContributorSelect.defaultProps = {
  onChange: () => null,
};

export { OPTION_CREATE_NEW, OPTION_TEXT_ONLY };
export default ContributorSelect;
