import React from 'react';
import PropTypes from 'prop-types';


const RelatedItemHiddenFields = ({
  prefix,
  id,
  shouldDelete,
  parent,
  slideUid,
  itemType,
  index,
  name,
  nameSource,
  description,
  descriptionSource,
  credit,
  creditSource,
  linkUrl,
  linkImage,
  media,
}) => (
  <>
    {id ? <input type="hidden" name={`${prefix}-id`} value={id} /> : null}
    {shouldDelete ? <input type="hidden" name={`${prefix}-DELETE`} value="1" /> : null}
    <input type="hidden" name={`${prefix}-parent`} value={parent} />
    <input type="hidden" name={`${prefix}-slide_uid`} value={slideUid} />
    <input type="hidden" name={`${prefix}-item_type`} value={itemType} />
    <input type="hidden" name={`${prefix}-index`} value={index} />
    <input type="hidden" name={`${prefix}-name`} value={name || ''} />
    <input type="hidden" name={`${prefix}-name_source`} value={nameSource || ''} />
    <input type="hidden" name={`${prefix}-description`} value={description || ''} />
    <input type="hidden" name={`${prefix}-description_source`} value={descriptionSource || ''} />
    <input type="hidden" name={`${prefix}-credit`} value={credit || ''} />
    <input type="hidden" name={`${prefix}-credit_source`} value={creditSource || ''} />
    <input type="hidden" name={`${prefix}-link_url`} value={linkUrl || ''} />
    {linkImage ? <input type="hidden" name={`${prefix}-link_image`} value={linkImage} /> : null}
    {media ? <input type="hidden" name={`${prefix}-media`} value={media} /> : null}
  </>
);

RelatedItemHiddenFields.propTypes = {
  prefix: PropTypes.string,
  id: PropTypes.string,
  shouldDelete: PropTypes.bool,
  parent: PropTypes.string,
  slideUid: PropTypes.string,
  itemType: PropTypes.string,
  index: PropTypes.number,
  name: PropTypes.string,
  nameSource: PropTypes.string,
  description: PropTypes.string,
  descriptionSource: PropTypes.string,
  credit: PropTypes.string,
  creditSource: PropTypes.string,
  linkUrl: PropTypes.string,
  linkImage: PropTypes.string,
  media: PropTypes.string,
};

export default RelatedItemHiddenFields;
