import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import { urls } from 'app-constants';
import { withResizeDetector } from 'react-resize-detector';
import { useTitleBuilder } from 'components/views/titleBuilder';
import ErrorBoundary from 'components/common/ErrorBoundary';
import StyledScrollbar from 'components/common/StyledScrollbar';
import withDragDropContext from 'components/common/withDragDropContext';
import LoadingOverlay from 'components/common/LoadingOverlay';
import ContributorChoiceItem from './ContributorChoiceItem';
import Item from './Item';

const SOURCE_OPTION_MANUAL = 'manual';
const SOURCE_OPTION_RELEASE = 'release';

const META_OPTION_TITLE = 'title';
const META_OPTION_ORG = 'organization';

const SORT_ALPHA = 1;
const SORT_ALPHA_ORGS_FIRST = 2;
const SORT_ALPHA_IND_FIRST = 3;

const ContributorsBuilder = ({ useReleaseCreditsField, metadataDisplayField, formsetData, hasError, width }) => {
  const scrollContainerRef = useRef();

  const [choicesFetching, setChoicesFetching] = useState(true);
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    fetchChoices();
  }, []);

  const fetchChoices = () => {
    setChoicesFetching(true);

    fetch(urls.contributorChoices, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setChoicesFetching(false);
        setChoices(camelize(data));
      })
      .catch(err => {
        setChoicesFetching(false);
        console.error(err);
      });
  };

  const [sourceOption, setSourceOption] = useState(useReleaseCreditsField.value ? SOURCE_OPTION_RELEASE : SOURCE_OPTION_MANUAL);
  const disableControls = sourceOption !== SOURCE_OPTION_MANUAL;
  const handleSourceOptionChange = evt => setSourceOption(evt.target.value);

  const [metaOption, setMetaOption] = useState(metadataDisplayField.value || META_OPTION_TITLE);
  const handleMetaOptionChange = evt => setMetaOption(evt.target.value);

  const getExtraItemProps = item => {
    return { contributorData: choices.find(c => c.uid === item.contributor), metaOption };
  };

  const opts = {
    formsetData,
    itemLabel: 'slide',
    itemLabelPlural: 'slides',
    hasError,
    addFromMedia: true,
    ItemComponent: Item,
    extraItemProps: getExtraItemProps,
    newItemsFirst: false,
  };

  const {
    managementForm,
    messages,
    renderedItems,
    initNewItem,
    initNewItems,
    itemsById,
    itemsOrdered,
    setItemsOrdered,
    itemsMarkedForDeletion,
    toggleDelete,
  } = useTitleBuilder(opts);

  const contributorsByItemId = useMemo(() => Object.entries(itemsById).reduce((result, [itemId, item]) => {
    result[itemId] = choices.find(c => c.uid === item.contributor);
    return result;
  }, {}), [itemsById]);

  const itemsByContributorId = useMemo(() => Object.values(itemsById).reduce((result, item) => {
    result[item.contributor] = item;
    return result;
  }, {}), [itemsById]);

  const contributorForItemId = useCallback(uid => itemsById[uid].contributor, [itemsById]);
  const contributorIds = useMemo(() => itemsOrdered.map(contributorForItemId), [itemsOrdered]);
  const addedContributorIds = useMemo(() => {
    return itemsOrdered.filter(uid => !itemsMarkedForDeletion.includes(uid)).map(contributorForItemId);
  }, [itemsOrdered, itemsMarkedForDeletion, contributorForItemId]);

  const handleChoiceClick = (evt, uid) => {
    if (contributorIds.includes(uid)) {
      const itemId = itemsByContributorId[uid].itemId;
      toggleDelete(itemId);
    } else {
      const prepend = evt.shiftKey;
      initNewItem({ contributor: uid }, prepend);
      setTimeout(() => {
        const node = scrollContainerRef.current.parentNode;
        node.scrollTop = prepend ? 0 : node.scrollHeight;
      }, 0);
    }
  };

  const [searchQuery, setSearchQuery] = useState('');

  const filteredChoices = useMemo(() => {
    return searchQuery ? choices.filter(c => {
      const haystack = `${c.name} ${c.organization} ${c.notes} ${(c.tags || []).join(' ')}`.toLowerCase();
      const needle = searchQuery.toLowerCase();
      return haystack.includes(needle);
    }) : choices;
  }, [searchQuery, choices]);

  const pageSize = 100;
  const [showPages, setShowPages] = useState(1);
  const showCount = pageSize * showPages;
  const visibleChoices = useMemo(() => filteredChoices.slice(0, showCount), [filteredChoices, showCount]);
  const showLoadMore = visibleChoices.length < filteredChoices.length;
  const handleLoadMoreClick = () => setShowPages(oldState => oldState + 1);
  useEffect(() => {
    setShowPages(1);
  }, [filteredChoices]);

  const handleClearSearchClick = evt => {
    evt.preventDefault();
    setSearchQuery('');
  };

  const handleAddAllClick = evt => {
    evt.preventDefault();
    initNewItems(visibleChoices.reduce((result, { uid }) => {
      if (!contributorIds.includes(uid)) {
        result.push({ contributor: uid });
      }
      return result;
    }, []));

    setTimeout(() => {
      const node = scrollContainerRef.current.parentNode;
      node.scrollTop = node.scrollHeight;
    }, 0);
  };

  const showEmptyMessage = !renderedItems.length && !choicesFetching && !disableControls;

  const sortHandler = sortType => evt => {
    evt.preventDefault();

    const allSorted = [...itemsOrdered].sort((a, b) => {
      return contributorsByItemId[a].sortKey > contributorsByItemId[b].sortKey ? 1 : -1;
    });

    switch (sortType) {
      case SORT_ALPHA:
        setItemsOrdered(allSorted);
        break;
      case SORT_ALPHA_ORGS_FIRST:
        setItemsOrdered([
          ...allSorted.filter(itemId => contributorsByItemId[itemId].type === 'organization'),
          ...allSorted.filter(itemId => contributorsByItemId[itemId].type === 'individual'),
        ]);
        break;
      case SORT_ALPHA_IND_FIRST:
        setItemsOrdered([
          ...allSorted.filter(itemId => contributorsByItemId[itemId].type === 'individual'),
          ...allSorted.filter(itemId => contributorsByItemId[itemId].type === 'organization'),
        ]);
        break;
    }
  };

  return (
    <div className="title-builder object-detail-panel-full-height slideshow-builder-grid">
      <ErrorBoundary>
        {managementForm}
        <div className="title-builder-items-container">
          <StyledScrollbar key={width} ref={scrollContainerRef}>
            {messages}
            <div className="title-builder-item disabled">
              <div className="form-group flex-1 m-0">
                <label className="strong mb-1" style={{ display: 'block' }}>Contributor Selection</label>

                <label className="form-radio m-0 lh-1">
                  <input
                    type="radio"
                    value={SOURCE_OPTION_MANUAL}
                    checked={sourceOption === SOURCE_OPTION_MANUAL}
                    onChange={handleSourceOptionChange}
                  />
                  <i className="form-icon" style={{ top: 3 }} /> Manual <span className="text-hint ml-1">Specify contributors manually.</span>
                </label>

                <label className="form-radio m-0 lh-1">
                  <input
                    type="radio"
                    value={SOURCE_OPTION_RELEASE}
                    checked={sourceOption === SOURCE_OPTION_RELEASE}
                    onChange={handleSourceOptionChange}
                  />
                  <i className="form-icon" style={{ top: 3 }} /> <span>Credited</span> <span className="text-hint ml-1">Include all contributors credited in an associated release.</span>
                </label>
                {sourceOption === SOURCE_OPTION_RELEASE && <input type="hidden" name={useReleaseCreditsField.name} value="1" />}
              </div>

              <div className="form-group flex-1 m-0">
                <label className="strong mb-1" style={{ display: 'block' }}>Thumbnail Metadata Display</label>

                <label className="form-radio m-0 lh-1">
                  <input
                    type="radio"
                    name={metadataDisplayField.name}
                    value={META_OPTION_TITLE}
                    checked={metaOption === META_OPTION_TITLE}
                    onChange={handleMetaOptionChange}
                  />
                  <i className="form-icon" style={{ top: 3 }} /> Title <span className="text-hint ml-1">Display contributor title.</span>
                </label>

                <label className="form-radio m-0 lh-1">
                  <input
                    type="radio"
                    name={metadataDisplayField.name}
                    value={META_OPTION_ORG}
                    checked={metaOption === META_OPTION_ORG}
                    onChange={handleMetaOptionChange}
                  />
                  <i className="form-icon" style={{ top: 3 }} /> Organization <span className="text-hint ml-1">Display contributor organization.</span>
                </label>
              </div>
            </div>
            <div className={classNames('p-3 bb flex-spread text-meta lh-1', disableControls && 'title-builder-disabled-element')}>
              <div>
                <span className="mr-2">Reorder contributors:</span>
                <a href="#" onClick={sortHandler(SORT_ALPHA)}>A–Z</a>
                <span className="mx-2">|</span>
                <a href="#" onClick={sortHandler(SORT_ALPHA_ORGS_FIRST)}>A–Z (organizations first)</a>
                <span className="mx-2">|</span>
                <a href="#" onClick={sortHandler(SORT_ALPHA_IND_FIRST)}>A–Z (individuals first)</a>
              </div>
              <div>Count: {addedContributorIds.length}</div>
            </div>
            <div style={{ minHeight: 200, position: 'relative' }}>
              <div className={classNames('slideshow-builder-items', disableControls && 'title-builder-disabled-element')}>
                {renderedItems}
                {showEmptyMessage && (
                  <div className="title-builder-empty-message-container">
                    <p>Add contributors from the list at right.</p>
                  </div>
                )}
              </div>
              <LoadingOverlay show={choicesFetching} />
            </div>
          </StyledScrollbar>
        </div>
        <div className={classNames('title-builder-form-container')} style={{ overflow: disableControls ? 'hidden' : 'auto' }}>
          <div className={classNames(disableControls && 'title-builder-disabled-element')}>
            <header className="contributors-builder-choices-header">
              <div className="input-group">
                <div className="has-icon-right" style={{ flex: 1, display: 'flex' }}>
                  <input
                    type="text"
                    className="form-input"
                    placeholder="Search contributors"
                    onChange={evt => setSearchQuery(evt.target.value)}
                    value={searchQuery}
                  />
                  {false && <i className="form-icon loading" />}
                </div>
              </div>
              <div className="text-meta lh-1 mt-1 flex-spread">
                <a href="#" onClick={handleClearSearchClick}>Clear search</a>
                <a href="#" onClick={handleAddAllClick}>+ Add all</a>
              </div>
            </header>
            {visibleChoices.map(c => (
              <ContributorChoiceItem
                key={c.uid}
                contributorData={c}
                isAdded={addedContributorIds.includes(c.uid)}
                onClick={handleChoiceClick}
              />
            ))}
            {showLoadMore && (
              <div className="p-4 text-center">
                <button type="button" className="btn btn-primary" onClick={handleLoadMoreClick}>Load More</button>
              </div>
            )}
          </div>
          <LoadingOverlay show={choicesFetching} />
        </div>
      </ErrorBoundary>
    </div>
  );
};

ContributorsBuilder.propTypes = {
  useReleaseCreditsField: PropTypes.object,
  metadataDisplayField: PropTypes.object,
  formsetData: PropTypes.object,
  hasError: PropTypes.bool,
  width: PropTypes.number,
};

export default withDragDropContext(withResizeDetector(ContributorsBuilder, {
  handleWidth: true,
  handleHeight: false,
  refreshMode: 'debounce',
}));
