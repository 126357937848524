import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { useTitleBuilder } from 'components/views/titleBuilder';
import withDragDropContext from 'components/common/withDragDropContext';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';
import ContributorSelect, { OPTION_CREATE_NEW, OPTION_TEXT_ONLY } from './ContributorSelect';
import ContributorForm from './ContributorForm';
import Item from './Item';


const CreditsWidget = ({
  parentId,
  parentFieldName,
  formsetData = {},
  contributorChoices,
  disabled = false,
}) => {
  const containerRef = useRef();
  const itemListRef = useRef();
  const [choicesFetching, setChoicesFetching] = useState(!contributorChoices);
  const [choices, setChoices] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [expanded, setExpanded] = useState(false);
  const [editFormIsOpen, setEditFormIsOpen] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);

  const handleEditClick = contributorId => {
    const id = choices.find(c => c.uid === contributorId).id;
    const url = urlJoin(urls.contributorBase, id, '/');
    window.open(url, '_blank');
  };

  const { isBound, isValid, prefix } = camelize(formsetData);
  const hasError = isBound && !isValid;

  const opts = {
    formsetData,
    hasError,
    newItemsFirst: false,
    sortableItemType: prefix,
    sortableItemClassName: 'mb-4',
    useCustomDragHandle: true,
    ItemComponent: Item,
    extraItemProps: item => ({
      parentFieldName,
      parentId,
      contributorData: choices.find(c => c.uid === item.contributor),
      onEditClick: () => handleEditClick(item.contributor),
    }),
  };

  const {
    itemsOrdered,
    initNewItem,
    managementForm,
    renderedItems,
  } = useTitleBuilder(opts);

  useEffect(() => {
    if (contributorChoices) {
      setChoices(camelize(contributorChoices));
    } else {
      fetchChoices();
    }
  }, []);

  const fetchChoices = () => {
    setChoicesFetching(true);

    fetch(urls.contributorChoices, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setChoicesFetching(false);
        setChoices(camelize(data));
      })
      .catch(err => {
        setChoicesFetching(false);
        console.error(err);
      });
  };

  const handleAddClick = () => {
    if (!selectedOption) return null;
    if (selectedOption === OPTION_CREATE_NEW) {
      setEditFormIsOpen(true);
    } else if (selectedOption === OPTION_TEXT_ONLY) {
      initNewItem({ contributor: null, role: '', content: '' });
      setTimeout(() => itemListRef.current.scrollTo(0, itemListRef.current.scrollHeight), 100);
    } else {
      initNewItem({ contributor: selectedOption, role: '', content: '' });
    }
    setSelectedOption(null);
  };

  const handleEditCancel = () => {
    setEditFormIsOpen(false);
    setTimeout(() => setEditingItemId(null), 1000);
  };

  const handleEditSave = record => {
    if (!editingItemId) {
      // New contributor was added
      initNewItem({ contributor: record.uuid, role: '' });
    }
    setEditFormIsOpen(false);
    setTimeout(() => setEditingItemId(null), 1000);
    fetchChoices();
  };

  const itemCountLabel = itemsOrdered.length === 1 ? 'Item' : 'Items';

  return (
    <ErrorBoundary>
      <div className={classNames('relation-field mt-1', disabled && 'disabled')} ref={containerRef}>
        {managementForm}
        <div className="controls top">
          <div className="item-count">{itemsOrdered.length} {itemCountLabel}</div>
          <div className="d-flex">
            <div style={{ width: 300, marginRight: 5 }}>
              <ContributorSelect
                contributors={choices}
                value={selectedOption}
                isLoading={choicesFetching}
                onChange={val => setSelectedOption(val)}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary"
              style={{ height: 'unset' }}
              disabled={!selectedOption}
              onClick={handleAddClick}
            >
              Add Credit
            </button>
          </div>
        </div>
        {hasError && <div className="error-list">Some of the items below contain errors.</div>}
        <div className="item-list" ref={itemListRef} style={{ height: expanded ? 'unset' : 350 }}>
          {renderedItems}
        </div>
        <div
          key={expanded ? 'up' : 'down'}
          className="expand-btn"
          title={`${expanded ? 'Collapse' : 'Expand'} field`}
          onClick={() => setExpanded(!expanded)}
        >
          <Icon name={`arrow_drop_${expanded ? 'up' : 'down'}`} />
        </div>
        <ContributorForm
          containerEl={containerRef.current}
          isOpen={editFormIsOpen}
          contributorId={editingItemId}
          onSave={handleEditSave}
          onCancel={handleEditCancel}
        />
      </div>
    </ErrorBoundary>
  );
};

CreditsWidget.propTypes = {
  parentId: PropTypes.string,
  parentFieldName: PropTypes.string,
  formsetData: PropTypes.object,
  contributorChoices: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
};

export default withDragDropContext(CreditsWidget);
