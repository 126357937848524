import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import Icon from 'components/common/Icon';
import { DeleteOverlay, ErrorList } from 'components/common/inlines';

const SectionItem = ({
  editMode,
  itemIndex,
  itemId,
  errors,
  prefix,
  shouldDelete: propsShouldDelete = false,
  fieldsOnly = false,
  fields,
  settingsActive,
  onSettingsClick,
  onDeleteClick = () => {},
  onLoadPreview,
}) => {
  const [shouldDelete, setShouldDelete] = useState(propsShouldDelete);
  const handleDeleteClick = () => {
    setShouldDelete(oldState => !oldState);
    onDeleteClick(itemId);
  };
  const handleSettingsClick = () => onSettingsClick(itemId);

  const {
    id, parent, sectionUid, type, title, media, inSectionNav, showSectionNav, showMainNav,
    linkText, slug, displayOptions,
  } = fields;

  let controlButtons;
  switch (editMode) {
    case 'insert':
      controlButtons = (
        <>
          <span
            className="btn-delete tooltip"
            key={shouldDelete}
            onClick={handleDeleteClick}
            data-tooltip={shouldDelete ? 'Undo Remove' : 'Remove Item'}
          >
            {shouldDelete ? <Icon name="undo" /> : <Icon name="delete" fill />}
          </span>

          <span
            className={`btn-action btn-settings bottom tooltip ${settingsActive ? 'active' : ''}`}
            onClick={handleSettingsClick}
            data-tooltip="Settings"
          >
            <Icon name="settings" fill size={22} />
          </span>
        </>
      );
      break;
    case 'sort':
      controlButtons = (
        <span key={editMode} className="btn-move tooltip" data-tooltip="Drag to Sort">
          <Icon name="menu" />
        </span>
      );
      break;
    default:
      break;
  }

  const preview = useMemo(() => {
    const previewStyles = {
      backgroundColor: 'transparent',
      padding: '2px 35px 2px 0',
    };
    return !!type && (
      <AttachedItemPreview
        itemType={type}
        itemId={fields[type]}
        onLoad={obj => onLoadPreview(itemId, obj)}
        style={previewStyles}
        clickableThumbnail={editMode === 'insert'}
      />
    );
  }, [type, fields[type], onLoadPreview, editMode]);

  const hiddenFields = (
    <>
      {shouldDelete && <input type="hidden" name={`${prefix}-DELETE`} value="1" />}
      {!!id && <input type="hidden" name={`${prefix}-id`} value={id} />}
      <input type="hidden" name={`${prefix}-parent`} value={parent} />
      <input type="hidden" name={`${prefix}-section_uid`} value={sectionUid} />
      <input type="hidden" name={`${prefix}-type`} value={type} />
      {!!title && <input type="hidden" name={`${prefix}-title`} value={title} />}
      {!!media && <input type="hidden" name={`${prefix}-media`} value={media} />}
      <input type="hidden" name={`${prefix}-index`} value={itemIndex} />
      {inSectionNav && <input type="hidden" name={`${prefix}-in_section_nav`} value="1" />}
      {showSectionNav && <input type="hidden" name={`${prefix}-show_section_nav`} value="1" />}
      {showMainNav && <input type="hidden" name={`${prefix}-show_main_nav`} value="1" />}
      <input type="hidden" name={`${prefix}-link_text`} value={linkText} />
      <input type="hidden" name={`${prefix}-slug`} value={slug} />
      <input type="hidden" name={`${prefix}-display_options`} value={JSON.stringify(displayOptions || {})} />
    </>
  );

  const hasErrors = Object.keys(errors).length > 0;

  return fieldsOnly ? hiddenFields : (
    <div key={itemId} className={hasErrors ? 'has-error' : null}>
      {hasErrors && <ErrorList className="mb-2" style={{ marginRight: 36 }} errors={errors} />}
      {preview}
      {hiddenFields}
      {shouldDelete && <DeleteOverlay onUndoClick={handleDeleteClick} />}
      {controlButtons}
    </div>
  );
};

SectionItem.propTypes = {
  editMode: PropTypes.string,
  itemIndex: PropTypes.number,
  itemId: PropTypes.string,
  errors: PropTypes.object,
  prefix: PropTypes.string,
  shouldDelete: PropTypes.bool,
  fieldsOnly: PropTypes.bool,
  fields: PropTypes.object,
  settingsActive: PropTypes.bool,
  onSettingsClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onLoadPreview: PropTypes.func,
};

export default SectionItem;
