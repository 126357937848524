import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import NavBuilder from 'components/views/NavBuilder';

const NavigationField = ({ useCustomNavField, navStyleField, navConfigField, releaseItems }) => {
  const [isEnabled, setIsEnabled] = useState(useCustomNavField.value);
  const [styleValue, setStyleValue] = useState(navStyleField.value);
  const [value, setValue] = useState(JSON.parse(navConfigField.value));

  const inputVal = JSON.stringify(value);
  const handleChange = useCallback(value => setValue(value), []);

  let errors = {};
  if (navConfigField.errors && navConfigField.errors.length > 0) {
    errors = JSON.parse(navConfigField.errors[0]);
  }

  return (
    <>
      <NavBuilder
        isEnabled={isEnabled}
        styleValue={styleValue}
        initialValue={value}
        errors={errors}
        releaseItems={releaseItems}
        onChange={handleChange}
        onEnabledChange={setIsEnabled}
        onStyleChange={setStyleValue}
      />
      <input type="hidden" name={navConfigField.name} value={inputVal} />
      <input type="hidden" name={navStyleField.name} value={styleValue} />
      {isEnabled && <input type="hidden" name={useCustomNavField.name} value="1" />}
    </>
  );
};


NavigationField.propTypes = {
  useCustomNavField: PropTypes.object,
  navStyleField: PropTypes.object,
  navConfigField: PropTypes.object,
  releaseItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    uid: PropTypes.string,
    objectId: PropTypes.string,
    objectUid: PropTypes.string,
    objectType: PropTypes.string,
    objectName: PropTypes.string,
    objectIconName: PropTypes.string,
  })),
};

export default NavigationField;
