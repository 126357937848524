import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import { formatters } from 'utils';
import { useSortableItems } from 'hooks';
import withDragDropContext from 'components/common/withDragDropContext';
import ErrorBoundary from 'components/common/ErrorBoundary';
import SortableItem from 'components/common/SortableItem';
import Item from './Item';


const SortableJsonKeyValueWidget = ({
  typeLabel,
  typeLabelPlural,
  helpText,
  keyLabel,
  valLabel,
  keyChoices,
  initialValue = [],
  errors = {},
  onChange = () => {},
}) => {
  const { itemsOrdered, setItemsOrdered, handleItemMove } = useSortableItems();
  const [itemsById, setItemsById] = useState({});
  const [errorsById, setErrorsById] = useState({});

  useEffect(() => {
    const { ordered, byId, errById } = initialValue.reduce((result, [itemKey, itemVal], idx) => {
      const id = uuid4();
      result.byId[id] = { itemKey, itemVal };
      result.ordered.push(id);

      if (errors[idx]) {
        result.errById[id] = errors[idx];
      }

      return result;
    }, { ordered: [], byId: {}, errById: {} });

    setItemsOrdered(ordered);
    setItemsById(byId);
    setErrorsById(errById);
  }, []);

  const handleAddItemClick = () => {
    const id = uuid4();
    setItemsById(oldState => ({ ...oldState, [id]: { itemKey: '', itemVal: '' } }));
    setItemsOrdered(oldState => [...oldState, id]);
  };

  useEffect(() => {
    const value = itemsOrdered.map(id => {
      const { itemKey, itemVal } = itemsById[id];
      return [itemKey, itemVal];
    });
    onChange(value);
  }, [JSON.stringify({ itemsOrdered, itemsById })]);

  const hasError = Object.keys(errors).length > 0;
  const itemCountLabel = itemsOrdered.length === 1 ? 'Item' : 'Items';
  const sortableItemType = `${formatters.slug(typeLabel)}-widget-items`;

  return (
    <div>
      <div className="form-group mb-2">
        <h5 className="m-0">{typeLabelPlural}</h5>
        {helpText && <div className="text-hint">{helpText}</div>}
      </div>
      <ErrorBoundary>
        <div className="relation-field mt-1">
          <div className="controls top">
            <div className="item-count">{itemsOrdered.length} {itemCountLabel}</div>
            <button type="button" className="btn btn-primary" onClick={handleAddItemClick}>Add {typeLabel}</button>
          </div>
          {hasError && <div className="error-list">Some of the items below contain errors.</div>}
          <div className="item-list">
            {itemsOrdered.map((id, idx) => {
              const { itemKey, itemVal } = itemsById[id];
              const itemErrors = errorsById[id];
              const handleChange = val => setItemsById(oldState => ({ ...oldState, [id]: val }));
              const handleDelete = () => {
                setItemsOrdered(oldState => oldState.filter(itemId => itemId !== id));
                setItemsById(oldState => Object.entries(oldState).reduce((result, [key, val]) => {
                  if (key !== id) result[key] = val;
                  return result;
                }, {}));
              };
              return (
                <SortableItem
                  key={id}
                  itemId={id}
                  type={sortableItemType}
                  className="mb-4"
                  onItemMove={handleItemMove}
                >
                  <Item
                    itemKey={itemKey}
                    itemVal={itemVal}
                    keyLabel={keyLabel}
                    valLabel={valLabel}
                    keyChoices={keyChoices}
                    errors={itemErrors}
                    onChange={handleChange}
                    onDeleteClick={handleDelete}
                  />
                </SortableItem>
              );
            })}
          </div>
        </div>
      </ErrorBoundary>
    </div>
  );
};

SortableJsonKeyValueWidget.propTypes = {
  typeLabel: PropTypes.string,
  typeLabelPlural: PropTypes.string,
  helpText: PropTypes.string,
  keyLabel: PropTypes.string,
  valLabel: PropTypes.string,
  keyChoices: PropTypes.arrayOf(PropTypes.string),
  initialValue: PropTypes.arrayOf(PropTypes.array),
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default withDragDropContext(SortableJsonKeyValueWidget);
