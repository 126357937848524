import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import striptags from 'striptags';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { formatters } from 'utils';
import Slider from 'rc-slider';
import Checkbox from 'components/common/Checkbox';
import { ErrorList } from 'components/common/inlines';
import QuillEditor from 'components/common/QuillEditor';
import MediaRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/MediaRelationField';
import Color from 'components/common/DjangoFormField/Color';
import Select from 'components/common/Select';
import slideFormDefaults from './slideFormDefaults';
import RelatedItemsField from './RelatedItemsField';

export const nameSourceOptions = [
  { value: 'media_name', label: 'Use media name' },
  { value: 'custom', label: 'Use custom name' },
  { value: 'none', label: 'None' },
];

export const descriptionSourceOptions = [
  { value: 'media_long_desc', label: 'Use media long description' },
  { value: 'media_short_desc', label: 'Use media short description' },
  { value: 'custom', label: 'Use custom description' },
  { value: 'none', label: 'None' },
];

export const creditSourceOptions = [
  { value: 'media_credit', label: 'Use media credit' },
  { value: 'custom', label: 'Use custom credit' },
  { value: 'none', label: 'None' },
];

export const selectFieldOptions = {
  nameSource: nameSourceOptions,
  descriptionSource: descriptionSourceOptions,
  creditSource: creditSourceOptions,
};

class Form extends Component {
  constructor (props) {
    super(props);
    this.defaultState = {
      itemId: null,
      fields: { ...slideFormDefaults },
      mediaData: null,
      processing: false,
      error: null,
    };
    this.state = {
      ...this.defaultState,
    };

    this.fieldPrefix = 'slideshowBuilderForm-';
    this.numericFields = [];
    this.requiredFields = ['media'];

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleCreditChange = this.handleCreditChange.bind(this);
    this.handleMediaChange = this.handleMediaChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleOverlayOpacityChange = this.handleOverlayOpacityChange.bind(this);
    this.initFormValues = this.initFormValues.bind(this);
    this.handleRelatedItemsChange = this.handleRelatedItemsChange.bind(this);
    this.handleTextSlideChange = this.handleTextSlideChange.bind(this);
    this.handleLayoutChange = this.handleLayoutChange.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormChange = debounce(this.handleFormChange, 500);
    this.getItemObject = this.getItemObject.bind(this);
    this.errorClass = this.errorClass.bind(this);
  }

  componentDidMount () {
    this.initFormValues();
    const mediaId = this.props.item.media;
    if (mediaId) {
      this.fetchData(mediaId);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { item } = this.props;
    const { mediaData } = this.state;
    const prevItem = prevProps.item;

    if (!isEqual(item, prevItem)) {
      this.initFormValues();
    }

    if (item.media !== prevItem.media) {
      if (!item.media) {
        this.setState({ mediaData: null, processing: false, error: null });
      } else {
        this.fetchData(item.media);
      }
    }

    if (item.layout === 'text' && mediaData && mediaData.type !== 'Image') {
      this.handleMediaChange(null);
      this.setState({ mediaData: null, processing: false, error: null });
    }

    if (
      (item && !prevItem) || (!item && prevItem) ||
      (item && prevItem && item.itemId !== prevItem.itemId)
    ) {
      // Reset form scroll position when a different item is selected
      if (this.containerRef) {
        this.containerRef.scrollTop = 0;
      }
    }
  }

  componentWillUnmount () {
    this.handleFormChange.flush();
  }

  fetchData (mediaId) {
    const url = urlJoin(urls.mediaDataBase, mediaId, '?format=json');
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState(oldState => ({
          mediaData: data,
          processing: false,
          fields: {
            ...oldState.fields,
            slug: oldState.fields.slug || formatters.slug(data.name),
          },
        }));
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  handleInputChange (e) {
    const { fields } = this.state;
    let { name, value, type, checked } = e.target;
    name = name.replace(this.fieldPrefix, '');
    if (value && this.numericFields.includes(name)) {
      value = parseFloat(value);
    } else if (type === 'checkbox') {
      value = checked;
    } else if (name === 'slug') {
      value = formatters.slug(value);
    }
    this.setState({
      fields: {
        ...fields,
        [name]: value,
      },
    }, this.handleFormChange);
  }

  handleSelectChange (field, value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        [field]: value,
      },
    }, this.handleFormChange);
  }

  handleDescriptionChange (value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        description: value,
      },
    }, this.handleFormChange);
  }

  handleCreditChange (value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        credit: value,
      },
    }, this.handleFormChange);
  }

  handleMediaChange (value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        media: value,
      },
    }, this.handleFormChange);
  }

  handleTextChange (value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        text: value,
      },
    }, this.handleFormChange);
  }

  handleOverlayOpacityChange (value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        overlayOpacity: value,
      },
    });
  }

  handleTextSlideChange (e) {
    const { fields } = this.state;
    const checked = e.target.checked;
    const isBleed = ['bleed', 'text-bleed'].includes(fields.layout);
    let value = '';
    if (isBleed && checked) {
      value = 'text-bleed';
    } else if (isBleed) {
      value = 'bleed';
    } else if (checked) {
      value = 'text-fit';
    }

    this.setState({
      fields: {
        ...fields,
        layout: value,
      },
    }, this.handleFormChange);
  }

  handleLayoutChange (e) {
    const { fields } = this.state;
    const inputVal = e.target.value;
    const isText = ['text-fit', 'text-bleed'].includes(fields.layout);
    let value = inputVal;
    if (isText) value = `text-${inputVal || 'fit'}`;

    this.setState({
      fields: {
        ...fields,
        layout: value,
      },
    }, this.handleFormChange);
  }

  handleRelatedItemsChange (value) {
    const { onRelatedItemsChange } = this.props;
    onRelatedItemsChange(value);
  }

  handleFormChange () {
    const { onChange, item } = this.props;
    const itemObj = this.getItemObject();

    if (item) {
      // Only call this if we are editing an existing item, not a blank one.
      onChange({ ...itemObj });
    }
  }

  getItemObject () {
    // Convert empty strings to null
    return Object.keys(this.state.fields).reduce((result, key) => {
      result[key] = this.state.fields[key] === '' ? null : this.state.fields[key];
      return result;
    }, {});
  }

  initFormValues () {
    const { item } = this.props;

    if (item) {
      const newFields = Object.keys(item).reduce((result, key) => {
        if (this.defaultState.fields.hasOwnProperty(key)) {
          // Keep explicit false for checkbox fields, but convert undefined or null to empty strings
          result[key] = (item[key] || item[key] === false) ? item[key] : '';
        }
        return result;
      }, {});
      this.setState({ fields: newFields, itemId: item.itemId });
    } else {
      this.setState({ fields: this.defaultState.fields, itemId: null });
    }
  }

  errorClass (fieldName) {
    const { item } = this.props;
    return item && item.errors[fieldName] ? 'has-error' : '';
  }

  renderNameInput () {
    const { mediaData } = this.state;
    const { name, nameSource } = this.state.fields;

    switch (nameSource) {
      case 'media_name':
        return <input key="nameSource1" type="text" className="form-input" value={mediaData ? mediaData.name : ''} disabled />;
      case 'custom':
        return (
          <input
            key="nameSource2"
            name={this.fieldPrefix + 'name'}
            type="text"
            className="form-input"
            value={name}
            onChange={this.handleInputChange}
          />
        );
      case 'none':
        // Intentionally fall through
      default:
        return <input key="nameSource3" type="text" className="form-input" disabled />;
    }
  }

  renderDescriptionInput () {
    const { mediaData } = this.state;
    const { description, descriptionSource } = this.state.fields;

    switch (descriptionSource) {
      case 'media_long_desc':
        return (
          <textarea
            key="descriptionSource1"
            cols="30"
            rows="3"
            className="form-input"
            value={mediaData ? striptags(mediaData.longDescription) : ''}
            disabled
          />
        );
      case 'media_short_desc':
        return (
          <textarea
            key="descriptionSource2"
            cols="30"
            rows="3"
            className="form-input"
            value={mediaData ? striptags(mediaData.shortDescription) : ''}
            disabled
          />
        );
      case 'custom':
        return (
          <QuillEditor
            inputName={this.fieldPrefix + 'description'}
            value={description}
            onChange={this.handleDescriptionChange}
            size="small"
          />
        );
      case 'none':
        // Intentionally fall through
      default:
        return <textarea key="descriptionSource3" cols="30" rows="3" className="form-input" disabled />;
    }
  }

  renderCreditInput () {
    const { mediaData } = this.state;
    const { credit, creditSource } = this.state.fields;

    switch (creditSource) {
      case 'media_credit':
        return (
          <textarea
            key="creditSource1"
            cols="30"
            rows="3"
            className="form-input"
            value={mediaData ? striptags(mediaData.credit) : ''}
            disabled
          />
        );
      case 'custom':
        return (
          <QuillEditor
            inputName={this.fieldPrefix + 'credit'}
            value={credit}
            onChange={this.handleCreditChange}
            size="small"
          />
        );
      case 'none':
        // Intentionally fall through
      default:
        return <input key="creditSource3" type="text" className="form-input" disabled />;
    }
  }

  render () {
    const { item, index, relatedItems } = this.props;
    const {
      layout,
      nameSource,
      descriptionSource,
      creditSource,
      media,
      slug,
      text,
      overlayColor,
      textColor,
      overlayOpacity,
    } = this.state.fields;

    if (!item) {
      return null;
    }
    const hasErrors = Object.keys(item.errors).length > 0;

    const nameSourceValue = nameSourceOptions.find(opt => opt.value === nameSource);
    const descriptionSourceValue = descriptionSourceOptions.find(opt => opt.value === descriptionSource);
    const creditSourceValue = creditSourceOptions.find(opt => opt.value === creditSource);

    const isText = ['text-fit', 'text-bleed'].includes(layout);

    return (
      <div className="title-builder-form-container" ref={node => this.containerRef = node}>
        <header className="title-builder-form-header">
          <div>
            <h5><span>Editing:</span> Slide {index}</h5>
          </div>
        </header>

        <div className="title-builder-form">
          {hasErrors ? <ErrorList className="mb-5" errors={item.errors} /> : null}

          <div className={`form-group mb-5 ${this.errorClass('media')}`}>
            <label className="block-label">Media</label>
            <p className="form-input-hint m-0">
              {isText
                ? 'Use the form below to select a background image for this slide.'
                : 'Use the form below to select an image or video for this slide.'}
            </p>
            <MediaRelationField
              limitTypes={isText ? ['image'] : ['image', 'video']}
              value={media}
              onChange={this.handleMediaChange}
            />
          </div>

          <div className={`form-group mb-5 ${this.errorClass('layout')}`}>
            <label className="form-checkbox my-0">
              <Checkbox
                onChange={this.handleTextSlideChange}
                checked={isText}
              />
              <i className="form-icon" />
              Text Slide <span className="form-input-hint">– Display as text slide with optional background image.</span>
            </label>
          </div>

          <div className={`form-group mb-5 ${this.errorClass('layout')}`}>
            <label className="block-label">Image Display</label>
            <label className="form-radio m-0">
              <input
                type="radio"
                value=""
                checked={['text-fit', ''].includes(layout)}
                onChange={this.handleLayoutChange}
              />
              <i className="form-icon" /> Fit <span className="form-input-hint">– {isText ? 'Background image' : 'Image'} fits within the slide area, preserving original crop.</span>
            </label>
            <label className="form-radio m-0">
              <input
                type="radio"
                value="bleed"
                checked={['text-bleed', 'bleed'].includes(layout)}
                onChange={this.handleLayoutChange}
              />
              <i className="form-icon" /> Full Bleed <span className="form-input-hint">– {isText ? 'Background image' : 'Image'} extends to cover the slide area. Some cropping may occur.</span>
            </label>
          </div>

          {isText && (
            <>
              <div className={classNames('form-group', 'mb-4', this.errorClass('text'))}>
                <label className="block-label">Text</label>
                <p className="form-input-hint m-0 mb-1">This text will appear overlaid on the slide.</p>
                <QuillEditor
                  inputName={this.fieldPrefix + 'text'}
                  value={text}
                  onChange={this.handleTextChange}
                  size="large"
                  useFullMenu
                />
              </div>

              <div className="columns">
                <div className="column col-3">
                  <div className={`form-group mb-4 ${this.errorClass('textColor')}`}>
                    <label className="block-label">Text Color</label>
                    <Color
                      name={this.fieldPrefix + 'textColor'}
                      value={textColor}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="column col-3">
                  <div className={`form-group mb-4 ${this.errorClass('overlayColor')}`}>
                    <label className="block-label">Overlay Color</label>
                    <Color
                      name={this.fieldPrefix + 'overlayColor'}
                      value={overlayColor}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </div>

                <div className="column col-6">
                  <div className={`form-group mb-4 ${this.errorClass('overlayOpacity')}`}>
                    <label className="block-label">Overlay Opacity</label>
                    <div className="d-flex align-items-center">
                      <div className="slider-field" style={{ flex: 1 }}>
                        <Slider
                          value={overlayOpacity}
                          onChange={this.handleOverlayOpacityChange}
                          onAfterChange={this.handleFormChange}
                        />
                      </div>
                      <div className="ml-2">
                        <div className="input-group">
                          <input
                            name={this.fieldPrefix + 'overlayOpacity'}
                            type="text"
                            className="form-input input-sm"
                            style={{ width: 35 }}
                            value={overlayOpacity}
                            onChange={this.handleInputChange}
                          />
                          <span className="input-group-addon addon-sm">%</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr className="mt-0 mb-3" />
            </>
          )}

          <div className={classNames('form-group', 'mb-3', this.errorClass('name'), this.errorClass('nameSource'))}>
            <label className="block-label">Name</label>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
                <Select
                  value={nameSourceValue}
                  options={nameSourceOptions}
                  onChange={val => this.handleSelectChange('nameSource', val)}
                />
              </div>
              <div style={{ flex: 1, minWidth: 300 }}>
                {this.renderNameInput()}
              </div>
            </div>
          </div>

          <div className={classNames('form-group', 'mb-3', this.errorClass('description'), this.errorClass('descriptionSource'))}>
            <label className="block-label">Description</label>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
                <Select
                  value={descriptionSourceValue}
                  options={descriptionSourceOptions}
                  onChange={val => this.handleSelectChange('descriptionSource', val)}
                />
              </div>
              <div style={{ flex: 1, minWidth: 300 }}>
                {this.renderDescriptionInput()}
              </div>
            </div>
          </div>

          <div className={classNames('form-group', 'mb-3', this.errorClass('credit'), this.errorClass('creditSource'))}>
            <label className="block-label">Credit</label>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
                <Select
                  value={creditSourceValue}
                  options={creditSourceOptions}
                  onChange={val => this.handleSelectChange('creditSource', val)}
                />
              </div>
              <div style={{ flex: 1, minWidth: 300 }}>
                {this.renderCreditInput()}
              </div>
            </div>
          </div>

          <div className={classNames('form-group', 'mb-3', this.errorClass('slug'))}>
            <label className="block-label">URL Slug</label>
            <input
              key="slug"
              name={this.fieldPrefix + 'slug'}
              type="text"
              className="form-input"
              value={slug}
              onChange={this.handleInputChange}
            />
            <p className="form-input-hint m-0 mt-1">This text will appear in the URL and must contain only lowercase letters, numbers, and hyphens.</p>
          </div>

          <hr className="mt-0 mb-3" />

          <RelatedItemsField
            name="relatedItems"
            label="Related Content"
            helpText="Use the form below to add related links or media to this slide."
            value={relatedItems}
            onChange={this.handleRelatedItemsChange}
          />
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  relatedItems: PropTypes.array,
  onChange: PropTypes.func,
  onRelatedItemsChange: PropTypes.func,
};

export default Form;
