import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import { urls } from 'app-constants';
import { formatters } from 'utils';
import Icon from 'components/common/Icon';
import { OBJECT_TYPE_TITLE, OBJECT_TYPE_RELEASE } from './objectTypes';


class TypePicker extends Component {
  constructor (props) {
    super(props);
    this.state = {
      processing: false,
      objectTypes: [],
      error: null,
    };

    // Configure API endpoints according to object type
    switch (props.objectBaseType) {
      case OBJECT_TYPE_TITLE:
        this.typeListUrl = urls.titleTypes;
        break;
      case OBJECT_TYPE_RELEASE:
        this.typeListUrl = urls.releaseTypes;
        break;
      default:
        break;
    }
  }

  componentDidMount () {
    this.fetchObjectTypes();
  }

  fetchObjectTypes () {
    this.setState({ processing: true });
    fetch(this.typeListUrl, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText || 'Bad Request' });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        data.sort((a, b) => a.name.localeCompare(b.name));
        this.setState({ objectTypes: data, processing: false });
      })
      .catch(err => {
        this.setState({ processing: false, error: 'Network error' });
        console.error(err);
      });
  }

  render () {
    const { activeOption, onSelect } = this.props;
    const { objectTypes, processing, error } = this.state;
    const description = activeOption ? objectTypes.find(t => t.name === activeOption).description : null;
    return (
      <div className="mt-4">
        <h6 className="identifier">Type</h6>
        <div className="icon-option-list" style={{ minHeight: 120 }}>
          {processing ? <div className="loading-spinner" /> : null}
          {error ? <div className="toast toast-error">Error: {error}</div> : null}
          {objectTypes.map(({ name, iconName }) => (
            <a
              key={name} href={`#${formatters.slug(name)}`}
              className={activeOption === name ? 'active' : null}
              onClick={e => { e.preventDefault(); onSelect(name); }}
            >
              <div className="option-icon">
                <Icon name={iconName} size={48} fill />
              </div>
              <div className="option-label">{name}</div>
            </a>
          ))}
        </div>
        {description ? <div><hr />{description}</div> : null}
      </div>
    );
  }
}

TypePicker.propTypes = {
  activeOption: PropTypes.string,
  onSelect: PropTypes.func,
  objectBaseType: PropTypes.oneOf([OBJECT_TYPE_TITLE, OBJECT_TYPE_RELEASE]),
};

export default TypePicker;
