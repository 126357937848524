import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import urlJoin from 'url-join';
import camelize from 'camelize';
import { urls } from 'app-constants';
import Select from 'components/common/Select';
import MapStyleSelect from './MapStyleSelect';


class MapboxWidget extends Component {
  constructor (props) {
    super(props);
    this.state = {
      integrationId: props.mapboxIntegrationField.value || '',
      mapStyle: props.mapStyleField.value,
      mapStyleChoices: [],
      accountChoices: props.mapboxIntegrationField.choices.filter(([id, label]) => !!id).map(([id, label]) => ({ value: id, label })),
      stylesFetching: false,
      activeAccount: {},
    };

    this.handleAccountChange = this.handleAccountChange.bind(this);
    this.handleStyleChange = this.handleStyleChange.bind(this);
  }

  componentDidMount () {
    const { mapboxIntegrationField } = this.props;
    const { accountChoices } = this.state;

    if (!mapboxIntegrationField.value && accountChoices.length > 0) {
      this.setState({ integrationId: accountChoices[0].value });
    }

    this.fetchStyles();
  }

  componentDidUpdate (prevProps, prevState) {
    const { integrationId, mapStyleChoices, mapStyle } = this.state;

    if (integrationId !== prevState.integrationId) {
      this.fetchStyles();
    }

    if (mapStyleChoices !== prevState.mapStyleChoices) {
      if (mapStyle && !mapStyleChoices.find(c => c.url === mapStyle)) {
        this.setState({ mapStyle: '' });
      }
    }
  }

  handleAccountChange (val) {
    this.setState({ integrationId: val });
  }

  handleStyleChange (val) {
    this.setState({ mapStyle: val });
  }

  fetchStyles () {
    const { integrationId } = this.state;
    if (!integrationId) {
      return null;
    }
    const url = urlJoin(urls.mapboxStylesBase, integrationId, '/');
    this.setState({ stylesFetching: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({
          mapStyleChoices: data.styles,
          activeAccount: data.metadata,
          stylesFetching: false,
        });
      })
      .catch(err => {
        this.setState({ error: err });
        console.error(err);
      });
  }

  renderHiddenFields () {
    const { mapboxIntegrationField, mapStyleField } = this.props;
    const { integrationId, mapStyle } = this.state;

    return (
      <>
        <input type="hidden" name={mapboxIntegrationField.name} value={integrationId} />
        <input type="hidden" name={mapStyleField.name} value={mapStyle} />
      </>
    );
  }

  render () {
    const { mapboxIntegrationField, mapStyleField } = this.props;
    const { integrationId, mapStyle, mapStyleChoices, accountChoices, activeAccount } = this.state;
    const accountErrors = mapboxIntegrationField.errors;
    const styleErrors = mapStyleField.errors;

    const accountVal = accountChoices.find(c => c.value === integrationId);

    return (
      <div>
        <div className={classNames('form-group', 'mb-4', accountErrors.length && 'has-error')}>
          <h5>Account</h5>
          <Select
            value={accountVal}
            options={accountChoices}
            onChange={this.handleAccountChange}
          />
          {accountErrors.length > 0 && (
            <p className="form-input-hint">
              {accountErrors.map((e, idx) => <Fragment key={idx}><span>{e}</span><br /></Fragment>)}
            </p>
          )}
        </div>
        <div className={classNames('form-group', 'mb-4', styleErrors.length && 'has-error')}>
          <h5>Map style</h5>
          <MapStyleSelect
            value={mapStyle}
            styles={mapStyleChoices}
            onChange={this.handleStyleChange}
            accessToken={activeAccount.publicAccessToken}
          />
          {styleErrors.length > 0 && (
            <p className="form-input-hint">
              {styleErrors.map((e, idx) => <Fragment key={idx}><span>{e}</span><br /></Fragment>)}
            </p>
          )}
        </div>
        {this.renderHiddenFields()}
      </div>
    );
  }
}

MapboxWidget.propTypes = {
  mapboxIntegrationField: PropTypes.object,
  mapStyleField: PropTypes.object,
};

export default MapboxWidget;
