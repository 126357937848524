import React from 'react';
import { CUSTOM_TITLE_FIELD_PROPTYPES } from './';
import FieldWrapper from './FieldWrapper';


const TextField = props => {
  const { name, value, autoFocus, onChange } = props;
  return (
    <FieldWrapper {...props}>
      <input
        type="text"
        className="form-input"
        name={name}
        value={value}
        autoFocus={autoFocus}
        onChange={e => onChange(e.target.value)}
      />
    </FieldWrapper>
  );
};

TextField.propTypes = {
  ...CUSTOM_TITLE_FIELD_PROPTYPES,
};

TextField.defaultProps = {
  onChange: () => null,
};

export default TextField;
