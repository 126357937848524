import React from 'react';
import PropTypes from 'prop-types';


const FacebookLinkPreview = ({ data }) => {
  if (!data) {
    return null;
  }
  const { image, title, description } = data;
  const thstyle = {
    paddingLeft: 0,
    width: 100,
    verticalAlign: 'top',
  };

  return (
    <div>
      <h5 className="mb-1">Link metadata</h5>
      <p className="text-hint">Facebook generates a link preview automatically based on the target page’s metadata, displayed below.</p>
      <hr className="mb-0" />
      {!image && !title && !description ? (
        <div className="p-4 text-center"><em>No metadata was found for this URL.</em></div>
      ) : (
        <table className="table">
          <tbody>
            {image ? (
              <tr>
                <td style={thstyle}><strong>Image</strong></td>
                <td><img src={image} alt="og:image" style={{ display: 'block', maxWidth: 500 }} /></td>
              </tr>
            ) : null}
            {title ? (
              <tr>
                <td style={thstyle}><strong>Title</strong></td>
                <td>{title}</td>
              </tr>
            ) : null}
            {description ? (
              <tr>
                <td style={thstyle}><strong>Description</strong></td>
                <td>{description}</td>
              </tr>
            ) : null}
          </tbody>
        </table>
      )}
    </div>
  );
};

FacebookLinkPreview.propTypes = {
  data: PropTypes.object,
};

export default FacebookLinkPreview;
