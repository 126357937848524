import { useState, useEffect } from 'react';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';

export default function useItemData (type, id) {
  if (type && !['media', 'title'].includes(type)) {
    throw new Error('Invalid object type');
  }

  const [itemData, setItemData] = useState(null);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  const fetchData = () => {
    const baseUrl = urls[`${type}DataBase`];
    const url = urlJoin(baseUrl, id, '?format=json&include_images=true');

    setError(null);
    setProcessing(true);

    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        setItemData(data);
        setProcessing(false);
      })
      .catch(err => {
        setError(err.message);
        setProcessing(false);
        console.error(err);
      });
  };

  useEffect(() => {
    if (type && id) {
      fetchData();
    } else {
      setError(null);
      setItemData(null);
      setProcessing(false);
    }
  }, [type, id]);

  return { itemData, error, processing };
}
