import modal from 'modal';


const forms = document.querySelectorAll('form[data-confirm-submit]');
forms.forEach(form => {
  const message = form.dataset.confirmSubmit || 'Are you sure?';
  const cancelText = form.dataset.cancelText || 'Cancel';
  const confirmText = form.dataset.confirmText || 'Confirm';

  form.addEventListener('submit', e => {
    e.preventDefault();
    modal.dialog.confirm({
      message,
      callback: val => val && form.submit(),
      buttons: [
        { ...modal.dialog.buttons.YES, text: confirmText },
        { ...modal.dialog.buttons.NO, text: cancelText },
      ],
    });
  });
});
