import React from 'react';
import PropTypes from 'prop-types';
import servicesConfig from './config';


const ServicesList = ({ selected, onSelect }) => (
  <ul className="integrations-services-list">
    {servicesConfig.map(({ serviceName, serviceLabel }) => (
      <li key={serviceName}>
        <a
          href={'#' + serviceName}
          className={selected === serviceName ? 'active' : null}
          onClick={e => { e.preventDefault(); onSelect(serviceName); }}
        >
          <div className={`integrations-services-list-icon service-icon-${serviceName}`} />
          <h4 className="m-0">{serviceLabel}</h4>
        </a>
      </li>
    ))}
  </ul>
);

ServicesList.propTypes = {
  selected: PropTypes.string,
  onSelect: PropTypes.func,
};

export default ServicesList;
