import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import { DeleteOverlay, ErrorList } from 'components/common/inlines';
import Icon from 'components/common/Icon';

class Item extends Component {
  constructor (props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleSettingsClick = this.handleSettingsClick.bind(this);
  }

  handleDeleteClick (e) {
    const { onDeleteClick } = this.props;
    e.stopPropagation();
    onDeleteClick();
  }

  handleSettingsClick () {
    const { onSettingsClick, itemId } = this.props;
    onSettingsClick(itemId);
  }

  renderControlButtons () {
    const { settingsActive, shouldDelete } = this.props;

    return (
      <>
        <span className="btn-move tooltip tooltip-left" data-tooltip="Drag to Sort">
          <Icon name="menu" />
        </span>

        <span
          className="btn-delete tooltip tooltip-left"
          key={shouldDelete}
          onClick={this.handleDeleteClick}
          data-tooltip={shouldDelete ? 'Undo Remove' : 'Remove Item'}
        >
          {shouldDelete ? <Icon name="undo" /> : <Icon name="delete" fill />}
        </span>

        <span
          className={`btn-action btn-settings tooltip tooltip-left ${settingsActive ? 'active' : ''}`}
          onClick={this.handleSettingsClick}
          data-tooltip="Settings"
        >
          <Icon name="settings" fill size={22} />
        </span>
      </>
    );
  }

  renderPreview () {
    const { type, onLoadPreview } = this.props;
    const itemId = this.props[type];
    const previewStyles = {
      backgroundColor: 'transparent',
      padding: '2px 35px 2px 0',
    };

    return !type ? null : (
      <AttachedItemPreview
        itemType={type}
        itemId={itemId}
        onLoad={onLoadPreview}
        style={previewStyles}
      />
    );
  }

  renderHiddenFields () {
    const { counter, prefix, shouldDelete, id, parent, type, title, media, linkText, slug, displayOptions } = this.props;

    return (
      <>
        {shouldDelete ? <input type="hidden" name={`${prefix}-DELETE`} value="1" /> : null}
        {id ? <input type="hidden" name={`${prefix}-id`} value={id} /> : null}
        <input type="hidden" name={`${prefix}-parent`} value={parent} />
        <input type="hidden" name={`${prefix}-type`} value={type} />
        {title ? <input type="hidden" name={`${prefix}-title`} value={title} /> : null}
        {media ? <input type="hidden" name={`${prefix}-media`} value={media} /> : null}
        <input type="hidden" name={`${prefix}-index`} value={counter} />
        <input type="hidden" name={`${prefix}-link_text`} value={linkText} />
        <input type="hidden" name={`${prefix}-slug`} value={slug} />
        <input type="hidden" name={`${prefix}-display_options`} value={JSON.stringify(displayOptions || {})} />
      </>
    );
  }

  render () {
    const { counter, itemId, errors, shouldDelete } = this.props;
    const hasErrors = Object.keys(errors).length;

    const containerClasses = classNames({
      error: Object.keys(errors).length,
      'title-builder-item': true,
      'to-delete': shouldDelete,
    });

    return (
      <div key={itemId}>
        {hasErrors ? <ErrorList style={{ borderWidth: '1px 0 0' }} errors={errors} /> : null}
        <div key={itemId} className={containerClasses}>
          <div className="index-counter">{counter}</div>
          {this.renderPreview()}
          {this.renderHiddenFields()}
          {this.renderControlButtons()}
          {shouldDelete ? <DeleteOverlay onUndoClick={this.handleDeleteClick} /> : null}
        </div>
      </div>
    );
  }
}

Item.propTypes = {
  counter: PropTypes.number,
  itemId: PropTypes.string,
  errors: PropTypes.object,
  prefix: PropTypes.string,
  shouldDelete: PropTypes.bool,
  settingsActive: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  onSettingsClick: PropTypes.func,
  onLoadPreview: PropTypes.func,
  id: PropTypes.string,
  parent: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  media: PropTypes.string,
  linkText: PropTypes.string,
  slug: PropTypes.string,
  displayOptions: PropTypes.object,
};

export default Item;
