import { useEffect } from 'react';

export default function useClickOutside (elementRef, callback) {
  useEffect(() => {
    const handleClickOutside = evt => {
      if (elementRef.current && !elementRef.current.contains(evt.target)) {
        callback(evt);
      }
    };

    document.addEventListener('click', handleClickOutside);

    // This catches clicks over an iframe, like a video embed
    window.addEventListener('blur', callback);

    return () => {
      document.removeEventListener('click', handleClickOutside);
      window.removeEventListener('blur', callback);
    };
  }, [elementRef, callback]);
}
