import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const PreserveParamsField = ({ location }) => (
  <input type="hidden" name="_preserve_params" value={location.search.replace('?', '')} />
);

PreserveParamsField.propTypes = {
  location: PropTypes.object,
};

export default withRouter(PreserveParamsField);
