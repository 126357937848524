import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import classNames from 'classnames';
import { urls } from 'app-constants';
import { useCSRF } from 'hooks';
import Modal from 'components/common/Modal';


const CreatePreset = ({ show, templateOptions, onCreate, onRequestClose }) => {
  const [processing, setProcessing] = useState(false);

  const [name, setName] = useState('');
  const handleNameChange = e => setName(e.target.value);

  const [templateId, setTemplateId] = useState(templateOptions[0].id);
  const handleTemplateChange = e => setTemplateId(e.target.value);

  const [fieldErrors, setFieldErrors] = useState({});

  // reset state values when hidden
  useEffect(() => {
    if (!show) {
      setName('');
      setTemplateId(templateOptions[0].id);
      setProcessing(false);
      setFieldErrors({});
    }
  }, [show]);

  const csrfToken = useCSRF();
  const handleFormSubmit = e => {
    e.preventDefault();
    setProcessing(true);

    const body = JSON.stringify({ name, template: templateId });
    fetch(urls.presets, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (response.status === 400) {
          // Form validation errors
          response.json().then(data => {
            setProcessing(false);
            setFieldErrors(camelize(data));
          });
        } else if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          response.json().then(data => {
            setProcessing(false);
            onCreate(camelize(data));
          });
        }
        return response;
      })
      .catch(err => {
        setProcessing(false);
        console.error(err);
      });
  };

  return (
    <Modal
      title="New Preset"
      style={{ content: { width: 400 } }}
      isOpen={show}
      onRequestClose={onRequestClose}
    >
      <form onSubmit={handleFormSubmit}>
        <div className={classNames('form-group', fieldErrors.name && 'has-error')}>
          <label>Name</label>
          <div className="has-icon-right" style={{ flex: 1, display: 'flex' }}>
            <input
              type="text"
              className="form-input input-lg"
              readOnly={processing}
              onChange={handleNameChange}
              value={name}
              autoFocus
            />
            {processing ? <i className="form-icon loading" /> : null}
          </div>
          {(fieldErrors.name || []).map((msg, idx) => <p key={idx} className="form-input-hint">{msg}</p>)}
        </div>

        {templateOptions.length > 1 && (
          <div className={classNames('form-group', fieldErrors.template && 'has-error')}>
            <label>Template</label>
            <select className="form-select" value={templateId} onChange={handleTemplateChange}>
              {templateOptions.map(({ id, name }) => (
                <option key={id} value={id}>{name}</option>
              ))}
            </select>
            {(fieldErrors.template || []).map((msg, idx) => <p key={idx} className="form-input-hint">{msg}</p>)}
          </div>
        )}

        <div className="modal-action-buttons mt-4">
          <button className="btn" type="button" onClick={onRequestClose}>Cancel</button>
          <button className="btn btn-primary" type="submit" disabled={!name}>Create</button>
        </div>
      </form>
    </Modal>
  );
};

CreatePreset.propTypes = {
  show: PropTypes.bool,
  templateOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })).isRequired,
  onCreate: PropTypes.func,
  onRequestClose: PropTypes.func,
};

export default CreatePreset;
