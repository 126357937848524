import React, { Component } from 'react';
import PropTypes from 'prop-types';


class Checkbox extends Component {
  componentDidMount () {
    this.el.indeterminate = this.props.indeterminate;
  }

  componentDidUpdate (prevProps) {
    if (prevProps.indeterminate !== this.props.indeterminate) {
      this.el.indeterminate = this.props.indeterminate;
    }
  }

  render () {
    const { indeterminate, ...rest } = this.props;
    return (
      <input {...rest} type="checkbox" ref={el => this.el = el} />
    );
  }
}

Checkbox.propTypes = {
  indeterminate: PropTypes.bool,
};

Checkbox.defaultProps = {
  indeterminate: false,
};

export default Checkbox;
