import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/common/Icon';
import { DeleteOverlay } from 'components/common/inlines';
import ItemContentStandard from './ItemContentStandard';
import ItemContentText from './ItemContentText';

const Item = props => {
  const {
    className,
    contributorData,
    parentId,
    parentFieldName,
    counter,
    itemId,
    prefix,
    errors = {},
    id: idFieldValue,
    role: roleFieldValue,
    contributor: contributorFieldValue,
    content: contentFieldValue,
    connectDragSource = children => children,
    omitFields = false,
    shouldDelete,
    onEditClick,
    onDeleteClick,
  } = props;

  const isTextOnly = !contributorFieldValue;
  const hasError = Object.keys(errors).length > 0;

  const [isEditing, setIsEditing] = useState(isTextOnly && !contentFieldValue);
  const handleEditClick = () => {
    if (isTextOnly) {
      setIsEditing(state => !state);
    } else {
      onEditClick(itemId);
    }
  };
  const handleDeleteClick = () => onDeleteClick(itemId);

  const classes = classNames('item', className, hasError && 'error');

  return (
    <div className={classes} style={{ minHeight: 85 }}>
      {isTextOnly ? (
        <ItemContentText
          prefix={prefix}
          errors={errors}
          contentFieldValue={contentFieldValue}
          isEditing={isEditing}
        />
      ) : (
        <ItemContentStandard
          contributorData={contributorData}
          prefix={prefix}
          errors={errors}
          idFieldValue={idFieldValue}
          roleFieldValue={roleFieldValue}
        />
      )}

      <div className="d-flex align-items-center">
        <span
          className="btn-delete tooltip tooltip-left mx-1"
          onClick={handleDeleteClick}
          data-tooltip="Remove Credit"
        >
          <Icon name="delete" fill />
        </span>
        <span
          className={classNames('btn-edit tooltip tooltip-left mx-1', isEditing && 'active')}
          onClick={handleEditClick}
          data-tooltip={`Edit ${isTextOnly ? 'Credit' : 'Contributor'}`}
        >
          <Icon name="edit" />
        </span>
        {connectDragSource((
          <span className="btn-move ml-1" title="Drag to sort">
            <Icon name="menu" />
          </span>
        ))}
      </div>
      {!omitFields && (
        <>
          {!!idFieldValue && <input type="hidden" name={`${prefix}-id`} value={idFieldValue} />}
          <input type="hidden" name={`${prefix}-${parentFieldName}`} value={parentId} />
          <input type="hidden" name={`${prefix}-index`} value={counter} />
          <input type="hidden" name={`${prefix}-contributor`} value={contributorFieldValue || ''} />
          {shouldDelete && <input type="hidden" name={`${prefix}-DELETE`} value="1" />}
          {shouldDelete && <DeleteOverlay onUndoClick={handleDeleteClick} />}
        </>
      )}
    </div>
  );
};

Item.propTypes = {
  className: PropTypes.string,
  contributorData: PropTypes.object,
  parentId: PropTypes.string,
  parentFieldName: PropTypes.string,
  counter: PropTypes.number,
  containerEl: PropTypes.instanceOf(Element),
  formComponent: PropTypes.elementType,
  itemId: PropTypes.string,
  prefix: PropTypes.string,
  errors: PropTypes.object,
  id: PropTypes.string,
  role: PropTypes.string,
  contributor: PropTypes.string,
  content: PropTypes.string,
  connectDragSource: PropTypes.func,
  omitFields: PropTypes.bool,
  shouldDelete: PropTypes.bool,
  onEditClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default Item;
