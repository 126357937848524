import React from 'react';
import PropTypes from 'prop-types';
import BreakText from 'components/common/BreakText';
import Icon from 'components/common/Icon';
import { VimeoIcon, YouTubeIcon, SoundCloudIcon } from 'components/common/serviceIcons';
import MediaPlayer from 'components/common/MediaPlayer';
import { VIMEO_URL_PATTERN, YOUTUBE_URL_PATTERN, SOUNDCLOUD_URL_PATTERN } from './';


const ExternalMediaPreview = ({ mediaUrl, onDeleteClick }) => {
  let mediaSource;
  let icon;
  if (VIMEO_URL_PATTERN.test(mediaUrl)) {
    mediaSource = 'Vimeo';
    icon = <VimeoIcon fill="currentColor" />;
  } else if (YOUTUBE_URL_PATTERN.test(mediaUrl)) {
    mediaSource = 'YouTube';
    icon = <YouTubeIcon fill="currentColor" />;
  } else if (SOUNDCLOUD_URL_PATTERN.test(mediaUrl)) {
    mediaSource = 'SoundCloud';
    icon = <SoundCloudIcon fill="currentColor" />;
  }

  return (
    <div className="attached-item-preview">
      <div className="attached-item-preview-icon">
        {icon}
      </div>

      <div className="attached-item-preview-player">
        <MediaPlayer mediaType="video" mediaUrl={mediaUrl} />
      </div>

      <div>
        <h6 className="mb-1">{mediaSource} Media</h6>
        <div className="text-hint lh-1 mb-1">
          <a href={mediaUrl} target="_blank" rel="noopener noreferrer">
            <BreakText text={mediaUrl} />
          </a>
        </div>
      </div>

      <div className="btn-delete tooltip tooltip-left" data-tooltip="Remove Media" onClick={onDeleteClick}>
        <Icon name="delete" fill />
      </div>
    </div>
  );
};

ExternalMediaPreview.propTypes = {
  mediaUrl: PropTypes.string,
  onDeleteClick: PropTypes.func,
};

export default ExternalMediaPreview;
