const itemFormDefaults = {
  year: '',
  month: '',
  day: '',
  time: '',
  dateDisplay: '',
  layout: '',
  headline: '',
  description: '',
  quoteAttribution: '',
  media: '',
  mediaSource: 'url',
  mediaUrl: '',
  mediaDescription: '',
  mediaCredit: '',
  mediaDescriptionInherit: true,
  mediaCreditInherit: true,
};

export default itemFormDefaults;
