import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { urls } from 'app-constants';
import { useCSRF } from 'hooks';
import DrawerModal from 'components/common/DrawerModal';
import ErrorBoundary from 'components/common/ErrorBoundary';
import LoadingOverlay from 'components/common/LoadingOverlay';
import Message from 'components/common/Message';
import Modal from 'components/common/Modal';
import Icon from 'components/common/Icon';

const EMPTY_FIELD_VALS = { analytics_provider: '', analytics_id: '' };
const PROVIDER_GA = 'ga';
const PROVIDER_GTM = 'gtm';

const ReleaseConfigureAnalytics = ({ ids = [] }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const [fieldVals, setFieldVals] = useState(EMPTY_FIELD_VALS);
  const handleFieldChange = evt => setFieldVals(oldState => ({ ...oldState, [evt.target.name]: evt.target.value }));

  const [defaultFieldVals, setDefaultFieldVals] = useState(null);
  useEffect(() => {
    const { analytics_provider, analytics_id } = window.WORKSPACE || {}; // eslint-disable-line
    if (analytics_provider && analytics_id) { // eslint-disable-line
      setDefaultFieldVals({ analytics_provider, analytics_id });
      setFieldVals({ analytics_provider, analytics_id });
    }
  }, []);

  const handleLinkClick = evt => {
    evt.preventDefault();
    setModalVisible(true);
  };

  const handleFormSubmit = evt => {
    evt.preventDefault();
    setConfirmModalVisible(true);
  };
  const handleConfirmSubmit = evt => {
    evt.preventDefault();
    performAction();
    setConfirmModalVisible(false);
  };
  const handleConfirmCancel = () => setConfirmModalVisible(false);

  const handleModalRequestClose = () => setModalVisible(false);
  const handleModalDidClose = () => setFieldVals(defaultFieldVals || EMPTY_FIELD_VALS);

  const csrfToken = useCSRF();
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const performAction = () => {
    setIsFetching(true);
    setErrorMessage(null);

    const body = JSON.stringify({ ids, ...fieldVals });

    fetch(urls.releaseConfigureAnalytics, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (response.status === 400) {
          // Form validation errors
          response.json().then(data => {
            setIsFetching(false);
            const errorString = Object.values(data).reduce((result, errArray) => {
              return result + errArray.join(' ');
            }, '');
            setErrorMessage(errorString || 'Action failed. Please try again.');
          });
        } else if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          response.json().then(data => {
            setIsFetching(false);
            window.location.reload();
          });
        }
        return response;
      })
      .catch(err => {
        setIsFetching(false);
        setErrorMessage('Action failed. Please try again.');
        console.error(err);
      });
  };

  return (
    <ErrorBoundary>
      <a href="#analytics" onClick={handleLinkClick}>
        <Icon name="monitoring" className="mr-2" />
        <span>Configure Analytics</span>
      </a>
      <DrawerModal
        isOpen={modalVisible}
        title="Configure Analytics"
        onRequestClose={handleModalRequestClose}
        onDidClose={handleModalDidClose}
      >
        <div className="limit-600">
          {errorMessage && (
            <div className="mb-3">
              <Message type="error" clearable={false} text={errorMessage} />
            </div>
          )}

          <form onSubmit={handleFormSubmit}>
            <div className="form-group mb-4">
              <h5>Analytics provider</h5>
              <label className="form-radio form-inline">
                <input
                  type="radio"
                  name="analytics_provider"
                  value={PROVIDER_GA}
                  checked={fieldVals.analytics_provider === PROVIDER_GA}
                  onChange={handleFieldChange}
                />
                <i className="form-icon" /> Google Analytics
              </label>
              <label className="form-radio form-inline">
                <input
                  type="radio"
                  name="analytics_provider"
                  value={PROVIDER_GTM}
                  checked={fieldVals.analytics_provider === PROVIDER_GTM}
                  onChange={handleFieldChange}
                />
                <i className="form-icon" /> Google Tag Manager
              </label>
            </div>

            <div className="form-group mb-4">
              <h5>Analytics ID</h5>
              <input
                type="text"
                name="analytics_id"
                maxLength="50"
                className="form-input"
                value={fieldVals.analytics_id}
                onChange={handleFieldChange}
              />
              <p className="form-input-hint">Enter the Tracking ID if using Google Analytics, or Container ID if using Google Tag Manager.</p>
            </div>

            <hr />
            <div>
              <button type="button" className="btn mr-2" onClick={handleModalRequestClose}>Cancel</button>
              <button type="submit" className="btn btn-primary">Submit</button>
            </div>
          </form>
        </div>
        <LoadingOverlay show={isFetching} />
      </DrawerModal>

      <Modal
        title="Are You Sure?"
        style={{ content: { width: 700 } }}
        isOpen={confirmModalVisible}
        onRequestClose={handleConfirmCancel}
      >
        <form onSubmit={handleConfirmSubmit}>
          <p>The selected releases will be updated. There is no undo.</p>
          <div className="modal-action-buttons">
            <button className="btn" onClick={handleConfirmCancel}>Cancel</button>
            <button className="btn btn-primary" type="submit">Confirm</button>
          </div>
        </form>
      </Modal>
    </ErrorBoundary>
  );
};

ReleaseConfigureAnalytics.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ReleaseConfigureAnalytics;
