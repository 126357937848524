import React, { Component } from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import isEqual from 'lodash/isEqual';


class FacebookReleaseMessageField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      message: props.longDescriptionVal,
      messageInherit: props.messageInheritVal,
      sourceObjectType: 'item',
      sourceObjectDescription: '',
    };

    this.handleSourceChange = this.handleSourceChange.bind(this);
    this.handleInheritInputChange = this.handleInheritInputChange.bind(this);
    this.handleMessageInputChange = this.handleMessageInputChange.bind(this);
  }

  componentDidMount () {
    this.handleSourceChange();
  }

  componentDidUpdate (prevProps, prevState) {
    const { sourceObject } = this.props;
    if (!isEqual(sourceObject, prevProps.sourceObject)) {
      this.handleSourceChange();
    }
  }

  handleSourceChange () {
    const { sourceObject } = this.props;
    if (sourceObject) {
      this.setState({
        sourceObjectType: sourceObject.hasOwnProperty('duration') ? 'media' : 'title',
        sourceObjectDescription: striptags(sourceObject.longDescription),
      });
    } else {
      this.setState({
        sourceObjectType: 'item',
        sourceObjectDescription: '',
      });
    }
  }

  handleInheritInputChange (e) {
    this.setState({ messageInherit: e.target.checked });
  }

  handleMessageInputChange (e) {
    this.setState({ message: e.target.value });
  }

  render () {
    const { readOnly } = this.props;
    const { longDescriptionVal, hasError, canInherit } = this.props;
    const { messageInherit, message, sourceObjectDescription, sourceObjectType } = this.state;
    return (
      <div className={`form-group mb-5 ${hasError ? 'has-error' : ''}`}>
        {canInherit ? (
          <div style={{ float: 'right', height: 21, display: 'flex', alignItems: 'center' }}>
            <label className="form-checkbox checkbox-right-sm">
              <input
                type="checkbox"
                name="message_inherit"
                checked={messageInherit}
                onChange={this.handleInheritInputChange}
                disabled={readOnly}
              />
              Inherit from selected {sourceObjectType}<i className="form-icon" />
            </label>
          </div>
        ) : (
          <input type="hidden" name="message_inherit" />
        )}
        <h5>Post body</h5>
        {canInherit && messageInherit ? (
          <>
            <textarea cols="30" rows="5" className="form-input" value={sourceObjectDescription} disabled />
            <input type="hidden" name="long_description" value={longDescriptionVal} />
          </>
        ) : (
          <textarea cols="30" rows="5" className="form-input" name="long_description" value={message} onChange={this.handleMessageInputChange} disabled={readOnly} />
        )}
      </div>
    );
  }
}

FacebookReleaseMessageField.propTypes = {
  longDescriptionVal: PropTypes.string,
  messageInheritVal: PropTypes.bool,
  hasError: PropTypes.bool,
  sourceObject: PropTypes.object,
  canInherit: PropTypes.bool,
  readOnly: PropTypes.bool,
};

export default FacebookReleaseMessageField;

