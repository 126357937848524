import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Checkbox from 'components/common/Checkbox';
import DisplayOptions, { TYPE_PLAYER_RELEASE, TYPE_SITE_RELEASE } from 'components/common/DisplayOptions';


class ReleaseItemSettingsForm extends Component {
  constructor (props) {
    super(props);
    this.state = {
      fields: {},
    };
    this.doOnChange = this.doOnChange.bind(this);
    this.doOnChange = debounce(this.doOnChange, 300);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDisplayOptionsChange = this.handleDisplayOptionsChange.bind(this);
  }

  componentDidMount () {
    this.initFormValues();
  }

  componentDidUpdate (prevProps, prevState) {
    const { initialValues } = this.props;
    const { fields } = this.state;
    if (initialValues !== prevProps.initialValues) {
      this.initFormValues();
    }

    if (JSON.stringify(fields) !== JSON.stringify(prevState.fields)) {
      this.doOnChange(fields);
    }
  }

  doOnChange () {
    const { onChange } = this.props;
    const { fields } = this.state;
    // For bulk operations, only send the fields that were actually modified
    const changedFields = Object.keys(fields).reduce((result, key) => {
      if (fields[key] !== null) {
        result[key] = fields[key];
      }
      return result;
    }, {});
    onChange(changedFields);
  }

  handleInputChange (e) {
    const { fields } = this.state;
    let { name, value, type } = e.target;
    if (type === 'checkbox') {
      value = fields[name] === null ? true : !fields[name];
    }

    this.setState({
      fields: {
        ...fields,
        [name]: value,
      },
    });
  }

  handleDisplayOptionsChange (displayOptions) {
    const { fields } = this.state;

    this.setState({
      fields: {
        ...fields,
        displayOptions,
      },
    });
  }

  initFormValues () {
    const { initialValues } = this.props;
    this.setState({ fields: initialValues });
  }

  render () {
    const { formTitle, itemType, releaseType, disableNavSettings, multiple } = this.props;
    const { fields } = this.state;
    if (!Object.keys(fields).length) {
      return null;
    }
    const { inSectionNav, showSectionNav, showMainNav, linkText, slug, displayOptions } = fields;

    return (
      <div className="title-builder-form-container">
        <header className="title-builder-form-header">
          <div>
            <h5><span>Settings:</span> {formTitle}</h5>
          </div>
        </header>
        <form className="title-builder-form" onSubmit={e => e.preventDefault()}>
          <div
            style={{
              position: 'relative',
              pointerEvents: disableNavSettings ? 'none' : 'auto',
              userSelect: disableNavSettings ? 'none' : 'auto',
            }}
          >
            {releaseType === TYPE_SITE_RELEASE && (
              <>
                <div className="form-group mb-3">
                  <label className="form-checkbox my-0">
                    <Checkbox
                      name="inSectionNav"
                      onChange={this.handleInputChange}
                      checked={inSectionNav}
                      indeterminate={inSectionNav === null}
                    />
                    <i className="form-icon" />
                    Include in section navigation
                  </label>
                  <div className="text-hint">Uncheck to exclude this item from the section navigation menu.</div>
                </div>

                <div className="form-group mb-3">
                  <label className="form-checkbox my-0">
                    <Checkbox
                      name="showSectionNav"
                      onChange={this.handleInputChange}
                      checked={showSectionNav}
                      indeterminate={showSectionNav === null}
                    />
                    <i className="form-icon" />
                    Display section navigation
                  </label>
                  <div className="text-hint">Uncheck to hide the section navigation menu when viewing this item.</div>
                </div>

                <div className="form-group mb-3">
                  <label className="form-checkbox my-0">
                    <Checkbox
                      name="showMainNav"
                      onChange={this.handleInputChange}
                      checked={showMainNav}
                      indeterminate={showMainNav === null}
                    />
                    <i className="form-icon" />
                    Display primary navigation
                  </label>
                  <div className="text-hint">Uncheck to hide the primary navigation menu when viewing this item.</div>
                </div>
              </>
            )}

            <div className="form-group mb-3">
              <label className="block-label">Link text</label>
              {multiple
                ? <input key="placeholder" type="text" className="form-input" value="(Multiple values)" disabled />
                : <input key="linktext" name="linkText" type="text" className="form-input" onChange={this.handleInputChange} value={linkText} />}
              <div className="text-hint mt-1">Enter text for this item’s link in the section navigation menu.</div>
            </div>

            <div className="form-group mb-3">
              <label className="block-label">URL slug</label>
              {multiple
                ? <input key="placeholder" type="text" className="form-input" value="(Multiple values)" disabled />
                : <input key="slug" name="slug" type="text" className="form-input" onChange={this.handleInputChange} value={slug} />}
              <div className="text-hint mt-1">This text will appear in the URL and must contain only lowercase letters, numbers, and hyphens.</div>
            </div>

            {disableNavSettings && (
              <div className="title-builder-disabled-overlay p-4 d-flex align-items-center justify-content-center">
                <div className="info-list">
                  <p>Navigation settings are unavailable when custom navigation is enabled. Visit the Navigation panel to configure.</p>
                </div>
              </div>
            )}
          </div>

          {!multiple && (
            <DisplayOptions
              parentType={releaseType}
              itemType={itemType}
              value={displayOptions}
              onChange={this.handleDisplayOptionsChange}
            />
          )}
        </form>
      </div>
    );
  }
}

ReleaseItemSettingsForm.propTypes = {
  formTitle: PropTypes.string,
  releaseType: PropTypes.oneOf([TYPE_PLAYER_RELEASE, TYPE_SITE_RELEASE]),
  disableNavSettings: PropTypes.bool,
  itemType: PropTypes.string,
  multiple: PropTypes.bool,
  initialValues: PropTypes.object,
  onChange: PropTypes.func,
};

export default ReleaseItemSettingsForm;
