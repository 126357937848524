import formatFileSize from 'pretty-bytes';
import formatDate from 'date-fns/format';
import formatRelativeDate from 'date-fns/formatRelative';
import isValidDate from 'date-fns/isValid';
import urlify from 'urlify';
import { shortDateFormat, shortDateTimeFormat, filterParamDateFormat, flexDateFormats } from 'app-constants';

// Capitalize first character of a string
export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1);

// Truncate a string to the specified number of characters and add ellipsis
export const truncate = (str, numChars) => str.length > numChars ? `${str.substr(0, numChars)} …` : str;

export const pluralize = (countOrArray, labelSingular, labelPlural, hideCount = false) => {
  const count = typeof countOrArray === 'number' ? countOrArray : countOrArray.length;
  labelPlural = labelPlural || labelSingular + 's';
  const label = count === 1 ? labelSingular : labelPlural;
  return hideCount ? label : `${count} ${label}`;
};

export const capFirst = str => str.charAt(0).toUpperCase() + str.slice(1);

// Format a variety of units to human-readable strings
export const formatters = {
  seconds: val => {
    if (isNaN(parseInt(val, 10))) {
      return '';
    }
    const hours = Math.floor(val / 3600);
    const minutes = Math.floor(val / 60) % 60;
    const seconds = Math.floor(val) % 60;
    return `${hours ? hours + ':' : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  },
  pixels: val => !isNaN(parseInt(val, 10)) ? `${val}px` : '',
  bytes: val => {
    const intVal = parseInt(val, 10);
    return !isNaN(intVal) ? formatFileSize(intVal) : '';
  },
  slug: val => urlify(val),
  relativeDate: val => capitalize(formatRelativeDate(val, new Date())),
  shortDate: val => isValidDate(val) ? formatDate(val, shortDateFormat, new Date()) : '',
  shortDateTime: val => isValidDate(val) ? formatDate(val, shortDateTimeFormat, new Date()) : '',
  filterParamDate: val => isValidDate(val) ? formatDate(val, filterParamDateFormat, new Date()) : '',
};

export const combineDateParts = ({ year, month, day, time }) => {
  if (!year) {
    return null;
  }

  const [hour = 0, minute = 0] = time ? time.split(':') : [0, 0];
  return new Date(year, month ? month - 1 : 0, day || 1, hour, minute);
};

export const formatFlexDate = (dateParts, customFormat) => {
  const { year, month, day, time } = dateParts;

  if (!year) {
    return '';
  }

  let formatString;
  const { precisionTime, precisionDay, precisionMonth, precisionYear } = flexDateFormats;

  if (time && day && month && year) {
    formatString = precisionTime; // January 1, 2000, 1:00 PM
  } else if (day && month && year) {
    formatString = precisionDay; // January 1, 2000
  } else if (month && year) {
    formatString = precisionMonth; // January 2000
  } else if (year) {
    formatString = precisionYear; // 2000
  }

  if (customFormat) {
    formatString = customFormat;
  }

  const date = combineDateParts(dateParts);
  return formatDate(date, formatString, new Date());
};

export const fetchWithProgress = (url, opts = {}, onProgress) => (
  new Promise((resolve, reject) => {
    var xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    xhr.withCredentials = true;
    for (const k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }
    xhr.onload = e => resolve(e.target.responseText);
    xhr.onerror = reject;
    if (xhr.upload && onProgress) {
      xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100
    }

    xhr.send(opts.body);
  })
);

/**
 * @param {File} image - Image File Object
 * @param {Object} pixelCrop - pixelCrop Object provided by react-image-crop
 * @param {String} fileName - Name of the returned file in Promise
 */
export const getCroppedImg = (image, pixelCrop, fileName) => {
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height,
  );

  // As Base64 string
  const base64Image = canvas.toDataURL('image/jpeg');
  return base64Image;

  // As a blob
  // return new Promise((resolve, reject) => {
  //   canvas.toBlob(file => {
  //     file.name = fileName;
  //     resolve(file);
  //   }, 'image/jpeg');
  // });
};

export const validateLat = (lat = null) => {
  let value = lat;
  let error = '';

  if (!isNaN(parseFloat(lat))) {
    value = parseFloat(parseFloat(lat).toFixed(5));
    if (value < -90 || value > 90) {
      error = 'Invalid latitude value';
    }
  } else {
    value = null;
  }

  return { value, error };
};

export const validateLng = (lng = null) => {
  let value = lng;
  let error = '';

  if (!isNaN(parseFloat(lng))) {
    value = parseFloat(parseFloat(lng).toFixed(5));
    if (value < -180 || value > 180) {
      error = 'Invalid longitude value';
    }
  } else {
    value = null;
  }

  return { value, error };
};

export const validateMapZoom = (zoom = null, opts = {}) => {
  const defaultOpts = { minZoom: 0, maxZoom: 22 };
  const { minZoom, maxZoom } = { ...defaultOpts, ...opts };
  let zoomVal = zoom;

  if (!isNaN(parseInt(zoom, 10))) {
    zoomVal = parseInt(zoom, 10);
    if (zoomVal > maxZoom) {
      zoomVal = maxZoom;
    }
    if (zoomVal < minZoom) {
      zoomVal = minZoom;
    }
    return zoomVal;
  } else {
    return null;
  }
};

export const simpleMinify = string => string
  .replace(/\n/g, ' ')
  .replace(/\s+/g, ' ')
  .replace(/> </g, '><')
  .trim();

export const pd = callback => (evt, ...args) => {
  evt.preventDefault();
  callback(...args);
};
