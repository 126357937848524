import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { pd } from 'utils';
import Icon from 'components/common/Icon';
import DeletePreset from './DeletePreset';
import DuplicatePreset from './DuplicatePreset';

const PresetsList = ({ items, onItemSelect, onItemDelete, onItemDuplicate, onRequestCreate }) => {
  const [itemToDelete, setItemToDelete] = useState(null);
  const [itemToDuplicate, setItemToDuplicate] = useState(null);
  const handleModalRequestClose = () => {
    setItemToDelete(null);
    setItemToDuplicate(null);
  };
  const handleItemDelete = obj => {
    setItemToDelete(null);
    onItemDelete(itemToDelete);
  };
  const handleItemDuplicate = obj => {
    setItemToDuplicate(null);
    onItemDuplicate(itemToDuplicate);
  };

  const handleCreateClick = e => {
    e.preventDefault();
    onRequestCreate();
  };

  return (
    <div className="template-presets-list">
      <p>
        Select an existing preset to edit, or <a href="#" onClick={handleCreateClick}>create a new one</a>.
      </p>
      {items.map(item => {
        const { id, name, templateName } = item;
        return (
          <div key={id} className="template-presets-list-item">
            <div className="float-right">
              <a href="#duplicate" onClick={pd(() => setItemToDuplicate(item))} className="mr-3" title="Duplicate">
                <Icon name="content_copy" />
              </a>
              <a href="#delete" onClick={pd(() => setItemToDelete(item))} title="Delete">
                <Icon name="delete" fill />
              </a>
            </div>
            <h5 className="m-0">
              <a href={`#${id}`} onClick={pd(() => onItemSelect(id))}>{name}</a>
            </h5>
            <div className="text-meta">Template: {templateName}</div>
          </div>
        );
      })}
      <DeletePreset
        preset={itemToDelete}
        onDelete={handleItemDelete}
        onRequestClose={handleModalRequestClose}
      />
      <DuplicatePreset
        preset={itemToDuplicate}
        onDuplicate={handleItemDuplicate}
        onRequestClose={handleModalRequestClose}
      />
    </div>
  );
};

PresetsList.propTypes = {
  items: PropTypes.array,
  onItemSelect: PropTypes.func,
  onItemDelete: PropTypes.func,
  onItemDuplicate: PropTypes.func,
  onRequestCreate: PropTypes.func,
};

export default PresetsList;
