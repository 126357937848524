import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';

const VisibilityKey = ({ itemTypeLabel = 'menu item' }) => (
  <div className="text-hint mb-2 d-flex align-items-center">
    <span className="mr-4 d-flex align-items-center"><Icon name="visibility" fill size={20} className="mr-1" /> Show {itemTypeLabel}</span>
    <span className="d-flex align-items-center"><Icon name="visibility_off" fill size={20} className="mr-1" /> Hide {itemTypeLabel}</span>
  </div>
);

VisibilityKey.propTypes = {
  itemTypeLabel: PropTypes.string,
};

export default VisibilityKey;
