import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';
import { VimeoIcon, YouTubeIcon } from 'components/common/serviceIcons';


const SourcePicker = ({ onSelect }) => (
  <div className="media-import-source-picker">
    <h6 className="identifier">Import From</h6>
    <div className="icon-option-list">
      <a href="#upload" onClick={e => { e.preventDefault(); onSelect('upload'); }}>
        <div className="option-icon">
          <Icon name="cloud_upload" fill size={60} />
        </div>
        <div className="option-label">File Upload</div>
      </a>
      <a href="#url" onClick={e => { e.preventDefault(); onSelect('url'); }}>
        <div className="option-icon">
          <Icon name="link" size={60} />
        </div>
        <div className="option-label">Web URL</div>
      </a>
      <a href="#vimeo" onClick={e => { e.preventDefault(); onSelect('vimeo'); }}>
        <div className="option-icon">
          <VimeoIcon size={48} fill="currentColor" />
        </div>
        <div className="option-label">Vimeo</div>
      </a>
      <a href="#youtube" onClick={e => { e.preventDefault(); onSelect('youtube'); }}>
        <div className="option-icon">
          <YouTubeIcon size={54} fill="currentColor" />
        </div>
        <div className="option-label">YouTube</div>
      </a>
      <a href="#podcast" onClick={e => { e.preventDefault(); onSelect('podcast'); }}>
        <div className="option-icon">
          <Icon name="rss_feed" size={54} />
        </div>
        <div className="option-label">Podcast</div>
      </a>
    </div>

  </div>
);

SourcePicker.propTypes = {
  onSelect: PropTypes.func,
};

export default SourcePicker;
