import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import { formatters } from 'utils';
import { ErrorList } from 'components/common/inlines';
import QuillEditor from 'components/common/QuillEditor';
import MediaRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/MediaRelationField';
import Checkbox from 'components/common/Checkbox';
import Select from 'components/common/Select';
import LocationWidget from 'components/common/LocationWidget';
import CountrySelect from 'components/common/CountrySelect';
import MarkerSelect from './MarkerSelect';
import itemFormDefaults from './itemFormDefaults';


class Form extends Component {
  constructor (props) {
    super(props);
    this.defaultState = {
      itemId: null,
      fields: { ...itemFormDefaults },
    };
    this.state = {
      ...this.defaultState,
    };

    this.fieldPrefix = 'mapBuilderForm-';
    this.numericFields = ['latitude', 'longitude'];
    this.requiredFields = ['name', 'slug'];

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleMediaChange = this.handleMediaChange.bind(this);
    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.initFormValues = this.initFormValues.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormChange = debounce(this.handleFormChange, 500);
    this.getItemObject = this.getItemObject.bind(this);
    this.errorClass = this.errorClass.bind(this);
  }

  componentDidMount () {
    this.initFormValues();
  }

  componentDidUpdate (prevProps, prevState) {
    const { item } = this.props;
    const prevItem = prevProps.item;

    if (!isEqual(item, prevProps.item)) {
      this.initFormValues();
    }

    if (
      (item && !prevItem) || (!item && prevItem) ||
      (item && prevItem && item.itemId !== prevItem.itemId)
    ) {
      // Reset form scroll position when a different item is selected
      this.containerRef.scrollTop = 0;
    }
  }

  componentWillUnmount () {
    this.handleFormChange.flush();
  }

  handleInputChange (e) {
    const { fields } = this.state;
    let { name, value, type, checked } = e.target;
    name = name.replace(this.fieldPrefix, '');
    if (value && this.numericFields.includes(name)) {
      value = parseFloat(value);
    } else if (type === 'checkbox') {
      value = checked;
    } else if (name === 'slug') {
      value = formatters.slug(value);
    }
    this.setState({
      fields: {
        ...fields,
        [name]: value,
      },
    }, this.handleFormChange);
  }

  handleSelectChange (field, value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        [field]: value,
      },
    }, this.handleFormChange);
  }

  handleDescriptionChange (value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        description: value,
      },
    }, this.handleFormChange);
  }

  handleMediaChange (value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        media: value,
      },
    }, this.handleFormChange);
  }

  handleLocationChange (lat, lng) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        latitude: lat,
        longitude: lng,
      },
    }, this.handleFormChange);
  }

  handleFormChange () {
    const { onChange, item } = this.props;
    const itemObj = this.getItemObject();

    if (item) {
      // Only call this if we are editing an existing item, not a blank one.
      onChange({ ...itemObj });
    }
  }

  getItemObject () {
    // Convert empty strings to null
    return Object.keys(this.state.fields).reduce((result, key) => {
      result[key] = this.state.fields[key] === '' ? null : this.state.fields[key];
      return result;
    }, {});
  }

  initFormValues () {
    const { item } = this.props;

    if (item) {
      const newFields = Object.keys(item).reduce((result, key) => {
        if (this.defaultState.fields.hasOwnProperty(key)) {
          // Keep explicit false for checkbox fields, but convert undefined or null to empty strings
          result[key] = (item[key] || item[key] === false) ? item[key] : '';
        }
        return result;
      }, {});
      this.setState({ fields: newFields, itemId: item.itemId });
    } else {
      this.setState({ fields: this.defaultState.fields, itemId: null });
    }
  }

  errorClass (fieldName) {
    const { item } = this.props;
    return item && item.errors[fieldName] ? 'has-error' : '';
  }

  render () {
    const { item, categoryOptions, markerTypes } = this.props;
    const {
      name,
      slug,
      description,
      locationType,
      defaultItem,
      countries,
      categories,
      marker,
      media,
      latitude,
      longitude,
    } = this.state.fields;
    const hasErrors = item && Object.keys(item.errors).length > 0;
    const headerText = item ? (item.name || '[No Name]') : '[New Item]';

    return (
      <div className="title-builder-form-container" ref={node => this.containerRef = node}>
        <header className="title-builder-form-header">
          <div>
            <h5><span>Editing:</span> {headerText}</h5>
          </div>
        </header>

        <div className="title-builder-form">
          {hasErrors ? <ErrorList className="mb-5" errors={item.errors} /> : null}

          <div className={`form-group mb-5 ${this.errorClass('name')}`}>
            <label className="block-label">Name</label>
            <input name={`${this.fieldPrefix}name`} type="text" className="form-input" value={name} onChange={this.handleInputChange} />
          </div>

          <div className={`form-group mb-5 ${this.errorClass('slug')}`}>
            <label className="block-label">URL Slug</label>
            <input name={`${this.fieldPrefix}slug`} type="text" className="form-input" value={slug} onChange={this.handleInputChange} />
            <p className="form-input-hint mb-0">Enter a URL-friendly version of the item name containing only lowercase letters, numbers, and hyphens.</p>
          </div>

          <div className={`form-group mb-5 ${this.errorClass('description')}`}>
            <label className="block-label">Description</label>
            <QuillEditor inputName={`${this.fieldPrefix}description`} value={description} onChange={this.handleDescriptionChange} />
          </div>

          <div className={`form-group mb-5 ${this.errorClass('categories')}`}>
            <label className="block-label">Categories</label>
            <Select
              value={categoryOptions.filter(c => categories.includes(c.value))}
              options={categoryOptions}
              onChange={val => this.handleSelectChange('categories', val)}
              isMulti
            />
            <p className="form-input-hint mb-0">Items are organized by category in the published map’s navigation menu. Edit categories in the “Categories” tab.</p>
          </div>

          <div className={`form-group mb-5 ${this.errorClass('defaultItem')}`}>
            <label className="form-checkbox my-0">
              <Checkbox
                name={`${this.fieldPrefix}defaultItem`}
                onChange={this.handleInputChange}
                checked={defaultItem}
              />
              <i className="form-icon" />
              Default Item
            </label>
            <p className="form-input-hint m-0">Display this item by default when the map is loaded.</p>
          </div>

          <hr className="mt-0 mb-3" />

          <div className={`form-group mb-5 ${this.errorClass('locationType')}`}>
            <label className="block-label">Location Type</label>
            <label className="form-radio m-0">
              <input type="radio" name={`${this.fieldPrefix}locationType`} value="point" checked={locationType === 'point'} onChange={this.handleInputChange} />
              <i className="form-icon" /> Point
            </label>
            <label className="form-radio m-0">
              <input type="radio" name={`${this.fieldPrefix}locationType`} value="countries" checked={locationType === 'countries'} onChange={this.handleInputChange} />
              <i className="form-icon" /> Countries
            </label>
          </div>

          {locationType === 'point' ? (
            <>
              <div className="form-group mb-5">
                <label className="block-label">Location</label>
                <p className="form-input-hint mt-0">Click a point on the map below to set a location, or enter coordinates directly.</p>
                <LocationWidget latitude={latitude} longitude={longitude} onChange={this.handleLocationChange} />
              </div>

              <div className={`form-group mb-5 ${this.errorClass('marker')}`}>
                <label className="block-label">Marker</label>
                <MarkerSelect
                  value={marker}
                  markerTypes={markerTypes}
                  onChange={val => this.handleSelectChange('marker', val)}
                />
                <p className="form-input-hint mb-0">Select a marker to represent this item. Edit marker types in the “Categories” tab.</p>
              </div>
            </>
          ) : null}

          {locationType === 'countries' ? (
            <div className={`form-group mb-5 ${this.errorClass('countries')}`}>
              <label className="block-label">Countries</label>
              <CountrySelect
                value={countries}
                onChange={val => this.handleSelectChange('countries', val)}
                isMulti
              />
            </div>
          ) : null}

          <div className={`form-group mb-5 ${this.errorClass('media')}`}>
            <label className="block-label">Media</label>
            <p className="form-input-hint m-0">Use the form below to attach media to this map item.</p>
            <MediaRelationField allowMultiple value={media} onChange={this.handleMediaChange} />
          </div>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  item: PropTypes.object,
  categoryOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })),
  markerTypes: PropTypes.arrayOf(PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
  })),
  onChange: PropTypes.func,
};

export default Form;
