// HOC that provides functionality common to all PlaylistItem components.
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DeleteOverlay } from 'components/common/inlines';
import Icon from 'components/common/Icon';

const playlistItemWrapper = WrappedComponent => {
  class WrappedPlaylist extends Component {
    constructor (props) {
      super(props);
      this.state = {
        shouldDelete: false,
        nameText: props.fields.name || '',
      };
      this.handleNameInputChange = this.handleNameInputChange.bind(this);
      this.handleDeleteClick = this.handleDeleteClick.bind(this);
      this.renderNameInput = this.renderNameInput.bind(this);
      this.renderHiddenFields = this.renderHiddenFields.bind(this);
      this.renderDeleteOverlay = this.renderDeleteOverlay.bind(this);
      this.renderControlButtons = this.renderControlButtons.bind(this);
    }

    handleNameInputChange (e) {
      this.setState({ nameText: e.target.value });
    }

    handleDeleteClick () {
      const { shouldDelete } = this.state;
      this.setState({ shouldDelete: !shouldDelete });
    }

    renderNameInput (labelText) {
      const { prefix, errors, editMode } = this.props;
      const { nameText } = this.state;
      const nameFieldErrors = errors && errors.name ? errors.name.map(({ message }) => message) : [];

      return editMode === 'insert' ? (
        <div className={`form-group m-0 ${nameFieldErrors.length ? 'has-error' : ''}`} style={{ width: '50%', minWidth: 300 }}>
          <label htmlFor={`${prefix}-name`}>{labelText}</label>
          <input type="text" className="form-input" name={`${prefix}-name`} value={nameText} onChange={this.handleNameInputChange} />
          {nameFieldErrors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
        </div>
      ) : (
        <div>
          <h4 className="m-0">{nameText}</h4>
          <input type="hidden" name={`${prefix}-name`} value={nameText} />
        </div>
      );
    }

    renderHiddenFields () {
      const { fields, prefix, itemIndex } = this.props;
      const { shouldDelete } = this.state;

      return (
        <>
          {!!fields.id && <input type="hidden" name={`${prefix}-id`} value={fields.id} />}
          {shouldDelete && <input type="hidden" name={`${prefix}-DELETE`} value="1" />}
          {!!fields.media && <input type="hidden" name={`${prefix}-media`} value={fields.media} />}
          <input type="hidden" name={`${prefix}-parent`} value={fields.parent} />
          <input type="hidden" name={`${prefix}-type`} value={fields.type} />
          <input type="hidden" name={`${prefix}-index`} value={itemIndex} />
          <input type="hidden" name={`${prefix}-group_uid`} value={fields.groupUid || ''} />
          <input type="hidden" name={`${prefix}-uuid`} value={fields.uuid} />
          <input type="hidden" name={`${prefix}-slug`} value={fields.slug} />
          <input type="hidden" name={`${prefix}-poster_duration`} value={fields.posterDuration || 0} />
          {!!fields.excludeFromMenu && <input type="hidden" name={`${prefix}-exclude_from_menu`} value="1" />}
          {!!fields.countdownOverlay && <input type="hidden" name={`${prefix}-countdown_overlay`} value="1" />}
          <input type="hidden" name={`${prefix}-countdown_label`} value={fields.countdownLabel} />
          {!!fields.skipOverlay && <input type="hidden" name={`${prefix}-skip_overlay`} value="1" />}
          <input type="hidden" name={`${prefix}-custom_overlay_text`} value={fields.customOverlayText} />
        </>
      );
    }

    renderDeleteOverlay () {
      const { shouldDelete } = this.state;
      return shouldDelete && <DeleteOverlay onUndoClick={this.handleDeleteClick} />;
    }

    renderControlButtons () {
      const { editMode, fields } = this.props;
      const { shouldDelete } = this.state;

      if (editMode === 'insert') {
        return (
          <span
            className="btn-delete tooltip"
            key={shouldDelete}
            onClick={this.handleDeleteClick}
            data-tooltip={shouldDelete ? 'Undo Remove' : 'Remove Item'}
          >
            {shouldDelete ? <Icon name="undo" /> : <Icon name="delete" fill />}
          </span>
        );
      } else if (editMode === 'sort') {
        return (
          <>
            <span className="btn-move tooltip" data-tooltip="Drag to Sort">
              <Icon name="menu" />
            </span>
            {!!fields.excludeFromMenu && (
              <span className="btn-icon tooltip" data-tooltip="Hidden Item" style={{ top: 'unset', bottom: 15 }}>
                <Icon name="visibility_off" />
              </span>
            )}
          </>
        );
      }
      return null;
    }

    render () {
      const { nameText } = this.state;

      return (
        <WrappedComponent
          nameText={nameText}
          renderNameInput={this.renderNameInput}
          renderHiddenFields={this.renderHiddenFields}
          renderDeleteOverlay={this.renderDeleteOverlay}
          renderControlButtons={this.renderControlButtons}
          {...this.props}
        />
      );
    }
  }

  WrappedPlaylist.displayName = `playlistItemWrapper(${WrappedComponent.displayName || WrappedComponent.name}`;

  WrappedPlaylist.propTypes = {
    editMode: PropTypes.oneOf(['sort', 'insert', null]),
    prefix: PropTypes.string,
    itemIndex: PropTypes.number,
    fields: PropTypes.object,
    errors: PropTypes.object,
    parentId: PropTypes.string,
  };

  return WrappedPlaylist;
};

export default playlistItemWrapper;
