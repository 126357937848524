import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import isEqual from 'lodash/isEqual';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { v4 as uuid4 } from 'uuid';
import withCSRF from 'components/common/withCSRF';
import Icon from 'components/common/Icon';
import Modal from 'components/common/Modal';
import LoadingOverlay from 'components/common/LoadingOverlay';
import DjangoFormField from 'components/common/DjangoFormField';
import Select from 'components/common/DjangoFormField/Select';
import TemplatePicker from './TemplatePicker';


class ReleaseTemplateSelect extends Component {
  constructor (props) {
    super(props);
    this.defaultState = {
      modalVisible: false,
      templateUid: props.templateFieldInitialValue,
      templateFieldValue: props.templateFieldInitialValue,
      presetUid: props.presetFieldInitialValue,
      presetFieldValue: props.presetFieldInitialValue,
      templateChoices: [],
      templateOptionsFormData: {},
      templateOptionsFieldValues: {},
      error: null,
      choicesFetching: false,
      optionsFetching: false,
    };
    this.state = { ...this.defaultState };

    this.handleChooseTemplateClick = this.handleChooseTemplateClick.bind(this);
    this.handleModalRequestClose = this.handleModalRequestClose.bind(this);
    this.handleTemplateSelect = this.handleTemplateSelect.bind(this);
    this.handlePresetChange = this.handlePresetChange.bind(this);
    this.handleApplyPreset = this.handleApplyPreset.bind(this);
    this.handleOptionFieldChange = this.handleOptionFieldChange.bind(this);
    this.handleOptionFieldClear = this.handleOptionFieldClear.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleTemplateOptionsSave = this.handleTemplateOptionsSave.bind(this);
    this.handleTemplateOptionsError = this.handleTemplateOptionsError.bind(this);
  }

  componentDidMount () {
    this.fetchTemplateChoices();
  }

  componentDidUpdate (prevProps, prevState) {
    const { modalVisible, templateFieldValue, templateOptionsFormData, choicesFetching, optionsFetching } = this.state;
    if (modalVisible && !prevState.modalVisible) {
      this.fetchTemplateChoices();
    }

    if (templateFieldValue !== prevState.templateFieldValue || (!choicesFetching && prevState.choicesFetching)) {
      this.fetchTemplateOptions();
    }

    if (!isEqual(templateOptionsFormData, prevState.templateOptionsFormData) || (!optionsFetching && prevState.optionsFetching)) {
      this.initTemplateOptionsValues();
    }
  }

  handleChooseTemplateClick (e) {
    e.preventDefault();
    this.setState({ modalVisible: true });
  }

  handleModalRequestClose () {
    const { templateUid, presetUid } = this.state;
    this.setState({
      modalVisible: false,
      templateFieldValue: templateUid,
      presetFieldValue: presetUid,
      error: null,
      choicesFetching: false,
      templateOptionsFormData: {},
      templateOptionsFieldValues: {},
    });
  }

  handleTemplateSelect (id) {
    this.setState({ templateFieldValue: id }, () => {
      const { presets } = this.getSelectedTemplate();
      const defaultPreset = presets.find(p => p.default);
      this.setState({ presetFieldValue: defaultPreset ? defaultPreset.uuid : '' });
    });
  }

  handlePresetChange (e) {
    this.setState({ presetFieldValue: e.target.value });
  }

  handleApplyPreset (e) {
    const presetId = e.target.value;
    this.fetchTemplateOptions(presetId);
  }

  handleOptionFieldChange (e) {
    const { templateOptionsFieldValues } = this.state;
    const { type, name, value, files, checked } = e.target;
    let val = value;
    if (type === 'file') {
      val = files[0];
    } else if (type === 'checkbox') {
      val = checked;
    }

    this.setState({
      templateOptionsFieldValues: {
        ...templateOptionsFieldValues,
        [name]: val,
      },
    });
  }

  handleOptionFieldClear (fieldName) {
    const { templateOptionsFieldValues } = this.state;
    this.setState({
      templateOptionsFieldValues: {
        ...templateOptionsFieldValues,
        [fieldName]: null,
      },
    });
  }

  handleFormSubmit (e) {
    const { templateFieldValue, presetFieldValue } = this.state;
    e.preventDefault();
    const cb = () => {
      if (presetFieldValue) {
        this.setState({
          modalVisible: false,
          templateOptionsFormData: {},
          templateOptionsFieldValues: {},
        });
      } else {
        this.submitTemplateOptions();
      }
    };
    this.setState({ templateUid: templateFieldValue, presetUid: presetFieldValue }, cb);
  }

  handleCancelClick (e) {
    const { templateUid, presetUid } = this.state;
    e.preventDefault();
    this.setState({
      modalVisible: false,
      templateFieldValue: templateUid,
      presetFieldValue: presetUid,
      error: null,
      choicesFetching: false,
      templateOptionsFormData: {},
      templateOptionsFieldValues: {},
    });
  }

  handleTemplateOptionsSave (e) {
    const { status, statusText, response } = e.target;
    const selectedTemplateUid = this.getSelectedTemplate().uid;
    const state = {
      optionsFetching: false,
      templateOptionsFormData: { ...this.state.templateOptionsFormData },
    };
    if (status === 204) {
      // Normal response, no body
      state.modalVisible = false;
      state.templateOptionsFormData = {};
      state.templateOptionsFieldValues = {};
    } else if (status === 200) {
      // Redisplaying form with errors
      state.templateOptionsFormData[selectedTemplateUid] = camelize(JSON.parse(response));
    } else {
      state.error = statusText;
    }
    this.setState(state);
  }

  handleTemplateOptionsError (e) {
    this.setState({
      optionsFetching: false,
      error: 'Network error',
    });
  }

  fetchTemplateChoices () {
    const { releaseId } = this.props;
    const url = urlJoin(urls.releaseTemplateChoicesBase, releaseId, '/');
    this.setState({ choicesFetching: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText || 'Bad Request' });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({ templateChoices: data, choicesFetching: false });
      })
      .catch(err => {
        this.setState({ choicesFetching: false });
        console.error(err);
      });
  }

  fetchTemplateOptions (presetId = null) {
    const { releaseId } = this.props;
    const selectedTemplate = this.getSelectedTemplate();
    if (!selectedTemplate) {
      return null;
    }
    const { id: templateId, uid: templateUid } = selectedTemplate;
    let url = urlJoin(urls.releaseTemplateOptionsBase, releaseId, templateId, '/');
    if (presetId) url += `?preset_id=${presetId}`;
    this.setState({ optionsFetching: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText || 'Bad Request' });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({
          optionsFetching: false,
          templateOptionsFormData: {
            ...this.state.templateOptionsFormData,
            [templateUid]: data,
          },
        });
      })
      .catch(err => {
        this.setState({ optionsFetching: false });
        console.error(err);
      });
  }

  initTemplateOptionsValues () {
    const { templateOptionsFormData } = this.state;

    const selectedTemplate = this.getSelectedTemplate();
    if (!selectedTemplate) {
      return null;
    }
    const form = templateOptionsFormData[selectedTemplate.uid] || {};
    if (!form.fields) {
      return null;
    }
    const vals = {};
    Object.values(form.fields).forEach(field => {
      if (typeof field.value !== 'object') {
        vals[field.name] = field.value;
      }
    });

    const loadFileObjects = () => {
      if (form.fromPreset) {
        Object.values(form.fields).filter(f => f.fileUrl).forEach(field => {
          let filename = field.fileUrl.split('/').slice(-1)[0];
          filename = filename.split('?')[0];
          fetch(field.fileUrl)
            .then(res => {
              const mimeType = res.headers.get('Content-Type');
              res.arrayBuffer().then(buf => {
                const file = new File([buf], filename, { type: mimeType });
                this.setState({
                  templateOptionsFieldValues: {
                    ...this.state.templateOptionsFieldValues,
                    [field.name]: file,
                  },
                });
              });
            });
        });
      }
    };

    this.setState({ templateOptionsFieldValues: vals, formKey: uuid4() }, loadFileObjects);
  }

  submitTemplateOptions () {
    const { releaseId, csrfToken } = this.props;
    const { templateOptionsFieldValues } = this.state;
    const templateId = this.getSelectedTemplate().id;
    const url = urlJoin(urls.releaseTemplateOptionsBase, releaseId, templateId, '/');
    const formData = new FormData();
    Object.entries(templateOptionsFieldValues).forEach(([name, value]) => {
      formData.append(name, value);
    });

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.withCredentials = true;
    xhr.setRequestHeader('X-CSRFToken', csrfToken);
    xhr.onload = this.handleTemplateOptionsSave;
    xhr.onerror = this.handleTemplateOptionsError;
    this.setState({ optionsFetching: true }, () => xhr.send(formData));
  }

  getSelectedTemplate () {
    const { templateFieldValue, templateChoices } = this.state;
    return templateChoices.find(({ uid }) => uid === templateFieldValue);
  }

  renderTemplateOptionsForm () {
    const { choicesFetching, optionsFetching, templateOptionsFormData, templateOptionsFieldValues: vals, formKey } = this.state;
    const template = this.getSelectedTemplate();
    if (!template || Object.keys(templateOptionsFormData) === 0) {
      return null;
    }
    const form = templateOptionsFormData[this.getSelectedTemplate().uid] || {};
    let fields = [];
    if (Object.keys(form).length && Object.keys(vals).length) {
      fields = Object.keys(form.fields).map(key => form.fields[key]);
    }
    const noOptions = !optionsFetching && !choicesFetching && fields.length === 0;

    const presetChoices = template.presets.length > 0 && [
      ['', '-- Select --'],
      ...template.presets.map(p => [p.id, p.name]),
    ];

    return (
      <div className="release-template-options-form">
        {noOptions ? <div className="text-center p-5"><em>No configurable options available for this template.</em></div> : null}
        {presetChoices && (
          <Select
            label="Load Preset"
            helpText="Populate the configuration options based on an existing preset."
            choices={presetChoices}
            value=""
            className="mb-4"
            inputStyle={{ display: 'block', maxWidth: 200 }}
            onChange={this.handleApplyPreset}
          />
        )}
        <div key={formKey}>
          {fields.map(field => (
            <DjangoFormField
              key={field.name}
              className="mb-4"
              {...field}
              value={vals[field.name]}
              allowFormatting={field.widgetType === 'Textarea'}
              onChange={this.handleOptionFieldChange}
              onClear={this.handleOptionFieldClear}
            />
          ))}
        </div>
        <LoadingOverlay show={optionsFetching} />
      </div>
    );
  }

  renderPresetsForm () {
    const { workspaceAdmin } = this.props;
    const { presetFieldValue } = this.state;
    const { presets } = this.getSelectedTemplate() || {};

    if (!presets) return null;

    const helpText = workspaceAdmin ? (
      <span>Manage template presets in your organization’s <a href={urls.templatePresetSettings}>settings</a>.</span>
    ) : (
      <span>To configure template presets, contact your organization’s administrator.</span>
    );

    if (presets.length === 0) {
      return (
        <>
          <div className="mb-1">
            <em>No presets available for this template.</em>
          </div>
          <div className="text-hint">{helpText}</div>
          <hr />
        </>
      );
    }

    return (
      <div className="form-group">
        {presets.map(({ uuid, name }) => (
          <div key={uuid}>
            <label className="form-radio m-0">
              <input
                type="radio"
                value={uuid}
                checked={presetFieldValue === uuid}
                onChange={this.handlePresetChange}
              />
              <i className="form-icon" /> Apply preset: {name}
            </label>
          </div>
        ))}
        <div>
          <label className="form-radio m-0">
            <input
              type="radio"
              value=""
              checked={!presetFieldValue}
              onChange={this.handlePresetChange}
            />
            <i className="form-icon" /> Custom options configuration
          </label>
        </div>
        <div className="text-hint mt-1">{helpText}</div>
        <hr />
      </div>
    );
  }

  render () {
    const { templateFieldName, presetFieldName } = this.props;
    const {
      templateUid,
      presetUid,
      templateFieldValue,
      presetFieldValue,
      templateChoices,
      modalVisible,
      error,
      choicesFetching,
      optionsFetching,
    } = this.state;
    const currentTemplate = templateChoices.find(({ uid }) => uid === templateUid) || {};
    const { thumbnail, name, description } = currentTemplate;
    const isLoading = Object.keys(currentTemplate).length === 0 && choicesFetching;

    const activePresetName = !!presetFieldValue && currentTemplate.presets && (currentTemplate.presets.find(p => p.uuid === presetFieldValue) || {}).name;

    return (
      <div className="release-template-select">
        {templateUid ? (
          <div className="release-template-preview card-item">
            <header>
              <div className="release-template-thumbnail mr-3">
                <img src={thumbnail} alt="" />
              </div>
              <div>
                <h6 className="m-0">{name}</h6>
                {!!description && <div className="text-meta" style={{ fontStyle: 'italic' }} dangerouslySetInnerHTML={{ __html: description }} />}
                {activePresetName && <div className="text-meta">Preset: {activePresetName}</div>}
              </div>
              {!isLoading ? (
                <button className="btn btn-primary action-edit" onClick={this.handleChooseTemplateClick}>
                  <Icon name="edit" />
                </button>
              ) : null}
            </header>
            <LoadingOverlay show={isLoading} />
          </div>
        ) : (
          <button className="btn btn-primary" onClick={this.handleChooseTemplateClick}>Choose Template</button>
        )}
        <input type="hidden" name={templateFieldName} value={templateUid} />
        <input type="hidden" name={presetFieldName} value={presetUid} />

        <Modal
          title="Template"
          style={{ content: { width: 900 } }}
          isOpen={modalVisible}
          onRequestClose={this.handleModalRequestClose}
        >
          {error ? (
            <div className="toast toast-error">Error: {error}</div>
          ) : (
            <div>
              <form onSubmit={this.handleFormSubmit}>
                {templateChoices.length ? (
                  <TemplatePicker
                    templateItems={templateChoices}
                    selectedTemplateId={templateFieldValue}
                    onSelect={this.handleTemplateSelect}
                  />
                ) : null}

                <h5 className="mt-5 mb-1">Configure Template Options</h5>
                <hr className="mt-0" />

                {this.renderPresetsForm()}
                {!presetFieldValue && this.renderTemplateOptionsForm()}

                <div className="modal-action-buttons mt-4">
                  <a className="btn" onClick={this.handleCancelClick}>Cancel</a>
                  <button className="btn btn-primary" type="submit" disabled={choicesFetching || optionsFetching}>Accept</button>
                </div>
              </form>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}

ReleaseTemplateSelect.propTypes = {
  csrfToken: PropTypes.string,
  releaseId: PropTypes.string,
  templateFieldName: PropTypes.string,
  templateFieldInitialValue: PropTypes.string,
  presetFieldName: PropTypes.string,
  presetFieldInitialValue: PropTypes.string,
  hasError: PropTypes.bool,
  workspaceAdmin: PropTypes.bool,
};

export default withCSRF(ReleaseTemplateSelect);
