import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';


const Radio = ({ name, label, labelClass, helpText, disabled, inline, value, choices, errors, className, style, onChange }) => (
  <div className={`form-group ${errors.length ? 'has-error' : ''} ${className || ''}`} style={style}>
    {label && <label htmlFor={name} className={labelClass}>{label}</label>}
    {choices.map(([choiceValue, choiceLabel]) => (
      <label key={choiceValue} className={classNames('form-radio m-0', inline && 'form-inline')}>
        <input
          type="radio"
          name={name}
          disabled={disabled}
          value={choiceValue}
          checked={value === choiceValue}
          onChange={onChange}
        />
        <i className="form-icon" /> <span dangerouslySetInnerHTML={{ __html: choiceLabel }} />
      </label>
    ))}
    {helpText && !errors.length ? <p className="form-input-hint mb-0">{helpText}</p> : null}
    {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
  </div>
);

Radio.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  value: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.array),
  errors: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  errors: [],
  onChange: () => null,
};

export default Radio;
