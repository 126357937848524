import React from 'react';
import PropTypes from 'prop-types';


const Select = ({ name, label, labelClass, helpText, value, choices, errors, className, style, inputStyle, onChange }) => (
  <div className={`form-group ${errors.length ? 'has-error' : ''} ${className || ''}`} style={style}>
    {label && <label htmlFor={name} className={labelClass}>{label}</label>}
    <select name={name} className="form-select" style={inputStyle} value={value || ''} onChange={onChange}>
      {choices.map(([choiceValue, choiceLabel]) => (
        <option key={choiceValue} value={choiceValue}>{choiceLabel}</option>
      ))}
    </select>
    {helpText && !errors.length ? <p className="form-input-hint mb-0">{helpText}</p> : null}
    {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
  </div>
);

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.array),
  errors: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  onChange: PropTypes.func,
};

Select.defaultProps = {
  errors: [],
  onChange: () => null,
};

export default Select;
