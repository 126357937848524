const itemFormDefaults = {
  name: '',
  slug: '',
  description: '',
  media: [],
  categories: [],
  marker: '',
  locationType: 'point',
  defaultItem: false,
  countries: [],
  latitude: null,
  longitude: null,
};

export default itemFormDefaults;
