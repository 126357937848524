import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { routedComponent } from 'routing';
import { objectListConfig, getValidFilterParams } from './config';
import ObjectList from './ObjectList';
import Filters from './Filters/Filters';


class ObjectListModal extends Component {
  constructor (props) {
    super(props);
    this.state = {
      selectedItems: [],
      dataKey: uuid4(),
      selectionKey: null,
    };

    this.el = document.createElement('div');

    this.handleSelectionChange = this.handleSelectionChange.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleAcceptClick = this.handleAcceptClick.bind(this);
  }

  componentDidMount () {
    document.body.appendChild(this.el);
  }

  componentDidUpdate (prevProps, prevState) {
    const { isOpen, resetFiltersOnClose } = this.props;
    if (isOpen && !prevProps.isOpen) {
      clearTimeout(this.resetTimeout);
      this.setState({ dataKey: uuid4() });
    }

    if (!isOpen && prevProps.isOpen && resetFiltersOnClose) {
      this.resetTimeout = setTimeout(() => this.resetParams(), 400);
    }
  }

  componentWillUnmount () {
    document.body.removeChild(this.el);
    clearTimeout(this.resetTimeout);
  }

  handleSelectionChange (selectedItems) {
    this.setState({ selectedItems });
  }

  handleCancelClick (e) {
    const { onRequestClose } = this.props;
    e.preventDefault();
    onRequestClose();
    this.setState({ selectionKey: uuid4() });
  }

  handleAcceptClick (e) {
    const { onAccept, allowMultipleSelection } = this.props;
    const { selectedItems } = this.state;
    const selection = allowMultipleSelection ? selectedItems : selectedItems.slice(-1)[0];
    e.preventDefault();
    onAccept(selection);
    this.setState({ selectionKey: uuid4() });
  }

  resetParams () {
    const { location, history, model } = this.props;
    const currentParams = queryString.parse(location.search);
    const toRemove = [...getValidFilterParams(model), 'page'];
    const newParams = Object.entries(currentParams)
      .filter(([key]) => !toRemove.includes(key))
      .reduce((result, [key, val]) => {
        result[key] = val;
        return result;
      }, {});
    const qs = queryString.stringify(newParams);
    history.push(`${location.pathname}?${qs}`);
  }

  render () {
    const { model, preFilter, disabledFilters, isOpen, allowMultipleSelection } = this.props;
    const { selectedItems, selectionKey, dataKey } = this.state;
    const selectedMessage = `${selectedItems.length} item${selectedItems.length !== 1 ? 's' : ''} selected`;

    return createPortal(
      <>
        <div className={`object-list-modal-mask ${isOpen ? 'open' : ''}`} />
        <div className={`object-list-modal ${isOpen ? 'open' : ''}`}>
          <header className="object-list-modal-header flex-spread">
            <h3 className="m-0">Select {model} to insert</h3>
            <div>{selectedMessage}</div>
          </header>

          <ObjectList
            model={model}
            preFilter={preFilter}
            allowMultipleSelection={allowMultipleSelection}
            onSelectionChange={this.handleSelectionChange}
            dataKey={dataKey}
            selectionKey={selectionKey}
            isModal
            detailTargetBlank
            imageClickToggleSelection
          />

          <div className="action-button-group">
            <div>
              <a className="btn btn-primary" onClick={this.handleCancelClick}>Cancel</a>
            </div>
            <div>
              <a className="btn btn-primary" onClick={this.handleAcceptClick} disabled={selectedItems.length === 0}>Accept</a>
            </div>
          </div>

          <div className="object-list-filters">
            <Filters model={model} disabled={disabledFilters} />
          </div>
        </div>
      </>,
      this.el,
    );
  }
}

ObjectListModal.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  isOpen: PropTypes.bool,
  allowMultipleSelection: PropTypes.bool,
  model: PropTypes.oneOf(Object.keys(objectListConfig)),
  preFilter: PropTypes.object,
  disabledFilters: PropTypes.arrayOf(PropTypes.string),
  resetFiltersOnClose: PropTypes.bool,
  onAccept: PropTypes.func,
  onRequestClose: PropTypes.func,
};

ObjectListModal.defaultProps = {
  allowMultipleSelection: true,
  preFilter: {},
  disabledFilters: [],
  resetFiltersOnClose: true,
  onAccept: () => null,
};

export default routedComponent(withRouter(ObjectListModal));
