import React from 'react';
import PropTypes from 'prop-types';


const MapboxStaticMap = ({
  width = 200,
  height = 200,
  lat,
  lon,
  zoom,
  user,
  styleId,
  accessToken,
  hiDPI = true,
  style = {},
}) => {
  const url = `https://api.mapbox.com/styles/v1/${user}/${styleId}/static/${lon},${lat},${zoom}/${width}x${height}${hiDPI ? '@2x' : ''}?access_token=${accessToken}`;
  return <img src={url} width={width} height={height} style={{ display: 'block', ...style }} />;
};

MapboxStaticMap.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  lat: PropTypes.number,
  lon: PropTypes.number,
  zoom: PropTypes.number,
  user: PropTypes.string,
  styleId: PropTypes.string,
  accessToken: PropTypes.string,
  hiDPI: PropTypes.bool,
  style: PropTypes.object,
};

export default MapboxStaticMap;
