import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import Message from 'components/common/Message';

export default function useNotification () {
  const [messageProps, setMessageProps] = useState(null);
  const messageTimeout = useRef();

  useEffect(() => {
    clearTimeout(messageTimeout.current);
    if (messageProps && messageProps.timeout) {
      messageTimeout.current = setTimeout(() => setMessageProps(null), (messageProps.timeout + 1) * 1000);
    }
    return () => clearTimeout(messageTimeout.current);
  }, [messageProps]);

  const messageContent = messageProps && createPortal(<Message {...messageProps} />, document.querySelector('.messages-container'));

  return {
    notification: messageContent,
    createNotification: setMessageProps,
  };
}
