import React from 'react';
import PropTypes from 'prop-types';


const File = ({
  name,
  label,
  helpText,
  initial,
  fileName,
  fileUrl,
  errors,
  clearable,
  className,
  style,
  onChange,
}) => {
  const initialStyles = {
    backgroundColor: '#f6f6f6',
    margin: 0,
    padding: '3px 10px',
    border: '1px solid #a6a6a6',
    borderBottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  };
  return (
    <div className={`form-group ${errors.length ? 'has-error' : ''} ${className || ''}`} style={style}>
      {label && <label htmlFor={name}>{label}</label>}
      {fileName ? (
        <div className="text-hint" style={initialStyles}>
          <div>
            Currently: {fileUrl ? <a href={fileUrl} target="_blank" rel="noopener noreferrer">{fileName}</a> : fileName}
          </div>
          <label style={{ fontWeight: 'normal', cursor: 'pointer', userSelect: 'none' }}>clear <input type="checkbox" name={`${name}-clear`} onChange={onChange} /></label>
        </div>
      ) : null}
      <input type="file" className="form-input" name={name} onChange={onChange} />
      {helpText && !errors.length ? <p className="form-input-hint mb-0">{helpText}</p> : null}
      {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
    </div>
  );
};

File.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string,
  initial: PropTypes.object,
  fileName: PropTypes.string,
  fileUrl: PropTypes.string,
  errors: PropTypes.array,
  clearable: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};

File.defaultProps = {
  errors: [],
  onChange: () => null,
};

export default File;
