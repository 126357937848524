import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { useCSRF } from 'hooks';
import Icon from 'components/common/Icon';
import LoadingOverlay from 'components/common/LoadingOverlay';

const MediaTextTracks = ({
  textTracks: textTracksInitial = [],
  defaultLang: defaultLangInitial,
  vimeoId,
  mediaId,
}) => {
  const [textTracks, setTextTracks] = useState(textTracksInitial);
  const [defaultLang, setDefaultLang] = useState(defaultLangInitial);

  useEffect(() => {
    setTextTracks(textTracksInitial || []);
  }, [textTracksInitial]);

  useEffect(() => {
    setDefaultLang(defaultLangInitial);
  }, [defaultLangInitial]);

  const [isFetching, setIsFetching] = useState(false);
  const csrfToken = useCSRF();

  const updateTracks = () => {
    const url = urlJoin(urls.vimeoUpdateTextTracksBase, mediaId, '/');

    setIsFetching(true);
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setTextTracks(data);
        setIsFetching(false);
      })
      .catch(err => {
        setIsFetching(false);
        console.error(err);
      });
  };

  const handleRefreshClick = evt => {
    evt.preventDefault();
    updateTracks();
  };

  return (
    <div>
      <div className="flex-spread align-items-center">
        <h5 className="m-0">Captions and subtitles</h5>
        <a
          href="#Refresh"
          className="tooltip tooltip-left d-flex align-items-center"
          data-tooltip="Refresh track list"
          onClick={handleRefreshClick}
        >
          <Icon name="sync" />
        </a>
      </div>
      <hr className="mb-4" />
      <div style={{ position: 'relative' }}>
        {textTracks.length > 0 && (
          <>
            <div className="flex-spread text-meta">
              <div>Language</div>
              <div>Make default</div>
            </div>
            <hr />
            {textTracks.map(({ code, label }) => {
              const handleChange = evt => setDefaultLang(evt.target.checked ? code : null);
              return (
                <Fragment key={code}>
                  <div className="flex-spread">
                    <div>{label}</div>
                    <label className="form-checkbox checkbox-right-sm">
                      <input type="checkbox" checked={defaultLang === code} onChange={handleChange} />
                      <i className="form-icon" />
                    </label>
                  </div>
                  <hr />
                </Fragment>
              );
            })}
            <input type="hidden" name="default_text_track" value={defaultLang || ''} />
          </>
        )}
        <div className="text-hint">
          {textTracks.length > 0 ? 'Select a track’s checkbox above to enable it by default. ' : 'No tracks found. '}
          Upload or manage captions and subtitles in this video’s <a href={`https://vimeo.com/manage/${vimeoId}/distribution#subtitles`} target="_blank" rel="noopener noreferrer">Vimeo settings</a>.
        </div>
        <LoadingOverlay show={isFetching} style={{ backgroundColor: 'rgba(247, 247, 247, .7)' }} />
      </div>
    </div>
  );
};

MediaTextTracks.propTypes = {
  textTracks: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.oneOf(['captions', 'subtitles']),
  })),
  defaultLang: PropTypes.string,
  vimeoId: PropTypes.string,
  mediaId: PropTypes.string,
};

export default MediaTextTracks;
