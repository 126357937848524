import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MapViewportWidget from 'components/common/MapViewportWidget';


class MapZoomField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      zoom: parseInt(props.field.value, 10),
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange ({ zoom }) {
    this.setState({ zoom });
  }

  render () {
    const { field } = this.props;
    const { zoom } = this.state;

    return (
      <>
        <MapViewportWidget
          zoom={zoom}
          enableCenter={false}
          onChange={this.handleChange}
        />
        <input type="hidden" name={field.name} value={zoom} />
      </>
    );
  }
}

MapZoomField.propTypes = {
  field: PropTypes.object,
};

export default MapZoomField;
