import { useState, useEffect, useLayoutEffect } from 'react';

export default function useIsVisible (refOrSelector) {
  const [isIntersecting, setIntersecting] = useState(null);
  // useEffect here triggers an additional render to ensure the ref is available.
  useEffect(() => {
    setIntersecting(false);
  }, []);

  const el = typeof refOrSelector === 'string' ? document.querySelector(refOrSelector) : refOrSelector.current;

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting));
    if (el) observer.observe(el);
    return () => observer.disconnect();
  }, [el]);

  return isIntersecting;
}
