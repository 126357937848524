import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import { usePortal } from 'hooks';
import CreditsWidget, { ReadOnlyCreditsWidget } from 'components/common/CreditsWidget';
import DjangoFormField from 'components/common/DjangoFormField';
import FlexDateField from 'components/common/FlexDateField';
import RelatedLinksFormset from 'components/common/RelatedLinksFormset';
import SEOFields from 'components/common/SEOFields';
import { FieldHeader, PublishDates, Tags } from './fields';
import useMetadataPreset from './useMetadataPreset';

const MediaMetadataForm = ({
  form,
  creditsFormset,
  contributorChoices,
  relatedLinksFormset,
  objectUid,
  objectType,
}) => {
  // console.log(JSON.stringify(creditsFormset, null, 2));
  const sidebarPortal = usePortal(document.querySelector('.general-sidebar-portal'));

  const { fields: origFields } = camelize(form);
  const {
    presetId,
    fields,
    formsets: presetFormsets,
    transformFormsetData,
    metadataPresetField,
    renderOverrideToggle,
    isOverrideActive,
    isFieldDisabled,
    processing,
  } = useMetadataPreset(origFields);

  const [creditTypeValue, setCreditTypeValue] = useState(fields.creditType.value);
  useEffect(() => {
    setCreditTypeValue(fields.creditType.value);
  }, [fields.creditType.value, isOverrideActive(fields.creditType.name)]);

  const showLocalAdvCredits = creditTypeValue === 'advanced' && (!presetId || isOverrideActive('credits'));
  const showPresetAdvCredits = creditTypeValue === 'advanced' && presetId && !isOverrideActive('credits');

  const showPresetRelatedLinks = !!presetId && !isOverrideActive('related_links');
  const presetRelatedLinksFormset = transformFormsetData(relatedLinksFormset, presetFormsets.relatedLinks);

  const captureDateFields = [fields.year, fields.month, fields.day, fields.time];
  const sidebarContent = (
    <>
      <div className="form-group">
        <div className="flex-spread mb-1">
          <h5 className="m-0">Capture date</h5>
          {renderOverrideToggle(captureDateFields.map(f => f.name))}
        </div>
        <FlexDateField
          yearField={fields.year}
          monthField={fields.month}
          dayField={fields.day}
          timeField={fields.time}
          disabled={captureDateFields.some(f => isFieldDisabled(f))}
        />
      </div>

      <PublishDates
        fields={fields}
        renderOverrideToggle={renderOverrideToggle}
        isFieldDisabled={isFieldDisabled}
      />

      <hr className="mb-6" />
      <div className="form-group mb-4">
        <FieldHeader field={fields.notes} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.notes}
          label=""
          helpText="Internal/administrative notes"
          disabled={isFieldDisabled(fields.notes)}
        />
      </div>
      <hr className="mb-6" />
      <Tags
        field={fields.tags}
        className="mb-6"
        renderOverrideToggle={renderOverrideToggle}
        isFieldDisabled={isFieldDisabled}
      />
    </>
  );

  return (
    <>
      {metadataPresetField}
      <hr className="mb-4" />

      <div className="form-group mb-6">
        <FieldHeader field={fields.shortDescription} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.shortDescription}
          allowFormatting
          label=""
          disabled={isFieldDisabled(fields.shortDescription)}
        />
      </div>

      <div className="form-group mb-6">
        <FieldHeader field={fields.longDescription} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.longDescription}
          allowFormatting
          label=""
          editorSize="large"
          disabled={isFieldDisabled(fields.longDescription)}
        />
      </div>

      <SEOFields
        titleField={fields.seoTitle}
        descriptionField={fields.seoDescription}
        renderOverrideToggle={renderOverrideToggle}
        isFieldDisabled={isFieldDisabled}
      />

      <div className="form-group mb-4">
        <div className="flex-spread mb-1">
          <h5 className="m-0">{fields.creditType.label}</h5>
          {renderOverrideToggle([fields.creditType.name, fields.credit.name, 'credits'])}
        </div>
        <DjangoFormField
          {...fields.creditType}
          label=""
          disabled={isFieldDisabled(fields.creditType)}
          value={creditTypeValue}
          onChange={evt => setCreditTypeValue(evt.target.value)}
        />
      </div>

      <div className={classNames({ 'd-none': creditTypeValue !== 'simple' })}>
        <div className="form-group mb-6">
          <DjangoFormField
            {...fields.credit}
            labelClass="h5"
            allowFormatting
            disabled={isFieldDisabled(fields.credit)}
            editorSize="small"
          />
        </div>
      </div>

      {!showPresetAdvCredits && (
        <div className={classNames({ 'd-none': !showLocalAdvCredits })}>
          <div className="form-group mb-6">
            <h5>Credits</h5>
            <CreditsWidget
              parentId={objectUid}
              parentFieldName="media"
              formsetData={creditsFormset}
              contributorChoices={contributorChoices}
            />
          </div>
        </div>
      )}

      {showPresetAdvCredits && (
        <div className="form-group mb-6">
          <h5>Credits</h5>
          <ReadOnlyCreditsWidget items={presetFormsets.credits || []} disabled />
        </div>
      )}

      {objectType === 'image' && (
        <div className="form-group mb-6">
          <FieldHeader field={fields.altText} renderOverrideToggle={renderOverrideToggle} />
          <DjangoFormField
            {...fields.altText}
            label=""
            disabled={isFieldDisabled(fields.altText)}
          />
        </div>
      )}

      <div className="form-group mb-4">
        <FieldHeader field={fields.licenseUrl} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.licenseUrl}
          label=""
          disabled={isFieldDisabled(fields.licenseUrl)}
        />
      </div>

      {objectType === 'image' && (
        <div className="form-group mb-4">
          <FieldHeader field={fields.printsUrl} renderOverrideToggle={renderOverrideToggle} />
          <DjangoFormField
            {...fields.printsUrl}
            label=""
            disabled={isFieldDisabled(fields.printsUrl)}
          />
        </div>
      )}

      <div className="form-group mb-4">
        <FieldHeader field={fields.nftUrl} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.nftUrl}
          label=""
          disabled={isFieldDisabled(fields.nftUrl)}
        />
      </div>


      <div className="form-group mb-6">
        <div className="flex-spread mb-2">
          <h5 className="m-0">Related Links</h5>
          {renderOverrideToggle('related_links')}
        </div>
        <div className={classNames({ 'd-none': showPresetRelatedLinks })}>
          <RelatedLinksFormset key="local" parentId={objectUid} formsetData={relatedLinksFormset} />
        </div>
        {showPresetRelatedLinks && (
          <RelatedLinksFormset key={`preset-${processing}`} parentId={objectUid} disabled formsetData={presetRelatedLinksFormset} />
        )}
      </div>

      {sidebarPortal(sidebarContent)}
    </>
  );
};

MediaMetadataForm.propTypes = {
  form: PropTypes.object,
  creditsFormset: PropTypes.object,
  contributorChoices: PropTypes.arrayOf(PropTypes.object),
  relatedLinksFormset: PropTypes.object,
  objectUid: PropTypes.string,
  objectType: PropTypes.string,
};

export default MediaMetadataForm;
