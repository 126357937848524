import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IntegrationItem from 'components/views/Integrations/IntegrationItem';


class PublishEndpointField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      value: props.field.value,
      uids: props.field.choices.reduce((result, [uid, label]) => ([...result, ...(uid ? [uid] : [])]), []),
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange (value) {
    this.setState({ value });
  }

  render () {
    const { field } = this.props;
    const { value, uids } = this.state;

    return (
      <div className="publish-endpoint-container">
        {uids.map(uid => (
          <div key={uid} className="publish-endpoint-item">
            <label className="form-radio p-0 m-0" style={{ flex: '0 0 24px' }}>
              <input
                type="radio"
                checked={value === uid}
                onChange={() => this.handleChange(uid)}
              />
              <i className="form-icon" />
            </label>
            <IntegrationItem id={uid} editable={false} />
            <div className="overlay" onClick={() => this.handleChange(uid)} />
          </div>
        ))}
        <input type="hidden" name={field.name} value={value || ''} />
      </div>
    );
  }
}

PublishEndpointField.propTypes = {
  field: PropTypes.object,
};

export default PublishEndpointField;
