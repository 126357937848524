import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import Select from 'components/common/Select';
import QuillEditor from 'components/common/QuillEditor';

const ImageGridItemForm = ({ item, mediaData, errors = {}, onChange }) => {
  const { uid, label, labelSource, description, descriptionSource, linkUrl } = item || {};

  const labelInputRef = useRef();

  useEffect(() => {
    if (uid && labelInputRef.current) {
      labelInputRef.current.focus();
    }
  }, [uid]);

  const handleInputChange = e => {
    if (!item) return null;
    const { name, value } = e.target;
    onChange({ [name]: value });
  };

  const handleFieldChange = (name, val) => onChange({ [name]: val });

  const labelSourceOptions = [
    { value: 'media_name', label: 'Use media name' },
    { value: 'custom', label: 'Use custom label' },
    { value: 'none', label: 'None' },
  ];
  const labelSourceValue = labelSourceOptions.find(opt => opt.value === labelSource);
  let derivedLabelValue = '';
  if (labelSource === 'media_name') {
    derivedLabelValue = mediaData ? mediaData.name : '';
  }
  const labelInput = labelSource === 'custom' ? (
    <input
      ref={labelInputRef}
      type="text"
      name="label"
      className="form-input"
      onChange={handleInputChange}
      value={label || ''}
    />
  ) : (
    <input
      type="text"
      className="form-input"
      value={derivedLabelValue}
      disabled
    />
  );

  const descriptionSourceOptions = [
    { value: 'media_long_desc', label: 'Use media long description' },
    { value: 'media_short_desc', label: 'Use media short description' },
    { value: 'custom', label: 'Use custom description' },
    { value: 'none', label: 'None' },
  ];
  const descriptionSourceValue = descriptionSourceOptions.find(opt => opt.value === descriptionSource);
  const formatDesc = val => striptags(val.replace(/<\/p>\s*<p>/gi, '</p>\n\n<p>'));
  let derivedDescriptionValue = '';
  if (descriptionSource === 'media_long_desc') {
    derivedDescriptionValue = mediaData ? formatDesc(mediaData.longDescription) : '';
  }
  if (descriptionSource === 'media_short_desc') {
    derivedDescriptionValue = mediaData ? formatDesc(mediaData.shortDescription) : '';
  }
  const descriptionInput = descriptionSource === 'custom' ? (
    <QuillEditor
      value={description}
      onChange={val => handleFieldChange('description', val)}
      size="small"
    />
  ) : (
    <textarea
      key={`${uid}-description-${descriptionSource}`}
      cols="30"
      rows="3"
      className="form-input"
      value={derivedDescriptionValue}
      disabled
    />
  );

  return (
    <div className="content">
      <h6 className="mb-4">Image Settings</h6>
      <div className="mb-3">
        <div>Label:</div>
        <div className="mb-1">
          <Select
            value={labelSourceValue}
            options={labelSourceOptions}
            onChange={val => handleFieldChange('labelSource', val)}
          />
          {errors.labelSource}
        </div>
        <div>
          {labelInput}
          {errors.label}
        </div>
      </div>

      <div className="mb-3">
        <div>Description:</div>
        <div className="mb-2">
          <Select
            value={descriptionSourceValue}
            options={descriptionSourceOptions}
            onChange={val => handleFieldChange('descriptionSource', val)}
          />
          {errors.descriptionSource}
        </div>
        <div>
          {descriptionInput}
          {errors.description}
        </div>
      </div>

      <div className="mb-3">
        <div>Link URL:</div>
        <div className="mb-1">
          <input
            type="text"
            name="linkUrl"
            className="form-input"
            onChange={handleInputChange}
            value={linkUrl || ''}
          />
          {errors.linkUrl}
        </div>
      </div>

      {!item && <div className="form-overlay" />}
    </div>
  );
};

ImageGridItemForm.propTypes = {
  item: PropTypes.shape({
    uid: PropTypes.string,
    label: PropTypes.string,
    labelSource: PropTypes.oneOf(['media_name', 'custom', 'none']),
    description: PropTypes.string,
    descriptionSource: PropTypes.oneOf(['media_long_desc', 'media_short_desc', 'custom', 'none']),
    linkUrl: PropTypes.string,
  }),
  mediaData: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default ImageGridItemForm;
