import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import striptags from 'striptags';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import Icon from 'components/common/Icon';
import TruncateText from 'components/common/TruncateText';
import { DeleteOverlay } from 'components/common/inlines';


class Item extends Component {
  constructor (props) {
    super(props);
    this.state = {
      attachedMedia: null,
      error: false,
      processing: false,
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount () {
    const { media } = this.props;
    this.fetchData(media);
  }

  componentDidUpdate (prevProps, prevState) {
    const { media } = this.props;
    if (media !== prevProps.media) {
      this.fetchData(media);
    }
  }

  handleDeleteClick (e) {
    const { onDeleteClick } = this.props;
    e.stopPropagation();
    onDeleteClick();
  }

  fetchData (mediaId) {
    if (!mediaId) {
      return null;
    }
    const url = urlJoin(urls.mediaDataBase, mediaId, '?format=json&include_images=true&image_size=400');
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({
          attachedMedia: data,
          processing: false,
        });
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  getNameText () {
    const { name, nameSource } = this.props;
    const { attachedMedia } = this.state;

    switch (nameSource) {
      case 'none':
        return '';
      case 'media_name':
        return attachedMedia ? attachedMedia.name : '';
      default:
        return name;
    }
  }

  getDescriptionText () {
    const { description, descriptionSource } = this.props;
    const { attachedMedia } = this.state;

    switch (descriptionSource) {
      case 'none':
        return '';
      case 'media_long_desc':
        return attachedMedia ? attachedMedia.longDescription : '';
      case 'media_short_desc':
        return attachedMedia ? attachedMedia.shortDescription : '';
      default:
        return description;
    }
  }

  renderThumbnail () {
    const { media, viewMode } = this.props;
    const { attachedMedia } = this.state;

    if (media && media.length) {
      return attachedMedia ? (
        <div className="title-builder-item-thumbnail mr-4">
          {viewMode === 'grid'
            ? <img src={attachedMedia.images.original || attachedMedia.thumbnail} alt="thumbnail" />
            : <img src={attachedMedia.thumbnail} alt="thumbnail" width="100" height="60" />}
        </div>
      ) : (
        <div className="title-builder-item-thumbnail mr-4">
          <Icon name="steppers" />
        </div>
      );
    } else {
      return (
        <div className="title-builder-item-thumbnail mr-4">
          <Icon name="content_copy" />
        </div>
      );
    }
  }

  renderFormFields () {
    const {
      prefix, counter, id, itemId, shouldDelete, parent, layout, slug, name, nameSource, description, descriptionSource, credit,
      creditSource, media, text, overlayColor, textColor, overlayOpacity,
    } = this.props;

    return (
      <>
        {id ? <input type="hidden" name={`${prefix}-id`} value={id} /> : null}
        <input type="hidden" name={`${prefix}-uuid`} value={itemId} />
        {shouldDelete ? <input type="hidden" name={`${prefix}-DELETE`} value="1" /> : null}
        <input type="hidden" name={`${prefix}-parent`} value={parent} />
        <input type="hidden" name={`${prefix}-index`} value={counter} />
        <input type="hidden" name={`${prefix}-layout`} value={layout || ''} />
        <input type="hidden" name={`${prefix}-slug`} value={slug || ''} />
        <input type="hidden" name={`${prefix}-name`} value={name || ''} />
        <input type="hidden" name={`${prefix}-name_source`} value={nameSource || ''} />
        <input type="hidden" name={`${prefix}-description`} value={description || ''} />
        <input type="hidden" name={`${prefix}-description_source`} value={descriptionSource || ''} />
        <input type="hidden" name={`${prefix}-credit`} value={credit || ''} />
        <input type="hidden" name={`${prefix}-credit_source`} value={creditSource || ''} />
        <input type="hidden" name={`${prefix}-text`} value={text || ''} />
        <input type="hidden" name={`${prefix}-overlay_color`} value={overlayColor || ''} />
        <input type="hidden" name={`${prefix}-text_color`} value={textColor || ''} />
        <input type="hidden" name={`${prefix}-overlay_opacity`} value={overlayOpacity || ''} />
        {media ? <input type="hidden" name={`${prefix}-media`} value={media} /> : null}
      </>
    );
  }

  render () {
    const { counter, active, selectable, selected, error, onClick, shouldDelete } = this.props;
    const { attachedMedia } = this.state;

    const containerClasses = classNames({
      active,
      selectable,
      selected,
      error,
      'title-builder-item': true,
      'to-delete': shouldDelete,
    });

    return (
      <div className={containerClasses} onClick={onClick}>
        <div className="index-counter">{counter}</div>

        {selectable && (
          <label className="form-checkbox" style={{ pointerEvents: 'none', paddingRight: 0 }}>
            <input type="checkbox" checked={selected} readOnly />
            <i className="form-icon" />
          </label>
        )}

        {this.renderThumbnail()}

        <div className="title-builder-item-details">
          <h6 className="mb-0"><TruncateText text={this.getNameText()} numChars={45} forceBreak /></h6>
          <div className="slideshow-builder-caption">{striptags(this.getDescriptionText())}</div>

          {attachedMedia && (
            <div className="meta my-1" style={{ lineHeight: 1.25 }}>
              <div>Media:&nbsp;<a href={`${urls.mediaBase}${attachedMedia.id}/`} target="_blank" rel="noopener noreferrer">{attachedMedia.id}</a></div>
              <div style={{ whiteSpace: 'nowrap' }} title={attachedMedia.masterFilename}>
                <TruncateText text={attachedMedia.masterFilename} numChars={25} truncateStart />
              </div>
            </div>

          )}
        </div>

        {this.renderFormFields()}

        {shouldDelete ? <DeleteOverlay onUndoClick={this.handleDeleteClick} /> : null}

        <span
          className="btn-delete tooltip tooltip-left"
          key={shouldDelete}
          onClick={this.handleDeleteClick}
          data-tooltip={shouldDelete ? 'Undo Remove' : 'Remove Item'}
        >
          {shouldDelete ? <Icon name="undo" /> : <Icon name="delete" fill />}
        </span>

        <span className="btn-move" title="Drag to sort">
          <Icon name="menu" />
        </span>
      </div>
    );
  }
}

Item.propTypes = {
  viewMode: PropTypes.oneOf(['list', 'grid']),
  counter: PropTypes.number,
  active: PropTypes.bool,
  selectable: PropTypes.bool,
  selected: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  prefix: PropTypes.string,
  id: PropTypes.string,
  itemId: PropTypes.string,
  parent: PropTypes.string,
  shouldDelete: PropTypes.bool,
  layout: PropTypes.string,
  slug: PropTypes.string,
  name: PropTypes.string,
  nameSource: PropTypes.string,
  description: PropTypes.string,
  descriptionSource: PropTypes.string,
  credit: PropTypes.string,
  creditSource: PropTypes.string,
  media: PropTypes.string,
  text: PropTypes.string,
  overlayColor: PropTypes.string,
  textColor: PropTypes.string,
  overlayOpacity: PropTypes.number,
};

export default Item;
