import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import QuillEditor from 'components/common/QuillEditor';


const RichText = ({ name, label, labelClass, helpText, disabled, value, errors, className, style, editorSize, onChange }) => {
  const handleChange = val => {
    const fakeEvt = {
      target: {
        name,
        type: 'textarea',
        value: val,
      },
    };
    onChange(fakeEvt);
  };

  return (
    <div className={classNames('form-group', className, errors.length > 0 && 'has-error')} style={style}>
      {label && <label htmlFor={name} className={labelClass}>{label}</label>}
      <QuillEditor
        inputName={name}
        size={editorSize}
        readOnly={disabled}
        value={value}
        onChange={handleChange}
      />
      {helpText && !errors.length && <p className="form-input-hint mb-0">{helpText}</p>}
      {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
    </div>
  );
};

RichText.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  editorSize: PropTypes.oneOf(['small', 'large']),
  onChange: PropTypes.func,
};

RichText.defaultProps = {
  errors: [],
  onChange: () => null,
};

export default RichText;
