import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import Select from 'components/common/Select';


const { Option } = components;

const TextStyleOption = props => {
  const { label, value, helpText } = props.data;
  const styleMap = {
    display_heading: { fontSize: '42px', fontWeight: 600 },
    h1: { fontSize: '30px', fontWeight: 600 },
    h2: { fontSize: '24px', fontWeight: 600 },
    h3: { fontSize: '20px', fontWeight: 600 },
    h4: { fontSize: '18px', fontWeight: 600 },
  };
  const style = styleMap[value];
  if (helpText) {
    return (
      <Option {...props}>
        <div style={{ display: 'flex', alignItems: 'baseline' }}>
          <div style={style} className="mr-2">{label}</div>
          <div className="text-hint">{helpText}</div>
        </div>
      </Option>
    );
  } else {
    return <Option {...props}><div style={style}>{label}</div></Option>;
  }
};

TextStyleOption.propTypes = {
  data: PropTypes.object,
};

const TextStyleSelect = props => <Select components={{ Option: TextStyleOption }} {...props} />;

export default TextStyleSelect;
