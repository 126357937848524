import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';


class ErrorBoundary extends Component {
  constructor (props) {
    super(props);
    this.state = { hasError: false };
  }

  // static getDerivedStateFromError () {
  //   return { hasError: true };
  // }

  componentDidCatch (error, info) {
    const { onError } = this.props;
    this.setState({ hasError: true });

    Sentry.withScope(scope => {
      Object.keys(info).forEach(key => {
        scope.setExtra(key, info[key]);
      });
      Sentry.captureException(error);
    });

    onError && onError(error, info);
  }

  render () {
    const { suppressMessage, children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return suppressMessage ? null : <div className="error-list">Oops, something went wrong. Our team has been notified and will investigate the error. In the meantime, refreshing the page may resolve the issue.</div>;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  onError: PropTypes.func,
  children: PropTypes.node,
  suppressMessage: PropTypes.bool,
};

export default ErrorBoundary;
