import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TagField from 'components/common/TagField';
import FieldHeader from './FieldHeader';

const Tags = ({
  field,
  className,
  renderOverrideToggle = () => {},
  isFieldDisabled = () => false,
}) => {
  let { helpText, errors } = field;
  helpText = helpText || 'Tags for internal/administrative use';

  return (
    <div className={classNames('form-group', className, errors.length > 0 && 'has-error')}>
      <FieldHeader field={field} renderOverrideToggle={renderOverrideToggle} />
      <TagField field={field} disabled={isFieldDisabled(field)} />
      {helpText && !errors.length && <p className="form-input-hint mb-0">{helpText}</p>}
      {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
    </div>
  );
};

Tags.propTypes = {
  field: PropTypes.object,
  className: PropTypes.string,
  renderOverrideToggle: PropTypes.func,
  isFieldDisabled: PropTypes.func,
};

export default Tags;
