import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';


const StyledScrollbar = forwardRef(({ children }, ref) => {
  return (
    <SimpleBar
      style={{ height: '100%', paddingRight: 11 }}
      scrollableNodeProps={{ ref }}
      data-simplebar-auto-hide="false"
      data-simplebar-force-visible="y"
    >
      {children}
    </SimpleBar>
  );
});

StyledScrollbar.displayName = 'StyledScrollbar';

StyledScrollbar.propTypes = {
  children: PropTypes.any,
};

export default StyledScrollbar;
