import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import isEqual from 'lodash/isEqual';
import { DragSource, DropTarget } from 'react-dnd';


const getType = props => props.type || 'SortableItem';

const itemSource = {
  beginDrag (props, monitor, component) {
    return {
      startPos: monitor.getClientOffset(),
      itemId: props.itemId,
    };
  },

  canDrag (props, monitor) {
    return props.itemId.indexOf('placeholder') === -1;
  },

  isDragging (props, monitor) {
    return props.itemId === monitor.getItem().itemId;
  },
};

const itemTarget = {
  hover: throttle((props, monitor, component) => {
    if (!component) {
      return null;
    }
    const dragId = (monitor.getItem() || {}).itemId;
    const hoverId = props.itemId;
    if (dragId === hoverId) {
      return null;
    }

    // Ignore the parent list if the target is within a nested list
    if (monitor.isOver({ shallow: true })) {
      // Example: https://react-dnd.github.io/react-dnd/examples-sortable-simple.html
      const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();  // eslint-disable-line
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      const insertPos = hoverClientY < hoverMiddleY ? 'before' : 'after';
      component.moveItem(dragId, hoverId, insertPos);
    }
  }, 50),
};

class SortableItem extends Component {
  constructor (props) {
    super(props);
    this.defaultState = {
      dragId: null,
      hoverId: null,
      insertPos: null,
    };
    this.state = {
      ...this.defaultState,
    };

    this.moveItem = this.moveItem.bind(this);
  }

  componentDidUpdate (prevProps, prevState) {
    const { isOver, onItemMove } = this.props;
    if (!isEqual(this.state, prevState)) {
      const { dragId, hoverId, insertPos } = this.state;
      if (dragId && hoverId && insertPos) {
        onItemMove(dragId, hoverId, insertPos);
      }
    }

    if (!isOver && prevProps.isOver) {
      this.setState({ ...this.defaultState });
    }
  }

  moveItem (dragId, hoverId, insertPos) {
    this.setState({ dragId, hoverId, insertPos });
  }

  render () {
    const { isDragging, connectDragPreview, connectDragSource, connectDropTarget, canSort, children, className, style } = this.props;

    const classes = classNames(className, {
      'sortable-item': true,
      draggable: canSort,
      dragging: isDragging,
    });

    let renderedChildren = children;
    if (typeof children === 'function') {
      // the child function pattern can be used to limit the drag handle to a specific
      // element within the child, as opposed to making the entire child component draggable.
      renderedChildren = canSort ? children(connectDragSource) : children();
    }

    const component = (
      <div className={classes} style={style}>
        <div className="sortable-item-contents">
          {renderedChildren}
        </div>
      </div>
    );

    if (canSort && typeof children === 'function') {
      // Sorting is enabled and `children` is a function: `connectDragSource` should be called
      // somewhere in a descendant component, with the drag handle element as its argument.
      return connectDragPreview(connectDropTarget(component));
    } else if (canSort) {
      return connectDragSource(connectDropTarget(component));
    } else {
      return component;
    }
  }
}

SortableItem.propTypes = {
  itemId: PropTypes.any,
  index: PropTypes.number,
  canSort: PropTypes.bool,
  onItemMove: PropTypes.func,
  isDragging: PropTypes.bool,
  isOver: PropTypes.bool,
  isOverCurrent: PropTypes.bool,
  connectDragSource: PropTypes.func,
  connectDragPreview: PropTypes.func,
  connectDropTarget: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
};

SortableItem.defaultProps = {
  canSort: true,
  type: 'SortableItem',
};

const SortableItemDragSource = DragSource(getType, itemSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  connectDragPreview: connect.dragPreview(),
  isDragging: monitor.isDragging(),
}))(SortableItem);

export default DropTarget(getType, itemTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  isOverCurrent: monitor.isOver({ shallow: true }),
}))(SortableItemDragSource);

