import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useCSRF } from 'hooks';
import { urls } from 'app-constants';
import Modal from './Modal';
import ErrorBoundary from 'components/common/ErrorBoundary';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Icon from 'components/common/Icon';


const SampleDataImport = () => {
  const csrfToken = useCSRF();
  const [modalVisible, setModalVisible] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (!modalVisible) {
      setErrorMessage(null);
      setIsFetching(false);
    }
  }, []);

  const handleLinkClick = e => {
    e.preventDefault();
    setModalVisible(true);
  };

  const handleModalRequestClose = () => setModalVisible(false);

  const handleCancelClick = e => {
    e.preventDefault();
    handleModalRequestClose();
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    performImport();
  };

  const performImport = () => {
    setIsFetching(true);

    fetch(urls.sampleDataImport, {
      credentials: 'include',
      method: 'POST',
      headers: { 'X-CSRFToken': csrfToken },
    })
      .then(response => {
        if ([400, 403].includes(response.status)) {
          response.json().then(data => {
            setIsFetching(false);
            setErrorMessage(data.detail);
          });
        } else if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          response.json().then(data => {
            window.location.reload();
          });
        }
        return response;
      })
      .catch(err => {
        setIsFetching(false);
        setErrorMessage('Import failed. Please try again later.');
        console.error(err);
      });
  };

  return (
    <ErrorBoundary>
      <a href="#import" onClick={handleLinkClick} className="btn" style={{ display: 'inline-flex', alignItems: 'center' }}>
        <Icon name="file_save" className="mr-3" size={20} />
        Import Sample Content
      </a>
      <Modal
        title="Import Sample Content"
        style={{ content: { width: 660 } }}
        isOpen={modalVisible}
        onRequestClose={handleModalRequestClose}
      >
        <form onSubmit={handleFormSubmit}>
          {errorMessage ? (
            <p className="text-error">{errorMessage}</p>
          ) : isFetching ? (
            <div className="d-flex align-items-center">
              <span className="mr-3"><LoadingSpinner size={30} /></span>
              <span>Importing, please keep this window open…</span>
            </div>
          ) : (
            <p>A selection of example content demonstrating the breadth of the MediaStorm Platform’s feature set will be imported into this workspace.</p>
          )}
          <div className="modal-action-buttons">
            <button type="button" className="btn" onClick={handleCancelClick} disabled={isFetching}>Cancel</button>
            <button className="btn btn-primary" type="submit" disabled={isFetching}>Import</button>
          </div>
        </form>
      </Modal>
    </ErrorBoundary>
  );
};

SampleDataImport.propTypes = {
  formUrl: PropTypes.string,
  multiple: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.string),
};

export default SampleDataImport;
