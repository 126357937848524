import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DateTimeWidget from 'components/common/DateTimeWidget';


const FlexDateField = ({
  yearField,
  monthField,
  dayField,
  timeField,
  showNowShortcut = false,
  disabled = false,
}) => {
  const [fieldVals, setFieldVals] = useState({});
  useEffect(() => {
    setFieldVals({
      year: yearField.value,
      month: monthField.value,
      day: dayField.value,
      time: timeField.value,
    });
  }, [yearField.value, monthField.value, dayField.value, timeField.value]);

  const handleDateChange = vals => setFieldVals(oldState => ({ ...oldState, ...vals }));

  const renderHiddenFields = () => {
    let { year, month, day, time } = fieldVals;

    year = !isNaN(parseInt(year, 10)) ? year : '';
    month = !isNaN(parseInt(month, 10)) ? month : '';
    day = !isNaN(parseInt(day, 10)) ? day : '';

    return (
      <>
        <input type="hidden" name={yearField.name} value={year} />
        <input type="hidden" name={monthField.name} value={month} />
        <input type="hidden" name={dayField.name} value={day} />
        <input type="hidden" name={timeField.name} value={time || ''} />
      </>
    );
  };

  const { year, month, day, time } = fieldVals;
  const calendarDefaultDate = year ? new Date(year, (month || 1) - 1, day || 1) : undefined;

  const errorMessages = [
    ...yearField.errors.map(e => `Year: ${e}`),
    ...monthField.errors.map(e => `Month: ${e}`),
    ...dayField.errors.map(e => `Day: ${e}`),
    ...timeField.errors.map(e => `Time: ${e}`),
  ];

  return (
    <>
      <DateTimeWidget
        year={year || null}
        month={month || null}
        day={day || null}
        time={time}
        yearError={yearField.errors.length > 0}
        monthError={monthField.errors.length > 0}
        dayError={dayField.errors.length > 0}
        timeError={timeField.errors.length > 0}
        showNowShortcut={showNowShortcut}
        disabled={disabled}
        calendarDefaultDate={calendarDefaultDate}
        onChange={handleDateChange}
      />
      {errorMessages.length > 0 && (
        <p className="form-input-hint text-error mt-0 mb-1">
          {errorMessages.map((m, idx) => <span key={idx}>{m}<br /></span>)}
        </p>
      )}
      {renderHiddenFields()}
    </>
  );
};

FlexDateField.propTypes = {
  yearField: PropTypes.object,
  monthField: PropTypes.object,
  dayField: PropTypes.object,
  timeField: PropTypes.object,
  showNowShortcut: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default FlexDateField;
