import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';

const Pagination = ({
  nextPage,
  previousPage,
  currentPage,
  perPage,
  pageCount,
  resultCount,
  resultsThisPage,
  pageSizeOptions,
  onSetPageSize,
  onClickNext,
  onClickPrev,
}) => {
  const firstItemIndex = (currentPage - 1) * perPage + 1;
  const lastItemIndex = (currentPage - 1) * perPage + resultsThisPage;

  return (
    <div className="list-pagination">
      <div className="list-pagination-pagesize-options">
        Per page: &nbsp;
        {pageSizeOptions.map((size, i) => (
          <span key={size}>
            {size === perPage ? <strong>{size}</strong> : <a href={`#${size}`} onClick={e => { e.preventDefault(); onSetPageSize(size); }}>{size}</a>}
            {i < pageSizeOptions.length - 1 ? <span dangerouslySetInnerHTML={{ __html: '&nbsp;&middot;&nbsp;' }} /> : null}
          </span>
        ))}
      </div>
      <div className="list-pagination-results">
        <div>Results {firstItemIndex}–{lastItemIndex} of {resultCount}</div>
        <div className="list-pagination-links">
          <a className="btn btn-primary prev" disabled={!previousPage} onClick={e => { e.preventDefault(); previousPage && onClickPrev(); }}>
            <Icon name="arrow_left" size={32} />
          </a>
          <a className="btn btn-primary next" disabled={!nextPage} onClick={e => { e.preventDefault(); nextPage && onClickNext(); }}>
            <Icon name="arrow_right" size={32} />
          </a>
        </div>
      </div>
    </div>
  );
};

Pagination.propTypes = {
  nextPage: PropTypes.number,
  previousPage: PropTypes.number,
  currentPage: PropTypes.number,
  perPage: PropTypes.number,
  pageCount: PropTypes.number,
  resultCount: PropTypes.number,
  resultsThisPage: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  onSetPageSize: PropTypes.func,
  onClickNext: PropTypes.func,
  onClickPrev: PropTypes.func,
};

Pagination.defaultProps = {
  pageSizeOptions: [50, 100, 200],
};

export default Pagination;
