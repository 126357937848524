import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MapViewportWidget from 'components/common/MapViewportWidget';


const MapViewportField = ({ latField, lngField, zoomField }) => {
  const latVal = Object.keys(latField.errors).length > 0 ? 0 : latField.value;
  const lngVal = Object.keys(lngField.errors).length > 0 ? 0 : lngField.value;

  const [coords, setCoords] = useState([latVal, lngVal]);
  const [zoom, setZoom] = useState(zoomField.value);

  const handleChange = ({ latitude, longitude, zoom }) => {
    setCoords([latitude, longitude]);
    setZoom(zoom);
  };

  const [latitude, longitude] = coords;

  return (
    <>
      <MapViewportWidget
        latitude={latitude}
        longitude={longitude}
        zoom={zoom}
        onChange={handleChange}
      />
      <input type="hidden" name={latField.name} value={latitude} />
      <input type="hidden" name={lngField.name} value={longitude} />
      <input type="hidden" name={zoomField.name} value={zoom} />
    </>
  );
};

MapViewportField.propTypes = {
  latField: PropTypes.object,
  lngField: PropTypes.object,
  zoomField: PropTypes.object,
};

export default MapViewportField;
