import { urls } from 'app-constants';


export const objectListConfig = {
  media: {
    dataEndpoint: urls.mediaData,
    baseUrl: urls.mediaBase,
    columns: [
      { field: 'thumbnail', optional: true, default: true, image: true, sortable: false },
      { field: 'name', optional: true, default: true, sortable: true, link: true },
      { field: 'type', optional: true, default: true, sortable: true },
      { field: 'file_size', label: 'Filesize', optional: true, default: false, sortable: true, numberFormat: 'bytes' },
      { field: 'duration', optional: true, default: true, sortable: true, numberFormat: 'seconds' },
      { field: 'width', optional: true, default: false, sortable: true, numberFormat: 'pixels' },
      { field: 'height', optional: true, default: false, sortable: true, numberFormat: 'pixels' },
      // { field: 'title_count', optional: true, default: true },
      // { field: 'has_master', label: 'Master', optional: true, default: true, sortable: false },
      { field: 'master_filename', label: 'Master', optional: true, default: true, sortable: true },
      { field: 'has_location', label: 'Location', optional: true, default: false, sortable: false },
      { field: 'created', optional: true, default: true, sortable: true },
      { field: 'modified', optional: true, default: false, sortable: true },
      { field: 'captured', optional: true, default: false, sortable: true },
      { field: 'notes', wrap: true, optional: true, default: false, sortable: false },
    ],
    filters: [
      {
        id: 'search',
        label: null,
        filterType: 'search',
        param: 'search',
      },
      {
        id: 'type',
        label: 'Media Type',
        filterType: 'choice',
        param: 'type',
        choices: [
          ['audio', 'Audio'],
          ['image', 'Image'],
          ['video', 'Video'],
        ],
      },
      {
        id: 'source',
        label: 'Source',
        filterType: 'choice',
        param: 'source',
        choices: [
          ['file', 'File'],
          ['vimeo', 'Vimeo'],
          ['youtube', 'YouTube'],
          ['podcast', 'Podcast'],
          // ['soundcloud', 'SoundCloud'],
        ],
      },
      {
        id: 'tags',
        label: 'Tags',
        filterType: 'tags',
        params: {
          allTags: 'tags',
          anyTags: 'tags__in',
        },
      },
      {
        id: 'location',
        label: 'Location',
        filterType: 'choice',
        param: 'has_location',
        choices: [
          ['true', 'Has Location'],
          ['false', 'No Location'],
        ],
      },
      {
        id: 'created',
        label: 'Created',
        filterType: 'daterange',
        params: {
          lowerBound: 'created__date__gte',
          upperBound: 'created__date__lte',
        },
      },
      {
        id: 'modified',
        label: 'Modified',
        filterType: 'daterange',
        params: {
          lowerBound: 'modified__date__gte',
          upperBound: 'modified__date__lte',
        },
      },
      {
        id: 'captured',
        label: 'Captured',
        filterType: 'daterange',
        params: {
          lowerBound: 'capture__date__gte',
          upperBound: 'capture__date__lte',
        },
      },
      {
        id: 'duration',
        label: 'Duration',
        filterType: 'range',
        params: {
          lowerBound: 'duration__gte',
          upperBound: 'duration__lte',
        },
        numberFormat: 'seconds',
        minValue: 0,
        maxValue: 3600, // 1 hour
      },
      {
        id: 'fileSize',
        label: 'File Size',
        filterType: 'range',
        params: {
          lowerBound: 'file_size__gte',
          upperBound: 'file_size__lte',
        },
        numberFormat: 'bytes',
        minValue: 0,
        maxValue: 500e+6, // 500 MB
      },
      {
        id: 'width',
        label: 'Width',
        filterType: 'range',
        params: {
          lowerBound: 'width__gte',
          upperBound: 'width__lte',
        },
        numberFormat: 'pixels',
        minValue: 0,
        maxValue: 5000,
      },
      {
        id: 'height',
        label: 'Height',
        filterType: 'range',
        params: {
          lowerBound: 'height__gte',
          upperBound: 'height__lte',
        },
        numberFormat: 'pixels',
        minValue: 0,
        maxValue: 5000,
      },
    ],
  },

  titles: {
    dataEndpoint: urls.titleData,
    baseUrl: urls.titleBase,
    columns: [
      { field: 'thumbnail', optional: true, default: true, image: true, sortable: false },
      { field: 'name', optional: true, default: true, sortable: true, link: true },
      { field: 'type', optional: true, default: true, sortable: true },
      { field: 'created', optional: true, default: true, sortable: true },
      { field: 'modified', optional: true, default: false, sortable: true },
      { field: 'notes', wrap: true, optional: true, default: false, sortable: false },
    ],
    filters: [
      {
        id: 'search',
        label: null,
        filterType: 'search',
        param: 'search',
      },
      {
        id: 'type',
        label: 'Title Type',
        filterType: 'titletype',
        param: 'type',
        choices: [
          ['Pin Map', 'Pin Map'],
          ['Playlist', 'Playlist'],
          ['Timeline', 'Timeline'],
        ],
      },
      {
        id: 'tags',
        label: 'Tags',
        filterType: 'tags',
        params: {
          allTags: 'tags',
          anyTags: 'tags__in',
        },
      },
      {
        id: 'created',
        label: 'Created',
        filterType: 'daterange',
        params: {
          lowerBound: 'created__date__gte',
          upperBound: 'created__date__lte',
        },
      },
      {
        id: 'modified',
        label: 'Modified',
        filterType: 'daterange',
        params: {
          lowerBound: 'modified__date__gte',
          upperBound: 'modified__date__lte',
        },
      },
    ],
  },

  releases: {
    dataEndpoint: urls.releaseData,
    baseUrl: urls.releaseBase,
    columns: [
      { field: 'thumbnail', optional: true, default: true, image: true, sortable: false },
      { field: 'name', optional: true, default: true, sortable: true, link: true },
      { field: 'type', optional: true, default: true, sortable: true },
      { field: 'created', optional: true, default: true, sortable: true },
      { field: 'modified', optional: true, default: false, sortable: true },
      { field: 'notes', wrap: true, optional: true, default: false, sortable: false },
    ],
    filters: [
      {
        id: 'search',
        label: null,
        filterType: 'search',
        param: 'search',
      },
      {
        id: 'type',
        label: 'Release Type',
        filterType: 'releasetype',
        param: 'type',
        choices: [
          ['Website', 'Website'],
        ],
      },
      {
        id: 'tags',
        label: 'Tags',
        filterType: 'tags',
        params: {
          allTags: 'tags',
          anyTags: 'tags__in',
        },
      },
      {
        id: 'created',
        label: 'Created',
        filterType: 'daterange',
        params: {
          lowerBound: 'created__date__gte',
          upperBound: 'created__date__lte',
        },
      },
      {
        id: 'modified',
        label: 'Modified',
        filterType: 'daterange',
        params: {
          lowerBound: 'modified__date__gte',
          upperBound: 'modified__date__lte',
        },
      },
    ],
  },

  contributors: {
    dataEndpoint: urls.contributorData,
    baseUrl: urls.contributorBase,
    columns: [
      { field: 'thumbnail', optional: true, default: true, image: true, imageSquare: true, sortable: false },
      { field: 'first_name', label: 'First Name', optional: true, default: true, sortable: true },
      { field: 'middle_name', label: 'Middle Name', optional: true, default: false, sortable: true },
      { field: 'last_name', label: 'Last Name', optional: true, default: true, sortable: true },
      { field: 'organization', optional: true, default: true, sortable: true },
      { field: 'tags', optional: true, default: false, sortable: false },
      { field: 'created', optional: true, default: true, sortable: true },
      { field: 'modified', optional: true, default: false, sortable: true },
      { field: 'notes', wrap: true, optional: true, default: false, sortable: false },
    ],
    filters: [
      {
        id: 'search',
        label: null,
        filterType: 'search',
        param: 'search',
      },
      {
        id: 'type',
        label: 'Type',
        filterType: 'choice',
        param: 'type',
        choices: [
          ['individual', 'Individual'],
          ['organization', 'Organization'],
        ],
      },
      // TODO
      // {
      //   id: 'organization',
      //   label: 'Organization',
      //   filterType: 'contributororganization',
      //   param: 'organization',
      //   choices: [],
      // },
      {
        id: 'image',
        label: 'Image',
        filterType: 'choice',
        param: 'has_image',
        choices: [
          ['true', 'Has Image'],
          ['false', 'No Image'],
        ],
      },
      {
        id: 'tags',
        label: 'Tags',
        filterType: 'tags',
        params: {
          allTags: 'tags',
          anyTags: 'tags__in',
        },
      },
      {
        id: 'created',
        label: 'Created',
        filterType: 'daterange',
        params: {
          lowerBound: 'created__date__gte',
          upperBound: 'created__date__lte',
        },
      },
      {
        id: 'modified',
        label: 'Modified',
        filterType: 'daterange',
        params: {
          lowerBound: 'modified__date__gte',
          upperBound: 'modified__date__lte',
        },
      },
    ],
  },

  tags: {
    dataEndpoint: urls.tagData,
    baseUrl: urls.tagBase,
    columns: [
      { field: 'name', label: 'Name', optional: false, default: true, sortable: true },
      { field: 'media_count', label: 'Media', optional: true, default: true, sortable: true },
      { field: 'title_count', label: 'Titles', optional: true, default: true, sortable: true },
      { field: 'release_count', label: 'Releases', optional: true, default: true, sortable: true },
      { field: 'item_count', label: 'Total Items', optional: true, default: true, sortable: true },
      { field: 'created', optional: true, default: true, sortable: true },
      { field: 'modified', optional: true, default: false, sortable: true },
    ],
    filters: [
      {
        id: 'search',
        label: null,
        filterType: 'search',
        param: 'search',
      },
      {
        id: 'status',
        label: 'Status',
        filterType: 'choice',
        param: 'status',
        choices: [
          ['used', 'Used'],
          ['unused', 'Unused'],
        ],
      },
      {
        id: 'created',
        label: 'Created',
        filterType: 'daterange',
        params: {
          lowerBound: 'created__date__gte',
          upperBound: 'created__date__lte',
        },
      },
      {
        id: 'modified',
        label: 'Modified',
        filterType: 'daterange',
        params: {
          lowerBound: 'modified__date__gte',
          upperBound: 'modified__date__lte',
        },
      },
    ],
  },

  'metadata presets': {
    dataEndpoint: urls.metadataPresetData,
    baseUrl: urls.metadataPresetBase,
    columns: [
      { field: 'name', label: 'Name', optional: false, default: true, sortable: true },
      { field: 'media_count', label: 'Media', optional: true, default: true, sortable: true },
      { field: 'title_count', label: 'Titles', optional: true, default: true, sortable: true },
      { field: 'release_count', label: 'Releases', optional: true, default: true, sortable: true },
      { field: 'item_count', label: 'Total Items', optional: true, default: true, sortable: true },
      { field: 'created', optional: true, default: true, sortable: true },
      { field: 'modified', optional: true, default: false, sortable: true },
    ],
    filters: [
      {
        id: 'search',
        label: null,
        filterType: 'search',
        param: 'search',
      },
      {
        id: 'status',
        label: 'Status',
        filterType: 'choice',
        param: 'status',
        choices: [
          ['used', 'Used'],
          ['unused', 'Unused'],
        ],
      },
      {
        id: 'created',
        label: 'Created',
        filterType: 'daterange',
        params: {
          lowerBound: 'created__date__gte',
          upperBound: 'created__date__lte',
        },
      },
      {
        id: 'modified',
        label: 'Modified',
        filterType: 'daterange',
        params: {
          lowerBound: 'modified__date__gte',
          upperBound: 'modified__date__lte',
        },
      },
    ],
  },
};

export const getValidFilterParams = model => {
  const filters = objectListConfig[model].filters;
  return filters.reduce((paramsArr, f) => {
    if ('param' in f) {
      paramsArr.push(f.param);
    }
    if ('params' in f) {
      paramsArr.push(...Object.values(f.params));
    }
    return paramsArr;
  }, []);
};
