import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { urls } from 'app-constants';
import Modal from 'components/common/Modal';
import ErrorBoundary from 'components/common/ErrorBoundary';
import SourcePicker from './SourcePicker';
import FileUpload from './FileUpload';
import Podcast from './Podcast';
import Url from './Url';
import Vimeo from './Vimeo';
import YouTube from './YouTube';


class MediaImport extends Component {
  constructor (props) {
    super(props);
    this.state = {
      modalVisible: false,
      importSource: null,
    };

    this.handleAddMediaClick = this.handleAddMediaClick.bind(this);
    this.handleModalRequestClose = this.handleModalRequestClose.bind(this);
    this.handleSourceSelect = this.handleSourceSelect.bind(this);
    this.handleImportComplete = this.handleImportComplete.bind(this);
    this.renderSourceComponent = this.renderSourceComponent.bind(this);
  }

  handleAddMediaClick (e) {
    e.preventDefault();
    this.setState({ modalVisible: true });
  }

  handleModalRequestClose () {
    this.setState({ modalVisible: false, importSource: null });
  }

  handleSourceSelect (importSource) {
    this.setState({ importSource });
  }

  handleImportComplete () {
    this.setState({ modalVisible: false, importSource: null });
    window.location.reload();
  }

  renderSourceComponent () {
    const { workspaceAdmin } = this.props;
    const { importSource } = this.state;
    switch (importSource) {
      case 'upload':
        return (
          <FileUpload onComplete={this.handleImportComplete} />
        );
      case 'url':
        return <Url endpoint={urls.mediaImportUrl} onComplete={this.handleImportComplete} />;
      case 'vimeo':
        return <Vimeo workspaceAdmin={workspaceAdmin} onComplete={this.handleImportComplete} />;
      case 'youtube':
        return <YouTube onComplete={this.handleImportComplete} />;
      case 'podcast':
        return <Podcast workspaceAdmin={workspaceAdmin} onComplete={this.handleImportComplete} />;
      case 'dropbox':
        return null;
      default:
        return <SourcePicker onSelect={this.handleSourceSelect} />;
    }
  }

  render () {
    const { modalVisible } = this.state;
    return (
      <div>
        <a href="#add-media" className="btn btn-primary" onClick={this.handleAddMediaClick}>Add Media</a>
        <Modal
          title="Add Media"
          style={{ content: { width: 800 } }}
          isOpen={modalVisible}
          onRequestClose={this.handleModalRequestClose}
        >
          <ErrorBoundary>
            {this.renderSourceComponent()}
          </ErrorBoundary>
        </Modal>
      </div>
    );
  }
}

MediaImport.propTypes = {
  workspaceAdmin: PropTypes.bool,
};

export default MediaImport;
