import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'app-constants';
import { useIsVisible } from 'hooks';
import LoadingBubbles from 'components/common/LoadingBubbles';

const LazyThumbnail = ({
  src,
  alt = 'thumbnail',
  width = 100,
  height = 60,
  className,
  style = {},
}) => {
  const containerRef = useRef();
  const isVisible = useIsVisible(containerRef);
  const [imgLoaded, setImgLoaded] = useState(false);

  useEffect(() => {
    setImgLoaded(false);
  }, [src]);

  const containerStyle = {
    position: 'relative',
    display: 'flex',
    flex: `0 0 ${width}px`,
    alignItems: 'center',
    justifyContent: 'center',
    height,
    width,
    backgroundColor: colors.grayMedium,
  };

  const imgStyle = imgLoaded ? null : {
    position: 'absolute',
    visibility: 'hidden',
  };

  return !!src && (
    <div ref={containerRef} className={className} style={containerStyle}>
      <LoadingBubbles style={{ color: colors.grayLight, display: imgLoaded ? 'none' : 'block' }} />
      {(isVisible || imgLoaded) && (
        <img
          src={src}
          alt={alt}
          width={width}
          height={height}
          style={imgStyle}
          onLoad={() => setImgLoaded(true)}
        />
      )}
    </div>
  );
};

LazyThumbnail.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default LazyThumbnail;
