import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import camelize from 'camelize';
import { connect } from 'react-redux';
import { urls } from 'app-constants';
import { simpleMinify } from 'utils';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Icon from 'components/common/Icon';


const TYPE_RESPONSIVE = 'responsive';
const TYPE_LEGACY = 'legacy';
const TYPE_FIXED = 'fixed';

class ReleaseEmbedCode extends Component {
  constructor (props) {
    super(props);
    this.state = {
      historyLoaded: false,
      publishHistory: [],
      error: null,
      embedType: TYPE_RESPONSIVE,
      width: 800,
      height: 610,
      copied: false,
      linkCopied: false,
    };
    this.handleCopyCodeClick = this.handleCopyCodeClick.bind(this);
    this.handleCopyLinkClick = this.handleCopyLinkClick.bind(this);
    this.handleTextareaClick = this.handleTextareaClick.bind(this);
    this.handleEmbedTypeChange = this.handleEmbedTypeChange.bind(this);
    this.handleSizeChange = this.handleSizeChange.bind(this);
  }

  componentDidMount () {
    this.fetchPublishHistory();
  }

  componentDidUpdate (prevProps, prevState) {
    const { lastPublishTimestamp } = this.props;
    const { copied, linkCopied } = this.state;

    if (copied && !prevState.copied) {
      clearTimeout(this.copyTimeout);
      this.copyTimeout = setTimeout(() => {
        this.setState({ copied: false });
      }, 1500);
    }

    if (linkCopied && !prevState.linkCopied) {
      clearTimeout(this.linkCopyTimeout);
      this.linkCopyTimeout = setTimeout(() => {
        this.setState({ linkCopied: false });
      }, 1500);
    }

    if (lastPublishTimestamp !== prevProps.lastPublishTimestamp) {
      this.setState({ historyLoaded: false }, this.fetchPublishHistory);
    }
  }

  componentWillUnmount () {
    clearTimeout(this.copyTimeout);
    clearTimeout(this.linkCopyTimeout);
  }

  handleCopyCodeClick (e) {
    e.preventDefault();
    this.textareaRef.select();
    document.execCommand('copy');
    this.setState({ copied: true });
  }

  handleCopyLinkClick (e) {
    e.preventDefault();
    this.linkInputRef.select();
    document.execCommand('copy');
    this.setState({ linkCopied: true });
  }

  handleTextareaClick (e) {
    e.target.select();
  }

  handleEmbedTypeChange (e) {
    this.setState({ embedType: e.target.value });
  }

  handleSizeChange (e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  fetchPublishHistory () {
    const { releaseId } = this.props;
    const url = urlJoin(urls.releasePublishHistoryBase, releaseId, '/');
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(({ publishHistory }) => {
        this.setState({
          publishHistory: publishHistory.filter(({ result }) => result === 'completed'),
          historyLoaded: true,
        });
      })
      .catch(err => {
        this.setState({ error: err });
        console.error(err);
      });
  }

  renderEmbedCode (url) {
    const { releaseId } = this.props;
    const { embedType, width, height } = this.state;

    const iframeResponsive = `<iframe src="${url}" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;

    switch (embedType) {
      case TYPE_RESPONSIVE:
        return simpleMinify(`
          <style>
            .ms-embed-${releaseId} {
              padding-bottom: 75%;
              padding-bottom:calc(56.25% + 160px);
            }
            @media (max-width: 480px) {
              .ms-embed-${releaseId} {
                padding-bottom:calc(100vh - 100px);
              }
            }
          </style>
          <div class="ms-embed-${releaseId}" style="position:relative">${iframeResponsive}</div>
        `);
      case TYPE_LEGACY:
        return `<div style="padding-bottom:75%;padding-bottom:calc(56.25% + 160px);position:relative;">${iframeResponsive}</div>`;
      case TYPE_FIXED:
        return `<iframe src="${url}" width="${width}" height="${height}" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>`;
    }
  }

  renderCopiedOverlay (copied) {
    const copiedStyle = copied ? { opacity: 1 } : { opacity: 0 };

    return (
      <div className="release-embed-code-copied" style={copiedStyle}>
        <span className="identifier">Copied!</span>
      </div>
    );
  }

  getErrors () {
    const { historyLoaded, publishHistory, error } = this.state;

    const renderError = (msg, style = 'error') => (
      <div className={`${style}-list mb-4`}>
        <ul className="m-0">
          <li>{msg}</li>
        </ul>
      </div>
    );

    if (historyLoaded && !publishHistory.length) {
      return renderError('This release has not yet been published.', 'warning');
    }

    if (error) {
      return renderError('Error retrieving Release details.');
    }

    if (historyLoaded) {
      const [lastPublishResult] = publishHistory.slice().reverse();
      const { url } = lastPublishResult;

      if (!url) {
        return renderError('No URL specified for this S3 bucket. Check that your S3 integration is configured correctly.');
      }
    }

    return null;
  }

  renderContents () {
    const { historyLoaded, publishHistory, copied } = this.state;

    if (!historyLoaded) {
      return (
        <div style={{ height: 80, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingSpinner />
        </div>
      );
    }

    const [lastPublishResult] = publishHistory.slice().reverse();
    const { url } = lastPublishResult;

    return (
      <div className="mb-2" style={{ position: 'relative' }}>
        <textarea
          ref={node => this.textareaRef = node}
          cols="30"
          rows="8"
          className="form-input release-embed-code-textarea"
          value={this.renderEmbedCode(url)}
          onClick={this.handleTextareaClick}
          readOnly
        />
        {this.renderCopiedOverlay(copied)}
      </div>
    );
  }

  renderOptions () {
    const { embedType, width, height } = this.state;
    return (
      <div>
        <label className="form-radio m-0">
          <input
            type="radio"
            value={TYPE_RESPONSIVE}
            checked={embedType === TYPE_RESPONSIVE}
            onChange={this.handleEmbedTypeChange}
          />
          <i className="form-icon" /> <strong>Responsive</strong>
          <div className="text-hint">Embed will scale to fit its container.</div>
        </label>
        <label className="form-radio m-0">
          <input
            type="radio"
            value={TYPE_LEGACY}
            checked={embedType === TYPE_LEGACY}
            onChange={this.handleEmbedTypeChange}
          />
          <i className="form-icon" /> <strong>Responsive (legacy)</strong>
          <div className="text-hint">Use this option if you experience problems using the standard responsive code in your publishing system.</div>
        </label>
        <label className="form-radio m-0">
          <input
            type="radio"
            value={TYPE_FIXED}
            checked={embedType === TYPE_FIXED}
            onChange={this.handleEmbedTypeChange}
          />
          <i className="form-icon" /> <strong>Fixed size</strong>
          <div className="text-hint">Embed will display at the exact width and height specified.</div>
        </label>

        {embedType === TYPE_FIXED && (
          <div className="mt-1" style={{ display: 'flex', paddingLeft: '1.2rem' }}>
            <input name="width" type="text" className="form-input input-sm" style={{ width: 40 }} value={width} onChange={this.handleSizeChange} />
            <span className="mx-1">x</span>
            <input name="height" type="text" className="form-input input-sm" style={{ width: 40 }} value={height} onChange={this.handleSizeChange} />
            <span className="mx-1">pixels</span>
          </div>
        )}
      </div>
    );
  }

  renderLinkField () {
    const { linkCopied, publishHistory } = this.state;

    const [lastPublishResult] = publishHistory.slice().reverse();
    const { url } = lastPublishResult;

    return (
      <div className="mb-2">
        <div style={{ position: 'relative' }}>
          <div className="input-group">
            <input
              ref={node => this.linkInputRef = node}
              type="text"
              className="form-input release-embed-code-link-input"
              value={url}
              readOnly
            />
            <span className="input-group-addon">
              <a href={url} target="_blank" rel="noopener noreferrer" className="d-flex td-none">
                <Icon name="open_in_new" />
              </a>
            </span>
          </div>
          {this.renderCopiedOverlay(linkCopied)}
        </div>
        <p className="form-input-hint">
          View the published player. You can also share this URL on Twitter to embed the player in a Tweet.
        </p>
      </div>
    );
  }

  render () {
    const { includeDirectLink } = this.props;
    const { historyLoaded, publishHistory, error } = this.state;
    const showExtras = historyLoaded && !error && publishHistory.length > 0 && !!publishHistory[publishHistory.length - 1].url;

    const errors = this.getErrors();

    return (
      <>
        <div className="form-group mb-4">
          <div className="mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
            <h5 className="mb-0">Embed code</h5>
            {showExtras && <a className="text-sm text-upper" href="#copy" onClick={this.handleCopyCodeClick}><strong>copy to clipboard</strong></a>}
          </div>
          {errors || this.renderContents()}
          {showExtras && this.renderOptions()}
        </div>

        {!errors && showExtras && includeDirectLink && (
          <div className="form-group mb-4">
            <div className="mb-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
              <h5 className="mb-0">Direct link</h5>
              <a className="text-sm text-upper" href="#copy" onClick={this.handleCopyLinkClick}><strong>copy to clipboard</strong></a>
            </div>
            {this.renderLinkField()}
          </div>
        )}
      </>
    );
  }
}

ReleaseEmbedCode.propTypes = {
  releaseId: PropTypes.string,
  lastPublishTimestamp: PropTypes.number,
  includeDirectLink: PropTypes.bool,
};

ReleaseEmbedCode.defaultProps = {
  includeDirectLink: true,
};

const mapStateToProps = ({ releaseDetail }) => ({
  lastPublishTimestamp: releaseDetail.timestamp,
});

export default connect(mapStateToProps)(ReleaseEmbedCode);
