import React from 'react';
import PropTypes from 'prop-types';
import SingleValueMetric from './SingleValueMetric';

const NullComponent = () => null;

const Metric = props => {
  let MetricComponent = SingleValueMetric;
  switch (props.id) {
    case 'post_video_retention_graph':
      MetricComponent = NullComponent;
      break;
    default:
      break;
  }

  return <MetricComponent {...props} />;
};

Metric.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
};

export default Metric;
