import React from 'react';
import PropTypes from 'prop-types';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import Icon from 'components/common/Icon';
import playlistItemWrapper from './playlistItemWrapper';

const Media = ({
  itemId,
  editMode,
  fields,
  errors,
  settingsActive,
  onSettingsClick,
  onLoadPreview,
  renderHiddenFields,
  renderDeleteOverlay,
  renderControlButtons,
}) => {
  const { media } = fields;

  const previewStyles = {
    backgroundColor: 'transparent',
    padding: '2px 35px 2px 0',
  };

  const handlePreviewLoad = data => onLoadPreview(itemId, data);
  const handleSettingsClick = () => onSettingsClick(itemId);

  return (
    <div>
      <AttachedItemPreview
        itemType="media"
        itemId={media}
        onLoad={handlePreviewLoad}
        style={previewStyles}
        clickableThumbnail={editMode === 'insert'}
      />
      {renderHiddenFields('media')}

      {editMode === 'insert' && (
        <span
          className={`btn-action btn-settings bottom tooltip ${settingsActive ? 'active' : ''}`}
          onClick={handleSettingsClick}
          data-tooltip="Settings"
        >
          <Icon name="settings" fill size={22} />
        </span>
      )}

      {renderDeleteOverlay()}
      {renderControlButtons()}
    </div>
  );
};

Media.propTypes = {
  itemId: PropTypes.string,
  editMode: PropTypes.oneOf(['sort', 'insert', null]),
  fields: PropTypes.object,
  errors: PropTypes.object,
  settingsActive: PropTypes.bool,
  onSettingsClick: PropTypes.func,
  onLoadPreview: PropTypes.func,
  renderHiddenFields: PropTypes.func,
  renderDeleteOverlay: PropTypes.func,
  renderControlButtons: PropTypes.func,
};

export default playlistItemWrapper(Media);
