import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'app-constants';


const LoadingSpinner = ({ className, style, color = colors.blueMedium, size = 40 }) => {
  const containerStyles = {
    height: size,
    width: size,
    position: 'relative',
    ...style,
  };
  const innerStyles = {
    // animation: 'fadein .5s, spinner 1s ease-in-out infinite',
    border: '4px solid #ccc',
    borderRadius: '50%',
    borderTopColor: color,
    height: size,
    left: '50%',
    marginLeft: `-${size / 2}px`,
    marginTop: `-${size / 2}px`,
    position: 'absolute',
    top: '50%',
    width: size,
  };

  return <div className={className} style={containerStyles}><div className="keyframes-spinner" style={innerStyles} /></div>;
};

LoadingSpinner.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  color: PropTypes.string,
  size: PropTypes.number,
};

export default LoadingSpinner;
