import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import JsonListWidget from 'components/common/JsonListWidget';

const JsonListField = ({ field, label, helpText }) => {
  const [value, setValue] = useState(JSON.parse(field.value));

  const inputVal = JSON.stringify(value);
  const handleChange = useCallback(value => setValue(value), []);

  let errors = {};
  if (field.errors && field.errors.length > 0) {
    errors = JSON.parse(field.errors[0]);
  }

  const { name, label: fieldLabel, helpText: fieldHelpText } = camelize(field);

  return (
    <>
      <JsonListWidget
        label={label || fieldLabel}
        helpText={helpText || fieldHelpText}
        value={value}
        errors={errors}
        onChange={handleChange}
      />
      <input type="hidden" name={name} value={inputVal} />
    </>
  );
};


JsonListField.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  helpText: PropTypes.string,
};

export default JsonListField;
