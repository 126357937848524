import React from 'react';
import PropTypes from 'prop-types';
import FlexDateField from 'components/common/FlexDateField';

const PublishDates = ({ fields, disabled = false, renderOverrideToggle, isFieldDisabled }) => {
  const publishedFields = [fields.publishedYear, fields.publishedMonth, fields.publishedDay, fields.publishedTime];
  const updatedFields = [fields.updatedYear, fields.updatedMonth, fields.updatedDay, fields.updatedTime];
  const publishedDisabled = disabled || publishedFields.some(f => isFieldDisabled(f));
  const updatedDisabled = disabled || updatedFields.some(f => isFieldDisabled(f));

  return (
    <>
      <div className="form-group m-0">
        <div className="flex-spread mb-1">
          <h5 className="m-0">Date published</h5>
          {renderOverrideToggle([...publishedFields.map(f => f.name), 'published_date'])}
        </div>
        <FlexDateField
          yearField={fields.publishedYear}
          monthField={fields.publishedMonth}
          dayField={fields.publishedDay}
          timeField={fields.publishedTime}
          disabled={publishedDisabled}
          showNowShortcut={!publishedDisabled}
        />
      </div>

      <div className="form-group m-0">
        <div className="flex-spread mb-1">
          <h5 className="m-0">Date updated</h5>
          {renderOverrideToggle([...updatedFields.map(f => f.name), 'updated_date'])}
        </div>
        <FlexDateField
          yearField={fields.updatedYear}
          monthField={fields.updatedMonth}
          dayField={fields.updatedDay}
          timeField={fields.updatedTime}
          disabled={updatedDisabled}
          showNowShortcut={!updatedDisabled}
        />
      </div>
      <div className="text-hint mb-4">
        Published/Updated dates can be displayed by adding a <em>Publication Dates</em> block to a menu item or page title. These values are for display purposes only — setting a publication date in the future <em>does not</em> schedule publication.
      </div>
    </>
  );
};

PublishDates.propTypes = {
  fields: PropTypes.object,
  disabled: PropTypes.bool,
  renderOverrideToggle: PropTypes.func,
  isFieldDisabled: PropTypes.func,
};

export default PublishDates;
