import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import { v4 as uuid4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import Icon from 'components/common/Icon';
import SortableItem from 'components/common/SortableItem';
import { DeleteOverlay } from 'components/common/inlines';
import { relatedItemDefaults } from './slideFormDefaults';
import RelatedItemInlineForm from './RelatedItemInlineForm';


class RelatedItemsField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeItem: null,
      formData: {
        ...relatedItemDefaults,
      },
      showModal: false,
      attachedMedia: {},
      processing: false,
      error: null,
    };
    this.itemRefs = {};

    this.initNewItem = this.initNewItem.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleAddItemClick = this.handleAddItemClick.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleMediaChange = this.handleMediaChange.bind(this);
    this.handleFormClose = this.handleFormClose.bind(this);
    this.handleItemMove = this.handleItemMove.bind(this);
    this.handleItemMove = throttle(this.handleItemMove, 50);
    this.saveFormValues = this.saveFormValues.bind(this);
  }

  componentDidMount () {
    this.fetchData();
  }

  componentDidUpdate (prevProps, prevState) {
    const { value } = this.props;
    const { activeItem, formData } = this.state;

    // Clear activeItem if the item was deleted
    if (value.length !== prevProps.value.length && activeItem && !value.find(({ itemId }) => itemId === activeItem)) {
      this.setState({ activeItem: null });
    }

    if (activeItem !== prevState.activeItem) {
      this.initFormData();

      if (activeItem) {
        this.setState({ showModal: true });
      }
    }

    if (activeItem && !isEqual(formData, prevState.formData)) {
      this.saveFormValues();
    }
  }

  componentWillUnmount () {
    clearTimeout(this.activeItemTimeout);
  }

  fetchData () {
    const { value } = this.props;
    const mediaIds = value.map(item => item.media);
    if (!mediaIds.length) {
      return null;
    }
    const idString = mediaIds.join(',');
    const url = urlJoin(urls.mediaData, '/', `?format=json&uuid__in=${idString}`);
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({
          attachedMedia: data.results.reduce((result, item) => {
            result[item.uuid] = item;
            return result;
          }, {}),
          processing: false,
        });
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  initNewItem () {
    const { value, onChange } = this.props;
    // Initialize a new item with a uid and default form values
    const itemId = uuid4();
    const newItem = { ...relatedItemDefaults, itemId };

    onChange([...value, newItem]);
    this.setState({ activeItem: itemId }, () => {
      setTimeout(() => !!this.itemRefs[itemId] && this.itemRefs[itemId].scrollIntoView({ behavior: 'smooth' }), 500);
    });
  }

  handleEditClick (e, itemId) {
    e.stopPropagation();
    this.setState({ activeItem: itemId });
  }

  handleDeleteClick (e, itemId) {
    const { value, onChange } = this.props;
    e.stopPropagation();
    const newValue = value.map(item => {
      return item.itemId === itemId ? { ...item, shouldDelete: !item.shouldDelete } : item;
    });
    onChange(newValue);
  }

  handleAddItemClick (e) {
    e.preventDefault();
    this.initNewItem();
  }

  handleFormChange (value) {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        ...value,
      },
    });
  }

  handleMediaChange (mediaObj) {
    const { attachedMedia } = this.state;
    this.setState({
      attachedMedia: {
        ...attachedMedia,
        [mediaObj.uuid]: mediaObj,
      },
    });
  }

  handleFormClose (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.nativeEvent.submitter && e.nativeEvent.submitter.value === 'add-another') {
      this.setState({ showModal: false });
      this.activeItemTimeout = setTimeout(() => {
        this.setState({ activeItem: null }, this.initNewItem);
      }, 500);
    } else {
      this.setState({ showModal: false });
      this.activeItemTimeout = setTimeout(() => this.setState({ activeItem: null }), 500);
    }
  }

  handleItemMove (sourceId, targetId, insertPos) {
    const { value, onChange } = this.props;
    const sourceItem = value.find(item => item.itemId === sourceId);
    const targetIndex = value.findIndex(item => item.itemId === targetId);
    const toIndex = insertPos === 'before' ? targetIndex : targetIndex + 1;
    const newValue = value.filter(item => item.itemId !== sourceId);
    newValue.splice(toIndex, 0, sourceItem);
    onChange(newValue);
  }

  saveFormValues () {
    const { value, onChange } = this.props;
    const { formData, activeItem } = this.state;
    if (!activeItem) {
      return null;
    }
    const newValue = [...value];
    const index = newValue.findIndex(item => item.itemId === activeItem);
    newValue[index] = { ...newValue[index], ...formData };
    onChange(newValue);
  }

  initFormData () {
    // Load initial field values for the child form
    const { value } = this.props;
    const { activeItem } = this.state;
    const index = value.findIndex(item => item.itemId === activeItem);
    const initialData = value[index] || {};
    const formData = { ...relatedItemDefaults, ...initialData };
    this.setState({ formData });
  }

  getNameText (item) {
    const { name, nameSource, itemType, media } = item;
    const { attachedMedia } = this.state;
    let nameText = '[No Name]';

    switch (nameSource) {
      case 'media_name':
        if (media) {
          nameText = attachedMedia[media] ? attachedMedia[media].name : '';
        }
        break;
      case 'custom':
        nameText = name || nameText;
        break;
      default:
        break;
    }

    return <><span style={{ textTransform: 'capitalize' }}>{itemType}</span>: {nameText}</>;
  }

  renderForm () {
    const { formData, activeItem } = this.state;
    if (!activeItem) {
      return null;
    }
    return (
      <RelatedItemInlineForm
        key={activeItem}
        item={formData}
        onSubmit={this.handleFormClose}
        onChange={this.handleFormChange}
        onMediaChange={this.handleMediaChange}
      />
    );
  }

  renderItems () {
    const { name, value: items } = this.props;
    const { activeItem } = this.state;
    // Use the value of the first field to label each item
    return items.map(item => {
      const label = this.getNameText(item) || '(New item)';
      const classes = classNames({
        item: true,
        active: item.itemId === activeItem,
        error: Object.keys(item.errors).length > 0,
      });
      return (
        <SortableItem key={item.itemId} itemId={item.itemId} type={name} className="mb-4" onItemMove={this.handleItemMove}>
          <div className={classes} ref={node => this.itemRefs[item.itemId] = node}>
            <h5 className="mb-0">{label}</h5>
            <div className="d-flex align-items-center">
              <span
                className="btn-delete tooltip tooltip-left mx-1"
                onClick={e => this.handleDeleteClick(e, item.itemId)}
                data-tooltip="Remove Item"
              >
                <Icon name="delete" fill />
              </span>
              <span
                className="btn-edit tooltip tooltip-left mx-1"
                onClick={e => this.handleEditClick(e, item.itemId)}
                data-tooltip="Edit Item"
              >
                <Icon name="edit" />
              </span>
              <span className="btn-move ml-1" title="Drag to sort">
                <Icon name="menu" />
              </span>
            </div>
            {item.shouldDelete && <DeleteOverlay onUndoClick={e => this.handleDeleteClick(e, item.itemId)} />}
          </div>
        </SortableItem>
      );
    });
  }

  render () {
    const { label, helpText, value } = this.props;
    const { showModal } = this.state;
    const modalClasses = classNames('custom-title-builder-child-form-modal', 'level-3', showModal && 'open');
    const hasError = value.some(v => Object.keys(v.errors) > 0);

    return (
      <div className="form-group mb-4">
        <label htmlFor={name} className="block-label">{label}</label>
        {helpText ? <p className="form-input-hint m-0">{helpText}</p> : null}
        <div className="relation-field mt-1">
          {hasError ? <div className="error-list">Some of the items below contain errors.</div> : null}
          <div className="item-list">
            {this.renderItems()}
          </div>
          <div className="controls">
            <div className="item-count">{`${value.length} Item${value.length === 1 ? '' : 's'}`}</div>
            <button type="button" className="btn btn-primary" onClick={this.handleAddItemClick}>Add Item</button>
          </div>
        </div>
        <div className={`custom-title-builder-child-form-modal-mask ${showModal ? 'open' : ''}`} style={{ opacity: 0 }} />
        <div className={modalClasses}>
          <header className="custom-title-builder-child-form-modal-header">
            <a href="#back" className="back-icon" onClick={this.handleFormClose}>
              <Icon name="arrow_back_ios" size={32} />
            </a>
            <h3>Editing <em>{label}</em> Item</h3>
          </header>
          <div className="custom-title-builder-child-form-modal-content">
            {this.renderForm()}
          </div>
        </div>
      </div>
    );
  }
}

RelatedItemsField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({
    itemId: PropTypes.string,
    errors: PropTypes.object,
    shouldDelete: PropTypes.bool,
    itemType: PropTypes.oneOf(['link', 'media']),
    index: PropTypes.number,
    name: PropTypes.string,
    nameSource: PropTypes.oneOf(['none', 'custom', 'media_name']),
    description: PropTypes.string,
    descriptionSource: PropTypes.oneOf(['none', 'custom', 'media_short_desc', 'media_long_desc']),
    credit: PropTypes.string,
    creditSource: PropTypes.oneOf(['none', 'custom', 'media_credit']),
    media: PropTypes.string,
  })),
  onChange: PropTypes.func,
};

RelatedItemsField.defaultProps = {
  value: [],
  onChange: () => null,
};

export default RelatedItemsField;
