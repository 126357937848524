import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { v4 as uuid4 } from 'uuid';
import camelize from 'camelize';
import { withResizeDetector } from 'react-resize-detector';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Message from 'components/common/Message';
import SortableItem from 'components/common/SortableItem';
import StyledScrollbar from 'components/common/StyledScrollbar';
import { ManagementForm } from 'components/common/inlines';
import withDragDropContext from 'components/common/withDragDropContext';
import AddTitleAttachment from 'components/common/AddTitleAttachment';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import Icon from 'components/common/Icon';
import formDefaults from './formDefaults';
import Item from './Item';
import Form from './Form';

const SOURCE_OPTION_MANUAL = 'manual';
const SOURCE_OPTION_TITLE = 'title';

class CoverBuilder extends Component {
  constructor (props) {
    super(props);
    this.state = {
      itemsById: {},
      itemsOrdered: [],
      activeItemId: null,
      sourceOption: props.itemSourceField.value ? SOURCE_OPTION_TITLE : SOURCE_OPTION_MANUAL,
      itemSourceId: props.itemSourceField.value || null,
    };
    this.nextItemIndex = 0;

    this.handleItemAdd = this.handleItemAdd.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleItemDeleteClick = this.handleItemDeleteClick.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleItemMove = this.handleItemMove.bind(this);
    this.handleItemMove = debounce(this.handleItemMove, 100);
    this.handleSourceOptionChange = this.handleSourceOptionChange.bind(this);
  }

  componentDidMount () {
    this.initItems();
  }

  componentDidUpdate (prevProps, prevState) {
    const { itemsById, itemsOrdered, activeItemId } = this.state;

    if (activeItemId && itemsById[activeItemId].shouldDelete) {
      // When the currently selected item is marked for deletion, select the next item.
      // If there is no next item, select the first item.
      const nextIndex = itemsOrdered.indexOf(activeItemId) + 1;
      let newActiveItemId = itemsOrdered[nextIndex] || itemsOrdered[0];
      // If all items are marked for deletion...
      if (!itemsOrdered.some(id => !itemsById[id].shouldDelete)) {
        newActiveItemId = null;
      }
      this.setState({ activeItemId: newActiveItemId });
    }
  }

  handleItemAdd () {
    this.initNewItem({ ...formDefaults });
  }

  handleItemClick (itemId) {
    const item = this.getItem(itemId);
    if (item && item.shouldDelete) {
      return;
    }
    this.setState({ activeItemId: itemId });
  }

  handleItemDeleteClick (itemId) {
    const { itemsById } = this.state;
    const item = this.getItem(itemId);
    this.setState({
      itemsById: {
        ...itemsById,
        [itemId]: {
          ...item,
          shouldDelete: !item.shouldDelete,
        },
      },
    });
  }

  handleFormChange (itemObj) {
    const { itemsById, activeItemId } = this.state;
    const activeItem = this.getItem(activeItemId);
    const newItem = {
      ...activeItem,
      ...itemObj,
    };

    this.setState({
      itemsById: {
        ...itemsById,
        [activeItemId]: newItem,
      },
    });
  }

  handleItemMove (sourceId, targetId, insertPos) {
    const { itemsOrdered } = this.state;
    const toIndex = insertPos === 'before' ? itemsOrdered.indexOf(targetId) : itemsOrdered.indexOf(targetId) + 1;
    const newValue = itemsOrdered.filter(uid => uid !== sourceId);
    newValue.splice(toIndex, 0, sourceId);
    this.setState({ itemsOrdered: newValue });
  }

  handleSourceOptionChange (e) {
    const { value } = e.target;
    const newState = { sourceOption: value };
    if (value === SOURCE_OPTION_MANUAL) {
      newState.itemSourceId = null;
    }
    this.setState(newState);
  }

  initNewItem (itemObject) {
    const { parentId } = this.props;
    const { prefix } = this.props.formsetData;
    const { itemsById, itemsOrdered } = this.state;

    const itemId = uuid4();
    const item = {
      itemId,
      parent: parentId,
      itemIndex: this.nextItemIndex,
      prefix: `${prefix}-${this.nextItemIndex}`,
      errors: {},
      ...itemObject,
    };

    this.setState({
      itemsById: {
        ...itemsById,
        [itemId]: item,
      },
      itemsOrdered: [
        itemId,
        ...itemsOrdered,
      ],
      activeItemId: itemId,
    });

    this.nextItemIndex += 1;
    return item;
  }

  initItems () {
    // Hydrate item list from initial formset data
    const { forms, prefix } = camelize(this.props.formsetData);
    const itemsById = {};
    const itemsOrdered = [];
    forms.forEach(form => {
      const itemId = uuid4();
      const fields = Object.keys(form.fields).reduce((result, key) => {
        if (key === 'DELETE') {
          result.shouldDelete = !!form.fields[key].value;
        } else {
          result[key] = form.fields[key].value;
        }
        return result;
      }, {});

      const item = {
        itemId,
        itemIndex: this.nextItemIndex,
        prefix: `${prefix}-${this.nextItemIndex}`,
        errors: form.errors,
        ...fields,
      };

      itemsById[itemId] = item;
      itemsOrdered.push(itemId);
      this.nextItemIndex += 1;
    });
    this.setState({ itemsById, itemsOrdered, activeItemId: itemsOrdered[0] });
  }

  getItem (itemId) {
    const { itemsById } = this.state;
    return itemsById[itemId];
  }

  renderItems () {
    const { itemsOrdered, activeItemId } = this.state;

    return itemsOrdered.map((itemId, idx) => {
      const item = this.getItem(itemId);
      const itemHasError = Object.keys(item.errors).length > 0;
      return (
        <SortableItem
          key={itemId}
          itemId={itemId}
          type="title-builder-items"
          onItemMove={this.handleItemMove}
        >
          <Item
            key={itemId}
            counter={idx + 1}
            active={activeItemId === itemId}
            error={itemHasError}
            onClick={() => this.handleItemClick(itemId)}
            onDeleteClick={() => this.handleItemDeleteClick(itemId)}
            {...item}
          />
        </SortableItem>
      );
    });
  }

  renderRightColumn () {
    const { itemSourceField } = this.props;
    const { itemsOrdered, activeItemId, sourceOption, itemSourceId } = this.state;
    const activeItem = this.getItem(activeItemId);
    const activeItemIndex = itemsOrdered.indexOf(activeItemId) + 1;

    const emptyMsg = itemsOrdered.length
      ? 'No item selected. Choose a item from the list at left, or…'
      : 'No items yet! Add some to get started.';

    if (sourceOption === SOURCE_OPTION_TITLE) {
      return (
        <div className="title-builder-form" style={{ paddingTop: 30, flex: 1 }}>
          <div className={classNames('form-group', 'mb-5', itemSourceField.errors.length > 0 && 'has-error')}>
            <label className="block-label">Source Title</label>
            <p className="form-input-hint m-0">Select a Playlist or Slideshow Title to generate Cover items from.</p>

            {itemSourceId ? (
              <AttachedItemPreview itemType="title" itemId={itemSourceId} clickableThumbnail>
                <div
                  className="btn-delete tooltip tooltip-left"
                  data-tooltip="Remove Title"
                  onClick={() => this.setState({ itemSourceId: null })}
                >
                  <Icon name="delete" fill />
                </div>
              </AttachedItemPreview>
            ) : (
              <div className="attached-item-preview">
                <AddTitleAttachment
                  preFilter={{ type__in: 'Playlist,Slideshow' }}
                  disabledFilters={['type']}
                  onChange={id => this.setState({ itemSourceId: id })}
                >
                  {handleModalTrigger => (
                    <button type="button" className="btn btn-primary" onClick={handleModalTrigger}>Select Title</button>
                  )}
                </AddTitleAttachment>
              </div>
            )}
            {itemSourceField.errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
          </div>
          <input type="hidden" name={itemSourceField.name} value={itemSourceId || ''} />
        </div>
      );
    } else {
      return (
        activeItemId ? (
          <Form item={activeItem} index={activeItemIndex} onChange={this.handleFormChange} />
        ) : (
          <div style={{ padding: 40, textAlign: 'center', flex: 1 }}>
            <p>{emptyMsg}</p>
            <button className="btn" type="button" onClick={this.handleItemAdd}>Add New Item</button>
          </div>
        )
      );
    }
  }

  render () {
    const { hasError, width } = this.props;
    const { prefix, managementForm } = camelize(this.props.formsetData);
    const { itemsOrdered, sourceOption } = this.state;
    const formCount = itemsOrdered.length;

    return (
      <div className="title-builder object-detail-panel-full-height">
        <ErrorBoundary>
          <ManagementForm prefix={prefix} formData={managementForm} totalForms={formCount} />
          <div className="title-builder-items-container" style={{ flex: '0 0 500px' }}>
            <StyledScrollbar key={width}>
              {hasError ? <Message type="error" text="Some items contain errors. Correct the errors below before saving." /> : null}
              <div
                className="title-builder-item disabled"
                // style={{ justifyContent: 'center' }}
              >
                <div className="form-group m-0">
                  <label className="strong mb-1" style={{ display: 'block' }}>Item Source</label>

                  <label className="form-radio m-0 lh-1">
                    <input
                      type="radio"
                      value={SOURCE_OPTION_MANUAL}
                      checked={sourceOption === SOURCE_OPTION_MANUAL}
                      onChange={this.handleSourceOptionChange}
                    />
                    <i className="form-icon" style={{ top: 3 }} /> Manual <span className="text-hint ml-1">Specify Cover items manually.</span>
                  </label>

                  <label className="form-radio m-0 lh-1">
                    <input
                      type="radio"
                      value={SOURCE_OPTION_TITLE}
                      checked={sourceOption === SOURCE_OPTION_TITLE}
                      onChange={this.handleSourceOptionChange}
                    />
                    <i className="form-icon" style={{ top: 3 }} /> <span>Title</span> <span className="text-hint ml-1">Automatically generate Cover items from the contents of a Playlist or Slideshow Title.</span>
                  </label>
                </div>

                {sourceOption === SOURCE_OPTION_MANUAL && (
                  <div className="pl-2">
                    <button type="button" className="btn btn-primary" onClick={this.handleItemAdd}>Add New Item</button>
                  </div>
                )}
              </div>
              <div style={{ display: sourceOption === SOURCE_OPTION_TITLE ? 'none' : 'block' }}>
                {this.renderItems()}
              </div>
            </StyledScrollbar>
          </div>
          {this.renderRightColumn()}
        </ErrorBoundary>
      </div>
    );
  }
}

CoverBuilder.propTypes = {
  itemSourceField: PropTypes.object,
  formsetData: PropTypes.object,
  parentId: PropTypes.string,
  hasError: PropTypes.bool,
  width: PropTypes.number,
};

export default withDragDropContext(withResizeDetector(CoverBuilder, {
  handleWidth: true,
  handleHeight: false,
  refreshMode: 'debounce',
}));
