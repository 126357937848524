import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import classNames from 'classnames';


class TabNav extends Component {
  constructor (props) {
    super(props);
    this.state = { activeTab: null };
    this.handleTabClick = this.handleTabClick.bind(this);
    this.setActiveTab = this.setActiveTab.bind(this);
    this.updateTabDisplay = this.updateTabDisplay.bind(this);
    this.updateFormAction = this.updateFormAction.bind(this);
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { location, tabs, defaultTab, queryParam } = nextProps;
    const tabFromParam = queryString.parse(location.search)[queryParam];
    const slugList = tabs.map(t => t.slug);
    const activeTab = slugList.includes(tabFromParam) ? tabFromParam : defaultTab;
    return { activeTab };
  }

  componentDidMount () {
    this.updateTabDisplay();
    this.updateFormAction();
  }

  componentDidUpdate (prevProps, prevState) {
    const { activeTab } = this.state;
    if (activeTab !== prevState.activeTab) {
      this.updateTabDisplay();
      this.updateFormAction();
    }
  }

  handleTabClick (e, slug) {
    e.preventDefault();
    this.setActiveTab(slug);
  }

  setActiveTab (slug) {
    const { location, history, queryParam } = this.props;
    const currentParams = queryString.parse(location.search);
    const params = {
      ...currentParams,
      [queryParam]: slug,
    };
    const qs = queryString.stringify(params);
    history.push(`${location.pathname}?${qs}`);
  }

  updateTabDisplay () {
    const { tabContainer, activeClass } = this.props;
    const { activeTab } = this.state;
    const allTabsSelector = `[data-tab-container="${tabContainer}"] [data-tab]`;
    const activeTabSelector = `[data-tab-container="${tabContainer}"] [data-tab="${activeTab}"]`;
    const allTabNodes = document.querySelectorAll(allTabsSelector);
    const activeTabNode = document.querySelector(activeTabSelector);
    allTabNodes.forEach(el => el.classList.remove(activeClass));
    activeTabNode.classList.add(activeClass);
  }

  updateFormAction () {
    const { formName, queryParam } = this.props;
    const { activeTab } = this.state;

    if (formName) {
      const form = document[formName];
      const [loc, qs] = form.action.split('?');
      const queryObj = queryString.parse(qs);
      queryObj[queryParam] = activeTab;
      form.action = `${loc}?${queryString.stringify(queryObj)}`;
    }
  }

  render () {
    const { tabs } = this.props;
    const { activeTab } = this.state;

    return (
      <>
        <ul className="tab-nav">
          {tabs.map(({ label, slug, hasError }) => {
            const classes = classNames({
              'tab-nav-item': true,
              active: activeTab === slug,
              error: hasError,
              tooltip: hasError,
              'tooltip-right': hasError,
            });
            return (
              <li key={slug} className={classes} data-tooltip={hasError ? 'This panel contains errors' : null}>
                <a href={`#${slug}`} onClick={e => this.handleTabClick(e, slug)}>{label}</a>
              </li>
            );
          })}
        </ul>
      </>
    );
  }
}

TabNav.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    slug: PropTypes.string,
    hasError: PropTypes.bool,
  })),
  tabContainer: PropTypes.string,
  defaultTab: PropTypes.string,
  queryParam: PropTypes.string,
  activeClass: PropTypes.string,
  formName: PropTypes.string,
};

TabNav.defaultProps = {
  queryParam: 'panel',
  activeClass: 'active',
};

export default withRouter(TabNav);
