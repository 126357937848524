import React from 'react';
import PropTypes from 'prop-types';


const LoadingBubbles = ({ style = {} }) => (
  <div className="loading-bubbles" style={{ width: 32, height: 16, ...style }}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 16" style={{ display: 'block' }}>
      <circle fill="currentColor" transform="translate(8 0)" cx="0" cy="8" r="0">
        <animate
          attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0"
          keyTimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8" calcMode="spline"
        />
      </circle>
      <circle fill="currentColor" transform="translate(16 0)" cx="0" cy="8" r="0">
        <animate
          attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0.3"
          keyTimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8" calcMode="spline"
        />
      </circle>
      <circle fill="currentColor" transform="translate(24 0)" cx="0" cy="8" r="0">
        <animate
          attributeName="r" values="0; 4; 0; 0" dur="1.2s" repeatCount="indefinite" begin="0.6"
          keyTimes="0;0.2;0.7;1" keySplines="0.2 0.2 0.4 0.8;0.2 0.6 0.4 0.8;0.2 0.6 0.4 0.8" calcMode="spline"
        />
      </circle>
    </svg>
  </div>
);

LoadingBubbles.propTypes = {
  style: PropTypes.object,
};

export default LoadingBubbles;
