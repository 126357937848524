import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import isAfter from 'date-fns/isAfter';
import { combineDateParts } from 'utils';
import { useTitleBuilder } from 'components/views/titleBuilder';
import ErrorBoundary from 'components/common/ErrorBoundary';
import StyledScrollbar from 'components/common/StyledScrollbar';
import Item from './Item';
import Form from './Form';
import itemFormDefaults from './itemFormDefaults';

const TimelineBuilder = ({ formsetData, hasError }) => {
  const opts = {
    formsetData,
    itemLabel: 'slide',
    itemLabelPlural: 'slides',
    hasError,
    isSortable: false,
    ItemComponent: Item,
    itemFormDefaults,
  };

  const {
    itemsById,
    setItemsOrdered,
    activeItem,
    handleItemAdd,
    handleFormChange,
    managementForm,
    messages,
    renderEmptyMessage,
    renderedItems,
  } = useTitleBuilder(opts);

  useEffect(() => updateSortOrder(), [itemsById]);

  const updateSortOrder = () => {
    const newItemsOrdered = Object.keys(itemsById).sort((a, b) => {
      const aDate = combineDateParts(itemsById[a]);
      const bDate = combineDateParts(itemsById[b]);

      if (!aDate && !bDate) {
        return 1;
      } else if (aDate && !bDate) {
        return 1;
      } else if (bDate && !aDate) {
        return -1;
      } else {
        return isAfter(aDate, bDate) ? 1 : -1;
      }
    });
    setItemsOrdered(newItemsOrdered);
  };

  return (
    <div className="title-builder object-detail-panel-full-height">
      <ErrorBoundary>
        {managementForm}
        <div className="title-builder-items-container">
          <StyledScrollbar>
            {messages}
            <div className="title-builder-item disabled" style={{ justifyContent: 'flex-end' }}>
              <button type="button" className="btn btn-primary" onClick={handleItemAdd}>Add New Slide</button>
            </div>
            {renderedItems}
          </StyledScrollbar>
        </div>

        {activeItem ? (
          <Form
            item={activeItem}
            onChange={handleFormChange}
          />
        ) : renderEmptyMessage()}
      </ErrorBoundary>
    </div>
  );
};

TimelineBuilder.propTypes = {
  formsetData: PropTypes.object,
  hasError: PropTypes.bool,
};

export default TimelineBuilder;
