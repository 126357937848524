const VALUE_SIMPLE = 'simple'; // msp_core.models.Media.CREDIT_TYPE_SIMPLE
const VALUE_ADVANCED = 'advanced'; // msp_core.models.Media.CREDIT_TYPE_ADVANCED

const creditTypeInputs = document.querySelectorAll('[name=credit_type]');
if (creditTypeInputs.length > 0) {
  const toggleCreditMode = val => {
    const simpleEl = document.querySelector('.media-credit-simple-container');
    const advancedEl = document.querySelector('.media-credit-advanced-container');
    if (!simpleEl || !advancedEl) return null;
    if (val === VALUE_SIMPLE) {
      simpleEl.classList.remove('d-none');
      if (!advancedEl.hasAttribute('data-error')) advancedEl.classList.add('d-none');
    } else if (val === VALUE_ADVANCED) {
      advancedEl.classList.remove('d-none');
      if (!simpleEl.hasAttribute('data-error')) simpleEl.classList.add('d-none');
    }
  };

  creditTypeInputs.forEach(el => el.addEventListener('change', e => toggleCreditMode(e.target.value)));

  // init on page load
  toggleCreditMode(document.querySelector('[name=credit_type]:checked').value);
}
