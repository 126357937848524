import React from 'react';
import PropTypes from 'prop-types';


const ProgressBar = ({ percent, className, style }) => (
  <div className={`progress ${className || ''}`} style={style}>
    <div className="progress-bar" style={{ width: `${percent}%` }} />
  </div>
);

ProgressBar.propTypes = {
  percent: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default ProgressBar;
