import React from 'react';
import ReactModal from 'react-modal';


const Modal = ({ title, children, onRequestClose, className = '', headerContent, ...rest }) => {
  const classes = {
    base: `modal ${className}`,
    afterOpen: 'modal--after-open',
    beforeClose: 'modal--before-close',
  };
  const overlayClasses = {
    base: 'modal-overlay',
    afterOpen: 'modal-overlay--after-open',
    beforeClose: 'modal-overlay--before-close',
  };

  return (
    <ReactModal
      className={classes}
      overlayClassName={overlayClasses}
      ariaHideApp={false}
      closeTimeoutMS={0}
      onRequestClose={onRequestClose}
      {...rest}
    >
      <header className="modal-header">
        {headerContent || (
          <>
            <h2 className="alt-head">{title}</h2>
            <div className="modal-close" onClick={onRequestClose} />
          </>
        )}
      </header>
      <div className="modal-content">
        {children}
      </div>
    </ReactModal>
  );
};

Modal.propTypes = ReactModal.propTypes;

export default Modal;
