import React from 'react';
import PropTypes from 'prop-types';


const TemplatePicker = ({ templateItems, onSelect, selectedTemplateId }) => {
  return (
    <div className="release-template-choices">
      {templateItems.map(({ uid, name, slug, description, thumbnail }) => {
        const active = uid === selectedTemplateId;
        return (
          <label key={uid} className={`release-template-choice-item ${active ? 'active' : ''}`}>
            <label className="form-radio form-inline radio-clear" style={{ flex: '0 0 16px' }}>
              <input type="radio" name="release-template" onChange={() => onSelect(uid)} value={uid} checked={active} />
              <i className="form-icon" />
            </label>
            <div className="release-template-thumbnail mx-3">
              <img src={thumbnail} alt="" />
            </div>
            <div>
              <h6 className="m-0">{name}</h6>
              {description ? <div className="text-meta mt-1" dangerouslySetInnerHTML={{ __html: description }} /> : null}
            </div>
          </label>
        );
      })}
    </div>
  );
};

TemplatePicker.propTypes = {
  templateItems: PropTypes.arrayOf(PropTypes.object),
  selectedTemplateId: PropTypes.string,
  onSelect: PropTypes.func,
};

export default TemplatePicker;
