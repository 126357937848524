import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'app-constants';
import { components } from 'react-select';
import Select from 'components/common/Select';
import MapboxStaticMap from 'components/common/MapboxStaticMap';


const { Option, SingleValue } = components;

const MapStylePreview = ({ label, owner, id, accessToken }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ border: `1px solid ${colors.grayLight}`, backgroundColor: colors.grayExtraLight }}>
        <MapboxStaticMap
          accessToken={accessToken}
          styleId={id}
          user={owner}
          lat={40.7}
          lon={-73.99}
          zoom={11}
          width={158}
          height={88}
          style={{ flex: '0 0 160' }}
        />
      </div>
      <div style={{ paddingLeft: 10 }}>
        <div><h6 style={{ margin: '0 0 5px' }}>{label}</h6></div>
        <div style={{ fontSize: '14px', lineHeight: 1.1, color: colors.grayMedium }}>
          <strong>id:</strong> {id}<br />
          <strong>user:</strong> {owner}
        </div>
      </div>
    </div>
  );
};

MapStylePreview.propTypes = {
  label: PropTypes.string,
  owner: PropTypes.string,
  id: PropTypes.string,
  accessToken: PropTypes.string,
};

const MapStyleOption = props => (
  <Option {...props}>
    <MapStylePreview {...props.data} />
  </Option>
);

MapStyleOption.propTypes = {
  data: PropTypes.object,
};

const MapStyleValue = props => (
  <SingleValue {...props}>
    <MapStylePreview {...props.data} />
  </SingleValue>
);

MapStyleValue.propTypes = {
  data: PropTypes.object,
};

const MapStyleSelect = ({ styles, value, accessToken, onChange }) => {
  const options = styles.map(s => ({ value: s.url, label: s.name, id: s.id, owner: s.owner, accessToken }));
  const selectedOption = options.find(({ value: optionValue }) => value === optionValue);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: 0,
      borderColor: state.isFocused ? colors.bluePowder : colors.borderDark,
      boxShadow: state.isFocused ? '0 0 0 0.1rem rgba(103, 163, 203, 0.2)' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? colors.bluePowder : colors.borderDark,
      },
      height: 110,
      minHeight: 110,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '100%',
    }),
  };

  return (
    <Select
      styles={customStyles}
      options={options}
      onChange={onChange}
      value={selectedOption || null}
      components={{ Option: MapStyleOption, SingleValue: MapStyleValue }}
    />
  );
};

MapStyleSelect.propTypes = {
  styles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    owner: PropTypes.string,
    url: PropTypes.string,
  })),
  accessToken: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

MapStyleSelect.defaultProps = {
  onChange: () => null,
};

export default MapStyleSelect;
