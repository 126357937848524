import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

export default function usePortal (containerEl) {
  if (typeof containerEl === 'undefined') containerEl = document.body;

  const portalEl = useRef(document.createElement('div'));

  useEffect(() => {
    if (containerEl) {
      containerEl.appendChild(portalEl.current);
      return () => containerEl.removeChild(portalEl.current);
    }
  }, [containerEl]);

  const renderPortal = content => createPortal(content, portalEl.current);

  return renderPortal;
}
