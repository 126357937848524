import React, { Component } from 'react';
import PropTypes from 'prop-types';
import parseDate from 'date-fns/parse';
import { Calendar } from 'react-date-range';
import { filterParamDateFormat, colors } from 'app-constants';
import { formatters } from 'utils';
import Icon from 'components/common/Icon';


class DateRangeFilter extends Component {
  constructor (props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      visibleCalendar: null,
    };
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleInputFocus = this.handleInputFocus.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const [activeStart, activeEnd] = nextProps.activeValue;
    return {
      startDate: activeStart ? parseDate(activeStart, filterParamDateFormat, new Date()) : null,
      endDate: activeEnd ? parseDate(activeEnd, filterParamDateFormat, new Date()) : null,
    };
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleChangeStartDate (startDate) {
    const { params, onChange } = this.props;
    onChange(params.lowerBound, formatters.filterParamDate(startDate));
    this.setState({ visibleCalendar: null });
  }

  handleChangeEndDate (endDate) {
    const { params, onChange } = this.props;
    onChange(params.upperBound, formatters.filterParamDate(endDate));
    this.setState({ visibleCalendar: null });
  }

  handleInputFocus (e) {
    this.setState({ visibleCalendar: e.target.name });
  }

  handleClickOutside (e) {
    if (this.containerRef && !this.containerRef.contains(e.target)) {
      this.setState({ visibleCalendar: null });
    }
  }

  render () {
    const { startDate, endDate, visibleCalendar } = this.state;

    return (
      <div className="date-range-filter" ref={node => this.containerRef = node}>
        <div className="date-range-filter-field">
          <input
            type="text"
            name="startDate"
            className={startDate ? 'has-value' : null}
            onFocus={this.handleInputFocus}
            value={formatters.shortDate(startDate)}
            placeholder="MM/DD/YYYY"
            readOnly
          />
          {startDate ? (
            <a href="#clear" className="clear" onClick={e => { e.preventDefault(); this.handleChangeStartDate(null); }}>
              <Icon name="close" size={20} />
            </a>
          ) : null}
        </div>
        <span className={`separator ${startDate || endDate ? 'active' : ''}`}>–</span>
        <div className="date-range-filter-field">
          <input
            type="text"
            name="endDate"
            className={endDate ? 'has-value' : null}
            onFocus={this.handleInputFocus}
            value={formatters.shortDate(endDate)}
            placeholder="MM/DD/YYYY"
            readOnly
          />
          {endDate ? (
            <a href="#clear" className="clear" onClick={e => { e.preventDefault(); this.handleChangeEndDate(null); }}>
              <Icon name="close" size={20} />
            </a>
          ) : null}
        </div>
        <div className="date-range-filter-calendar">
          {visibleCalendar === 'startDate' ? (
            <Calendar
              className="d-flex dark"
              color={colors.bluePowder}
              date={startDate}
              onChange={this.handleChangeStartDate}
              // The maxDate prop must only be present when we have an endDate.
              // Setting it to null doesn't work.
              {...(endDate ? { maxDate: endDate } : {})}
            />
          ) : null}
          {visibleCalendar === 'endDate' ? (
            <Calendar
              className="d-flex dark"
              color={colors.bluePowder}
              date={endDate}
              onChange={this.handleChangeEndDate}
              {...(startDate ? { minDate: startDate } : {})}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

DateRangeFilter.propTypes = {
  params: PropTypes.shape({
    lowerBound: PropTypes.string,
    upperBound: PropTypes.string,
  }),
  activeValue: PropTypes.array,
  onChange: PropTypes.func,
};

DateRangeFilter.defaultProps = {
  activeValue: [],
};

export default DateRangeFilter;
