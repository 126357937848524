import React, { useState, createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const ItemDataCacheContext = createContext();

export const ItemDataCacheProvider = ({ children }) => {
  const [contextVal, setContextVal] = useState({});
  const updateItemDataCache = val => setContextVal(prevState => ({ ...prevState, ...val }));
  const value = {
    itemDataCache: contextVal,
    setItemDataCache: setContextVal,
    updateItemDataCache,
  };
  return (
    <ItemDataCacheContext.Provider value={value}>
      {children}
    </ItemDataCacheContext.Provider>
  );
};

ItemDataCacheProvider.propTypes = {
  children: PropTypes.any,
};

export function useItemDataCache () {
  const context = useContext(ItemDataCacheContext);
  if (typeof context === 'undefined') {
    return {
      itemDataCache: {},
      setItemDataCache: () => null,
      updateItemDataCache: () => null,
    };
  }
  return context;
}

export function withItemDataCache (WrappedComponent) {
  const Component = props => (
    <ItemDataCacheProvider>
      <WrappedComponent {...props} />
    </ItemDataCacheProvider>
  );

  Component.displayName = `withItemDataCache(${WrappedComponent.displayName || WrappedComponent.name})`;
  return Component;
}
