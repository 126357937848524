import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCSRF } from 'hooks';
import Modal from './Modal';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';

const ObjectDelete = ({ formUrl, multiple = false, ids = [] }) => {
  const csrfToken = useCSRF();
  const [modalVisible, setModalVisible] = useState(false);

  const handleLinkClick = e => {
    e.preventDefault();
    setModalVisible(true);
  };

  const handleModalRequestClose = () => setModalVisible(false);

  const handleCancelClick = e => {
    e.preventDefault();
    handleModalRequestClose();
  };

  return (
    <ErrorBoundary>
      <a href="#delete" onClick={handleLinkClick}>
        <Icon name="delete" className="mr-2" />
        <span>Delete</span>
      </a>
      <Modal
        title="Are You Sure?"
        style={{ content: { width: 660 } }}
        isOpen={modalVisible}
        onRequestClose={handleModalRequestClose}
      >
        <form action={formUrl} method="post">
          {multiple
            ? <p>{ids.length} item{ids.length === 1 ? '' : 's'} will be deleted permanently.</p>
            : <p>This item will be deleted permanently.</p>}
          <div className="modal-action-buttons">
            <button className="btn" onClick={handleCancelClick}>Cancel</button>
            <button className="btn btn-error" type="submit">Delete</button>
          </div>
          <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
          {ids.map(id => <input key={id} type="hidden" name="ids" value={id} />)}
        </form>
      </Modal>
    </ErrorBoundary>
  );
};

ObjectDelete.propTypes = {
  formUrl: PropTypes.string,
  multiple: PropTypes.bool,
  ids: PropTypes.arrayOf(PropTypes.string),
};

export default ObjectDelete;
