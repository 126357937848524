
// Toggle main nav menu
const rootElId = 'layout-root';
const menuOpenClass = 'menu-open';
const navToggleClass = 'nav-menu-toggle';

const toggleMainNav = () => {
  const rootEl = document.getElementById(rootElId);
  rootEl && rootEl.classList.toggle(menuOpenClass);
};

const navToggle = document.querySelector(`.${navToggleClass}`);
navToggle && navToggle.addEventListener('click', () => toggleMainNav());
