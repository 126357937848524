import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import LibraryMediaField from 'components/common/LibraryMediaField';

const SocialImage = ({ field, className, disabled }) => {
  const { helpText, errors } = field;

  return (
    <div className={classNames('form-group', className, errors.length > 0 && 'has-error')}>
      <LibraryMediaField
        inputName={field.name}
        buttonText="Choose Image"
        value={field.value}
        limitTypes={['image']}
        disabled={disabled}
      />
      {helpText && !errors.length && <p className="form-input-hint mb-0">{helpText}</p>}
      {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
    </div>
  );
};

SocialImage.propTypes = {
  field: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default SocialImage;
