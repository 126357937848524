import React, { useState, useEffect, useRef, useCallback } from 'react';
import { v4 as uuid4 } from 'uuid';
import camelize from 'camelize';
import debounce from 'lodash/debounce';
import Message from 'components/common/Message';
import SortableItem from 'components/common/SortableItem';
import { ManagementForm } from 'components/common/inlines';

export default function useTitleBuilder ({
  formsetData,
  itemLabel = 'item',
  itemLabelPlural = 'items',
  hasError = false,
  addFromMedia = false,
  newItemsFirst = true,
  isSortable = true,
  useCustomDragHandle = false,
  sortableItemType = 'title-builder-items',
  sortableItemClassName,
  ItemComponent,
  extraItemProps = {},
  itemFormDefaults,
  itemMediaFieldName = 'media',
  onItemClick,
}) {
  const nextItemIndex = useRef(0);
  const [itemsById, setItemsById] = useState({});
  const [itemsOrdered, setItemsOrdered] = useState([]);
  const [activeItemId, setActiveItemId] = useState(null);
  const [messageKey, setMessageKey] = useState(null);
  const [messageText, setMessageText] = useState('');

  const activeItem = itemsById[activeItemId];

  const itemsMarkedForDeletion = itemsOrdered.filter(uid => itemsById[uid].shouldDelete);

  useEffect(() => initItems(), []);

  useEffect(() => {
    if (activeItem && activeItem.shouldDelete) {
      setActiveItemId(null);
    }
  }, [activeItem]);

  const handleItemAdd = addFromMedia
    ? (mediaIds, attrs = {}) => {
      const newItems = mediaIds.map(id => ({ ...itemFormDefaults, ...attrs, [itemMediaFieldName]: id }));
      initNewItems(newItems);

      const count = mediaIds.length;
      setMessageKey(uuid4());
      setMessageText(count === 1 ? `1 ${itemLabel} was added.` : `${count} ${itemLabelPlural} were added.`);
    }
    : (attrs = {}) => initNewItems([{ ...itemFormDefaults, ...attrs }]);

  const handleItemClick = itemId => {
    const item = itemsById[itemId];
    if (item && item.shouldDelete) {
      return;
    }
    (onItemClick || setActiveItemId)(itemId);
  };

  const handleItemDeleteClick = (itemId, bool) => setItemsById(oldState => {
    const item = oldState[itemId];
    if (typeof bool === 'undefined') bool = !item.shouldDelete;
    return {
      ...oldState,
      [itemId]: { ...item, shouldDelete: bool },
    };
  });

  const toggleDelete = handleItemDeleteClick;

  const handleFormChange = itemObj => {
    setItemsById({
      ...itemsById,
      [activeItemId]: { ...activeItem, ...itemObj },
    });
  };

  const handleBulkChange = (ids, fields) => setItemsById(oldState => ({
    ...oldState,
    ...ids.reduce((result, id) => {
      result[id] = {
        ...oldState[id],
        ...fields,
      };
      return result;
    }, {}),
  }));

  let handleItemMove = (sourceId, targetId, insertPos) => {
    if (!isSortable) return null;

    const toIndex = insertPos === 'before' ? itemsOrdered.indexOf(targetId) : itemsOrdered.indexOf(targetId) + 1;
    const newValue = itemsOrdered.filter(uid => uid !== sourceId);
    newValue.splice(toIndex, 0, sourceId);
    setItemsOrdered(newValue);
  };

  handleItemMove = useCallback(debounce(handleItemMove, 100), [itemsOrdered, isSortable]);

  const initNewItems = (itemObjects, prepend = newItemsFirst) => {
    const { prefix } = formsetData;
    const newItems = {};
    const newItemIds = [];

    itemObjects.forEach(itemObj => {
      const itemId = uuid4();
      const item = {
        itemId,
        itemIndex: nextItemIndex.current,
        prefix: `${prefix}-${nextItemIndex.current}`,
        errors: {},
        ...itemObj,
      };
      newItems[itemId] = item;
      newItemIds.push(itemId);
      nextItemIndex.current += 1;
    });

    setItemsById({ ...itemsById, ...newItems });
    setItemsOrdered(prepend ? [...newItemIds, ...itemsOrdered] : [...itemsOrdered, ...newItemIds]);
    setActiveItemId(newItemIds[0]);

    return newItems;
  };

  const initNewItem = (itemObject, prepend = newItemsFirst) => {
    const [result] = Object.values(initNewItems([itemObject], prepend));
    return result;
  };

  const initItems = () => {
    // Hydrate item list from initial formset data
    const { forms, prefix } = camelize(formsetData);
    const itemsById = {};
    const itemsOrdered = [];
    forms.forEach(form => {
      let itemId = uuid4();
      if (form.fields.uuid) {
        itemId = form.isBound ? form.fields.uuid.value : form.fields.uuid.initial;
      }
      const fields = Object.keys(form.fields).reduce((result, key) => {
        if (key === 'DELETE') {
          result.shouldDelete = !!form.fields[key].value;
        } else {
          result[key] = form.fields[key].value;
        }
        return result;
      }, {});

      const item = {
        itemId,
        itemIndex: nextItemIndex.current,
        prefix: `${prefix}-${nextItemIndex.current}`,
        errors: form.errors,
        ...fields,
      };

      itemsById[itemId] = item;
      itemsOrdered.push(itemId);
      nextItemIndex.current += 1;
    });

    setItemsById(itemsById);
    setItemsOrdered(itemsOrdered);
    setActiveItemId(itemsOrdered[0]);
  };

  const { prefix, managementForm: mgf } = camelize(formsetData);
  const managementForm = (
    <ManagementForm
      prefix={prefix}
      formData={mgf}
      totalForms={itemsOrdered.length}
    />
  );

  const renderedItems = itemsOrdered.map((itemId, idx) => {
    const item = itemsById[itemId];
    const itemHasError = Object.keys(item.errors).length > 0;
    const extraProps = typeof extraItemProps === 'function' ? extraItemProps(item) : extraItemProps;
    const itemProps = {
      counter: idx + 1,
      active: activeItemId === itemId,
      error: itemHasError,
      onClick: () => handleItemClick(itemId),
      onDeleteClick: () => handleItemDeleteClick(itemId),
      ...item,
      ...extraProps,
    };

    return isSortable ? (
      <SortableItem
        key={itemId}
        itemId={itemId}
        className={sortableItemClassName}
        type={sortableItemType}
        onItemMove={handleItemMove}
      >
        {useCustomDragHandle
          ? (connectDragSource) => <ItemComponent key={itemId} connectDragSource={connectDragSource} {...itemProps} />
          : <ItemComponent key={itemId} {...itemProps} />}
      </SortableItem>
    ) : <ItemComponent key={itemId} {...itemProps} />;
  });

  const messages = (
    <>
      {hasError && <Message type="error" text="Some items contain errors. Correct the errors below before saving." />}
      {messageKey && <Message key={messageKey} type="info" timeout={5} text={messageText} />}
    </>
  );

  const emptyMsgText = itemsOrdered.length
    ? `No ${itemLabel} selected. Choose one from the list at left, or…`
    : `No ${itemLabelPlural} yet! Add some to get started.`;

  const renderEmptyMessage = (clickHandler = handleItemAdd) => (
    <div className="title-builder-empty-message-container">
      <p>{emptyMsgText}</p>
      <button className="btn" type="button" style={{ textTransform: 'capitalize' }} onClick={clickHandler}>
        Add New {addFromMedia ? itemLabelPlural : itemLabel}
      </button>
    </div>
  );

  return {
    itemsById,
    setItemsById,
    itemsOrdered,
    setItemsOrdered,
    itemsMarkedForDeletion,
    toggleDelete,
    activeItem,
    setActiveItemId,
    handleItemAdd,
    handleFormChange,
    handleBulkChange,
    initNewItems,
    initNewItem,
    managementForm,
    messages,
    renderEmptyMessage,
    renderedItems,
  };
}
