import React from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import ReleaseItemsChooser from 'components/common/ReleaseItemsChooser';

const BlockDisplayOptions = ({ releaseItems, conditionalDisplay, conditionalDisplayItems, onChange }) => {
  const handleConditionalItemsChange = val => onChange({ conditionalDisplayItems: val });
  const handleConditionalDisplayChange = evt => onChange({ conditionalDisplay: evt.target.value === 'limit' });

  return (
    <div className="block-display-options content">
      <div className="form-group">
        <label className="block-label">Display this block for:</label>
        <label className="form-radio m-0">
          <input
            type="radio"
            value=""
            checked={!conditionalDisplay}
            onChange={handleConditionalDisplayChange}
          />
          <i className="form-icon" /> All content in this release
        </label>
        <label className="form-radio m-0">
          <input
            type="radio"
            value="limit"
            checked={conditionalDisplay}
            onChange={handleConditionalDisplayChange}
          />
          <i className="form-icon" /> Specific content only
        </label>
        <AnimateHeight duration={500} height={conditionalDisplay ? 'auto' : 0}>
          <div className="mt-2">
            <ReleaseItemsChooser
              releaseItems={releaseItems}
              selectedIds={conditionalDisplayItems}
              onChange={handleConditionalItemsChange}
            />
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};

BlockDisplayOptions.propTypes = {
  releaseItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    objectId: PropTypes.string,
    objectType: PropTypes.string,
    objectName: PropTypes.string,
    objectIconName: PropTypes.string,
  })),
  conditionalDisplay: PropTypes.bool,
  conditionalDisplayItems: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default BlockDisplayOptions;
