import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import striptags from 'striptags';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { formatters, capFirst } from 'utils';
import { ErrorList } from 'components/common/inlines';
import QuillEditor from 'components/common/QuillEditor';
import AddLibraryMedia from 'components/common/AddLibraryMedia';
import AddTitleAttachment from 'components/common/AddTitleAttachment';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import Icon from 'components/common/Icon';
import MediaRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/MediaRelationField';
import Select from 'components/common/Select';
import formDefaults from './formDefaults';


class Form extends Component {
  constructor (props) {
    super(props);
    this.defaultState = {
      itemId: null,
      fields: { ...formDefaults },
      itemData: null,
      processing: false,
      error: null,
    };
    this.state = {
      ...this.defaultState,
    };

    this.fieldPrefix = 'coverBuilderForm-';
    this.numericFields = [];
    this.requiredFields = [];

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormChange = debounce(this.handleFormChange, 500);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLinkTypeChange = this.handleLinkTypeChange.bind(this);
    this.handleLinkTargetSelect = this.handleLinkTargetSelect.bind(this);
    this.initFormValues = this.initFormValues.bind(this);
    this.getItemObject = this.getItemObject.bind(this);
    this.errorClass = this.errorClass.bind(this);
  }

  componentDidMount () {
    this.initFormValues();

    const { linkType } = this.props.item;
    const itemId = this.props.item[linkType];
    if (itemId) {
      this.fetchData(linkType, itemId);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { item } = this.props;
    const prevItem = prevProps.item;

    if (!isEqual(item, prevItem)) {
      this.initFormValues();
    }

    if (
      item.linkType !== prevItem.linkType ||
      (item.linkType === 'media' && item.media !== prevItem.media) ||
      (item.linkType === 'title' && item.title !== prevItem.title)
    ) {
      const itemId = item[item.linkType];
      if (!itemId) {
        this.setState({ itemData: null, processing: false, error: null });
      } else {
        this.fetchData(item.linkType, itemId);
      }
    }

    if (
      (item && !prevItem) || (!item && prevItem) ||
      (item && prevItem && item.itemId !== prevItem.itemId)
    ) {
      // Reset form scroll position when a different item is selected
      if (this.containerRef) {
        this.containerRef.scrollTop = 0;
      }
    }
  }

  componentWillUnmount () {
    this.handleFormChange.flush();
  }

  fetchData (type, id) {
    if (!id) {
      return null;
    }
    const baseUrl = urls[`${type}DataBase`];
    const url = urlJoin(baseUrl, id, '?format=json');
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({ itemData: data, processing: false });
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  handleFormChange () {
    const { onChange, item } = this.props;
    const itemObj = this.getItemObject();

    if (item) {
      // Only call this if we are editing an existing item, not a blank one.
      onChange({ ...itemObj });
    }
  }

  handleFieldChange (fieldName, value) {
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [fieldName]: value,
      },
    }), this.handleFormChange);
  }

  handleInputChange (e) {
    let { name, value, type, checked } = e.target;
    name = name.replace(this.fieldPrefix, '');
    if (value && this.numericFields.includes(name)) {
      value = parseFloat(value);
    } else if (type === 'checkbox') {
      value = checked;
    } else if (name === 'slug') {
      value = formatters.slug(value);
    }

    this.handleFieldChange(name, value);
  }

  handleLinkTypeChange (e) {
    const isExternal = e.target.value === 'external';
    this.handleFieldChange('external', isExternal);
    if (isExternal) {
      this.setState(prevState => ({
        fields: {
          ...prevState.fields,
          nameSource: 'custom',
          descriptionSource: 'custom',
          media: null,
          title: null,
        },
      }), this.handleFormChange);
    }
  }

  handleLinkTargetSelect (type, id) {
    const { fields } = this.state;
    const newFields = { linkType: type };
    if (type === 'media') {
      newFields.media = id;
      newFields.title = null;
    } else {
      newFields.title = id;
      newFields.media = null;
    }

    this.setState({
      fields: {
        ...fields,
        ...newFields,
      },
    }, this.handleFormChange);
  }

  getItemObject () {
    // Convert empty strings to null
    return Object.keys(this.state.fields).reduce((result, key) => {
      result[key] = this.state.fields[key] === '' ? null : this.state.fields[key];
      return result;
    }, {});
  }

  initFormValues () {
    const { item } = this.props;

    if (item) {
      const newFields = Object.keys(item).reduce((result, key) => {
        if (this.defaultState.fields.hasOwnProperty(key)) {
          // Keep explicit false for checkbox fields, but convert undefined or null to empty strings
          result[key] = (item[key] || item[key] === false) ? item[key] : '';
        }
        return result;
      }, {});
      this.setState({ fields: newFields, itemId: item.itemId });
    } else {
      this.setState({ fields: this.defaultState.fields, itemId: null });
    }
  }

  errorClass (fieldName) {
    const { item } = this.props;
    return item && item.errors[fieldName] ? 'has-error' : '';
  }

  renderNameInput () {
    const { itemData } = this.state;
    const { name, nameSource } = this.state.fields;

    switch (nameSource) {
      case 'item_name':
        return <input key="nameSource1" type="text" className="form-input" value={itemData ? itemData.name : ''} disabled />;
      case 'custom':
        return (
          <input
            key="nameSource2"
            name={this.fieldPrefix + 'name'}
            type="text"
            className="form-input"
            value={name}
            onChange={this.handleInputChange}
          />
        );
      default:
        return <input key="nameSource3" type="text" className="form-input" disabled />;
    }
  }

  renderDescriptionInput () {
    const { itemData } = this.state;
    const { description, descriptionSource } = this.state.fields;

    switch (descriptionSource) {
      case 'item_long_desc':
        return (
          <textarea
            key="descriptionSource1"
            cols="30"
            rows="3"
            className="form-input"
            value={itemData ? striptags(itemData.longDescription) : ''}
            disabled
          />
        );
      case 'item_short_desc':
        return (
          <textarea
            key="descriptionSource2"
            cols="30"
            rows="3"
            className="form-input"
            value={itemData ? striptags(itemData.shortDescription) : ''}
            disabled
          />
        );
      case 'custom':
        return (
          <QuillEditor
            inputName={this.fieldPrefix + 'description'}
            value={description}
            onChange={val => this.handleFieldChange('description', val)}
            size="small"
          />
        );
      default:
        return <textarea key="descriptionSource3" cols="30" rows="3" className="form-input" disabled />;
    }
  }

  renderLinkTargetField () {
    const { linkType } = this.state.fields;
    const itemId = this.state.fields[linkType];

    if (!itemId) {
      return (
        <div className="attached-item-preview justify-content-center">
          <AddLibraryMedia onChange={id => this.handleLinkTargetSelect('media', id)}>
            {handleModalTrigger => (
              <button type="button" className="btn btn-primary" onClick={handleModalTrigger}>Select Media</button>
            )}
          </AddLibraryMedia>
          <span style={{ margin: '0 15px' }}>– or –</span>
          <AddTitleAttachment onChange={id => this.handleLinkTargetSelect('title', id)}>
            {handleModalTrigger => (
              <button type="button" className="btn btn-primary" onClick={handleModalTrigger}>Select Title</button>
            )}
          </AddTitleAttachment>
        </div>
      );
    }

    return (
      <AttachedItemPreview itemType={linkType} itemId={itemId} clickableThumbnail>
        <div
          className="btn-delete tooltip tooltip-left"
          data-tooltip={`Remove ${capFirst(linkType)}`}
          onClick={() => this.handleLinkTargetSelect(linkType, null)}
        >
          <Icon name="delete" fill />
        </div>
      </AttachedItemPreview>
    );
  }

  render () {
    const { item, index } = this.props;
    const { external, linkUrl, label, nameSource, descriptionSource, backgroundImage, backgroundVideo } = this.state.fields;

    if (!item) {
      return null;
    }
    const hasErrors = Object.keys(item.errors).length > 0;

    const nameSourceOptions = [
      { value: 'item_name', label: 'Use item name' },
      { value: 'custom', label: 'Use custom name' },
    ];
    const nameSourceValue = nameSourceOptions.find(opt => opt.value === nameSource);

    const descriptionSourceOptions = [
      { value: 'item_long_desc', label: 'Use item long description' },
      { value: 'item_short_desc', label: 'Use item short description' },
      { value: 'custom', label: 'Use custom description' },
    ];
    const descriptionSourceValue = descriptionSourceOptions.find(opt => opt.value === descriptionSource);

    return (
      <div className="title-builder-form-container" ref={node => this.containerRef = node}>
        <header className="title-builder-form-header">
          <div>
            <h5><span>Editing:</span> Item {index}</h5>
          </div>
        </header>

        <div className="title-builder-form">
          {hasErrors ? <ErrorList className="mb-5" errors={item.errors} /> : null}

          <div className="form-group mb-5">
            <label className="block-label">Link Type</label>

            <label className="form-radio">
              <input
                type="radio"
                value=""
                checked={!external}
                onChange={this.handleLinkTypeChange}
              />
              <i className="form-icon" /> Media/Title <span className="text-hint ml-1">Link to a Media or Title object.</span>
            </label>

            <label className="form-radio">
              <input
                type="radio"
                value="external"
                checked={external}
                onChange={this.handleLinkTypeChange}
              />
              <i className="form-icon" /> <span>External URL</span> <span className="text-hint ml-1">Link to an external URL.</span>
            </label>
          </div>

          {external ? (
            <div className={`form-group mb-5 ${this.errorClass('linkUrl')}`}>
              <label className="block-label">Link URL</label>
              <p className="form-input-hint mb-1">Enter a URL for this item to link to.</p>
              <input
                name={this.fieldPrefix + 'linkUrl'}
                type="text"
                className="form-input"
                value={linkUrl || ''}
                onChange={this.handleInputChange}
              />
            </div>
          ) : (
            <div className={`form-group mb-5 ${this.errorClass('media')}`}>
              <label className="block-label">Linked item</label>
              <p className="form-input-hint m-0">Select a Media or Title object for this Cover Item to link to.</p>
              {this.renderLinkTargetField()}
            </div>
          )}

          <div className={`form-group mb-5 ${this.errorClass('label')}`}>
            <label className="block-label">Label</label>
            <p className="form-input-hint mb-1">Enter a short label. This text will appear on a button or in a menu, depending on the selected layout option.</p>
            <input
              name={this.fieldPrefix + 'label'}
              type="text"
              className="form-input"
              value={label}
              onChange={this.handleInputChange}
            />
          </div>

          <div className={classNames('form-group', 'mb-3', this.errorClass('name'), this.errorClass('nameSource'))}>
            <label className="block-label">Name</label>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
                <Select
                  value={nameSourceValue}
                  options={nameSourceOptions}
                  isDisabled={external}
                  onChange={val => this.handleFieldChange('nameSource', val)}
                />
              </div>
              <div style={{ flex: 1, minWidth: 300 }}>
                {this.renderNameInput()}
              </div>
            </div>
          </div>

          <div className={classNames('form-group', 'mb-3', this.errorClass('description'), this.errorClass('descriptionSource'))}>
            <label className="block-label">Description</label>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
                <Select
                  value={descriptionSourceValue}
                  options={descriptionSourceOptions}
                  isDisabled={external}
                  onChange={val => this.handleFieldChange('descriptionSource', val)}
                />
              </div>
              <div style={{ flex: 1, minWidth: 300 }}>
                {this.renderDescriptionInput()}
              </div>
            </div>
          </div>

          <div className={`form-group mb-3 ${this.errorClass('backgroundImage')}`}>
            <label className="block-label">Background image</label>
            <p className="form-input-hint m-0">Select a background image for this item. This image will be displayed on platforms that do not support background video. If no image is specified, the linked item’s poster frame will be displayed.</p>
            <MediaRelationField
              limitTypes={['image']}
              value={backgroundImage}
              onChange={val => this.handleFieldChange('backgroundImage', val)}
            />
          </div>

          <div className={`form-group mb-3 ${this.errorClass('backgroundVideo')}`}>
            <label className="block-label">Background video</label>
            <p className="form-input-hint m-0">Select a background video for this item.</p>
            <MediaRelationField
              limitTypes={['video']}
              value={backgroundVideo}
              onChange={val => this.handleFieldChange('backgroundVideo', val)}
            />
          </div>

          <hr className="mt-0 mb-3" />
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  item: PropTypes.object,
  index: PropTypes.number,
  onChange: PropTypes.func,
};

export default Form;
