import React, { useState, useEffect } from 'react';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import DjangoFormField from 'components/common/DjangoFormField';
import LoadingSpinner from 'components/common/LoadingSpinner';

export default function useMetadataPreset (formFields) {
  const [presetId, setPresetId] = useState(null);
  const [presetData, setPresetData] = useState(null);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    setPresetId(formFields.metadataPreset.value);
  }, [formFields.metadataPreset.value]);

  useEffect(() => {
    if (presetId) {
      fetchData();
    } else {
      setError(null);
      setPresetData(null);
      setProcessing(false);
      setOverrideFields([]);
    }
  }, [presetId]);

  const fetchData = () => {
    const baseUrl = urls.metadataPresetDataBase;
    const url = urlJoin(baseUrl, presetId);

    setError(null);
    setProcessing(true);

    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        setPresetData(data);
        setProcessing(false);
      })
      .catch(err => {
        setError(err.message);
        setProcessing(false);
        console.error(err);
      });
  };

  const [overrideFields, setOverrideFields] = useState([]);

  useEffect(() => {
    setOverrideFields(JSON.parse(formFields.metadataPresetOverrideFields.value));
  }, [formFields.metadataPresetOverrideFields.value]);

  const renderOverrideToggle = fieldNames => {
    if (!presetId) return null;
    if (typeof fieldNames === 'string') {
      fieldNames = [fieldNames];
    }

    const handleChange = evt => {
      let val = overrideFields.filter(f => !fieldNames.includes(f));
      if (!evt.target.checked) val = [...val, ...fieldNames];
      setOverrideFields(val);
    };

    const isChecked = !fieldNames.some(f => overrideFields.includes(f));

    return (
      <label className="form-switch" style={{ margin: 0, padding: '.1rem 2rem .1rem 0' }}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
        />
        <i className="form-icon" style={{ left: 'unset', right: 0 }} />
        <span style={{ whiteSpace: 'nowrap' }}>Use preset</span>
      </label>
    );
  };

  const isOverrideActive = fieldName => overrideFields.includes(fieldName);
  const isFieldDisabled = field => !!presetId && !isOverrideActive(field.name);

  const metadataPresetField = (
    <div className="d-flex align-items-center mb-2">
      <h5 className="m-0">Metadata preset</h5>
      <DjangoFormField
        {...formFields.metadataPreset}
        label=""
        className="m-0 mx-3"
        style={{ flex: '0 0 200px' }}
        value={presetId}
        onChange={evt => setPresetId(evt.target.value || null)}
      />
      {processing && <LoadingSpinner size={25} />}
      <div style={{ flex: 1 }} />
      <a className="text-sm" href={urls.metadataPresetManage} target="_blank" rel="noopener noreferrer">manage presets</a>
      <input type="hidden" name={formFields.metadataPresetOverrideFields.name} value={JSON.stringify(overrideFields)} />
    </div>
  );

  const fields = presetId ? Object.keys(formFields).reduce((result, name) => {
    if (!overrideFields.includes(formFields[name].name)) {
      if (!presetData) {
        result[name] = {
          ...formFields[name],
          value: '',
          errors: [],
        };
      } else if (presetData.hasOwnProperty(name)) {
        result[name] = {
          ...formFields[name],
          value: presetData[name],
          errors: [],
        };
      }
    }
    return result;
  }, { ...formFields }) : formFields;

  const formsets = {
    credits: presetData && presetData.credits,
    relatedLinks: presetData && presetData.relatedLinks,
  };

  const transformFormsetData = (origFormset, presetFormset) => ({
    ...origFormset,
    prefix: `readonly_${origFormset.prefix}`,
    non_form_errors: [],
    forms: (presetFormset || []).map((item, idx) => ({
      errors: {},
      is_bound: false,
      is_valid: false,
      prefix: `readonly_${origFormset.prefix}_${idx}`,
      fields: Object.entries(item).reduce((result, [key, value]) => {
        result[key] = { value };
        return result;
      }, {}),
    })),
  });

  return {
    presetId,
    presetData,
    error,
    processing,
    metadataPresetField,
    renderOverrideToggle,
    isOverrideActive,
    isFieldDisabled,
    fields,
    formsets,
    transformFormsetData,
  };
}
