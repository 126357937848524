import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';
import playlistItemWrapper from './playlistItemWrapper';

const Label = ({ renderNameInput, renderHiddenFields, renderDeleteOverlay, renderControlButtons }) => (
  <div>
    <header>
      <div className="inline-list-builder-icon">
        <Icon name="sell" fill size={22} />
      </div>
      {renderNameInput('Label text')}
    </header>

    {renderHiddenFields()}
    {renderDeleteOverlay()}
    {renderControlButtons()}
  </div>
);

Label.propTypes = {
  renderNameInput: PropTypes.func,
  renderHiddenFields: PropTypes.func,
  renderDeleteOverlay: PropTypes.func,
  renderControlButtons: PropTypes.func,
};

export default playlistItemWrapper(Label);
