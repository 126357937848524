import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import snakeize from 'snakeize';
import StructuredContentBuilder from 'components/views/StructuredContentBuilder';

const StructuredContentField = ({ field }) => {
  const [value, setValue] = useState(JSON.parse(field.value));

  const inputVal = JSON.stringify(snakeize(value));
  const handleChange = useCallback(value => setValue(value), []);

  let errors = {};
  if (field.errors && field.errors.length > 0) {
    // See apps/msp_structured_content/forms.py
    errors = JSON.parse(field.errors[0]);
  }

  return (
    <>
      <StructuredContentBuilder
        initialValue={value}
        errors={errors}
        allowSubItemSource={false}
        allowConditionalDisplay={false}
        onChange={handleChange}
      />
      <input type="hidden" name={field.name} value={inputVal} />
    </>
  );
};


StructuredContentField.propTypes = {
  field: PropTypes.object,
};

export default StructuredContentField;
