import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import { usePortal } from 'hooks';
import CreditsWidget, { ReadOnlyCreditsWidget } from 'components/common/CreditsWidget';
import DjangoFormField from 'components/common/DjangoFormField';
import RelatedLinksFormset from 'components/common/RelatedLinksFormset';
import SEOFields from 'components/common/SEOFields';
import { FieldHeader, PublishDates, Tags } from './fields';
import useMetadataPreset from './useMetadataPreset';

const TitleMetadataForm = ({
  form,
  creditsFormset,
  contributorChoices,
  relatedLinksFormset,
  objectUid,
}) => {
  const sidebarPortal = usePortal(document.querySelector('.general-sidebar-portal'));

  const { fields: origFields } = camelize(form);
  const {
    presetId,
    fields,
    formsets: presetFormsets,
    transformFormsetData,
    metadataPresetField,
    renderOverrideToggle,
    isOverrideActive,
    isFieldDisabled,
    processing,
  } = useMetadataPreset(origFields);

  const showPresetAdvCredits = !!presetId && !isOverrideActive('credits');
  const showPresetRelatedLinks = !!presetId && !isOverrideActive('related_links');
  const presetRelatedLinksFormset = transformFormsetData(relatedLinksFormset, presetFormsets.relatedLinks);

  const sidebarContent = (
    <>
      <PublishDates
        fields={fields}
        renderOverrideToggle={renderOverrideToggle}
        isFieldDisabled={isFieldDisabled}
      />

      <hr className="mb-6" />
      <div className="form-group mb-4">
        <FieldHeader field={fields.notes} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.notes}
          label=""
          helpText="Internal/administrative notes"
          disabled={isFieldDisabled(fields.notes)}
        />
      </div>
      <hr className="mb-6" />
      <Tags
        field={fields.tags}
        className="mb-6"
        renderOverrideToggle={renderOverrideToggle}
        isFieldDisabled={isFieldDisabled}
      />
    </>
  );

  return (
    <>
      {metadataPresetField}
      <hr className="mb-4" />

      <div className="form-group mb-6">
        <FieldHeader field={fields.shortDescription} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.shortDescription}
          allowFormatting
          label=""
          disabled={isFieldDisabled(fields.shortDescription)}
        />
      </div>

      <div className="form-group mb-6">
        <FieldHeader field={fields.longDescription} renderOverrideToggle={renderOverrideToggle} />
        <DjangoFormField
          {...fields.longDescription}
          allowFormatting
          label=""
          editorSize="large"
          disabled={isFieldDisabled(fields.longDescription)}
        />
      </div>

      <SEOFields
        titleField={fields.seoTitle}
        descriptionField={fields.seoDescription}
        renderOverrideToggle={renderOverrideToggle}
        isFieldDisabled={isFieldDisabled}
      />

      {!!creditsFormset && (
        <div className="form-group mb-6">
          <div className="flex-spread mb-2">
            <h5 className="m-0">Credits</h5>
            {renderOverrideToggle('credits')}
          </div>
          <div className={classNames({ 'd-none': showPresetAdvCredits })}>
            <CreditsWidget
              parentId={objectUid}
              parentFieldName="media"
              formsetData={creditsFormset}
              contributorChoices={contributorChoices}
            />
          </div>
          {showPresetAdvCredits && (
            <ReadOnlyCreditsWidget items={presetFormsets.credits || []} disabled />
          )}
        </div>
      )}

      <div className="form-group mb-6">
        <div className="flex-spread mb-2">
          <h5 className="m-0">Related Links</h5>
          {renderOverrideToggle('related_links')}
        </div>
        <div className={classNames({ 'd-none': showPresetRelatedLinks })}>
          <RelatedLinksFormset key="local" parentId={objectUid} formsetData={relatedLinksFormset} />
        </div>
        {showPresetRelatedLinks && (
          <RelatedLinksFormset key={`preset-${processing}`} parentId={objectUid} disabled formsetData={presetRelatedLinksFormset} />
        )}
      </div>

      {sidebarPortal(sidebarContent)}
    </>
  );
};

TitleMetadataForm.propTypes = {
  form: PropTypes.object,
  creditsFormset: PropTypes.object,
  contributorChoices: PropTypes.arrayOf(PropTypes.object),
  relatedLinksFormset: PropTypes.object,
  objectUid: PropTypes.string,
};

export default TitleMetadataForm;
