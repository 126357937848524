import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid4 } from 'uuid';
import formatDate from 'date-fns/format';
import { colors } from 'app-constants';
import { Calendar } from 'react-date-range';
import Icon from 'components/common/Icon';


class DateTimeWidget extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showCalendar: false,
    };

    this.fieldPrefix = uuid4() + '-';

    // Generate list of choices for month select input
    this.months = Array(12).fill().map((_, i) => [i + 1, formatDate(new Date(2000, i, 15), 'MMMM')]);
    this.numericFields = ['year', 'month', 'day'];

    this.handleCalendarClick = this.handleCalendarClick.bind(this);
    this.handleCalendarChange = this.handleCalendarChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNowClick = this.handleNowClick.bind(this);
  }

  handleCalendarClick (e) {
    const { showCalendar } = this.state;
    e.preventDefault();
    this.setState({ showCalendar: !showCalendar });
  }

  handleCalendarChange (date) {
    const { onChange } = this.props;
    this.setState({ showCalendar: false });
    onChange({
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate(),
    });
  }

  handleInputChange (e) {
    const { onChange } = this.props;
    let { name, value } = e.target;
    name = name.replace(this.fieldPrefix, '');
    if (value && this.numericFields.includes(name)) {
      value = parseInt(value, 10);
    }
    onChange({ [name]: value });
  }

  handleNowClick (e) {
    const { onChange } = this.props;
    e.preventDefault();
    const timeStr = formatDate(new Date(), 'HH:mm');
    onChange({ time: timeStr });
  }

  render () {
    const { year, month, day, time, yearError, monthError, dayError, timeError, showNowShortcut, disabled, calendarDefaultDate } = this.props;
    const { showCalendar } = this.state;

    return (
      <div className={classNames('datetime-widget', disabled && 'disabled')}>
        <div className="form-group mb-5 mr-3">
          <label className="block-label">&nbsp;</label>
          <a href="#choose-date" className="picker" style={{ textDecoration: 'none' }} onClick={disabled ? undefined : this.handleCalendarClick}>
            <Icon name="calendar_month" fill size={32} />
          </a>
        </div>

        <div className={classNames('form-group mb-5 mr-3', yearError && 'has-error')}>
          <label className="block-label">Year</label>
          <input
            name={`${this.fieldPrefix}year`}
            type="number"
            className="form-input"
            disabled={disabled}
            value={year || ''}
            onChange={this.handleInputChange}
          />
        </div>

        <div className={classNames('form-group mb-5 mr-3', monthError && 'has-error')}>
          <label className="block-label">Month</label>
          <select
            name={`${this.fieldPrefix}month`}
            className="form-select"
            value={month || ''}
            onChange={this.handleInputChange}
            disabled={disabled}
          >
            <option />
            {this.months.map(([value, label]) => (
              <option key={value} value={value}>{label}</option>
            ))}
          </select>
        </div>

        <div className={classNames('form-group mb-5 mr-3', dayError && 'has-error')}>
          <label className="block-label">Day</label>
          <input
            name={`${this.fieldPrefix}day`}
            type="number"
            disabled={disabled}
            min="1"
            max="31"
            className="form-input"
            value={day || ''}
            onChange={this.handleInputChange}
          />
        </div>

        <div className={classNames('form-group mb-5 mr-3', timeError && 'has-error')}>
          <div className="flex-spread">
            <label className="block-label">Time</label>
            {showNowShortcut && <a href="#now" onClick={this.handleNowClick} style={{ fontSize: '.7rem' }}>now</a>}
          </div>
          <input
            name={`${this.fieldPrefix}time`}
            type="time"
            disabled={disabled}
            className="form-input"
            placeholder="HH:MM"
            value={time || ''}
            onChange={this.handleInputChange}
          />
        </div>

        {showCalendar && (
          <div className="calendar-container">
            <Calendar
              className="d-flex"
              color={colors.bluePowder}
              date={calendarDefaultDate || new Date()}
              onChange={this.handleCalendarChange}
            />
          </div>
        )}
      </div>
    );
  }
}

DateTimeWidget.propTypes = {
  year: PropTypes.number,
  month: PropTypes.number,
  day: PropTypes.number,
  time: PropTypes.string,
  yearError: PropTypes.bool,
  monthError: PropTypes.bool,
  dayError: PropTypes.bool,
  timeError: PropTypes.bool,
  showNowShortcut: PropTypes.bool,
  disabled: PropTypes.bool,
  calendarDefaultDate: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
};

export default DateTimeWidget;
