// Render management form fields for inline formsets
import React from 'react';
import PropTypes from 'prop-types';


const ManagementForm = ({ formData, totalForms, prefix }) => (
  <>
    <input type="hidden" name={`${prefix}-TOTAL_FORMS`} value={totalForms} />
    <input type="hidden" name={`${prefix}-INITIAL_FORMS`} value={formData.initialForms} />
    <input type="hidden" name={`${prefix}-MIN_NUM_FORMS`} value={formData.minForms} />
    <input type="hidden" name={`${prefix}-MAX_NUM_FORMS`} value={formData.maxForms} />
  </>
);

ManagementForm.propTypes = {
  formData: PropTypes.shape({
    initialForms: PropTypes.number,
    maxForms: PropTypes.number,
    minForms: PropTypes.number,
  }),
  totalForms: PropTypes.number,
  prefix: PropTypes.string,
};

export default ManagementForm;
