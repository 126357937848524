import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import renderBlockErrors from './renderBlockErrors';
import Select from 'components/common/Select';
import FormGroup from './FormGroup';

const Button = ({ uid, errors, size, alignment, text, linkUrl, onPreviewTextUpdate, onChange }) => {
  const sizeOptions = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
  ];
  const sizeSelectVal = sizeOptions.find(opt => opt.value === size);
  const handleSizeChange = val => onChange({ size: val });
  const alignOptions = [
    { value: 'left', label: 'Left' },
    { value: 'center', label: 'Center' },
    { value: 'right', label: 'Right' },
  ];
  const alignSelectVal = alignOptions.find(opt => opt.value === alignment);
  const handleAlignChange = val => onChange({ alignment: val });
  const handleTextChange = e => onChange({ text: e.target.value });
  const handleLinkUrlChange = e => onChange({ linkUrl: e.target.value });

  useEffect(() => {
    onPreviewTextUpdate(text || '');
  }, [text]);

  const renderedErrors = renderBlockErrors(errors);

  return (
    <div className="content">
      <FormGroup className="mb-3" label="Size:">
        <Select
          value={sizeSelectVal}
          options={sizeOptions}
          onChange={handleSizeChange}
        />
        {renderedErrors.size}
      </FormGroup>

      <FormGroup className="mb-3" label="Alignment:">
        <Select
          value={alignSelectVal}
          options={alignOptions}
          onChange={handleAlignChange}
        />
        {renderedErrors.alignment}
      </FormGroup>

      <FormGroup className="mb-3" label="Text:">
        <input
          type="text"
          className="form-input"
          onChange={handleTextChange}
          value={text || ''}
        />
        {renderedErrors.text}
      </FormGroup>

      <FormGroup label="Link URL:">
        <input
          type="text"
          className="form-input"
          onChange={handleLinkUrlChange}
          value={linkUrl || ''}
        />
        {renderedErrors.linkUrl}
      </FormGroup>
    </div>
  );
};

Button.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  text: PropTypes.string,
  linkUrl: PropTypes.string,
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default Button;
