import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'app-constants';
import Icon from 'components/common/Icon';
import QuillEditor from 'components/common/QuillEditor';
import InlineListBuilder from 'components/common/inlines/InlineListBuilder';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import { ObjectListModal } from 'components/views/ObjectList';
import playlistItemWrapper from './playlistItemWrapper';
import PlaylistItem from './PlaylistItem';


class Group extends Component {
  constructor (props) {
    super(props);
    this.state = {
      fields: {
        name: props.fields.name || '',
        description: props.fields.description || '',
        groupMedia: props.fields.groupMedia || '',
      },
      mediaModalOpen: false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleChooseImageClick = this.handleChooseImageClick.bind(this);
    this.handleRemoveImageClick = this.handleRemoveImageClick.bind(this);
    this.handleMediaModalSelect = this.handleMediaModalSelect.bind(this);
    this.handleMediaModalRequestClose = this.handleMediaModalRequestClose.bind(this);
  }

  handleInputChange (e) {
    const { prefix } = this.props;
    const { fields } = this.state;
    const key = e.target.name.replace(`${prefix}-`, '');

    const newFields = {
      ...fields,
      [key]: e.target.type === 'file' ? e.target.files[0] : e.target.value,
    };
    this.setState({ fields: newFields });
  }

  handleDescriptionChange (value) {
    const { fields } = this.state;

    this.setState({
      fields: {
        ...fields,
        description: value,
      },
    });
  }

  handleChooseImageClick (e) {
    e.preventDefault();
    this.setState({ mediaModalOpen: true });
  }

  handleRemoveImageClick (e) {
    e.preventDefault();
    this.setState({
      fields: {
        ...this.state.fields,
        groupMedia: '',
      },
    });
  }

  handleMediaModalSelect (item) {
    this.setState({
      fields: {
        ...this.state.fields,
        groupMedia: item.uuid,
      },
    });
    this.handleMediaModalRequestClose();
  }

  handleMediaModalRequestClose () {
    this.setState({ mediaModalOpen: false });
  }

  renderSubItems () {
    const { parentId, editMode, mediaDataBaseUrl, subItems, activeItemId, onSettingsClick, onLoadPreview } = this.props;
    if (subItems.length > 0) {
      return subItems.map(({ itemId, ...rest }) => (
        <div key={itemId}>
          <PlaylistItem
            itemId={itemId}
            parentId={parentId}
            editMode={editMode}
            mediaDataBaseUrl={mediaDataBaseUrl}
            settingsActive={activeItemId === itemId}
            onSettingsClick={onSettingsClick}
            onLoadPreview={onLoadPreview}
            {...rest}
          />
        </div>
      ));
    } else if (editMode === 'insert') {
      // Don't display the empty message when in edit mode.
      return [];
    } else {
      return [<div key={0} style={{ textAlign: 'center', padding: '20px' }}><em>This group is empty.</em></div>];
    }
  }

  errorClass (fieldName) {
    const { errors } = this.props;
    return errors && errors[fieldName] ? 'has-error' : '';
  }

  renderErrorMessages (fieldName) {
    const { errors } = this.props;
    if (!errors || !errors[fieldName]) {
      return null;
    }
    return errors[fieldName].map(({ message }, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>);
  }

  renderForm () {
    const { prefix, editMode } = this.props;
    const { fields, mediaModalOpen } = this.state;

    return editMode === 'insert' ? (
      <div style={{ flex: 1 }}>
        <div className={`form-group mb-2 ${this.errorClass('name')}`}>
          <label htmlFor={`${prefix}-name`}>Name</label>
          <input type="text" className="form-input" name={`${prefix}-name`} value={fields.name} onChange={this.handleInputChange} />
          {this.renderErrorMessages('name') || <p className="form-input-hint mb-0">Enter a name for this Group.</p>}
        </div>

        <div className={`form-group mb-2 ${this.errorClass('groupMedia')}`}>
          <label>Image</label>
          <input type="hidden" name={`${prefix}-group_media`} value={fields.groupMedia} />
          {fields.groupMedia ? (
            <AttachedItemPreview itemType="media" itemId={fields.groupMedia} style={{ backgroundColor: colors.grayExtraLight }} clickableThumbnail>
              <div
                className="btn-delete tooltip tooltip-left"
                style={{ backgroundColor: 'transparent' }}
                data-tooltip="Remove Image"
                onClick={this.handleRemoveImageClick}
              >
                <Icon name="delete" fill />
              </div>
            </AttachedItemPreview>
          ) : (
            <div className="attached-item-preview" style={{ backgroundColor: colors.grayExtraLight, justifyContent: 'center' }}>
              <button className="btn btn-primary" onClick={this.handleChooseImageClick}>Choose Image</button>
            </div>
          )}
          {this.renderErrorMessages('groupMedia') || <p className="form-input-hint mb-0">Select an image to serve as this Group’s thumbnail. If left blank, the first child item’s thumbnail will be used instead.</p>}
        </div>

        <div className={`form-group m-0 ${this.errorClass('description')}`}>
          <label htmlFor={`${prefix}-description`}>Description</label>
          <QuillEditor inputName={`${prefix}-description`} value={fields.description} onChange={this.handleDescriptionChange} size="small" />
          {this.renderErrorMessages('description') || <p className="form-input-hint mb-0">Enter additional descriptive text for this Group (optional).</p>}
        </div>

        <ObjectListModal
          model="media"
          preFilter={{ type: 'image' }}
          disabledFilters={['type', 'duration', 'source']}
          isOpen={mediaModalOpen}
          allowMultipleSelection={false}
          onAccept={this.handleMediaModalSelect}
          onRequestClose={this.handleMediaModalRequestClose}
        />
      </div>
    ) : (
      <div>
        <h4 className="m-0">{fields.name}</h4>
        <input type="hidden" name={`${prefix}-name`} value={fields.name} />
        <input type="hidden" name={`${prefix}-description`} value={fields.description} />
        <input type="hidden" name={`${prefix}-group_media`} value={fields.groupMedia} />
      </div>
    );
  }

  render () {
    const {
      itemId,
      subItems,
      editMode,
      onSubItemMove,
      onAddMedia,
      renderHiddenFields,
      renderDeleteOverlay,
      renderControlButtons,
    } = this.props;
    const itemIds = subItems.length ? subItems.map(item => item.itemId) : [`group-${itemId}-placeholder`];
    const nodeTypes = [
      { name: 'media', callback: idx => onAddMedia(idx) },
    ];
    return (
      <div>
        <header>
          <div className="inline-list-builder-icon">
            <Icon name="stacks" fill size={22} />
          </div>
          {this.renderForm()}
        </header>

        <section className="mt-3">
          <InlineListBuilder
            itemIds={itemIds}
            editMode={editMode}
            nodeTypes={nodeTypes}
            onItemMove={onSubItemMove}
          >
            {this.renderSubItems()}
          </InlineListBuilder>
        </section>

        {renderHiddenFields('group')}
        {renderDeleteOverlay()}
        {renderControlButtons()}
      </div>
    );
  }
}

Group.propTypes = {
  itemId: PropTypes.string,
  parentId: PropTypes.string,
  editMode: PropTypes.string,
  itemIndex: PropTypes.number,
  fields: PropTypes.object,
  errors: PropTypes.object,
  prefix: PropTypes.string,
  subItems: PropTypes.arrayOf(PropTypes.object),
  onSubItemMove: PropTypes.func,
  mediaDataBaseUrl: PropTypes.string,
  activeItemId: PropTypes.string,
  onSettingsClick: PropTypes.func,
  onAddMedia: PropTypes.func,
  onLoadPreview: PropTypes.func,
  renderNameInput: PropTypes.func,
  renderHiddenFields: PropTypes.func,
  renderDeleteOverlay: PropTypes.func,
  renderControlButtons: PropTypes.func,
};

Group.defaultProps = {
  subItems: [],
};

export default playlistItemWrapper(Group);
