import React from 'react';
import camelize from 'camelize';


export default function renderBlockErrors (errors) {
  return Object.entries(camelize(errors)).reduce((result, [fieldName, errorMessages]) => {
    result[fieldName] = errorMessages.map((msg, idx) => (
      <div key={`${fieldName}-error-${idx}`} className="form-input-hint text-error">{msg}</div>
    ));
    return result;
  }, {});
}
