import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import Icon from 'components/common/Icon';
import { DeleteOverlay } from 'components/common/inlines';


const ImageGridItem = ({
  media,
  label,
  labelSource,
  counter,
  active,
  error,
  shouldDelete,
  connectDragSource,
  onClick,
  onDeleteClick,
  onMediaLoad,
}) => {
  const [attachedMedia, setAttachedMedia] = useState(null);

  useEffect(() => onMediaLoad(attachedMedia), [attachedMedia]);

  const handleDeleteClick = e => {
    e.stopPropagation();
    onDeleteClick();
  };

  const fetchData = mediaId => {
    if (!mediaId) return null;
    const url = urlJoin(urls.mediaDataBase, mediaId, '?format=json');
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          console.error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => setAttachedMedia(data))
      .catch(err => console.error(err));
  };
  useEffect(() => fetchData(media), [media]);

  const containerClasses = classNames({
    active,
    error,
    'title-builder-item': true,
    'to-delete': shouldDelete,
  });

  const labelText = {
    media_name: attachedMedia ? attachedMedia.name : '',
    custom: label,
    none: '',
  }[labelSource];

  return (
    <div className={containerClasses} onClick={onClick}>
      <div className="index-counter">{counter}</div>
      <div className="title-builder-item-thumbnail mr-4">
        {attachedMedia
          ? <img src={attachedMedia.thumbnailLg} alt="thumbnail" />
          : <Icon name="steppers" />}
      </div>

      <div className="title-builder-item-details">
        <div className="item-label">{labelText}</div>
      </div>

      {shouldDelete ? <DeleteOverlay onUndoClick={handleDeleteClick} /> : null}

      <span
        className="btn-delete tooltip tooltip-left"
        key={shouldDelete}
        onClick={handleDeleteClick}
        data-tooltip={shouldDelete ? 'Undo Remove' : 'Remove Item'}
      >
        {shouldDelete ? <Icon name="undo" /> : <Icon name="delete" fill />}
      </span>
      {connectDragSource(
        <span className="btn-move" title="Drag to sort">
          <Icon name="menu" />
        </span>,
      )}
    </div>
  );
};

ImageGridItem.propTypes = {
  media: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelSource: PropTypes.oneOf(['media_name', 'custom', 'none']),
  counter: PropTypes.number,
  active: PropTypes.bool,
  error: PropTypes.bool,
  shouldDelete: PropTypes.bool,
  connectDragSource: PropTypes.func,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  onMediaLoad: PropTypes.func,
};

export default ImageGridItem;
