import React from 'react';
import PropTypes from 'prop-types';

const FieldHeader = ({ field, label, renderOverrideToggle }) => {
  return (
    <div className="flex-spread mb-2">
      <h5 className="m-0">{label || field.label}</h5>
      {renderOverrideToggle(field.name)}
    </div>
  );
};

FieldHeader.propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  renderOverrideToggle: PropTypes.func,
};

export default FieldHeader;
