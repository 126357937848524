import React from 'react';
import PropTypes from 'prop-types';
import { CUSTOM_TITLE_FIELD_PROPTYPES } from './';
import FieldWrapper from './FieldWrapper';


const ChoiceField = props => {
  const { name, value, radio, choices, onChange } = props;
  const choicesArr = choices.map(c => typeof c === 'string' ? [c, c] : c);

  return (
    <FieldWrapper {...props}>
      {radio ? choicesArr.map(([c, label, hintText]) => (
        <label key={c} className="form-radio m-0">
          <input type="radio" name={name} value={c} checked={value === c} onChange={e => onChange(e.target.value)} />
          <i className="form-icon" /> {label} {hintText && <span className="form-input-hint">{hintText}</span>}
        </label>
      )) : (
        <select
          className="form-select"
          style={{ width: 250 }}
          name={name}
          value={value}
          onChange={e => onChange(e.target.value)}
        >
          {choicesArr.map(([c, label]) => <option key={c} value={c}>{label}</option>)}
        </select>
      )}
    </FieldWrapper>
  );
};

ChoiceField.propTypes = {
  ...CUSTOM_TITLE_FIELD_PROPTYPES,
  radio: PropTypes.bool,
};

ChoiceField.defaultProps = {
  onChange: () => null,
};

export default ChoiceField;
