import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';
import Select from 'components/common/Select';


const { Option, SingleValue } = components;
const iconStyles = {
  display: 'inline-block',
  width: 24,
  height: 24,
  backgroundPosition: '50% 50%',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  marginRight: 8,
};

const IconOption = (props) => {
  const { label, icon } = props.data;
  const style = {
    ...iconStyles,
    backgroundImage: icon ? `url('${icon}')` : null,
    borderRadius: icon ? 0 : '50%',
    backgroundColor: icon ? 'transparent' : '#dddddd',
  };

  return (
    <Option {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={style} />
        <span>{label}</span>
      </div>
    </Option>
  );
};

IconOption.propTypes = {
  data: PropTypes.object,
};

const IconValue = (props) => {
  const { label, icon } = props.data;
  const style = {
    ...iconStyles,
    backgroundImage: icon ? `url('${icon}')` : null,
    borderRadius: icon ? 0 : '50%',
    backgroundColor: icon ? 'transparent' : '#dddddd',
  };

  return (
    <SingleValue {...props}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={style} />
        <span>{label}</span>
      </div>
    </SingleValue>
  );
};

IconValue.propTypes = {
  data: PropTypes.object,
};

const MarkerSelect = ({ markerTypes, value, onChange }) => {
  const options = [
    { value: '', label: 'Default', icon: '' },
    ...markerTypes.map(m => ({ value: m.uid, label: m.name, icon: m.icon })),
  ];
  const selectedOptions = options.filter(({ value: optionValue }) => value === optionValue);
  return (
    <Select
      options={options}
      onChange={onChange}
      value={selectedOptions}
      components={{ Option: IconOption, SingleValue: IconValue }}
    />
  );
};

MarkerSelect.propTypes = {
  markerTypes: PropTypes.arrayOf(PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.string,
  })),
  value: PropTypes.string,
  onChange: PropTypes.func,
};

MarkerSelect.defaultProps = {
  onChange: () => null,
};

export default MarkerSelect;
