import React, { Component } from 'react';
import { CUSTOM_TITLE_FIELD_PROPTYPES } from './';
import { withResizeDetector } from 'react-resize-detector';
import { HotTable } from '@handsontable/react';


class TableField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      tableLoaded: false,
    };
    this.hotTableComponent = React.createRef();
    this.tableSettings = {
      minCols: 10,
      minRows: 20,
      colHeaders: true,
      rowHeaders: true,
      stretchH: 'all',
      manualColumnResize: true,
      manualRowResize: true,
      minSpareCols: 3,
      minSpareRows: 3,
    };

    this.handleTableInit = this.handleTableInit.bind(this);
    this.handleTableChange = this.handleTableChange.bind(this);
  }

  componentDidUpdate (prevProps, prevState) {
    const { tableLoaded } = this.state;
    if (tableLoaded && !prevState.tableLoaded) {
      this.loadData();
    }
  }

  handleTableInit () {
    this.setState({ tableLoaded: true });
  }

  handleTableChange () {
    const { onChange } = this.props;
    const { tableLoaded } = this.state;
    if (!tableLoaded) {
      return null;
    }

    const hot = this.hotTableComponent.current.hotInstance;
    const data = hot.getData();
    // Remove empty rows and columns
    const trimmedData = data.filter((row, idx) => !hot.isEmptyRow(idx)).map(row => {
      return row.filter((val, idx) => !hot.isEmptyCol(idx));
    });
    onChange(trimmedData);
  }

  loadData () {
    const { value } = this.props;
    if (value && value.length) {
      this.hotTableComponent.current.hotInstance.loadData(value);
    }
  }

  render () {
    const { name, label, helpText, errors } = this.props;
    /* eslint-disable react/jsx-handler-names */
    return (
      <div className="form-group mb-4">
        <label htmlFor={name} className="block-label">{label}</label>
        {helpText ? <p className="form-input-hint m-0">{helpText}</p> : null}
        <div className="mt-1" style={{ width: '100%', overflow: 'hidden', height: 400 }}>
          <HotTable
            ref={this.hotTableComponent}
            settings={this.tableSettings}
            afterInit={this.handleTableInit}
            afterChange={this.handleTableChange}
          />
        </div>
        {errors.map((message, idx) => <p key={idx} className="form-input-hint text-error mb-0">{message}</p>)}
      </div>
    );
    /* eslint-enable react/jsx-handler-names */
  }
}

TableField.propTypes = {
  ...CUSTOM_TITLE_FIELD_PROPTYPES,
};

TableField.defaultProps = {
  onChange: () => null,
  errors: [],
};

// We're not explicitly using any of the functionality provided by withResizeDetector,
// but the simple fact of adding it resolves an issue with the HotTable component not rendering
// properly if the page loads with a different tab active.
export default withResizeDetector(TableField);
