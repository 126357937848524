import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import renderBlockErrors from './renderBlockErrors';
import Select from 'components/common/Select';
import FormGroup from './FormGroup';


const Divicer = ({ uid, errors, dividerStyle, margin, onPreviewTextUpdate, onChange }) => {
  const styleOptions = [
    { value: 'hairline', label: 'Hairline' },
    { value: 'double', label: 'Double Line' },
    { value: 'whitespace', label: 'Whitespace' },
  ];
  const styleSelectVal = styleOptions.find(opt => opt.value === dividerStyle);
  const handleStyleChange = val => onChange({ dividerStyle: val });

  const marginOptions = [
    { value: 'small', label: 'Small' },
    { value: 'medium', label: 'Medium' },
    { value: 'large', label: 'Large' },
  ];
  const marginSelectVal = marginOptions.find(opt => opt.value === margin);
  const handleMarginChange = val => onChange({ margin: val });

  useEffect(() => {
    onPreviewTextUpdate(styleSelectVal.label);
  }, [styleSelectVal.label]);

  const renderedErrors = renderBlockErrors(errors);

  return (
    <div className="content">
      <FormGroup className="mb-3" label="Style:">
        <Select
          value={styleSelectVal}
          options={styleOptions}
          onChange={handleStyleChange}
        />
        {renderedErrors.dividerStyle}
      </FormGroup>

      <FormGroup className="mb-3" label="Margin:">
        <Select
          value={marginSelectVal}
          options={marginOptions}
          onChange={handleMarginChange}
        />
        {renderedErrors.margin}
      </FormGroup>
    </div>
  );
};

Divicer.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  dividerStyle: PropTypes.oneOf(['hairline', 'double', 'whitespace']),
  margin: PropTypes.oneOf(['small', 'medium', 'large']),
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default Divicer;
