import React from 'react';
import PropTypes from 'prop-types';


const BreakText = ({ text }) => <span style={{ wordBreak: 'break-all' }}>{text}</span>;

BreakText.propTypes = {
  text: PropTypes.string,
};

export default BreakText;
