import React from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import DjangoFormField from 'components/common/DjangoFormField';

const DjangoFieldWrapper = ({ field, className }) => <DjangoFormField className={className} {...camelize(field)} />;

DjangoFieldWrapper.propTypes = {
  field: PropTypes.object,
  className: PropTypes.string,
};

export default DjangoFieldWrapper;
