import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'app-constants';
import Icon from 'components/common/Icon';

const ContributorPreview = ({ name, organization, thumbnail, type, style = {} }) => {
  const iconName = {
    individual: 'person',
    organization: 'domain',
  }[type];

  return (
    <div style={{ display: 'flex', alignItems: 'center', ...style }}>
      <div style={{ border: `1px solid ${colors.grayLight}`, backgroundColor: colors.grayExtraLight }}>
        {thumbnail ? (
          <img src={thumbnail} width={60} height={60} style={{ display: 'block' }} />
        ) : (
          <div style={{ width: 60, height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Icon name={iconName} size={32} fill style={{ color: colors.grayMedium }} />
          </div>
        )}
      </div>
      <div style={{ paddingLeft: 10 }}>
        <div>
          <h6 style={{ margin: '0 0 5px' }}>
            {name ? (
              <>
                {name}
                {organization && <span style={{ fontWeight: 300, color: colors.grayMedium }}> &nbsp;|&nbsp; {organization}</span>}
              </>
            ) : organization}
          </h6>
        </div>
        <div style={{ fontSize: '14px', lineHeight: 1.1, color: colors.grayMedium }}>
          {type}
        </div>
      </div>
    </div>
  );
};

ContributorPreview.propTypes = {
  name: PropTypes.string,
  organization: PropTypes.string,
  thumbnail: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
};

export default ContributorPreview;
