import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { urls } from 'app-constants';
import urlJoin from 'url-join';
import LoadingOverlay from 'components/common/LoadingOverlay';


const Preview = ({ presetId, data, shouldLoad }) => {
  const [isLoading, setIsLoading] = useState(true);

  const [frameUrl, setFrameUrl] = useState('');
  const urlTimeout = useRef();
  useEffect(() => {
    clearTimeout(urlTimeout.current);
    setIsLoading(true);
    urlTimeout.current = setTimeout(() => {
      let url = urlJoin(urls.presetPreviewBase, presetId, '/');
      if (data) url += `?previewData=${encodeURIComponent(JSON.stringify(data))}`;
      setFrameUrl(url);
    }, 1500);

    return () => clearTimeout(urlTimeout.current);
  }, [data]);

  const frameLoadTimeout = useRef();
  const handleFrameLoad = e => {
    // Once the frame has loaded, allow some extra time for the loading *within*
    // the frame to happen before hiding the overlay animation.
    clearTimeout(frameLoadTimeout.current);
    frameLoadTimeout.current = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  useEffect(() => {
    return () => clearTimeout(frameLoadTimeout.current);
  }, []);

  return (
    <div className="template-presets-preview-container mb-4">
      <div className="template-presets-preview">
        {shouldLoad && (
          <iframe
            src={frameUrl}
            frameBorder="0"
            allow="autoplay; fullscreen"
            onLoad={handleFrameLoad}
            allowFullScreen
          />
        )}
      </div>
      <LoadingOverlay text="Loading preview…" show={isLoading} />
    </div>
  );
};

Preview.propTypes = {
  presetId: PropTypes.string,
  data: PropTypes.object,
  shouldLoad: PropTypes.bool,
};

export default Preview;
