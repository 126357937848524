import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import parseISO from 'date-fns/parseISO';
import urlJoin from 'url-join';
import camelize from 'camelize';
import queryString from 'query-string';
import { urls } from 'app-constants';
import { formatters } from 'utils';
import withCSRF from 'components/common/withCSRF';
import LoadingOverlay from 'components/common/LoadingOverlay';


class Podcast extends Component {
  constructor (props) {
    super(props);
    this.state = {
      processing: false,
      importResult: null,
      error: null,
      feedsFetching: true,
      feedsById: {},
      activeFeedId: null,
      feedEntriesFetching: false,
      feedEntries: [],
      visibleEntries: [],
      feedEntriesSelected: [],
      feedEntriesQuery: '',
      entryListMaxHeight: 500,
      rangeSelection: null,
      hideImported: false,
      abortController: null,
    };
    this.handleEntryImport = this.handleEntryImport.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleHideImportedClick = this.handleHideImportedClick.bind(this);
    this.handleToggleFeedEntry = this.handleToggleFeedEntry.bind(this);
    this.handleFeedEntryClick = this.handleFeedEntryClick.bind(this);
    this.handleFeedFieldChange = this.handleFeedFieldChange.bind(this);
    this.doImport = this.doImport.bind(this);
    this.fetchFeedEntries = this.fetchFeedEntries.bind(this);
    this.setEntryListHeight = this.setEntryListHeight.bind(this);
  }

  componentDidMount () {
    this.fetchFeeds();
  }

  componentDidUpdate (prevProps, prevState) {
    const { activeFeedId, feedsById, feedEntries, feedEntriesSelected, feedEntriesQuery, hideImported } = this.state;

    if (activeFeedId && (activeFeedId !== prevState.activeFeedId || !isEqual(feedsById, prevState.feedsById))) {
      this.setState({
        feedEntries: [],
        feedEntriesSelected: [],
        feedEntriesQuery: '',
      });
      this.fetchFeedEntries();
      this.setEntryListHeight();
      window.addEventListener('resize', this.setEntryListHeight);
    }

    if (
      feedEntries !== prevState.feedEntries ||
      feedEntriesQuery !== prevState.feedEntriesQuery ||
      hideImported !== prevState.hideImported
    ) {
      const visibleEntries = feedEntries.filter(item => {
        if (hideImported && item.imported) return false;
        if (feedEntriesQuery && !item.title.toLowerCase().includes(feedEntriesQuery.toLowerCase())) return false;
        return true;
      });

      this.setState({
        visibleEntries,
        feedEntriesSelected: feedEntriesSelected.filter(id => visibleEntries.map(({ id }) => id).includes(id)),
      });
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.setEntryListHeight);
  }

  handleEntryImport () {
    const { feedEntriesSelected } = this.state;
    if (feedEntriesSelected.length) {
      this.doImport(feedEntriesSelected);
    }
  }

  handleSearchInputChange (e) {
    this.setState({ feedEntriesQuery: e.target.value });
  }

  handleHideImportedClick (e) {
    this.setState(oldState => ({ hideImported: !oldState.hideImported }));
  }

  handleFeedEntryClick (e, index, id) {
    const { rangeSelection, feedEntries, feedEntriesSelected } = this.state;

    // If the shift key is depressed when selecting an item, toggle the state of all
    // items between the one currently being clicked and the most recent previously clicked.
    if (e.shiftKey && rangeSelection) {
      if (index === rangeSelection.index) {
        return null;
      }

      const checked = rangeSelection.checked;
      const startIndex = rangeSelection.index < index ? rangeSelection.index : index;
      const endIndex = rangeSelection.index < index ? index : rangeSelection.index;

      const idsInRange = feedEntries.slice(startIndex, endIndex + 1).map(({ uri }) => uri.split('/').slice(-1)[0]);
      if (checked) {
        const newIds = idsInRange.filter(id => !feedEntriesSelected.includes(id));
        this.setState({ feedEntriesSelected: [...feedEntriesSelected, ...newIds] });
      } else {
        this.setState({ feedEntriesSelected: feedEntriesSelected.filter(id => !idsInRange.includes(id)) });
      }
    } else {
      this.handleToggleFeedEntry(id);
    }
  }

  handleToggleFeedEntry (id) {
    const { feedEntries, feedEntriesSelected } = this.state;
    const checked = !feedEntriesSelected.includes(id);

    if (checked) {
      this.setState({ feedEntriesSelected: [...feedEntriesSelected, id] });
    } else {
      this.setState({ feedEntriesSelected: feedEntriesSelected.filter(item => item !== id) });
    }

    const index = feedEntries.findIndex(({ uri }) => uri.indexOf(id) > -1);
    this.setState({ rangeSelection: { checked, index } });
  }

  handleFeedFieldChange (e) {
    this.setState({ activeFeedId: e.target.value });
  }

  doImport (entryIds) {
    const { csrfToken, onComplete } = this.props;
    const { activeFeedId } = this.state;
    const url = urlJoin(urls.podcastImportBase, activeFeedId, '/');
    const body = JSON.stringify({ ids: entryIds });

    this.setState({ processing: true });
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({ importResult: data, processing: false });
        onComplete && onComplete();
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  fetchFeeds () {
    this.setState({ feedsFetching: true });
    fetch(urls.podcastFeedList, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ feedsError: true, statusText: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        const activeFeedId = data[0] ? data[0].id : null;
        const feedsById = data.reduce((result, item) => {
          const { id, ...rest } = item;
          result[id] = rest;
          return result;
        }, {});
        this.setState({
          feedsById,
          activeFeedId,
          feedsFetching: false,
        });
      })
      .catch(err => {
        this.setState({ feedsFetching: false });
        console.error(err);
      });
  }

  fetchFeedEntries () {
    const { activeFeedId, feedEntriesQuery, abortController: ac } = this.state;
    ac && ac.abort();

    const queryParams = { format: 'json' };
    if (feedEntriesQuery) {
      queryParams.query = feedEntriesQuery;
    }

    const dataUrl = urlJoin(urls.podcastListBase, activeFeedId, `?${queryString.stringify(queryParams)}`);
    const abortController = new AbortController();
    const cb = () => fetch(dataUrl, { credentials: 'include', signal: abortController.signal })
      .then(response => {
        if (!response.ok) {
          this.setState({ feedEntriesError: true, statusText: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => this.setState({
        feedEntries: data,
        feedEntriesFetching: false,
        abortController: null,
      }))
      .catch(err => {
        this.setState({ feedEntriesFetching: false, abortController: null });
        console.error(err);
      });

    this.setState({ feedEntriesFetching: true, abortController }, cb);
  }

  setEntryListHeight () {
    const maxHeight = window.innerHeight - this.entryListRef.getBoundingClientRect().top - 150;
    this.setState({ entryListMaxHeight: maxHeight });
  }

  renderEntryList () {
    const {
      feedEntries,
      visibleEntries,
      feedEntriesSelected,
      feedEntriesFetching,
      entryListMaxHeight,
    } = this.state;

    return (
      <div className="feed-entries-list" ref={node => this.entryListRef = node} style={{ maxHeight: entryListMaxHeight }}>
        {/* fetching in progress, no results */}
        {feedEntriesFetching && feedEntries.length === 0 ? <div className="feed-entries-page-loading" /> : null}

        {/* done fetching, no results */}
        {!feedEntriesFetching && feedEntries.length === 0 ? (
          <div style={{ padding: '20px', textAlign: 'center' }}>No items were found.</div>
        ) : null}

        {feedEntries.length > 0 && visibleEntries.length === 0 && (
          <div style={{ padding: '20px', textAlign: 'center' }}>No items matching the query were found.</div>
        )}

        {visibleEntries.map(({ id, title, link, duration, published, image, imported }, i) => {
          const handleClick = e => this.handleFeedEntryClick(e, i, id);
          const isSelected = feedEntriesSelected.includes(id);
          return (
            <div key={id} className={`feed-entries-item ${isSelected ? 'selected' : ''}`}>
              <label className="form-checkbox">
                <input type="checkbox" onClick={handleClick} checked={isSelected} />
                <i className="form-icon" />
              </label>
              <div
                className="feed-entries-thumbnail"
                style={{ backgroundImage: `url('${image}')` }}
                onClick={handleClick}
              />
              <div className="feed-entries-detail">
                <div className="title">
                  {link
                    ? <a href={link} target="_blank" rel="noopener noreferrer">{title}</a>
                    : <span>{title}</span>}
                </div>
                <div className="meta">
                  {!!published && <>published: {formatters.shortDate(parseISO(published))} &nbsp;&middot;&nbsp;</>}
                  duration: {formatters.seconds(duration)}
                  {imported && <> &nbsp;&middot;&nbsp; <span className="text-success">✓ imported</span></>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  renderFeedField () {
    const { activeFeedId, feedsById } = this.state;
    const choices = Object.entries(feedsById).map(([id, data]) => <option key={id} value={id}>{data.feedTitle}</option>);
    return (
      <select className="form-select" style={{ width: 'auto' }} value={activeFeedId} onChange={this.handleFeedFieldChange}>
        {choices}
      </select>
    );
  }

  renderLibrary () {
    const {
      activeFeedId,
      feedsById,
      processing,
      feedEntriesSelected,
      feedEntriesQuery,
      hideImported,
    } = this.state;

    if (!activeFeedId) return null;

    const { feedTitle } = feedsById[activeFeedId];
    const multipleFeeds = Object.keys(feedsById).length > 1;
    return (
      <div className="feed-entries-container">
        <div className="d-flex align-items-center mb-2">
          <h6 className="identifier mb-0 mr-2">Podcast Feed:</h6>
          {multipleFeeds ? this.renderFeedField() : <strong>{feedTitle}</strong>}
        </div>
        <div className="feed-entries">
          <header>
            <div className="input-group flex-1">
              <div className="has-icon-right" style={{ flex: 1, display: 'flex' }}>
                <input
                  type="text"
                  className="form-input"
                  placeholder="Search episodes"
                  onChange={this.handleSearchInputChange}
                  value={feedEntriesQuery}
                />
              </div>
            </div>
            <div className="ml-2">
              <label className="form-checkbox" style={{ lineHeight: '21px' }}>
                <input type="checkbox" onClick={this.handleHideImportedClick} checked={hideImported} />
                <i className="form-icon" /> <span className="text-sm">Hide previously imported</span>
              </label>
            </div>
          </header>
          {this.renderEntryList()}
        </div>
        <div className="media-import-actions">
          <div className="count">
            {feedEntriesSelected.length} Episode{feedEntriesSelected.length === 1 ? '' : 's'} Selected
          </div>
          <button
            className="btn btn-primary"
            disabled={feedEntriesSelected.length === 0 || processing}
            onClick={this.handleEntryImport}
          >
            Import Episodes
          </button>
        </div>
      </div>
    );
  }

  render () {
    const { workspaceAdmin } = this.props;
    const { processing, error, feedsFetching, activeFeedId } = this.state;

    return error ? (
      <div className="media-import-podcast">
        <div className="toast toast-error">Import failed: {error}</div>
      </div>
    ) : (
      <div className="media-import-podcast">
        {!feedsFetching && !activeFeedId && (
          <div className="py-4">
            No podcast integrations are currently configured.
            {workspaceAdmin
              ? <span> Visit the <a href={urls.integrationSettings}>Integration Settings</a> area to add one.</span>
              : <span> Contact your workspace administrator to add one.</span>}
          </div>
        )}

        {this.renderLibrary()}

        <LoadingOverlay show={processing} />
      </div>
    );
  }
}

Podcast.propTypes = {
  csrfToken: PropTypes.string,
  workspaceAdmin: PropTypes.bool,
  onComplete: PropTypes.func,
};

export default withCSRF(Podcast);
