import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

const Text = ({
  name,
  label,
  addon,
  labelClass,
  helpText,
  disabled,
  placeholder,
  value,
  errors,
  className,
  style,
  onChange,
}) => {
  const input = (
    <input
      type="text"
      className="form-input"
      name={name}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  );

  return (
    <div className={classNames('form-group', className, errors.length > 0 && 'has-error')} style={style}>
      {label && <label htmlFor={name} className={labelClass}>{label}</label>}
      {addon ? (
        <div className="input-group">
          <span className="input-group-addon">{addon}</span>
          {input}
        </div>
      ) : input}
      {helpText && !errors.length ? <p className="form-input-hint mb-0">{helpText}</p> : null}
      {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
    </div>
  );
};

Text.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  addon: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.instanceOf(Element)]),
  labelClass: PropTypes.string,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};

Text.defaultProps = {
  errors: [],
  onChange: () => null,
};

export default Text;
