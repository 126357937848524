import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';

const JsonListWidget = ({
  label,
  helpText,
  value = [],
  errors = {},
  onChange = () => {},
}) => {
  const hasValue = value.length > 0;

  const handleChange = (evt, idx) => {
    const newValue = [...value];
    newValue[idx] = evt.target.value;
    onChange(newValue);
  };

  const handleAddClick = () => onChange([...value, '']);

  return (
    <div>
      <div className="form-group mb-2">
        <h5 className="m-0">{label}</h5>
        {helpText && <div className="text-hint">{helpText}</div>}
      </div>
      <ErrorBoundary>
        {(hasValue ? value : ['']).map((val, idx, arr) => (
          <div key={idx} className={classNames('d-flex align-items-center', idx > 0 && 'mt-2')}>
            <input
              type="text"
              className="form-input"
              value={val}
              onChange={evt => handleChange(evt, idx)}
            />
            {idx === arr.length - 1 && (
              <button
                type="button"
                className="btn btn-primary ml-2 tooltip"
                data-tooltip="Add another"
                style={{
                  width: 30,
                  height: 30,
                  flex: '0 0 30px',
                  borderRadius: '50%',
                  padding: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={handleAddClick}
              >
                <Icon name="add" />
              </button>
            )}
          </div>
        ))}
      </ErrorBoundary>
    </div>
  );
};

JsonListWidget.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default JsonListWidget;
