import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const ToggleSwitch = ({ className, style, enabled, onChange, children }) => {
  return (
    <label className={classNames('form-switch', className)} style={style}>
      <input
        type="checkbox"
        checked={enabled}
        onChange={e => onChange(e.target.checked)}
      />
      <i className="form-icon" />
      {children}
    </label>
  );
};

ToggleSwitch.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  enabled: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

export default ToggleSwitch;
