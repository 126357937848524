const slideFormDefaults = {
  layout: '',
  slug: '',
  name: '',
  nameSource: 'media_name',
  description: '',
  descriptionSource: 'media_long_desc',
  credit: '',
  creditSource: 'media_credit',
  media: null,
  text: '',
  overlayColor: '#000000',
  textColor: '#FFFFFF',
  overlayOpacity: '80',
};

const relatedItemDefaults = {
  itemType: 'link',
  name: '',
  nameSource: 'custom',
  description: '',
  descriptionSource: 'custom',
  credit: '',
  creditSource: 'none',
  linkUrl: '',
  linkImage: null,
  media: null,
  errors: {},
};

export { relatedItemDefaults };
export default slideFormDefaults;
