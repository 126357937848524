import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { objectListConfig, getValidFilterParams } from '../config';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';
import SearchFilter from './SearchFilter';
import ChoiceFilter from './ChoiceFilter';
import RangeFilter from './RangeFilter';
import DateRangeFilter from './DateRangeFilter';
import TitleTypeFilter from './TitleTypeFilter';
import ReleaseTypeFilter from './ReleaseTypeFilter';
import TagFilter from './TagFilter';


const filterComponents = {
  search: SearchFilter,
  choice: ChoiceFilter,
  range: RangeFilter,
  daterange: DateRangeFilter,
  titletype: TitleTypeFilter,
  releasetype: ReleaseTypeFilter,
  tags: TagFilter,
};

class Filters extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeFilters: {},
    };

    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleClearFilters = this.handleClearFilters.bind(this);
    this.updateQueryParams = this.updateQueryParams.bind(this);
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { location, model } = nextProps;
    const queryObj = queryString.parse(location.search);
    const { filters } = objectListConfig[model];
    const enabledFilters = filters.filter(f => !nextProps.disabled.includes(f.id));
    const activeFilters = {};
    enabledFilters.forEach(f => {
      if (f.param in queryObj) {
        activeFilters[f.label] = queryObj[f.param];
      } else if (f.params && (f.params.lowerBound in queryObj || f.params.upperBound in queryObj)) {
        activeFilters[f.label] = [
          queryObj[f.params.lowerBound || null],
          queryObj[f.params.upperBound || null],
        ];
      } else if (f.params && Object.values(f.params).some(param => param in queryObj)) {
        activeFilters[f.label] = Object.entries(f.params).reduce((result, [label, param]) => {
          if (param in queryObj) result[label] = queryObj[param];
          return result;
        }, {});
      }
    });

    return { activeFilters };
  }

  handleFilterChange (param, value, removeParams = []) {
    if (!value && value !== 0) {
      this.updateQueryParams(null, [param, ...removeParams]);
    } else {
      this.updateQueryParams({ [param]: value }, removeParams);
    }
  }

  handleClearFilters (e) {
    const { model } = this.props;
    const filterParams = getValidFilterParams(model);
    e.preventDefault();
    this.updateQueryParams(null, filterParams);
  }

  updateQueryParams (newParams, removeParams = []) {
    const { location, history } = this.props;
    const currentParams = queryString.parse(location.search);
    const params = {
      ...currentParams,
      ...(newParams || {}),
    };
    removeParams.forEach(key => delete params[key]);
    const qs = queryString.stringify(params);
    history.push(`${location.pathname}?${qs}`);
  }

  render () {
    const { model, disabled } = this.props;
    const { activeFilters } = this.state;
    const { filters } = objectListConfig[model];
    const enabledFilters = filters.filter(f => !disabled.includes(f.id));
    const hasActive = Object.keys(activeFilters).length > 0;

    return (
      <div>
        <header>
          {/* TODO: result count */}
          {hasActive ? (
            <a href="#clear-filters" className="filter-clear tooltip tooltip-left" data-tooltip="Reset Filters" onClick={this.handleClearFilters}>
              <Icon name="replay" size={32} />
            </a>
          ) : null}
          <h3 className="alt-head text-reverse">Filter {model}</h3>
        </header>
        <ErrorBoundary>
          {enabledFilters.map(({ label, filterType, ...rest }, i) => {
            const Component = filterComponents[filterType];
            return Component && (
              <section key={`filter-${i}`} className="filter-group">
                <ErrorBoundary suppressMessage>
                  {label ? <h5>{label}</h5> : null}
                  <Component activeValue={activeFilters[label]} onChange={this.handleFilterChange} {...rest} />
                </ErrorBoundary>
              </section>
            );
          })}
        </ErrorBoundary>
      </div>
    );
  }
}

Filters.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  model: PropTypes.oneOf(Object.keys(objectListConfig)),
  disabled: PropTypes.arrayOf(PropTypes.string),
};

Filters.defaultProps = {
  disabled: [],
};

export default withRouter(Filters);
