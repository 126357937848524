import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { pluralize } from 'utils';
import Slider from 'rc-slider';
import Checkbox from 'components/common/Checkbox';
import ColorField from 'components/views/CustomTitleBuilder/CustomTitleField/ColorField';
import QuillEditor from 'components/common/QuillEditor';
import Select from 'components/common/Select';
import ToggleSwitch from 'components/common/ToggleSwitch';
import { selectFieldOptions } from './Form';
import RelatedItemsField from './RelatedItemsField';

export const MODE_REPLACE = 'replace';
export const MODE_APPEND = 'append';
// const MODE_PREPEND = 'prepend';

const fieldDefaults = {
  layout: '',
  name: '',
  nameSource: 'media_name',
  description: '',
  descriptionSource: 'media_long_desc',
  credit: '',
  creditSource: 'media_credit',
  overlayColor: '#000000',
  textColor: '#FFFFFF',
  overlayOpacity: '80',
  relatedItems: [],
  relatedItemsMode: MODE_REPLACE,
};

const BulkEditForm = ({ count, onSubmit, onCancel }) => {
  const [fieldValues, setFieldValues] = useState({});
  const setField = (fieldName, value) => setFieldValues(oldState => ({
    ...oldState,
    [fieldName]: value,
  }));
  const [enabledFieldGroups, setEnabledFieldGroups] = useState([]);
  const groupToggleHandler = groupName => enabled => setEnabledFieldGroups(oldState => {
    const out = oldState.filter(g => g !== groupName);
    if (enabled) out.push(groupName);
    return out;
  });

  // Update fieldValues to include only enabled fields.
  useEffect(() => {
    setFieldValues(oldState => enabledFieldGroups.reduce((result, groupName) => {
      fieldGroups.find(g => g.name === groupName).fields.forEach(fieldName => {
        result[fieldName] = oldState[fieldName] || fieldDefaults[fieldName];
      });
      return result;
    }, {}));
  }, [JSON.stringify(enabledFieldGroups)]);

  const selectFieldValues = Object.entries(selectFieldOptions).reduce((result, [fieldName, opts]) => {
    const val = fieldValues[fieldName] || fieldDefaults[fieldName];
    result[fieldName] = opts.find(opt => opt.value === val);
    return result;
  }, {});

  const fieldChangeHandler = fieldName => value => setField(fieldName, value);
  const inputChangeHandler = fieldName => evt => setField(fieldName, evt.target.value);

  const isText = ['text-fit', 'text-bleed'].includes(fieldValues.layout);
  const isBleed = ['bleed', 'text-bleed'].includes(fieldValues.layout);
  const handleTextSlideChange = evt => {
    const checked = evt.target.checked;
    let value = '';
    if (isBleed && checked) {
      value = 'text-bleed';
    } else if (isBleed) {
      value = 'bleed';
    } else if (checked) {
      value = 'text-fit';
    }

    setField('layout', value);
  };
  const handleLayoutChange = evt => {
    const inputVal = evt.target.value;
    let value = inputVal;
    if (isText) value = `text-${inputVal || 'fit'}`;
    setField('layout', value);
  };

  const handleSubmit = () => onSubmit(fieldValues);

  const fieldGroups = [
    {
      name: 'slideLayout',
      label: 'Slide Layout',
      fields: ['layout'],
      content: (
        <>
          <div className="form-group">
            <label className="form-checkbox my-0">
              <Checkbox
                onChange={handleTextSlideChange}
                checked={isText}
              />
              <i className="form-icon" />
              Text Slide <span className="form-input-hint">– Display as text slide with optional background image.</span>
            </label>
          </div>

          <div className="form-group mb-3">
            <label className="block-label">Image Display</label>
            <label className="form-radio m-0">
              <input
                type="radio"
                value=""
                checked={!isBleed}
                onChange={handleLayoutChange}
              />
              <i className="form-icon" /> Fit <span className="form-input-hint">– {isText ? 'Background image' : 'Image'} fits within the slide area, preserving original crop.</span>
            </label>
            <label className="form-radio m-0">
              <input
                type="radio"
                value="bleed"
                checked={isBleed}
                onChange={handleLayoutChange}
              />
              <i className="form-icon" /> Full Bleed <span className="form-input-hint">– {isText ? 'Background image' : 'Image'} extends to cover the slide area. Some cropping may occur.</span>
            </label>
          </div>
        </>
      ),
    },
    {
      name: 'textSlideOptions',
      label: 'Text Slide Options',
      fields: ['overlayColor', 'textColor', 'overlayOpacity'],
      content: (
        <div className="columns mb-3">
          <div className="column col-3">
            <div className="form-group m-0">
              <label className="block-label">Text Color</label>
              <ColorField
                clearable
                value={fieldValues.textColor}
                onChange={fieldChangeHandler('textColor')}
              />
            </div>
          </div>

          <div className="column col-3">
            <div className="form-group m-0">
              <label className="block-label">Overlay Color</label>
              <ColorField
                clearable
                value={fieldValues.overlayColor}
                onChange={fieldChangeHandler('overlayColor')}
              />
            </div>
          </div>

          <div className="column col-6">
            <div className="form-group m-0">
              <label className="block-label">Overlay Opacity</label>
              <div className="d-flex align-items-center">
                <div className="slider-field" style={{ flex: 1 }}>
                  <Slider
                    value={fieldValues.overlayOpacity}
                    onChange={fieldChangeHandler('overlayOpacity')}
                  />
                </div>
                <div className="ml-2">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-input input-sm"
                      style={{ width: 35 }}
                      value={fieldValues.overlayOpacity}
                      onChange={inputChangeHandler('overlayOpacity')}
                    />
                    <span className="input-group-addon addon-sm">%</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      name: 'name',
      label: 'Name',
      fields: ['name', 'nameSource'],
      content: (
        <div className="form-group mb-3">
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
              <Select
                value={selectFieldValues.nameSource}
                options={selectFieldOptions.nameSource}
                onChange={fieldChangeHandler('nameSource')}
              />
            </div>
            <div style={{ flex: 1, minWidth: 300 }}>
              {fieldValues.nameSource === 'custom' && (
                <input
                  type="text"
                  className="form-input"
                  value={fieldValues.name}
                  onChange={inputChangeHandler('name')}
                />
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      name: 'description',
      label: 'Description',
      fields: ['description', 'descriptionSource'],
      content: (
        <div className="form-group mb-3">
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
              <Select
                value={selectFieldValues.descriptionSource}
                options={selectFieldOptions.descriptionSource}
                onChange={fieldChangeHandler('descriptionSource')}
              />
            </div>
            <div style={{ flex: 1, minWidth: 300 }}>
              {fieldValues.descriptionSource === 'custom' && (
                <QuillEditor
                  value={fieldValues.description}
                  onChange={fieldChangeHandler('description')}
                  size="small"
                />
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      name: 'credit',
      label: 'Credit',
      fields: ['credit', 'creditSource'],
      content: (
        <div className="form-group mb-3">
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
              <Select
                value={selectFieldValues.creditSource}
                options={selectFieldOptions.creditSource}
                onChange={fieldChangeHandler('creditSource')}
              />
            </div>
            <div style={{ flex: 1, minWidth: 300 }}>
              {fieldValues.creditSource === 'custom' && (
                <QuillEditor
                  value={fieldValues.credit}
                  onChange={fieldChangeHandler('credit')}
                  size="small"
                />
              )}
            </div>
          </div>
        </div>
      ),
    },
    {
      name: 'relatedContent',
      label: 'Related Content',
      fields: ['relatedItemsMode', 'relatedItems'],
      content: (
        <>
          <div className="form-group mb-3">
            <label className="block-label">Behavior</label>
            <label className="form-radio m-0">
              <input
                type="radio"
                value={MODE_REPLACE}
                checked={fieldValues.relatedItemsMode === MODE_REPLACE}
                onChange={inputChangeHandler('relatedItemsMode')}
              />
              <i className="form-icon" /> Replace <span className="form-input-hint">– Any existing related content items will be <em>overwritten</em>.</span>
            </label>
            <label className="form-radio m-0">
              <input
                type="radio"
                value={MODE_APPEND}
                checked={fieldValues.relatedItemsMode === MODE_APPEND}
                onChange={inputChangeHandler('relatedItemsMode')}
              />
              <i className="form-icon" /> Append <span className="form-input-hint">– New related content items will be added to any existing items.</span>
            </label>
            {/*
            <label className="form-radio m-0">
              <input
                type="radio"
                value={MODE_PREPEND}
                checked={fieldValues.relatedItemsMode === MODE_PREPEND}
                onChange={inputChangeHandler('relatedItemsMode')}
              />
              <i className="form-icon" /> Prepend <span className="form-input-hint">– These related content items will be added <em>before</em> any existing items.</span>
            </label>
            */}
          </div>

          <RelatedItemsField
            name="relatedItems"
            label="Related Content"
            value={fieldValues.relatedItems}
            onChange={fieldChangeHandler('relatedItems')}
          />
        </>
      ),
    },
  ];

  return (
    <div className="title-builder-form-container">
      <header className="title-builder-form-header">
        <div style={{ maxWidth: 'unset' }}>
          <h5><span>Editing:</span> {pluralize(count, 'Slide')}</h5>
          <div className="d-flex">
            <button type="button" className="btn btn-sm mr-2" onClick={onCancel}>Cancel</button>
            <button type="button" className="btn btn-sm btn-primary" onClick={handleSubmit}>Apply Changes</button>
          </div>
        </div>
      </header>

      <div className="title-builder-form">
        <div className="mb-3">In the list below, enable the fields you wish to edit. Click “Apply Changes” to update the selected slides.</div>
        {fieldGroups.map(({ name, label, content }) => {
          const isEnabled = enabledFieldGroups.includes(name);
          return (
            <Fragment key={name}>
              <hr className="m-0" />
              <div className="d-flex">
                <ToggleSwitch
                  enabled={isEnabled}
                  onChange={groupToggleHandler(name)}
                >
                  <span>{label}</span> {!isEnabled && <em className="text-hint"> — No change</em>}
                </ToggleSwitch>
              </div>
              {isEnabled && (
                <div className="mt-3">{content}</div>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

BulkEditForm.propTypes = {
  count: PropTypes.number,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default BulkEditForm;
