import React, { Component } from 'react';
import { createPortal } from 'react-dom';
import camelize from 'camelize';
import classNames from 'classnames';
import { v4 as uuid4 } from 'uuid';
import isEqual from 'lodash/isEqual';
import throttle from 'lodash/throttle';
import debounce from 'lodash/debounce';
import { customTitleFieldTypes as ft } from 'app-constants';
import CustomTitleField, { CUSTOM_TITLE_FIELD_PROPTYPES } from './';
import Icon from 'components/common/Icon';
import SortableItem from 'components/common/SortableItem';


class ObjectRelationField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeItem: null,
      formData: {},
      errorsById: {},
      showModal: false,
    };
    this.itemRefs = {};
    this.fileInputs = {};

    this.portalEl = document.createElement('div');
    this.portalEl.className = 'custom-title-builder-child-form-modal-portal';

    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleAddItemClick = this.handleAddItemClick.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleFormClose = this.handleFormClose.bind(this);
    this.handleItemMove = this.handleItemMove.bind(this);
    this.handleItemMove = throttle(this.handleItemMove, 50);
    this.saveFormValues = this.saveFormValues.bind(this);
    this.saveFormValues = debounce(this.saveFormValues, 1000);
  }

  componentDidMount () {
    // Error data will only change on page load.
    this.mapErrorsToItems();
    document.body.appendChild(this.portalEl);
  }

  componentDidUpdate (prevProps, prevState) {
    const { value } = this.props;
    const { activeItem, formData } = this.state;

    // Clear activeItem if the item was deleted
    if (value.length !== prevProps.value.length && activeItem && !value.find(({ uid }) => uid === activeItem)) {
      this.setState({ activeItem: null });
    }

    if (activeItem !== prevState.activeItem) {
      this.initFormData();

      if (activeItem) {
        this.setState({ showModal: true });
      }
    }

    if (!isEqual(formData, prevState.formData)) {
      this.saveFormValues();
    }
  }

  componentWillUnmount () {
    clearTimeout(this.activeItemTimeout);
    this.saveFormValues.flush();
    document.body.removeChild(this.portalEl);
  }

  handleEditClick (e, uid) {
    e.stopPropagation();
    this.setState({ activeItem: uid });
  }

  handleDeleteClick (e, uid) {
    const { value, onChange } = this.props;
    e.stopPropagation();
    onChange(value.filter((item) => item.uid !== uid));
  }

  handleAddItemClick (e) {
    e.preventDefault();
    const { inlineFields, value, onChange } = this.props;
    // Initialize a new item with a uid and default form values
    const uid = uuid4();
    const newItem = inlineFields.reduce((result, fieldSpec) => {
      result[fieldSpec.name] = fieldSpec.default;
      return result;
    }, { uid });

    onChange([...value, newItem]);
    this.setState({ activeItem: uid }, () => {
      setTimeout(() => this.itemRefs[uid].scrollIntoView({ behavior: 'smooth' }), 10);
    });
  }

  handleFieldChange (fieldName, value) {
    const { formData } = this.state;
    this.setState({
      formData: {
        ...formData,
        [fieldName]: value,
      },
    });
  }

  handleFormClose (e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showModal: false });
    this.activeItemTimeout = setTimeout(() => this.setState({ activeItem: null }), 500);
  }

  handleItemMove (sourceId, targetId, insertPos) {
    const { value, onChange } = this.props;
    const sourceItem = value.find(item => item.uid === sourceId);
    const targetIndex = value.findIndex(item => item.uid === targetId);
    const toIndex = insertPos === 'before' ? targetIndex : targetIndex + 1;
    const newValue = value.filter(item => item.uid !== sourceId);
    newValue.splice(toIndex, 0, sourceItem);
    onChange(newValue);
  }

  saveFormValues () {
    const { value, onChange } = this.props;
    const { formData, activeItem } = this.state;
    const newValue = [...value];
    const index = newValue.findIndex(item => item.uid === activeItem);
    newValue[index] = { ...newValue[index], ...formData };
    onChange(newValue);
  }

  mapErrorsToItems () {
    // Form error messages are passed through props as an array. Map them to item UIDs
    // to ensure errors stay with the proper item as items are added or removed.
    const { errors, value: items } = this.props;
    const errorsById = errors.reduce((result, errorObject, idx) => {
      if (Object.keys(errorObject).length) {
        const uid = items[idx].uid;
        result[uid] = errorObject;
      }
      return result;
    }, {});
    this.setState({ errorsById });
  }

  initFormData () {
    // Load initial field values for the child form
    const { value, inlineFields } = this.props;
    const { activeItem } = this.state;
    const itemIndex = value.findIndex(item => item.uid === activeItem);
    const initialData = value[itemIndex];
    const formData = {};
    if (initialData) {
      Object.entries(initialData).forEach(([key, value]) => {
        if (typeof inlineFields.find(field => field.name === key) !== 'undefined') {
          formData[key] = value;
        }
      });
    }
    this.setState({ formData });
  }

  renderForm () {
    const { inlineFields, nestingLevel } = this.props;
    const { formData, activeItem, errorsById } = this.state;
    if (!Object.keys(formData).length) {
      return null;
    }

    return (
      <form className="custom-title-builder-form" onSubmit={this.handleFormClose} key={activeItem}>
        {camelize(inlineFields).map((fieldSpec, i) => {
          const fieldErrors = errorsById[activeItem] ? errorsById[activeItem][fieldSpec.name] : [];
          return (
            <CustomTitleField
              key={`${activeItem}-${fieldSpec.name}`}
              {...fieldSpec}
              value={formData[fieldSpec.name]}
              errors={fieldErrors}
              autoFocus={i === 0}
              onChange={value => this.handleFieldChange(fieldSpec.name, value)}
              fileInputRefs={this.fileInputs[activeItem]}
              nestingLevel={nestingLevel + 1}
            />
          );
        })}
        <hr />
        <div>
          <button type="submit" className="btn btn-primary">Close</button>
        </div>
      </form>
    );
  }

  renderFileInputs (item) {
    const { inlineFields } = this.props;
    const fileFields = inlineFields.filter(f => [ft.file, ft.image].includes(f.type));
    const handleRef = (node, fieldName) => {
      this.fileInputs[item.uid] = this.fileInputs[item.uid] || {};
      this.fileInputs[item.uid][fieldName] = node;
    };
    return (
      <div style={{ position: 'absolute' }}>
        {fileFields.map(fieldSpec => (
          <input
            ref={node => handleRef(node, fieldSpec.name)}
            key={`${item.uid}-${fieldSpec.name}`}
            type="file"
            className="hidden-file-input"
            name={`${item.uid}-${fieldSpec.name}`}
            id={`${item.uid}-${fieldSpec.name}`}
            onChange={e => this.handleFieldChange(fieldSpec.name, e.target.files[0].name)}
          />
        ))}
      </div>
    );
  }

  renderItems () {
    const { name, inlineFields, value: items } = this.props;
    const { activeItem, errorsById } = this.state;
    // Use the value of the first field to label each item
    const labelFieldName = inlineFields[0] && inlineFields[0].name;
    return items.map(item => {
      const label = item[labelFieldName] || '(New item)';
      const classes = classNames({
        item: true,
        active: item.uid === activeItem,
        error: errorsById.hasOwnProperty(item.uid),
      });
      return (
        <SortableItem key={item.uid} itemId={item.uid} type={name} className="mb-4" onItemMove={this.handleItemMove}>
          <div className={classes} ref={node => this.itemRefs[item.uid] = node}>
            <h5 className="mb-0">{label}</h5>
            <div className="d-flex align-items-center">
              {/* <span className="text-sm text-error mx-1">Click again to confirm</span> */}
              <span
                className="btn-delete tooltip tooltip-left mx-1"
                onClick={e => this.handleDeleteClick(e, item.uid)}
                data-tooltip="Remove Item"
              >
                <Icon name="delete" fill />
              </span>
              <span
                className="btn-edit tooltip tooltip-left mx-1"
                onClick={e => this.handleEditClick(e, item.uid)}
                data-tooltip="Edit Item"
              >
                <Icon name="edit" />
              </span>
              <span className="btn-move ml-1" title="Drag to sort">
                <Icon name="menu" />
              </span>
            </div>
            {/* TODO */}
            {/* {this.renderFileInputs(item)} */}
          </div>
        </SortableItem>
      );
    });
  }

  render () {
    const { label, helpText, errors, value, nestingLevel } = this.props;
    const { showModal } = this.state;
    const modalClasses = classNames({
      'custom-title-builder-child-form-modal': true,
      open: showModal,
    }, `level-${nestingLevel}`);

    return (
      <div className="form-group mb-4">
        <label htmlFor={name} className="block-label">{label}</label>
        {helpText ? <p className="form-input-hint m-0">{helpText}</p> : null}
        <div className="relation-field mt-1">
          {errors.length ? <div className="error-list">Some of the items below contain errors.</div> : null}
          <div className="item-list">
            {this.renderItems()}
          </div>
          <div className="controls">
            <div className="item-count">{`${value.length} Item${value.length === 1 ? '' : 's'}`}</div>
            <button type="button" className="btn btn-primary" onClick={this.handleAddItemClick}>Add Item</button>
          </div>
        </div>
        {createPortal(
          <>
            <div className={`custom-title-builder-child-form-modal-mask ${showModal ? 'open' : ''}`} />
            <div className={modalClasses}>
              <header className="custom-title-builder-child-form-modal-header">
                <a href="#back" className="back-icon" onClick={this.handleFormClose}>
                  <Icon name="chevron_left" />
                </a>
                <h3>Editing <em>{label}</em> Item</h3>
              </header>
              <div className="custom-title-builder-child-form-modal-content">
                {this.renderForm()}
              </div>
            </div>
          </>,
          this.portalEl,
        )}
      </div>
    );
  }
}

ObjectRelationField.propTypes = {
  ...CUSTOM_TITLE_FIELD_PROPTYPES,
};

ObjectRelationField.defaultProps = {
  onChange: () => null,
  errors: [],
  value: [],
};

export default ObjectRelationField;
