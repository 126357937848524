import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Marker = ({ className, left, maxLeft, draggable = true, onDrag }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [localLeft, setLocalLeft] = useState(left);

  useEffect(() => {
    if (!isDragging) {
      leftRef.current = left;
      setLocalLeft(left);
    }
  }, [left]);

  const leftRef = useRef(left);
  const unsub = useRef();

  useEffect(() => {
    if (!draggable && unsub.current) unsub.current();
  }, [draggable]);

  const handleMouseDown = evt => {
    if (!draggable) return;
    setIsDragging(true);
    const dragStartX = evt.pageX;

    const handleMouseMove = evt => {
      const delta = evt.pageX - dragStartX;
      let newLeft = localLeft + delta;
      if (newLeft < 0) newLeft = 0;
      if (newLeft > maxLeft) newLeft = maxLeft;
      setLocalLeft(newLeft);
      leftRef.current = newLeft;
    };

    const handleMouseUp = evt => {
      setIsDragging(false);
      onDrag(leftRef.current);
      unsub.current && unsub.current();
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    unsub.current = () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    return unsub.current;
  };

  return (
    <div
      className={classNames(className, isDragging && 'dragging')}
      style={{ left: localLeft }}
      onMouseDown={handleMouseDown}
      onClick={evt => evt.stopPropagation()}
    />
  );
};

Marker.propTypes = {
  className: PropTypes.string,
  left: PropTypes.number,
  maxLeft: PropTypes.number,
  draggable: PropTypes.bool,
  onDrag: PropTypes.func,
};

export default Marker;
