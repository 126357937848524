import React from 'react';
import PropTypes from 'prop-types';
import { customTitleFieldTypes as ft } from 'app-constants';
import TextField from './TextField';
import TextAreaField from './TextAreaField';
import SlugField from './SlugField';
import NumberField from './NumberField';
// import DateTimeField from './DateTimeField';
import BooleanField from './BooleanField';
import ChoiceField from './ChoiceField';
import ColorField from './ColorField';
import TableField from './TableField';
// import FileField from './FileField';
import MediaRelationField from './MediaRelationField';
import TitleRelationField from './TitleRelationField';
import ObjectRelationField from './ObjectRelationField';


const CUSTOM_TITLE_FIELD_PROPTYPES = {
  name: PropTypes.string,
  value: PropTypes.any,
  errors: PropTypes.array,
  label: PropTypes.string,
  helpText: PropTypes.string,
  required: PropTypes.bool,
  maxLength: PropTypes.number,
  default: PropTypes.string,
  choices: PropTypes.array,
  allowFormatting: PropTypes.bool,
  allowMultiple: PropTypes.bool,
  limitTypes: PropTypes.arrayOf(PropTypes.string),
  inlineFields: PropTypes.arrayOf(PropTypes.object),
  uniqueFields: PropTypes.arrayOf(PropTypes.string),
  autoFocus: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

const CustomTitleField = ({ type, ...rest }) => {
  switch (type) {
    case ft.text:
      return <TextField {...rest} />;
    case ft.multilineText:
      return <TextAreaField {...rest} />;
    case ft.slug:
      return <SlugField {...rest} />;
    case ft.integer:
    case ft.float:
      return <NumberField {...rest} />;
    case ft.datetime:
      return null;
      // TODO: DateTimeField is incomplte -- deal with timezones
      // return <DateTimeField {...rest} />;
    case ft.bool:
      return <BooleanField {...rest} />;
    case ft.choice:
      return <ChoiceField radio={rest.choices.length < 4} {...rest} />;
    case ft.color:
      return <ColorField {...rest} />;
    case ft.table:
      return <TableField {...rest} />;
    case ft.image:
    case ft.file:
      return null;
      // TODO
      // return <FileField {...rest} />;
    case ft.media:
      return <MediaRelationField {...rest} />;
    case ft.title:
      return <TitleRelationField {...rest} />;
    case ft.object:
      return <ObjectRelationField {...rest} />;
    default:
      return null;
  }
};

CustomTitleField.propTypes = {
  type: PropTypes.oneOf(Object.values(ft)),
};

export default CustomTitleField;
export { CUSTOM_TITLE_FIELD_PROPTYPES };
