import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Checkbox from 'components/common/DjangoFormField/Checkbox';
import Text from 'components/common/DjangoFormField/Text';
import LibraryMediaField from 'components/common/LibraryMediaField';
import FieldWrapper from 'components/views/CustomTitleBuilder/CustomTitleField/FieldWrapper';

const DISPLAY_STYLE_FILL = 'fill';
const DISPLAY_STYLE_FIT = 'fit';

const MENU_POSITION_BOTTOM = 'bottom';
const MENU_POSITION_RIGHT = 'right';

const ROWS_AUTO = 'auto';
const ROWS_FIXED = 'fixed';

class CoverLayout extends Component {
  constructor (props) {
    super(props);
    this.state = {
      formData: null,
      fields: {},
      rowsStyle: ROWS_AUTO,
      rowsPerPageInitialVal: 1,
    };
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRowsStyleChange = this.handleRowsStyleChange.bind(this);
  }

  componentDidMount () {
    this.initFormData();
  }

  componentDidUpdate (prevProps, prevState) {
    const { rowsStyle, rowsPerPageInitialVal } = this.state;
    if (rowsStyle !== prevState.rowsStyle) {
      if (rowsStyle === ROWS_FIXED) {
        this.handleFieldChange('rows_per_page', rowsPerPageInitialVal);
      } else {
        this.handleFieldChange('rows_per_page', '');
      }
    }
  }

  initFormData () {
    const { fieldNames, formData } = this.props;
    const fields = fieldNames.reduce((result, name) => {
      const val = formData.is_bound ? formData.fields[name].value : formData.fields[name].initial;
      result[name] = val;
      return result;
    }, {});

    this.setState({
      fields,
      rowsStyle: fields.rows_per_page ? ROWS_FIXED : ROWS_AUTO,
      rowsPerPageInitialVal: fields.rows_per_page || 1,
    });
  }

  handleFieldChange (fieldName, value) {
    const { fields } = this.state;
    this.setState({
      fields: {
        ...fields,
        [fieldName]: value,
      },
    });
  }

  handleInputChange (e) {
    let { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      value = !!checked;
    }
    this.handleFieldChange(name, value);
  }

  handleRowsStyleChange (e) {
    this.setState({ rowsStyle: e.target.value });
  }

  renderLayoutChoices () {
    const { layouts } = this.props;
    const { fields } = this.state;

    return camelize(layouts).map(({ slug, label, description, thumbnailUrl }) => {
      const handleSelect = () => this.handleFieldChange('layout', slug);
      const isSelected = fields.layout === slug;

      return (
        <div key={slug} className={classNames('cover-layout-item', 'card-item', isSelected && 'selected')} onClick={handleSelect}>
          <div className="cover-layout-thumbnail">
            <img src={thumbnailUrl} />
          </div>
          <footer>
            <div className="cover-layout-radio">
              <label className="form-radio">
                <input
                  type="radio"
                  name="layout"
                  value={slug}
                  checked={fields.layout === slug}
                  onChange={handleSelect}
                />
                <i className="form-icon" />
              </label>
            </div>
            <div className="cover-layout-label">
              <h5 className="m-0">{label}</h5>
              {!!description && <div className="text-meta mt-1">{description}</div>}
            </div>
          </footer>
        </div>
      );
    });
  }

  renderHiddenInput (name) {
    const { fields } = this.state;
    return <input type="hidden" name={name} value={typeof fields[name] !== 'undefined' ? fields[name] : ''} />;
  }

  renderOptions () {
    const { layouts, formData } = this.props;
    const { fields, rowsStyle } = this.state;

    if (!fields.layout) {
      return null;
    }

    const activeLayout = layouts.find(l => l.slug === fields.layout);
    const fieldNames = this.props.fieldNames.filter(name => (
      name !== 'layout' && !(activeLayout.exclude_fields || []).includes(name)
    ));

    const show = name => fieldNames.includes(name);

    return (
      <>
        {show('show_name') ? (
          <Checkbox
            name="show_name"
            label="Show name"
            helpText="Display this Cover’s name heading when no individual item is selected."
            value={fields.show_name}
            errors={formData.errors.show_name || []}
            className="mb-3"
            onChange={this.handleInputChange}
          />
        ) : this.renderHiddenInput('show_name')}
        {show('show_description') ? (
          <Checkbox
            name="show_description"
            label="Show description"
            helpText="Display this Cover’s short description text when no individual item is selected."
            value={fields.show_description}
            errors={formData.errors.show_description || []}
            className="mb-3"
            onChange={this.handleInputChange}
          />
        ) : this.renderHiddenInput('show_description')}
        {show('dim_inactive') ? (
          <Checkbox
            name="dim_inactive"
            label="Dim inactive items"
            helpText="Slightly dim all items except the one currently under the mouse pointer."
            value={fields.dim_inactive}
            errors={formData.errors.dim_inactive || []}
            className="mb-3"
            onChange={this.handleInputChange}
          />
        ) : this.renderHiddenInput('dim_inactive')}
        {show('button_text') ? (
          <Text
            name="button_text"
            label="Button text"
            helpText="Text for call-to-action button displayed when the Cover is first loaded."
            value={fields.button_text || ''}
            errors={formData.errors.button_text || []}
            className="mb-3"
            onChange={this.handleInputChange}
          />
        ) : this.renderHiddenInput('button_text')}
        {show('background_image') ? (
          <FieldWrapper
            name="background_image"
            label="Background image"
            helpText="Select a default background image for this Cover. This image will be displayed when no individual item is selected, on platforms that do not support background video."
            errors={formData.errors.background_image || []}
          >
            <LibraryMediaField
              inputName="background_image"
              buttonText="Choose Image"
              limitTypes={['image']}
              value={fields.background_image || ''}
              onChange={value => this.handleFieldChange('background_image', value)}
            />
          </FieldWrapper>
        ) : this.renderHiddenInput('background_image')}
        {show('background_video') ? (
          <FieldWrapper
            name="background_video"
            label="Background video"
            helpText="Select a default background video for this Cover. This video will be displayed when no individual item is selected."
            errors={formData.errors.background_video || []}
          >
            <LibraryMediaField
              inputName="background_video"
              buttonText="Choose Video"
              limitTypes={['video']}
              value={fields.background_video || ''}
              onChange={value => this.handleFieldChange('background_video', value)}
            />
          </FieldWrapper>
        ) : this.renderHiddenInput('background_video')}
        {show('loop_video') ? (
          <Checkbox
            name="loop_video"
            label="Loop Video Playback"
            value={fields.loop_video}
            errors={formData.errors.loop_video || []}
            className="mb-3"
            onChange={this.handleInputChange}
          />
        ) : this.renderHiddenInput('loop_video')}
        {show('animation_delay') ? (
          <FieldWrapper
            name="animation_delay"
            label="Animation delay"
            helpText="Specify the delay, in seconds, before text and overlay elements animate in. Set a value of 0 to disable this animation."
            errors={formData.errors.animation_delay || []}
          >
            <input
              type="number"
              step="0.1"
              className="form-input"
              name="animation_delay"
              value={fields.animation_delay || 0}
              onChange={this.handleInputChange}
              style={{ width: 'unset' }}
            />
          </FieldWrapper>
        ) : this.renderHiddenInput('animation_delay')}
        {show('items_per_page') ? (
          <FieldWrapper
            name="items_per_page"
            label="Max items per page"
            helpText="Specify the maximum number of cover items displayed per page."
            errors={formData.errors.items_per_page || []}
          >
            <input
              type="number"
              step="1"
              className="form-input"
              name="items_per_page"
              value={fields.items_per_page || ''}
              onChange={this.handleInputChange}
              style={{ width: 'unset' }}
            />
          </FieldWrapper>
        ) : this.renderHiddenInput('items_per_page')}
        {show('rows_per_page') ? (
          <FieldWrapper
            name="rows_per_page"
            label="Rows per page"
            errors={formData.errors.rows_per_page || []}
          >
            <label className="form-radio">
              <input
                type="radio"
                value={ROWS_AUTO}
                checked={rowsStyle === ROWS_AUTO}
                onChange={this.handleRowsStyleChange}
              />
              <i className="form-icon" />
              <span>Automatic</span>
              <div className="text-hint">Calculate the number of grid rows automatically.</div>
            </label>

            <label className="form-radio">
              <input
                type="radio"
                value={ROWS_FIXED}
                checked={rowsStyle === ROWS_FIXED}
                onChange={this.handleRowsStyleChange}
              />
              <i className="form-icon" />
              <span>Fixed</span>
              <div className="text-hint">Display a fixed number of grid rows.</div>
              <input
                type="number"
                step="1"
                min={1}
                max={6}
                className="form-input"
                name="rows_per_page"
                disabled={rowsStyle !== ROWS_FIXED}
                value={fields.rows_per_page || ''}
                onChange={this.handleInputChange}
                style={{ width: 80, marginTop: 5 }}
              />
            </label>
          </FieldWrapper>
        ) : this.renderHiddenInput('rows_per_page')}
        {show('menu_position') ? (
          <FieldWrapper
            label="Menu Position"
            errors={formData.errors.menu_position || []}
          >
            <label className="form-radio">
              <input
                type="radio"
                name="menu_position"
                value={MENU_POSITION_BOTTOM}
                checked={fields.menu_position === MENU_POSITION_BOTTOM}
                onChange={this.handleInputChange}
              />
              <i className="form-icon" />
              <span>Bottom</span>
            </label>

            <label className="form-radio">
              <input
                type="radio"
                name="menu_position"
                value={MENU_POSITION_RIGHT}
                checked={fields.menu_position === MENU_POSITION_RIGHT}
                onChange={this.handleInputChange}
              />
              <i className="form-icon" />
              <span>Right</span>
            </label>
          </FieldWrapper>
        ) : this.renderHiddenInput('menu_position')}
        {show('display_style') ? (
          <FieldWrapper
            label="Item display style"
            errors={formData.errors.display_style || []}
          >
            <label className="form-radio">
              <input
                type="radio"
                name="display_style"
                value={DISPLAY_STYLE_FILL}
                checked={fields.display_style === DISPLAY_STYLE_FILL}
                onChange={this.handleInputChange}
              />
              <i className="form-icon" />
              <span>Fill display area</span>
              <div className="text-hint">Image/video content fills available space. Cropping may occur.</div>
            </label>

            <label className="form-radio">
              <input
                type="radio"
                name="display_style"
                value={DISPLAY_STYLE_FIT}
                checked={fields.display_style === DISPLAY_STYLE_FIT}
                onChange={this.handleInputChange}
              />
              <i className="form-icon" />
              <span>Fit to display area</span>
              <div className="text-hint">Image/video content fits within available space. Letterboxing may occur.</div>
            </label>
          </FieldWrapper>
        ) : this.renderHiddenInput('display_style')}
      </>
    );
  }

  render () {
    const { formData } = this.props;
    const nonFieldErrors = formData.non_field_errors.length > 0 && formData.non_field_errors;
    const layoutErrors = formData.errors.layout;

    return (
      <div className="object-detail-content">
        <div className="object-detail-main">
          {nonFieldErrors && (
            <div className="error-list mb-4">
              {nonFieldErrors.map((msg, idx) => <p key={idx}>{msg}</p>)}
            </div>
          )}
          <h4 className="alt-head mb-1">Layout</h4>
          <hr className="mt-0 mb-4" />
          {layoutErrors && (
            <div className="error-list mb-4">
              {layoutErrors.map((msg, idx) => <p key={idx}>{msg}</p>)}
            </div>
          )}
          <div className="cover-layout-item-container">
            <ErrorBoundary>
              {this.renderLayoutChoices()}
            </ErrorBoundary>
          </div>
        </div>
        <div className="object-detail-sidebar">
          <h4 className="alt-head mb-1">Options</h4>
          <hr className="mt-0 mb-4" />
          <ErrorBoundary>
            {this.renderOptions()}
          </ErrorBoundary>
        </div>
      </div>
    );
  }
}

CoverLayout.propTypes = {
  formData: PropTypes.object.isRequired,
  layouts: PropTypes.arrayOf(PropTypes.shape({
    slug: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    exclude_fields: PropTypes.arrayOf(PropTypes.string),
  })).isRequired,
  fieldNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default CoverLayout;
