import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import Icon from 'components/common/Icon';
import TruncateText from 'components/common/TruncateText';
import { DeleteOverlay } from 'components/common/inlines';


class Item extends Component {
  constructor (props) {
    super(props);
    this.state = {
      attachedMedia: [],
      error: false,
      processing: false,
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount () {
    const { media } = this.props;
    this.fetchData(media);
  }

  componentDidUpdate (prevProps, prevState) {
    const { media } = this.props;
    if (!isEqual(media, prevProps.media)) {
      this.fetchData(media);
    }
  }

  handleDeleteClick (e) {
    const { onDeleteClick } = this.props;
    e.stopPropagation();
    onDeleteClick();
  }

  fetchData (mediaIds = []) {
    if (!mediaIds.length) {
      return null;
    }
    const idString = mediaIds.join(',');
    const url = urlJoin(urls.mediaData, '/', `?format=json&uuid__in=${idString}`);
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({
          attachedMedia: data.results.reduce((result, item) => {
            result[item.uuid] = item;
            return result;
          }, {}),
          processing: false,
        });
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  renderThumbnail () {
    const { media } = this.props;
    const { attachedMedia } = this.state;
    const previewMedia = attachedMedia[media[0]];

    if (media && media.length) {
      return previewMedia ? (
        <div className="title-builder-item-thumbnail mr-4">
          <img src={previewMedia.thumbnail} alt="thumbnail" width="100" height="60" />
        </div>
      ) : (
        <div className="title-builder-item-thumbnail mr-4">
          <Icon name="steppers" />
        </div>
      );
    } else {
      return (
        <div className="title-builder-item-thumbnail mr-4">
          <Icon name="content_copy" />
        </div>
      );
    }
  }

  renderFormFields () {
    const {
      prefix, counter, id, shouldDelete, parent, defaultItem, description, latitude, longitude, locationType,
      marker, name, slug, countries, categories, media,
    } = this.props;
    const latitudeValue = isNaN(parseFloat(latitude)) ? '' : latitude;
    const longitudeValue = isNaN(parseFloat(longitude)) ? '' : longitude;

    return (
      <>
        {id ? <input type="hidden" name={`${prefix}-id`} value={id} /> : null}
        {shouldDelete ? <input type="hidden" name={`${prefix}-DELETE`} value="1" /> : null}
        <input type="hidden" name={`${prefix}-parent`} value={parent} />
        <input type="hidden" name={`${prefix}-index`} value={counter} />
        {defaultItem ? <input type="hidden" name={`${prefix}-default_item`} value="1" /> : null}
        <input type="hidden" name={`${prefix}-description`} value={description || ''} />
        <input type="hidden" name={`${prefix}-latitude`} value={latitudeValue} />
        <input type="hidden" name={`${prefix}-longitude`} value={longitudeValue} />
        <input type="hidden" name={`${prefix}-location_type`} value={locationType || ''} />
        <input type="hidden" name={`${prefix}-marker`} value={marker || ''} />
        <input type="hidden" name={`${prefix}-name`} value={name || ''} />
        <input type="hidden" name={`${prefix}-slug`} value={slug || ''} />
        {(countries || []).map(val => <input key={val} type="hidden" name={`${prefix}-countries`} value={val} />)}
        {(categories || []).map(val => <input key={val} type="hidden" name={`${prefix}-categories`} value={val} />)}
        {(media || []).map(val => <input key={val} type="hidden" name={`${prefix}-media`} value={val} />)}
      </>
    );
  }

  render () {
    const { counter, active, error, onClick, name, shouldDelete, media } = this.props;
    const { attachedMedia } = this.state;

    const containerClasses = classNames({
      active,
      error,
      'title-builder-item': true,
      // 'm-0': true,
      'to-delete': shouldDelete,
    });

    return (
      <div className={containerClasses} onClick={onClick}>
        <div className="index-counter">{counter}</div>
        {this.renderThumbnail()}

        <div className="title-builder-item-details">
          {/* <div className="date">{dateDisplay || formattedDate}</div> */}
          <h6 className="mb-0"><TruncateText text={name} numChars={75} /></h6>

          {media.length ? (
            <div className="meta mt-1 lh-1">
              Media:&nbsp;
              {media.map((uid, idx) => {
                const last = idx === media.length - 1;
                const m = attachedMedia[uid];
                return m ? (
                  <span key={m.id}>
                    <a href={`${urls.mediaBase}${m.id}/`} target="_blank" rel="noopener noreferrer">{m.id}</a>{last ? '' : ', '}
                  </span>
                ) : null;
              })}
            </div>
          ) : null}
        </div>

        {this.renderFormFields()}

        {shouldDelete ? <DeleteOverlay onUndoClick={this.handleDeleteClick} /> : null}

        <span
          className="btn-delete tooltip tooltip-left"
          key={shouldDelete}
          onClick={this.handleDeleteClick}
          data-tooltip={shouldDelete ? 'Undo Remove' : 'Remove Item'}
        >
          {shouldDelete ? <Icon name="undo" /> : <Icon name="delete" fill />}
        </span>

        <span className="btn-move" title="Drag to sort">
          <Icon name="menu" />
        </span>
      </div>
    );
  }
}

Item.propTypes = {
  counter: PropTypes.number,
  active: PropTypes.bool,
  error: PropTypes.bool,
  onClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  prefix: PropTypes.string,
  id: PropTypes.string,
  parent: PropTypes.string,
  shouldDelete: PropTypes.bool,
  name: PropTypes.string,
  defaultItem: PropTypes.bool,
  description: PropTypes.string,
  media: PropTypes.arrayOf(PropTypes.string),
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  locationType: PropTypes.string,
  marker: PropTypes.string,
  slug: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.arrayOf(PropTypes.string),
};

export default Item;
