import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';


const Controls = ({ empty, mode, onChangeMode }) => (
  <div className="inline-list-builder-controls-wrap">
    <div className="btn-group btn-group-block inline-list-builder-controls">
      <button
        className={`tooltip tooltip-bottom btn ${mode === 'sort' ? 'btn-primary' : ''}`}
        onClick={e => { e.preventDefault(); onChangeMode('sort'); }}
        data-tooltip="Arrange"
        disabled={empty}
      >
        <Icon name="unfold_more" />
      </button>

      <button
        className={`tooltip tooltip-bottom btn ${mode === 'insert' ? 'btn-primary' : ''}`}
        onClick={e => { e.preventDefault(); onChangeMode('insert'); }}
        data-tooltip="Edit"
      >
        <Icon name="edit" />
      </button>
    </div>
  </div>
);

Controls.propTypes = {
  empty: PropTypes.bool,
  mode: PropTypes.oneOf(['sort', 'insert']),
  onChangeMode: PropTypes.func,
};

export default Controls;
