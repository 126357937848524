import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { useItemDataCache } from 'context';
import Message from 'components/common/Message';
import Icon from 'components/common/Icon';
import MediaTypeIcon from 'components/common/MediaTypeIcon';
import LazyThumbnail from 'components/common/LazyThumbnail';

const ITEM_TYPE_MEDIA = 'media';
const ITEM_TYPE_TITLE = 'title';

const AttachedItemPreview = ({
  style,
  className,
  itemType = ITEM_TYPE_MEDIA,
  itemId,
  clickableThumbnail = false,
  lazyThumbnail = true,
  onLoad,
  children,
}) => {
  const [data, setData] = useState({});

  const opts = useMemo(() => ({
    [ITEM_TYPE_MEDIA]: {
      baseUrl: urls.mediaBase,
      baseDataUrl: urls.mediaDataBase,
      label: 'Media',
      renderIcon: () => !!data.type && <MediaTypeIcon type={data.type.toLowerCase()} />, // eslint-disable-line
    },
    [ITEM_TYPE_TITLE]: {
      baseUrl: urls.titleBase,
      baseDataUrl: urls.titleDataBase,
      label: 'Title',
      renderIcon: () => <Icon name={data.iconName} />, // eslint-disable-line
    },
  }[itemType]), [itemType, data]);

  const { itemDataCache } = useItemDataCache();

  useEffect(() => {
    setData({});
    const cachedData = itemDataCache[itemId];
    if (cachedData) {
      setData(cachedData);
      setProcessing(false);
    } else {
      fetchData(itemId);
    }
  }, [itemId]);

  useEffect(() => {
    if (data.id && onLoad) {
      onLoad(data);
    }
  }, [data.id]);

  const [processing, setProcessing] = useState(true);
  const fetchData = itemId => {
    const url = urlJoin(opts.baseDataUrl, itemId || '', '/', '/?format=json');
    setProcessing(true);
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        setData(data);
        setProcessing(false);
      })
      .catch(err => {
        setProcessing(false);
        console.error(err);
      });
  };

  let thumbnailImg;
  if (data.thumbnail) {
    thumbnailImg = lazyThumbnail ? (
      <LazyThumbnail src={data.thumbnail} alt="thumbnail" width={100} height={60} />
    ) : (
      <img src={data.thumbnail} alt="thumbnail" width="100" height="60" />
    );
  }

  const thumbnail = clickableThumbnail ? (
    <a href={`${opts.baseUrl}${data.id}/`} target="_blank" rel="noopener noreferrer">
      {thumbnailImg}
    </a>
  ) : thumbnailImg;

  let innerContent = processing ? (
    <div style={{ position: 'relative', height: 60, width: '100%' }}>
      <div className="loading-spinner" />
    </div>
  ) : (
    <>
      <Message type="error" clearable={false} text={`Error retrieving ${opts.label} details.`} />
      {children}
    </>
  );

  if (data.hasOwnProperty('id')) {
    innerContent = (
      <>
        <div className="attached-item-preview-icon">
          {opts.renderIcon()}
        </div>

        <div className="attached-item-preview-thumbnail">
          {thumbnail}
        </div>

        <div>
          <h5 className="mb-1 break-word">{data.name}</h5>
          <div className="text-hint lh-1 mb-1">
            {opts.label}: <a href={`${opts.baseUrl}${data.id}/`} target="_blank" rel="noopener noreferrer">{data.id}</a>
          </div>
        </div>
        {children}
      </>
    );
  }

  return (
    <div className={classNames('attached-item-preview', className)} style={style}>
      {innerContent}
    </div>
  );
};

AttachedItemPreview.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
  itemType: PropTypes.oneOf([ITEM_TYPE_MEDIA, ITEM_TYPE_TITLE]),
  itemId: PropTypes.string,
  clickableThumbnail: PropTypes.bool,
  lazyThumbnail: PropTypes.bool,
  onLoad: PropTypes.func,
  children: PropTypes.node,
};

export default AttachedItemPreview;
