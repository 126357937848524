import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/views/CustomTitleBuilder/CustomTitleField/FieldWrapper';
import LibraryMediaField from 'components/common/LibraryMediaField';
import QuillEditor from 'components/common/QuillEditor';


const RelatedLinkForm = ({
  prefix,
  errors,
  filePreviews,
  isBound,
  name,
  url,
  description,
  image,
  onLabelChange,
  onClose,
}) => {
  const handleFormClose = e => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  const handleAddAnother = e => {
    e.preventDefault();
    e.stopPropagation();
    onClose({ addAnother: true });
  };

  const errorsForField = fieldName => (errors[fieldName] || []).map(err => err.message);

  return (
    <div className="custom-title-builder-form">
      <FieldWrapper name={`${prefix}-name`} label="Name" errors={errorsForField('name')}>
        <input
          type="text"
          className="form-input"
          name={`${prefix}-name`}
          defaultValue={name || ''}
          onChange={e => onLabelChange(e.target.value)}
        />
      </FieldWrapper>

      <FieldWrapper name={`${prefix}-url`} label="URL" errors={errorsForField('url')}>
        <input
          type="text"
          className="form-input"
          name={`${prefix}-url`}
          defaultValue={url || ''}
        />
      </FieldWrapper>

      <FieldWrapper name={`${prefix}-description`} label="Description" errors={errorsForField('description')}>
        <QuillEditor inputName={`${prefix}-description`} value={description || ''} />
      </FieldWrapper>

      <FieldWrapper name={`${prefix}-image`} label="Image" errors={errorsForField('image')}>
        <LibraryMediaField
          inputName={`${prefix}-image`}
          buttonText="Choose Image"
          limitTypes={['image']}
          value={image || ''}
        />
      </FieldWrapper>

      <hr />
      <div>
        <button type="submit" className="btn btn-primary mr-2" onClick={handleFormClose}>Close</button>
        <button type="submit" className="btn btn-primary" onClick={handleAddAnother}>Add Another</button>
      </div>
    </div>
  );
};

RelatedLinkForm.propTypes = {
  prefix: PropTypes.string,
  errors: PropTypes.object,
  filePreviews: PropTypes.object,
  isBound: PropTypes.bool,
  name: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  onLabelChange: PropTypes.func,
  onClose: PropTypes.func,
};

export default RelatedLinkForm;
