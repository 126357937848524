import React from 'react';
import PropTypes from 'prop-types';


const ChoiceFilter = ({ param, choices, activeValue, onChange }) => [
  <label key="all" className="form-radio">
    <input
      type="radio"
      name={param}
      checked={!activeValue}
      onChange={() => onChange(param, null)}
    />
    <i className="form-icon" /> All
  </label>,
  ...choices.map(([value, label]) => (
    <label key={value} className={`form-radio ${activeValue === value ? 'active' : ''}`}>
      <input
        type="radio"
        name={param}
        checked={activeValue === value}
        onChange={() => onChange(param, value)}
      />
      <i className="form-icon" /> {label}
    </label>
  )),
];

ChoiceFilter.propTypes = {
  param: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.array),
  activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default ChoiceFilter;
