import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import throttle from 'lodash/throttle';
import { CUSTOM_TITLE_FIELD_PROPTYPES } from './';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import AddLibraryMedia from 'components/common/AddLibraryMedia';
import Icon from 'components/common/Icon';
import SortableItem from 'components/common/SortableItem';


class MediaRelationField extends Component {
  constructor (props) {
    super(props);
    this.state = {};
    this.handleItemAdd = this.handleItemAdd.bind(this);
    this.handleItemRemove = this.handleItemRemove.bind(this);
    this.handleItemMove = this.handleItemMove.bind(this);
    this.handleItemMove = throttle(this.handleItemMove, 50);
  }

  handleItemAdd (selection) {
    const { allowMultiple, value, onChange } = this.props;
    if (allowMultiple) {
      let newValue = value || [];
      // Disallow duplicates
      newValue = newValue.filter(uid => !selection.includes(uid));
      newValue = [...newValue, ...selection];
      onChange(newValue);
    } else {
      onChange(selection);
    }
  }

  handleItemRemove (uid) {
    const { value, onChange } = this.props;
    const newValue = value.filter(val => val !== uid);
    onChange(newValue);
  }

  handleItemMove (sourceId, targetId, insertPos) {
    const { value, onChange } = this.props;
    const toIndex = insertPos === 'before' ? value.indexOf(targetId) : value.indexOf(targetId) + 1;
    const newValue = value.filter(uid => uid !== sourceId);
    newValue.splice(toIndex, 0, sourceId);
    onChange(newValue);
  }

  getObjectListOptions () {
    const { limitTypes, allowMultiple } = this.props;
    const objectListOptions = { allowMultiple };
    if (limitTypes.length > 1) {
      // Pre-filter the object list to display only allowed types
      objectListOptions.preFilter = {
        type__in: limitTypes.join(','),
      };
    } else if (limitTypes.length === 1) {
      // If only one type is allowed, disable the 'type' filter control
      objectListOptions.preFilter = {
        type: limitTypes[0],
      };
      objectListOptions.disabledFilters = ['type'];
    }
    return objectListOptions;
  }

  renderSingleWidget () {
    const { value, onChange, onPreviewLoad } = this.props;
    return value ? (
      <AttachedItemPreview itemType="media" itemId={value} clickableThumbnail onLoad={onPreviewLoad}>
        <div className="btn-delete tooltip tooltip-left" data-tooltip="Remove Media" onClick={() => onChange(null)}>
          <Icon name="delete" fill />
        </div>
      </AttachedItemPreview>
    ) : (
      <AddLibraryMedia onChange={this.handleItemAdd} {...this.getObjectListOptions()}>
        {handleModalTrigger => (
          <div className="attached-item-preview">
            <button type="button" className="btn btn-primary" onClick={e => { e.preventDefault(); handleModalTrigger(); }}>Choose Media</button>
          </div>
        )}
      </AddLibraryMedia>
    );
  }

  renderMultiWidget () {
    let { name, value, errors } = this.props;
    value = value || [];
    return (
      <div className={`relation-field mt-1 ${errors.length ? 'has-error' : ''}`}>
        {errors.length ? <div className="error-list">Some of the items below contain errors.</div> : null}
        <div className="item-list">
          {value.map(uid => (
            <SortableItem key={uid} itemId={uid} type={name} className="mb-4" onItemMove={this.handleItemMove}>
              <AttachedItemPreview itemType="media" itemId={uid} className="m-0" clickableThumbnail>
                <div className="btn-delete tooltip tooltip-left" data-tooltip="Remove Media" onClick={() => this.handleItemRemove(uid)}>
                  <Icon name="delete" fill />
                </div>
                <div className="btn-move" title="Drag to sort">
                  <Icon name="menu" />
                </div>
              </AttachedItemPreview>
            </SortableItem>
          ))}
        </div>
        <div className="controls">
          <div className="item-count">{`${value.length} Item${value.length === 1 ? '' : 's'}`}</div>
          <AddLibraryMedia onChange={this.handleItemAdd} {...this.getObjectListOptions()}>
            {handleModalTrigger => (
              <button type="button" className="btn btn-primary" onClick={e => { e.preventDefault(); handleModalTrigger(); }}>Add Media</button>
            )}
          </AddLibraryMedia>
        </div>
      </div>
    );
  }

  render () {
    const { className, name, label, helpText, allowMultiple, errors } = this.props;
    const displayErrors = !allowMultiple && errors.length;
    return (
      <div className={classNames('form-group', className || 'mb-4')}>
        <label htmlFor={name} className="block-label">{label}</label>
        {helpText ? <p className="form-input-hint m-0">{helpText}</p> : null}
        <div className="mt-1">
          {allowMultiple ? this.renderMultiWidget() : this.renderSingleWidget()}
        </div>
        {displayErrors ? errors.map((message, idx) => <p key={idx} className="form-input-hint text-error mb-0">{message}</p>) : null}
      </div>
    );
  }
}

MediaRelationField.propTypes = {
  ...CUSTOM_TITLE_FIELD_PROPTYPES,
  onPreviewLoad: PropTypes.func,
};

MediaRelationField.defaultProps = {
  onChange: () => null,
  onPreviewLoad: () => null,
  errors: [],
  limitTypes: [],
};

export default MediaRelationField;
