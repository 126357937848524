import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';

export const MODE_LIST = 'list';
export const MODE_GRID = 'grid';
export const DEFAULT_MODE = MODE_LIST;
export const VIEW_MODES = [MODE_LIST, MODE_GRID];

const DisplayOptions = ({ viewMode, onChange }) => {
  const handleListViewClick = e => {
    e.preventDefault();
    onChange(MODE_LIST);
  };

  const handleGridViewClick = e => {
    e.preventDefault();
    onChange(MODE_GRID);
  };

  return (
    <div className="slideshow-builder-display-options">
      {viewMode === MODE_LIST
        ? <a href="#" onClick={handleGridViewClick} className="tooltip tooltip-bottom td-none" data-tooltip="Grid View"><Icon name="grid_on" fill /></a>
        : <span><Icon name="grid_on" fill /></span>}
      {viewMode === MODE_GRID
        ? <a href="#" onClick={handleListViewClick} className="tooltip tooltip-bottom td-none" data-tooltip="List View"><Icon name="view_list" fill /></a>
        : <span><Icon name="view_list" fill /></span>}
    </div>
  );
};

DisplayOptions.propTypes = {
  viewMode: PropTypes.oneOf(VIEW_MODES),
  onChange: PropTypes.func,
};

export default DisplayOptions;
