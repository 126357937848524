import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
// import debounce from 'lodash/debounce';
import { v4 as uuid4 } from 'uuid';
import camelize from 'camelize';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Message from 'components/common/Message';
import SortableItem from 'components/common/SortableItem';
import StyledScrollbar from 'components/common/StyledScrollbar';
import { ManagementForm } from 'components/common/inlines';
import AddLibraryMedia from 'components/common/AddLibraryMedia';
import AddTitleAttachment from 'components/common/AddTitleAttachment';
import withDragDropContext from 'components/common/withDragDropContext';
import ReleaseItemSettingsForm from 'components/views/ReleaseItemSettingsForm';
import { TYPE_PLAYER_RELEASE, getDisplayOptionsConfig } from 'components/common/DisplayOptions';
import formDefaults from './formDefaults';
import Item from './Item';


const PlayerBuilder = ({
  formsetData,
  parentId,
  hasError,
}) => {
  const nextItemIndex = useRef(0);

  const [itemsById, setItemsById] = useState({});
  const [itemsOrdered, setItemsOrdered] = useState([]);
  const [itemDetails, setItemDetails] = useState({});
  const [activeItemId, setActiveItemId] = useState(null);
  const [messageKey, setMessageKey] = useState(null);
  const [messageText, setMessageText] = useState('');

  const initNewItems = itemObjects => {
    const { prefix } = formsetData;
    const newItems = {};
    const newItemIds = [];

    itemObjects.forEach(itemObj => {
      const itemId = uuid4();
      const item = {
        itemId,
        parent: parentId,
        itemIndex: nextItemIndex.current,
        prefix: `${prefix}-${nextItemIndex.current}`,
        errors: {},
        ...itemObj,
      };
      newItems[itemId] = item;
      newItemIds.push(itemId);
      nextItemIndex.current += 1;
    });

    setItemsById(oldState => ({ ...oldState, ...newItems }));
    setItemsOrdered(oldState => [...newItemIds, ...oldState]);
    return newItems;
  };

  const initItems = () => {
    // Hydrate item list from initial formset data
    const { forms, prefix } = camelize(formsetData);
    const itemsById = {};
    const itemsOrdered = [];
    forms.forEach(form => {
      const itemId = uuid4();
      const fields = Object.entries(form.fields).reduce((result, [key, { value }]) => {
        if (key === 'DELETE') {
          result.shouldDelete = !!value;
        } else if (key === 'displayOptions') {
          result[key] = JSON.parse(value);
        } else {
          result[key] = value;
        }
        return result;
      }, {});

      const item = {
        itemId,
        itemIndex: nextItemIndex.current,
        prefix: `${prefix}-${nextItemIndex.current}`,
        errors: form.errors,
        ...fields,
      };

      itemsById[itemId] = item;
      itemsOrdered.push(itemId);
      nextItemIndex.current += 1;
    });
    setItemsById(itemsById);
    setItemsOrdered(itemsOrdered);
  };

  useEffect(initItems, []);

  const activeItem = itemsById[activeItemId];

  const updateItem = (itemId, attrs) => {
    setItemsById(oldState => ({
      ...oldState,
      [itemId]: {
        ...oldState[itemId],
        ...attrs,
      },
    }));
  };

  useEffect(() => {
    if ((activeItem || {}).shouldDelete) {
      setActiveItemId(null);
    }
  }, [JSON.stringify(activeItem)]);

  const addItemsMessage = count => {
    setMessageKey(uuid4());
    setMessageText(count === 1 ? '1 item was added.' : `${count} items were added.`);
  };

  const handleItemAdd = (itemType, itemIds, message = true) => {
    const newItems = itemIds.map(id => ({ ...formDefaults, type: itemType, [itemType]: id }));
    initNewItems(newItems);

    const count = itemIds.length;
    if (message) addItemsMessage(count);
  };

  const handleItemSettingsClick = itemId => {
    const item = itemsById[itemId];
    if (item && item.shouldDelete) return;
    setActiveItemId(itemId === activeItemId ? null : itemId);
  };

  const handleItemDeleteClick = itemId => {
    const shouldDelete = !itemsById[itemId].shouldDelete;
    updateItem(itemId, { shouldDelete });
  };

  const handleSettingsFormChange = fields => {
    updateItem(activeItemId, fields);
  };

  const handleItemMove = (sourceId, targetId, insertPos) => {
    const toIndex = itemsOrdered.indexOf(targetId) + (insertPos === 'before' ? 0 : 1);
    const newValue = itemsOrdered.filter(uid => uid !== sourceId);
    newValue.splice(toIndex, 0, sourceId);
    setItemsOrdered(newValue);
  };

  const handleItemPreviewLoad = (itemId, obj) => {
    const item = itemsById[itemId];
    cachePreviewData(itemId, obj);

    const displayOptions = {
      ...getDisplayOptionsConfig(TYPE_PLAYER_RELEASE, obj.type).defaultValues,
      ...(item.displayOptions || {}),
    };

    updateItem(itemId, {
      slug: item.slug || obj.slug,
      linkText: item.linkText || obj.name,
      displayOptions,
    });
  };

  const cachePreviewData = (itemId, obj) => {
    setItemDetails(oldState => ({
      ...oldState,
      [itemId]: {
        label: obj.name,
        type: obj.type,
        id: obj.id,
        requires: obj.requires,
      },
    }));
  };

  const [coverWarnings, setCoverWarnings] = useState([]);
  const checkCoverItems = () => {
    const msgs = [];

    const linkedObjectIds = Object.values(itemsById).reduce((result, { media, title }) => {
      if (media) result.push(media);
      if (title) result.push(title);
      return result;
    }, []);

    Object.entries(itemDetails).filter(([uid, { requires, id }], idx, arr) => {
      const isFirst = idx === arr.findIndex(([_, item]) => item.id === id);
      const markedForDeletion = itemsById[uid].shouldDelete;
      return isFirst && !markedForDeletion && requires && requires.length > 0;
    }).forEach(([uid, { id, type, label, requires }]) => {
      const missingItems = requires.filter(([_, uid]) => !linkedObjectIds.includes(uid));
      const missingIds = missingItems.map(([_, uid]) => uid);

      const handleAddClick = evt => {
        evt.preventDefault();
        missingItems.forEach(([type, uid]) => handleItemAdd(type, [uid], false));
        addItemsMessage(missingItems.length);
      };

      if (missingIds.length > 0) {
        const msg = <span>{type} {type !== 'Media' && 'Title '}<strong>{id}</strong> (&quot;{label}&quot;) contains links to Media or Title objects that do not appear in this release. <a href="#" onClick={handleAddClick}>Add now &raquo;</a></span>;
        msgs.push(msg);
      }
    });

    setCoverWarnings(msgs);
  };

  useEffect(() => {
    checkCoverItems();
  }, [itemDetails, itemsById]);

  const renderedItems = itemsOrdered.map((itemId, idx) => {
    const item = itemsById[itemId];
    return (
      <SortableItem
        key={itemId}
        itemId={itemId}
        type="title-builder-items"
        onItemMove={handleItemMove}
      >
        <Item
          key={itemId}
          counter={idx + 1}
          settingsActive={activeItemId === itemId}
          errors={item.errors}
          onSettingsClick={() => handleItemSettingsClick(itemId)}
          onDeleteClick={() => handleItemDeleteClick(itemId)}
          onLoadPreview={obj => handleItemPreviewLoad(itemId, obj)}
          {...item}
        />
      </SortableItem>
    );
  });

  const { prefix, managementForm } = camelize(formsetData);
  const formCount = itemsOrdered.length;
  const { slug, linkText, displayOptions } = activeItem || {};
  const { label: activeItemLabel, type: activeItemType } = itemDetails[activeItemId] || {};

  return (
    <div className="title-builder player-builder object-detail-panel-full-height">
      <ErrorBoundary>
        <ManagementForm prefix={prefix} formData={managementForm} totalForms={formCount} />
        <div className="title-builder-items-container">
          <StyledScrollbar>
            {hasError && <Message type="error" text="Some items contain errors. Correct the errors below before saving." />}
            {coverWarnings.map((msg, idx) => <Message key={idx} type="error">{msg}</Message>)}
            {!!messageKey && <Message key={messageKey} type="info" timeout={5} text={messageText} />}
            <div className="title-builder-item disabled" style={{ justifyContent: 'center' }}>
              <AddLibraryMedia allowMultiple onChange={ids => handleItemAdd('media', ids)}>
                {handleModalTrigger => (
                  <button type="button" className="btn btn-primary" onClick={handleModalTrigger}>Add Media</button>
                )}
              </AddLibraryMedia>
              <span style={{ margin: '0 15px' }}>– or –</span>
              <AddTitleAttachment allowMultiple onChange={ids => handleItemAdd('title', ids)}>
                {handleModalTrigger => (
                  <button type="button" className="btn btn-primary" onClick={handleModalTrigger}>Add Titles</button>
                )}
              </AddTitleAttachment>
            </div>
            <div className="player-builder-items">
              {renderedItems}
            </div>
          </StyledScrollbar>
        </div>
        {activeItemId && activeItemType && (
          <ReleaseItemSettingsForm
            formTitle={activeItemLabel}
            releaseType={TYPE_PLAYER_RELEASE}
            itemType={activeItemType}
            initialValues={{ slug, linkText, displayOptions }}
            onChange={handleSettingsFormChange}
          />
        )}
      </ErrorBoundary>
    </div>
  );
};

PlayerBuilder.propTypes = {
  formsetData: PropTypes.object,
  parentId: PropTypes.string,
  hasError: PropTypes.bool,
};

export default withDragDropContext(PlayerBuilder);
