import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import withDragDropContext from 'components/common/withDragDropContext';
import { useSortableItems } from 'hooks';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Modal from 'components/common/Modal';
import SortableItem from 'components/common/SortableItem';
import Item from './Item';


const RedirectsWidget = ({
  isEnabled = true,
  initialValue = [],
  errors = {},
  onChange = () => {},
}) => {
  const { itemsOrdered, setItemsOrdered, handleItemMove } = useSortableItems();
  const [itemsById, setItemsById] = useState({});
  const [errorsById, setErrorsById] = useState({});

  useEffect(() => {
    const { ordered, byId, errById } = initialValue.reduce((result, [src, dest], idx) => {
      const id = uuid4();
      result.byId[id] = { src, dest };
      result.ordered.push(id);

      if (errors[idx]) {
        result.errById[id] = errors[idx];
      }

      return result;
    }, { ordered: [], byId: {}, errById: {} });

    setItemsOrdered(ordered);
    setItemsById(byId);
    setErrorsById(errById);
  }, []);

  const [textVal, setTextVal] = useState('');
  const [bulkInputModalIsOpen, setBulkInputModalIsOpen] = useState(false);

  const itemsFromText = () => {
    const lines = textVal.split(/\r?\n/).map(s => s.trim()).filter(s => !!s);

    const { ordered, byId } = lines.reduce((result, line) => {
      const parts = line.split(/\s+/);
      const dest = parts.pop();
      const src = parts.join(' ');

      const id = uuid4();
      result.byId[id] = { src, dest };
      result.ordered.push(id);
      return result;
    }, { ordered: [], byId: {} });

    setItemsOrdered(ordered);
    setItemsById(byId);
  };

  const textFromItems = () => {
    const text = itemsOrdered.map(id => {
      const { src, dest } = itemsById[id];
      return `${src} ${dest}`;
    }).join('\n');

    setTextVal(text);
  };

  const handleBulkInputClick = evt => {
    evt.preventDefault();
    textFromItems();
    setBulkInputModalIsOpen(true);
  };

  const handleBulkInputAccept = () => {
    itemsFromText();
    setTextVal('');
    setBulkInputModalIsOpen(false);
  };

  const handleBulkInputCancel = () => {
    setTextVal('');
    setBulkInputModalIsOpen(false);
  };


  const handleAddItemClick = () => {
    const id = uuid4();
    setItemsById(oldState => ({ ...oldState, [id]: { src: '', dest: '' } }));
    setItemsOrdered(oldState => [...oldState, id]);
  };

  useEffect(() => {
    const value = itemsOrdered.map(id => {
      const { src, dest } = itemsById[id];
      return [src, dest];
    });
    onChange(value);
  }, [JSON.stringify({ itemsOrdered, itemsById })]);

  const hasError = Object.keys(errors).length > 0;
  const containerStyles = isEnabled ? {} : { pointerEvents: 'none', userSelect: 'none' };
  const itemCountLabel = itemsOrdered.length === 1 ? 'Item' : 'Items';

  return (
    <div className="object-detail-container h-100">
      <div className="redirects-widget object-detail-content h-100">
        <div className="object-detail-main">
          {!isEnabled && (
            <div className="error-list mb-4">
              Redirects are only supported for releases published to Netlify destinations. Select a Netlify publish destination in the “Publishing” panel to enable redirect configuration.
            </div>
          )}

          <div className="nav-builder-items-wrapper" style={containerStyles}>
            <div className="form-group m-0">
              <h5 className="m-0">Redirects</h5>
              <div className="d-flex flex-spread mb-2">
                <div className="text-hint">Configure URL redirects for this release.</div>
                <div className="text-hint">
                  <a href="#bulk-input" onClick={handleBulkInputClick}>Bulk input</a>
                </div>
              </div>
            </div>
            <ErrorBoundary>
              <div className="relation-field mt-1">
                <div className="controls top">
                  <div className="item-count">{itemsOrdered.length} {itemCountLabel}</div>
                  <button type="button" className="btn btn-primary" onClick={handleAddItemClick}>Add Redirect</button>
                </div>
                {hasError && <div className="error-list">Some of the items below contain errors.</div>}
                <div className="item-list">
                  {itemsOrdered.map((id, idx) => {
                    const { src, dest } = itemsById[id];
                    const itemErrors = errorsById[id];
                    const handleChange = val => setItemsById(oldState => ({ ...oldState, [id]: val }));
                    const handleDelete = () => {
                      setItemsOrdered(oldState => oldState.filter(itemId => itemId !== id));
                      setItemsById(oldState => Object.entries(oldState).reduce((result, [key, val]) => {
                        if (key !== id) result[key] = val;
                        return result;
                      }, {}));
                    };
                    return (
                      <SortableItem
                        key={id}
                        itemId={id}
                        type="redirect-widget-items"
                        className="mb-4"
                        onItemMove={handleItemMove}
                      >
                        <Item
                          src={src}
                          dest={dest}
                          errors={itemErrors}
                          onChange={handleChange}
                          onDeleteClick={handleDelete}
                        />
                      </SortableItem>
                    );
                  })}
                </div>
              </div>

              <Modal
                title="Bulk input redirects"
                style={{ content: { width: 700 } }}
                isOpen={bulkInputModalIsOpen}
                onRequestClose={handleBulkInputCancel}
              >
                <div className="form-group">
                  <div className="text-hint mb-1">
                    Enter redirect values one per line, with a space or tab separating the “from” and “to” components.
                  </div>
                  <textarea
                    className="form-input"
                    rows={10}
                    value={textVal}
                    onChange={evt => setTextVal(evt.target.value)}
                  />
                </div>
                <div className="modal-action-buttons">
                  <button className="btn" type="button" onClick={handleBulkInputCancel}>Cancel</button>
                  <button className="btn btn-primary" type="button" onClick={handleBulkInputAccept}>Accept</button>
                </div>
              </Modal>
            </ErrorBoundary>

            {!isEnabled && <div className="title-builder-disabled-overlay" />}
          </div>
        </div>

        <div className="object-detail-sidebar">
          <div
            className="title-builder-form-container"
            style={{ position: 'relative', ...containerStyles }}
          >
            <header className="title-builder-form-header">
              <div>
                <h5><span>About Redirects</span></h5>
              </div>
            </header>
            <div className="text-hint">
              <p>To configure a redirect, enter the source path in the “From” field and destination path in the “To” field. Source paths should begin with a slash character. Destination paths should begin with a slash (for redirects to other pages within the release) or with “http://” or “https://” (for redirects to external sites).</p>
              <p>An asterisk at the end of a source path indicates a wildcard and will match anyting that follows it.</p>
              <p>For more information about redirect options and syntax, please refer to the <a href="https://docs.netlify.com/routing/redirects/redirect-options/" target="_blank" rel="noopener noreferrer">Netlify documentation</a>.</p>
            </div>
            {!isEnabled && <div className="title-builder-disabled-overlay" />}
          </div>
        </div>
      </div>
    </div>
  );
};

RedirectsWidget.propTypes = {
  isEnabled: PropTypes.bool,
  initialValue: PropTypes.arrayOf(PropTypes.array),
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default withDragDropContext(RedirectsWidget);
