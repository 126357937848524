import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import striptags from 'striptags';
import renderBlockErrors from './renderBlockErrors';
import QuillEditor from 'components/common/QuillEditor';


const Text = ({ uid, errors, content, onPreviewTextUpdate, onChange }) => {
  const handleContentChange = content => onChange({ content });

  useEffect(() => {
    onPreviewTextUpdate(striptags(content || ''));
  }, [content]);

  const renderedErrors = renderBlockErrors(errors);

  return (
    <div className="sc-blocktype-text">
      <QuillEditor
        value={content}
        onChange={handleContentChange}
      />
      {renderedErrors.content}
    </div>
  );
};

Text.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  content: PropTypes.string,
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default Text;
