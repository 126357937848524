import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import { formatters } from 'utils';

const ItemSettingsForm = ({ formTitle, initialValues, onChange }) => {
  const [fields, setFields] = useState({});
  useEffect(() => setFields(initialValues), [initialValues]);

  const {
    slug,
    excludeFromMenu,
    countdownOverlay,
    countdownLabel,
    customOverlayText,
    skipOverlay,
    posterDuration,
  } = fields;

  const updateField = useCallback((name, value) => {
    setFields(oldState => ({ ...oldState, [name]: value }));
  }, [setFields]);

  const handleInputChange = evt => {
    let { name, value, type } = evt.target;
    if (type === 'checkbox') {
      value = fields[name] === null ? true : !fields[name];
    } else if (type === 'number') {
      value = parseFloat(value);
    } else if (name === 'slug') {
      value = formatters.slug(value);
    }
    updateField(name, value);
  };

  const handleShowPosterChange = evt => {
    updateField('posterDuration', evt.target.checked ? 3 : 0);
  };

  const [showCustomOverlayInput, setShowCustomOverlayInput] = useState(false);

  const handleShowCustomOverlayChange = evt => {
    const val = evt.target.checked;
    setShowCustomOverlayInput(val);
    if (!val) updateField('customOverlayText', '');
  };

  useEffect(() => {
    setShowCustomOverlayInput(!!initialValues.customOverlayText);
  }, []);

  const doOnChange = useCallback(debounce(fields => onChange(fields), 300), []);

  useEffect(() => doOnChange(fields), [JSON.stringify(fields)]);

  return (
    <div className="title-builder-form-container">
      <header className="title-builder-form-header">
        <div>
          <h5><span>Settings:</span> {formTitle}</h5>
        </div>
      </header>

      <form className="title-builder-form" onSubmit={e => e.preventDefault()}>
        <div className="form-group mb-3">
          <label className="block-label">URL Slug</label>
          <input
            key="slug"
            name="slug"
            type="text"
            className="form-input"
            onChange={handleInputChange}
            value={slug || ''}
          />
          <div className="text-hint mt-1">This text will appear in the URL and must contain only lowercase letters, numbers, and hyphens.</div>
        </div>

        <div className="form-group mb-0">
          <label className="form-checkbox">
            <input
              type="checkbox"
              name="excludeFromMenu"
              checked={!!excludeFromMenu}
              onChange={handleInputChange}
            />
            <i className="form-icon" /> Exclude from playlist menu
          </label>
        </div>

        <div className="form-group mb-3">
          <label className="form-checkbox">
            <input
              type="checkbox"
              checked={!!posterDuration}
              onChange={handleShowPosterChange}
            />
            <i className="form-icon" /> Display poster frame before playback begins
          </label>
          {!posterDuration && (
            <div className="text-hint mt-1">Note: There will be a momentary display of a poster frame while a clip is buffering enough to start playing.</div>
          )}

          {!!posterDuration && (
            <div>
              <label className="">Poster Frame Duration</label>
              <div className="input-group">
                <input
                  name="posterDuration"
                  type="number"
                  min={0}
                  max={30}
                  className="form-input"
                  onChange={handleInputChange}
                  value={typeof posterDuration === 'undefined' ? '' : posterDuration}
                  style={{ flex: '0 0 60px' }}
                />
                <span className="input-group-addon text-hint">seconds</span>
              </div>
            </div>
          )}
        </div>

        <hr />
        <div className="form-group mb-3">
          <label className="block-label">Overlays</label>

          <label className="form-checkbox">
            <input
              type="checkbox"
              name="countdownOverlay"
              checked={!!countdownOverlay}
              onChange={handleInputChange}
            />
            <i className="form-icon" /> Countdown timer
          </label>

          {!!countdownOverlay && (
            <div className="form-group">
              <input
                name="countdownLabel"
                type="text"
                className="form-input"
                onChange={handleInputChange}
                value={countdownLabel || ''}
              />
              <div className="text-hint mt-1">Optionally include a text label before countdown timer.</div>
            </div>
          )}

          <label className="form-checkbox">
            <input
              type="checkbox"
              name="skipOverlay"
              checked={!!skipOverlay}
              onChange={handleInputChange}
            />
            <i className="form-icon" /> Skip button
          </label>

          <label className="form-checkbox">
            <input
              type="checkbox"
              checked={showCustomOverlayInput}
              onChange={handleShowCustomOverlayChange}
            />
            <i className="form-icon" /> Custom text
          </label>

          {showCustomOverlayInput && (
            <input
              name="customOverlayText"
              type="text"
              className="form-input"
              onChange={handleInputChange}
              value={customOverlayText || ''}
            />
          )}
        </div>
      </form>
    </div>
  );
};

ItemSettingsForm.propTypes = {
  formTitle: PropTypes.string,
  initialValues: PropTypes.shape({
    excludeFromMenu: PropTypes.bool,
    countdownOverlay: PropTypes.bool,
    countdownLabel: PropTypes.string,
    customOverlayText: PropTypes.string,
    skipOverlay: PropTypes.bool,
    posterDuration: PropTypes.number,
  }),
  onChange: PropTypes.func,
};

export default ItemSettingsForm;
