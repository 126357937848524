import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useCSRF } from 'hooks';
import { urls } from 'app-constants';
import Modal from './Modal';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';

const ReleaseBulkPublish = ({ ids = [] }) => {
  const csrfToken = useCSRF();
  const [modalVisible, setModalVisible] = useState(false);

  const handleLinkClick = e => {
    e.preventDefault();
    setModalVisible(true);
  };

  const handleModalRequestClose = () => setModalVisible(false);

  const handleCancelClick = e => {
    e.preventDefault();
    handleModalRequestClose();
  };

  return (
    <ErrorBoundary>
      <a href="#publish" onClick={handleLinkClick}>
        <Icon name="upload" className="mr-2" />
        <span>Publish</span>
      </a>
      <Modal
        title="Are You Sure?"
        style={{ content: { width: 660 } }}
        isOpen={modalVisible}
        onRequestClose={handleModalRequestClose}
      >
        <form action={urls.releaseBulkPublish} method="post">
          <p>{ids.length} item{ids.length === 1 ? '' : 's'} will be queued for publication.</p>
          <div className="modal-action-buttons">
            <button className="btn" onClick={handleCancelClick}>Cancel</button>
            <button className="btn btn-primary" type="submit">Publish</button>
          </div>
          <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
          {ids.map(id => <input key={id} type="hidden" name="ids" value={id} />)}
        </form>
      </Modal>
    </ErrorBoundary>
  );
};

ReleaseBulkPublish.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
};

export default ReleaseBulkPublish;
