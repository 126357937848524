import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'app-constants';
import ReactSelect, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';


export const getControlStyles = (state, hasError = false) => ({
  borderRadius: 0,
  borderColor: hasError ? colors.error : (state.isFocused ? colors.bluePowder : colors.borderDark),
  boxShadow: state.isFocused ? '0 0 0 0.1rem rgba(103, 163, 203, 0.2)' : 'none',
  '&:hover': {
    borderColor: state.isFocused ? colors.bluePowder : colors.borderDark,
  },
});

export const getOptionStyles = state => ({
  backgroundColor: 'transparent',
  color: state.isSelected ? colors.bluePowder : 'inherit',
  '&:hover': {
    backgroundColor: colors.blueExtraLight,
  },
});

export const getMenuStyles = state => ({ zIndex: 5 });

const { Option } = components;

const OptionWithHelpText = props => {
  const { label, helpText } = props.data;
  if (helpText) {
    return (
      <Option {...props}>
        <div>{label}</div>
        <div className="text-hint">{helpText}</div>
      </Option>
    );
  } else {
    return <Option {...props} />;
  }
};

const Select = ({ isCreatable, isMulti, name, options, value, onChange, components, styles, hasError, ...rest }) => {
  const handleChange = isMulti ? data => onChange((data || []).map(({ value }) => value)) : data => onChange(data.value);

  const baseStyles = {
    control: (provided, state) => ({
      ...provided,
      ...getControlStyles(state, hasError),
    }),
    option: (provided, state) => ({
      ...provided,
      ...getOptionStyles(state),
    }),
    menu: (provided, state) => ({
      ...provided,
      ...getMenuStyles(state),
    }),
  };

  const Component = isCreatable ? CreatableSelect : ReactSelect;

  components = components || { Option: OptionWithHelpText };

  return (
    <Component
      isMulti={isMulti}
      closeMenuOnSelect={!isMulti}
      name={name}
      options={options}
      value={value}
      styles={{ ...baseStyles, ...styles }}
      onChange={handleChange}
      components={components}
      classNamePrefix="react-select"
      {...rest}
    />
  );
};

Select.propTypes = {
  isCreatable: PropTypes.bool,
  isMulti: PropTypes.bool,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any,
    label: PropTypes.string,
  })),
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    }),
    PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
    })),
  ]),
  onChange: PropTypes.func,
  components: PropTypes.object,
  styles: PropTypes.object,
  hasError: PropTypes.bool,
};

Select.defaultProps = {
  onChange: () => null,
  styles: {},
};

export default Select;
