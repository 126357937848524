import { customTitleFieldTypes as ft, previewImageVersions } from 'app-constants';
import { TYPE_BLOCK, TYPE_PLAYER_RELEASE, TYPE_SITE_RELEASE } from './objectTypes';

const fields = {
  menuDefaultState: {
    name: 'menu_default_state',
    type: ft.choice,
    radio: true,
    choices: [
      ['open', 'Open'],
      ['closed', 'Closed'],
    ],
    label: 'Default menu state',
    helpText: 'Choose whether the menu is open or closed by default when this item is loaded.',
    defaultValue: 'closed',
  },
  hideMenu: {
    name: 'hide_menu',
    type: ft.bool,
    label: 'Hide menu button',
    helpText: 'Select this option to hide the menu button when displaying this title.',
    defaultValue: false,
  },
  hideControlBar: {
    name: 'hide_control_bar',
    type: ft.bool,
    label: 'Hide control bar',
    helpText: 'Select this option to hide the bottom control bar when displaying this title.',
    defaultValue: false,
  },
  pipEnabled: {
    name: 'pip_enabled',
    type: ft.bool,
    label: 'Allow PIP',
    helpText: 'Select this option to allow picture-in-picture playback.',
    defaultValue: false,
  },
  onEnd: {
    name: 'on_end',
    type: ft.choice,
    radio: true,
    choices: [
      ['poster', 'Display poster frame'],
      ['share', 'Display sharing options'],
    ],
    label: 'On end behavior',
    helpText: 'Choose an action to trigger when playback completes.',
    defaultValue: 'poster',
  },
  imageLayout: {
    name: 'image_layout',
    type: ft.choice,
    radio: true,
    choices: [
      ['', 'Default', '– image fits within the display area, preserving original crop'],
      ['bleed', 'Full bleed', '– image covers the display area; cropping may occur'],
    ],
    label: 'Poster frame style',
    helpText: 'Choose a display style for this item’s poster frame image.',
    defaultValue: '',
  },
  playlistDefaultState: {
    name: 'playlist_default_state',
    type: ft.choice,
    radio: true,
    choices: [
      ['open', 'Open'],
      ['closed', 'Closed'],
    ],
    label: 'Default playlist state',
    helpText: 'Choose whether the playlist drawer is open or closed by default when this item is loaded.',
    defaultValue: 'open',
  },
  playlistShowThumbnails: {
    name: 'show_thumbnails',
    type: ft.bool,
    label: 'Show thumbnails',
    helpText: 'Select this option to display thumbnail images in the playlist sidebar.',
    defaultValue: true,
  },
  playlistOnEnd: {
    name: 'on_end',
    type: ft.choice,
    radio: true,
    choices: [
      ['poster', 'Display poster frame'],
      ['share', 'Display sharing options'],
      ['next', 'Navigate to the next item in the release'],
    ],
    label: 'On end behavior',
    helpText: 'Choose an action to trigger when the playlist completes.',
    defaultValue: 'poster',
  },
  slideshowMenuDefaultPanel: {
    name: 'menu_default_panel',
    type: ft.choice,
    radio: true,
    choices: [
      ['caption', 'Caption'],
      ['location', 'Location'],
      ['related', 'Related'],
    ],
    label: 'Default menu panel',
    helpText: 'Choose which menu panel is active by default.',
    defaultValue: 'caption',
  },
  slideshowThumbnailSource: {
    name: 'thumbnail_source',
    type: ft.choice,
    radio: false,
    choices: [
      ['', 'Original'],
      ...previewImageVersions.map(({ slug, label }) => [slug, label]),
    ],
    label: 'Thumbnail image source',
    helpText: 'Choose which image version to use for slide thumbnails.',
    defaultValue: '',
  },
  slideshowShowThumbnailsOnLoad: {
    name: 'show_thumbnails_on_load',
    type: ft.bool,
    label: 'Display thumbnails on load',
    helpText: 'Select this option to display a grid of thumbnail images when the Slideshow first loads.',
    defaultValue: false,
  },
};

export default function getDisplayOptionsConfig (parentType, itemType) {
  itemType = itemType.toLowerCase();
  const releaseOnly = val => [TYPE_PLAYER_RELEASE, TYPE_SITE_RELEASE].includes(parentType) ? [val] : [];
  const blockOnly = val => parentType === TYPE_BLOCK ? [val] : [];

  const fieldConfig = [
    { ...fields.menuDefaultState, defaultValue: ['pin map', 'slideshow'].includes(itemType) ? 'open' : 'closed' },
    ...releaseOnly(fields.hideMenu),
    ...(() => {
      switch (itemType) {
        case 'cover':
          return [fields.hideControlBar];
        case 'playlist':
          return [
            fields.playlistDefaultState,
            fields.playlistShowThumbnails,
            ...releaseOnly(fields.pipEnabled),
            ...releaseOnly(fields.playlistOnEnd),
          ];
        case 'video':
          // fall through
        case 'audio':
          return [
            fields.pipEnabled,
            fields.onEnd,
            fields.imageLayout,
          ];
        case 'image':
          return [fields.imageLayout];
        case 'slideshow':
          return [
            fields.slideshowMenuDefaultPanel,
            fields.slideshowThumbnailSource,
            fields.slideshowShowThumbnailsOnLoad,
          ];
        default:
          return [];
      }
    })(),
  ];

  const menuItemConfig = [
    { slug: 'item_name', label: 'Name', iconName: 'title', defaultValue: true },
    { slug: 'item_short_description', label: 'Short Description', iconName: 'subject', defaultValue: true },
    { slug: 'item_long_description', label: 'Long Description', iconName: 'subject', defaultValue: false },
    ...(() => {
      return itemType !== 'contributors' ? blockOnly({ slug: 'credits', label: 'Credits', iconName: 'group', defaultValue: false }) : [];
    })(),
    ...(() => {
      switch (itemType) {
        case 'playlist':
          // fall through
        case 'video':
          return blockOnly({ slug: 'transcript', label: 'Transcript', iconName: 'text_snippet', defaultValue: false });
        case 'slideshow':
          return [
            { slug: 'name', label: 'Slide Name', iconName: 'title', defaultValue: true },
            { slug: 'date', label: 'Date', iconName: 'calendar_month', defaultValue: true },
            { slug: 'caption', label: 'Caption', iconName: 'subject', defaultValue: true },
            { slug: 'purchase_links', label: 'License & Prints Links', iconName: 'paid', defaultValue: true },
            { slug: 'location', label: 'Location', iconName: 'location_on', defaultValue: true },
            { slug: 'related', label: 'Related', iconName: 'language', defaultValue: true },
          ];
        case 'pin map':
          return [
            { slug: 'items_list', label: 'Map Items List', iconName: 'list', defaultValue: true },
            { slug: 'legend', label: 'Legend', iconName: 'map', defaultValue: true },
          ];
        default:
          return [];
      }
    })(),
  ];

  const defaultValues = {
    ...fieldConfig.reduce((result, { name, defaultValue }) => ({ ...result, [name]: defaultValue }), {}),
    menu_items: menuItemConfig.reduce((result, { slug, defaultValue }) => ({ ...result, [slug]: defaultValue }), {}),
  };

  return {
    fields: fieldConfig,
    menuItems: menuItemConfig,
    defaultValues,
  };
}
