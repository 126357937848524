import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import withCSRF from 'components/common/withCSRF';
import Modal from 'components/common/Modal';
import ErrorBoundary from 'components/common/ErrorBoundary';
import TypePicker from './TypePicker';
import { OBJECT_TYPE_TITLE, OBJECT_TYPE_RELEASE, OBJECT_TYPE_METADATA_PRESET } from './objectTypes';

const PICKER_TYPES = [OBJECT_TYPE_TITLE, OBJECT_TYPE_RELEASE];

class ObjectCreate extends Component {
  constructor (props) {
    super(props);
    this.defaultState = {
      modalVisible: false,
      name: '',
      objectType: null,
      validationErrors: null,
      error: null,
      processing: false,
      createdObject: null,
    };
    this.state = {
      ...this.defaultState,
    };

    // Configure API endpoints according to object type
    switch (props.objectBaseType) {
      case OBJECT_TYPE_TITLE:
        this.baseUrl = urls.titleBase;
        this.createUrl = urls.titleCreate;
        this.baseTypeLabel = 'Title';
        break;
      case OBJECT_TYPE_RELEASE:
        this.baseUrl = urls.releaseBase;
        this.createUrl = urls.releaseCreate;
        this.baseTypeLabel = 'Release';
        break;
      case OBJECT_TYPE_METADATA_PRESET:
        this.baseUrl = urls.metadataPresetBase;
        this.createUrl = urls.metadataPresetCreate;
        this.baseTypeLabel = 'Preset';
        break;
      default:
        break;
    }

    this.handleNewObjectClick = this.handleNewObjectClick.bind(this);
    this.handleModalRequestClose = this.handleModalRequestClose.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTypeSelect = this.handleTypeSelect.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.createObject = this.createObject.bind(this);
  }

  componentDidUpdate (prevProps, prevState) {
    const { createdObject } = this.state;

    if (createdObject && createdObject.id) {
      const url = urlJoin(this.baseUrl, createdObject.id);
      window.location.href = url;
    }
  }

  handleNewObjectClick (e) {
    e.preventDefault();
    this.setState({ modalVisible: true });
  }

  handleModalRequestClose () {
    this.setState({ ...this.defaultState });
  }

  handleInputChange (e) {
    this.setState({ name: e.target.value });
  }

  handleTypeSelect (objectType) {
    this.setState({ objectType });
  }

  handleFormSubmit (e) {
    e.preventDefault();
    this.createObject();
  }

  handleCancelClick (e) {
    e.preventDefault();
    this.handleModalRequestClose();
  }

  createObject () {
    const { csrfToken } = this.props;
    const { name, objectType } = this.state;
    const body = JSON.stringify({ name, type: objectType });
    this.setState({ processing: true });
    fetch(this.createUrl, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (!response.ok && response.status !== 400) throw new Error(response.statusText || 'Bad Request');
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        if (data.id) {
          this.setState({ createdObject: data, processing: false });
        } else {
          this.setState({ validationErrors: data, processing: false });
        }
      })
      .catch(err => {
        this.setState({ error: err.toString(), processing: false });
        console.error(err);
      });
  }

  render () {
    const { objectBaseType } = this.props;
    const { modalVisible, processing, validationErrors, error, name, objectType } = this.state;
    const showTypePicker = PICKER_TYPES.includes(objectBaseType);
    const canSubmit = !!name && (!showTypePicker || !!objectType);

    return (
      <div>
        <a href="#new-title" className="btn btn-primary" onClick={this.handleNewObjectClick}>New {this.baseTypeLabel}</a>
        <Modal
          title={`Create New ${this.baseTypeLabel}`}
          style={{ content: { width: 800 } }}
          isOpen={modalVisible}
          onRequestClose={this.handleModalRequestClose}
        >
          <ErrorBoundary>
            {error ? (
              <div className="title-create">
                <div className="toast toast-error">{error}</div>
              </div>
            ) : (
              <div className="title-create">
                <form onSubmit={this.handleFormSubmit}>
                  <div className="form-group">
                    <div className="has-icon-right" style={{ flex: 1, display: 'flex' }}>
                      <input
                        type="text"
                        className="form-input input-lg"
                        placeholder="Name"
                        readOnly={processing}
                        onChange={this.handleInputChange}
                        value={name}
                        autoFocus
                      />
                      {processing ? <i className="form-icon loading" /> : null}
                    </div>
                  </div>

                  {validationErrors && (
                    <div className="error-list mt-3">
                      {Object.values(validationErrors).map((message, i) => <p key={i}>{message}</p>)}
                    </div>
                  )}

                  {showTypePicker && (
                    <TypePicker objectBaseType={objectBaseType} onSelect={this.handleTypeSelect} activeOption={objectType} />
                  )}

                  <div className="modal-action-buttons mt-4">
                    <a className="btn" onClick={this.handleCancelClick}>Cancel</a>
                    <button className="btn btn-primary" type="submit" disabled={!canSubmit}>Create</button>
                  </div>
                </form>
              </div>
            )}
          </ErrorBoundary>
        </Modal>
      </div>
    );
  }
}

ObjectCreate.propTypes = {
  csrfToken: PropTypes.string,
  objectBaseType: PropTypes.oneOf([OBJECT_TYPE_TITLE, OBJECT_TYPE_RELEASE, OBJECT_TYPE_METADATA_PRESET]),
};

export default withCSRF(ObjectCreate);
