import React from 'react';
import PropTypes from 'prop-types';


const InsertLine = ({ nodeTypes, insertionPoint }) => {
  let insertLink;
  if (nodeTypes.length === 1) {
    const { name, callback } = nodeTypes[0];
    const handleClick = e => {
      e.preventDefault();
      callback(insertionPoint);
    };
    insertLink = <span><a href="#insert" onClick={handleClick}>Insert {name}</a></span>;
  } else {
    insertLink = (
      <span>
        Insert:
        <span className="insert-options">
          {nodeTypes.map(({ name, callback }, idx) => {
            const handleClick = e => {
              e.preventDefault();
              callback(insertionPoint);
            };
            return (
              <span key={`insert-${name}-${idx}`}>
                <a href="#insert" onClick={handleClick}>{name}</a>
                {idx < nodeTypes.length - 1 ? <span className="s">|</span> : null}
              </span>
            );
          })}
        </span>
      </span>
    );
  }

  return (
    <div className="insert-line">
      <div>{insertLink}</div>
    </div>
  );
};


InsertLine.propTypes = {
  nodeTypes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    callback: PropTypes.func,
  })).isRequired,
  insertionPoint: PropTypes.number,
};

export default InsertLine;
