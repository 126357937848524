import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import snakeCase from 'lodash/snakeCase';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import withCSRF from 'components/common/withCSRF';
import LoadingOverlay from 'components/common/LoadingOverlay';
import ConfirmDelete from './ConfirmDelete';
import IntegrationForm from './IntegrationForm';


class EditIntegration extends Component {
  constructor (props) {
    super(props);
    this.state = {
      fieldVals: {},
      fieldErrors: {},
      processing: false,
      error: null,
      confirmDelete: false,
    };

    this.handleDeleteClick = this.handleDeleteClick.bind(this);
    this.handleDeleteCancel = this.handleDeleteCancel.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount () {
    const { id } = this.props;
    if (id) {
      this.fetchFormData();
    }
  }

  handleDeleteClick (e) {
    const { onRequestDelete } = this.props;
    e.preventDefault();
    this.setState({ confirmDelete: true });
    onRequestDelete();
  }

  handleDeleteCancel () {
    const { onCancelDelete } = this.props;
    this.setState({ confirmDelete: false });
    onCancelDelete();
  }

  handleFormChange (fieldVals) {
    this.setState({ fieldVals });
  }

  handleFormSubmit (e) {
    const { id, csrfToken, createUrl, onSave } = this.props;
    const { fieldVals } = this.state;
    // If no id is supplied, we are creating a new integration
    const create = !id;
    e.preventDefault();

    // Convert field names to snake_case for the API
    const formData = Object.entries(fieldVals).reduce((result, [name, value]) => {
      const key = snakeCase(name);
      result[key] = value;
      return result;
    }, {});
    const url = create ? createUrl : urlJoin(urls.integrationEditBase, id, '/');
    const body = JSON.stringify({ ...formData });

    this.setState({ processing: true });
    fetch(url, {
      credentials: 'include',
      method: create ? 'POST' : 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (response.status === 400) {
          // Form validation errors
          response.json().then(data => {
            const fieldErrors = camelize(data);
            this.setState({
              fieldErrors,
              processing: false,
            });
          });
        } else if (!response.ok) {
          this.setState({
            processing: false,
            error: response.statusText,
          });
        } else {
          this.setState({ processing: false }, onSave);
        }
        return response;
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  fetchFormData () {
    const { id } = this.props;
    const url = urlJoin(urls.integrationEditBase, id, '?format=json');

    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({
          fieldVals: data,
          processing: false,
        });
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  render () {
    const { id, serviceLabel, formFields, description, oAuthUrl, healthCheckErrors, onCancel, onDelete } = this.props;
    const { confirmDelete, fieldVals, fieldErrors, processing } = this.state;
    const create = !id;
    const formReady = Object.keys(fieldVals).length > 0;

    if (confirmDelete) {
      return (
        <ConfirmDelete
          id={id}
          onSuccess={onDelete}
          onCancel={this.handleDeleteCancel}
        />
      );
    }

    if (create && oAuthUrl) {
      return (
        <div>
          <p>{description}</p>
          <hr />
          <div className="modal-action-buttons">
            <button className="btn" type="button" onClick={onCancel}>Cancel</button>
            <a href={oAuthUrl} className="btn btn-primary">Connect {serviceLabel} Account</a>
          </div>
        </div>
      );
    }

    return (
      <div style={{ position: 'relative' }}>
        <p>{create ? description : 'Change configuration options for this integration.'}</p>
        {healthCheckErrors && healthCheckErrors.length > 0 && (
          <div className="error-list mb-3">{healthCheckErrors.map((message, i) => <p key={i}>{message}</p>)}</div>
        )}
        <form onSubmit={this.handleFormSubmit}>
          {create || formReady ? (
            <IntegrationForm
              fieldConfig={formFields}
              data={create ? undefined : fieldVals}
              errors={fieldErrors}
              onChange={this.handleFormChange}
            />
          ) : <div style={{ height: 80 }} />}
          <hr />
          <div className="modal-action-buttons">
            {!create && (
              <div style={{ flex: 1, marginLeft: -5 }}>
                <button className="btn btn-error" type="button" onClick={this.handleDeleteClick}>Delete</button>
              </div>
            )}
            <button className="btn" type="button" onClick={onCancel}>Cancel</button>
            <button className="btn btn-primary" type="submit" disabled={processing}>Submit</button>
          </div>
        </form>
        <LoadingOverlay show={processing} />
      </div>
    );
  }
}

EditIntegration.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  id: PropTypes.string,
  serviceLabel: PropTypes.string,
  formFields: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    type: PropTypes.oneOf(['text', 'password', 'checkbox', 'switch']).isRequired,
    defaultValue: PropTypes.bool,
  })),
  description: PropTypes.string,
  oAuthUrl: PropTypes.string,
  createUrl: PropTypes.string,
  healthCheckErrors: PropTypes.arrayOf(PropTypes.string),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onRequestDelete: PropTypes.func,
  onCancelDelete: PropTypes.func,
  onDelete: PropTypes.func,
};

export default withCSRF(EditIntegration);
