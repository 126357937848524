import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import renderBlockErrors from './renderBlockErrors';
import Select from 'components/common/Select';
import TextStyleSelect from 'components/common/TextStyleSelect';
import MediaRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/MediaRelationField';
import TitleRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/TitleRelationField';
import FormGroup from './FormGroup';


const SOURCE_OPTIONS = [
  { value: 'item', label: 'Current Item', helpText: 'Display metadata associated with the current active item' },
  { value: 'release', label: 'Release', helpText: 'Display metadata associated with the release' },
  { value: 'media', label: 'Media', helpText: 'Display metadata associated with a specific media object' },
  { value: 'title', label: 'Title', helpText: 'Display metadata associated with a specific title' },
];

const FIELD_OPTIONS = {
  item: [
    { value: 'name', label: 'Name' },
    { value: 'short_description', label: 'Short Description' },
    { value: 'long_description', label: 'Long Description' },
    { value: 'transcript', label: 'Transcript' },
  ],
  release: [
    { value: 'name', label: 'Name' },
    { value: 'short_description', label: 'Short Description' },
    { value: 'long_description', label: 'Long Description' },
  ],
  media: [
    { value: 'name', label: 'Name' },
    { value: 'short_description', label: 'Short Description' },
    { value: 'long_description', label: 'Long Description' },
    { value: 'transcript', label: 'Transcript' },
  ],
  title: [
    { value: 'name', label: 'Name' },
    { value: 'short_description', label: 'Short Description' },
    { value: 'long_description', label: 'Long Description' },
  ],
};

const DynamicText = ({
  uid,
  errors,
  allowSubItemSource = true,
  textStyle,
  alignment,
  source,
  field,
  media,
  title,
  useSubItems,
  onPreviewTextUpdate,
  onChange,
}) => {
  const createHandler = name => val => onChange({ [name]: val });
  const handleSourceChange = val => {
    const updates = { source: val };
    if (val !== 'title') {
      updates.title = null;
    }
    if (val !== 'media') {
      updates.media = null;
    }
    onChange(updates);
  };
  const handleUseSubItemsChange = e => onChange({ useSubItems: e.target.checked });
  const textStyleOptions = [
    { value: 'display_heading', label: 'Display Heading', helpText: '(extra large)' },
    { value: 'h1', label: 'Heading 1' },
    { value: 'h2', label: 'Heading 2' },
    { value: 'h3', label: 'Heading 3' },
    { value: 'p', label: 'Paragraph' },
    { value: 'caption', label: 'Caption' },
  ];
  const textStyleSelectVal = textStyleOptions.find(opt => opt.value === textStyle);
  const alignOptions = [
    { value: 'left', label: 'Left' },
    { value: 'center', label: 'Center' },
    { value: 'right', label: 'Right' },
  ];
  const alignSelectVal = alignOptions.find(opt => opt.value === alignment);
  const handleAlignChange = val => onChange({ alignment: val });
  const sourceSelectVal = SOURCE_OPTIONS.find(opt => opt.value === source);
  const fieldSelectVal = (source && field) ? FIELD_OPTIONS[source].find(opt => opt.value === field) : null;

  useEffect(() => {
    let text = '';
    if (sourceSelectVal) text += sourceSelectVal.label;
    if (fieldSelectVal) text += ': ' + fieldSelectVal.label;
    onPreviewTextUpdate(text);
  }, [sourceSelectVal, fieldSelectVal]);

  useEffect(() => {
    if (field && !FIELD_OPTIONS[source].map(({ value }) => value).includes(field)) {
      onChange({ field: '' });
    }
  }, [source, field]);

  const renderedErrors = renderBlockErrors(errors);
  const showSubItemsField = allowSubItemSource && source === 'item';

  return (
    <div className="content">
      <FormGroup className="mb-3" label="Source:">
        <Select
          value={sourceSelectVal}
          options={SOURCE_OPTIONS}
          onChange={handleSourceChange}
        />
        {renderedErrors.source}
      </FormGroup>

      {source === 'media' && (
        <FormGroup className="mb-3">
          <MediaRelationField className="m-0" value={media} onChange={createHandler('media')} />
          {renderedErrors.media}
        </FormGroup>
      )}

      {source === 'title' && (
        <FormGroup className="mb-3">
          <TitleRelationField className="m-0" value={title} onChange={createHandler('title')} />
          {renderedErrors.title}
        </FormGroup>
      )}

      {showSubItemsField && (
        <FormGroup className="mb-1">
          <label className="form-checkbox">
            <input type="checkbox" checked={useSubItems} onChange={handleUseSubItemsChange} />
            <i className="form-icon" /> Include Sub-items
            <div className="text-hint">
              If this option is selected, metadata will be sourced from the current active item <em>within</em> the current title (for supported title types) — for example, an image within a slideshow title or a video within a playlist title. Deselect this option to source metadata only from the title itself.
            </div>
          </label>
          {renderedErrors.useSubItems}
        </FormGroup>
      )}

      <FormGroup className="mb-3" label="Field:">
        <Select
          value={fieldSelectVal}
          options={FIELD_OPTIONS[source]}
          isDisabled={!source}
          onChange={createHandler('field')}
        />
        {renderedErrors.field}
      </FormGroup>

      <FormGroup className="mb-3" label="Style:">
        <TextStyleSelect
          value={textStyleSelectVal}
          options={textStyleOptions}
          onChange={createHandler('textStyle')}
        />
        {renderedErrors.textStyle}
      </FormGroup>

      <FormGroup className="mb-3" label="Alignment:">
        <Select
          value={alignSelectVal}
          options={alignOptions}
          onChange={handleAlignChange}
        />
        {renderedErrors.alignment}
      </FormGroup>
    </div>
  );
};

DynamicText.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  allowSubItemSource: PropTypes.bool,
  textStyle: PropTypes.oneOf(['display_heading', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'caption']),
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  source: PropTypes.oneOf(['', ...SOURCE_OPTIONS.map(opt => opt.value)]),
  field: PropTypes.string,
  media: PropTypes.string,
  title: PropTypes.string,
  useSubItems: PropTypes.bool,
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default DynamicText;
