import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';

const MediaTypeIcon = ({ type, fill }) => (
  <Icon
    name={{
      audio: 'volume_up',
      image: 'imagesmode',
      video: 'videocam',
    }[type]}
    fill={typeof fill === 'undefined' ? type !== 'image' : fill}
  />
);

MediaTypeIcon.propTypes = {
  type: PropTypes.oneOf(['audio', 'image', 'video']),
  fill: PropTypes.bool,
};

export default MediaTypeIcon;
