import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'app-constants';
import { formatters, pluralize } from 'utils';
import { DeleteOverlay, ErrorList } from 'components/common/inlines';
import { ObjectListModal } from 'components/views/ObjectList';
import InlineListBuilder from 'components/common/inlines/InlineListBuilder';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import Icon from 'components/common/Icon';
import SectionItem from './SectionItem';


const Section = ({
  fields: fieldsFromProps,
  itemId,
  editMode,
  subItems = [],
  activeSettingsItems,
  shouldDelete,
  itemIndex,
  prefix,
  errors,
  isExpanded = true,
  onToggleExpanded,
  onSubItemMove,
  onSubItemPreviewLoad,
  onSubItemSettingsClick,
  onSubItemDeleteClick,
  onDeleteClick,
  onAddMedia,
  onAddTitle,
}) => {
  const [fields, setFields] = useState({});

  useEffect(() => {
    setFields(Object.entries(fieldsFromProps).reduce((result, [key, val]) => {
      result[key] = val || '';
      return result;
    }, {}));
  }, []);

  const [autoSlugEnabled, setAutoSlugEnabled] = useState(!fieldsFromProps.slug);
  const handleInputChange = evt => {
    let { name, value } = evt.target;
    name = name.replace(`${prefix}-`, '');
    const newFields = {
      ...fields,
      [name]: value,
    };

    // Auto-populate slug from name
    if (autoSlugEnabled && name === 'name') {
      newFields.slug = formatters.slug(value);
    }

    if (name === 'slug') {
      if (autoSlugEnabled) {
        // Disable auto-slugging if the slug field itself is explicitly changed
        setAutoSlugEnabled(false);
      } else if (!value) {
        // Re-enable auto-slugging if the slug field is empty
        setAutoSlugEnabled(true);
      }
    }

    setFields(newFields);
  };

  const [mediaModalOpen, setMediaModalOpen] = useState(false);
  const handleChooseImageClick = evt => {
    evt.preventDefault();
    setMediaModalOpen(true);
  };

  const handleRemoveImageClick = evt => {
    evt.preventDefault();
    setFields(oldState => ({ ...oldState, socialImage: '' }));
  };

  const handleMediaModalSelect = item => {
    setFields(oldState => ({ ...oldState, socialImage: item.uuid }));
    handleMediaModalRequestClose();
  };

  const handleMediaModalRequestClose = () => setMediaModalOpen(false);

  const { errorClasses, errorMessages } = Object.keys(fields).reduce((result, fieldName) => {
    if (errors[fieldName]) {
      result.errorClasses[fieldName] = 'has-error';
      result.errorMessages[fieldName] = errors[fieldName].map(({ message }, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>);
    } else {
      result.errorClasses[fieldName] = '';
      result.errorMessages[fieldName] = null;
    }
    return result;
  }, { errorClasses: {}, errorMessages: {} });

  const hasFields = Object.keys(fields).length > 0;

  const editableFields = hasFields && (editMode === 'insert' ? (
    <div style={{ flex: 1, display: 'flex', flexWrap: 'wrap' }}>
      <div className={`form-group mb-2 ${errorClasses.name}`} style={{ flex: '0 0 50%', minWidth: 300, paddingRight: 5 }}>
        <label htmlFor={`${prefix}-name`}>Name</label>
        <input type="text" className="form-input" name={`${prefix}-name`} value={fields.name} onChange={handleInputChange} />
        {errorMessages.name || <div className="form-input-hint">Appears in navigation menus.</div>}
      </div>
      <div className={`form-group mb-2 ${errorClasses.slug}`} style={{ flex: '0 0 50%', minWidth: 300, paddingLeft: 5 }}>
        <label htmlFor={`${prefix}-slug`}>Slug</label>
        <input type="text" className="form-input" name={`${prefix}-slug`} value={fields.slug} onChange={handleInputChange} />
        {errorMessages.slug || <div className="form-input-hint">Appears in URLs.</div>}
      </div>

      <div className={`form-group mb-2 ${errorClasses.socialImage}`} style={{ flex: '0 0 100%' }}>
        <label>Image</label>
        <input type="hidden" name={`${prefix}-social_image`} value={fields.socialImage} />
        {fields.socialImage ? (
          <AttachedItemPreview itemType="media" itemId={fields.socialImage} style={{ backgroundColor: colors.grayExtraLight }} clickableThumbnail>
            <div
              className="btn-delete tooltip tooltip-left"
              style={{ backgroundColor: 'transparent' }}
              data-tooltip="Remove Image"
              onClick={handleRemoveImageClick}
            >
              <Icon name="delete" fill />
            </div>
          </AttachedItemPreview>
        ) : (
          <div className="attached-item-preview" style={{ backgroundColor: colors.grayExtraLight, justifyContent: 'center' }}>
            <button className="btn btn-primary" onClick={handleChooseImageClick}>Choose Image</button>
          </div>
        )}
        {errorMessages.socialImage || <div className="form-input-hint">Appears when content in this section is shared on social media.</div>}
      </div>

      <div className={`form-group m-0 ${errorClasses.description}`} style={{ flex: '0 0 100%' }}>
        <label htmlFor={`${prefix}-description`}>Description</label>
        <textarea rows="3" className="form-input" name={`${prefix}-description`} value={fields.description} onChange={handleInputChange} />
        {errorMessages.description || <div className="form-input-hint">Appears in search results and when content in this section is shared on social media.</div>}
      </div>

      <ObjectListModal
        model="media"
        preFilter={{ type: 'image' }}
        disabledFilters={['type', 'duration', 'source']}
        isOpen={mediaModalOpen}
        allowMultipleSelection={false}
        onAccept={handleMediaModalSelect}
        onRequestClose={handleMediaModalRequestClose}
      />
    </div>
  ) : (
    <div>
      <h4 className="m-0">{fields.name}</h4>
      <input type="hidden" name={`${prefix}-name`} value={fields.name} />
      <input type="hidden" name={`${prefix}-slug`} value={fields.slug} />
      <input type="hidden" name={`${prefix}-social_image`} value={fields.socialImage} />
      <input type="hidden" name={`${prefix}-description`} value={fields.description} />
    </div>
  ));

  const hiddenFields = hasFields && (
    <>
      {shouldDelete && <input type="hidden" name={`${prefix}-DELETE`} value="1" />}
      {!!fields.id && <input type="hidden" name={`${prefix}-id`} value={fields.id} />}
      <input type="hidden" name={`${prefix}-uuid`} value={fields.uuid} />
      <input type="hidden" name={`${prefix}-parent`} value={fields.parent} />
      <input type="hidden" name={`${prefix}-index`} value={itemIndex} />
    </>
  );

  let controlButtons;
  if (editMode === 'insert') {
    controlButtons = (
      <span
        className="btn-delete tooltip"
        key={shouldDelete}
        onClick={onDeleteClick}
        data-tooltip={shouldDelete ? 'Undo Remove' : 'Remove Item'}
      >

        {shouldDelete ? <Icon name="undo" /> : <Icon name="delete" fill />}
      </span>
    );
  } else if (editMode === 'sort') {
    controlButtons = (
      <span key={editMode} className="btn-move tooltip" data-tooltip="Drag to Sort">
        <Icon name="menu" />
      </span>
    );
  }

  let renderedSubItems = [];
  if (subItems.length > 0) {
    renderedSubItems = subItems.map((item, idx) => (
      <SectionItem
        key={item.itemId}
        editMode={editMode}
        onLoadPreview={onSubItemPreviewLoad}
        onSettingsClick={onSubItemSettingsClick}
        onDeleteClick={onSubItemDeleteClick}
        settingsActive={activeSettingsItems.includes(item.itemId)}
        itemIndex={idx}
        fieldsOnly={!isExpanded}
        {...item}
      />
    ));
  } else if (editMode === 'sort') {
    renderedSubItems = [<div key={`section-${itemId}-placeholder`} style={{ textAlign: 'center', padding: '20px' }}><em>This section is empty.</em></div>];
  }

  const { name, slug, description, ...hiddenFieldErrors } = errors;
  const showErrorList = Object.keys(hiddenFieldErrors).length > 0;
  const itemIds = subItems.length ? subItems.map(item => item.itemId) : [`section-${itemId}-placeholder`];
  const nodeTypes = [
    { name: 'media', callback: idx => onAddMedia(idx) },
    { name: 'title', callback: idx => onAddTitle(idx) },
  ];

  return (
    <div>
      <header>
        <div className="inline-list-builder-icon">
          <Icon name="stacks" fill />
        </div>
        <div>
          {showErrorList && <ErrorList className="mb-3" errors={hiddenFieldErrors} />}
          {editableFields}
          {hiddenFields}
        </div>
      </header>

      <section className="mt-3">
        {subItems.length > 0 && (
          <div className="subitems-toggle" onClick={onToggleExpanded}>
            <span>{isExpanded ? 'Hide' : 'Show'} {pluralize(subItems, 'item')}</span>
            <span className="ml-2" style={{ transform: isExpanded ? 'rotate(180deg) translateY(-3px)' : '' }}>▾</span>
          </div>
        )}
        {isExpanded ? (
          <InlineListBuilder
            type="sectionitem"
            itemIds={itemIds}
            editMode={editMode}
            nodeTypes={nodeTypes}
            onItemMove={onSubItemMove}
          >
            {renderedSubItems}
          </InlineListBuilder>
        ) : renderedSubItems}
      </section>

      {shouldDelete && <DeleteOverlay onUndoClick={onDeleteClick} />}
      {controlButtons}
    </div>
  );
};

Section.propTypes = {
  itemId: PropTypes.string,
  editMode: PropTypes.string,
  subItems: PropTypes.arrayOf(PropTypes.object),
  onSubItemMove: PropTypes.func,
  onSubItemPreviewLoad: PropTypes.func,
  onSubItemSettingsClick: PropTypes.func,
  onSubItemDeleteClick: PropTypes.func,
  activeSettingsItems: PropTypes.array,
  onDeleteClick: PropTypes.func,
  onAddMedia: PropTypes.func,
  onAddTitle: PropTypes.func,
  shouldDelete: PropTypes.bool,
  itemIndex: PropTypes.number,
  prefix: PropTypes.string,
  errors: PropTypes.object,
  fields: PropTypes.object,
  isExpanded: PropTypes.bool,
  onToggleExpanded: PropTypes.func,
};

export default Section;
