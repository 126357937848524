import React from 'react';
import PropTypes from 'prop-types';


const Checkbox = ({ name, label, helpText, value, errors, className, style, onChange }) => (
  <div className={`form-group ${errors.length ? 'has-error' : ''} ${className || ''}`} style={style}>
    <label className="form-checkbox">
      <input type="checkbox" name={name} checked={value} onChange={onChange} />
      <i className="form-icon" /> {label}
    </label>
    {helpText && !errors.length ? <p className="form-input-hint m-0">{helpText}</p> : null}
    {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
  </div>
);

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  errors: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  errors: [],
  onChange: () => null,
};

export default Checkbox;
