import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import { ObjectListModal } from 'components/views/ObjectList';
import Icon from 'components/common/Icon';


class AddLibraryMedia extends Component {
  constructor (props) {
    super(props);
    this.state = {
      mediaModalOpen: false,
    };

    this.handleModalTrigger = this.handleModalTrigger.bind(this);
    this.handleMediaModalAccept = this.handleMediaModalAccept.bind(this);
    this.handleMediaModalRequestClose = this.handleMediaModalRequestClose.bind(this);
  }

  handleModalTrigger () {
    const { mediaModalOpen } = this.state;
    if (!mediaModalOpen) {
      this.setState({ mediaModalOpen: true });
    }
  }

  handleMediaModalAccept (selectedMedia) {
    const { onChange, allowMultiple } = this.props;
    if (allowMultiple) {
      const value = selectedMedia.map(m => m.uuid);
      onChange(value);
    } else {
      const { uuid } = selectedMedia;
      onChange(uuid);
    }
    this.handleMediaModalRequestClose();
  }

  handleMediaModalRequestClose () {
    this.setState({ mediaModalOpen: false });
  }

  renderChildren () {
    // By passing a function to children, we can override the default UI
    // for the button that triggers the modal.
    const { children } = this.props;
    if (typeof children === 'function') {
      const renderedChildren = children(this.handleModalTrigger);
      return renderedChildren && Children.only(renderedChildren);
    }
    return null;
  }

  render () {
    const { preFilter, disabledFilters, allowMultiple } = this.props;
    const { mediaModalOpen } = this.state;

    return (
      <>
        {this.renderChildren() || (
          // Default UI if no 'children' prop
          <div className={`add-media ${mediaModalOpen ? 'active' : ''}`} onClick={this.handleModalTrigger}>
            <div className="add-media-icon mr-3">
              <Icon name="theaters" />
            </div>
            <div>
              <h6><a>Add From Media Library</a></h6>
              <span className="text-hint">Choose from existing media.</span>
            </div>
          </div>
        )}
        <ObjectListModal
          model="media"
          isOpen={mediaModalOpen}
          allowMultipleSelection={allowMultiple}
          preFilter={preFilter}
          disabledFilters={disabledFilters}
          onAccept={this.handleMediaModalAccept}
          onRequestClose={this.handleMediaModalRequestClose}
        />
      </>
    );
  }
}

AddLibraryMedia.propTypes = {
  children: PropTypes.func,
  onChange: PropTypes.func,
  preFilter: PropTypes.object,
  allowMultiple: PropTypes.bool,
  disabledFilters: PropTypes.arrayOf(PropTypes.string),
};

AddLibraryMedia.defaultProps = {
  preFilter: {},
  disabledFilters: [],
  allowMultiple: false,
};

export default AddLibraryMedia;
