import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import { v4 as uuid4 } from 'uuid';
import ResizeDetector from 'react-resize-detector';
import withDragDropContext from 'components/common/withDragDropContext';
import SortableItem from 'components/common/SortableItem';
import Block from './Block';
import AddBlockMenu from './AddBlockMenu';
import blockDefaults from './blockDefaults';


const StructuredContentBuilder = ({
  initialValue = [],
  errors = {},
  allowSubItemSource = true,
  allowConditionalDisplay = true,
  releaseItems = [],
  onChange,
}) => {
  const itemListRef = useRef();
  const [blocksById, setBlocksById] = useState({});
  const [blocksOrdered, setBlocksOrdered] = useState([]);

  useEffect(() => {
    if (onChange) {
      const value = blocksOrdered.map((uid, idx) => ({ ...blocksById[uid], index: idx + 1 }));
      onChange(value);
    }
  }, [blocksById, blocksOrdered, onChange]);

  // init data
  useEffect(() => {
    setBlocksById(camelize(initialValue).reduce((result, item) => {
      const { uid, shouldDelete, ...rest } = item;
      result[uid] = { uid, shouldDelete: !!shouldDelete, ...rest };
      return result;
    }, {}));
    setBlocksOrdered(initialValue.map(({ uid }) => uid));
  }, []);

  const updateBlock = (uid, attrs) => setBlocksById(oldState => ({
    ...oldState,
    [uid]: {
      ...oldState[uid],
      ...attrs,
    },
  }));

  const initNewBlock = blockType => {
    const block = {
      blockType,
      uid: uuid4(),
      shouldDelete: false,
      conditionalDisplay: false,
      conditionalDisplayItems: [],
      ...blockDefaults[blockType],
    };

    setBlocksById({ ...blocksById, [block.uid]: block });
    setBlocksOrdered([...blocksOrdered, block.uid]);
    toggleBlockExpandedState(block.uid);
  };

  // expand / collapse blocks
  const [blocksExpanded, setBlocksExpanded] = useState({});

  useEffect(() => {
    setBlocksExpanded(blocksOrdered.reduce((result, uid) => {
      let expanded = uid in blocksExpanded ? blocksExpanded[uid] : false;
      if (errors[uid]) expanded = true;
      result[uid] = expanded;
      return result;
    }, {}));
  }, [blocksOrdered]);

  const toggleBlockExpandedState = uid => {
    setBlocksExpanded({
      ...blocksExpanded,
      [uid]: !blocksExpanded[uid],
    });
  };

  const toggleAllBlocksExpandedState = isExpanded => {
    setBlocksExpanded(blocksOrdered.reduce((result, uid) => ({ ...result, [uid]: isExpanded }), {}));
  };

  const handleExpandAllClick = e => {
    e.preventDefault();
    toggleAllBlocksExpandedState(true);
  };

  const handleCollapseAllClick = e => {
    e.preventDefault();
    toggleAllBlocksExpandedState(false);
  };

  // add block menu
  const [menuIsVisible, setMenuIsVisible] = useState(false);
  const toggleAddBlockMenu = () => setMenuIsVisible(!menuIsVisible);
  const handleAddBlock = blockType => {
    setMenuIsVisible(false);
    initNewBlock(blockType);
    setTimeout(() => itemListRef.current.scrollTo(0, itemListRef.current.scrollHeight), 100);
  };

  // drag and drop sorting
  const handleItemMove = (sourceId, targetId, insertPos) => {
    const targetIndex = blocksOrdered.indexOf(targetId);
    const toIndex = insertPos === 'before' ? targetIndex : targetIndex + 1;
    const newValue = blocksOrdered.filter(uid => uid !== sourceId);
    newValue.splice(toIndex, 0, sourceId);
    setBlocksOrdered(newValue);
  };

  const blocks = blocksOrdered.map(uid => {
    const { blockType, shouldDelete, ...attrs } = blocksById[uid];
    const isExpanded = blocksExpanded[uid];
    const blockProps = {
      type: blockType,
      isExpanded,
      shouldDelete,
      errors: errors[uid] || {},
      attrs,
    };
    return (
      <SortableItem
        key={uid}
        itemId={uid}
        type="sc-builder-items"
        className="mb-4"
        onItemMove={handleItemMove}
      >
        {(connectDragSource) => (
          <Block
            {...blockProps}
            allowSubItemSource={allowSubItemSource}
            allowConditionalDisplay={allowConditionalDisplay}
            releaseItems={releaseItems}
            connectDragSource={connectDragSource}
            onChange={attrs => updateBlock(uid, attrs)}
            onDisclosureClick={() => toggleBlockExpandedState(uid)}
            onDeleteClick={() => updateBlock(uid, { shouldDelete: !shouldDelete })}
          />
        )}
      </SortableItem>
    );
  });

  const hasError = Object.keys(errors).length > 0;

  return (
    <div className="sc-builder">
      <ResizeDetector handleHeight>
        {({ height }) => (
          <>
            <div className="controls">
              <div className="text-meta">
                <a href="#expand" onClick={handleExpandAllClick}>Expand all</a>&nbsp; | &nbsp;<a href="#collapse" onClick={handleCollapseAllClick}>Collapse all</a>
              </div>
              <div style={{ position: 'relative' }}>
                <button type="button" className="btn btn-primary btn-sm" onClick={toggleAddBlockMenu}>
                  Add Block <i className="icon icon-caret" />
                </button>
                {menuIsVisible && (
                  <AddBlockMenu
                    style={{ maxHeight: height && height - 60 }}
                    onItemSelect={handleAddBlock}
                    onClickOutside={() => setMenuIsVisible(false)}
                  />
                )}
              </div>
            </div>
            {hasError && <div className="error-list">Some of the items below contain errors.</div>}
            <div className="item-list" ref={itemListRef}>
              {blocks.length ? blocks : <div className="text-center my-4">No content yet! Click “Add Block” to get started.</div>}
            </div>
          </>
        )}
      </ResizeDetector>
    </div>
  );
};

StructuredContentBuilder.propTypes = {
  initialValue: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.object,
  allowSubItemSource: PropTypes.bool,
  allowConditionalDisplay: PropTypes.bool,
  releaseItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    objectId: PropTypes.string,
    objectType: PropTypes.string,
    objectName: PropTypes.string,
    objectIconName: PropTypes.string,
  })),
  onChange: PropTypes.func,
};

export default withDragDropContext(StructuredContentBuilder);
