import React, { useState } from 'react';
import PropTypes from 'prop-types';
import QuillEditor from 'components/common/QuillEditor';

const ItemContentText = ({ prefix, errors, contentFieldValue, isEditing }) => {
  const [content, setContent] = useState(contentFieldValue);

  const contentFieldErrors = (errors.content || []).map((err, idx) => (
    <div key="idx" className="form-input-hint text-error mt-1">{err.message}</div>
  ));

  return (
    <div className="mr-2" style={{ flex: 1 }}>
      {isEditing ? (
        <>
          <QuillEditor
            value={content}
            onChange={val => setContent(val)}
          />
          {contentFieldErrors}
        </>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className="fade-bottom-gray-extra-light"
          style={{ maxHeight: 100 }}
        />
      )}
      <input type="hidden" name={`${prefix}-content`} value={content} />
    </div>
  );
};

ItemContentText.propTypes = {
  prefix: PropTypes.string,
  errors: PropTypes.object,
  contentFieldValue: PropTypes.string,
  isEditing: PropTypes.bool,
};

export default ItemContentText;
