import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { colors } from 'app-constants';
import { CUSTOM_TITLE_FIELD_PROPTYPES } from './';
import FieldWrapper from './FieldWrapper';
import { SketchPicker } from 'react-color';
import Checkboard from 'react-color/lib/components/common/Checkboard';


class ColorField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showPicker: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handlePickerChange = this.handlePickerChange.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClick () {
    this.setState({ showPicker: !this.state.showPicker });
  }

  handleFocus () {
    this.setState({ showPicker: true });
  }

  handlePickerChange (color) {
    const { onChange } = this.props;
    onChange(color.hex);
  }

  handleClearClick () {
    const { onChange } = this.props;
    onChange('');
  }

  handleClickOutside (e) {
    if (this.ref && !this.ref.contains(event.target)) {
      this.setState({ showPicker: false });
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  render () {
    const { name, value, clearable } = this.props;
    const { showPicker } = this.state;
    const buttonStyle = { cursor: 'pointer' };
    if (showPicker) {
      buttonStyle.backgroundColor = colors.blueExtraLight;
    }

    const clearButtonStyle = {
      backgroundColor: '#E2E2E2',
      borderRadius: '50%',
      color: '#8a8a8a',
      cursor: 'pointer',
      fontSize: '18px',
      height: 18,
      lineHeight: '18px',
      position: 'absolute',
      right: 5,
      textAlign: 'center',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 18,
    };

    const pickerWrapStyle = {
      backgroundColor: '#fff',
      borderRadius: 4,
      display: showPicker ? 'block' : 'none',
      left: 0,
      marginBottom: 20,
      padding: 1,
      position: 'absolute',
      top: '120%',
      zIndex: 999,
    };

    return (
      <FieldWrapper {...this.props}>
        <div style={{ position: 'relative', width: 150 }} ref={node => this.ref = node}>
          <div className="input-group">
            <span className="input-group-addon" style={buttonStyle} onClick={this.handleClick}>
              <div style={{ backgroundColor: value, border: '1px solid #fff', width: 24, height: 24, position: 'relative' }}>
                {!value ? <Checkboard white="#fff" gray="#999" size={6} /> : null}
              </div>
            </span>
            <input
              type="text"
              className="form-input"
              name={name}
              value={value ? value.toUpperCase() : ''}
              onFocus={this.handleFocus}
              readOnly
              style={{ backgroundColor: '#fff' }} // Override read-only style
            />
            {clearable && !!value && <div style={clearButtonStyle} onClick={this.handleClearClick}>&times;</div>}
          </div>
          <div style={pickerWrapStyle}>
            <SketchPicker disableAlpha onChangeComplete={this.handlePickerChange} color={value || {}} />
          </div>
        </div>
      </FieldWrapper>
    );
  }
}

ColorField.propTypes = {
  ...CUSTOM_TITLE_FIELD_PROPTYPES,
  clearable: PropTypes.bool,
};

ColorField.defaultProps = {
  onChange: () => null,
};

export default ColorField;
