import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/common/Icon';
import LoadingOverlay from 'components/common/LoadingOverlay';

const Image = ({
  name,
  label,
  helpText,
  initial,
  value,
  errors,
  thumbnailUrl,
  fileUrl,
  className,
  style,
  acceptTypes,
  onChange,
  onClear,
}) => {
  const [shouldClear, setShouldClear] = useState(false);
  const [curVal, setCurVal] = useState(initial);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [previewIsLoading, setPreviewIsLoading] = useState(false);
  const readerRef = useRef();
  const inputRef = useRef();

  useEffect(() => {
    const imgUrl = thumbnailUrl || fileUrl;
    if (imgUrl) setPreviewUrl(imgUrl);
  }, [thumbnailUrl, fileUrl]);
  const previewStyle = previewUrl && { backgroundImage: `url('${previewUrl}')` };

  useEffect(() => {
    if (value && value instanceof File) {
      setCurVal(value.name);
    }
  }, [value]);

  const handleInputChange = e => {
    setShouldClear(false);
    loadPreviewFromFile(e.target.files[0]);
    setCurVal(e.target.files[0].name);
    onChange(e);
  };

  const handleRemoveClick = e => {
    e.preventDefault();
    setShouldClear(true);
    setPreviewUrl(null);
    setCurVal(null);
    onClear(name);
    inputRef.current.value = '';
  };

  useEffect(() => {
    const e = {
      target: {
        type: 'checkbox',
        name: `${name}-clear`,
        checked: shouldClear,
      },
    };
    onChange(e);
  }, [shouldClear]);

  const loadPreviewFromFile = fileObj => {
    setPreviewIsLoading(true);
    readerRef.current = new FileReader();
    readerRef.current.readAsDataURL(fileObj);
    readerRef.current.onload = () => {
      setPreviewUrl(readerRef.current.result);
      setPreviewIsLoading(false);
    };
    readerRef.current.onerror = () => {};
  };

  useEffect(() => {
    return () => readerRef.current && readerRef.current.abort();
  }, []);

  return (
    <div className={`form-group ${errors.length ? 'has-error' : ''} ${className || ''}`} style={style}>
      {label && <label>{label}</label>}
      <div className="image-widget">
        <div className={classNames('preview-container', !previewUrl && 'empty')}>
          {previewUrl ? (
            <a className="preview" style={previewStyle} href={previewUrl} target="_blank" rel="noopener noreferrer" />
          ) : (
            <div className="preview" style={previewStyle}>
              <span><Icon name="imagesmode" /></span>
            </div>
          )}
          <LoadingOverlay show={previewIsLoading} />
        </div>
        <div className="field-container">
          <div className="mb-2 text-right">
            <label htmlFor={name}>
              <span style={{ width: 110 }} className="btn btn-primary btn-sm">Choose image</span>
              <input
                type="file"
                className="hidden-file-input"
                name={name}
                id={name}
                ref={inputRef}
                accept={acceptTypes.map(t => '.' + t).join(',')}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div className="mb-2 text-right">
            <button
              style={{ width: 110 }}
              className="btn btn-sm"
              type="button"
              disabled={!curVal}
              onClick={handleRemoveClick}
            >
              Remove image
            </button>
          </div>
          {shouldClear && <input type="hidden" name={`${name}-clear`} value="1" />}
        </div>
      </div>
      {helpText && !errors.length ? <p className="form-input-hint mb-0">{helpText}</p> : null}
      {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
    </div>
  );
};

Image.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string,
  initial: PropTypes.string,
  value: PropTypes.any,
  errors: PropTypes.array,
  thumbnailUrl: PropTypes.string,
  fileUrl: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  acceptTypes: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};

Image.defaultProps = {
  errors: [],
  acceptTypes: ['jpg', 'jpeg', 'gif', 'png'],
  onChange: () => null,
  onClear: () => null,
};

export default Image;
