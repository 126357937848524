import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { useCSRF } from 'hooks';
import Modal from 'components/common/Modal';


const DeletePreset = ({ preset, onDelete, onRequestClose }) => {
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (!preset) setProcessing(false);
  }, [preset]);

  const csrfToken = useCSRF();
  const handleFormSubmit = e => {
    e.preventDefault();
    if (!preset) return null;
    setProcessing(true);

    const url = urlJoin(urls.presetsBase, preset.id, '/');
    fetch(url, {
      credentials: 'include',
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
    })
      .then(response => {
        if (response.status === 204) {
          setProcessing(false);
          onDelete(preset);
        } else {
          throw new Error('Deletion failed.');
        }
        return response;
      })
      .catch(err => {
        setProcessing(false);
        console.error(err);
      });
  };

  return (
    <Modal
      title="Are you Sure?"
      style={{ content: { width: 400 } }}
      isOpen={!!preset}
      onRequestClose={onRequestClose}
    >
      {preset && (
        <form onSubmit={handleFormSubmit}>
          <p>The “{preset.name}” preset will be deleted. Any releases using it will revert back to the default settings.</p>

          <div className="modal-action-buttons mt-4">
            <button className="btn" type="button" onClick={onRequestClose} disabled={processing}>Cancel</button>
            <button className="btn btn-error" type="submit" disabled={processing}>Delete</button>
          </div>
        </form>
      )}
    </Modal>
  );
};

DeletePreset.propTypes = {
  preset: PropTypes.object,
  onDelete: PropTypes.func,
  onRequestClose: PropTypes.func,
};

export default DeletePreset;
