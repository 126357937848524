import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';
import { VIMEO_URL_PATTERN, YOUTUBE_URL_PATTERN, SOUNDCLOUD_URL_PATTERN } from './';


class AddUrlMedia extends Component {
  constructor (props) {
    super(props);
    this.state = {
      showInput: false,
      inputValue: '',
      invalid: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  handleClick () {
    const { showInput } = this.state;
    if (!showInput) {
      this.setState({ showInput: true });
    }
  }

  handleInputChange (e) {
    this.setState({ inputValue: e.target.value });
  }

  handleSubmit (e) {
    e.preventDefault();
    const { onChange } = this.props;
    const { inputValue } = this.state;
    if (this.validateUrl()) {
      onChange(inputValue);
    } else {
      this.setState({ invalid: true });
    }
  }

  handleCancelClick (e) {
    e.preventDefault();
    this.setState({
      showInput: false,
      inputValue: '',
      invalid: false,
    });
  }

  validateUrl () {
    const { inputValue } = this.state;
    return (
      VIMEO_URL_PATTERN.test(inputValue) ||
      YOUTUBE_URL_PATTERN.test(inputValue) ||
      SOUNDCLOUD_URL_PATTERN.test(inputValue)
    );
  }

  render () {
    const { showInput, inputValue, invalid } = this.state;

    return (
      <div className={`add-media ${showInput ? 'active' : ''}`} onClick={this.handleClick}>
        <div className="add-media-icon mr-3">
          <Icon name="link" />
        </div>
        {showInput ? (
          <div className={`form-group ${invalid ? 'has-error' : ''}`} style={{ width: '100%', padding: '6px 0' }}>
            <form style={{ display: 'flex', width: '100%' }} onSubmit={this.handleSubmit}>
              <div className="input-group mr-2" style={{ flex: 1 }}>
                <input autoFocus type="text" className="form-input" placeholder="https://" value={inputValue} onChange={this.handleInputChange} />
                <input type="submit" className="btn btn-primary input-group-btn" value="OK" />
              </div>
              <button className="btn" onClick={this.handleCancelClick}>Cancel</button>
            </form>
            {invalid ? <div className="form-input-hint">Enter a valid YouTube, Vimeo, or SoundCloud URL.</div> : null}
          </div>
        ) : (
          <div>
            <h6><a>Add From Web URL</a></h6>
            <span className="text-hint">Enter a URL to YouTube, Vimeo, or SoundCloud media.</span>
          </div>
        )}
      </div>
    );
  }
}

AddUrlMedia.propTypes = {
  onChange: PropTypes.func,
};

export default AddUrlMedia;
