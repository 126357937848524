import React from 'react';
import PropTypes from 'prop-types';
import Label from './Label';
import Group from './Group';
import Media from './Media';


const PlaylistItem = props => {
  const { type } = props.fields;
  switch (type) {
    case 'label':
      return <Label {...props} />;
    case 'group':
      return <Group {...props} />;
    case 'media':
      return <Media {...props} />;
    default:
      return null;
  }
};

PlaylistItem.propTypes = {
  fields: PropTypes.object,
};

export default PlaylistItem;
