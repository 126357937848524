import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import { urls } from 'app-constants';
import { useNotification } from 'hooks';
import { withResizeDetector } from 'react-resize-detector';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';
import PageHeader from 'components/common/PageHeader';
import CreatePreset from './CreatePreset';
import OptionsForm from './OptionsForm';
import PresetsList from './PresetsList';
import Preview from './Preview';


const TemplatePresets = ({ headerSubtitle, templateOptions, width: containerWidth }) => {
  const [presets, setPresets] = useState([]);
  const [presetsFetching, setPresetsFetching] = useState(true);
  const [activePresetId, setActivePresetId] = useState('');
  const [formReady, setFormReady] = useState(false);
  const optionsFormRef = useRef();

  const { notification, createNotification } = useNotification();

  const [modalVisible, setModalVisible] = useState(false);
  const handleNewPresetClick = () => setModalVisible(true);
  const handleModalRequestClose = () => setModalVisible(false);
  const handleCreate = obj => {
    setModalVisible(false);
    setActivePresetId(obj.id);
    fetchPresets();
  };

  const handleDelete = obj => {
    createNotification({ type: 'success', text: `Successfully deleted “${obj.name}.”`, timeout: 3 });
    fetchPresets();
  };

  const handleDuplicate = obj => {
    createNotification({ type: 'success', text: `Successfully duplicated “${obj.name}.”`, timeout: 3 });
    fetchPresets();
  };

  const [previewData, setPreviewData] = useState(null);
  const handleOptionsChange = val => {
    setPreviewData(val);
  };

  const handleCancelClick = () => {
    setActivePresetId('');
    setFormReady(false);
  };
  const handleSaveClick = e => {
    e.preventDefault();
    optionsFormRef.current && optionsFormRef.current.save();
  };

  const handleOptionsSave = () => {
    createNotification({ type: 'success', text: 'Successfully updated preset configuraton.', timeout: 3 });
    setActivePresetId('');
    setFormReady(false);
    fetchPresets();
  };

  useEffect(() => fetchPresets(), []);

  const fetchPresets = () => {
    setPresetsFetching(true);
    fetch(urls.presets)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setPresets(camelize(data));
        setPresetsFetching(false);
      })
      .catch(err => {
        setPresetsFetching(false);
        console.error(err);
      });
  };

  const activePreset = activePresetId && presets.find(p => p.id === activePresetId);
  const activePresetName = activePreset ? activePreset.name : '';
  const showForm = presets.length > 0 && !presetsFetching && !!activePreset;

  const headerButtons = activePresetId ? [
    { label: 'Save', onClick: handleSaveClick },
    { label: 'Cancel', onClick: handleCancelClick },
  ] : [
    { label: 'New Preset', onClick: handleNewPresetClick },
  ];

  return (
    <div className="wrap-page">
      <ErrorBoundary>
        {notification}
        <PageHeader
          title={`Template Presets${activePresetName && ' » ' + activePresetName}`}
          subtitle={headerSubtitle}
          iconName="palette"
          buttons={headerButtons}
        />

        <div className="page-body">
          {presets.length === 0 && !presetsFetching && (
            <div className="empty my-6">
              <div className="empty-icon">
                <Icon name="palette" size={48} />
              </div>
              <p className="empty-title h3">No Presets Yet</p>
              <p className="empty-subtitle">
                <a href="#" onClick={e => { e.preventDefault(); handleNewPresetClick(); }}>Add one</a> to get started.
              </p>
            </div>
          )}

          {presets.length > 0 && !showForm && (
            <PresetsList
              items={presets}
              onItemSelect={setActivePresetId}
              onItemDelete={handleDelete}
              onItemDuplicate={handleDuplicate}
              onRequestCreate={handleNewPresetClick}
            />
          )}

          {showForm && (
            <div className={classNames('template-presets-edit-container', containerWidth > 1100 && 'column-layout')}>
              <div className="form">
                <h5 className="mb-1">Configure Preset</h5>
                <hr className="mt-0" />
                <OptionsForm
                  ref={optionsFormRef}
                  preset={activePreset}
                  onFormReady={() => setFormReady(true)}
                  onChange={handleOptionsChange}
                  onSave={handleOptionsSave}
                />
              </div>
              <div className="preview">
                <h5 className="mb-1">Preview</h5>
                <hr className="mt-0" />
                <Preview
                  presetId={activePresetId}
                  data={previewData}
                  shouldLoad={formReady}
                />
              </div>
            </div>
          )}
        </div>

        <CreatePreset
          show={modalVisible}
          templateOptions={templateOptions}
          onCreate={handleCreate}
          onRequestClose={handleModalRequestClose}
        />
      </ErrorBoundary>
    </div>
  );
};

TemplatePresets.propTypes = {
  headerSubtitle: PropTypes.string,
  templateOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  width: PropTypes.number,
};

export default withResizeDetector(TemplatePresets);
