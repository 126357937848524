import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';
import getDisplayOptionsConfig from './displayOptionsConfig';
import objectTypes from './objectTypes';

const MenuItems = ({ parentType, itemType, value, onChange }) => {
  const { menuItems } = getDisplayOptionsConfig(parentType, itemType);
  const toggleVisible = slug => onChange({ ...value, [slug]: !value[slug] });

  return (
    <div className="player-builder-menu-items-container">
      {menuItems.map(({ slug, label, iconName }) => {
        const isVisible = value[slug] || false;
        return (
          <div key={slug} className={`player-builder-menu-item ${isVisible ? 'active' : ''}`}>
            <span className="d-flex align-items-center" style={{ width: 30, marginRight: 5 }}>
              <Icon name={iconName} />
            </span>
            <span style={{ flex: 1 }}>{label}</span>
            <span key={isVisible} className="visibility-icon" onClick={() => toggleVisible(slug)}>
              <Icon name={isVisible ? 'visibility' : 'visibility_off'} fill size={20} />
            </span>
          </div>
        );
      })}
    </div>
  );
};

MenuItems.propTypes = {
  parentType: PropTypes.oneOf(objectTypes),
  itemType: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
};

export default MenuItems;
