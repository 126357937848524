import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import camelize from 'camelize';
import striptags from 'striptags';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import urlJoin from 'url-join';
import { urls, colors } from 'app-constants';
import { formatters } from 'utils';
import { ErrorList } from 'components/common/inlines';
import QuillEditor from 'components/common/QuillEditor';
import AddLibraryMedia from 'components/common/AddLibraryMedia';
import AddTitleAttachment from 'components/common/AddTitleAttachment';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import Icon from 'components/common/Icon';
import MediaRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/MediaRelationField';
import Select from 'components/common/Select';
import itemFormDefaults from './itemFormDefaults';


class Form extends Component {
  constructor (props) {
    super(props);
    this.defaultState = {
      itemId: null,
      fields: { ...itemFormDefaults },
      itemData: null,
      processing: false,
      error: null,
    };
    this.state = {
      ...this.defaultState,
    };

    this.fieldPrefix = 'annotationBuilderForm-';
    this.numericFields = ['startTime', 'duration'];
    this.requiredFields = [];

    this.handleFormChange = this.handleFormChange.bind(this);
    this.handleFormChange = debounce(this.handleFormChange, 500);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleTypeChange = this.handleTypeChange.bind(this);
    this.handleLinkTargetSelect = this.handleLinkTargetSelect.bind(this);
    this.initFormValues = this.initFormValues.bind(this);
    this.getItemObject = this.getItemObject.bind(this);
    this.errorClass = this.errorClass.bind(this);
  }

  componentDidMount () {
    this.initFormValues();

    const { type } = this.props.item;
    const itemId = ['media', 'title'].includes(type) && this.props.item[type];
    if (itemId) {
      this.fetchData(type, itemId);
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { item } = this.props;
    const prevItem = prevProps.item;

    if (!isEqual(item, prevItem)) {
      this.initFormValues();
    }

    if (item.type !== prevItem.type || item[item.type] !== prevItem[item.type]) {
      const itemId = item[item.type];
      if (!itemId) {
        this.setState({ itemData: null, processing: false, error: null });
      } else {
        this.fetchData(item.type, itemId);
      }
    }

    if (
      (item && !prevItem) || (!item && prevItem) ||
      (item && prevItem && item.itemId !== prevItem.itemId)
    ) {
      // Reset form scroll position when a different item is selected
      if (this.containerRef) {
        this.containerRef.scrollTop = 0;
      }
    }
  }

  componentWillUnmount () {
    this.handleFormChange.flush();
  }

  fetchData (type, id) {
    if (!id) {
      return null;
    }
    const baseUrl = urls[`${type}DataBase`];
    const url = urlJoin(baseUrl, id, '?format=json');
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({ itemData: data, processing: false });
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  handleFormChange () {
    const { onChange, item } = this.props;
    const itemObj = this.getItemObject();

    if (item) {
      // Only call this if we are editing an existing item, not a blank one.
      onChange({ ...itemObj });
    }
  }

  handleFieldChange (fieldName, value) {
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        [fieldName]: value,
      },
    }), this.handleFormChange);
  }

  handleInputChange (e) {
    let { name, value, type, checked } = e.target;
    name = name.replace(this.fieldPrefix, '');
    if (value && this.numericFields.includes(name)) {
      value = parseFloat(value);
    } else if (type === 'checkbox') {
      value = checked;
    } else if (name === 'slug') {
      value = formatters.slug(value);
    }

    this.handleFieldChange(name, value);
  }

  handleTypeChange (e) {
    const newFields = {
      type: e.target.value,
      labelSource: 'custom',
      descriptionSource: 'custom',
      media: null,
      title: null,
    };

    switch (e.target.value) {
      case 'chapter':
        newFields.linkUrl = '';
        break;
      case 'item_link':
        newFields.type = 'media';
        break;
    }

    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        ...newFields,
      },
    }), this.handleFormChange);
  }

  handleLinkTargetSelect (type, id) {
    const newFields = { type, media: null, title: null };
    newFields[type] = id;
    this.setState(prevState => ({
      fields: {
        ...prevState.fields,
        ...newFields,
      },
    }), this.handleFormChange);
  }

  getItemObject () {
    // Convert empty strings to null
    return Object.keys(this.state.fields).reduce((result, key) => {
      result[key] = this.state.fields[key] === '' ? null : this.state.fields[key];
      return result;
    }, {});
  }

  initFormValues () {
    const { item } = this.props;

    if (item) {
      const newFields = Object.keys(item).reduce((result, key) => {
        if (this.defaultState.fields.hasOwnProperty(key)) {
          // Keep explicit false for checkbox fields, but convert undefined or null to empty strings
          result[key] = (item[key] || item[key] === false) ? item[key] : '';
        }
        return result;
      }, {});
      this.setState({ fields: newFields, itemId: item.itemId });
    } else {
      this.setState({ fields: this.defaultState.fields, itemId: null });
    }
  }

  errorClass (fieldName) {
    const { item } = this.props;
    return item && item.errors[fieldName] ? 'has-error' : '';
  }

  renderLabelInput () {
    const { itemData } = this.state;
    const { label, labelSource } = this.state.fields;

    switch (labelSource) {
      case 'item_name':
        return <input key="labelSource1" type="text" className="form-input" value={itemData ? itemData.name : ''} disabled />;
      case 'custom':
        return (
          <input
            key="labelSource2"
            name={this.fieldPrefix + 'label'}
            type="text"
            className="form-input"
            value={label}
            onChange={this.handleInputChange}
          />
        );
      default:
        return <input key="labelSource3" type="text" className="form-input" disabled />;
    }
  }

  renderDescriptionInput () {
    const { itemData } = this.state;
    const { description, descriptionSource } = this.state.fields;

    switch (descriptionSource) {
      case 'item_long_desc':
        return (
          <textarea
            key="descriptionSource1"
            cols="30"
            rows="3"
            className="form-input"
            value={itemData ? striptags(itemData.longDescription) : ''}
            disabled
          />
        );
      case 'item_short_desc':
        return (
          <textarea
            key="descriptionSource2"
            cols="30"
            rows="3"
            className="form-input"
            value={itemData ? striptags(itemData.shortDescription) : ''}
            disabled
          />
        );
      case 'custom':
        return (
          <QuillEditor
            inputName={this.fieldPrefix + 'description'}
            value={description}
            onChange={val => this.handleFieldChange('description', val)}
            size="small"
          />
        );
      default:
        return <textarea key="descriptionSource3" cols="30" rows="3" className="form-input" disabled />;
    }
  }

  renderLinkTargetField () {
    const { type } = this.state.fields;
    const itemId = this.state.fields[type];

    if (!itemId) {
      return (
        <div className="attached-item-preview justify-content-center">
          <AddLibraryMedia onChange={id => this.handleLinkTargetSelect('media', id)}>
            {handleModalTrigger => (
              <button type="button" className="btn btn-primary" onClick={handleModalTrigger}>Select Media</button>
            )}
          </AddLibraryMedia>
          <span style={{ margin: '0 15px' }}>– or –</span>
          <AddTitleAttachment onChange={id => this.handleLinkTargetSelect('title', id)}>
            {handleModalTrigger => (
              <button type="button" className="btn btn-primary" onClick={handleModalTrigger}>Select Title</button>
            )}
          </AddTitleAttachment>
        </div>
      );
    }

    return (
      <AttachedItemPreview
        itemType={type}
        itemId={itemId}
        clickableThumbnail
      >
        <div
          className="btn-delete tooltip tooltip-left"
          data-tooltip="Remove"
          onClick={() => this.handleLinkTargetSelect(type, null)}
        >
          <Icon name="delete" fill />
        </div>
      </AttachedItemPreview>
    );
  }

  render () {
    const { item, onSyncStartTime } = this.props;
    const { type, startTime, duration, linkUrl, label, labelSource, descriptionSource, popupStyle, thumbnailMedia } = this.state.fields;

    if (!item) {
      return null;
    }
    const hasErrors = Object.keys(item.errors).length > 0;

    const isInternalLink = ['media', 'title'].includes(type);

    const labelSourceOptions = [
      { value: 'item_name', label: 'Use item name' },
      { value: 'custom', label: 'Use custom label' },
    ];
    const labelSourceValue = labelSourceOptions.find(opt => opt.value === labelSource);

    const descriptionSourceOptions = [
      { value: 'item_long_desc', label: 'Use item long description' },
      { value: 'item_short_desc', label: 'Use item short description' },
      { value: 'custom', label: 'Use custom description' },
    ];
    const descriptionSourceValue = descriptionSourceOptions.find(opt => opt.value === descriptionSource);

    return (
      <div className="title-builder-form-container" ref={node => this.containerRef = node}>
        <header className="title-builder-form-header">
          <div>
            <h5><span>Editing:</span> {label || '[Unlabeled Annotation]'}</h5>
          </div>
        </header>

        <div className="title-builder-form">
          {hasErrors ? <ErrorList className="mb-5" errors={item.errors} /> : null}

          <div className="form-group mb-5">
            <label className="block-label">Type</label>

            <label className="form-radio">
              <input
                type="radio"
                value="chapter"
                checked={type === 'chapter'}
                onChange={this.handleTypeChange}
              />
              <i className="form-icon" /> <span>Chapter</span> <span className="text-hint ml-1">Link to a specific playhead position.</span>
            </label>

            <label className="form-radio">
              <input
                type="radio"
                value="media"
                checked={isInternalLink}
                onChange={this.handleTypeChange}
              />
              <i className="form-icon" /> Media/Title <span className="text-hint ml-1">Link to a Media or Title object.</span>
            </label>

            <label className="form-radio">
              <input
                type="radio"
                value="link"
                checked={type === 'link'}
                onChange={this.handleTypeChange}
              />
              <i className="form-icon" /> <span>External URL</span> <span className="text-hint ml-1">Link to an external URL.</span>
            </label>
          </div>

          <div className="d-flex mb-5">
            <div style={{ flex: '0 0 50%', paddingRight: 20 }}>
              <div className={`form-group ${this.errorClass('startTime')}`}>
                <label className="block-label">Start Time</label>
                <div className="d-flex align-items-center">
                  <input
                    name={this.fieldPrefix + 'startTime'}
                    type="number"
                    min={0}
                    step={0.1}
                    className="form-input"
                    value={startTime || 0}
                    style={{ width: 100 }}
                    onChange={this.handleInputChange}
                  />
                  <button type="button" className="btn btn-primary ml-2" onClick={onSyncStartTime}>Sync to Playhead</button>
                </div>
                <p className="form-input-hint mt-1">Start time, in total seconds from media start.</p>
              </div>
            </div>

            <div style={{ flex: '0 0 50%', paddingLeft: 20, borderLeft: `1px solid ${colors.grayLight}` }}>
              <div className={`form-group ${this.errorClass('duration')}`}>
                <label className="block-label">Duration</label>
                <input
                  name={this.fieldPrefix + 'duration'}
                  type="number"
                  min={0}
                  step={0.1}
                  className="form-input"
                  value={duration || ''}
                  style={{ width: 100 }}
                  onChange={this.handleInputChange}
                />
                <p className="form-input-hint mt-1">Popup duration, in seconds.</p>
              </div>
            </div>
          </div>

          {type === 'link' && (
            <div className={`form-group mb-5 ${this.errorClass('linkUrl')}`}>
              <label className="block-label">Link URL</label>
              <p className="form-input-hint mb-1">Enter a URL for this item to link to.</p>
              <input
                name={this.fieldPrefix + 'linkUrl'}
                type="text"
                className="form-input"
                value={linkUrl || ''}
                onChange={this.handleInputChange}
              />
            </div>
          )}

          {isInternalLink && (
            <div className={`form-group mb-5 ${this.errorClass('media')}`}>
              <label className="block-label">Linked item</label>
              <p className="form-input-hint mb-1">Select a Media or Title object for this annotation to link to.</p>
              {this.renderLinkTargetField()}
            </div>
          )}

          <div className={classNames('form-group', 'mb-3', this.errorClass('label'), this.errorClass('labelSource'))}>
            <label className="block-label">Label</label>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
                <Select
                  value={labelSourceValue}
                  options={labelSourceOptions}
                  isDisabled={!isInternalLink}
                  isSearchable={false}
                  onChange={val => this.handleFieldChange('labelSource', val)}
                />
              </div>
              <div style={{ flex: 1, minWidth: 300 }}>
                {this.renderLabelInput()}
              </div>
            </div>
          </div>

          <div className={classNames('form-group', 'mb-3', this.errorClass('description'), this.errorClass('descriptionSource'))}>
            <label className="block-label">Description</label>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div style={{ flex: '0 0 240px', margin: '0 10px 10px 0' }}>
                <Select
                  value={descriptionSourceValue}
                  options={descriptionSourceOptions}
                  isDisabled={!isInternalLink}
                  isSearchable={false}
                  onChange={val => this.handleFieldChange('descriptionSource', val)}
                />
              </div>
              <div style={{ flex: 1, minWidth: 300 }}>
                {this.renderDescriptionInput()}
              </div>
            </div>
          </div>

          <div className="form-group mb-5">
            <label className="block-label">Popup Style</label>

            <label className="form-radio">
              <input
                type="radio"
                name={this.fieldPrefix + 'popupStyle'}
                value="none"
                checked={popupStyle === 'none'}
                onChange={this.handleInputChange}
              />
              <i className="form-icon" /> <span>None</span> <span className="text-hint ml-1">No popup.</span>
            </label>

            <label className="form-radio">
              <input
                type="radio"
                name={this.fieldPrefix + 'popupStyle'}
                value="compact"
                checked={popupStyle === 'compact'}
                onChange={this.handleInputChange}
              />
              <i className="form-icon" /> <span>Compact</span> <span className="text-hint ml-1">Popup displays label only.</span>
            </label>

            <label className="form-radio">
              <input
                type="radio"
                name={this.fieldPrefix + 'popupStyle'}
                value="expanded"
                checked={popupStyle === 'expanded'}
                onChange={this.handleInputChange}
              />
              <i className="form-icon" /> <span>Expanded</span> <span className="text-hint ml-1">Popup displays label, description, and thumbnail.</span>
            </label>
          </div>

          <div className={`form-group mb-3 ${this.errorClass('thumbnailMedia')}`}>
            <label className="block-label">Thumbnail</label>
            <p className="form-input-hint m-0">
              Select a thumbnail image.
              {isInternalLink && ' If no image is specified, the linked item’s thumbnail will be displayed.'}
            </p>
            <MediaRelationField
              value={thumbnailMedia}
              onChange={val => this.handleFieldChange('thumbnailMedia', val)}
            />
          </div>
        </div>
      </div>
    );
  }
}

Form.propTypes = {
  item: PropTypes.object,
  onSyncStartTime: PropTypes.func,
  onChange: PropTypes.func,
};

export default Form;
