import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CustomLinkForm = ({ onSubmit }) => {
  const [fields, setFields] = useState({ label: '', url: '' });
  const handleFieldChange = evt => {
    const key = evt.target.name.replace('customLink-', '');
    const value = evt.target.value;
    setFields(oldState => ({ ...oldState, [key]: value }));
  };
  const handleSubmit = evt => {
    evt.preventDefault();
    onSubmit(fields);
    setFields({ label: '', url: '' });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group mb-3">
        <label className="block-label strong">Custom Link</label>
        <div className="form-input-hint mt-0 mb-1">Link to an external URL.</div>
      </div>

      <div className="form-group mb-2">
        <label className="block-label alt">Label Text</label>
        <input
          name="customLink-label"
          type="text"
          className="form-input"
          value={fields.label}
          onChange={handleFieldChange}
        />
      </div>

      <div className="form-group mb-2">
        <label className="block-label alt">URL</label>
        <input
          name="customLink-url"
          type="text"
          className="form-input"
          value={fields.url}
          onChange={handleFieldChange}
        />
      </div>

      <div className="flex-right mb-4">
        <button
          type="submit"
          className="btn"
          disabled={!fields.label}
        >
          Add
        </button>
      </div>
    </form>
  );
};

CustomLinkForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default CustomLinkForm;
