import TimelineBuilder from './TimelineBuilder';

// Constants
const MEDIA_SOURCE_URL = 'url';
const MEDIA_SOURCE_LIBRARY = 'media_library';
const VIMEO_URL_PATTERN = /vimeo\.com\/.+/;
const YOUTUBE_URL_PATTERN = /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})/;
const SOUNDCLOUD_URL_PATTERN = /(soundcloud\.com|snd\.sc)\/.+$/;

export default TimelineBuilder;
export { MEDIA_SOURCE_URL, MEDIA_SOURCE_LIBRARY, VIMEO_URL_PATTERN, YOUTUBE_URL_PATTERN, SOUNDCLOUD_URL_PATTERN };
