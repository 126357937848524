import React from 'react';
import PropTypes from 'prop-types';
import renderBlockErrors from './renderBlockErrors';
import Select from 'components/common/Select';
import MediaRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/MediaRelationField';
import TitleRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/TitleRelationField';
import FormGroup from './FormGroup';


const SOURCE_OPTIONS = [
  { value: 'item', label: 'Current Item', helpText: 'Display metadata associated with the current active item' },
  { value: 'release', label: 'Release', helpText: 'Display metadata associated with the release' },
  { value: 'media', label: 'Media', helpText: 'Display metadata associated with a specific media object' },
  { value: 'title', label: 'Title', helpText: 'Display metadata associated with a specific title' },
];

const RelatedLinks = ({
  uid,
  errors,
  allowSubItemSource = true,
  source,
  media,
  title,
  listStyle,
  displayUrls,
  useSubItems,
  onChange,
}) => {
  const createHandler = name => val => onChange({ [name]: val });
  const handleSourceChange = val => {
    const updates = { source: val };
    if (val !== 'title') {
      updates.title = null;
    }
    if (val !== 'media') {
      updates.media = null;
    }
    onChange(updates);
  };
  const handleListStyleChange = val => onChange({ listStyle: val });
  const handleDisplayUrlsChange = e => onChange({ displayUrls: e.target.checked });
  const handleUseSubItemsChange = e => onChange({ useSubItems: e.target.checked });
  const listStyleOptions = [
    { value: 'none', label: 'None' },
    { value: 'bulleted', label: 'Bulleted' },
    { value: 'numbered', label: 'Numbered' },
  ];
  const listStyleSelectVal = listStyleOptions.find(opt => opt.value === listStyle);
  const sourceSelectVal = SOURCE_OPTIONS.find(opt => opt.value === source);

  const renderedErrors = renderBlockErrors(errors);
  const showSubItemsField = allowSubItemSource && source === 'item';

  return (
    <div className="content">
      <FormGroup className="mb-3" label="Source:">
        <Select
          value={sourceSelectVal}
          options={SOURCE_OPTIONS}
          onChange={handleSourceChange}
        />
        {renderedErrors.source}
      </FormGroup>

      {source === 'media' && (
        <FormGroup className="mb-3">
          <MediaRelationField className="m-0" value={media} onChange={createHandler('media')} />
          {renderedErrors.media}
        </FormGroup>
      )}

      {source === 'title' && (
        <FormGroup className="mb-3">
          <TitleRelationField className="m-0" value={title} onChange={createHandler('title')} />
          {renderedErrors.title}
        </FormGroup>
      )}

      {showSubItemsField && (
        <FormGroup className="mb-1" alignItems="baseline" label="Source:">
          <label className="form-checkbox">
            <input type="checkbox" checked={useSubItems} onChange={handleUseSubItemsChange} />
            <i className="form-icon" /> Include Sub-items
            <div className="text-hint">
              If this option is selected, metadata will be sourced from the current active item <em>within</em> the current title (for supported title types) — for example, an image within a slideshow title or a video within a playlist title. Deselect this option to source metadata only from the title itself.
            </div>
          </label>
          {renderedErrors.useSubItems}
        </FormGroup>
      )}

      <FormGroup className="mb-1" label="List Style:">
        <Select
          value={listStyleSelectVal}
          options={listStyleOptions}
          onChange={handleListStyleChange}
        />
        {renderedErrors.listStyle}
      </FormGroup>

      <FormGroup alignItems="baseline">
        <label className="form-checkbox">
          <input type="checkbox" checked={displayUrls} onChange={handleDisplayUrlsChange} />
          <i className="form-icon" /> Display full URLs along with label text.
        </label>
        {renderedErrors.displayUrls}
      </FormGroup>
    </div>
  );
};

RelatedLinks.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  allowSubItemSource: PropTypes.bool,
  source: PropTypes.oneOf(['', ...SOURCE_OPTIONS.map(opt => opt.value)]),
  media: PropTypes.string,
  title: PropTypes.string,
  listStyle: PropTypes.oneOf(['none', 'bulleted', 'numbered']),
  displayUrls: PropTypes.bool,
  useSubItems: PropTypes.bool,
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default RelatedLinks;
