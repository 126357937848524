import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import withCSRF from 'components/common/withCSRF';
import LoadingOverlay from 'components/common/LoadingOverlay';


class ConfirmDelete extends Component {
  constructor (props) {
    super(props);
    this.state = {
      processing: false,
      error: null,
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
  }

  handleFormSubmit (e) {
    e.preventDefault();
    this.doDelete();
  }

  handleCancelClick (e) {
    const { onCancel } = this.props;
    e.preventDefault();
    onCancel();
  }

  doDelete () {
    const { id, onSuccess, csrfToken } = this.props;
    const url = urlJoin(urls.integrationDeleteBase, id, '/');
    this.setState({ processing: true });
    fetch(url, {
      credentials: 'include',
      method: 'DELETE',
      headers: { 'X-CSRFToken': csrfToken },
    })
      .then(response => {
        if (response.status !== 204) {
          this.setState({ error: 'Deletion failed.' });
        }
        return response;
      })
      .then(() => {
        this.setState({ processing: false });
        onSuccess();
      })
      .catch(err => {
        this.setState({ processing: false, error: 'Deletion failed.' });
        console.error(err);
      });
  }

  render () {
    const { processing, error } = this.state;
    return error ? (
      <div className="toast toast-error">Error: {error}</div>
    ) : (
      <div style={{ position: 'relative' }}>
        <p>This integration will be deleted immediately.</p>
        <form onSubmit={this.handleFormSubmit}>
          <hr />
          <div className="modal-action-buttons">
            <button className="btn" onClick={this.handleCancelClick}>Cancel</button>
            <button className="btn btn-error" type="submit">Confirm</button>
          </div>
        </form>
        <LoadingOverlay show={processing} />
      </div>
    );
  }
}

ConfirmDelete.propTypes = {
  csrfToken: PropTypes.string,
  id: PropTypes.string,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default withCSRF(ConfirmDelete);
