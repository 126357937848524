import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import snakeize from 'snakeize';
import isEqual from 'lodash/isEqual';
import FontFaceObserver from 'fontfaceobserver';
import FontMetrics from 'fontmetrics';
import FamilySelect from './FamilySelect';

const EMPTY_VAL = {
  source: '',
  kitId: '',
  family: '',
  scaleFactor: 1,
  fallbacks: '',
  weights: [],
};

// Aspect ratio of Open Sans, the default template font
const STANDARD_FONT_AR = 0.54;

const FontField = ({ style = {}, field, googleKey, onChange = () => {} }) => {
  const [value, setValue] = useState(EMPTY_VAL);

  useEffect(() => {
    if (field) {
      const newValue = {
        ...EMPTY_VAL,
        ...camelize(JSON.parse(field.value || '{}')),
      };
      if (!isEqual(value, newValue)) {
        setValue(newValue);
      }
    }
  }, [field, value]);

  const handleChange = value => {
    setValue(value);
    onChange(JSON.stringify(snakeize(value)));
  };

  const handleSourceChange = evt => handleChange({ ...EMPTY_VAL, source: evt.target.value });
  const handleKitIdChange = evt => handleChange({ ...value, kitId: evt.target.value });
  const handleFamilyChange = val => {
    handleChange({ ...value, ...val });

    if (val.family && value.source !== 'local') {
      const font = new FontFaceObserver(val.family);
      font.load()
        .then(() => {
          const scaleFactor = getDefaultScaleFactor(val.family);
          handleChange({ ...value, ...val, scaleFactor });
        })
        .catch(err => console.error(err));
    }
  };
  const handleScaleFactorChange = evt => handleChange({ ...value, scaleFactor: evt.target.value / 100 });

  const [familyErrors, setFamilyErrors] = useState([]);
  const handleFamilyErrorStateChange = errors => setFamilyErrors(errors);

  const getDefaultScaleFactor = fontFamily => {
    const metrics = FontMetrics({ fontFamily });
    const ar = Math.abs(metrics.xHeight);
    let scaleFactor = parseFloat((STANDARD_FONT_AR / ar).toFixed(3));
    // round to nearest 5%
    scaleFactor = (Math.round(scaleFactor * 100 / 5) * 5) / 100;
    return scaleFactor;
  };

  const inputVal = JSON.stringify(snakeize(value));

  return (
    <>
      <div className="font-field-widget" style={style}>
        <div className="mr-3">
          <label className="block-label">Source</label>
          <select name="fontfield_source" className="form-select" value={value.source} onChange={handleSourceChange}>
            <option value="google">Google</option>
            <option value="typekit">Typekit</option>
            <option value="local">Local</option>
          </select>
        </div>

        {value.source === 'typekit' && (
          <div className="mr-3">
            <label className="block-label">Kit ID</label>
            <input
              name="fontfield_kit_id"
              type="text"
              className="form-input"
              value={value.kitId}
              onChange={handleKitIdChange}
            />
          </div>
        )}

        <div className="mr-3" style={{ flex: 1 }}>
          <label className="block-label">Font Family</label>
          <FamilySelect
            source={value.source || ''}
            kitId={value.kitId}
            selectedFamily={value.family}
            googleKey={googleKey}
            onChange={handleFamilyChange}
            onErrorStateChange={handleFamilyErrorStateChange}
          />
        </div>

        <div>
          <label className="block-label">Scale</label>
          <div className="input-group">
            <input
              name="fontfield_scale_factor"
              type="number"
              min="50"
              max="300"
              className="form-input"
              value={Math.round(value.scaleFactor * 100)}
              onChange={handleScaleFactorChange}
            />
            <span className="input-group-addon">%</span>
          </div>
        </div>
      </div>
      {familyErrors.map((message, idx) => <p key={idx} className="form-input-hint text-error mb-0">{message}</p>)}
      {field && <input type="hidden" name={field.name} value={inputVal} />}
    </>
  );
};


FontField.propTypes = {
  style: PropTypes.object,
  field: PropTypes.object,
  googleKey: PropTypes.string,
  onChange: PropTypes.func,
};

export default FontField;
