import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getCroppedImg } from 'utils';
import ReactCrop, { makeAspectCrop, getPixelCrop } from 'react-image-crop';
import Icon from 'components/common/Icon';
import Modal from './Modal';
import DragDropFileInput from './DragDropFileInput';

const parseCropVal = cropString => {
  const [x1, y1, x2, y2] = cropString.split(',').map(v => parseFloat(v));
  return {
    x: x1,
    y: y1,
    width: x2 - x1,
    height: y2 - y1,
  };
};

const stringifyCropVal = cropObject => {
  const { x, y, width, height } = cropObject;
  return [x, y, x + width, y + height].join(',');
};


class PreviewImage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      modalVisible: false,
      modalView: null,
      croppedImage: null,
      rejectedUpload: false,
      activeEdits: {
        crop: {},
        pixelCrop: {},
        vertical: false,
        image: null,
        uploadedFile: null,
        useFile: false,
      },
      commitedEdits: {
        crop: {},
        pixelCrop: {},
        vertical: false,
        image: null,
        uploadedFile: null,
        useFile: false,
      },
    };
    this.handleZoomClick = this.handleZoomClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.handleModalRequestClose = this.handleModalRequestClose.bind(this);
    this.handleCropChange = this.handleCropChange.bind(this);
    this.handleModeChange = this.handleModeChange.bind(this);
    this.handleImageLoaded = this.handleImageLoaded.bind(this);
    this.handleCropComplete = this.handleCropComplete.bind(this);
    this.handleFileDrop = this.handleFileDrop.bind(this);
    this.handleAcceptClick = this.handleAcceptClick.bind(this);
    this.clearCurrentCrop = this.clearCurrentCrop.bind(this);
    this.resetCurrentEdits = this.resetCurrentEdits.bind(this);
    this.setDefaultCrop = this.setDefaultCrop.bind(this);
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    // Restore saved crop from bound form field values. This comes into play when
    // we are re-rendering a bound form with validation errors.
    const { inputImageValue, inputCropValue, inputUseDefaultValue } = nextProps;
    if (inputImageValue) {
      return {
        croppedImage: inputImageValue,
        commitedEdits: {
          crop: inputCropValue ? parseCropVal(inputCropValue) : {},
          useFile: inputUseDefaultValue !== '1',
        },
      };
    }
    return {};
  }

  componentDidUpdate (prevProps, prevState) {
    const { activeEdits: { useFile } } = this.state;

    if (useFile !== prevState.activeEdits.useFile) {
      this.clearCurrentCrop();
      this.setState({ rejectedUpload: false });
    }
  }

  handleZoomClick (e) {
    e.preventDefault();
    this.setState({ modalVisible: true, modalView: 'zoom' });
  }

  handleEditClick (e) {
    e.preventDefault();
    this.setState({ modalVisible: true, modalView: 'edit' });
  }

  handleModalRequestClose () {
    this.setState({ modalVisible: false });
    this.resetCurrentEdits();
  }

  handleCropChange (crop, pixelCrop) {
    this.setState({
      activeEdits: {
        ...this.state.activeEdits,
        crop,
        pixelCrop,
      },
    });
  }

  handleModeChange (e) {
    this.setState({
      activeEdits: {
        ...this.state.activeEdits,
        useFile: e.target.value === 'upload',
      },
    });
  }

  handleImageLoaded (image) {
    const { activeEdits } = this.state;

    // Don't reset the crop if we're already displaying an edited preview
    if (!Object.keys(activeEdits.crop).length) {
      this.setDefaultCrop(image);
    }
  }

  handleCropComplete (crop) {
    const { activeEdits: { image } } = this.state;

    // Reset to the default crop if no selection was made
    if (crop.width === 0 || crop.height === 0) {
      this.setDefaultCrop(image);
    }
  }

  handleFileDrop (accepted, rejected) {
    const [file] = accepted;
    this.setState({
      activeEdits: { ...this.state.activeEdits, uploadedFile: file },
      rejectedUpload: rejected.length > 0,
    });
  }

  handleAcceptClick (e) {
    e.preventDefault();
    const { activeEdits } = this.state;
    const croppedImage = getCroppedImg(activeEdits.image, activeEdits.pixelCrop);
    this.setState({
      croppedImage,
      commitedEdits: { ...activeEdits },
      modalVisible: false,
    });
  }

  clearCurrentCrop () {
    this.setState({
      activeEdits: {
        ...this.state.activeEdits,
        crop: {},
        pixelCrop: {},
      },
    });
  }

  resetCurrentEdits () {
    const { commitedEdits } = this.state;
    this.setState({ activeEdits: { ...commitedEdits } });
  }

  setDefaultCrop (image) {
    const { aspectRatio } = this.props;
    const nativeRatio = image.width / image.height;

    let attrs = {};
    if (aspectRatio > nativeRatio) {
      attrs = {
        x: 0,
        y: Math.round(image.height / 2 - (image.width / aspectRatio) / 2) / image.height * 100,
        width: 100,
      };
    } else {
      attrs = {
        x: Math.round(image.width / 2 - (image.height * aspectRatio) / 2) / image.width * 100,
        y: 0,
        height: 100,
      };
    }

    const percentCrop = makeAspectCrop({
      aspect: aspectRatio,
      ...attrs,
    }, nativeRatio);

    const pixelCrop = getPixelCrop(image, percentCrop);

    this.setState({
      activeEdits: {
        ...this.state.activeEdits,
        vertical: image.height > image.width,
        image: image,
        crop: percentCrop,
        pixelCrop: pixelCrop,
      },
    });
  }

  renderEditor () {
    const { defaultImgUrl } = this.props;
    const { rejectedUpload, activeEdits: { crop, useFile, uploadedFile } } = this.state;

    if (!useFile) {
      return (
        <ReactCrop
          src={defaultImgUrl}
          crop={crop}
          onChange={this.handleCropChange}
          onImageLoaded={this.handleImageLoaded}
          onComplete={this.handleCropComplete}
          crossorigin="anonymous"
        />
      );
    } else if (uploadedFile) {
      return (
        <ReactCrop
          src={uploadedFile.preview}
          crop={crop}
          onChange={this.handleCropChange}
          onImageLoaded={this.handleImageLoaded}
          onComplete={this.handleCropComplete}
          crossorigin="anonymous"
        />
      );
    } else {
      return (
        <div>
          {rejectedUpload ? <div className="toast toast-error mb-4">Error: Invalid image format.</div> : null}
          <DragDropFileInput
            onFileDrop={this.handleFileDrop}
            accept="image/jpeg,image/png,.jpg,.png"
            multiple={false}
          />
        </div>
      );
    }
  }

  renderModalContent () {
    const { label, slug, largeImgUrl, defaultImgUrl } = this.props;
    const { modalView, croppedImage, activeEdits: { useFile } } = this.state;
    // const canvasStyle = vertical ? { height: 600 } : null; // buggy
    const canvasStyle = { height: 600 };

    return modalView === 'edit' ? (
      <div>
        <hr className="mt-0" />
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h5 className="m-0">{label}</h5>
          <div className="form-group">
            <label className={`form-radio form-inline mr-3 ${!defaultImgUrl ? 'text-gray' : ''}`}>
              <input type="radio" value="default" checked={!useFile} onChange={this.handleModeChange} disabled={!defaultImgUrl} />
              <i className="form-icon" /> Use default image
            </label>
            <label className="form-radio form-inline">
              <input type="radio" value="upload" checked={useFile} onChange={this.handleModeChange} />
              <i className="form-icon" /> Upload a file
            </label>
          </div>
        </div>
        <hr className="mb-4" />
        <div className="media-preview-image-canvas" style={canvasStyle}>
          {this.renderEditor()}
        </div>
      </div>
    ) : (
      <div>
        <hr className="mt-0 mb-5" />
        <div className="media-preview-image-canvas" style={canvasStyle}>
          <img src={croppedImage || largeImgUrl} alt={slug} className="zoomed" />
        </div>
      </div>
    );
  }

  renderInputs () {
    const { inputImageName, inputCropName, inputUseDefaultName } = this.props;
    const { croppedImage, commitedEdits: { crop, useFile } } = this.state;

    let cropVal = '';
    if (Object.keys(crop).length) {
      cropVal = stringifyCropVal(crop);
    }

    if (croppedImage) {
      return [
        <input type="hidden" key={inputCropName} name={inputCropName} value={cropVal} />,
        <input type="hidden" key={inputImageName} name={inputImageName} value={croppedImage} />,
        <input type="hidden" key={inputUseDefaultName} name={inputUseDefaultName} value={useFile ? '0' : '1'} />,
      ];
    }
  }

  render () {
    const { label, slug, imgUrl, isThumbnail, hasError, editable = true } = this.props;
    const { modalVisible, modalView, croppedImage } = this.state;
    const modalHeaderContent = modalView === 'edit' ? (
      <>
        <h2 className="alt-head">Edit Image Preview</h2>
        <div>
          <button className="btn mr-2" onClick={this.handleModalRequestClose}>Cancel</button>
          <button className="btn btn-primary" onClick={this.handleAcceptClick}>Accept</button>
        </div>
      </>
    ) : null;

    const imgStyle = isThumbnail ? { height: '80px' } : null;

    return (
      <div className={`media-preview-image card-item ${hasError ? 'error' : ''}`}>
        <header>
          <h6>{label}</h6>
          {editable && (
            <div className="actions">
              <button
                className="btn btn-primary tooltip"
                data-tooltip="Edit"
                onClick={this.handleEditClick}
              >
                <Icon name="edit" size={24} />
              </button>
            </div>
          )}
        </header>
        <div className="media-preview-image-version">
          <figure>
            <img
              src={croppedImage || imgUrl} alt={slug} style={imgStyle}
              onClick={this.handleZoomClick}
            />
          </figure>
        </div>
        <Modal
          title={label}
          headerContent={modalHeaderContent}
          className="deep"
          style={{ content: { width: 660 } }}
          isOpen={modalVisible}
          onRequestClose={this.handleModalRequestClose}
        >
          {this.renderModalContent()}
        </Modal>
        {editable && this.renderInputs()}
      </div>
    );
  }
}

PreviewImage.propTypes = {
  editable: PropTypes.bool,
  label: PropTypes.string,
  slug: PropTypes.string,
  inputImageName: PropTypes.string,
  inputImageValue: PropTypes.string,
  inputCropName: PropTypes.string,
  inputCropValue: PropTypes.string,
  inputUseDefaultName: PropTypes.string,
  inputUseDefaultValue: PropTypes.string,
  defaultImgUrl: PropTypes.string,
  imgUrl: PropTypes.string,
  largeImgUrl: PropTypes.string,
  isThumbnail: PropTypes.bool,
  aspectRatio: PropTypes.number,
  hasError: PropTypes.bool,
};

export default PreviewImage;
