import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import camelize from 'camelize';
import { urls } from 'app-constants';
import Icon from 'components/common/Icon';
import LoadingSpinner from 'components/common/LoadingSpinner';

const JobProcessingIndicator = ({
  jobId,
  titleTextActive = 'Processing',
  titleTextComplete = 'Complete',
  titleTextFailed = 'Error',
  statusTextActive,
  statusTextComplete,
  statusTextFailed,
}) => {
  const STATUS_ACTIVE = 'active';
  const STATUS_COMPLETE = 'complete';
  const STATUS_FAILED = 'failed';

  const pollInterval = 1000;
  const pollTimeout = useRef();

  useEffect(() => {
    pollTimeout.current = setTimeout(() => pollStatus(), pollInterval);
  }, []);

  const [status, setStatus] = useState(STATUS_ACTIVE);

  let titleText;
  let statusText;
  switch (status) {
    case STATUS_ACTIVE:
      titleText = titleTextActive;
      statusText = statusTextActive;
      break;
    case STATUS_COMPLETE:
      titleText = titleTextComplete;
      statusText = statusTextComplete;
      break;
    case STATUS_FAILED:
      titleText = titleTextFailed;
      statusText = statusTextFailed;
      break;
    default:
      break;
  }

  const pollStatus = () => {
    const url = urlJoin(urls.asyncJobDetailBase, jobId, '/');
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          console.error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        const { status } = data;
        let curStatus;
        switch (status) {
          case 'queued':
            // fall through
          case 'scheduled':
            // fall through
          case 'started':
            curStatus = STATUS_ACTIVE;
            break;
          case 'finished':
            curStatus = STATUS_COMPLETE;
            break;
          case 'deferred':
            // fall through
          case 'failed':
            curStatus = STATUS_COMPLETE;
            break;
          default:
            break;
        }

        setStatus(curStatus);

        if (curStatus === STATUS_ACTIVE) {
          pollTimeout.current = setTimeout(() => pollStatus(), pollInterval);
        } else {
          clearTimeout(pollTimeout.current);
          pollTimeout.current = null;
        }
      })
      .catch(err => {
        console.error(err);
      });
  };

  let statusIndicator = <LoadingSpinner size={70} style={{ margin: '0 auto' }} />;
  if (status === STATUS_COMPLETE) {
    statusIndicator = (
      <div className="empty-icon">
        <Icon name="check_circle" fill />
      </div>
    );
  } else if (status === STATUS_FAILED) {
    statusIndicator = (
      <div className="empty-icon">
        <Icon name="warning" />
      </div>
    );
  }

  return (
    <div className="p-4" style={{ flex: 1 }}>
      <div className="empty">
        {statusIndicator}
        {titleText && <p className="empty-title h3">{titleText}</p>}
        {statusText && <p className="empty-subtitle">{statusText}</p>}
      </div>
    </div>
  );
};


JobProcessingIndicator.propTypes = {
  jobId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  titleTextActive: PropTypes.string,
  titleTextComplete: PropTypes.string,
  titleTextFailed: PropTypes.string,
  statusTextActive: PropTypes.string,
  statusTextComplete: PropTypes.string,
  statusTextFailed: PropTypes.string,
};

export default JobProcessingIndicator;
