import React, { useState } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import isUrl from 'is-url';
import { useCSRF } from 'hooks';
import { urls } from 'app-constants';


const YouTube = ({ onComplete = () => {} }) => {
  const [video, setVideo] = useState('');
  const [bulkImport, setBulkImport] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [invalidVideo, setInvalidVideo] = useState(false);
  const [error, setError] = useState(null);

  const handleInputChange = evt => setVideo(evt.target.value);

  const handleFormSubmit = evt => {
    evt.preventDefault();
    const videoList = parseInputValue(video);
    if (videoList.length) {
      if (videoList.some(item => !validateItem(item))) {
        setInvalidVideo(true);
      } else {
        setInvalidVideo(false);
        doImport(videoList);
      }
    }
  };

  const handleBulkImportToggle = evt => {
    evt.preventDefault();
    setBulkImport(!bulkImport);
  };

  const parseInputValue = val => val.split('\n').map(s => s.trim()).filter(s => !!s);
  const validateItem = item => !(isUrl(item) && !item.toLowerCase().includes('youtu'));

  const csrfToken = useCSRF();
  const doImport = videoList => {
    const url = urls.youtubeImport;
    const body = JSON.stringify({ videos: videoList });

    setProcessing(true);
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (!response.ok) {
          setError(response.statusText);
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        setProcessing(false);
        onComplete && onComplete();
      })
      .catch(err => {
        setProcessing(false);
        console.error(err);
      });
  };

  if (error) {
    return (
      <div className="media-import-url">
        <div className="toast toast-error">Import failed: {error}</div>
      </div>
    );
  }

  return (
    <div className="media-import-url">
      <form onSubmit={handleFormSubmit}>
        <div className={`form-group ${invalidVideo ? 'has-error' : ''}`}>
          <div className="input-group">
            <div className="has-icon-right" style={{ flex: 1, display: 'flex' }}>
              {bulkImport ? (
                <textarea
                  rows={10}
                  className="form-input"
                  placeholder="URLs or YouTube IDs, one per line"
                  readOnly={processing}
                  onChange={handleInputChange}
                  value={video}
                  autoFocus
                />
              ) : (
                <input
                  type="text"
                  className="form-input"
                  placeholder="URL or YouTube ID"
                  readOnly={processing}
                  onChange={handleInputChange}
                  value={video}
                  autoFocus
                />
              )}
              {processing && <i className="form-icon loading" />}
            </div>
            <button type="submit" className="btn btn-primary input-group-btn">Submit</button>
          </div>
          {invalidVideo && <div className="form-input-hint">Enter a valid YouTube URL or video ID</div>}
        </div>
        <div className="flex-spread">
          {bulkImport
            ? <div>Import public videos by entering their URLs or YouTube IDs, one per line.</div>
            : <div>Import a public video by entering its URL or YouTube ID.</div>}
          <div><a href="#" className="text-sm" onClick={handleBulkImportToggle}>[+/-] bulk import</a></div>
        </div>
      </form>
    </div>
  );
};

YouTube.propTypes = {
  onComplete: PropTypes.func,
};

export default YouTube;
