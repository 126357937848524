import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import classNames from 'classnames';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { useCSRF } from 'hooks';
import Modal from 'components/common/Modal';


const DuplicatePreset = ({ preset, onDuplicate, onRequestClose }) => {
  const [processing, setProcessing] = useState(false);

  const [name, setName] = useState('');
  const handleNameChange = e => setName(e.target.value);
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    if (!preset) {
      setName('');
      setProcessing(false);
      setFieldErrors({});
    }
  }, [preset]);

  const csrfToken = useCSRF();
  const handleFormSubmit = e => {
    e.preventDefault();
    if (!preset) return null;
    setProcessing(true);

    const url = urlJoin(urls.presetDuplicateBase, preset.id, '/');
    const body = JSON.stringify({ name });
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (response.status === 400) {
          // Form validation errors
          response.json().then(data => {
            setProcessing(false);
            setFieldErrors(camelize(data));
          });
        } else if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          response.json().then(data => {
            setProcessing(false);
            onDuplicate(camelize(data));
          });
        }
        return response;
      })
      .catch(err => {
        setProcessing(false);
        console.error(err);
      });
  };

  return (
    <Modal
      title="Duplicate Preset"
      style={{ content: { width: 400 } }}
      isOpen={!!preset}
      onRequestClose={onRequestClose}
    >
      {preset && (
        <form onSubmit={handleFormSubmit}>
          <p>This action will create an exact copy of the “{preset.name}” preset.</p>
          <div className={classNames('form-group', fieldErrors.name && 'has-error')}>
            <label>Name</label>
            <div className="has-icon-right" style={{ flex: 1, display: 'flex' }}>
              <input
                type="text"
                className="form-input input-lg"
                readOnly={processing}
                onChange={handleNameChange}
                value={name}
                autoFocus
              />
              {processing ? <i className="form-icon loading" /> : null}
            </div>
            {(fieldErrors.name || []).map((msg, idx) => <p key={idx} className="form-input-hint">{msg}</p>)}
          </div>

          <div className="modal-action-buttons mt-4">
            <button className="btn" type="button" onClick={onRequestClose}>Cancel</button>
            <button className="btn btn-primary" type="submit" disabled={!name}>Submit</button>
          </div>
        </form>
      )}
    </Modal>
  );
};

DuplicatePreset.propTypes = {
  preset: PropTypes.object,
  onDuplicate: PropTypes.func,
  onRequestClose: PropTypes.func,
};

export default DuplicatePreset;
