import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { validateLat, validateLng, validateMapZoom } from 'utils';
import { Map, TileLayer, AttributionControl } from 'react-leaflet';
import { withResizeDetector } from 'react-resize-detector';


// Correct longitude values when map is panned beyond bounds
const correctLongitude = value => {
  while (value < -180) value += 360;
  while (value > 180) value -= 360;
  return value;
};

const MapViewportWidget = ({
  enableZoom,
  enableCenter,
  latitude,
  longitude,
  zoom,
  disableScrollWheelZoom,
  defaultViewport,
  height: containerHeight,
  onChange,
}) => {
  const validatedZoom = validateMapZoom(zoom);
  const { value: validatedLat, error: latError } = validateLat(latitude);
  const { value: validatedLng, error: lngError } = validateLng(longitude);

  const defaultZoom = enableZoom ? validatedZoom : defaultViewport.zoom;
  const defaultCenter = enableCenter ? [validatedLat, validatedLng] : defaultViewport.center;
  const [viewport, setViewport] = useState({ center: defaultCenter, zoom: defaultZoom });
  const mapRef = useRef();

  useEffect(() => {
    // Trigger Leaflet re-render if container size changes
    if (mapRef.current) mapRef.current.leafletElement.invalidateSize(false);
  }, [containerHeight]);

  useEffect(() => {
    if (enableZoom && typeof validatedZoom === 'number') {
      setViewport({ ...viewport, zoom: validatedZoom });
    }
  }, [enableZoom, validatedZoom]);

  useEffect(() => {
    if (enableCenter && !latError && !lngError) {
      setViewport({ ...viewport, center: [validatedLat, validatedLng] });
    }
  }, [enableCenter, validatedLat, validatedLng, latError, lngError]);

  useEffect(() => {
    // Trigger change callback
    if (onChange) {
      const { center: [latitude, longitude], zoom } = viewport;
      onChange({ latitude, longitude: correctLongitude(longitude), zoom });
    }
  }, [viewport]);

  // Direct input of lat/lon coordinates
  // const handleInputChange = e => {
  //   let { center: [latitude, longitude], zoom } = viewport;
  //   const { name, value } = e.target;
  //
  //   if (name === 'lat') {
  //     latitude = value;
  //   } else if (name === 'lng') {
  //     longitude = value;
  //   }
  //   onChange({ latitude, longitude, zoom });
  // };

  return (
    <div className="location-widget">
      <div className="map-container" style={{ height: 300 }}>
        <Map
          ref={mapRef}
          viewport={viewport}
          scrollWheelZoom={!disableScrollWheelZoom}
          attributionControl={false}
          onViewportChanged={setViewport}
          maxZoom={22}
        >
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            attribution="&copy; <a href=&quot;http://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://carto.com/attributions&quot;>CARTO</a>"
          />
          <AttributionControl position="bottomright" prefix={false} />
        </Map>
        {enableCenter && <div className="crosshair" />}
      </div>
      <footer>
        {enableCenter && (
          <>
            <div className={`form-group ${latError ? 'has-error' : ''}`} style={{ flex: 1 }}>
              <div className="input-group">
                <span className="input-group-addon addon-sm">Latitude</span>
                <input
                  type="text"
                  className="form-input input-sm"
                  style={{ width: 70, minWidth: 70, flex: '1 0 auto' }}
                  name="lat"
                  value={latitude === null ? '' : latitude}
                  disabled
                  // onChange={handleInputChange}
                />
              </div>
              {!!latError && <p className="form-input-hint m-0">{latError}</p>}
            </div>
            <div className={`form-group ${lngError ? 'has-error' : ''}`} style={{ flex: 1 }}>
              <div className="input-group">
                <span className="input-group-addon addon-sm">Longitude</span>
                <input
                  type="text"
                  className="form-input input-sm"
                  style={{ width: 70, minWidth: 70, flex: '1 0 auto' }}
                  name="lng"
                  value={longitude === null ? '' : longitude}
                  disabled
                  // onChange={handleInputChange}
                />
              </div>
              {!!lngError && <p className="form-input-hint m-0">{lngError}</p>}
            </div>
          </>
        )}

        {enableZoom && (
          <div className="form-group">
            <div className="input-group">
              <span className="input-group-addon addon-sm">Zoom</span>
              <input type="text" className="form-input input-sm" name="zoom" value={viewport.zoom} style={{ width: 50 }} disabled />
            </div>
          </div>
        )}
      </footer>
    </div>
  );
};

MapViewportWidget.propTypes = {
  enableZoom: PropTypes.bool,
  enableCenter: PropTypes.bool,
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableScrollWheelZoom: PropTypes.bool,
  defaultViewport: PropTypes.shape({
    center: PropTypes.arrayOf(PropTypes.number),
    zoom: PropTypes.number,
  }),
  height: PropTypes.number, // react-resize-detector
  width: PropTypes.number, // react-resize-detector
  onChange: PropTypes.func,
};

MapViewportWidget.defaultProps = {
  enableZoom: true,
  enableCenter: true,
  defaultViewport: {
    center: [40.7128, -74.0060],
    zoom: 5,
  },
  disableScrollWheelZoom: true,
};

export default withResizeDetector(MapViewportWidget);
