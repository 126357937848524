import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import camelize from 'camelize';
import ls from 'local-storage';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { useIsVisible } from 'hooks';
import { withItemDataCache, useItemDataCache } from 'context';
import StyledScrollbar from 'components/common/StyledScrollbar';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';
import Message from 'components/common/Message';
import { ManagementForm } from 'components/common/inlines';
import InlineListBuilder, { Controls as ListBuilderControls } from 'components/common/inlines/InlineListBuilder';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { ObjectListModal } from 'components/views/ObjectList';
import ReleaseItemSettingsForm from 'components/views/ReleaseItemSettingsForm';
import { TYPE_SITE_RELEASE, getDisplayOptionsConfig } from 'components/common/DisplayOptions';
import Section from './Section';

const SiteBuilder = ({
  sectionsFormsetData,
  itemsFormsetData,
  releaseId,
  parentId,
  hasError,
  useCustomNav = false,
}) => {
  const [sectionsById, setSectionsById] = useState({});
  const [sectionsOrdered, setSectionsOrdered] = useState([]);
  const [itemsById, setItemsById] = useState({});
  const [itemGroups, setItemGroups] = useState({});
  const [itemDetails, setItemDetails] = useState({});
  const [editMode, setEditMode] = useState((sectionsFormsetData.forms.length === 0 || hasError) ? 'insert' : 'sort');
  const [modalCallback, setModalCallback] = useState(() => () => null);
  const [modalOpen, setModalOpen] = useState(null);
  const [activeSettingsItems, setActiveSettingsItems] = useState([]);

  const nextSectionIndex = useRef(0);
  const nextItemIndex = useRef(0);

  const containerRef = useRef();
  const isVisible = useIsVisible(containerRef);

  const { setItemDataCache } = useItemDataCache();
  const [dataCacheLoaded, setDataCacheLoaded] = useState(false);
  const fetchItemData = () => {
    const url = urlJoin(urls.releaseContentDataBase, releaseId, '/');
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => Object.entries(data).reduce((result, [key, val]) => {
        result[key] = camelize(val);
        return result;
      }, {}))
      .then(data => {
        setItemDataCache(data);
        setDataCacheLoaded(true);
      })
      .catch(err => {
        console.error(err);
        setDataCacheLoaded(true);
      });
  };

  useEffect(() => {
    fetchItemData();
  }, []);

  useEffect(() => {
    initSections();
    initItems();
  }, []);

  useEffect(() => {
    if (editMode === 'sort') {
      setActiveSettingsItems([]);
    }
  }, [editMode]);

  const initNewSection = () => {
    const { prefix } = sectionsFormsetData;
    const itemId = uuid4();
    const section = {
      itemId,
      prefix: `${prefix}-${nextSectionIndex.current}`,
      errors: {},
      fields: {
        id: null,
        index: null,
        name: '',
        slug: '',
        description: '',
        parent: parentId,
        uuid: itemId,
      },
    };

    nextSectionIndex.current += 1;
    return section;
  };

  const initSections = useCallback(() => {
    // Hydrate sections list from initial formset data
    const { forms } = camelize(sectionsFormsetData);

    const sortedForms = [...forms].sort((a, b) => (
      parseInt(a.fields.index.value, 10) - parseInt(b.fields.index.value, 10)
    ));

    const newSectionsById = {};
    const newSectionsOrdered = [];
    let shouldDelete = false;
    sortedForms.forEach(form => {
      const itemId = (form.isBound ? form.fields.uuid.value : form.fields.uuid.initial) || uuid4();
      const fields = Object.keys(form.fields).reduce((result, key) => {
        if (key === 'DELETE') {
          shouldDelete = !!form.fields[key].value;
        } else {
          result[key] = form.fields[key].value;
        }
        return result;
      }, {});
      fields.index = nextSectionIndex.current;

      const section = {
        itemId,
        fields,
        shouldDelete,
        prefix: form.prefix,
        errors: form.errors,
      };

      newSectionsById[itemId] = section;
      newSectionsOrdered.push(itemId);
      nextSectionIndex.current += 1;
    });
    setSectionsById(newSectionsById);
    setSectionsOrdered(newSectionsOrdered);
  }, [sectionsFormsetData.forms]);

  const handleInsertSection = useCallback(idx => {
    const newSection = initNewSection();
    setSectionsById(oldState => ({
      ...oldState,
      [newSection.itemId]: newSection,
    }));
    setSectionsOrdered(oldState => [
      ...oldState.slice(0, idx),
      newSection.itemId,
      ...oldState.slice(idx),
    ]);
  }, []);

  const handleSectionDeleteClick = itemId => {
    const section = sectionsById[itemId];
    setSectionsById(oldState => ({
      ...oldState,
      [itemId]: { ...section, shouldDelete: !section.shouldDelete },
    }));
  };

  const handleSectionMove = (sourceId, targetId, insertPos) => {
    const toIndex = insertPos === 'before' ? sectionsOrdered.indexOf(targetId) : sectionsOrdered.indexOf(targetId) + 1;
    const newSectionsOrdered = sectionsOrdered.filter(id => id !== sourceId);
    newSectionsOrdered.splice(toIndex, 0, sourceId);
    setSectionsOrdered(newSectionsOrdered);
  };

  const [collapsedSections, setCollapsedSections] = useState(JSON.parse(ls.get(`siteRelease-${parentId}-collapsedSections`) || '[]'));
  const createSectionToggleHandler = itemId => () => setCollapsedSections(oldState => oldState.includes(itemId) ? oldState.filter(id => id !== itemId) : [...oldState, itemId]);
  // remove subitems from activeSettingsItems on section collapse
  useEffect(() => {
    const collapsedSubItemIds = collapsedSections.reduce((result, sId) => {
      return [...result, ...(itemGroups[sId] || [])];
    }, []);
    setActiveSettingsItems(activeSettingsItems.filter(id => !collapsedSubItemIds.includes(id)));
    ls.set(`siteRelease-${parentId}-collapsedSections`, JSON.stringify(collapsedSections));
  }, [JSON.stringify(collapsedSections)]);

  const handleExpandAllClick = evt => {
    evt.preventDefault();
    setCollapsedSections([]);
  };

  const handleCollapseAllClick = evt => {
    evt.preventDefault();
    setCollapsedSections([...sectionsOrdered]);
  };


  const initNewItem = (type, objectId, sectionId) => {
    const { prefix } = itemsFormsetData;
    const itemId = uuid4();

    const item = {
      itemId,
      prefix: `${prefix}-${nextItemIndex.current}`,
      errors: {},
      fields: {
        id: null,
        index: null,
        sectionUid: sectionId,
        type: type,
        media: type === 'media' ? objectId : null,
        title: type === 'title' ? objectId : null,
        parent: parentId,
        inSectionNav: true,
        showSectionNav: true,
        showMainNav: true,
        linkText: '',
        slug: '',
        displayOptions: {},
      },
    };

    nextItemIndex.current += 1;
    return item;
  };

  const initItems = () => {
    // Hydrate items list from initial formset data
    const { forms } = camelize(itemsFormsetData);
    const itemsById = {};
    const itemGroups = {};
    let shouldDelete = false;
    forms.forEach(form => {
      const itemId = uuid4();
      const fields = Object.keys(form.fields).reduce((result, key) => {
        if (key === 'DELETE') {
          shouldDelete = !!form.fields[key].value;
        } else if (key === 'displayOptions') {
          result[key] = JSON.parse(form.fields[key].value);
        } else {
          result[key] = form.fields[key].value;
        }
        return result;
      }, {});

      const item = {
        itemId,
        fields,
        shouldDelete,
        prefix: form.prefix,
        errors: form.errors,
      };

      itemsById[itemId] = item;

      const sectionId = item.fields.sectionUid;
      itemGroups[sectionId] = itemGroups[sectionId] || [];
      itemGroups[sectionId].push(itemId);
      nextItemIndex.current += 1;
    });

    // Within each section, sort items by index field value.
    // Normally the forms should be in the right order to begin with,
    // but that is not necessarily the case if we are redisplaying
    // bound forms with validation errors.
    Object.keys(itemGroups).forEach(key => {
      itemGroups[key].sort((a, b) => (
        parseInt(itemsById[a].fields.index, 10) - parseInt(itemsById[b].fields.index, 10)
      ));
    });

    setItemsById(itemsById);
    setItemGroups(itemGroups);
  };

  const insertItems = (itemType, sectionId, startIndex, modalObjects) => {
    const newItems = {};
    const newItemIds = [];
    modalObjects.forEach(({ uuid }) => {
      const item = initNewItem(itemType, uuid, sectionId);
      newItems[item.itemId] = item;
      newItemIds.push(item.itemId);
    });

    setItemsById(oldState => ({ ...oldState, ...newItems }));
    setItemGroups(oldState => {
      const curSectionItems = oldState[sectionId] || [];
      return {
        ...oldState,
        [sectionId]: [...curSectionItems.slice(0, startIndex), ...newItemIds, ...curSectionItems.slice(startIndex)],
      };
    });
  };

  const handleInsertItemClick = (itemType, sectionId, idx) => {
    const cb = modalObjects => {
      insertItems(itemType, sectionId, idx, modalObjects);
      handleModalRequestClose();
    };
    setModalCallback(() => cb);
    setModalOpen(itemType);
  };

  const handleItemMove = (sourceId, targetId, insertPos) => {
    const sourceItem = itemsById[sourceId];
    const targetItem = itemsById[targetId];
    const sourceSectionId = sourceItem ? sourceItem.fields.sectionUid : null;
    let targetSectionId = targetItem ? targetItem.fields.sectionUid : null;
    if (targetId.indexOf('placeholder') > -1) {
      // Dragging into an empty section
      targetSectionId = targetId.match(/section-(.+)-placeholder/)[1];
    }

    const sourceSectionItems = (itemGroups[sourceSectionId] || []).filter(id => id !== sourceId);
    const targetSectionItems = (itemGroups[targetSectionId] || []).filter(id => id !== sourceId);

    let toIndex = 0;
    if (targetItem) {
      toIndex = insertPos === 'before' ? targetSectionItems.indexOf(targetId) : targetSectionItems.indexOf(targetId) + 1;
    }

    targetSectionItems.splice(toIndex, 0, sourceId);

    const newItem = {
      ...sourceItem,
      fields: {
        ...sourceItem.fields,
        sectionUid: targetSectionId,
      },
    };

    setItemGroups(oldState => ({
      ...oldState,
      [sourceSectionId]: sourceSectionItems,
      [targetSectionId]: targetSectionItems,
    }));

    setItemsById(oldState => ({
      ...oldState,
      [sourceId]: newItem,
    }));
  };

  const handleItemPreviewLoad = useCallback((itemId, obj) => {
    setItemDetails(oldState => ({
      ...oldState,
      [itemId]: {
        label: obj.name,
        type: obj.type,
        id: obj.id,
        requires: obj.requires,
      },
    }));

    setItemsById(oldState => {
      const origItem = oldState[itemId];
      return {
        ...oldState,
        [itemId]: {
          ...origItem,
          fields: {
            ...origItem.fields,
            // If slug or link_text fields are empty, default to values from
            // the target media or title object
            slug: origItem.slug || origItem.fields.slug || obj.slug,
            linkText: origItem.linkText || origItem.fields.linkText || obj.name,
            displayOptions: {
              ...getDisplayOptionsConfig(TYPE_SITE_RELEASE, obj.type).defaultValues,
              ...(origItem.fields.displayOptions || {}),
            },
          },
        },
      };
    });
  }, []);

  const [coverWarnings, setCoverWarnings] = useState([]);
  const checkCoverItems = () => {
    const msgs = [];

    const linkedObjectIds = Object.values(itemsById).reduce((result, { fields: { media, title } }) => {
      if (media) result.push(media);
      if (title) result.push(title);
      return result;
    }, []);

    Object.entries(itemDetails).filter(([uid]) => !itemsById[uid].shouldDelete).filter(([uid, { requires, id }], idx, arr) => {
      const isFirst = idx === arr.findIndex(([_, item]) => item.id === id);
      return isFirst && requires && requires.length > 0;
    }).forEach(([uid, { id, type, label, requires }]) => {
      const missingItems = requires.filter(([_, uid]) => !linkedObjectIds.includes(uid));
      const missingIds = missingItems.map(([_, uid]) => uid);

      const sectionId = itemsById[uid].fields.sectionUid;
      const startIndex = itemGroups[sectionId].indexOf(uid) + 1;

      const handleAddClick = evt => {
        evt.preventDefault();
        missingItems.forEach(([type, uid], idx) => {
          insertItems(type, sectionId, startIndex + idx, [{ uuid: uid }]);
        });
      };

      if (missingIds.length > 0) {
        const msg = <span>{type} {type !== 'Media' && 'Title '}<strong>{id}</strong> (&quot;{label}&quot;) contains links to Media or Title objects that do not appear in this release. <a href="#" onClick={handleAddClick}>Add now &raquo;</a></span>;
        msgs.push(msg);
      }
    });

    setCoverWarnings(msgs);
  };

  useEffect(() => {
    checkCoverItems();
  }, [itemDetails, itemsById]);

  const handleItemSettingsClick = itemId => {
    setActiveSettingsItems(oldState => oldState.includes(itemId)
      ? oldState.filter(id => id !== itemId)
      : [...oldState, itemId]);
  };

  const handleItemDeleteClick = itemId => {
    setItemsById(oldState => ({
      ...oldState,
      [itemId]: {
        ...oldState[itemId],
        shouldDelete: !oldState[itemId].shouldDelete,
      },
    }));
  };

  const handleSettingsFormChange = fields => {
    const updatedItems = {};
    activeSettingsItems.forEach(itemId => {
      const origItem = itemsById[itemId];
      updatedItems[itemId] = {
        ...origItem,
        fields: { ...origItem.fields, ...fields },
      };
    });

    setItemsById(oldState => ({ ...oldState, ...updatedItems }));
  };

  const handleModalRequestClose = () => setModalOpen(null);

  const getSettingsFormInitialValues = () => {
    const items = activeSettingsItems.map(itemId => itemsById[itemId]);
    let inSectionNav = null;
    let showSectionNav = null;
    let showMainNav = null;

    if (items.every(item => item.fields.inSectionNav === true)) {
      inSectionNav = true;
    } else if (items.every(item => item.fields.inSectionNav === false)) {
      inSectionNav = false;
    }

    if (items.every(item => item.fields.showSectionNav === true)) {
      showSectionNav = true;
    } else if (items.every(item => item.fields.showSectionNav === false)) {
      showSectionNav = false;
    }

    if (items.every(item => item.fields.showMainNav === true)) {
      showMainNav = true;
    } else if (items.every(item => item.fields.showMainNav === false)) {
      showMainNav = false;
    }

    if (items.length === 1) {
      const linkText = items[0].fields.linkText || '';
      const slug = items[0].fields.slug || '';
      const displayOptions = items[0].fields.displayOptions || {};
      return { inSectionNav, showSectionNav, showMainNav, linkText, slug, displayOptions };
    } else {
      return { inSectionNav, showSectionNav, showMainNav };
    }
  };

  const renderedSections = sectionsOrdered.map((itemId, idx) => {
    const section = sectionsById[itemId];
    const subItems = itemGroups[itemId] ? itemGroups[itemId].map(id => itemsById[id]) : [];
    const sectionHasError = Object.keys(section.errors).length > 0;
    return (
      <Section
        key={itemId}
        itemIndex={idx}
        isExpanded={isVisible && !collapsedSections.includes(itemId)}
        onToggleExpanded={createSectionToggleHandler(itemId)}
        error={sectionHasError}
        editMode={editMode}
        subItems={subItems}
        onSubItemMove={handleItemMove}
        onSubItemPreviewLoad={handleItemPreviewLoad}
        onSubItemSettingsClick={handleItemSettingsClick}
        onSubItemDeleteClick={handleItemDeleteClick}
        activeSettingsItems={activeSettingsItems}
        onAddMedia={idx => handleInsertItemClick('media', itemId, idx)}
        onAddTitle={idx => handleInsertItemClick('title', itemId, idx)}
        onDeleteClick={() => handleSectionDeleteClick(itemId)}
        {...section}
      />
    );
  });

  const nodeTypes = [{ name: 'Section', callback: handleInsertSection }];
  const { prefix: sectionsFormsetPrefix, managementForm: sectionsManagementForm } = camelize(sectionsFormsetData);
  const { prefix: itemsFormsetPrefix, managementForm: itemsManagementForm } = camelize(itemsFormsetData);
  const sectionsFormCount = sectionsOrdered.length;
  const itemsFormCount = Object.keys(itemsById).length;
  const showSettingsForm = activeSettingsItems.length > 0;
  let settingsFormTitle;
  let settingsItemType;
  if (showSettingsForm) {
    const { label, type } = itemDetails[activeSettingsItems[0]];
    settingsFormTitle = activeSettingsItems.length > 1 ? '(Multiple items selected)' : label;
    settingsItemType = activeSettingsItems.length > 1 ? null : type;
  }

  return (
    <div ref={containerRef} className="title-builder player-builder object-detail-panel-full-height">
      {!dataCacheLoaded ? <LoadingOverlay show /> : (
        <ErrorBoundary>
          <ManagementForm prefix={sectionsFormsetPrefix} formData={sectionsManagementForm} totalForms={sectionsFormCount} />
          <ManagementForm prefix={itemsFormsetPrefix} formData={itemsManagementForm} totalForms={itemsFormCount} />
          <div style={{ flex: 1, height: '100%' }}>
            <StyledScrollbar>
              <div style={{ padding: 30, position: 'relative' }}>
                <ListBuilderControls mode={editMode} empty={sectionsOrdered.length === 0} onChangeMode={setEditMode} />
                <div
                  className="text-meta"
                  style={{
                    position: 'absolute',
                    top: 30,
                    left: 125,
                    height: 40,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <a href="#expand" onClick={handleExpandAllClick}>Expand all</a>&nbsp; | &nbsp;<a href="#collapse" onClick={handleCollapseAllClick}>Collapse all</a>
                </div>
                {hasError && (
                  <div className="my-3">
                    <Message type="error" text="Some items contain errors. Correct the errors below before saving." />
                  </div>
                )}
                {coverWarnings.map((msg, idx) => <Message key={idx} type="error">{msg}</Message>)}
                <InlineListBuilder
                  type="section"
                  editMode={editMode}
                  nodeTypes={nodeTypes}
                  itemIds={sectionsOrdered}
                  onItemMove={handleSectionMove}
                >
                  {renderedSections}
                </InlineListBuilder>

                {sectionsOrdered.length === 0 && (
                  <div className="empty my-4">
                    <div className="empty-icon">
                      <Icon name="web" size={60} />
                    </div>
                    <p className="empty-title h3">Empty Site</p>
                    <p className="empty-subtitle">Add sections to get started.</p>
                  </div>
                )}

                <ObjectListModal
                  model="media"
                  isOpen={modalOpen === 'media'}
                  onAccept={modalCallback}
                  onRequestClose={handleModalRequestClose}
                />

                <ObjectListModal
                  model="titles"
                  isOpen={modalOpen === 'title'}
                  onAccept={modalCallback}
                  onRequestClose={handleModalRequestClose}
                />
              </div>
            </StyledScrollbar>
          </div>
          <div style={{ flex: '0 0 509px' }}>
            {showSettingsForm && (
              <ReleaseItemSettingsForm
                formTitle={settingsFormTitle}
                releaseType={TYPE_SITE_RELEASE}
                disableNavSettings={useCustomNav}
                itemType={settingsItemType}
                multiple={activeSettingsItems.length > 1}
                initialValues={getSettingsFormInitialValues()}
                onChange={handleSettingsFormChange}
              />
            )}
          </div>
        </ErrorBoundary>
      )}
    </div>
  );
};

SiteBuilder.propTypes = {
  sectionsFormsetData: PropTypes.object,
  itemsFormsetData: PropTypes.object,
  releaseId: PropTypes.string,
  parentId: PropTypes.string,
  hasError: PropTypes.bool,
  useCustomNav: PropTypes.bool,
};

export default withItemDataCache(SiteBuilder);
// export default withIsVisible('[data-tab=content]', SiteBuilder);
