import React from 'react';
import PropTypes from 'prop-types';
import { colors } from 'app-constants';
import LoadingBubbles from 'components/common/LoadingBubbles';
import Icon from 'components/common/Icon';

const ItemContentStandard = ({
  contributorData,
  prefix,
  errors = {},
  idFieldValue,
  roleFieldValue,
}) => {
  const { thumbnail, name, organization, type } = contributorData || {};
  const isLoading = typeof contributorData === 'undefined';

  const iconName = {
    individual: 'person',
    organization: 'domain',
  }[type];

  const label = type === 'organization' ? organization : (
    <>
      {name}
      {organization && <span style={{ fontWeight: 300, color: colors.grayMedium }}> &nbsp;|&nbsp; {organization}</span>}
    </>
  );

  const roleFieldErrors = (errors.role || []).map((err, idx) => (
    <div key="idx" className="form-input-hint text-error mt-1">{err.message}</div>
  ));

  return isLoading ? <LoadingBubbles style={{ marginLeft: 20, color: colors.grayMedium }} /> : (
    <>
      <div className="d-flex align-items-center">
        <div style={{ border: `1px solid ${colors.grayLight}`, backgroundColor: colors.grayExtraLight, marginRight: 10 }}>
          {thumbnail ? (
            <img src={thumbnail} width={60} height={60} style={{ display: 'block' }} />
          ) : (
            <div style={{ width: 60, height: 60, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Icon name={iconName} size={32} fill style={{ color: colors.grayMedium }} />
            </div>
          )}
        </div>

        <div>
          <h5 className="mb-1">{label}</h5>
          <div className="input-group">
            <span className="input-group-addon addon-sm text-hint">role</span>
            <input
              type="text"
              className="form-input input-sm"
              style={{ width: 'unset' }}
              name={`${prefix}-role`}
              defaultValue={roleFieldValue}
              autoFocus={!idFieldValue}
            />
          </div>
          <div>{roleFieldErrors}</div>
        </div>
      </div>
    </>
  );
};

ItemContentStandard.propTypes = {
  contributorData: PropTypes.object,
  prefix: PropTypes.string,
  errors: PropTypes.object,
  idFieldValue: PropTypes.string,
  roleFieldValue: PropTypes.string,
};

export default ItemContentStandard;
