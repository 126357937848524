import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePrevious } from 'hooks';
import { useTitleBuilder } from 'components/views/titleBuilder';
import ErrorBoundary from 'components/common/ErrorBoundary';
import StyledScrollbar from 'components/common/StyledScrollbar';
import withDragDropContext from 'components/common/withDragDropContext';
import Item from './Item';
import Form from './Form';
import itemFormDefaults from './itemFormDefaults';

const MapBuilder = ({ formsetData, hasError, categories, markerTypes }) => {
  const opts = {
    formsetData,
    hasError,
    ItemComponent: Item,
    itemFormDefaults,
  };

  const {
    itemsById,
    setItemsById,
    activeItem,
    handleItemAdd,
    handleFormChange,
    managementForm,
    messages,
    renderEmptyMessage,
    renderedItems,
  } = useTitleBuilder(opts);

  const prevItems = usePrevious(itemsById) || {};

  // When an item has its "Default Item" field enabled, disable it on all other items.
  useEffect(() => {
    if (Object.keys(prevItems).length && Object.keys(itemsById).length) {
      const [newDefaultItemId] = Object.entries(itemsById).find(([key, value]) => value.defaultItem && !prevItems[key].defaultItem) || [];
      if (newDefaultItemId) setDefaultItem(newDefaultItemId);
    }
  }, [itemsById]);

  const setDefaultItem = itemId => {
    const updatedItems = Object.entries(itemsById).filter(
      ([key, value]) => key !== itemId && value.defaultItem,
    ).reduce((result, [key, value]) => {
      result[key] = {
        ...value,
        defaultItem: false,
      };
      return result;
    }, {});

    setItemsById({ ...itemsById, ...updatedItems });
  };

  return (
    <div className="title-builder object-detail-panel-full-height">
      <ErrorBoundary>
        {managementForm}
        <div className="title-builder-items-container">
          <StyledScrollbar>
            {messages}
            <div className="title-builder-item disabled" style={{ justifyContent: 'flex-end' }}>
              <button type="button" className="btn btn-primary" onClick={handleItemAdd}>Add New Item</button>
            </div>
            {renderedItems}
          </StyledScrollbar>
        </div>

        {activeItem ? (
          <Form
            item={activeItem}
            categoryOptions={categories.map(c => ({ value: c.id, label: c.name }))}
            markerTypes={markerTypes}
            onChange={handleFormChange}
          />
        ) : renderEmptyMessage()}
      </ErrorBoundary>
    </div>
  );
};

MapBuilder.propTypes = {
  formsetData: PropTypes.object,
  hasError: PropTypes.bool,
  categories: PropTypes.array,
  markerTypes: PropTypes.array,
};

export default withDragDropContext(MapBuilder);
