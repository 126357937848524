import React from 'react';
import { CUSTOM_TITLE_FIELD_PROPTYPES } from './';


const FieldWrapper = props => {
  const { name, label, helpText, errors, className, style, children } = props;
  return (
    <div className={`form-group mb-4 ${errors.length ? 'has-error' : ''} ${className || ''}`} style={style}>
      <label className="block-label" htmlFor={name}>{label}</label>
      {children}
      {helpText && !errors.length ? <p className="form-input-hint mb-0">{helpText}</p> : null}
      {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
    </div>
  );
};

FieldWrapper.propTypes = {
  ...CUSTOM_TITLE_FIELD_PROPTYPES,
};

FieldWrapper.defaultProps = {
  errors: [],
};

export default FieldWrapper;
