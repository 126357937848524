import { urls } from 'app-constants';


const servicesConfig = [
  {
    serviceName: 'netlify',
    serviceLabel: 'Netlify',
    description: 'Netlify integration enables publication of releases to Netlify. You will be redirected to Netlify to authenticate, then back to the Platform to complete the setup process.',
    oAuthUrl: urls.netlifyConnect,
    formFields: [
      {
        name: 'customDomain',
        label: 'Custom Domain',
        helpText: 'If you wish to use a custom domain for Player Releases published to this Netlify account, enter it here. You will need to update your domain‘s DNS configuration after saving this integration. You may leave this field blank to use the default “netlify.app” URLs provided by Netlify.',
        type: 'text',
      },
      {
        name: 'releaseEnabled',
        label: 'Enable Release Endpoint',
        helpText: 'Allow releases to be published to this Netlify account.',
        type: 'switch',
        defaultValue: true,
      },
    ],
  },
  {
    serviceName: 'vimeo',
    serviceLabel: 'Vimeo',
    description: 'Vimeo integration enables import of videos and associated metadata from a Vimeo account. You will be redirected to Vimeo to authenticate, then back to the Platform to complete the setup process.',
    oAuthUrl: urls.vimeoConnect,
    formFields: [
      {
        name: 'importEnabled',
        label: 'Enable Media Import',
        helpText: 'Toggle off to disable this integration.',
        type: 'switch',
        defaultValue: true,
      },
    ],
  },
  {
    serviceName: 'podcast',
    serviceLabel: 'Podcast',
    description: 'Podcast integration enables import of audio files and associated metadata from a podcast feed.',
    createUrl: urls.podcastCreate,
    formFields: [
      {
        name: 'feedUrl',
        label: 'Feed URL',
        type: 'text',
      },
      {
        name: 'importEnabled',
        label: 'Enable Media Import',
        helpText: 'Toggle off to disable this integration.',
        type: 'switch',
        defaultValue: true,
      },
    ],
  },
  {
    serviceName: 'mapbox',
    serviceLabel: 'Mapbox',
    description: 'Mapbox integration enables the use of maps with certain title types. Complete the form below to connect your Mapbox account.',
    createUrl: urls.mapboxCreate,
    formFields: [
      {
        name: 'authSecret',
        label: 'Secret Access Token',
        helpText: 'Enter a Mapbox secret access token with the <code>styles:list</code> scope. <a href="https://docs.mapbox.com/help/how-mapbox-works/access-tokens/" target="_blank" rel="noopener noreferrer">Learn more &raquo;</a>',
        type: 'password',
      },
      {
        name: 'publicToken',
        label: 'Public Access Token',
        helpText: 'Enter a Mapbox public access token. You may use the “default public token” associated with your account, or create a new token with access to <em>only the public scopes.</em> <a href="https://docs.mapbox.com/help/how-mapbox-works/access-tokens/" target="_blank" rel="noopener noreferrer">Learn more &raquo;</a>',
        type: 'text',
      },
    ],
  },
  {
    serviceName: 'facebook',
    serviceLabel: 'Facebook',
    description: 'Facebook integration enables publication of releases to a Facebook Page. If your organization has multiple Pages, add a separate integration for each one. You will be redirected to Facebook to authenticate, then back to the Platform to complete the setup process.',
    oAuthUrl: urls.facebookConnect,
    requiredFeatureFlag: 'facebook_release',
    formFields: [
      {
        name: 'releaseEnabled',
        label: 'Enable Release Endpoint',
        helpText: 'Allow releases to be published to this Facebook Page.',
        type: 'switch',
        defaultValue: true,
      },
    ],
  },
  {
    serviceName: 's3',
    serviceLabel: 'Amazon S3',
    description: 'Amazon S3 integration enables publication of releases to an S3 bucket. Complete the form below to configure a bucket for use with the MediaStorm Platform.',
    createUrl: urls.s3Create,
    formFields: [
      {
        name: 'authId',
        label: 'AWS Access Key Id',
        type: 'text',
      },
      {
        name: 'authSecret',
        label: 'AWS Secret Access Key',
        type: 'password',
      },
      {
        name: 'bucketName',
        label: 'Bucket Name',
        type: 'text',
      },
      {
        name: 'bucketPathPrefix',
        label: 'Path Prefix',
        helpText: 'Enter a subfolder path, or leave blank to use the top level of the bucket.',
        type: 'text',
      },
      {
        name: 'publicUrl',
        label: 'Public URL',
        helpText: 'Enter the URL where the contents of this bucket are publicly accessible.',
        type: 'text',
      },
      {
        name: 'useHistory',
        label: 'Use History API',
        helpText: 'Select this option if your AWS configuration for this bucket supports client-side routing using the browser History API.',
        type: 'checkbox',
        defaultValue: false,
      },
      {
        name: 'releaseEnabled',
        label: 'Enable Release Endpoint',
        helpText: 'Allow releases to be published to this S3 bucket.',
        type: 'switch',
        defaultValue: true,
      },
    ],
  },
];

export default servicesConfig.filter(item => (
  !item.requiredFeatureFlag || (window.FEATURE_FLAGS_ACTIVE || []).includes(item.requiredFeatureFlag)
));
