import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { urls } from 'app-constants';
import { useCSRF } from 'hooks';
import Select from 'components/common/Select';


const TagSelect = ({
  isCreatable = true,
  useNameValues = false,
  disabled = false,
  name,
  value,
  onChange,
}) => {
  const [isFetching, setIsFetching] = useState(false);
  const [options, setOptions] = useState([]);

  const mapOptions = data => data.map(({ id, name }) => ({ value: useNameValues ? name : id, label: name }));

  const selectedOptions = options.filter(o => value.includes(o.value));

  useEffect(() => {
    if (window.tagChoices) {
      setOptions(mapOptions(window.tagChoices));
    } else {
      fetchOptions();
    }
  }, []);

  const fetchOptions = () => {
    setIsFetching(true);

    fetch(urls.tagListCreate)
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => {
        setOptions(mapOptions(data));
        setIsFetching(false);
      })
      .catch(err => {
        setIsFetching(false);
        console.error(err);
      });
  };

  const handleChange = val => onChange(val || []);

  // Tag creation
  const csrfToken = useCSRF();
  const handleCreate = inputValue => {
    setIsFetching(true);

    const body = JSON.stringify({ name: inputValue });
    fetch(urls.tagListCreate, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (!response.ok) throw new Error(response.statusText);
        return response;
      })
      .then(response => response.json())
      .then(data => {
        onChange([...value, useNameValues ? data.name : data.id]);
        fetchOptions();
      })
      .catch(err => {
        setIsFetching(false);
        console.error(err);
      });
  };

  const selectProps = {
    isCreatable,
    isMulti: true,
    name,
    options,
    isDisabled: disabled,
    value: selectedOptions,
    isLoading: isFetching,
    onChange: handleChange,
  };

  if (isCreatable) {
    selectProps.onCreateOption = handleCreate;
  }

  return <Select {...selectProps} />;
};

TagSelect.propTypes = {
  isCreatable: PropTypes.bool,
  useNameValues: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

export default TagSelect;
