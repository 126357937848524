const formDefaults = {
  type: 'media',
  title: null,
  media: null,
  linkText: '',
  slug: '',
  displayOptions: {},
};

export default formDefaults;
