import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/common/Icon';
import TruncateText from 'components/common/TruncateText';
import { DeleteOverlay } from 'components/common/inlines';

const TYPE_INDIVIDUAL = 'individual';
const TYPE_ORGANIZATION = 'organization';
const META_OPTION_TITLE = 'title';
const META_OPTION_ORG = 'organization';

const Item = ({
  prefix,
  counter,
  error,
  id,
  shouldDelete,
  parent,
  contributor,
  contributorData,
  metaOption,
  onDeleteClick,
}) => {
  const handleDeleteClick = () => onDeleteClick();

  let thumbnailContent = <Icon name="steppers" />;
  if (contributorData) {
    thumbnailContent = contributorData.thumbnailLg
      ? <img src={contributorData.thumbnailLg} alt="thumbnail" style={{ pointerEvents: 'none' }} />
      : <Icon name={contributorData.type === 'organization' ? 'domain' : 'person'} fill size={48} />;
  }

  const containerClasses = classNames({
    error,
    'title-builder-item': true,
    disabled: true,
    'to-delete': shouldDelete,
  });

  const { name, organization, title, type } = contributorData || {};

  let line2;
  if (metaOption === META_OPTION_TITLE) {
    line2 = title && title.join(', ');
  } else if (metaOption === META_OPTION_ORG && type !== TYPE_ORGANIZATION) {
    line2 = organization;
  }

  return (
    <div className={containerClasses}>
      <div className="index-counter">{counter}</div>
      <div className="title-builder-item-thumbnail mr-4">
        {thumbnailContent}
      </div>

      <div className="title-builder-item-details">
        <h6 className="mb-0"><TruncateText text={name || organization} numChars={45} forceBreak /></h6>
        <div className="slideshow-builder-caption" title={line2}>{line2}</div>
      </div>

      {!!id && <input type="hidden" name={`${prefix}-id`} value={id} />}
      {shouldDelete && <input type="hidden" name={`${prefix}-DELETE`} value="1" />}
      <input type="hidden" name={`${prefix}-parent`} value={parent} />
      <input type="hidden" name={`${prefix}-index`} value={counter} />
      <input type="hidden" name={`${prefix}-contributor`} value={contributor} />

      {shouldDelete && <DeleteOverlay onUndoClick={handleDeleteClick} />}

      <span
        className="btn-delete tooltip tooltip-left"
        key={shouldDelete}
        onClick={handleDeleteClick}
        data-tooltip={shouldDelete ? 'Undo Remove' : 'Remove Item'}
      >
        {shouldDelete ? <Icon name="undo" /> : <Icon name="delete" fill />}
      </span>

      <span className="btn-move" title="Drag to sort">
        <Icon name="menu" />
      </span>
    </div>
  );
};

Item.propTypes = {
  counter: PropTypes.number,
  error: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  prefix: PropTypes.string,
  id: PropTypes.string,
  itemId: PropTypes.string,
  parent: PropTypes.string,
  shouldDelete: PropTypes.bool,
  contributor: PropTypes.string,
  contributorData: PropTypes.shape({
    name: PropTypes.string,
    organization: PropTypes.string,
    title: PropTypes.arrayOf(PropTypes.string),
    type: PropTypes.oneOf([TYPE_INDIVIDUAL, TYPE_ORGANIZATION]),
    thumbnailLg: PropTypes.string,
  }),
  metaOption: PropTypes.oneOf([META_OPTION_TITLE, META_OPTION_ORG]),
};

export default Item;
