import React from 'react';
import PropTypes from 'prop-types';
import { countries } from 'app-constants';
import Select from 'components/common/Select';

const countryOptions = countries.map(([code, name]) => ({ value: code, label: name }));

const CountrySelect = ({ isMulti, allowEmpty, name, value, onChange }) => {
  const selectedOptions = countryOptions.filter(({ value: code }) => value.includes(code));
  const options = allowEmpty ? [{ value: '', label: '(None)' }, ...countryOptions] : countryOptions;
  return (
    <Select
      isMulti={isMulti}
      name={name}
      options={options}
      onChange={onChange}
      value={selectedOptions}
    />
  );
};

CountrySelect.propTypes = {
  isMulti: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.array,
  onChange: PropTypes.func,
};

CountrySelect.defaultProps = {
  onChange: () => null,
};

export default CountrySelect;
