import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import camelize from 'camelize';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { urls } from 'app-constants';
import { releasePublish } from 'actions/releaseDetailActions';
import withCSRF from 'components/common/withCSRF';
import AnimateHeight from 'react-animate-height';
import Icon from 'components/common/Icon';
import LoadingOverlay from 'components/common/LoadingOverlay';
import ProgressBar from 'components/common/ProgressBar';
import LoadingSpinner from 'components/common/LoadingSpinner';
import HistoryItem from './HistoryItem';


const PUBLISH_METHOD_INCREMENTAL = 'incremental';
const PUBLISH_METHOD_FULL = 'full';

class ReleasePublish extends Component {
  constructor (props) {
    super(props);
    this.state = {
      cancelRequested: false,
      initialStatusLoaded: false,
      initialHistoryLoaded: false,
      isPublishing: false,
      showAdvancedOptions: false,
      publishMethod: PUBLISH_METHOD_INCREMENTAL,
      publishJob: {},
      publishHistory: [],
      error: null,
    };
    this.pollInterval = 1000;

    this.handlePublishClick = this.handlePublishClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.handleMoreLinkClick = this.handleMoreLinkClick.bind(this);
    this.handlePublishMethodChange = this.handlePublishMethodChange.bind(this);
    this.pollStatus = this.pollStatus.bind(this);
  }

  componentDidMount () {
    this.pollStatus();
    this.fetchPublishHistory();
  }

  componentDidUpdate (prevProps, prevState) {
    const { actions } = this.props;
    const { isPublishing } = this.state;

    if (isPublishing && !prevState.isPublishing && !this.pollTimeout) {
      this.pollTimeout = setTimeout(() => this.pollStatus(), this.pollInterval);
      this.setState({ showAdvancedOptions: false });
    }

    if (!isPublishing && prevState.isPublishing) {
      this.fetchPublishHistory();
      actions.releasePublish({ timestamp: Date.now() });
      this.setState({ publishMethod: PUBLISH_METHOD_INCREMENTAL });
    }
  }

  componentWillUnmount () {
    if (this.pollTimeout) {
      clearTimeout(this.pollTimeout);
      this.pollTimeout = null;
    }
  }

  handlePublishClick (e) {
    const { preflight } = this.props;
    e.preventDefault();
    if (preflight.passed) {
      this.doPublish();
    }
  }

  handleCancelClick (e) {
    e.preventDefault();
    this.cancelPublish();
  }

  handleMoreLinkClick () {
    const { showAdvancedOptions } = this.state;
    this.setState({ showAdvancedOptions: !showAdvancedOptions });
  }

  handlePublishMethodChange (e) {
    const { value } = e.target;
    this.setState({ publishMethod: value });
  }

  doPublish () {
    const { releaseId, csrfToken } = this.props;
    const { publishMethod } = this.state;
    const url = urlJoin(urls.releasePublishBase, releaseId, '/');
    const body = JSON.stringify({ incremental: publishMethod === PUBLISH_METHOD_INCREMENTAL });
    this.setState({
      cancelRequested: false,
      publishJob: {},
    });
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (response.status === 204) {
          this.setState({
            isPublishing: true,
          });
        } else {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .catch(err => {
        this.setState({ error: err });
        console.error(err);
      });
  }

  cancelPublish () {
    const { releaseId, csrfToken } = this.props;
    const url = urlJoin(urls.releasePublishBase, releaseId, '/');
    const body = JSON.stringify({ cancel: true });
    this.setState({ cancelRequested: true });
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (response.status !== 204) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .catch(err => {
        this.setState({ error: err });
        console.error(err);
      });
  }

  pollStatus () {
    const { releaseId } = this.props;
    const url = urlJoin(urls.releasePublishTaskStatusBase, releaseId, '/');
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        const jobActive = Object.keys(data).length;

        this.setState({
          publishJob: { ...data },
          isPublishing: jobActive,
          initialStatusLoaded: true,
        });

        if (jobActive) {
          this.pollTimeout = setTimeout(() => this.pollStatus(), this.pollInterval);
        } else {
          clearTimeout(this.pollTimeout);
          this.pollTimeout = null;
        }
      })
      .catch(err => {
        // this.setState({ error: err });
        console.error(err);
      });
  }

  fetchPublishHistory () {
    const { releaseId } = this.props;
    const url = urlJoin(urls.releasePublishHistoryBase, releaseId, '/');
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(({ publishHistory }) => this.setState({ publishHistory, initialHistoryLoaded: true }))
      .catch(err => console.error(err));
  }

  renderStatusIcon () {
    const { preflight } = this.props;
    const { isPublishing, error, initialStatusLoaded } = this.state;

    if (!initialStatusLoaded) {
      return null;
    } else if (!preflight.passed || error) {
      return <Icon name="warning" fill className="text-error" />;
    } else if (isPublishing) {
      return <LoadingSpinner key="icon-loading" size={24} />;
    } else {
      return <Icon name="check_circle" fill />;
    }
  }

  renderErrorList () {
    const { preflight } = this.props;
    if (preflight.passed) {
      return null;
    }

    return (
      <div className="error-list mb-4">
        <ul className="m-0">{preflight.errors.map((error, i) => <li key={i} dangerouslySetInnerHTML={{ __html: error }} />)}</ul>
      </div>
    );
  }

  renderPublishHistory () {
    const { publishHistory, initialHistoryLoaded } = this.state;
    const historyItems = publishHistory.slice().reverse();
    if (!initialHistoryLoaded) {
      return null;
    }

    return historyItems.length
      ? historyItems.map(item => <HistoryItem key={item.date} data={item} />)
      : <em>This release has not yet been published.</em>;
  }

  render () {
    const { preflight, endpointType } = this.props;
    const { cancelRequested, isPublishing, publishJob, error, initialStatusLoaded, showAdvancedOptions, publishMethod } = this.state;
    const canPublish = preflight.passed && !error;
    const progress = publishJob.taskProgressPercent;
    const cancelDisabled = !publishJob.cancellable || cancelRequested;

    let statusText = initialStatusLoaded ? 'Ready to publish.' : '';
    if (!preflight.passed) {
      statusText = 'Correct the errors listed below before publishing.';
    } else if (isPublishing) {
      statusText = publishJob.taskMessage || 'Starting...';
    } else if (error) {
      statusText = <span className="text-error">Failed to publish. <a href="">Refresh the page</a> and try again.</span>;
    }

    return (
      <>
        <div className="release-publish-container card-item mb-5">
          <div className="release-publish-icon">
            {this.renderStatusIcon()}
          </div>
          <div className="release-publish-message">
            {statusText}
          </div>
          {progress ? (
            <div className="release-publish-progress">
              <ProgressBar percent={progress} style={{ height: 20 }} />
            </div>
          ) : null}
          <div className="release-publish-actions">
            {isPublishing ? (
              <button
                className="btn btn-large btn-error"
                onClick={this.handleCancelClick}
                disabled={cancelDisabled}
              >
                Cancel
              </button>
            ) : (
              <button
                className="btn btn-large btn-primary"
                disabled={!canPublish}
                onClick={this.handlePublishClick}
              >
                Publish
              </button>
            )}
          </div>

          {endpointType === 's3' && (
            <>
              <div className="release-publish-more-link ml-2 tooltip tooltip-right" data-tooltip="Publishing Options" onClick={this.handleMoreLinkClick}>
                <Icon name="chevron_down" />
              </div>
              <div style={{ flex: '0 0 100%' }}>
                <AnimateHeight duration={500} height={showAdvancedOptions ? 'auto' : 0}>
                  <div className="release-publish-more">
                    <label className="form-radio m-0">
                      <input
                        type="radio"
                        value={PUBLISH_METHOD_INCREMENTAL}
                        checked={publishMethod === PUBLISH_METHOD_INCREMENTAL}
                        onChange={this.handlePublishMethodChange}
                        disabled={isPublishing}
                      />
                      <i className="form-icon" /> <strong>Incremental publish</strong> (default)
                      <div className="text-hint">If the release has been published previously, only changed files will be uploaded.</div>
                    </label>
                    <label className="form-radio m-0">
                      <input
                        type="radio"
                        value={PUBLISH_METHOD_FULL}
                        checked={publishMethod === PUBLISH_METHOD_FULL}
                        onChange={this.handlePublishMethodChange}
                        disabled={isPublishing}
                      />
                      <i className="form-icon" /> <strong>Full publish</strong>
                      <div className="text-hint">Upload all release files. Use this option if you encounter problems with the incremental publish method.</div>
                    </label>
                  </div>
                </AnimateHeight>
              </div>
            </>
          )}
          <LoadingOverlay show={!initialStatusLoaded} />
        </div>
        {this.renderErrorList()}
        <h4 className="alt-head mb-1">Publish History</h4>
        <hr className="mt-0 mb-2" />
        <div className="release-publish-history">
          {this.renderPublishHistory()}
        </div>
      </>
    );
  }
}

ReleasePublish.propTypes = {
  actions: PropTypes.object,
  csrfToken: PropTypes.string,
  releaseId: PropTypes.string,
  preflight: PropTypes.shape({
    passed: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
  }),
  endpointType: PropTypes.oneOf(['s3', 'netlify', '']),
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ releasePublish }, dispatch),
});

export default withCSRF(connect(null, mapDispatchToProps)(ReleasePublish));
