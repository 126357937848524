import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'components/common/Icon';

const Item = ({ src, dest, errors, onChange, onDeleteClick }) => {
  const handleSrcChange = evt => onChange({ src: evt.target.value, dest });
  const handleDestChange = evt => onChange({ src, dest: evt.target.value });

  const hasError = errors && Object.keys(errors).length > 0;

  const renderErrors = field => {
    if (errors && errors[field]) {
      return errors[field].map((msg, idx) => <div key={idx} className="text-sm text-error">{msg}</div>);
    } else if (hasError) {
      return <div className="text-sm">&nbsp;</div>;
    }
  };


  return (
    <div className={classNames('item redirects-widget-item', hasError && 'error')}>
      <div>
        <div className="input-group">
          <span className="input-group-addon addon-sm">From</span>
          <input
            autoFocus
            type="text"
            className="form-input input-sm"
            value={src}
            onChange={handleSrcChange}
          />
        </div>
        {renderErrors('src')}
      </div>

      <div>
        <div className="input-group">
          <span className="input-group-addon addon-sm">To</span>
          <input
            type="text"
            className="form-input input-sm"
            value={dest}
            onChange={handleDestChange}
          />
        </div>
        {renderErrors('dest')}
      </div>

      <span
        className="btn-delete tooltip tooltip-left mx-1"
        onClick={onDeleteClick}
        data-tooltip="Delete"
      >
        <Icon name="delete" fill />
      </span>

      <span className="btn-move ml-1" title="Drag to sort">
        <Icon name="menu" />
      </span>
    </div>
  );
};

Item.propTypes = {
  src: PropTypes.string,
  dest: PropTypes.string,
  errors: PropTypes.shape({
    src: PropTypes.arrayOf(PropTypes.string),
    dest: PropTypes.arrayOf(PropTypes.string),
  }),
  onChange: PropTypes.func,
  onDeleteClick: PropTypes.func,
};

export default Item;
