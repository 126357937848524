import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { capFirst } from 'utils';
import AddLibraryMedia from 'components/common/AddLibraryMedia';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import AddTitleAttachment from 'components/common/AddTitleAttachment';
import Icon from 'components/common/Icon';

class ReleaseSourceSelect extends Component {
  constructor (props) {
    super(props);
    this.emptyState = {
      sourceType: '',
      mediaId: '',
      titleId: '',
    };
    this.state = {
      sourceType: props.sourceTypeVal,
      mediaId: props.mediaVal,
      titleId: props.titleVal,
    };

    this.handleMediaAdd = this.handleMediaAdd.bind(this);
    this.handleTitleAdd = this.handleTitleAdd.bind(this);
    this.handlePreviewLoad = this.handlePreviewLoad.bind(this);
    this.handleSourceRemove = this.handleSourceRemove.bind(this);
  }

  handleMediaAdd (uid) {
    this.setState({
      sourceType: 'media',
      mediaId: uid,
      titleId: '',
    });
  }

  handleTitleAdd (uid) {
    this.setState({
      sourceType: 'title',
      titleId: uid,
      mediaId: '',
    });
  }

  handlePreviewLoad (data) {
    const { onChange } = this.props;
    onChange(data);
  }

  handleSourceRemove () {
    const { onChange } = this.props;
    this.setState({ ...this.emptyState });
    onChange(null);
  }

  renderPreview () {
    const { readOnly } = this.props;
    const { sourceType } = this.state;

    const itemId = this.state[`${sourceType}Id`];
    return (
      <AttachedItemPreview itemType={sourceType} itemId={itemId} clickableThumbnail>
        {!readOnly && (
          <div
            className="btn-delete tooltip tooltip-left"
            data-tooltip={`Remove ${capFirst(sourceType)}`}
            onClick={this.handleSourceRemove}
          >
            <Icon name="delete" fill />
          </div>
        )}
      </AttachedItemPreview>
    );
  }

  render () {
    const { readOnly } = this.props;
    const { sourceType, mediaId, titleId } = this.state;
    return (
      <div>
        {mediaId || titleId ? this.renderPreview() : (
          <div className="attached-item-preview justify-content-center">
            <AddLibraryMedia onChange={this.handleMediaAdd}>
              {handleModalTrigger => (
                <button type="button" className="btn btn-primary" onClick={e => { e.preventDefault(); handleModalTrigger(); }} disabled={readOnly}>Choose Media</button>
              )}
            </AddLibraryMedia>
            <em className="mx-3">– or –</em>
            <AddTitleAttachment onChange={this.handleTitleAdd}>
              {handleModalTrigger => (
                <button type="button" className="btn btn-primary" onClick={e => { e.preventDefault(); handleModalTrigger(); }} disabled={readOnly}>Choose Title</button>
              )}
            </AddTitleAttachment>
          </div>
        )}
        <input type="hidden" name="source_type" value={sourceType} />
        <input type="hidden" name="media" value={mediaId} />
        <input type="hidden" name="title" value={titleId} />
      </div>
    );
  }
}

ReleaseSourceSelect.propTypes = {
  sourceTypeVal: PropTypes.string,
  mediaVal: PropTypes.string,
  titleVal: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

ReleaseSourceSelect.defaultProps = {
  onChange: () => null,
};

export default ReleaseSourceSelect;
