import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useClickOutside } from 'hooks';
import Icon from 'components/common/Icon';

const ButtonMenu = ({ label, disabled, items = [] }) => {
  const menuRef = useRef();
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const handleButtonClick = () => setMenuIsOpen(!menuIsOpen);
  const handleClickOutside = () => setMenuIsOpen(false);
  useClickOutside(menuRef, handleClickOutside);

  return (
    <div className={classNames('action-menu', menuIsOpen && 'active')} ref={menuRef}>
      <button
        className="btn btn-primary action-menu-toggle"
        onClick={handleButtonClick}
        disabled={disabled}
        type="button"
      >
        {label} <i className="icon icon-caret" />
      </button>
      <ul className="action-menu-list" onClick={() => setMenuIsOpen(false)}>
        {items.map(({ label, iconName, onClick }) => (
          <li key={label} className="action-menu-item">
            <a
              href={`#${label}`}
              onClick={evt => { evt.preventDefault(); onClick(); }}
            >
              {iconName && <Icon name={iconName} size={20} className="mr-3" />}
              {label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

ButtonMenu.propTypes = {
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    iconName: PropTypes.string,
    onClick: PropTypes.func,
  })),
};

export default ButtonMenu;
