import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const TextArea = ({ name, label, labelClass, helpText, disabled, value, errors, className, style, onChange }) => (
  <div className={classNames('form-group', className, errors.length > 0 && 'has-error')} style={style}>
    {label && <label htmlFor={name} className={labelClass}>{label}</label>}
    <textarea className="form-input" name={name} disabled={disabled} value={value} onChange={onChange} />
    {helpText && !errors.length && <p className="form-input-hint mb-0">{helpText}</p>}
    {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
  </div>
);

TextArea.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  labelClass: PropTypes.string,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  errors: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};

TextArea.defaultProps = {
  errors: [],
  onChange: () => null,
};

export default TextArea;
