import React from 'react';
import { formatters } from 'utils';
import { CUSTOM_TITLE_FIELD_PROPTYPES } from './';
import FieldWrapper from './FieldWrapper';


const SlugField = props => {
  const { name, value, autoFocus, onChange } = props;
  const handleChange = e => onChange(formatters.slug(e.target.value));
  return (
    <FieldWrapper {...props}>
      <input
        type="text"
        className="form-input"
        name={name}
        value={value}
        autoFocus={autoFocus}
        onChange={handleChange}
      />
    </FieldWrapper>
  );
};

SlugField.propTypes = {
  ...CUSTOM_TITLE_FIELD_PROPTYPES,
};

SlugField.defaultProps = {
  onChange: () => null,
};

export default SlugField;
