import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';


class ObjectTypeFilter extends Component {
  constructor (props) {
    super(props);
    this.state = {
      choices: props.choices,
    };
  }

  componentDidMount () {
    this.fetchData();
  }

  fetchData () {
    const { dataUrl } = this.props;
    fetch(dataUrl, {
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText || 'Bad Request' });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        // Convert to array of [value, label] pairs and sort alphabetically
        const choices = data.map(({ name }) => [name, name]).sort(([a], [b]) => a.localeCompare(b));
        this.setState({ choices });
      })
      .catch(err => {
        this.setState({ error: 'Network error' });
        console.error(err);
      });
  }

  render () {
    const { param, activeValue, onChange } = this.props;
    const { choices } = this.state;
    return (
      <>
        <label key="all" className="form-radio">
          <input
            type="radio"
            name={param}
            checked={!activeValue}
            onChange={() => onChange(param, null)}
          />
          <i className="form-icon" /> All
        </label>
        {choices.map(([value, label]) => (
          <label key={value} className={`form-radio ${activeValue === value ? 'active' : ''}`}>
            <input
              type="radio"
              name={param}
              checked={activeValue === value}
              onChange={() => onChange(param, value)}
            />
            <i className="form-icon" /> {label}
          </label>
        ))}
      </>
    );
  }
}

ObjectTypeFilter.propTypes = {
  dataUrl: PropTypes.string,
  param: PropTypes.string,
  choices: PropTypes.arrayOf(PropTypes.array),
  activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default ObjectTypeFilter;
