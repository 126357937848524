import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TagSelect from 'components/common/TagSelect';

const TagField = ({ field, disabled = false }) => {
  const [fieldVal, setFieldVal] = useState(field.value || []);
  useEffect(() => {
    setFieldVal(field.value || []);
  }, [JSON.stringify(field.value)]);

  return (
    <>
      <TagSelect
        value={fieldVal}
        disabled={disabled}
        onChange={val => setFieldVal(val || [])}
      />
      <select multiple readOnly name={field.name} value={fieldVal} style={{ display: 'none' }}>
        {fieldVal.map(v => <option key={v} value={v} />)}
      </select>
    </>
  );
};

TagField.propTypes = {
  field: PropTypes.object,
  disabled: PropTypes.bool,
};

export default TagField;
