import React from 'react';
import PropTypes from 'prop-types';
import SortableItem from 'components/common/SortableItem';
import withDragDropContext from 'components/common/withDragDropContext';
import InsertLine from './InsertLine';


const InlineListBuilder = ({ type, children, itemIds, editMode, nodeTypes, onItemMove }) => {
  const wrapNode = (node, idx) => (
    <SortableItem
      key={node.key}
      type={type}
      index={idx}
      itemId={itemIds[idx]}
      onItemMove={onItemMove}
      canSort={editMode === 'sort'}
    >
      {node}
    </SortableItem>
  );
  let renderedChildren;
  if (editMode === 'insert') {
    renderedChildren = children.reduce((result, child, idx) => {
      // Add an insertion point before each child node
      result.push(<InsertLine key={`insert-point-${idx}`} nodeTypes={nodeTypes} insertionPoint={idx} />);
      result.push(wrapNode(child, idx));
      return result;
    }, []);
    // Add a final insertion point at the end of the list
    renderedChildren.push(<InsertLine key={`insert-point-${children.length}`} nodeTypes={nodeTypes} insertionPoint={children.length} />);
  } else {
    renderedChildren = children.map((child, idx) => wrapNode(child, idx));
  }

  return (
    <div className="inline-list-builder">
      {renderedChildren}
    </div>
  );
};


InlineListBuilder.propTypes = {
  editMode: PropTypes.oneOf(['sort', 'insert', null]),
  nodeTypes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    callback: PropTypes.func,
  })),
  onItemMove: PropTypes.func,
  children: PropTypes.node,
  itemIds: PropTypes.array,
  type: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
};

InlineListBuilder.defaultProps = {
  editMode: null,
  children: [],
  itemIds: [],
  onItemMove: () => null,
};

export default withDragDropContext(InlineListBuilder);
