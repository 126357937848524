import React, { Component } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import Icon from 'components/common/Icon';


class SearchFilter extends Component {
  constructor (props) {
    super(props);
    this.state = { inputValue: '' };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClear = this.handleClear.bind(this);
    this.handleCallback = debounce(this.handleCallback.bind(this), 500);
  }

  componentDidMount () {
    const { activeValue } = this.props;
    this.setState({ inputValue: activeValue || '' });
  }

  componentDidUpdate (prevProps, prevState) {
    const { activeValue } = this.props;
    const { inputValue } = this.state;

    if (activeValue !== prevProps.activeValue && activeValue !== inputValue) {
      this.setState({ inputValue: activeValue || '' });
    } else if (inputValue !== prevState.inputValue) {
      this.handleCallback();
    }
  }

  handleInputChange (e) {
    const value = e.target.value;
    this.setState({ inputValue: value });
  }

  handleClear (e) {
    e.preventDefault();
    this.setState({ inputValue: '' });
  }

  handleCallback () {
    const { param, onChange } = this.props;
    const { inputValue } = this.state;
    onChange(param, inputValue || null);
  }

  render () {
    const { activeValue } = this.props;
    const { inputValue } = this.state;
    return (
      <div className={`search-filter ${activeValue ? 'active' : ''}`}>
        <input
          name="search"
          type="text"
          className={activeValue ? 'has-value' : null}
          placeholder="Keywords"
          value={inputValue}
          onChange={this.handleInputChange}
        />
        <div className="search-filter-icon">
          <Icon name="search" size={30} />
        </div>
        {activeValue ? (
          <a href="#clear" className="clear" onClick={this.handleClear}>
            <Icon name="close" />
          </a>
        ) : null}
      </div>
    );
  }
}

SearchFilter.propTypes = {
  param: PropTypes.string,
  activeValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
};

export default SearchFilter;
