import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { validateLat, validateLng, validateMapZoom } from 'utils';
import { Map, Marker, TileLayer, AttributionControl } from 'react-leaflet';
import { withResizeDetector } from 'react-resize-detector';


class LocationWidget extends Component {
  constructor (props) {
    super(props);
    this.state = {
      lat: null,
      lng: null,
      latError: '',
      lngError: '',
      viewport: props.defaultViewport,
    };

    this.handleMapClick = this.handleMapClick.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleClearClick = this.handleClearClick.bind(this);
    this.handleViewportChanged = this.handleViewportChanged.bind(this);
  }

  componentDidMount () {
    const { latitude, longitude, zoom } = this.props;
    const { viewport } = this.state;
    this.updateCoords(latitude, longitude);

    const validatedZoom = validateMapZoom(zoom);
    if (validatedZoom !== null) {
      this.setState({
        viewport: { ...viewport, zoom: validatedZoom },
      });
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { height, latitude, longitude, zoom, useZoom, onChange, defaultViewport } = this.props;
    const { lat, lng, latError, lngError, viewport } = this.state;

    // Trigger Leaflet re-render if container size changes
    if (height !== prevProps.height && this.mapRef) {
      this.mapRef.leafletElement.invalidateSize(false);
    }

    if (latitude !== prevProps.latitude || longitude !== prevProps.longitude) {
      this.updateCoords(latitude, longitude);
    }

    if (zoom !== prevProps.zoom && typeof zoom === 'number') {
      this.setState({ viewport: { ...viewport, zoom } });
    }

    if (lat !== prevState.lat || lng !== prevState.lng) {
      if (!latError && !lngError) {
        onChange(lat, lng);

        // Re-center map view on selected coordinates
        if (!isNaN(parseFloat(lat)) && !isNaN(parseFloat(lng))) {
          this.setState({
            viewport: {
              ...viewport,
              center: [lat, lng],
            },
          });
        } else {
          this.setState({ viewport: defaultViewport });
        }
      }
    }

    if (useZoom && viewport.zoom !== prevState.viewport.zoom) {
      onChange(lat, lng, validateMapZoom(viewport.zoom));
    }
  }

  handleMapClick (e) {
    const { latlng } = e;
    this.updateCoords(latlng.lat, latlng.lng);
  }

  handleInputChange (e) {
    const { name, value } = e.target;
    if (name === 'lat') {
      if (value === '') {
        this.setState({ lat: null, latError: '' });
      } else {
        const { value: lat, error: latError } = validateLat(value);
        this.setState({ lat, latError });
      }
    } else if (name === 'lng') {
      if (value === '') {
        this.setState({ lng: null, lngError: '' });
      } else {
        const { value: lng, error: lngError } = validateLng(value);
        this.setState({ lng, lngError });
      }
    }
  }

  handleClearClick (e) {
    e.preventDefault();
    this.setState({ lat: null, lng: null });
  }

  handleViewportChanged (viewport) {
    this.setState({ viewport });
  }

  updateCoords (latVal, lngVal) {
    const { value: lat, error: latError } = validateLat(latVal);
    const { value: lng, error: lngError } = validateLng(lngVal);
    this.setState({ lat, lng, latError, lngError });
  }

  render () {
    const { useZoom, disableScrollWheelZoom } = this.props;
    const { viewport, lat, lng, latError, lngError } = this.state;
    const showMarker = lat !== null && lng !== null && !latError && !lngError;

    return (
      <div className="location-widget mt-1">
        <div className="map-container">
          <Map
            ref={node => this.mapRef = node}
            viewport={viewport}
            scrollWheelZoom={!disableScrollWheelZoom}
            attributionControl={false}
            onViewportChanged={this.handleViewportChanged}
            onClick={this.handleMapClick}
            maxZoom={22}
            maxBounds={[
              [-90, -180],
              [90, 180],
            ]}
            minZoom={3}
          >
            <TileLayer
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
              attribution="&copy; <a href=&quot;http://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a> contributors, <a href=&quot;https://carto.com/attributions&quot;>CARTO</a>"
            />
            {showMarker ? <Marker position={[lat, lng]} /> : null}
            <AttributionControl position="bottomright" prefix={false} />
          </Map>
        </div>
        <footer>
          <div className={`form-group ${latError ? 'has-error' : ''}`}>
            <div className="input-group">
              <span className="input-group-addon addon-sm">Latitude</span>
              <input type="text" className="form-input input-sm" name="lat" value={lat === null ? '' : lat} onChange={this.handleInputChange} />
            </div>
            {latError ? <p className="form-input-hint m-0">{latError}</p> : null}
          </div>
          <div className={`form-group ${lngError ? 'has-error' : ''}`}>
            <div className="input-group">
              <span className="input-group-addon addon-sm">Longitude</span>
              <input type="text" className="form-input input-sm" name="lng" value={lng === null ? '' : lng} onChange={this.handleInputChange} />
            </div>
            {lngError ? <p className="form-input-hint m-0">{lngError}</p> : null}
          </div>

          {useZoom && (
            <div className="form-group">
              <div className="input-group">
                <span className="input-group-addon addon-sm">Zoom</span>
                <input type="text" className="form-input input-sm" name="zoom" value={viewport.zoom} style={{ width: 50 }} disabled />
              </div>
            </div>
          )}

          <div className="form-group">
            <button className="btn btn-sm" onClick={this.handleClearClick}>Clear Location</button>
          </div>
        </footer>
      </div>
    );
  }
}

LocationWidget.propTypes = {
  latitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  longitude: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  zoom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  useZoom: PropTypes.bool,
  disableScrollWheelZoom: PropTypes.bool,
  defaultViewport: PropTypes.shape({
    center: PropTypes.arrayOf(PropTypes.number),
    zoom: PropTypes.number,
  }),
  height: PropTypes.number, // react-resize-detector
  width: PropTypes.number, // react-resize-detector
  onChange: PropTypes.func,
};

LocationWidget.defaultProps = {
  defaultViewport: {
    center: [0, 0],
    zoom: 3,
  },
  disableScrollWheelZoom: true,
};

export default withResizeDetector(LocationWidget);
