import React from 'react';
import cookies from 'js-cookie';
import { csrfCookieName } from 'app-constants';

// eslint-disable-next-line
const withCSRF = Component => props => (
  <Component csrfToken={cookies.get(csrfCookieName)} {...props} />
);

export default withCSRF;
