import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import camelize from 'camelize';
import snakeize from 'snakeize';
import withDragDropContext from 'components/common/withDragDropContext';
import ErrorBoundary from 'components/common/ErrorBoundary';
import { ManagementForm } from 'components/common/inlines';
import SortableItem from 'components/common/SortableItem';
import VisibilityKey from 'components/common/VisibilityKey';
import Item from './Item';
import Form from './Form';


class MenuBuilder extends Component {
  constructor (props) {
    super(props);
    this.state = {
      itemsById: {},
      itemsOrdered: [],
      activeItemId: null,
    };
    this.nextItemIndex = 0;

    this.updateItem = this.updateItem.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleItemDeleteClick = this.handleItemDeleteClick.bind(this);
    this.handleItemVisibilityClick = this.handleItemVisibilityClick.bind(this);
    this.handleAddItemClick = this.handleAddItemClick.bind(this);
    this.handleItemMove = this.handleItemMove.bind(this);
    this.handleFormChange = this.handleFormChange.bind(this);
  }

  componentDidMount () {
    this.initItems();
  }

  componentDidUpdate (prevProps, prevState) {
    const { activeItemId, itemsById } = this.state;

    if (activeItemId && itemsById[activeItemId].shouldDelete) {
      this.setState({ activeItemId: null });
    }
  }

  handleItemClick (itemId) {
    const { activeItemId } = this.state;
    this.setState({ activeItemId: activeItemId === itemId ? null : itemId });
  }

  handleItemDeleteClick (itemId) {
    const item = this.getItem(itemId);
    this.updateItem(itemId, { shouldDelete: !item.shouldDelete });
  }

  handleItemVisibilityClick (itemId) {
    const item = this.getItem(itemId);
    this.updateItem(itemId, { isVisible: !item.isVisible });
  }

  handleAddItemClick (e) {
    e.preventDefault();
    const { itemId } = this.initNewItem();
    this.setState({ activeItemId: itemId });
  }

  handleItemMove (sourceId, targetId, insertPos) {
    const { itemsOrdered } = this.state;
    const targetIndex = itemsOrdered.indexOf(targetId);
    const toIndex = insertPos === 'before' ? targetIndex : targetIndex + 1;
    const newValue = itemsOrdered.filter(id => id !== sourceId);
    newValue.splice(toIndex, 0, sourceId);
    this.setState({ itemsOrdered: newValue });
  }

  handleFormChange (itemObj) {
    const { itemsById, activeItemId } = this.state;
    const activeItem = this.getItem(activeItemId);
    const newItem = {
      ...activeItem,
      ...itemObj,
    };

    this.setState({
      itemsById: {
        ...itemsById,
        [activeItemId]: newItem,
      },
    });
  }

  updateItem (itemId, attrs) {
    const { itemsById } = this.state;
    const item = this.getItem(itemId);
    this.setState({
      itemsById: {
        ...itemsById,
        [itemId]: {
          ...item,
          ...attrs,
        },
      },
    });
  }

  initNewItem () {
    const { prefix } = this.props.formsetData;
    const { itemsById, itemsOrdered } = this.state;
    const itemId = uuid4();
    const newItem = {
      itemId,
      itemIndex: this.nextItemIndex,
      prefix: `${prefix}-${this.nextItemIndex}`,
      errors: {},
      label: '',
      type: 'page',
      linkUrl: '',
      structuredContent: [],
      isVisible: true,
      conditionalDisplay: false,
      conditionalDisplayItems: [],
      shouldDelete: false,
    };

    this.nextItemIndex += 1;

    this.setState({
      itemsById: {
        ...itemsById,
        [itemId]: newItem,
      },
      itemsOrdered: [
        ...itemsOrdered,
        itemId,
      ],
    });

    return newItem;
  }

  initItems () {
    // Hydrate item list from initial formset data
    const { forms, prefix } = camelize(this.props.formsetData);
    const itemsById = {};
    const itemsOrdered = [];
    forms.forEach(form => {
      const itemId = (form.isBound ? form.fields.uuid.value : form.fields.uuid.initial) || uuid4();
      const fields = Object.keys(form.fields).reduce((result, key) => {
        if (key === 'DELETE') {
          result.shouldDelete = !!form.fields[key].value;
        } else if (['structuredContent', 'conditionalDisplayItems'].includes(key)) {
          result[key] = JSON.parse(form.fields[key].value);
        } else {
          result[key] = form.fields[key].value;
        }
        return result;
      }, {});

      const item = {
        itemId,
        itemIndex: this.nextItemIndex,
        prefix: `${prefix}-${this.nextItemIndex}`,
        errors: form.errors,
        ...fields,
      };

      itemsById[itemId] = item;
      itemsOrdered.push(itemId);
      this.nextItemIndex += 1;
    });
    this.setState({ itemsById, itemsOrdered });
  }

  getItem (itemId) {
    const { itemsById } = this.state;
    return itemsById[itemId];
  }

  renderFormFields () {
    const { parentId } = this.props;
    const { itemsOrdered, itemsById } = this.state;

    return itemsOrdered.map(itemId => itemsById[itemId]).map((item, idx) => {
      let { prefix, id, itemId, shouldDelete, label, type, linkUrl, isVisible, conditionalDisplay, conditionalDisplayItems } = item;
      let structuredContent = item.structuredContent || [];
      if (type === 'page') {
        linkUrl = '';
      } else {
        structuredContent = structuredContent.map(item => ({ ...item, shouldDelete: true }));
      }
      structuredContent = JSON.stringify(snakeize(structuredContent));

      return (
        <Fragment key={itemId}>
          {id && <input type="hidden" name={`${prefix}-id`} value={id} />}
          <input type="hidden" name={`${prefix}-uuid`} value={itemId} />
          {shouldDelete && <input type="hidden" name={`${prefix}-DELETE`} value="1" />}
          <input type="hidden" name={`${prefix}-release`} value={parentId} />
          <input type="hidden" name={`${prefix}-index`} value={idx + 1} />
          <input type="hidden" name={`${prefix}-label`} value={label || ''} />
          <input type="hidden" name={`${prefix}-type`} value={type} />
          <input type="hidden" name={`${prefix}-link_url`} value={linkUrl || ''} />
          {conditionalDisplay && <input type="hidden" name={`${prefix}-conditional_display`} value={1} />}
          <input type="hidden" name={`${prefix}-conditional_display_items`} value={JSON.stringify(conditionalDisplayItems)} />
          <input type="hidden" name={`${prefix}-structured_content`} value={structuredContent} />
          {isVisible && <input type="hidden" name={`${prefix}-is_visible`} value={1} />}
        </Fragment>
      );
    });
  }

  renderItems () {
    // const { prefix } = camelize(this.props.formsetData);
    const { itemsOrdered, activeItemId } = this.state;
    return itemsOrdered.map((itemId, idx) => {
      const item = this.getItem(itemId);
      const hasError = Object.keys(item.errors).length > 0;
      return (
        <SortableItem
          key={itemId}
          itemId={itemId}
          type="menu-builder-items"
          className="mb-4"
          onItemMove={this.handleItemMove}
        >
          <Item
            {...item}
            hasError={hasError}
            isEditing={activeItemId === itemId}
            shouldDelete={item.shouldDelete}
            onClick={() => this.handleItemClick(itemId)}
            onVisibilityClick={() => this.handleItemVisibilityClick(itemId)}
            onDeleteClick={() => this.handleItemDeleteClick(itemId)}
          />
        </SortableItem>
      );
    });
  }

  render () {
    const { releaseItems, hasError } = this.props;
    const { prefix, managementForm } = camelize(this.props.formsetData);
    const { itemsOrdered, activeItemId } = this.state;
    const formCount = itemsOrdered.length;
    const activeItem = this.getItem(activeItemId);
    const itemCountLabel = itemsOrdered.length === 1 ? 'Item' : 'Items';

    const emptyMsg = itemsOrdered.length
      ? 'No item selected. Choose an item from the list at left to edit, or…'
      : 'No items yet! Add some to get started.';

    return (
      <div className="object-detail-container h-100">
        <div className="menu-builder object-detail-content h-100">
          <ManagementForm prefix={prefix} formData={managementForm} totalForms={formCount} />
          <div className="object-detail-main">
            <div className="form-group m-0">
              <h5 className="m-0">Menu Items</h5>
              <div className="text-hint mb-2">Configure global menu items for this release.</div>
              <VisibilityKey />
            </div>
            <ErrorBoundary>
              <div className="relation-field mt-1">
                {hasError ? <div className="error-list">Some of the items below contain errors.</div> : null}
                <div className="item-list">
                  {this.renderItems()}
                </div>
                <div className="controls">
                  <div className="item-count">{itemsOrdered.length} {itemCountLabel}</div>
                  <button type="button" className="btn btn-primary" onClick={this.handleAddItemClick}>Add Menu Item</button>
                </div>
              </div>
              {this.renderFormFields()}
            </ErrorBoundary>
          </div>
          <div className="object-detail-sidebar">
            {activeItemId ? (
              <Form
                key={activeItemId}
                releaseItems={releaseItems}
                item={activeItem}
                errors={activeItem.errors}
                onChange={this.handleFormChange}
              />
            ) : (
              <div className="text-center py-4">
                <p>{emptyMsg}</p>
                <button className="btn" type="button" onClick={this.handleAddItemClick}>Add a New Menu Item</button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

MenuBuilder.propTypes = {
  formsetData: PropTypes.object,
  releaseItems: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    objectId: PropTypes.string,
    objectType: PropTypes.string,
    objectName: PropTypes.string,
    objectIconName: PropTypes.string,
  })),
  parentId: PropTypes.string,
  hasError: PropTypes.bool,
};

export default withDragDropContext(MenuBuilder);
