import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import Icon from 'components/common/Icon';
import ContributorPreview from 'components/common/ContributorPreview';

const ContributorChoiceItem = ({
  contributorData = {},
  isAdded = false,
  onClick = () => {},
}) => {
  const editUrl = urlJoin(urls.contributorBase, contributorData.id, '/');
  const handleEditClick = evt => evt.stopPropagation();

  return (
    <div className="contributors-builder-choice-item" onClick={evt => onClick(evt, contributorData.uid)}>
      <ContributorPreview {...contributorData} style={{ flex: 1 }} />
      <a
        className="edit-icon"
        href={editUrl}
        title="Edit contributor"
        target="_blank"
        rel="noopener noreferrer"
        onClick={handleEditClick}
      >
        <Icon name="edit" />
      </a>
      <div className={classNames('status-icon', isAdded && 'added')}>
        <Icon name={isAdded ? 'check' : 'add'} />
      </div>
    </div>
  );
};

ContributorChoiceItem.propTypes = {
  contributorData: PropTypes.shape(ContributorPreview.propTypes),
  isAdded: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ContributorChoiceItem;
