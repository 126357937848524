import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import ErrorBoundary from 'components/common/ErrorBoundary';
import LoadingSpinner from 'components/common/LoadingSpinner';
import Metric from './Metric';


class ObjectAnalytics extends Component {
  constructor (props) {
    super(props);
    this.state = {
      metricGroups: [],
      processing: true,
      error: null,
    };
  }

  componentDidMount () {
    this.fetchData();
  }

  componentDidUpdate (prevProps, prevState) {
    const { dataUrl } = this.props;

    if (dataUrl !== prevProps.dataUrl) {
      this.fetchData();
    }
  }

  fetchData () {
    const { dataUrl } = this.props;
    this.setState({ processing: true });
    fetch(dataUrl, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({ metricGroups: data, processing: false });
      })
      .catch(err => {
        this.setState({ error: err, processing: false });
        console.error(err);
      });
  }

  renderMetricGroups () {
    const { metricGroups } = this.state;

    return (
      <ErrorBoundary>
        {metricGroups.map(({ category, metrics }) => (
          <div key={category} className="analytics-group">
            <header>
              <h5>{category}</h5>
            </header>
            <section className="analytics-metrics-container">
              {metrics.map(props => <Metric key={props.id} {...props} />)}
            </section>
          </div>
        ))}
      </ErrorBoundary>
    );
  }

  render () {
    const { processing, error } = this.state;

    if (processing) {
      return (
        <div className="analytics-container">
          <div className="analytics-group d-flex justify-content-center">
            <div className="text-center">
              <div className="mb-2"><em>Fetching analytics data…</em></div>
              <LoadingSpinner style={{ margin: '0 auto' }} />
            </div>
          </div>
        </div>
      );
    } else if (error) {
      return (
        <div className="analytics-container">
          <div className="analytics-group d-flex justify-content-center">
            <div className="text-center">
              <div className="text-error">Failed to fetch analytics data.</div>
            </div>
          </div>
        </div>
      );
    } else {
      return this.renderMetricGroups();
    }
  }
}

ObjectAnalytics.propTypes = {
  dataUrl: PropTypes.string,
};

export default ObjectAnalytics;
