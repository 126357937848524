import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Icon = ({ name, size, fill = false, className, style = {} }) => {
  style = {
    ...style,
    fontVariationSettings: `'FILL' ${fill ? 1 : 0}, 'wght' 400, 'GRAD' 0, 'opsz' ${size || 24}`,
  };
  if (size) style.fontSize = size;
  return <span className={classNames('material-symbols-outlined', className)} style={style}>{name}</span>;
};

Icon.propTypes = {
  name: PropTypes.string,
  size: PropTypes.number,
  fill: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Icon;
