import React, { Component, Children } from 'react';
import PropTypes from 'prop-types';
import { ObjectListModal } from 'components/views/ObjectList';


class AddTitleAttachment extends Component {
  constructor (props) {
    super(props);
    this.state = {
      modalOpen: false,
    };

    this.handleModalTrigger = this.handleModalTrigger.bind(this);
    this.handleModalAccept = this.handleModalAccept.bind(this);
    this.handleModalRequestClose = this.handleModalRequestClose.bind(this);
  }

  handleModalTrigger () {
    const { modalOpen } = this.state;
    if (!modalOpen) {
      this.setState({ modalOpen: true });
    }
  }

  handleModalAccept (selection) {
    const { onChange, allowMultiple } = this.props;
    if (allowMultiple) {
      const value = selection.map(m => m.uuid);
      onChange(value);
    } else {
      const { uuid } = selection;
      onChange(uuid);
    }
    this.handleModalRequestClose();
  }

  handleModalRequestClose () {
    this.setState({ modalOpen: false });
  }

  renderChildren () {
    // By passing a function to children, we can override the default UI
    // for the button that triggers the modal.
    const { children } = this.props;
    if (typeof children === 'function') {
      const renderedChildren = children(this.handleModalTrigger);
      return renderedChildren && Children.only(renderedChildren);
    }
    return null;
  }

  render () {
    const { preFilter, disabledFilters, allowMultiple } = this.props;
    const { modalOpen } = this.state;

    return (
      <>
        {this.renderChildren() || (
          // Default UI if no 'children' prop
          <button className="btn btn-primary" onClick={e => { e.preventDefault(); this.handleModalTrigger(); }}>Add Title</button>
        )}
        <ObjectListModal
          model="titles"
          isOpen={modalOpen}
          allowMultipleSelection={allowMultiple}
          preFilter={preFilter}
          disabledFilters={disabledFilters}
          onAccept={this.handleModalAccept}
          onRequestClose={this.handleModalRequestClose}
        />
      </>
    );
  }
}

AddTitleAttachment.propTypes = {
  children: PropTypes.func,
  onChange: PropTypes.func,
  preFilter: PropTypes.object,
  allowMultiple: PropTypes.bool,
  disabledFilters: PropTypes.arrayOf(PropTypes.string),
};

AddTitleAttachment.defaultProps = {
  preFilter: {},
  disabledFilters: [],
  allowMultiple: false,
};

export default AddTitleAttachment;
