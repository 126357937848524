import React from 'react';
import PropTypes from 'prop-types';
import { InlineFormset } from 'components/common/inlines';
import RelatedLinkForm from './RelatedLinkForm';


const RelatedLinksFormset = ({ parentId, formsetData, disabled = false }) => (
  <InlineFormset
    parentId={parentId}
    formsetData={formsetData}
    disabled={disabled}
    labelFieldName="name"
    itemTypeLabel="Related Link"
    formComponent={RelatedLinkForm}
  />
);

RelatedLinksFormset.propTypes = {
  parentId: PropTypes.string,
  formsetData: PropTypes.object,
  disabled: PropTypes.bool,
};

export default RelatedLinksFormset;
