import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import debounce from 'lodash/debounce';
import { urls } from 'app-constants';
import { FacebookLinkPreview } from './';


class FacebookReleaseLinkField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      linkData: null,
      processing: false,
      error: null,
    };

    this.fetchData = debounce(this.fetchData, 1000, { leading: true, trailing: true });
  }

  componentDidMount () {
    this.fetchData();
  }

  componentDidUpdate (prevProps, prevState) {
    const { linkUrl } = this.props;
    if (linkUrl !== prevProps.linkUrl) {
      if (/^https?:\/\/.+\..+/.test(linkUrl)) {
        this.fetchData();
      }
    }
  }

  fetchData () {
    const { linkUrl } = this.props;
    if (!linkUrl) {
      this.setState({
        linkData: null,
        processing: false,
        error: null,
      });
      return null;
    }

    const url = urlJoin(urls.facebookLinkPreview, `?url=${linkUrl}`);
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({
            linkData: null,
            error: response.statusText,
          });
          throw new Error(response.statusText);
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        this.setState({
          linkData: data,
          processing: false,
          error: null,
        });
      })
      .catch(err => {
        this.setState({
          linkData: null,
          processing: false,
          error: err.message,
        });
        console.error(err);
      });
  }

  render () {
    const { readOnly, errors, linkUrl, onChange } = this.props;
    const { linkData, processing } = this.state;

    return (
      <>
        <div className={`form-group mb-6 ${errors ? 'has-error' : ''}`}>
          <h5>Link</h5>
          <div className="has-icon-right">
            <input className="form-input" type="text" maxLength={255} name="link" value={linkUrl} onChange={onChange} disabled={readOnly} />
            {processing ? <i className="form-icon loading" /> : null}
          </div>
          {errors ? errors.map(({ message }, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>) : null}
        </div>
        {!readOnly && !!linkUrl ? <FacebookLinkPreview data={linkData} /> : null}
      </>
    );
  }
}

FacebookReleaseLinkField.propTypes = {
  linkUrl: PropTypes.string,
  readOnly: PropTypes.bool,
  errors: PropTypes.object,
  onChange: PropTypes.func,
};

export default FacebookReleaseLinkField;
