import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ItemInlineForm = ({
  editing,
  labelFieldName,
  editableFieldNames = [],
  prefix,
  errors,
  onLabelChange,
  onConfirmEdit,
  ...rest
}) => {
  const fieldNames = [labelFieldName, ...editableFieldNames.filter(n => n !== labelFieldName)];

  const [fieldValues, setFieldValues] = useState(fieldNames.reduce((result, name) => {
    result[name] = rest[name];
    return result;
  }, {}));

  if (!editing) {
    return fieldNames.map(name => <input key={name} type="hidden" name={`${prefix}-${name}`} value={fieldValues[name]} />);
  }

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onConfirmEdit();
    }
  };

  const handleInputChange = e => {
    const name = e.target.name.substring(prefix.length + 1);
    const value = e.target.value;
    setFieldValues({ ...fieldValues, [name]: value });
    if (name === labelFieldName) onLabelChange(e.target.value);
  };

  const renderedErrors = fieldNames.reduce((result, name) => {
    const fieldErrors = (errors[name] || []).map((err, idx) => (
      <div key="idx" className="form-input-hint mt-1">{name}: {err.message}</div>
    ));
    return [...result, ...fieldErrors];
  }, []);

  const inputs = fieldNames.map((name, idx) => (
    <input
      key={name}
      type="text"
      className="form-input input-sm"
      name={`${prefix}-${name}`}
      value={fieldValues[name] || ''}
      onChange={handleInputChange}
      onKeyPress={handleKeyPress}
      autoFocus={idx === 0}
    />
  ));

  return inputs.length === 1 ? (
    <div style={{ flex: 1, margin: '-2px 5px -2px 0' }}>
      <div className="input-group">
        {inputs}
        <button className="btn btn-primary btn-sm input-group-btn" onClick={onConfirmEdit}>OK</button>
      </div>
      {renderedErrors}
    </div>
  ) : (
    <div style={{ display: 'flex', flex: 1, margin: '-2px 5px -2px 0' }}>
      <div style={{ display: 'flex', flex: 1, flexWrap: 'wrap' }}>
        {inputs.map((input, idx) => (
          <div key={idx} className="input-group mr-1" style={{ flex: 1, maxWidth: '50%' }}>
            <span className="input-group-addon addon-sm text-hint">{fieldNames[idx]}</span>
            {input}
          </div>
        ))}
        <div style={{ width: '100%' }}>{renderedErrors}</div>
      </div>
      <button className="btn btn-primary btn-sm" onClick={onConfirmEdit}>OK</button>
    </div>
  );
};

ItemInlineForm.propTypes = {
  editing: PropTypes.bool.isRequired,
  labelFieldName: PropTypes.string.isRequired,
  editableFieldNames: PropTypes.arrayOf(PropTypes.string),
  prefix: PropTypes.string,
  errors: PropTypes.object,
  onLabelChange: PropTypes.func.isRequired,
  onConfirmEdit: PropTypes.func.isRequired,
};

export default ItemInlineForm;
