import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Icon from 'components/common/Icon';
import ContributorSelect from './ContributorSelect';
import Item from './Item';


const ReadOnlyCreditsWidget = ({ items = [], disabled = false }) => {
  const containerRef = useRef();
  const itemListRef = useRef();
  const [expanded, setExpanded] = useState(false);

  const itemCountLabel = items.length === 1 ? 'Item' : 'Items';

  return (
    <ErrorBoundary>
      <div className={classNames('relation-field mt-1', disabled && 'disabled')} ref={containerRef}>
        <div className="controls top">
          <div className="item-count">{items.length} {itemCountLabel}</div>
          <div className="d-flex">
            <div style={{ width: 300, marginRight: 5 }}>
              <ContributorSelect
                contributors={[]}
                value={null}
                isLoading={false}
              />
            </div>
            <button
              type="button"
              className="btn btn-primary"
              style={{ height: 'unset' }}
              disabled
            >
              Add Credit
            </button>
          </div>
        </div>
        <div className="item-list" ref={itemListRef} style={{ height: expanded ? 'unset' : 350 }}>
          {items.map(({ uid, role, contributor, content }) => (
            <Item
              key={uid}
              className="mb-4"
              prefix="readonly-credits"
              id={uid}
              role={role}
              contributorData={contributor}
              contributor={uid}
              content={content}
              omitFields
            />
          ))}
        </div>
        <div
          key={expanded ? 'up' : 'down'}
          className="expand-btn"
          title={`${expanded ? 'Collapse' : 'Expand'} field`}
          onClick={() => setExpanded(!expanded)}
        >
          <Icon name={`arrow_drop_${expanded ? 'up' : 'down'}`} />
        </div>
      </div>
    </ErrorBoundary>
  );
};

ReadOnlyCreditsWidget.propTypes = {
  items: PropTypes.array,
  disabled: PropTypes.bool,
};

export default ReadOnlyCreditsWidget;
