export default {
  type: 'link',
  startTime: 0,
  duration: 5,
  linkUrl: '',
  media: null,
  title: null,
  label: '',
  labelSource: 'custom',
  description: '',
  descriptionSource: 'custom',
  popupStyle: 'expanded',
  thumbnailMedia: null,
};
