import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import camelize from 'camelize';
import queryString from 'query-string';
import ls from 'local-storage';
import { capitalize } from 'utils';
import { objectListUpdateSelection } from 'actions/objectListActions';
import { objectListConfig, getValidFilterParams } from './config';
import Transition from 'react-transition-group/Transition';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Message from 'components/common/Message';
import Pagination from 'components/common/Pagination';
import SampleDataImport from 'components/common/SampleDataImport';
import Table from './Table';


class ObjectList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: {},
      fetching: false,
      error: false,
      statusText: '',
      page: null,
      pageSize: null,
      sortField: null,
      sortDir: null,
      filterParams: {},
      selectedIds: [],
      rangeSelection: null,
      shiftKey: false,
    };
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleKeyUp = this.handleKeyUp.bind(this);
    this.handleNextPageClick = this.handleNextPageClick.bind(this);
    this.handlePrevPageClick = this.handlePrevPageClick.bind(this);
    this.handleSetPageSize = this.handleSetPageSize.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleViewAllClick = this.handleViewAllClick.bind(this);
    this.restorePersistedParams = this.restorePersistedParams.bind(this);
    this.updateQueryParams = this.updateQueryParams.bind(this);
    this.handleToggleItemSelect = this.handleToggleItemSelect.bind(this);
    this.handleToggleSelectAll = this.handleToggleSelectAll.bind(this);
  }

  /* eslint-disable camelcase */
  static getDerivedStateFromProps (nextProps, prevState) {
    const { location, model } = nextProps;
    const { page, per_page, sort, ...otherParams } = queryString.parse(location.search);

    const state = {
      page: page || null,
      pageSize: per_page || null,
      filterParams: {},
    };

    if (sort) {
      state.sortField = sort.startsWith('-') ? sort.slice(1) : sort;
      state.sortDir = sort.startsWith('-') ? 'desc' : 'asc';
    } else {
      state.sortField = null;
      state.sortDir = null;
    }

    const validFilterParams = getValidFilterParams(model);
    Object.keys(otherParams).forEach(key => {
      if (validFilterParams.includes(key)) {
        state.filterParams[key] = otherParams[key];
      }
    });

    return state;
  }
  /* eslint-enable camelcase */

  componentDidMount () {
    this.restorePersistedParams() || this.fetchData();
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('keyup', this.handleKeyUp);
  }

  componentDidUpdate (prevProps, prevState) {
    const { onSelectionChange, selectionKey, dataKey, actions, model, isModal } = this.props;
    const { fetching, error, data, filterParams, selectedIds } = this.state;

    if (!isModal && !fetching && prevState.fetching) {
      window.scrollTo(0, 0);
    }

    if (error && data.detail === 'Invalid page.') {
      this.setState({ error: false }, () => this.updateQueryParams({ page: 'last' }));
    }

    if (['page', 'pageSize', 'sortField', 'sortDir'].some(key => this.state[key] !== prevState[key])) {
      this.fetchData();
    }

    if (!isEqual(filterParams, prevState.filterParams)) {
      this.fetchData();
    }

    if (dataKey && dataKey !== prevProps.dataKey) {
      // Hacky way to trigger data refresh from an upstream component. TODO
      this.fetchData();
      this.containerRef.scrollTop = 0;
    }

    if (!isEqual(selectedIds, prevState.selectedIds)) {
      if (!isModal) {
        actions.objectListUpdateSelection({ model, selectedIds });
      }
      if (typeof onSelectionChange !== 'undefined') {
        const selectedObjects = selectedIds.map(id => data.results.find(obj => obj.id === id));
        onSelectionChange(selectedObjects);
      }
    }

    if (selectionKey && selectionKey !== prevProps.selectionKey) {
      // Hacky way to clear selection from an upstream component. TODO
      this.setState({ selectedIds: [] });
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('keyup', this.handleKeyUp);
  }

  handleKeyDown (e) {
    if (e.shiftKey) {
      this.setState({ shiftKey: true });
    }
  }

  handleKeyUp (e) {
    if (!e.shiftKey) {
      this.setState({ shiftKey: false });
    }
  }

  handleNextPageClick () {
    const { data: { pagination } } = this.state;
    if (pagination.next_page) {
      this.updateQueryParams({ page: pagination.next_page });
    }
  }

  handlePrevPageClick () {
    const { data: { pagination } } = this.state;
    if (pagination.previous_page) {
      this.updateQueryParams({ page: pagination.previous_page });
    }
  }

  handleSetPageSize (size) {
    this.updateQueryParams({ per_page: size });
    ls.set('objectList-pageSize', size);
  }

  handleSort (field) {
    const { sortField, sortDir } = this.state;
    if (field === sortField) {
      // We're already sorted on this field, so reverse the direction.
      this.updateQueryParams({ sort: `${sortDir === 'asc' ? '-' : ''}${field}` });
    } else {
      this.updateQueryParams({ sort: field });
    }
  }

  handleViewAllClick (e) {
    const { model } = this.props;
    const filterParams = getValidFilterParams(model);
    e.preventDefault();
    this.updateQueryParams(null, filterParams);
  }

  handleToggleItemSelect (itemObject) {
    const { allowMultipleSelection } = this.props;
    const { selectedIds, rangeSelection, shiftKey, data: { results } } = this.state;
    const index = results.findIndex(({ id }) => itemObject.id === id);

    if (allowMultipleSelection) {
      if (shiftKey && rangeSelection) {
        // Shift key is depressed, so trigger range selection behavior
        if (index === rangeSelection.index) {
          return null;
        }
        const checked = rangeSelection.checked;
        const startIndex = rangeSelection.index < index ? rangeSelection.index : index;
        const endIndex = rangeSelection.index < index ? index : rangeSelection.index;

        const idsInRange = results.slice(startIndex, endIndex + 1).map(({ id }) => id);
        if (checked) {
          const newIds = idsInRange.filter(id => !selectedIds.includes(id));
          this.setState({ selectedIds: [...selectedIds, ...newIds] });
        } else {
          this.setState({ selectedIds: selectedIds.filter(id => !idsInRange.includes(id)) });
        }
      } else {
        // No range selection -- standard behavior
        const isSelected = selectedIds.includes(itemObject.id);
        const newSelection = isSelected ? selectedIds.filter(id => id !== itemObject.id) : [...selectedIds, itemObject.id];
        this.setState({
          selectedIds: newSelection,
          rangeSelection: { checked: !isSelected, index },
        });
      }
    } else {
      // No multiple selection -- only one item at a time
      const newSelection = isEqual(selectedIds, [itemObject.id]) ? [] : [itemObject.id];
      this.setState({ selectedIds: newSelection });
    }
  }

  handleToggleSelectAll () {
    const { allowMultipleSelection } = this.props;
    const { selectedIds, data } = this.state;
    if (!allowMultipleSelection) {
      return;
    }
    const allItemsSelected = data.results.every(({ id }) => selectedIds.includes(id));
    this.setState({
      selectedIds: allItemsSelected ? [] : data.results.map(({ id }) => id),
    });
  }

  fetchData () {
    const { model, preFilter } = this.props;
    const { dataEndpoint } = objectListConfig[model];
    const { page, pageSize, sortField, sortDir, filterParams } = this.state;

    // The "preFilter" prop can pass a set of hardcoded filter params to always apply.
    const params = {
      format: 'json',
      ...filterParams,
      ...preFilter,
    };
    if (page) {
      params.page = page;
    }
    if (pageSize) {
      params.per_page = pageSize;
    }
    if (sortField && sortDir) {
      params.sort = `${sortDir === 'desc' ? '-' : ''}${sortField}`;
    }

    const dataUrl = `${dataEndpoint}?${queryString.stringify(params)}`;

    // Clear selected items whenever we fetch new data
    this.setState({ fetching: true, selectedIds: [] });
    fetch(dataUrl, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: true, statusText: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => this.setState({ data, fetching: false }))
      .catch(err => {
        this.setState({ fetching: false });
        console.error(err);
      });
  }

  restorePersistedParams () {
    const { location } = this.props;
    const urlParams = queryString.parse(location.search);
    const pageSize = ls.get('objectList-pageSize');
    if (pageSize && !('per_page' in urlParams)) {
      this.updateQueryParams({ per_page: pageSize });
      return true;
    }
    return false;
  }

  updateQueryParams (newParams, removeParams = []) {
    const { location, history } = this.props;
    const currentParams = queryString.parse(location.search);
    const params = {
      ...currentParams,
      ...newParams,
    };
    removeParams.forEach(key => delete params[key]);
    const qs = queryString.stringify(params);
    history.push(`${location.pathname}?${qs}`);
  }

  renderEmptyMessage () {
    const { model, isModal, allowSampleDataImport } = this.props;
    const { filterParams } = this.state;
    const filtersActive = Object.keys(filterParams).length > 0;

    return filtersActive ? (
      <div className="object-list-no-results">
        <p>No results matching the active filters were found.</p>
        <a className="btn" href="#view-all" onClick={this.handleViewAllClick}>View all {capitalize(model)}</a>
      </div>
    ) : (
      <div className="object-list-no-results">
        {!isModal && allowSampleDataImport
          ? (
            <div>
              <p>No {model} yet! Add some to get started, or click the button below to import example content.</p>
              <SampleDataImport />
            </div>
          )
          : <p>No {model} yet! Add some to get started.</p>}
      </div>
    );
  }

  render () {
    const { model, detailTargetBlank, imageClickToggleSelection } = this.props;
    const { columns, baseUrl } = objectListConfig[model];
    const { data, fetching, error, statusText, sortDir, sortField, selectedIds } = this.state;
    const paginationProps = camelize(data.pagination);
    const emptyResults = (data.results && data.results.length === 0 && !fetching) || false;

    const transitionClasses = {
      entering: 'fetching',
      entered: 'fetching show-animation',
      exiting: 'fetching',
      exited: '',
    };

    // TODO
    // let searchResultsText;
    // if (paginationProps) {
    //   const count = paginationProps.resultCount;
    //   searchResultsText = `${count || 0} Result${count === 1 ? '' : 's'}`;
    // }

    // Transition timeout prop here affects the delay *before* the loading animation is shown.
    // If the request completes quickly, we don't want to see a brief flash of the loading state.
    return (
      <ErrorBoundary>
        <Transition in={fetching} timeout={200}>
          {state => (
            <section ref={node => this.containerRef = node} className={`object-list ${transitionClasses[state]}`}>
              {error && !fetching ? <Message type="error" text={`Error fetching results: ${data.detail || statusText}`} /> : null}
              {emptyResults ? this.renderEmptyMessage() : null}
              <div className="object-list-table-container">
                {/* If the table overflows horizontally, this ensures the pagination div also fills the full width */}
                <div style={{ display: 'inline-block', minWidth: '100%' }}>
                  {data.results && !emptyResults ? [
                    <Table
                      key="object-list-table"
                      columns={columns}
                      items={data.results}
                      model={model}
                      detailBaseUrl={baseUrl}
                      sortDir={sortDir}
                      sortField={sortField}
                      selectedItemIds={selectedIds}
                      onSort={this.handleSort}
                      onToggleItemSelect={this.handleToggleItemSelect}
                      onToggleSelectAll={this.handleToggleSelectAll}
                      detailTargetBlank={detailTargetBlank}
                      imageClickToggleSelection={imageClickToggleSelection}
                    />,
                    <Pagination
                      {...paginationProps}
                      key="object-list-pagination"
                      resultsThisPage={data.results.length}
                      onSetPageSize={this.handleSetPageSize}
                      onClickNext={this.handleNextPageClick}
                      onClickPrev={this.handlePrevPageClick}
                    />,
                  ] : null}
                </div>
              </div>
            </section>
          )}
        </Transition>
      </ErrorBoundary>
    );
  }
}

ObjectList.propTypes = {
  actions: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  model: PropTypes.oneOf(Object.keys(objectListConfig)),
  preFilter: PropTypes.object,
  allowMultipleSelection: PropTypes.bool,
  isModal: PropTypes.bool,
  allowSampleDataImport: PropTypes.bool,
  dataKey: PropTypes.string,
  selectionKey: PropTypes.string,
  detailTargetBlank: PropTypes.bool,
  imageClickToggleSelection: PropTypes.bool,
  onSelectionChange: PropTypes.func,
};

ObjectList.defaultProps = {
  preFilter: {},
  allowMultipleSelection: true,
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ objectListUpdateSelection }, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(ObjectList));
