import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import File from './File';
import Image from './Image';
import Text from './Text';
import TextArea from './TextArea';
import RichText from './RichText';
import Checkbox from './Checkbox';
import Radio from './Radio';
import Select from './Select';
import Color from './Color';
import Font from './Font';


const DjangoFormField = ({ fieldType, widgetType, widgetInline, allowFormatting, value, onChange, ...rest }) => {
  const [internalValue, setInternalValue] = useState(value);
  useEffect(() => setInternalValue(value), [value]);

  let propsVal;
  if (!onChange) {
    onChange = evt => {
      let { type, value: v, checked } = evt.target;
      if (type === 'checkbox') v = !!checked;
      setInternalValue(v);
    };
    propsVal = internalValue;
  } else {
    propsVal = value;
  }

  const props = { value: propsVal, onChange, ...rest };

  switch (fieldType) {
    case 'ImageField':
      return <Image {...props} clearable={widgetType === 'ClearableFileInput'} />;
    case 'SVGAndImageField':
      return <Image {...props} acceptTypes={['jpg', 'jpeg', 'gif', 'png', 'svg']} clearable={widgetType === 'ClearableFileInput'} />;
    case 'FileField':
      return <File {...props} clearable={widgetType === 'ClearableFileInput'} />;
    case 'BooleanField':
      return <Checkbox {...props} />;
    case 'TypedChoiceField':
      // fall through
    case 'SerializableModelChoiceField':
      // fall through
    case 'ChoiceField':
      if (['RadioSelect', 'MspRadioSelect'].includes(widgetType) && props.choices) {
        return <Radio inline={widgetInline} {...props} />;
      } else if (props.choices) {
        return <Select {...props} />;
      } else {
        return <Text {...props} />;
      }
    case 'CharField':
      if (widgetType === 'Textarea') {
        return allowFormatting ? <RichText {...props} /> : <TextArea {...props} />;
      } else if (widgetType === 'ColorInput') {
        return <Color {...props} />;
      } else {
        return <Text {...props} />;
      }
    case 'FontField':
      return <Font {...props} />;
    default:
      return <Text {...props} />;
  }
};

DjangoFormField.propTypes = {
  fieldType: PropTypes.string,
  widgetType: PropTypes.string,
  widgetInline: PropTypes.bool,
  choices: PropTypes.array,
  allowFormatting: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
};

export default DjangoFormField;
