import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isUrl from 'is-url';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import urlJoin from 'url-join';
import camelize from 'camelize';
import queryString from 'query-string';
import { urls } from 'app-constants';
import { formatters } from 'utils';
import VisibilitySensor from 'react-visibility-sensor';
import withCSRF from 'components/common/withCSRF';
import LoadingOverlay from 'components/common/LoadingOverlay';


const isAvailableStatus = status => status === 'available';
const isPendingStatus = status => ['uploading', 'transcoding', 'transcode_starting'].includes(status);

class Vimeo extends Component {
  constructor (props) {
    super(props);
    this.state = {
      video: '',
      invalidVideo: false,
      processing: false,
      importResult: null,
      error: null,
      accountsFetching: false,
      accountsById: {},
      activeAccountId: null,
      accountVideosFetching: false,
      accountVideosData: {},
      accountVideos: [],
      accountVideosPage: 1,
      accountVideosSelected: [],
      accountVideosQuery: '',
      videoListMaxHeight: 500,
      queryProcessing: false,
      rangeSelection: null,
    };
    this.handleVideoInputChange = this.handleVideoInputChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleAccountImport = this.handleAccountImport.bind(this);
    this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
    this.handleToggleAccountVideo = this.handleToggleAccountVideo.bind(this);
    this.handleAccountVideoClick = this.handleAccountVideoClick.bind(this);
    this.handleListScrollEnd = this.handleListScrollEnd.bind(this);
    this.handleAccountFieldChange = this.handleAccountFieldChange.bind(this);
    this.doImport = this.doImport.bind(this);
    this.fetchAccountVideos = this.fetchAccountVideos.bind(this);
    this.fetchAccountVideosDebounced = debounce(this.fetchAccountVideos, 300);
    this.setVideoListHeight = this.setVideoListHeight.bind(this);
  }

  componentDidMount () {
    this.fetchAccounts();
  }

  componentDidUpdate (prevProps, prevState) {
    const { accountVideosFetching, activeAccountId, accountsById } = this.state;

    if (!accountVideosFetching && prevState.accountVideosFetching) {
      this.setState({ queryProcessing: false });
    }

    if (activeAccountId && (activeAccountId !== prevState.activeAccountId || !isEqual(accountsById, prevState.accountsById))) {
      this.setState({
        accountVideosData: {},
        accountVideos: [],
        accountVideosPage: 1,
        accountVideosSelected: [],
        accountVideosQuery: '',
      });
      this.fetchAccountVideos();
      this.setVideoListHeight();
      window.addEventListener('resize', this.setVideoListHeight);
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.setVideoListHeight);
  }

  handleVideoInputChange (e) {
    this.setState({ video: e.target.value });
  }

  handleFormSubmit (e) {
    const { video } = this.state;
    const isValid = this.validateVideo(video);
    e.preventDefault();
    this.setState({
      invalidVideo: !isValid,
    }, () => isValid && this.doImport([video]));
  }

  handleAccountImport () {
    const { accountVideosSelected } = this.state;
    if (accountVideosSelected.length) {
      this.doImport(accountVideosSelected);
    }
  }

  handleSearchInputChange (e) {
    this.setState({
      accountVideosQuery: e.target.value,
      accountVideosPage: 1,
      queryProcessing: true,
    }, this.fetchAccountVideosDebounced);
  }

  handleAccountVideoClick (e, index, id) {
    const { rangeSelection, accountVideos, accountVideosSelected } = this.state;

    // If the shift key is depressed when selecting an item, toggle the state of all
    // items between the one currently being clicked and the most recent previously clicked.
    if (e.shiftKey && rangeSelection) {
      if (index === rangeSelection.index) {
        return null;
      }

      const checked = rangeSelection.checked;
      const startIndex = rangeSelection.index < index ? rangeSelection.index : index;
      const endIndex = rangeSelection.index < index ? index : rangeSelection.index;

      const idsInRange = accountVideos.slice(startIndex, endIndex + 1).map(({ uri }) => uri.split('/').slice(-1)[0]);
      if (checked) {
        const newIds = idsInRange.filter(id => !accountVideosSelected.includes(id));
        this.setState({ accountVideosSelected: [...accountVideosSelected, ...newIds] });
      } else {
        this.setState({ accountVideosSelected: accountVideosSelected.filter(id => !idsInRange.includes(id)) });
      }
    } else {
      this.handleToggleAccountVideo(id);
    }
  }

  handleToggleAccountVideo (id) {
    const { accountVideos, accountVideosSelected } = this.state;
    const checked = !accountVideosSelected.includes(id);

    if (checked) {
      this.setState({ accountVideosSelected: [...accountVideosSelected, id] });
    } else {
      this.setState({ accountVideosSelected: accountVideosSelected.filter(item => item !== id) });
    }

    const index = accountVideos.findIndex(({ uri }) => uri.indexOf(id) > -1);
    this.setState({ rangeSelection: { checked, index } });
  }

  handleListScrollEnd (isVisible) {
    const { accountVideosPage } = this.state;
    if (isVisible) {
      this.setState({ accountVideosPage: accountVideosPage + 1 }, () => this.fetchAccountVideos(true));
    }
  }

  handleAccountFieldChange (e) {
    this.setState({ activeAccountId: e.target.value });
  }

  validateVideo (value) {
    // Accept either a valid URL containing string 'vimeo' or a numeric value
    return isUrl(value) ? value.toLowerCase().indexOf('vimeo') > -1 : !isNaN(value);
  }

  doImport (videoList) {
    const { csrfToken, onComplete } = this.props;
    const { activeAccountId } = this.state;
    const url = activeAccountId ? urlJoin(urls.vimeoImportBase, activeAccountId, '/') : urls.vimeoImport;
    const body = JSON.stringify({ videos: videoList });

    this.setState({ processing: true });
    fetch(url, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({ importResult: data, processing: false });
        onComplete && onComplete();
      })
      .catch(err => {
        this.setState({ processing: false });
        console.error(err);
      });
  }

  fetchAccounts () {
    this.setState({ accountsFetching: true });
    fetch(urls.vimeoAccountList, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ accountsError: true, statusText: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        const activeAccountId = data[0] ? data[0].id : null;
        const accountsById = data.reduce((result, item) => {
          const { id, ...rest } = item;
          result[id] = rest;
          return result;
        }, {});
        this.setState({
          accountsById,
          activeAccountId,
          accountsFetching: false,
        });
      })
      .catch(err => {
        this.setState({ accountsFetching: false });
        console.error(err);
      });
  }

  fetchAccountVideos (append = false) {
    const { activeAccountId, accountVideos, accountVideosPage, accountVideosQuery } = this.state;
    const queryParams = {
      format: 'json',
      page: accountVideosPage,
    };
    if (accountVideosQuery) {
      queryParams.query = accountVideosQuery;
    }

    const dataUrl = urlJoin(urls.vimeoListBase, activeAccountId, `?${queryString.stringify(queryParams)}`);

    this.setState({ accountVideosFetching: true });
    fetch(dataUrl, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ accountVideosError: true, statusText: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => this.setState({
        accountVideosData: data,
        accountVideos: append ? [...accountVideos, ...data.data] : data.data || [],
        accountVideosFetching: false,
      }))
      .catch(err => {
        this.setState({ accountVideosFetching: false });
        console.error(err);
      });
  }

  setVideoListHeight () {
    const maxHeight = window.innerHeight - this.videoListRef.getBoundingClientRect().top - 150;
    this.setState({ videoListMaxHeight: maxHeight });
  }

  renderVideoList () {
    const { accountVideos, accountVideosSelected, accountVideosData, accountVideosFetching, videoListMaxHeight } = this.state;
    const hasNextPage = accountVideosData.paging && !!accountVideosData.paging.next;

    return (
      <div className="account-videos-list" ref={node => this.videoListRef = node} style={{ maxHeight: videoListMaxHeight }}>
        {/* fetching in progress, no results */}
        {accountVideosFetching && accountVideos.length === 0 ? <div className="account-videos-page-loading" /> : null}

        {/* done fetching, no results */}
        {!accountVideosFetching && accountVideos.length === 0 ? (
          <div style={{ padding: '20px', textAlign: 'center' }}>No videos were found.</div>
        ) : null}
        {accountVideos.map(({ uri, name, link, duration, createdTime, pictures, status }, i) => {
          const thumbUrl = pictures.sizes.find(p => p.width >= 200).link;
          const id = uri.split('/').slice(-1)[0];
          const isSelected = accountVideosSelected.includes(id);
          const isAvailable = isAvailableStatus(status);
          const isPending = isPendingStatus(status);
          let handleClick = e => this.handleAccountVideoClick(e, i, id);
          if (!isAvailable) handleClick = () => {};

          const classes = classNames({
            'account-videos-item': true,
            selected: isSelected,
            disabled: !isAvailable,
          });

          return (
            <div key={id} className={classes}>
              <label className="form-checkbox">
                <input type="checkbox" disabled={!isAvailable} onClick={handleClick} checked={isSelected} />
                <i className="form-icon" />
              </label>
              <div
                className="account-videos-thumbnail"
                style={{ backgroundImage: `url('${thumbUrl}')` }}
                onClick={handleClick}
              />
              <div className="account-videos-detail">
                <div><a href={link} target="_blank" rel="noopener noreferrer">{name}</a></div>
                <div className="meta">
                  created: {formatters.shortDate(createdTime)} &nbsp;&middot;&nbsp; duration: {formatters.seconds(duration)}
                </div>
              </div>

              {!isAvailable && (
                <div className="overlay">
                  {isPending
                    ? 'This video is still processing. Check back later.'
                    : 'This video is unavailable. Check your Vimeo dashboard for details.'}
                </div>
              )}
            </div>
          );
        })}
        {hasNextPage ? (
          <VisibilitySensor onChange={this.handleListScrollEnd} delayedCall partialVisibility intervalDelay={1000}>
            <div className="account-videos-page-loading" />
          </VisibilitySensor>
        ) : null}
      </div>
    );
  }

  renderHelpText () {
    const { workspaceAdmin } = this.props;
    const { activeAccountId, accountsFetching } = this.state;
    if (accountsFetching) {
      return '';
    } else if (activeAccountId) {
      return <span>Import a video by entering its URL or Vimeo ID, or choose from your Vimeo library below.</span>;
    } else if (workspaceAdmin) {
      return <span>Import a public video by entering its URL or Vimeo ID. To access private videos, <a href={urls.organizationSettings}>connect your Vimeo account.</a></span>;
    } else {
      return <span>Import a public video by entering its URL or Vimeo ID.</span>;
    }
  }

  renderAccountField () {
    const { activeAccountId, accountsById } = this.state;
    const choices = Object.entries(accountsById).map(([id, data]) => <option key={id} value={id}>{data.accountName}</option>);
    return (
      <select className="form-select" style={{ width: 'auto' }} value={activeAccountId} onChange={this.handleAccountFieldChange}>
        {choices}
      </select>
    );
  }

  renderLibrary () {
    const { activeAccountId, accountsById, processing, accountVideosSelected, accountVideosQuery, queryProcessing } = this.state;
    if (!activeAccountId) {
      return null;
    }
    const { accountName } = accountsById[activeAccountId];
    const multipleAccounts = Object.keys(accountsById).length > 1;
    return (
      <div className="account-videos-container">
        <div className="d-flex align-items-center mb-2">
          <h6 className="identifier mb-0 mr-2">Vimeo Library:</h6>
          {multipleAccounts ? this.renderAccountField() : <strong>{accountName}</strong>}
        </div>
        <div className="account-videos">
          <header>
            <div className="input-group">
              <div className="has-icon-right" style={{ flex: 1, display: 'flex' }}>
                <input
                  type="text"
                  className="form-input"
                  placeholder="Search videos"
                  onChange={this.handleSearchInputChange}
                  value={accountVideosQuery}
                />
                {queryProcessing ? <i className="form-icon loading" /> : null}
              </div>
            </div>
          </header>
          {this.renderVideoList()}
        </div>
        <div className="media-import-actions">
          <div className="count">
            {accountVideosSelected.length} Video{accountVideosSelected.length === 1 ? '' : 's'} Selected
          </div>
          <button
            className="btn btn-primary"
            disabled={accountVideosSelected.length === 0 || processing}
            onClick={this.handleAccountImport}
          >
            Import Videos
          </button>
        </div>
      </div>
    );
  }

  render () {
    const { video, processing, error, invalidVideo } = this.state;

    return error ? (
      <div className="media-import-vimeo">
        <div className="toast toast-error">Import failed: {error}</div>
      </div>
    ) : (
      <div className="media-import-vimeo">
        <form onSubmit={this.handleFormSubmit}>
          <div className={`form-group ${invalidVideo ? 'has-error' : ''}`}>
            <div className="input-group">
              <input
                type="text"
                className="form-input"
                placeholder="URL or Vimeo ID"
                onChange={this.handleVideoInputChange}
                value={video}
                autoFocus
              />
              <button type="submit" className="btn btn-primary input-group-btn">Submit</button>
            </div>
            {invalidVideo ? <div className="form-input-hint">Enter a valid Vimeo URL or numeric ID</div> : null}
          </div>
          <div>{this.renderHelpText()}</div>
        </form>
        {this.renderLibrary()}
        <LoadingOverlay show={processing} />
      </div>
    );
  }
}

Vimeo.propTypes = {
  csrfToken: PropTypes.string,
  workspaceAdmin: PropTypes.bool,
  onComplete: PropTypes.func,
};

export default withCSRF(Vimeo);
