import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import ErrorBoundary from 'components/common/ErrorBoundary';
import ReleaseSourceSelect from 'components/views/ReleaseSourceSelect';
import { FacebookReleaseMessageField, FacebookReleaseLinkField, FacebookReleaseImagePreview } from './';


const POST_TYPE_LINK = 'link';
const POST_TYPE_IMAGE = 'image';

class FacebookReleaseCompose extends Component {
  constructor (props) {
    super(props);
    this.state = {
      postType: props.formData.fields.post_type.value,
      link: props.formData.fields.link.value,
      sourceObject: null,
    };

    this.handlePostTypeChange = this.handlePostTypeChange.bind(this);
    this.handleLinkChange = this.handleLinkChange.bind(this);
    this.handleSourceChange = this.handleSourceChange.bind(this);
  }

  handlePostTypeChange (e) {
    const postType = e.target.value;
    this.setState({ postType });
  }

  handleLinkChange (e) {
    const link = e.target.value;
    this.setState({ link });
  }

  handleSourceChange (obj) {
    this.setState({ sourceObject: obj });
  }

  renderSourceSelect () {
    const { readOnly } = this.props;
    const { fields } = camelize(this.props.formData);
    return (
      <div className="mb-6">
        <ReleaseSourceSelect
          sourceTypeVal={fields.sourceType.value || ''}
          mediaVal={fields.media.value || ''}
          titleVal={fields.title.value || ''}
          onChange={this.handleSourceChange}
          readOnly={readOnly}
        />
        {/* <p className="form-input-hint">The Facebook Post’s media attachments will be derived from the selected Media or Title object.</p> */}
      </div>
    );
  }

  render () {
    const { readOnly } = this.props;
    const { fields, errors } = camelize(this.props.formData);
    const { postType, sourceObject, link } = this.state;
    const sourceObjectType = sourceObject ? (sourceObject.hasOwnProperty('duration') ? 'media' : 'title') : null;

    return (
      <ErrorBoundary>
        <div>
          <h5>Post type</h5>
          <div className="form-group mb-6">
            <label className="form-radio m-0">
              <input
                type="radio"
                name="post_type"
                value={POST_TYPE_LINK}
                checked={postType === POST_TYPE_LINK}
                onChange={this.handlePostTypeChange}
                disabled={readOnly}
              />
              <i className="form-icon" /> <strong>Link</strong>
              <div className="text-hint">Post a link. Facebook will attempt to display a preview image, title and description based on the linked page’s metadata.</div>
            </label>
            <label className="form-radio m-0">
              <input
                type="radio"
                name="post_type"
                value={POST_TYPE_IMAGE}
                checked={postType === POST_TYPE_IMAGE}
                onChange={this.handlePostTypeChange}
                disabled={readOnly}
              />
              <i className="form-icon" /> <strong>Image</strong>
              <div className="text-hint">Post an image from the selected Media or Title object. Link URLs may be included in the post body, but Facebook will not generate an automatic link preview.</div>
            </label>
          </div>

          {postType === POST_TYPE_IMAGE ? this.renderSourceSelect() : (
            <>
              <input type="hidden" name="source_type" value={fields.sourceType.value || ''} />
              <input type="hidden" name="media" value={fields.media.value || ''} />
              <input type="hidden" name="title" value={fields.title.value || ''} />
            </>
          )}

          <FacebookReleaseMessageField
            longDescriptionVal={fields.longDescription.value || ''}
            messageInheritVal={fields.messageInherit.value}
            sourceObject={sourceObject}
            canInherit={postType === POST_TYPE_IMAGE}
            readOnly={readOnly}
          />

          {postType === POST_TYPE_LINK
            ? <FacebookReleaseLinkField linkUrl={link} readOnly={readOnly} errors={errors.link} onChange={this.handleLinkChange} />
            : <input type="hidden" name="link" value="" />}

          {postType === POST_TYPE_IMAGE && sourceObject && !readOnly
            ? <FacebookReleaseImagePreview objectType={sourceObjectType} objectUid={sourceObject.uuid} />
            : null}
        </div>
      </ErrorBoundary>
    );
  }
}

FacebookReleaseCompose.propTypes = {
  formData: PropTypes.object,
  readOnly: PropTypes.bool,
};

export default FacebookReleaseCompose;
