import React from 'react';
import QuillEditor from 'components/common/QuillEditor';
import { CUSTOM_TITLE_FIELD_PROPTYPES } from './';
import FieldWrapper from './FieldWrapper';


const TextAreaField = props => {
  const { name, value, autoFocus, allowFormatting, onChange } = props;
  return (
    <FieldWrapper {...props}>
      {allowFormatting ? (
        <QuillEditor value={value} onChange={onChange} />
      ) : (
        <textarea
          className="form-input"
          name={name}
          value={value}
          rows={8}
          autoFocus={autoFocus}
          onChange={e => onChange(e.target.value)}
        />
      )}
    </FieldWrapper>
  );
};

TextAreaField.propTypes = {
  ...CUSTOM_TITLE_FIELD_PROPTYPES,
};

TextAreaField.defaultProps = {
  onChange: () => null,
};

export default TextAreaField;
