import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const FormGroup = ({ className, alignItems = 'center', label, children }) => {
  return (
    <div className={classNames('d-flex', className)} style={{ alignItems }}>
      <div style={{ flex: '0 0 80px' }}>{label}</div>
      <div style={{ flex: 1, position: 'relative' }}>
        {children}
      </div>
    </div>
  );
};

FormGroup.propTypes = {
  className: PropTypes.string,
  alignItems: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
};

export default FormGroup;
