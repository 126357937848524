export default {
  heading: { headingStyle: 'h2', alignment: 'left', content: '' },
  button: { size: 'medium', alignment: 'center', text: '', linkUrl: '' },
  text: { content: '' },
  dynamic_text: { textStyle: 'p', alignment: 'left', source: '' },
  image_grid: {
    items: [],
    size: 'large',
    imageSize: 'medium',
    imageVersion: 'original',
  },
  media: {
    media: null,
    descriptionSource: 'media_long_desc',
    description: '',
    creditSource: 'media_credit',
    credit: '',
    size: 'large',
    backgroundVideo: false,
    loop: true,
  },
  title: {
    title: null,
    size: 'large',
    displayOptions: {},
  },
  embed: { size: 'large' },
  divider: { dividerStyle: 'whitespace', margin: 'medium' },
  related_links: { listStyle: 'none', source: 'item', displayUrls: false },
  credits: { displayImages: true, displayAffiliations: true, rolePosition: 'below' },
  publication_dates: {
    displayPublished: true,
    displayUpdated: true,
    publishedLabelText: 'Published:',
    updatedLabelText: 'Updated:',
    dateFormat: 'medium',
    displayTime: false,
    alignment: 'left',
  },
};
