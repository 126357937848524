import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { usePortal, usePrevious } from 'hooks';
import Icon from 'components/common/Icon';

const DrawerModal = ({ isOpen, title, nestingLevel = 1, onRequestClose, onDidClose, children }) => {
  const closeTimeout = useRef();
  const renderPortal = usePortal();

  const handleRequestClose = e => {
    e.preventDefault();
    onRequestClose();
  };

  const prevIsOpen = usePrevious(isOpen);
  useEffect(() => {
    const cleanup = () => clearTimeout(closeTimeout.current);
    cleanup();

    if (!isOpen && prevIsOpen) {
      closeTimeout.current = setTimeout(() => onDidClose(), 500);
    }

    return cleanup;
  }, [isOpen]);

  const maskClasses = classNames('drawer-modal-mask', isOpen && 'open');
  const modalClasses = classNames('drawer-modal', `level-${nestingLevel}`, isOpen && 'open');

  return renderPortal(
    <>
      <div className={maskClasses} />
      <div className={modalClasses}>
        <header className="drawer-modal-header">
          <a href="#back" className="back-icon" onClick={handleRequestClose}>
            <Icon name="arrow_back_ios" size={32} />
          </a>
          <h3>{title}</h3>
        </header>
        <div className="drawer-modal-content">
          {children}
        </div>
      </div>
    </>,
  );
};

DrawerModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string,
  nestingLevel: PropTypes.oneOf([1, 2, 3, 4, 5]),
  onRequestClose: PropTypes.func,
  onDidClose: PropTypes.func,
  children: PropTypes.node,
};

export default DrawerModal;
