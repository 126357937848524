import React from 'react';
import PropTypes from 'prop-types';


const DeleteOverlay = ({ onUndoClick }) => (
  <div className="delete-overlay">
    <div>
      <span className="text-error">This item will be removed on the next save. </span>
      <a href="#undo-delete" onClick={e => { e.preventDefault(); onUndoClick(e); }}>Undo</a>
    </div>
  </div>
);

DeleteOverlay.propTypes = {
  onUndoClick: PropTypes.func,
};

export default DeleteOverlay;
