import React from 'react';
import { CUSTOM_TITLE_FIELD_PROPTYPES } from './';
import FieldWrapper from './FieldWrapper';


const NumberField = props => {
  const { name, value, autoFocus, onChange } = props;
  const handleChange = e => {
    const value = parseFloat(e.target.value);
    onChange(isNaN(value) ? null : value);
  };
  return (
    <FieldWrapper {...props}>
      <input
        type="number"
        step="any"
        className="form-input"
        name={name}
        value={value === null ? '' : value}
        autoFocus={autoFocus}
        onChange={handleChange}
        style={{ width: 'unset' }}
      />
    </FieldWrapper>
  );
};

NumberField.propTypes = {
  ...CUSTOM_TITLE_FIELD_PROPTYPES,
};

NumberField.defaultProps = {
  onChange: () => null,
};

export default NumberField;
