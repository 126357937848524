import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import camelize from 'camelize';
import { connect } from 'react-redux';
import parseISO from 'date-fns/parseISO';
import { urls, helpUrls } from 'app-constants';
import { formatters, truncate } from 'utils';
import LoadingSpinner from 'components/common/LoadingSpinner';
import ReleaseEmbedCode from 'components/common/ReleaseEmbedCode';


class ReleasePublishStatus extends Component {
  constructor (props) {
    super(props);
    this.state = {
      statusLoaded: false,
      publishStatus: null,
      error: null,
      embedCodeVisible: false,
    };
  }

  componentDidMount () {
    this.fetchPublishStatus();
  }

  componentDidUpdate (prevProps, prevState) {
    const { lastPublishTimestamp } = this.props;
    const { embedCodeVisible } = this.state;

    if (lastPublishTimestamp !== prevProps.lastPublishTimestamp) {
      this.setState({ statusLoaded: false }, this.fetchPublishStatus);
    }

    if (embedCodeVisible && !prevState.embedCodeVisible) {
      setTimeout(() => this.embedCodeContainer.scrollIntoView({ behavior: 'smooth', block: 'end' }), 300);
    }
  }

  fetchPublishStatus () {
    const { releaseId } = this.props;
    const url = urlJoin(urls.releasePublishStatusBase, releaseId, '/');
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        this.setState({
          publishStatus: data,
          statusLoaded: true,
        });
      })
      .catch(err => {
        this.setState({ error: err });
        console.error(err);
      });
  }

  renderError (msg, style = 'error') {
    return (
      <div className={`${style}-list mb-4`}>
        <p dangerouslySetInnerHTML={{ __html: msg }} />
      </div>
    );
  }

  renderContents () {
    const { releaseId } = this.props;
    const { statusLoaded, publishStatus, error: fetchError, embedCodeVisible } = this.state;

    if (!statusLoaded) {
      return (
        <div style={{ height: 80, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LoadingSpinner />
        </div>
      );
    }

    let errorMessage = null;
    if (statusLoaded && !publishStatus.published) {
      errorMessage = 'This release has not yet been published.';
      return this.renderError(errorMessage, 'warning');
    }

    if (fetchError) {
      errorMessage = 'Error retrieving Release details.';
      return this.renderError(errorMessage);
    }

    const { resolved, result: { date: dateString, url, service, netlifySiteName, bucketName } } = publishStatus;
    const date = parseISO(dateString);

    if (url && !resolved) {
      errorMessage = `The release was successfully published on ${formatters.shortDateTime(date)}, but does not appear to be reachable at the specified URL (<span style="word-break:break-all">${url}</span>).`;
      if (service === 'netlify' && netlifySiteName) {
        const hostname = new URL(url).hostname;
        const isApex = hostname.split('.').length < 3;
        if (isApex) {
          errorMessage += ` Apex domains have special configuration requirements. If your DNS provider supports ALIAS or ANAME records, you can configure such a record pointing <code>${hostname}</code> to <code>${netlifySiteName}.netlify.app</code>. Otherwise, please refer to Netflify’s guidance on <a href="${helpUrls.netlifyApexDomainConfig}" target="_blank" rel="noopener noreferrer">configuring DNS for an apex domain</a>.`;
        } else {
          errorMessage += ` Ensure that you have configured a CNAME record with your DNS provider pointing <code>${hostname}</code> to <code>${netlifySiteName}.netlify.app</code>.`;
        }
      }
      if (service === 's3') {
        errorMessage += ` Ensure that you have configured your AWS settings according to our <a href="${helpUrls.websiteReleaseAWSConfig}" target="_blank" rel="noopener noreferrer">guidelines</a>.`;
      }
      return this.renderError(errorMessage);
    }

    return (
      <div style={{ position: 'relative' }} className="mb-2">
        <div className="card-item mb-4 p-2 text-sm">
          <div><strong>Last Published:</strong> {formatters.shortDateTime(date)}</div>
          {service && <div><strong>Service:</strong> {service}</div>}
          {netlifySiteName && <div><strong>Netlify Site:</strong> {netlifySiteName}</div>}
          {bucketName && <div><strong>S3 Bucket:</strong> {bucketName}</div>}
          {url && (
            <div>
              <div className="mb-2"><strong>URL:</strong> <a href={url} title={url} target="_blank" rel="noopener noreferrer">{truncate(url, 100)}</a></div>
              <hr />
              <div className="d-flex">
                <a className="btn btn-primary" href={url} target="_blank" rel="noopener noreferrer">View Published Release</a>
                {!embedCodeVisible && (
                  <button type="button" className="btn ml-2" onClick={() => this.setState({ embedCodeVisible: true })}>
                    Show Embed Code <i className="icon icon-caret" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>

        {embedCodeVisible && (
          <div ref={node => this.embedCodeContainer = node} className="pb-2">
            <ReleaseEmbedCode releaseId={releaseId} includeDirectLink={false} />
          </div>
        )}
      </div>
    );
  }

  render () {
    return (
      <div className="form-group mb-4">
        <h5 className="mb-2">Publish Status</h5>
        {this.renderContents()}
      </div>
    );
  }
}

ReleasePublishStatus.propTypes = {
  releaseId: PropTypes.string,
  lastPublishTimestamp: PropTypes.number,
};

const mapStateToProps = ({ releaseDetail }) => ({
  lastPublishTimestamp: releaseDetail.timestamp,
});

export default connect(mapStateToProps)(ReleasePublishStatus);
