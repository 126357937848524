import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { v4 as uuid4 } from 'uuid';
import TagSelect from 'components/common/TagSelect';


const TagFilter = ({ params, activeValue, onChange }) => {
  const instanceId = useRef(uuid4());

  const [paramsByState, setParamsByState] = useState({});

  const toggleActiveParam = param => {
    setParamsByState({
      active: param,
      inactive: param === params.allTags ? params.anyTags : params.allTags,
    });
  };

  useEffect(() => {
    if (activeValue && activeValue.allTags) {
      toggleActiveParam(params.allTags);
    } else {
      toggleActiveParam(params.anyTags);
    }
  }, [activeValue]);

  let activeTags = activeValue && (activeValue.anyTags || activeValue.allTags);
  activeTags = activeTags ? activeTags.split(',') : [];

  useEffect(() => {
    if (activeTags.length > 0) {
      onChange(paramsByState.active, activeTags.join(','), [paramsByState.inactive]);
    }
  }, [paramsByState.active]);

  const handleTagsChange = val => {
    onChange(paramsByState.active, val.join(','));
  };

  return (
    <div className={classNames('tag-filter', activeTags.length > 0 && 'active')}>
      <label key="any" className="form-radio">
        <input
          name={instanceId.current}
          type="radio"
          checked={paramsByState.active === params.anyTags}
          onChange={() => toggleActiveParam(params.anyTags)}
        />
        <i className="form-icon" /> Any of these tags
      </label>
      <label key="all" className="form-radio mb-3">
        <input
          name={instanceId.current}
          type="radio"
          checked={paramsByState.active === params.allTags}
          onChange={() => toggleActiveParam(params.allTags)}
        />
        <i className="form-icon" /> All of these tags
      </label>

      <TagSelect
        isCreatable={false}
        useNameValues
        value={activeTags}
        onChange={handleTagsChange}
      />
    </div>
  );
};

TagFilter.propTypes = {
  params: PropTypes.shape({
    anyTags: PropTypes.string,
    allTags: PropTypes.string,
  }),
  activeValue: PropTypes.shape({
    anyTags: PropTypes.string,
    allTags: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

export default TagFilter;
