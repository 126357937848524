import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ColorField from 'components/views/CustomTitleBuilder/CustomTitleField/ColorField';

class Color extends Component {
  constructor (props) {
    super(props);
    this.state = { color: props.value || '' };
    this.handleColorChange = this.handleColorChange.bind(this);
  }

  componentDidUpdate (prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({ color: value });
    }
  }

  handleColorChange (color) {
    const { name, onChange } = this.props;
    this.setState({ color });
    const fakeEvt = {
      target: {
        name,
        type: 'text',
        value: color,
      },
    };
    onChange(fakeEvt);
  }

  render () {
    const { color } = this.state;
    return <ColorField {...this.props} clearable value={color} onChange={this.handleColorChange} />;
  }
}

Color.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  helpText: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.array,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
};

Color.defaultProps = {
  errors: [],
  onChange: () => null,
};

export default Color;
