import React from 'react';
import { CUSTOM_TITLE_FIELD_PROPTYPES } from './';
import Checkbox from 'components/common/Checkbox';


const BooleanField = props => {
  const { name, value, label, helpText, errors, className, style, onChange } = props;
  const handleChange = e => onChange(value === null ? true : !value);
  return (
    <div className={`form-group mb-4 ${errors.length ? 'has-error' : ''} ${className || ''}`} style={style}>
      <label className="form-checkbox my-0">
        <Checkbox
          name={name}
          onChange={handleChange}
          checked={value}
        />
        <i className="form-icon" />
        {label}
      </label>
      {helpText && !errors.length ? <p className="form-input-hint m-0">{helpText}</p> : null}
      {errors.map((message, idx) => <p key={idx} className="form-input-hint mb-0">{message}</p>)}
    </div>
  );
};

BooleanField.propTypes = {
  ...CUSTOM_TITLE_FIELD_PROPTYPES,
};

BooleanField.defaultProps = {
  errors: [],
};

export default BooleanField;
