import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';

const PageHeader = ({ title, subtitle, iconName, buttons }) => (
  <header className="header-page">
    {iconName && <div className="icon-wrap"><Icon name={iconName} fill size={48} /></div>}
    <div className="title-wrap">
      <div className="title">{title}</div>
      {subtitle && <div className="subtitle">{subtitle}</div>}
    </div>
    {buttons && (
      <div className="action-button-group">
        {buttons.map(({ label, submit, href, onClick = () => {} }) => (
          <div key={label}>
            {submit && <input className="btn btn-primary" type="submit" value={label} />}
            {!submit && href && <a className="btn btn-primary" href={href}>{label}</a>}
            {!submit && !href && <button className="btn btn-primary" type="button" onClick={onClick}>{label}</button>}
          </div>
        ))}
      </div>
    )}
  </header>
);

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  iconName: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    submit: PropTypes.bool,
    href: PropTypes.string,
    onClick: PropTypes.func,
  })),
};

export default PageHeader;
