import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import renderBlockErrors from './renderBlockErrors';
import Select from 'components/common/Select';
import TextStyleSelect from 'components/common/TextStyleSelect';
import FormGroup from './FormGroup';


const Heading = ({ uid, errors, headingStyle, alignment, content, linkUrl, onPreviewTextUpdate, onChange }) => {
  const styleOptions = [
    { value: 'display_heading', label: 'Display Heading', helpText: '(extra large)' },
    { value: 'h1', label: 'Heading 1' },
    { value: 'h2', label: 'Heading 2' },
    { value: 'h3', label: 'Heading 3' },
  ];
  const styleSelectVal = styleOptions.find(opt => opt.value === headingStyle);
  const handleStyleChange = val => onChange({ headingStyle: val });
  const alignOptions = [
    { value: 'left', label: 'Left' },
    { value: 'center', label: 'Center' },
    { value: 'right', label: 'Right' },
  ];
  const alignSelectVal = alignOptions.find(opt => opt.value === alignment);
  const handleAlignChange = val => onChange({ alignment: val });
  const handleTextChange = e => onChange({ content: e.target.value });
  const handleLinkUrlChange = e => onChange({ linkUrl: e.target.value });

  useEffect(() => {
    onPreviewTextUpdate(content || '');
  }, [content]);

  const renderedErrors = renderBlockErrors(errors);

  return (
    <div className="content">
      <FormGroup className="mb-3" label="Style:">
        <TextStyleSelect
          value={styleSelectVal}
          options={styleOptions}
          onChange={handleStyleChange}
        />
        {renderedErrors.headingStyle}
      </FormGroup>

      <FormGroup className="mb-3" label="Alignment:">
        <Select
          value={alignSelectVal}
          options={alignOptions}
          onChange={handleAlignChange}
        />
        {renderedErrors.alignment}
      </FormGroup>

      <FormGroup className="mb-3" label="Text:">
        <input
          type="text"
          className="form-input"
          onChange={handleTextChange}
          value={content}
        />
        {renderedErrors.content}
      </FormGroup>

      <FormGroup label="Link URL:">
        <input
          type="text"
          className="form-input"
          onChange={handleLinkUrlChange}
          value={linkUrl || ''}
        />
        {renderedErrors.linkUrl}
      </FormGroup>
    </div>
  );
};

Heading.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  headingStyle: PropTypes.oneOf(['display_heading', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
  alignment: PropTypes.oneOf(['left', 'center', 'right']),
  content: PropTypes.string,
  linkUrl: PropTypes.string,
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default Heading;
