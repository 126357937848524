import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import queryString from 'query-string';
import LocationWidget from 'components/common/LocationWidget';
import CountrySelect from 'components/common/CountrySelect';
import LoadingSpinner from 'components/common/LoadingSpinner';


class LocationField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      lat: props.latField.value,
      lng: props.lngField.value,
      placename: props.placenameField.value,
      address1: props.address1Field.value,
      address2: props.address2Field.value,
      city: props.cityField.value,
      stateProvince: props.stateProvinceField.value,
      postalCode: props.postalCodeField.value,
      country: props.countryField.value,
      geocodeFetching: false,
      reverseGeocodeFetching: false,
      geocodeError: null,
    };

    this.fieldPrefix = 'locationField-';

    this.handleLocationChange = this.handleLocationChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleGeocodeClick = this.handleGeocodeClick.bind(this);
    this.handleReverseGeocodeClick = this.handleReverseGeocodeClick.bind(this);
  }

  handleLocationChange (lat, lng) {
    this.setState({ lat, lng });
  }

  handleInputChange (e) {
    let { name, value } = e.target;
    name = name.replace(this.fieldPrefix, '');
    this.setState({ [name]: value });
  }

  handleCountryChange (value) {
    this.setState({ country: value });
  }

  handleGeocodeClick () {
    const { address1, address2, city, stateProvince, postalCode, country } = this.state;
    const apiUrl = 'https://nominatim.openstreetmap.org/search';
    const params = {
      format: 'json',
      q: [address1, address2, city, stateProvince, postalCode].filter(s => !!s).join(', '),
    };
    if (country) {
      params.countrycodes = country;
    }
    const url = `${apiUrl}?${queryString.stringify(params)}`;

    this.setState({ geocodeFetching: true, geocodeError: null });
    fetch(url)
      .then(response => {
        if (!response.ok) {
          this.setState({ geocodeError: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        if (data.length > 0) {
          const { lat, lon } = data[0];
          this.setState({
            lat,
            lng: lon,
            geocodeFetching: false,
          });
        } else {
          this.setState({
            geocodeError: 'No results were found for this address.',
            geocodeFetching: false,
          });
        }
      })
      .catch(err => {
        this.setState({ geocodeFetching: false });
        console.error(err);
      });
  }

  handleReverseGeocodeClick () {
    const { lat, lng } = this.state;
    if (lat === null || lng === null) {
      this.setState({ geocodeError: 'Select a location on the map in order to perform reverse geocoding.' });
      return false;
    }

    const apiUrl = 'https://nominatim.openstreetmap.org/reverse';
    const params = {
      format: 'json',
      lat,
      lon: lng,
    };
    const url = `${apiUrl}?${queryString.stringify(params)}`;

    this.setState({ reverseGeocodeFetching: true, geocodeError: null });
    fetch(url)
      .then(response => {
        if (!response.ok) {
          this.setState({ geocodeError: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        if (!data.error) {
          const { displayName } = data;
          const { houseNumber, road, hamlet, village, town, city, state, region, postcode, countryCode } = data.address || {};
          this.setState({
            address1: `${houseNumber ? houseNumber + ' ' : ''}${road || ''}`,
            address2: '',
            city: city || town || village || hamlet || '',
            stateProvince: state || region || '',
            postalCode: postcode || '',
            country: countryCode ? countryCode.toUpperCase() : '',
            placename: displayName || '',
            reverseGeocodeFetching: false,
          });
        } else {
          this.setState({
            geocodeError: 'No results were found for this location.',
            reverseGeocodeFetching: false,
          });
        }
      })
      .catch(err => {
        this.setState({
          reverseGeocodeFetching: false,
          geocodeError: 'Geocoding failed due to a network error. Try again later.',
        });
        console.error(err);
      });
  }

  errorClass (name) {
    return this.props[`${name}Field`].errors.length > 0 ? 'has-error' : '';
  }

  renderErrors (name) {
    // eslint-disable-next-line react/prop-types
    const { errors } = this.props[`${name}Field`];
    if (errors.length === 0) return null;
    return errors.map((msg, idx) => <p key={`${name}-error-idx`} className="form-input-hint">{msg}</p>);
  }

  renderHiddenFields () {
    const {
      latField,
      lngField,
      placenameField,
      address1Field,
      address2Field,
      cityField,
      stateProvinceField,
      postalCodeField,
      countryField,
    } = this.props;
    const { placename, address1, address2, city, stateProvince, postalCode, country } = this.state;
    let { lat, lng } = this.state;

    lat = !isNaN(parseFloat(lat)) ? lat : '';
    lng = !isNaN(parseFloat(lng)) ? lng : '';

    return (
      <>
        <input type="hidden" name={latField.name} value={lat} />
        <input type="hidden" name={lngField.name} value={lng} />
        <input type="hidden" name={placenameField.name} value={placename} />
        <input type="hidden" name={address1Field.name} value={address1} />
        <input type="hidden" name={address2Field.name} value={address2} />
        <input type="hidden" name={cityField.name} value={city} />
        <input type="hidden" name={stateProvinceField.name} value={stateProvince} />
        <input type="hidden" name={postalCodeField.name} value={postalCode} />
        <input type="hidden" name={countryField.name} value={country} />
      </>
    );
  }

  render () {
    const {
      lat,
      lng,
      placename,
      address1,
      address2,
      city,
      stateProvince,
      postalCode,
      country,
      geocodeFetching,
      reverseGeocodeFetching,
      geocodeError,
    } = this.state;

    const spinner = <LoadingSpinner size={20} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }} />;

    return (
      <div className="object-detail-content">
        <section className="object-detail-main">
          <div className="location-tab-inner">
            <p className="form-input-hint mt-0 mb-1">Click a point on the map below to set a location, or enter coordinates directly in the fields provided.</p>
            <LocationWidget
              latitude={lat}
              longitude={lng}
              disableScrollWheelZoom={false}
              onChange={this.handleLocationChange}
            />
            {this.renderHiddenFields()}
          </div>
        </section>
        <section className="object-detail-sidebar">
          <div className={`form-group mb-6 ${this.errorClass('placename')}`}>
            <h5 className="mb-1">Placename</h5>
            <input name={`${this.fieldPrefix}placename`} type="text" className="form-input" onChange={this.handleInputChange} value={placename} />
            {this.renderErrors('placename') || <div className="text-hint mt-1">Enter a display-friendly name for this location. This will appear when the content is published.</div>}
          </div>

          <h5 className="m-0">Location Details</h5>
          <div className="text-hint mt-1 mb-3">Optionally enter specific address details for this location. This information is not displayed when content is published, but may be useful for internal record keeping and search.</div>

          <div className={`form-group mb-3 ${this.errorClass('address1')}`}>
            <label className="block-label">Address Line 1</label>
            <input name={`${this.fieldPrefix}address1`} type="text" className="form-input" onChange={this.handleInputChange} value={address1} />
            {this.renderErrors('address1')}
          </div>

          <div className={`form-group mb-3 ${this.errorClass('address2')}`}>
            <label className="block-label">Address Line 2</label>
            <input name={`${this.fieldPrefix}address2`} type="text" className="form-input" onChange={this.handleInputChange} value={address2} />
            {this.renderErrors('address2')}
          </div>

          <div className={`form-group mb-3 ${this.errorClass('city')}`}>
            <label className="block-label">City</label>
            <input name={`${this.fieldPrefix}city`} type="text" className="form-input" onChange={this.handleInputChange} value={city} />
            {this.renderErrors('city')}
          </div>

          <div className={`form-group mb-3 ${this.errorClass('stateProvince')}`}>
            <label className="block-label">State / Province / Region</label>
            <input name={`${this.fieldPrefix}stateProvince`} type="text" className="form-input" onChange={this.handleInputChange} value={stateProvince} />
            {this.renderErrors('stateProvince')}
          </div>

          <div className={`form-group mb-3 ${this.errorClass('postalCode')}`}>
            <label className="block-label">ZIP / Postal Code</label>
            <input name={`${this.fieldPrefix}postalCode`} type="text" className="form-input" onChange={this.handleInputChange} value={postalCode} />
            {this.renderErrors('postalCode')}
          </div>

          <div className={`form-group mb-6 ${this.errorClass('country')}`}>
            <label className="block-label">Country</label>
            <CountrySelect allowEmpty value={[country]} onChange={this.handleCountryChange} />
            {this.renderErrors('country')}
          </div>

          <h5>Geocoding</h5>
          {geocodeError && <div className="error-list mb-3">{geocodeError}</div>}

          <div className="mb-3 p-3 bg-gray-light d-flex align-items-center">
            <button
              className="btn mr-2"
              type="button"
              style={{ position: 'relative' }}
              onClick={this.handleGeocodeClick}
              disabled={geocodeFetching || reverseGeocodeFetching}
            >
              <span style={{ opacity: geocodeFetching ? 0 : 1 }}>Geocode</span>
              {geocodeFetching && spinner}
            </button>
            <div className="text-hint">Set map coordinates to the address specified in the Location Details section.</div>
          </div>

          <div className="mb-3 p-3 bg-gray-light d-flex align-items-center">
            <button
              className="btn mr-2"
              type="button"
              style={{ position: 'relative' }}
              onClick={this.handleReverseGeocodeClick}
              disabled={geocodeFetching || reverseGeocodeFetching}
            >
              <span style={{ opacity: reverseGeocodeFetching ? 0 : 1 }}>Reverse Geocode</span>
              {reverseGeocodeFetching && spinner}
            </button>
            <div className="text-hint">Fill Placename and Location Details based on current map coordinates.</div>
          </div>
        </section>
      </div>
    );
  }
}

LocationField.propTypes = {
  latField: PropTypes.object,
  lngField: PropTypes.object,
  placenameField: PropTypes.object,
  address1Field: PropTypes.object,
  address2Field: PropTypes.object,
  cityField: PropTypes.object,
  stateProvinceField: PropTypes.object,
  postalCodeField: PropTypes.object,
  countryField: PropTypes.object,
};

export default LocationField;
