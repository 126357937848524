import { OBJECT_LIST_UPDATE_SELECTION } from '../actions/objectListActions';


export const objectList = (state = {}, action) => {
  switch (action.type) {
    case OBJECT_LIST_UPDATE_SELECTION: {
      const { model, selectedIds } = action.payload;
      return {
        ...state,
        [model]: {
          ...(state[model] || {}),
          selectedIds,
        },
      };
    }
    default:
      return state;
  }
};
