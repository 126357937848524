import React from 'react';
import PropTypes from 'prop-types';
import { formatters } from 'utils';


const SingleValueMetric = ({ id, label, value, valueType }) => {
  let valueFormatted = value;
  if (formatters.hasOwnProperty(valueType)) {
    valueFormatted = formatters[valueType](value);
  }
  return (
    <div className="analytics-metric">
      <h6 className="analytics-metric-label">{label}</h6>
      <div className="analytics-metric-value">{valueFormatted}</div>
    </div>
  );
};

SingleValueMetric.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  valueType: PropTypes.string,
};

export default SingleValueMetric;
