import React from 'react';
import PropTypes from 'prop-types';
import renderBlockErrors from './renderBlockErrors';
import Select from 'components/common/Select';
import MediaRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/MediaRelationField';
import TitleRelationField from 'components/views/CustomTitleBuilder/CustomTitleField/TitleRelationField';
import FormGroup from './FormGroup';


const SOURCE_OPTIONS = [
  { value: 'item', label: 'Current Item', helpText: 'Display metadata associated with the current active item' },
  // { value: 'release', label: 'Release', helpText: 'Display metadata associated with the release' },
  { value: 'media', label: 'Media', helpText: 'Display metadata associated with a specific media object' },
  { value: 'title', label: 'Title', helpText: 'Display metadata associated with a specific title' },
];

const Credits = ({
  uid,
  errors,
  allowSubItemSource = true,
  source,
  media,
  title,
  useSubItems,
  displayImages,
  displayAffiliations,
  rolePosition,
  onChange,
}) => {
  const createHandler = name => val => onChange({ [name]: val });
  const handleSourceChange = val => {
    const updates = { source: val };
    if (val !== 'title') {
      updates.title = null;
    }
    if (val !== 'media') {
      updates.media = null;
    }
    onChange(updates);
  };

  const handleInputChange = e => {
    let { name, value, type, checked } = e.target;
    name = name.replace(`${uid}__`, '');
    if (type === 'checkbox') {
      value = checked;
    }
    onChange({ [name]: value });
  };

  const sourceSelectVal = SOURCE_OPTIONS.find(opt => opt.value === source);
  const showSubItemsField = allowSubItemSource && source === 'item';
  const renderedErrors = renderBlockErrors(errors);

  return (
    <div className="content">
      <FormGroup className="mb-3" label="Source:">
        <Select
          value={sourceSelectVal}
          options={SOURCE_OPTIONS}
          onChange={handleSourceChange}
        />
        {renderedErrors.source}
      </FormGroup>

      {source === 'media' && (
        <FormGroup className="mb-3">
          <MediaRelationField className="m-0" value={media} onChange={createHandler('media')} />
          {renderedErrors.media}
        </FormGroup>
      )}

      {source === 'title' && (
        <FormGroup className="mb-3">
          <TitleRelationField className="m-0" value={title} onChange={createHandler('title')} />
          {renderedErrors.title}
        </FormGroup>
      )}

      {showSubItemsField && (
        <FormGroup className="mb-1" alignItems="baseline" label="Source:">
          <label className="form-checkbox">
            <input name={`${uid}__useSubItems`} type="checkbox" checked={useSubItems} onChange={handleInputChange} />
            <i className="form-icon" /> Include Sub-items
            <div className="text-hint">
              If this option is selected, metadata will be sourced from the current active item <em>within</em> the current title (for supported title types) — for example, an image within a slideshow title or a video within a playlist title. Deselect this option to source metadata only from the title itself.
            </div>
          </label>
          {renderedErrors.useSubItems}
        </FormGroup>
      )}

      <FormGroup alignItems="baseline" label="Show role:">
        <label className="form-radio m-0">
          <input
            type="radio"
            name={`${uid}__rolePosition`}
            value="above"
            checked={rolePosition === 'above'}
            onChange={handleInputChange}
          />
          <i className="form-icon" /> Above contributor details
          <div className="text-hint">Adjacent credits with the same role will be grouped under a common heading.</div>
        </label>
        <label className="form-radio m-0">
          <input
            type="radio"
            name={`${uid}__rolePosition`}
            value="below"
            checked={rolePosition === 'below'}
            onChange={handleInputChange}
          />
          <i className="form-icon" /> Below contributor details
          <div className="text-hint">Role will be displayed with each individual credit.</div>
        </label>
        {renderedErrors.rolePosition}
      </FormGroup>

      <FormGroup>
        <hr />
      </FormGroup>

      <FormGroup alignItems="baseline" label="Include:">
        <label className="form-checkbox mb-0">
          <input type="checkbox" name={`${uid}__displayImages`} checked={displayImages} onChange={handleInputChange} />
          <i className="form-icon" /> Contributor images
        </label>
        {renderedErrors.displayImages}
        <label className="form-checkbox mt-0">
          <input type="checkbox" name={`${uid}__displayAffiliations`} checked={displayAffiliations} onChange={handleInputChange} />
          <i className="form-icon" /> Contributor affiliations
        </label>
        {renderedErrors.displayAffiliations}
      </FormGroup>
    </div>
  );
};

Credits.propTypes = {
  uid: PropTypes.string,
  errors: PropTypes.object,
  allowSubItemSource: PropTypes.bool,
  source: PropTypes.oneOf(['', ...SOURCE_OPTIONS.map(opt => opt.value)]),
  media: PropTypes.string,
  title: PropTypes.string,
  useSubItems: PropTypes.bool,
  displayImages: PropTypes.bool,
  displayAffiliations: PropTypes.bool,
  rolePosition: PropTypes.oneOf(['above', 'below']),
  onPreviewTextUpdate: PropTypes.func,
  onChange: PropTypes.func,
};

export default Credits;
