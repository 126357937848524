import React, { useRef } from 'react';
import { useClickOutside } from 'hooks';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';

const blockTypes = [
  { slug: 'heading', description: 'Heading text', iconName: 'format_h1' },
  { slug: 'button', description: 'Button with link', iconName: 'ads_click' },
  { slug: 'divider', description: 'Horizontal divider / spacer', iconName: 'horizontal_rule' },
  { slug: 'text', description: 'Free-form text with basic formatting options', iconName: 'subject' },
  { slug: 'embed', description: 'Embed code (iframe-based embeds only)', iconName: 'code' },
  { slug: 'dynamic_text', description: 'Text derived from a field on an existing object', iconName: 'article_shortcut' },
  { slug: 'credits', description: 'Credits for the current active item', iconName: 'contacts' },
  { slug: 'related_links', description: 'Links related to the current active item', iconName: 'link' },
  { slug: 'publication_dates', description: 'Release published / updated dates', iconName: 'calendar_month' },
  { slug: 'media', description: 'Media with optional description and credit', iconName: 'theaters' },
  { slug: 'title', label: 'Title', description: 'Embedded title object', iconName: 'apps' },
  { slug: 'image_grid', description: 'Images in a grid layout with optional hyperlinks', iconName: 'grid_on' },
];

const AddBlockMenu = ({ style = {}, onItemSelect, onClickOutside = () => {} }) => {
  const containerRef = useRef();
  useClickOutside(containerRef, onClickOutside);

  return (
    <div ref={containerRef} className="sc-add-block-menu" style={style}>
      {blockTypes.map(({ slug, label, description, iconName }) => (
        <div key={slug} className="sc-add-block-menu-item" onClick={() => onItemSelect(slug)}>
          <div className="icon-container">
            <Icon name={iconName} />
          </div>
          <div>
            <div style={{ textTransform: 'capitalize' }}><strong>{label || slug.replace(/_/g, ' ')}</strong></div>
            <div className="text-meta">{description}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

AddBlockMenu.propTypes = {
  style: PropTypes.object,
  onItemSelect: PropTypes.func,
  onClickOutside: PropTypes.func,
};

export default AddBlockMenu;
