import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/common/Icon';
import AttachedItemPreview from 'components/common/AttachedItemPreview';
import AddLibraryMedia from 'components/common/AddLibraryMedia';


class LibraryMediaField extends Component {
  constructor (props) {
    super(props);
    this.state = { value: props.value };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount () {
    const { value } = this.props;
    this.setState({ value });
  }

  componentDidUpdate (prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({ value });
    }
  }

  handleChange (value) {
    const { onChange, disabled } = this.props;
    if (!disabled) {
      onChange ? onChange(value) : this.setState({ value });
    }
  }

  getObjectListOptions () {
    const { limitTypes, disabledFilters = [] } = this.props;
    const objectListOptions = { disabledFilters };
    if (limitTypes.length > 1) {
      // Pre-filter the object list to display only allowed types
      objectListOptions.preFilter = {
        type__in: limitTypes.join(','),
      };
    } else if (limitTypes.length === 1) {
      // If only one type is allowed, disable the 'type' filter control
      objectListOptions.preFilter = {
        type: limitTypes[0],
      };
      objectListOptions.disabledFilters.push('type');
    }
    return objectListOptions;
  }

  render () {
    const { inputName, buttonText, disabled, onChange } = this.props;
    const { value } = onChange ? this.props : this.state;
    return (
      <div style={disabled ? { opacity: 0.5, pointerEvents: 'none' } : {}}>
        {value ? (
          <AttachedItemPreview itemType="media" itemId={value} clickableThumbnail>
            <div className="btn-delete tooltip tooltip-left" data-tooltip="Remove Media" onClick={() => this.handleChange(null)}>
              <Icon name="delete" fill />
            </div>
          </AttachedItemPreview>
        ) : (
          <AddLibraryMedia allowMultiple={false} onChange={this.handleChange} {...this.getObjectListOptions()}>
            {handleModalTrigger => (
              <div className="attached-item-preview justify-content-center">
                <button type="button" className="btn btn-primary" onClick={e => { e.preventDefault(); !disabled && handleModalTrigger(); }}>{buttonText || 'Choose Media'}</button>
              </div>
            )}
          </AddLibraryMedia>
        )}
        {!!inputName && <input name={inputName} type="hidden" value={value || ''} />}
      </div>
    );
  }
}

LibraryMediaField.propTypes = {
  inputName: PropTypes.string,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  limitTypes: PropTypes.arrayOf(PropTypes.string),
  disabledFilters: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
};

LibraryMediaField.defaultProps = {
  disabled: false,
  limitTypes: [],
};

export default LibraryMediaField;
