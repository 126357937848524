import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


const Message = ({
  type = 'primary',
  text,
  clearable = true,
  timeout,
  children,
}) => {
  const [hide, setHide] = useState(false);

  const timeoutRef = useRef();
  useEffect(() => {
    if (timeout) {
      timeoutRef.current = setTimeout(() => {
        setHide(true);
      }, timeout * 1000);
    }
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const handleButtonClick = evt => {
    evt.preventDefault();
    setHide(true);
  };

  const classes = classNames(
    'toast',
    `toast-${type}`,
    { hide },
  );

  return (
    <div className={classes}>
      {clearable && <button className="btn btn-clear float-right" onClick={handleButtonClick} />}
      {children || <span dangerouslySetInnerHTML={{ __html: text }} />}
    </div>
  );
};

Message.propTypes = {
  type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  text: PropTypes.string,
  clearable: PropTypes.bool,
  timeout: PropTypes.number, // In seconds
  children: PropTypes.node,
};

export default Message;
