import React from 'react';
import PropTypes from 'prop-types';


const ErrorList = ({ errors, className, style, showFieldNames = true }) => {
  // Flatten out the error objects.
  const errorList = Object.keys(errors).reduce((result, key) => {
    const fieldErrors = errors[key].map(({ message }) => ({ field: key, message }));
    return [...result, ...fieldErrors];
  }, []);

  return (
    <div className={`error-list ${className || ''}`} style={style}>
      {errorList.map(({ field, message }, i) => (
        <p key={i}>
          {showFieldNames && field !== '_all_' && <strong>{field}: </strong>}
          {message}
        </p>
      ))}
    </div>
  );
};

ErrorList.propTypes = {
  errors: PropTypes.object,
  style: PropTypes.object,
  className: PropTypes.string,
  showFieldNames: PropTypes.bool,
};

export default ErrorList;
