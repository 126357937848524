import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { urls } from 'app-constants';
import LoadingOverlay from 'components/common/LoadingOverlay';
// import Select from 'components/common/DjangoFormField/Select';

const TYPE_MEDIA = 'media';
const TYPE_TITLE = 'title';


const ObjectPreview = ({ objectId, objectType, titleType }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [frameUrl, setFrameUrl] = useState('');
  const [templateChoices, setTemplateChoices] = useState([]);
  const [activeTemplateId, setActiveTemplateId] = useState(null);
  const [activePresetId, setActivePresetId] = useState(null);

  useEffect(() => {
    fetchTemplateChoices();
  }, []);

  useEffect(() => {
    if (templateChoices.length) {
      setActiveTemplateId(templateChoices[0].id);
      const defaultPreset = templateChoices[0].presets.find(p => p.default);
      if (defaultPreset) setActivePresetId(defaultPreset.id);
    }
  }, [templateChoices]);

  const fetchTemplateChoices = () => {
    fetch(urls.previewTemplateChoices, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          throw new Error('Error fetching template choices.');
        }
        return response;
      })
      .then(response => response.json())
      .then(data => setTemplateChoices(data))
      .catch(err => console.error(err));
  };

  useEffect(() => {
    if (activeTemplateId) {
      setIsLoading(true);
      const baseUrl = urls[`${objectType}PreviewBase`];
      let url = baseUrl + objectId + '/';
      if (activePresetId) url += `?preset=${activePresetId}`;
      // TODO: support template query param
      setFrameUrl(url);
    }
  }, [activeTemplateId, activePresetId]);

  const frameLoadTimeout = useRef();
  const handleFrameLoad = e => {
    // Once the frame has loaded, allow some extra time for the loading *within*
    // the frame to happen before hiding the overlay animation.
    clearTimeout(frameLoadTimeout.current);
    frameLoadTimeout.current = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };
  useEffect(() => {
    return () => clearTimeout(frameLoadTimeout.current);
  }, []);

  // const { presets } = templateChoices.find(t => t.id === activeTemplateId) || {};
  // const presetChoices = presets && presets.length > 0 && [
  //   ['', '(None)'],
  //   ...presets.map(({ id, name }) => ([id, name])),
  // ];

  return (
    <div className="object-detail-content">
      <div className="object-detail-main">
        {/*
        <h4 className="alt-head mb-1">Preview</h4>
        <hr className="mt-0 mb-4" />
        */}

        <div className="object-preview-frame-container mb-4">
          <div className="object-preview-frame">
            <iframe
              src={frameUrl}
              frameBorder="0"
              allow="autoplay; fullscreen"
              onLoad={frameUrl ? handleFrameLoad : undefined}
              allowFullScreen
            />
          </div>
          <LoadingOverlay text="Loading preview…" show={isLoading} />
        </div>
      </div>

      {/*
      <div className="object-detail-sidebar">
        <h4 className="alt-head mb-1">Options</h4>
        <hr className="mt-0 mb-4" />

        TODO - support multiple templates
        {templateChoices.length > 0 && (
          <div className="form-group mb-4">
            <div><label><strong>Template</strong></label></div>
            {templateChoices.map(({ id, name }) => (
              <div key={id}>
                <label className="form-radio m-0">
                  <input
                    type="radio"
                    value={id}
                    checked={activeTemplateId === id}
                    onChange={e => setActiveTemplateId(e.target.value)}
                  />
                  <i className="form-icon" /> {name}
                </label>
              </div>
            ))}
          </div>
        )}

        {presetChoices && (
          <>
            <Select
              label="Preset"
              helpText="Preview using the selected template preset."
              choices={presetChoices}
              value={activePresetId || ''}
              className="object-preview-preset-field mb-4"
              inputStyle={{ display: 'block', maxWidth: 200 }}
              onChange={e => setActivePresetId(e.target.value)}
            />
            <hr />
          </>
        )}

        {(templateChoices.length > 0 || presetChoices) && <hr />}

        <p className="form-input-hint">This preview is for illustrative purposes only and includes limited configuration options. To view and adjust all available options, add this item to a <a href={urls.releaseBase}>release</a>.</p>
      </div>
      */}
    </div>
  );
};

ObjectPreview.propTypes = {
  objectId: PropTypes.string,
  objectType: PropTypes.oneOf([TYPE_MEDIA, TYPE_TITLE]),
  titleType: PropTypes.string,
};

export default ObjectPreview;
