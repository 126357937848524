import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuid4 } from 'uuid';
import camelize from 'camelize';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { pd, pluralize } from 'utils';
import { withResizeDetector } from 'react-resize-detector';
import { useTitleBuilder } from 'components/views/titleBuilder';
import ErrorBoundary from 'components/common/ErrorBoundary';
import StyledScrollbar from 'components/common/StyledScrollbar';
import { ManagementForm } from 'components/common/inlines';
import AddLibraryMedia from 'components/common/AddLibraryMedia';
import ButtonMenu from 'components/common/ButtonMenu';
import withDragDropContext from 'components/common/withDragDropContext';
import itemFormDefaults from './slideFormDefaults';
import Item from './Item';
import Form from './Form';
import RelatedItemHiddenFields from './RelatedItemHiddenFields';
import DisplayOptions, { VIEW_MODES, DEFAULT_MODE } from './DisplayOptions';
import BulkEditForm, { MODE_REPLACE } from './BulkEditForm';

const SlideshowBuilder = ({ location, history, formsetData, relatedItemsFormsetData, hasError, width }) => {
  const [viewMode, setViewMode] = useState(DEFAULT_MODE);
  const [selectionModeActive, setSelectionModeActive] = useState(false);
  const [bulkEditModeActive, setBulkEditModeActive] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);

  const toggleItemSelection = (itemId, bool) => setSelectedIds(oldState => {
    const isSelected = oldState.includes(itemId);
    if (typeof bool === 'undefined') bool = !isSelected;
    const val = oldState.filter(id => id !== itemId);
    if (bool) val.push(itemId);
    return val;
  });

  const extraItemProps = item => {
    const selected = selectedIds.includes(item.itemId);
    const props = {
      viewMode,
      selectable: selectionModeActive,
      onToggleSelect: bool => toggleItemSelection(item.itemId, bool),
      selected,
    };

    if (selectionModeActive) {
      props.active = false;
    }

    return props;
  };

  const opts = {
    formsetData,
    itemLabel: 'slide',
    itemLabelPlural: 'slides',
    hasError,
    addFromMedia: true,
    ItemComponent: Item,
    extraItemProps,
    itemFormDefaults,
    onItemClick: selectionModeActive ? toggleItemSelection : undefined,
  };

  const {
    itemsOrdered,
    itemsMarkedForDeletion,
    activeItem,
    setActiveItemId,
    handleItemAdd,
    handleFormChange,
    handleBulkChange,
    toggleDelete,
    managementForm,
    messages,
    renderEmptyMessage,
    renderedItems,
    initNewItem,
  } = useTitleBuilder(opts);

  // Related items

  const [relatedItemsById, setRelatedItemsById] = useState({});
  const [relatedItemsBySlide, setRelatedItemsBySlide] = useState({});
  const nextRelatedItemIndex = useRef(0);

  useEffect(() => initRelatedItems(), []);

  const initRelatedItems = () => {
    // Hydrate related items list from initial formset data
    const { forms, prefix } = camelize(relatedItemsFormsetData);
    const newRelatedItemsById = {};
    const newRelatedItemsBySlide = {};
    forms.forEach(form => {
      const itemId = uuid4();
      const fields = Object.keys(form.fields).reduce((result, key) => {
        if (key === 'DELETE') {
          result.shouldDelete = !!form.fields[key].value;
        } else {
          result[key] = form.fields[key].value;
        }
        return result;
      }, {});

      const item = {
        itemId,
        itemIndex: nextRelatedItemIndex.current,
        prefix: `${prefix}-${nextRelatedItemIndex.current}`,
        errors: form.errors,
        ...fields,
      };

      newRelatedItemsById[itemId] = item;
      newRelatedItemsBySlide[item.slideUid] = newRelatedItemsBySlide[item.slideUid] || [];
      newRelatedItemsBySlide[item.slideUid].push(itemId);
      nextRelatedItemIndex.current += 1;
    });

    setRelatedItemsById(newRelatedItemsById);
    setRelatedItemsBySlide(newRelatedItemsBySlide);
  };

  const handleRelatedItemsChange = items => {
    if (!activeItem) return null;

    const { prefix } = relatedItemsFormsetData;
    const activeItemId = activeItem.itemId;
    const newIds = items.map(item => item.itemId);
    const newItemsById = items.reduce((result, item, idx) => {
      result[item.itemId] = {
        ...item,
        index: idx + 1,
        slideUid: activeItemId,
      };
      if (!item.prefix) {
        result[item.itemId].prefix = `${prefix}-${nextRelatedItemIndex.current}`;
        nextRelatedItemIndex.current += 1;
      }
      return result;
    }, { ...relatedItemsById });

    setRelatedItemsBySlide({
      ...relatedItemsBySlide,
      [activeItemId]: newIds,
    });
    setRelatedItemsById(newItemsById);
  };

  const handleRelatedItemsBulkChange = (values, slideIds, mode) => {
    const { prefix } = relatedItemsFormsetData;

    const relatedItems = slideIds.reduce((result, slideId) => {
      return [
        ...result,
        ...values.map((fields, idx) => {
          const itemPrefix = `${prefix}-${nextRelatedItemIndex.current}`;
          nextRelatedItemIndex.current += 1;
          return {
            ...fields,
            slideUid: slideId,
            prefix: itemPrefix,
            itemId: uuid4(),
            shouldDelete: false,
          };
        }),
      ];
    }, []);

    setRelatedItemsById(oldState => {
      const curItems = { ...oldState };

      // Calculate current index for each slide
      const lastIndexBySlide = Object.values(oldState).reduce((result, v) => {
        if (v.index > (result[v.slideUid] || 0)) {
          result[v.slideUid] = v.index;
        }
        return result;
      }, {});

      // Set shouldDelete on all preexisting items if replacing
      if (mode === MODE_REPLACE) {
        Object.values(curItems).forEach(val => {
          if (slideIds.includes(val.slideUid)) {
            val.shouldDelete = true;
          }
        });
      }

      const newItems = relatedItems.reduce((result, item) => {
        lastIndexBySlide[item.itemId] = (lastIndexBySlide[item.itemId] || 0) + 1;
        result[item.itemId] = {
          ...item,
          index: lastIndexBySlide[item.itemId],
          itemIndex: lastIndexBySlide[item.itemId],
        };
        return result;
      }, {});

      return { ...curItems, ...newItems };
    });

    setRelatedItemsBySlide(oldState => ({
      ...oldState,
      ...slideIds.reduce((result, slideId) => {
        result[slideId] = [
          ...(oldState[slideId] || []),
          ...relatedItems.filter(item => item.slideUid === slideId).map(item => item.itemId),
        ];
        return result;
      }, {}),
    }));
  };

  // List/Grid view mode

  useEffect(() => updateViewMode(), [location.search]);

  const handleTextSlideAdd = () => initNewItem({ ...itemFormDefaults, layout: 'text' });

  const setViewModeParam = mode => {
    if (!VIEW_MODES.includes(mode)) return null;

    const currentParams = queryString.parse(location.search);
    const newParams = {
      ...currentParams,
      ss_viewmode: mode,
    };
    const qs = queryString.stringify(newParams);
    history.push(`${location.pathname}?${qs}`);
  };

  const updateViewMode = () => {
    const params = queryString.parse(location.search);
    const vm = VIEW_MODES.includes(params.ss_viewmode) ? params.ss_viewmode : DEFAULT_MODE;
    setViewMode(vm);
  };

  // Bulk selection/editing --------------------------

  const clearSelection = () => setSelectedIds([]);
  const toggleSelectionMode = () => setSelectionModeActive(oldState => !oldState);
  const cancelSelection = () => {
    setActiveItemId(itemsOrdered[0]);
    toggleSelectionMode();
  };

  useEffect(() => {
    if (itemsOrdered.length) {
      setActiveItemId(selectionModeActive ? null : itemsOrdered[0]);
    }

    if (!selectionModeActive) {
      clearSelection();
    }
  }, [selectionModeActive, itemsOrdered]);

  useEffect(() => {
    setSelectedIds(oldState => oldState.filter(id => !itemsMarkedForDeletion.includes(id)));
  }, [JSON.stringify(itemsMarkedForDeletion)]);


  useEffect(() => {
    if (bulkEditModeActive && (!selectionModeActive || !selectedIds.length > 0)) {
      setBulkEditModeActive(false);
    }
  }, [selectedIds, selectionModeActive]);

  const handleSelectAll = pd(() => setSelectedIds(itemsOrdered.filter(id => !itemsMarkedForDeletion.includes(id))));
  const handleSelectNone = pd(clearSelection);
  const handleDeleteSelection = () => {
    selectedIds.forEach(itemId => toggleDelete(itemId, true));
    toggleSelectionMode();
  };
  const handleEditSelection = () => {
    setBulkEditModeActive(true);
  };
  const handleBulkEditFormSubmit = data => {
    const { relatedItems, relatedItemsMode, ...fields } = data;
    handleBulkChange(selectedIds, fields);
    if (relatedItems) {
      handleRelatedItemsBulkChange(relatedItems, selectedIds, relatedItemsMode);
    }
    toggleSelectionMode();
  };
  const handleBulkEditFormCancel = cancelSelection;


  // -------------------------------------------------

  const objectListOptions = {
    preFilter: { type__in: 'image,video' },
  };

  const { prefix: riPrefix, managementForm: riManagementForm } = camelize(relatedItemsFormsetData);
  const riFormCount = Object.keys(relatedItemsById).length;
  const relatedItems = Object.values(relatedItemsById).map(item => {
    const index = relatedItemsBySlide[item.slideUid].indexOf(item.itemId) + 1;
    return { ...item, index };
  });
  const activeItemId = activeItem && activeItem.itemId;
  const activeItemIndex = activeItemId && itemsOrdered.indexOf(activeItemId) + 1;
  const activeRelatedItems = (relatedItemsBySlide[activeItemId] || []).map(id => relatedItemsById[id]);

  return (
    <div className={`title-builder object-detail-panel-full-height slideshow-builder-${viewMode}`}>
      <ErrorBoundary>
        {managementForm}
        <AddLibraryMedia allowMultiple onChange={handleItemAdd} {...objectListOptions}>
          {handleModalTrigger => {
            let rightColumnContent;
            if (selectionModeActive) {
              if (bulkEditModeActive) {
                rightColumnContent = (
                  <BulkEditForm
                    count={selectedIds.length}
                    onSubmit={handleBulkEditFormSubmit}
                    onCancel={handleBulkEditFormCancel}
                  />
                );
              } else if (selectedIds.length > 0) {
                rightColumnContent = (
                  <div className="title-builder-empty-message-container">
                    <p>{pluralize(selectedIds.length, 'slide')} selected.</p>
                    <div className="d-flex align-items-center justify-content-center">
                      <button
                        className="btn btn-primary"
                        type="button"
                        onClick={handleEditSelection}
                      >
                        Edit
                      </button>
                      <span style={{ margin: '0 15px' }}>– or –</span>
                      <button
                        className="btn btn-error ml-2"
                        type="button"
                        onClick={handleDeleteSelection}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              } else {
                rightColumnContent = (
                  <div className="title-builder-empty-message-container">
                    <p>No slides selected. Choose from the list at left, or…</p>
                    <button className="btn" type="button" style={{ textTransform: 'capitalize' }} onClick={cancelSelection}>
                      Cancel Selection
                    </button>
                  </div>
                );
              }
            } else if (activeItem) {
              rightColumnContent = (
                <Form
                  item={activeItem}
                  index={activeItemIndex}
                  relatedItems={activeRelatedItems}
                  onChange={handleFormChange}
                  onRelatedItemsChange={handleRelatedItemsChange}
                />
              );
            } else {
              rightColumnContent = renderEmptyMessage(handleModalTrigger);
            }

            return (
              <>
                <div className="title-builder-items-container">
                  <StyledScrollbar key={viewMode + width}>
                    {messages}
                    <div className="title-builder-item disabled flex-spread">
                      {selectionModeActive ? (
                        <>
                          <div className="text-sm">
                            <span>Select: &nbsp;</span>
                            <span>
                              <a href="#" onClick={handleSelectAll}>All</a>
                              <span> &thinsp;|&thinsp; </span>
                              <a href="#" onClick={handleSelectNone}>None</a>
                            </span>
                          </div>

                          <button
                            className="btn"
                            type="button"
                            onClick={cancelSelection}
                          >
                            Cancel Selection
                          </button>
                        </>
                      ) : (
                        <>
                          <div className="d-flex align-items-center">
                            <DisplayOptions viewMode={viewMode} onChange={setViewModeParam} />
                          </div>
                          <div className="d-flex">
                            <button
                              className="btn mr-2"
                              type="button"
                              onClick={toggleSelectionMode}
                            >
                              Select
                            </button>
                            <ButtonMenu
                              label="Add New Slides"
                              items={[
                                { label: 'Choose Media', iconName: 'theaters', onClick: handleModalTrigger },
                                { label: 'Add Text Slide', iconName: 'format_align_left', onClick: handleTextSlideAdd },
                              ]}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div className="slideshow-builder-items">
                      {renderedItems}
                    </div>
                  </StyledScrollbar>
                </div>

                {rightColumnContent}
              </>
            );
          }}
        </AddLibraryMedia>
        <ManagementForm
          prefix={riPrefix}
          formData={riManagementForm}
          totalForms={riFormCount}
        />
        {relatedItems.map(item => <RelatedItemHiddenFields key={item.itemId} {...item} />)}
      </ErrorBoundary>
    </div>
  );
};

SlideshowBuilder.propTypes = {
  location: PropTypes.object,
  history: PropTypes.object,
  formsetData: PropTypes.object,
  relatedItemsFormsetData: PropTypes.object,
  hasError: PropTypes.bool,
  width: PropTypes.number,
};

export default withDragDropContext(withRouter(withResizeDetector(SlideshowBuilder, {
  handleWidth: true,
  handleHeight: false,
  refreshMode: 'debounce',
})));
