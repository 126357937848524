import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'components/common/ErrorBoundary';
import ReactPlayer from 'react-player';


const MediaPlayer = ({ mediaType, mediaUrl }) => {
  return (
    <ErrorBoundary>
      <ReactPlayer
        url={mediaUrl}
        width="100%"
        height={mediaType === 'video' ? '100%' : '80px'}
        controls
      />
    </ErrorBoundary>
  );
};

MediaPlayer.propTypes = {
  mediaType: PropTypes.string,
  mediaUrl: PropTypes.string,
};

export default MediaPlayer;
