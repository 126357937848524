import React, { Component } from 'react';
import PropTypes from 'prop-types';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import LoadingSpinner from 'components/common/LoadingSpinner';


class FacebookReleaseImagePreview extends Component {
  constructor (props) {
    super(props);
    this.state = {
      data: null,
      processing: false,
      error: null,
    };
  }

  componentDidMount () {
    this.fetchData();
  }

  componentDidUpdate (prevProps, prevState) {
    const { objectUid } = this.props;
    if (objectUid !== prevProps.objectUid) {
      this.setState({
        data: null,
        error: false,
      }, () => this.fetchData(objectUid));
    }
  }

  fetchData () {
    const { objectType, objectUid } = this.props;
    if (!objectUid) {
      return null;
    }

    const baseUrl = objectType === 'title' ? urls.titleDataBase : urls.mediaDataBase;
    const url = urlJoin(baseUrl, objectUid, '?include_images=true');
    this.setState({ processing: true });
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          this.setState({ error: response.statusText });
        }
        return response;
      })
      .then(response => response.json())
      .then(data => {
        this.setState({ data: data, processing: false });
      })
      .catch(err => {
        this.setState({ processing: false, error: err });
        console.error(err);
      });
  }

  getImageUrl () {
    const { previewImageVersion, imageMediaUseOriginal } = this.props;
    const { data } = this.state;
    if (!data) {
      return null;
    }
    let imageUrl = data.images[previewImageVersion];
    if (imageMediaUseOriginal && data.images.original) {
      imageUrl = data.images.original;
    }
    return imageUrl;
  }

  render () {
    const { objectType } = this.props;
    const { data, processing } = this.state;
    const imgUrl = this.getImageUrl();

    return (
      <div>
        <h5 className="mb-1">Image preview</h5>
        <p className="text-hint">This image will be included in the Facebook post.</p>
        {processing && !data ? (
          <div className="bg-gray-light p-4 d-flex justify-content-center">
            <LoadingSpinner />
          </div>
        ) : null}

        {data && !imgUrl ? (
          <div className="bg-gray-light p-6 text-center">
            <em>The selected {objectType} is missing a preview image.</em>
          </div>
        ) : null}

        {data && imgUrl ? (
          <div className="bg-gray-light p-4">
            <img src={imgUrl} alt="image preview" style={{ display: 'block', margin: '0 auto', maxWidth: 500 }} />
          </div>
        ) : null}
      </div>
    );
  }
}

FacebookReleaseImagePreview.propTypes = {
  objectType: PropTypes.oneOf(['title', 'media']),
  objectUid: PropTypes.string,
  previewImageVersion: PropTypes.string,
  imageMediaUseOriginal: PropTypes.bool,
};

FacebookReleaseImagePreview.defaultProps = {
  previewImageVersion: 'poster-frame',
  imageMediaUseOriginal: true,
};

export default FacebookReleaseImagePreview;
