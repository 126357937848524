import React, { useState, useEffect, useMemo, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import camelize from 'camelize';
import urlJoin from 'url-join';
import { urls } from 'app-constants';
import { useCSRF } from 'hooks';
import DjangoFormField from 'components/common/DjangoFormField';
import Text from 'components/common/DjangoFormField/Text';
import Checkbox from 'components/common/DjangoFormField/Checkbox';
import LoadingOverlay from 'components/common/LoadingOverlay';
import Message from 'components/common/Message';


const OptionsForm = forwardRef(({ preset, onFormReady, onChange, onSave }, ref) => {
  const [formData, setFormData] = useState({});
  const [fieldValues, setFieldValues] = useState({});
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  const [nameValue, setNameValue] = useState(preset.name);
  const [nameErrors, setNameErrors] = useState([]);
  const handleNameChange = e => setNameValue(e.target.value);

  const [isDefaultValue, setIsDefaultValue] = useState(preset.default);
  const handleIsDefaultChange = e => setIsDefaultValue(e.target.checked);

  const fieldInfo = useMemo(() => Object.values(formData.fields || {}).reduce((result, field) => {
    return { ...result, [field.name]: field };
  }, {}), [formData]);

  // Reformat field values for use with the preview
  const formatValues = vals => Object.entries(vals).reduce((result, [key, val]) => {
    const { fieldType, widgetType, fileUrl } = fieldInfo[key] || {};

    if (fieldType === 'FontField') {
      result[key] = JSON.parse(val);
    } else if (widgetType === 'ClearableFileInput' && vals[`${key}-clear`]) {
      result[key] = '';
    } else if (val instanceof File) {
      // Uploaded but unsaved file URLs
      result[key] = URL.createObjectURL(val);
    } else if (fileUrl) {
      // Pre-existing file URLs
      result[key] = fileUrl;
    } else if (fieldType) {
      // Omits any fields not in the form definition
      result[key] = val;
    }
    return result;
  }, {});

  useEffect(() => {
    if (Object.keys(fieldInfo).length && Object.keys(fieldValues).length) {
      onChange(formatValues(fieldValues));
    }
  }, [fieldInfo, fieldValues]);

  const handleFieldChange = e => {
    const { type, name, value, files, checked } = e.target;
    let val = value;
    if (type === 'file') {
      val = files[0];
    } else if (type === 'checkbox') {
      val = checked;
    }
    setFieldValues({ ...fieldValues, [name]: val });
  };

  // This is for clearable file/image fields
  const handleFieldClear = name => {
    setFieldValues({ ...fieldValues, [name]: null });
  };

  const csrfToken = useCSRF();

  const handleOptionsFormLoad = e => {
    const { status, statusText, response } = e.target;
    if (status === 204) {
      // Normal response, no body
      onSave();
    } else if (status === 200) {
      // Redisplaying form with errors
      setFormData(camelize(JSON.parse(response)));
    } else {
      setError(statusText);
    }
    setIsFetching(false);
  };

  const saveOptionsForm = () => {
    const url = urlJoin(urls.presetTemplateOptionsBase, preset.id, '/');
    const formData = new FormData();
    Object.entries(fieldValues).forEach(([name, value]) => {
      formData.append(name, value);
    });

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.withCredentials = true;
    xhr.setRequestHeader('X-CSRFToken', csrfToken);
    xhr.onload = handleOptionsFormLoad;
    xhr.onerror = () => {
      setIsFetching(false);
      setError('Network Error');
    };
    setIsFetching(true);
    xhr.send(formData);
  };

  const savePresetForm = cb => {
    setIsFetching(true);

    const body = JSON.stringify({
      name: nameValue,
      default: isDefaultValue,
    });
    const url = urlJoin(urls.presetsBase, preset.id, '/');
    fetch(url, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'X-CSRFToken': csrfToken,
      },
      body,
    })
      .then(response => {
        if (response.status === 400) {
          // Form validation errors
          response.json().then(data => {
            setIsFetching(false);
            setNameErrors(data.name);
          });
        } else if (!response.ok) {
          throw new Error(response.statusText);
        } else {
          cb ? cb() : setIsFetching(false);
        }
        return response;
      })
      .catch(err => {
        setIsFetching(false);
        setError('Network Error');
        console.error(err);
      });
  };

  const handleSubmit = e => {
    e && e.preventDefault();
    if (isFetching) return null;
    if (nameValue !== preset.name || isDefaultValue !== preset.default) {
      savePresetForm(saveOptionsForm);
    } else {
      saveOptionsForm();
    }
  };

  useImperativeHandle(ref, () => ({
    save: () => handleSubmit(),
  }));

  useEffect(() => {
    fetchOptions();
  }, [preset.id]);

  const fetchOptions = () => {
    const url = urlJoin(urls.presetTemplateOptionsBase, preset.id, '/');
    setIsFetching(true);
    fetch(url, { credentials: 'include' })
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText || 'Bad Request');
        }
        return response;
      })
      .then(response => response.json())
      .then(data => camelize(data))
      .then(data => {
        setIsFetching(false);
        setFormData(data);
      })
      .catch(err => {
        setIsFetching(false);
        setError(err.message);
        console.error(err);
      });
  };

  useEffect(() => {
    initFieldValues();
    if (Object.keys(formData).length) {
      onFormReady();
    }
  }, [formData]);

  const initFieldValues = () => {
    if (!formData.fields) return null;
    const vals = Object.values(formData.fields).reduce((result, field) => {
      if (typeof field.value !== 'object') {
        result[field.name] = field.value;
      }
      return result;
    }, {});
    setFieldValues(vals);
  };

  let fields = [];
  if (Object.keys(formData).length && Object.keys(fieldValues).length) {
    fields = Object.values(formData.fields);
  }
  // const noOptions = !isFetching && fields.length === 0;
  const hasFormErrors = Object.keys(formData.errors || {}).length > 0;

  return error ? (
    <div className="toast toast-error">Error: {error}</div>
  ) : (
    <form onSubmit={handleSubmit}>
      <div className="release-template-options-form">
        {/* TODO {noOptions && <div className="text-center p-5"><em>No configurable options available for this template.</em></div>} */}
        {hasFormErrors && (
          <div className="mb-3">
            <Message type="error" text="Please correct the errors below." />
          </div>
        )}
        {fields.length > 0 && (
          <>
            <Text
              label="Preset Name"
              value={nameValue}
              errors={nameErrors}
              className="mb-1"
              onChange={handleNameChange}
            />
            <Checkbox
              label="Default"
              value={isDefaultValue}
              className="mb-4"
              helpText="If selected, this preset will be applied by default to new releases."
              onChange={handleIsDefaultChange}
            />
            {fields.map(field => (
              <DjangoFormField
                key={field.name}
                className="mb-4"
                {...field}
                value={fieldValues[field.name]}
                allowFormatting={field.widgetType === 'Textarea'}
                onChange={handleFieldChange}
                onClear={handleFieldClear}
              />
            ))}
          </>
        )}
        <LoadingOverlay show={isFetching} />
      </div>
    </form>
  );
});

OptionsForm.displayName = 'OptionsForm';

OptionsForm.propTypes = {
  preset: PropTypes.object.isRequired,
  onFormReady: PropTypes.func,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
};

export default OptionsForm;
